import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { AddTicketCategoriesSetting, EditTicketCategoriesSetting, GetTicketCategoriesSettingDetail, GetTicketDepartmentSettingList } from "../../../services/TicketsSettingServices";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";

function CategoryForm() {
  const { id, type } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [ticketData, setTicketData] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    if(type==="add"){
      let response = hasPermission({
        scopes: ["ticketsettingcategoryadd"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }
    if(type==="open"){
      let response = hasPermission({
        scopes: ["ticketsettingcategoryedit"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }
  }, []);

  useEffect(() => {
    GetTicketDepartmentSettingList()
      .then((response) => {
        const arr = response?.data?.support_department?.filter(item=>item?.status === 1);
        let tempList = [];
        arr.sort((a, b)=>{
          let name1 = a.title.toString().trim().toLowerCase();
          let name2 = b.title.toString().trim().toLowerCase();
          return name1.localeCompare(name2);
        }).map((item) => {
          let singleItem = {
            label: item.title,
            value: item.id,
          };
          tempList.push(singleItem);
        });
        // console.log("department", arr);
        setDepartmentList(tempList);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    if (type !== "add") {
      GetTicketCategoriesSettingDetail(id).then((res) => {
        if(res.data.list_data){
          // console.log("responseget", res);
          setTicketData(res.data?.list_data);
          setSelectedDepartment(res.data?.list_data.department_id);
          setIsLoading(false);
        }
        else {
          Swal.fire({
            icon: "Info",
            title: "Info",
            text: "No record found with this id",
          })
          history.push("/administration/ticketSettings/categories/table")
        }
        
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: ticketData && ticketData.title ? ticketData.title : "",
      Department: ticketData && ticketData.department_id ? departmentList[departmentList?.findIndex((option)=> option?.value == ticketData?.department_id)]?.label : "",
      Status: ticketData && ticketData.status ? ticketData.status.toString() : "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("Name is required").max(255, "Name can't be more than 255 characters"),
      Department: Yup.string().required("Department Is Required"),
      Status: Yup.string().required("Status Is Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("title", values?.Name);
      formData.append("department_id", selectedDepartment);
      formData.append("status", values?.Status);
      type !== "add" && formData.append("id", id);
      if (type == "add") {
        AddTicketCategoriesSetting(formData)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            resetForm();
            history.push("/administration/ticketSettings/categories/table");
          })
          .catch((err) => {
            setDisabled(false);
            console.log("error: ", err?.response?.data[0]);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data}`,
            });
          });
      } else {
        EditTicketCategoriesSetting(formData)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            });
          })
          .catch((err) => {
            setDisabled(false);
            console.log("error: ", err?.response?.data[0]);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data}`,
            });
          });
      }
    },
  });

  return (
    <div className="card card-body-inr">
      {isLoading ? (
        <SkeletonTicketList />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-lg-4" title="Name">
              <label htmlFor="Name">Name *</label>
              <div className="form-icon-group mb-4" >
                <input
                  type="text"
                  className={"form-control" + (formik.errors.Name && formik.touched.Name ? " is-invalid" : "")}
                  name="Name"
                  id="Name"
                  placeholder="Name"
                  title="Name"
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4" title="Department">
              <label htmlFor="Department">Department *</label>
              <div className="form-icon-group mb-4" >
                <Select
                  className={"form-control custom-select-box" + (formik.errors.Department && formik.touched.Department ? " is-invalid" : "")}
                  name="Department"
                  id="Department"
                  title="Department"
                  placeholder={formik.values.Department ? formik.values.Department : "Select Department"}
                  options={departmentList}
                  onBlur={formik.handleBlur}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("Department", value.label);
                      setSelectedDepartment(value.value);
                    } else {
                      formik.setFieldValue("Department", "");
                      setSelectedDepartment("");
                    }
                    // setGreensideClassTypeSelected(value.value)
                  }}
                  value={departmentList.filter((option)=> {
                    return option.label === formik.values.Department;
                  })}
                  isClearable
                  // value={formik.values.Department}
                />
                {/* <input
                type="text"
                className={"form-control" + (formik.errors.Department && formik.touched.Department ? " is-invalid" : "")}
                name="Department"
                id="Department"
                placeholder="Department Id"
                title="Department Id"
                value={formik.values.Department}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <label htmlFor="Status" title="Status">Status *</label>
              <div className="form-icon-group" title="Status">
                <div className="custon_rd_new">
                  <div class="custom-control custom-radio custom-control-inline" title="Active">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline"
                      class="custom-control-input"
                      checked={formik.values.Status == "1"}
                      onChange={() => formik.setFieldValue("Status", "1")}
                      onBlur={formik.handleBlur}
                    />
                    <label class="custom-control-label" for="customRadioInline1">
                      Active
                    </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline" title="In Active">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline"
                      class="custom-control-input"
                      title="Status"
                      checked={formik.values.Status == "2"}
                      onChange={() => formik.setFieldValue("Status", "2")}
                      onBlur={formik.handleBlur}
                    />
                    <label class="custom-control-label" for="customRadioInline2">
                      In Active
                    </label>
                  </div>
                </div>

                {/* <input
                  type="radio"
                  //   className={"" + (formik.errors.Status && formik.touched.Status ? " is-invalid" : "")}
                  name="Status"
                  id="Active"
                  placeholder="Status"
                  title="Status"
                  checked={formik.values.Status}
                  onChange={() => formik.setFieldValue("Status", 1)}
                  onBlur={formik.handleBlur}
                /> */}

                {/* <label htmlFor="Active">Active</label>
                <input
                  type="radio"
                  //   className={"" + (formik.errors.Status && formik.touched.Status ? " is-invalid" : "")}
                  name="Status"
                  id="Inactive"
                  placeholder="Status"
                  checked={formik.values.Status == 0}
                  title="Status"
                  onChange={() => formik.setFieldValue("Status", 0)}
                  onBlur={formik.handleBlur}
                /> */}

                {/* <label htmlFor="Inactive">In Active</label> */}
              </div>
            </div>
          </div>
          <div className="form-group form-group-save-cancel">
            {type == "open" ? (
              <PermissionsGate scopes={["tsedit"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="submit" title="Save">
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                {/* <i className="fal fa-save"></i> */}
                Save
              </button>
            </PermissionsGate>
            ) : (
              <PermissionsGate scopes={["tsadd"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="submit" title="Save">
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                {/* <i className="fal fa-save"></i> */}
                Save
              </button>
            </PermissionsGate>
            )}
            
            <button className="btn btn-close btn-danger" type="reset" title="Cancel" onClick={() => formik.resetForm()}>
              <i className="fal fa-times"></i>
              Cancel
            </button>

            {formik.touched.Name && formik.errors.Name ? <div className="invalid-feedback d-block">{formik.errors.Name}</div> : null}
            {formik.touched.Department && formik.errors.Department ? <div className="invalid-feedback d-block">{formik.errors.Department}</div> : null}
            {formik.touched.Status && formik.errors.Status ? <div className="invalid-feedback d-block">{formik.errors.Status}</div> : null}
          </div>
        </form>
      )}
    </div>
  );
}

export default CategoryForm;
