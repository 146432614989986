import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../utils/permissionGate";
import {
  RenderAssessmentType,
  RenderCourseType,
} from "../../../utils/CommonGroupingItem";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  GradeLetterAggregationMethods,
  GradeLetterTypes,
} from "../../../services/GradeSettingService";
import ListOfTemplateModal from "../CourseGradeSettings/ListOfTemplateModal";
import Str from "../../common/Str";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { AddGradeMix } from "../../../services/GradeMixService";

const ProgrammeGradeMixForm = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [gradingMethodValue, setGradingMethodValue] = useState("1");
  const [aggregationMethodValue, setAggregationMethodValue] = useState();
  const [gradeTypeValue, setGradeTypeValue] = useState();
  const [dropoutValue, setDropoutValue] = useState("");
  const [passMark, setPassMark] = useState("");
  const [gradeMixName, setGradeMixName] = useState("");
  const [gradeMixSORName, setGradeMixSORName] = useState("");
  const [passMarkValue, setPassMarkValue] = useState("");
  const [gradeTypeArr, setGradeTypeArr] = useState([]);
  const [aggregationTypeArr, setAggregationTypeArr] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isGradeLocked, setIsGradeLocked] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [editedRows, setEditedRows] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const tooltipObj = {
    aggregationMethodTooltip:
      "Median of grades - If this is selected, the programme grade must be determined by is the middle value (or the mean of the two middle values) when percentages are arranged in order of value for the courses.\nLowest Grade – If this is selected, the programme grade will be the lowest grade achieved for any of its courses.\nHighest Grade - If this is selected, the programme grade will be the highest grade achieved for any of its courses.\nMode of Grades - If this is selected, the programme grade will be the grade that occurs the most frequently.\nNatural - If this is selected, the programme grade will be the sum of all course grades, scaled by their relative weights.",
    dropoutTootltip:
      "The number entered will be used to exclude X number of courses which have the lowest final grade from the final calculation, this will only consider courses which have a weight if natural is selected",
  };

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pgmixadd"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  let templatePassMarkArr = [];
  let templateArray =
    selectedTemplate?.gradeOptions?.length &&
    selectedTemplate?.gradeOptions.map((item) => {
      templatePassMarkArr.push({
        value: item?.id,
        label: item?.text,
      });
    });

  let options = [
    { label: "Red", value: "0" },
    { label: "Yellow", value: "49" },
    { label: "Green", value: "100" },
  ];

  useEffect(() => {
    GradeLetterTypes()
      .then((res) => {
        setGradeTypeArr(res?.data);
      })
      .catch((err) => console.error(err));
    GradeLetterAggregationMethods()
      .then((res) => {
        setAggregationTypeArr(res?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleUpdateData = () => {
    let tempErrorMessage = errorMessage;

    if (gradeMixName === undefined || gradeMixName == "") {
      tempErrorMessage = {
        ...tempErrorMessage,
        gradeMixName: "Grade Mix Name is Required",
      };
    } else {
      delete tempErrorMessage?.gradeMixName;
    }

    if (gradeMixSORName === undefined || gradeMixSORName == "") {
      tempErrorMessage = {
        ...tempErrorMessage,
        gradeMixSORName: "Grade Mix SOR Name is Required",
      };
    } else {
      delete tempErrorMessage?.gradeMixSORName;
    }

    if (gradeTypeValue === undefined || gradeTypeValue == "5") {
      tempErrorMessage = {
        ...tempErrorMessage,
        gradeType: "Grade Type is Required",
      };
    } else {
      delete tempErrorMessage?.gradeType;
    }
    if (
      aggregationMethodValue === undefined &&
      gradeTypeValue !== undefined &&
      gradeTypeValue != 3
    ) {
      tempErrorMessage = {
        ...tempErrorMessage,
        aggregationMethod: "Aggregation Method is Required",
      };
    } else {
      delete tempErrorMessage?.aggregationMethod;
    }
    if (gradeTypeValue == "2" || gradeTypeValue == "1") {
      if (passMarkValue === "") {
        tempErrorMessage = {
          ...tempErrorMessage,
          passMark: "Pass Mark is Required",
        };
      } else {
        delete tempErrorMessage?.passMark;
      }
    } else {
      if (passMark == "" && (gradeTypeValue == "0" || gradeTypeValue == "3")) {
        tempErrorMessage = {
          ...tempErrorMessage,
          passMark: "Pass Mark is Required",
        };
      } else {
        delete tempErrorMessage?.passMark;
      }
    }
    // if (dropoutValue > totalRows - 1 && gradeTypeValue != 3) {
    //   tempErrorMessage = {
    //     ...tempErrorMessage,
    //     dropoutValue: "Dropout Value Must Be Smaller Than Total Assessments",
    //   };
    // } else {
    //   delete tempErrorMessage?.dropoutValue;
    // }
    if (!selectedTemplate?.id && gradeTypeValue == "2") {
      tempErrorMessage = {
        ...tempErrorMessage,
        template: "Template is Required",
      };
    } else {
      delete tempErrorMessage?.template;
    }
    setErrorMessage(tempErrorMessage);
    if (Object.keys(tempErrorMessage).length) {
      return;
    }
    setIsDisabled(true);
    let data = {
      // id,
      qualification_id: parseInt(id),
      name: gradeMixName,
      sor_name: gradeMixSORName,
      drop_lowest_amount: dropoutValue ? parseInt(dropoutValue) : 0,
      // exclude_empty_grades: excludeEmptyGrade,
      // formula: formulaValue,
      // grading_method_id: 1,
      grading_template_id: selectedTemplate?.id ? selectedTemplate?.id : 0,
      grading_type: gradeTypeValue,
      pass_mark:
        gradeTypeValue == "1" || (gradeTypeValue == "2" && passMarkValue)
          ? passMarkValue
          : passMark
          ? passMark
          : 0,
      // assessment: JSON.stringify(editedRows),
    };
    if (gradingMethodValue == "1") {
      data.aggregation_method_id =
        gradeTypeValue != 3 ? aggregationMethodValue : 1;
    }
    AddGradeMix(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        });
        setIsDisabled(false);
        setUpdateData(!updateData);
        setEditedRows([]);
        history.push(`/courseAdministration/Programmes/programme/open/${id}/gradeMix/table`);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something Went Wrong",
        });
        setIsDisabled(false);
        setUpdateData(!updateData);
        console.error("err: ", err);
      });
  };
  
  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="grade-setting-box">
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">Create Grade Mix</div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row grade-store-box ">
                <div className="col-md-6 col-lg-6">
                  <label htmlFor="Name"> Name *</label>
                  <div className="form-icon-group mb-4" title="Name">
                    <input
                      type="text"
                      className={"form-control" + (errorMessage?.gradeMixName?.length ? " is-invalid" : "")}
                      name="Name"
                      id="Name"
                      placeholder="Name"
                      title="Name"
                      value={gradeMixName}
                      onChange={(e) => setGradeMixName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <label htmlFor="SOR_Name">SOR Name *</label>
                  <div className="form-icon-group mb-4" title="Name">
                    <input
                      type="text"
                      className={"form-control" + (errorMessage?.gradeMixSORName?.length ? " is-invalid" : "")}
                      name="SOR Name"
                      id="SOR_Name"
                      placeholder="SOR Name"
                      title="SOR Name"
                      value={gradeMixSORName}
                      onChange={(e)=> setGradeMixSORName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">Grade Settings</div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row grade-store-box ">
                <div className="col-md-4 col-lg-2">
                  <div
                    className="form-icon-group mb-4 d-block"
                    title="Grade Type"
                  >
                    <label>Grade Type </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errorMessage?.gradeType ? " is-invalid" : "")
                      }
                      name="grade_type"
                      value={gradeTypeArr.filter(function (option) {
                        return option.value === gradeTypeValue;
                      })}
                      onChange={(value) => {
                        if (value) {
                          setGradeTypeValue(value.value);
                          setAggregationMethodValue();
                          setDropoutValue("");
                          setPassMarkValue("");
                          setPassMark("");
                          setSelectedTemplate({});
                          setErrorMessage((prevState) => ({
                            ...prevState,
                            gradeType: "",
                          }));
                        } else {
                          setGradeTypeValue();
                          setPassMarkValue("");
                          setPassMark("");
                          setAggregationMethodValue();
                          setDropoutValue("");
                          setSelectedTemplate({});
                          setErrorMessage((prevState) => ({
                            ...prevState,
                            gradeType: "Grade Type is Required",
                          }));
                        }
                      }}
                      isClearable
                      //   onBlur={formik.handleBlur}
                      options={gradeTypeArr}
                      maxMenuHeight={175}
                      placeholder={"Grade Type"}
                      isDisabled={isGradeLocked}
                    />
                  </div>
                </div>
                {gradeTypeValue || +gradeTypeValue === 0 ? (
                  <>
                    {gradeTypeValue != "3" && (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Aggregation Method"
                        >
                          <label>
                            Aggregation Method{" "}
                            <i
                              className="fal fa-info-circle grade-icon"
                              title={tooltipObj.aggregationMethodTooltip}
                            ></i>
                          </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.aggregationMethod
                                ? " is-invalid"
                                : "")
                            }
                            name="aggregation_method"
                            value={aggregationTypeArr.filter(function (option) {
                              return option.value == aggregationMethodValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setAggregationMethodValue(value.value);
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  aggregationMethod: "",
                                }));
                              } else {
                                setAggregationMethodValue();
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  aggregationMethod:
                                    "Aggregation Method is Required",
                                }));
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={aggregationTypeArr}
                            maxMenuHeight={175}
                            placeholder={"Aggregation Method"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue != "3" && (
                      <div className="col-md-4 col-lg-2" title="Dropout Value">
                        <label>
                          Dropout Value{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title={tooltipObj.dropoutTootltip}
                          ></i>
                        </label>
                        <div className="form-icon-group mb-4">
                          <input
                            className={
                              "form-control" +
                              (errorMessage.dropoutValue?.length
                                ? " is-invalid"
                                : "")
                            }
                            type="number"
                            onChange={(e) => {
                              setDropoutValue(e.target.value);
                            }}
                            value={dropoutValue}
                            placeholder="Dropout Value"
                            disabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue == "1" ? (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Pass Mark"
                        >
                          <label>Pass Mark </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            name="pass_mark"
                            value={options.filter(function (option) {
                              return option.value == passMarkValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setPassMarkValue(value.value);
                              } else {
                                setPassMarkValue("");
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={options}
                            maxMenuHeight={175}
                            placeholder={"Mark"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    ) : gradeTypeValue == "2" ? (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Pass Mark"
                        >
                          <label>Pass Mark </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            name="pass_mark"
                            value={templatePassMarkArr.filter(function (
                              option
                            ) {
                              return option.value == passMarkValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setPassMarkValue(value.value);
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  passMark: "",
                                }));
                              } else {
                                setPassMarkValue("");
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  passMark: "Pass Mark is Required",
                                }));
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={templatePassMarkArr}
                            maxMenuHeight={175}
                            placeholder={"Mark"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-4 col-lg-2" title="Pass Mark">
                        <label>Pass Mark </label>
                        <div className="form-icon-group mb-4">
                          <input
                            className={
                              "form-control" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            type="number"
                            onChange={(e) => {
                              if (e.target.value) {
                                setPassMark(e.target.value);
                              } else {
                                setPassMark("");
                              }
                            }}
                            value={passMark}
                            placeholder="Pass Mark"
                            disabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue == "2" && (
                      <div className="search-filter-div-right mb-4">
                        <label>&nbsp;</label>
                        <div className="filter-search-bar-blk">
                          <div className="add-ticket-blk dropdown-comman">
                            <button
                              // className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#letterTemplatemodal"
                              className="btn btn-save btn-success"
                              type="button"
                              title="Select Template"
                              disabled={isGradeLocked}
                            >
                              <i className="fal fa-file-alt"></i>Select Template
                            </button>
                            {selectedTemplate && (
                              <>
                                {selectedTemplate && selectedTemplate?.name && (
                                  <div className="d-flex select-delete">
                                    <Link
                                      className="as-text-blue curser"
                                      title="Open"
                                      to={`/courseAdministration/gradingTemplate/edit/${selectedTemplate?.id}`}
                                    >
                                      <span className="textLimit100">
                                        {selectedTemplate?.name}
                                      </span>
                                    </Link>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => setSelectedTemplate({})}
                                      disabled={isGradeLocked}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form-group form-group-save-cancel">
          <PermissionsGate
            scopes={["pgmixadd"]}
            errorProps={{ disabled: true }}
          >
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
              onClick={handleUpdateData}
              disabled={isDisabled}
            >
              {isDisabled ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Save
            </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => {
              setUpdateData(!updateData);
              history.goBack()
            }}
          >
            <i className="fal fa-times"></i>Cancel
          </button>
          {errorMessage
            ? Object.keys(errorMessage).map((key) => {
                return errorMessage[key] ? (
                  <div className="invalid-feedback d-block" key={key}>
                    {errorMessage[key]}
                  </div>
                ) : (
                  <React.Fragment key={key}></React.Fragment>
                );
              })
            : ""}
        </div>
      </div>
      <ListOfTemplateModal
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
      />
    </div>
  );
};

export default ProgrammeGradeMixForm;
