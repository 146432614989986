import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  GetStudentList,
  ImportCSVStudentCrmNumber,
  NetSuiteCustomerService,
} from "../../../services/NetSuiteService";
import { GetListOfUser } from "../../../services/UserService";
import { TrimText } from "../../common/TrimText";
import PermissionsGate from "../../../utils/permissionGate";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";

function StudentTable() {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [deleterow, setDeleteRow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    setIsLoaded(true);
    let formData = new FormData();
    formData.append("method", "GET");

    GetStudentList(formData)
      .then((response) => {
        //   setData([])
        setData(response?.data?.data);
        setIsLoaded(false);
        setDeleteRow(false);
      })
      .catch((error) => {
        setIsLoaded(false);
        setDeleteRow(false);
      });
  }, [deleterow]);

  const uploadCsv = (csvFile) => {
    Swal.fire({
      title: "Netsuite data importing",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("csvfile", csvFile);
    ImportCSVStudentCrmNumber(formData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Uploaded successfully",
        });
        // Swal.close();
        //   setData([])
        // setData(response?.data?.data);
        // setIsLoaded(false);
        // setDeleteRow(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        // Swal.close();
        // setIsLoaded(false);
        // setDeleteRow(false);
      });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setStatus([]);
    setSearchStatusCheck({});
  };

  // const handleStatusFilter = (e) => {
  //   const value = e.target.value;
  //   let arr = status;
  //   if (arr.includes(value)) {
  //     arr.splice(arr.indexOf(value), 1);
  //   } else {
  //     arr.push(value);
  //   }
  //   setStatus(arr);
  //   let id = e.target.id;
  //   setSearchStatusCheck({
  //     ...searchStatusCheck,
  //     [id]: !searchStatusCheck[id],
  //   });
  // };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    if (status.includes(value)) {
      setStatus([]);
    } else {
      setStatus([value]);
    }

    let id = e.target.id;
    if (id == "action1") {
      setSearchStatusCheck({ action1: !searchStatusCheck.action1 });
    } else {
      setSearchStatusCheck({
        [id]: !searchStatusCheck[id],
        action1: false,
      });
    }
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempnetsuiteId = allData.filter((item) => {
        let includes =
          item.netsuite_id &&
          item.netsuite_id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempcompanyname = allData.filter((item) => {
        let includes =
          item.first_name &&
          item.first_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdatecreated = allData.filter((item) => {
        let includes =
          item.email && item.email.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes =
          item.student_crm_id &&
          item.student_crm_id.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempcompanyname,
        ...tempdatecreated,
        ...tempnetsuiteId,
        ...tempStudentNo,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (post == "True" && item.netsuite_id) {
            return item;
          } else if (post == "False" && !item.netsuite_id) {
            return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (search.length || status.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <a
              href="javascript:void(0);"
              className="as-text-blue curser "
              onClick={() =>
                history.push(
                  `/administration/netsuite/mergedData/open/${row.id}`
                )
              }
            >
              {row.first_name
                ? TrimText(row.first_name + " " + row.last_name, 20)
                : "-"}
            </a>
          </div>
        </div>
      ),
    },
    {
      name: "Student No.",
      selector: "student_crm_id",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">{row.student_crm_id}</span>
        </div>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (row.email ? row.email : "-"),
    },
    {
      name: "Merged",

      sortable: true,
      cell: (row) => (row.netsuite_id ? <span className="as-green-bg as-widget">True</span> : <span className="as-red-bg as-widget">False</span>),
    },
    {
      name: "Customer Id",
      selector: "netsuite_id",
      sortable: true,
      cell: (row) => (row.netsuite_id ? row.netsuite_id : "-"),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              errorProps={{ disabled: true }}
              scopes={["nmsmsview"]}
            >
              <Link
                className="btn btn-primary rounded-circle"
                to={`/administration/netsuite/mergedData/open/${row.id}`}


                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            {/* <button className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.id)}>
              <i className="fal fa-trash-alt"></i>
            </button> */}
          </div>
        </div>
      ),
    },
  ]);

  // const handleDelete = (ID) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   })
  //     .then(async (result) => {
  //       if (result.isConfirmed) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Deleting Record",
  //           showConfirmButton: false,
  //         });

  //         let formData = new FormData();
  //         formData.append("method", "DELETE");
  //         formData.append("customer_id", ID);
  //         NetSuiteCustomerService(formData)
  //           .then((res) => {
  //             console.log("errorDelete:then", res.status);
  //             setDeleteRow(!deleterow);
  //             Swal.fire("Deleted!", "Customer has been deleted.", "success");
  //           })
  //           .catch((err) => {
  //             if (err.response.status === 422) {
  //               setDeleteRow(!deleterow);
  //               Swal.fire(
  //                 "",
  //                 `This company type cannot be deleted because the company has associated transactions.`,
  //                 "warning"
  //               );
  //             }
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       setDeleteRow(!deleterow);
  //     });
  // };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Student No", "Email", "Merged", "Customer Id"];

    data = data?.map((row) => ({
      ...row,
      Name: row?.first_name ? row.first_name + " " + row.last_name : "-",
      "Student No": row?.student_crm_id ? row.student_crm_id : "-",
      Email: row.email ? row.email : "-",
      Merged: row?.netsuite_id ? "TRUE" : "FALSE",
      "Customer Id": row.netsuite_id ? row.netsuite_id : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.Name,
          row["Student No"],
          row.Email,
          row.Merged,
          row["Customer Id"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  {/* <div
                id="assessment-table-main_wrapper"
                className="dataTables_wrapper no-footer"
              > */}
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner filter-custom-new`}>
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""
                                }`}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="Merged"
                            >
                              <span>
                                Merged <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                <li className="item-text-red">
                                  <input
                                    type="checkbox"
                                    id="action2"
                                    value="False"
                                    onClick={handleStatusFilter}
                                    checked={searchStatusCheck.action2}
                                  />
                                  <label htmlFor="action2">
                                    <span className="as-red-bg as-widget">False</span>
                                  </label>
                                </li>
                                <li className="item-text-green">
                                  <input
                                    type="checkbox"
                                    id="action1"
                                    value="True"
                                    onClick={handleStatusFilter}
                                    checked={searchStatusCheck.action1}
                                  />
                                  <label htmlFor="action1">
                                    <span className="as-green-bg as-widget">True</span>
                                  </label>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Netsuite_Customers");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Netsuite_Customers");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Netsuite_Customers");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className="filter-search-bar-blk">
                <div className="form-group m-0 atttach-file">
                  <label>
                    <i className="fal fa-plus"></i>
                    <span>Import Netsuite Data</span>
                    <PermissionsGate
                      scopes={["nmsmsadd"]}
                      RenderError={() => (
                        <input
                          type="file"
                          title="button"
                          id="input"
                          className="form-control form-control-aatch-file"
                          name="file[]"
                          disabled
                        />
                      )}
                    >
                      <input
                        type="file"
                        title="button"
                        id="input"
                        className="form-control form-control-aatch-file"
                        accept={
                          ".csv"
                        }
                        name="file[]"
                        onChange={(e) => {
                          if (e.target.files.length) {
                            uploadCsv(e.target.files[0]);
                          }
                        }}
                      />
                    </PermissionsGate>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* <div className=" filter-search-bar-blk"> */}

          {/* <div className="add-ticket-blk button-reset dropdown-comman">
              <button className="btn btn-primary">
                <i className="fal fa-plus"></i>Import Netsuite Data
              </button>
            </div> */}
          {/* <div className="col-md-4"> */}

          {/* </div> */}
          {/* </div> */}

          {isLoaded ? (
            <SkeletonTicketList />
          ) : (
            <DataTable
              data={dataToRender()}
              defaultSortField="first_name"
              defaultSortAsc={false}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentTable;
