import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../../utils/permissionGate";
import Str from "../../../common/Str";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import {
  AssessmentsData,
  AssessmentsDataCancelToken,
  AssessmentsDataFilters,
  GetCommonContent,
  GetStudentAssementAttachment,
} from "../../../../services/StudentsSettingServices";
import { TrimText } from "../../../common/TrimText";
import { downloadURL, IMAGE_URL, TABLE_ROWS_PER_PAGE, zipURL } from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import $ from 'jquery';
import Tablefilter from "../../../common/Tablefilter";
import axios from "axios";
import JSZip from "jszip";
import {saveAs} from "file-saver"
import { handleTableScroll } from "../../../../utils/commonFunction";
// import file_size_url from 'file_size_url'
// import ufs from 'url-file-size'
// import { HttpClient } from 'urllib';


function StudentAssessment() {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [deleterow, setDeleteRow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [attachmentData, setAttachmentData] = useState([])
  const [filterData, setFilterData] = useState({intake : []});
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("subject");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [checkTotal, setCheckTotal] = useState(0);
  const [checkPending, setCheckPending] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [zipPercent, setZipPercent] = useState("")

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])


  useEffect(() => {
    let response = hasPermission({ scopes: ["sasview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    GetStudentAssementAttachment(id).then((res)=>{
      setAttachmentData(res.data.Files)
    }).catch((err)=>{console.log(err);})
  }, [])

  useEffect(() => {
    AssessmentsDataFilters(id).then((res)=>{
      setFilterData({...res.data, intake : res.data.yearsAndIntaksFilter})

    }).catch(err=>console.log(err))
  }, [])
  
  

  useEffect(() => {
    const cancelTokenSources = [];

    const getAssessmentList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const apiData = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        viaSearch: search,
        student_id: id,
        intake : intake.arr,
        exportStatus: "false"
      }
  
      try {
        const res = await AssessmentsDataCancelToken(apiData, source.token);
        if (res.status === 200) {
          setUserData(res.data?.data.data);
          setTotalRows(res.data?.data.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    getAssessmentList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, [deleterow, page, perPage, sortOrder, sortkey, search, id,intake]);

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setIntake({ arr: [], checkObj: {} });
    setSearch("");
  };

  const columns = useMemo(() => [
    {
      name: "Assessment",
      selector: "name",
      sortable: true,
      sortField:"name",
      cell: (row) => (row.name ? <Link title={row.name} to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignment}`} className="as-text-blue curser feature-name"><span className="textLimit100">{row.name}</span></Link> : "-"),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      sortField: "year",
      cell: (row) => (
        <p>
          {row.number || row.year ? (
            <>
              {row.year ? row["year"] : ""}-{row.number ? row.number : ""}
            </>
          ) : (
            "-"
            )}
        </p>
      ),
    },
    // { name: "Email", selector: "Email", sortable: true, cell: (row) => TrimText(row.Email ? row.Email : "NA", 15) },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      sortField: "subject",
      // minWidth: "190px",
      cell: (row) => (
            <PermissionsGate
              scopes={["castview"]}
              RenderError={() => <p className="as-text-blue curser ">{row.subject}</p>}
            >
              <Link
                to={`/courseAdministration/coursesdetails/${row.intakeID}/detailCourses/show`}
                className="as-text-blue curser feature-name"
                title={row.subject}
              >
                <span className="textLimit100">{row.subject}</span>
              </Link>
            </PermissionsGate>
      ),
    },
    
    {
      name: "Code",
      selector: "code",
      sortField: "code",
      sortable: true,
      cell: (row) => row.code ? <span title={row.code}>{TrimText(row.code, 15)}</span> : "-",
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      sortField: "type",
      cell: (row) => (row.type ? row.type : "-"),
    },
    {
      name: "Mark",
      selector: "mark",
      sortable: true,
      sortField: "mark",
      cell: (row) => (row.mark && row.mark.toString() && row.Total && row.Total.toString() ? `${row.mark}/${row.Total} ` : "-"),
    },
    {
      name: "Percentage",
      selector: "percent",
      sortable: true,
      sortField: "percent",
      cell: (row) =>
        row.percent && row.percent.toString() ? row.percent : "-",
    },
    {
      name: "Weight",
      selector: "weighting",
      sortable: true,
      sortField: "weighting",
      cell: (row) =>
        row.weighting && row.weighting.toString() ? row.weighting : "-",
    },
    {
      name: "Total Weight",
      selector: "totalWeight",
      sortable: true,
      sortField: "totalWeight",
      cell: (row) =>
        row.percent &&
          row.percent.toString() &&
          row.weighting &&
          row.weighting.toString()
          ? (row.percent * row.weighting) / 100
          : "-",
    },

    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["castview"]}
              RenderError={() => <button className="btn btn-primary rounded-circle" disabled><i className="fal fa-folder-open"></i></button>}
            >
              <Link
                className="btn btn-primary rounded-circle"
                to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignment}`}
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            {row.assPath ? (
              <a
                className="btn btn-primary rounded-circle"
                href={
                  IMAGE_URL +
                  "/" +
                  row.assPath
                    .replace("/home/myaie/public_html/", "")
                    .replace("public/", "")
                }
                target={"_blank"}
                title="Open Submitted Assessment"
              >
                <i className="fal fa-eye"></i>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Assessment",
      "Intake",
      "Subject",
      "Code",
      "Type",
      "Mark",
      "Percentage",
      "Weight",
      "Total Weight",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const dataSend = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      viaSearch: search,
      student_id: id,
      intake : intake.arr,
      exportStatus: "true"
    }
    AssessmentsData(dataSend)
      // GetListOfUser()
      .then((res) => {
        data = res.data.data;
        data = data?.map((row) => ({
          ...row,
          Subject: row?.subject,
          Intake: row?.year ? row.year + "-" + row.number : "-",
          Assessment: row?.name ? row.name : "-",
          Code: row.code ? row.code : "-",
          Type: row.type && row.type.toString() ? row.type : "-",
          Weight: row.weighting && row.weighting.toString() ? row.weighting : "-",
          // Total: row.Total && row.Total.toString() ? row.Total : "-",
          Mark: row.mark && row.mark.toString() && row.Total && row.Total.toString() ? `${row.mark}/${row.Total} ` : "-",
          Percentage: row.percent && row.percent.toString() ? row.percent : "-",
          "Total Weight":
            row.percent && row.percent.toString()
              ? (row.percent * row.weighting) / 100
              : "-",
        }));
        // console.log(typeof data);
        // console.log(typeof result);
    
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row.Assessment,
              row.Intake,
              row.Subject,
              row.Code,
              row.Type,
              row.Mark,
              row.Percentage,
              row.Weight,
              row["Total Weight"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
       
      })
      .catch((err) => console.log(err));
  };

  const handleAttachment=()=>{
    let check_total=0;
    let pending=0;
    let newArr = attachmentData;
    var zip = new JSZip();
    let zipVal ={};
    newArr.map((val)=>{
      if(val.assignmentsFile && val.assignmentsFile!="" && val.assignmentsFile!=null){
        check_total++
        setCheckTotal(check_total)
      }
    })
    let myPromise = Promise.all(
      newArr.map(async(data)=>{
        if(data.assignmentsFile && data.assignmentsFile!=null && data.assignmentsFile!=""){
          const fileURL = downloadURL+`${data.assignmentsFile.includes("s3.af-south-1.amazonaws.com") ? "" : IMAGE_URL+"/"}${data.assignmentsFile.replaceAll(
            "/home/myaie/public_html/","").replace("public/","")}`;
            const fileExt = data.assignmentsFile.split("/").reverse()[0];
            try{
              const result = await axios({
                url : fileURL,
                method : "get",
                responseType : "blob",
                headers : {
                  "Content-Type": "application/octet-stream",
                  "access-control-expose-headers": "content-length"
                },
                onDownloadProgress: (ProgressEvent)=>{
                  const {loaded, total}=ProgressEvent;
                  const totalLength = ProgressEvent.lengthComputable
                  ? ProgressEvent.total
                  : ProgressEvent.target.getResponseHeader('content-length') ||( ProgressEvent.target.response && ProgressEvent.target.response.size);
                  if(data.size.length && data.size.includes("KB")){
                    let size=0;
                    let load=loaded ? Math.round(loaded / 1024) : '';
                    setLoaded(load)
                    setTotal(data.size)
                    size=parseInt(data.size.replace("KB",""))
                    let percentCompleted = Math.round((load * 100) / size); // you can use this to show user percentage of file downloaded
                    setPercent(percentCompleted.toString())
                  }
                  else if(data.size.length && data.size.includes("MB")){
                    let size=0;
                    let load=loaded ? Math.round(loaded / 1024 / 1024) : '';
                    setLoaded(load)
                    setTotal(data.size)
                    size=parseInt(data.size.replace("MB",""))
                    let percentCompleted = Math.round((load * 100) / size); // you can use this to show user percentage of file downloaded
                    setPercent(percentCompleted.toString())
                  } 
                  else if(data.size.length && data.size.includes("GB")){
                    let size=0;
                    let load=loaded ? Math.round(loaded / 1024 / 1024 / 1024) : ''
                    setLoaded(load)
                    setTotal(data.size)
                    size=parseInt(data.size.replace("GB",""))
                    let percentCompleted = Math.round((load * 100) / size); // you can use this to show user percentage of file downloaded
                    setPercent(percentCompleted.toString())
                  }
                }
              });
              zip.folder(data.assignmentsName).file(fileExt,result.data,{binary:true})
              pending++
              setCheckPending(pending)
            }catch(err){
              pending++
              setCheckPending(pending)
            }
              
        }
        zipVal = zip;
      })
    )
    myPromise.then(()=>{
      try{
        zipVal.generateAsync({type : "blob"}).then(function(content){
          saveAs(content,"AssessmentZip")
          $("#downloadAssessmentAll").modal("hide");
      })
        
      }catch(err){

      }
     

    })
     
}

  return (
    // <div className="l-o-c-t my-tickets-info-list Tickets-main-wrap">
    <div className="custom-table-div filter-search-icon card card-table-custom">
      <div className="search-filter-div">
        <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                    <div className="filter-scroll">
                        <div className={`filter-scroll-inner filter-custom-new`}>
                        <Tablefilter
                          filterName={"Intake"}
                          optionArr={filterData.intake}
                          state={intake}
                          setState={setIntake}
                          isOptionReversed={true}
                        />
                        </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", "Assessments");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", "Assessments");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", "Assessments");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="search-filter-div-right">
            <div class=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <button
                  type="button"
                  className="btn btn-primary"
                  title="Download All"
                  data-toggle="modal" 
                  disabled={attachmentData.length==0 ? true : false}
                  data-target="#downloadAssessmentAll"
                  onClick={()=>{handleAttachment()}}
                >Download All</button>
                
              </div>
            </div>
        </div>
    </div>

      {/* {loading ? (
        <SkeletonTicketList />
      ) : ( */}
      <DataTable
        // keyField="id"
        data={userData}
        defaultSortField="subject"
        defaultSortAsc={false}
        onSort={handleSort}
        columns={columns}
        noDataComponent={Str.noRecord}
        progressPending={loading}
        progressComponent={<SkeletonTicketList />}
        pagination
        paginationServer
        sortServer
        highlightOnHover={false}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      <div className="downloading-progress modal fade" id="downloadAssessmentAll" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={checkTotal==1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ width: checkTotal==1 ? percent+"%" : `${((checkPending / checkTotal) * 100).toFixed(2)}%` }}>
                    {checkTotal==1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)}%</div>
                </div>
                <>File Size : {loaded}/{total} Pending Files : {checkPending}/{checkTotal}</>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentAssessment;
