import React from 'react'
const stc = require('string-to-color');

// only for Learning Method or Study Type
export const RenderLearningMethod = (data, origin) => {

    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        case "part_time_online":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "full_time_online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "full_time_hybrid":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "correspondence":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence", origin });
        case "online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part time":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        default:
            return UIRender({ color: "N/A", text: "-", type: "learning method", origin });
    }
};
export const RenderLearningMethodGradebook = (data, origin) => {

    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        case "part_time_online":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "full_time_online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "full_time_hybrid":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "correspondence":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence", origin });
        case "online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part time":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        default:
            return UIRender({ color: "N/A", text: "-", type: "learning method", origin });
    }
};
export const RenderFeatureAndBenefits = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "program":
            return UIRender({ color: "skyblue", text: "Program", type: "features And Benefits",origin });
        case "institute":
            return UIRender({ color: "red", text: "Institute", type: "features And Benefits",origin });
        case "faculty":
            return UIRender({ color: "green", text: "Faculty", type: "features And Benefits",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "features And Benefits" });
    }
};

// Only for list with Short course and programme options, DON'T ADD ANY OTHER OPTION
export const RenderSLP = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "programs":
            return UIRender({ color: "cat-blue", text: "Programs", type: "slp",origin });
        case "shortcourse":
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "slp",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "slp" });
    }
};

// Only For School Type
export const RenderSchool = (data, origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "visual_design":
            return UIRender({
                color: "cat-skyblue",
                text: "School of Visual Design, Marketing & Branding",
                type: "school",
                origin
            });
        case "draughting":
            return UIRender({
                color: "cat-greenhex",
                text: "School of Draughting & Technical Design",
                type: "school",
                origin
            });
        case "it":
            return UIRender({
                color: "cat-blue",
                text: "School of Information Technology & Data Science",
                type: "school",
                origin
            });
        case "engineering":
            return UIRender({
                color: "cat-yellow",
                text: "School of Engineering & Science",
                type: "school",
                origin
            });
        case "business":
            return UIRender({
                color: "cat-red",
                text: "School of Business, Entrepreneurship & Finance",
                type: "school",
                origin
            });
        case "architecture":
            return UIRender({
                color: "cat-brown",
                text: "School of Architecture & The Built Environment",
                type: "school",
                origin
            });
        case "gdc":
            return UIRender({
                color: "cat-pink",
                text: "Greenside Design Center",
                type: "school",
                origin
            });
        case "school of draughting & technical design":
            return UIRender({
                color: "cat-greenhex",
                text: "School of Draughting & Technical Design",
                type: "school",
                origin
            });
        case "school of engineering & science":
            return UIRender({
                color: "cat-yellow",
                text: "School of Engineering & Science",
                type: "school",
                origin
            });
        case "school of business, entrepreneurship & finance":
            return UIRender({
                color: "cat-red",
                text: "School of Business, Entrepreneurship & Finance",
                type: "school",
                origin
            });;
        case "school of architecture & the built environment":
            return UIRender({
                color: "cat-brown",
                text: "School of Architecture & The Built Environment",
                type: "school",
                origin
            });;
        case "greenside design center":
            return UIRender({
                color: "cat-pink",
                text: "Greenside Design Center",
                type: "school",
                origin
            });
        case "fg":
            return UIRender({
                color: "cat-pinkshade",
                text: "School of Game Design & Development",
                type: "school",
                origin
            });
        case "fst":
            return UIRender({
                color: "cat-blueshade",
                text: "School of Music & Sound Production",
                type: "school",
                origin
            });
        case "School of Game Design & Development":
            return UIRender({
                color: "cat-pinkshade",
                text: "School of Game Design & Development",
                type: "school",
                origin
            });
        case "School of Music & Sound Production":
            return UIRender({
                color: "cat-blueshade",
                text: "School of Music & Sound Production",
                type: "school",
                origin
            });
        case "beauty":
            return UIRender({
                color: "cat-pink",
                text: "School of Beauty and Health Science",
                type: "school",
                origin
            });
        case "artisans":
            return UIRender({
                color: "cat-brown",
                text: "School of Artisans, Trades and Professions",
                type: "school",
                origin
            });
        default:
            return UIRender({
                color: "NA",
                text: "--",
                type: "school"
            });
    }
};

//Only for Progromme Type 
export const RenderProgrammeType = (data, origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "online":
            return UIRender({ color: "cat-yellowshade", text: "Online", type: "programme type", origin });

        case "Diploma":
            return UIRender({ color: "cat-green", text: "Diploma", type: "programme type", origin });

        case "1":
            return UIRender({ color: "cat-blue", text: "Certificate", type: "programme type", origin });

        case "2":
            return UIRender({ color: "cat-green", text: "Diploma", type: "programme type", origin });

        case "3":
            return UIRender({ color: "cat-red", text: "Higher Certificate", type: "programme type", origin });

        case "4":
            return UIRender({ color: "cat-orange", text: "Bachelor’s Degree", type: "programme type", origin });

        case "5":
            return UIRender({ color: "cat-skyblue", text: "Honours Degree", type: "programme type", origin });

        case "6":
            return UIRender({ color: "cat-brown", text: "Master’s Degree", type: "programme type", origin });

        case "Certificate":
            return UIRender({ color: "cat-green", text: "Certificate", type: "programme type", origin });

        case "higherCertificate":
            return UIRender({ color: "cat-green", text: "Higher Certificate", type: "programme type", origin });

        case "bachelorsDegree":
            return UIRender({ color: "cat-green", text: "Bachelor's Degree", type: "programme type", origin });

        case "honoursDegree":
            return UIRender({ color: "cat-green", text: "Honours Degree", type: "programme type", origin });

        case "mastersDegree":
            return UIRender({ color: "cat-green", text: "Masters Degree", type: "programme type", origin });

        default:
            return UIRender({ color: "cat-green", text: "Masters Degree", type: "programme type", origin });
    }
};

//Only for Course Type
export const RenderCourseType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "scheduled":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "mentored":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "information":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "orientation":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "shortcourse":
            return UIRender({ color: "cat-yellow", text: "SLP", type: "course type",origin });

        case "short course":
            return UIRender({ color: "cat-yellow", text: "SLP", type: "course type",origin });

        case "rereg_shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });

        default:
            return UIRender({ color: "NA", text: "-", type: "course type" });
    }
};

// only for Correspondence
export const RenderCorrespondence = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full-Time, Hybrid", type: "correspondence",origin });
        case "fulltime":
            return UIRender({ color: "cat-skyblue", text: "Full-Time, Hybrid", type: "correspondence",origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "correspondence",origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part Time, Online", type: "correspondence",origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "correspondence",origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "correspondence" });
    }
};
//only for registration list type
export const RenderStudentType = (data) => {
    switch (data) {
        case "south-african-citizen":
            return UIRender({ color: "cat-skyblue", text: "South African Citizen", type: "studentType"});
        case "international-student":
            return UIRender({ color: "cat-red", text: "International Student", type: "studentType" });
        default:
            return UIRender({ color: "black", text: "N/A", type: "studentType" });
    }
};

// only for programme or course type
export const RenderProgrammeOrCourseType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "shortcourse":
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "proogrammeOrCourseType",origin });
        case "programs":
            return UIRender({ color: "cat-blue", text: "Programs", type: "proogrammeOrCourseType",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "proogrammeOrCourseType" });
    }
};

//only for Ticket Level
export const RenderTicketLevel = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "1":
            return UIRender({ color: "cat-green", text: "1", type: "ticketLevel",origin });
        case "2":
            return UIRender({ color: "cat-blue", text: "2", type: "ticketLevel",origin });
        case "3":
            return UIRender({ color: "cat-yellow", text: "3", type: "ticketLevel",origin });
        case "4":
            return UIRender({ color: "cat-red", text: "4", type: "ticketLevel",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "ticketLevel" });
    }
};

//only for Status in pmc table
export const RenderPMCStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "new":
            return UIRender({ color: "skyblue", text: "New", type: "pmcStatus", backgroundColorHex: "#e0ffff", colorHex: "#40e0d0",origin });
        case "draft":
            return UIRender({ color: "red", text: "Draft", type: "pmcStatus", backgroundColorHex: "#ffaaaa", colorHex: "#ff0000",origin });
        case "final":
            return UIRender({ color: "green", text: "Final", type: "pmcStatus", backgroundColorHex: "#d5ffd9", colorHex: "#00c915",origin });
        case "approved":
            return UIRender({ color: "yellowshade", text: "Approved", type: "pmcStatus", backgroundColorHex: "#e7e8d7", colorHex: "#abb072",origin });
        case "inactive":
            return UIRender({ color: "blue", text: "Inactive", type: "pmcStatus", backgroundColorHex: "#e0edff", colorHex: "#1899d2",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "pmcStatus" });
    }
};

//only for Status in quiz table
export const RenderQuizStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "new":
            return UIRender({ color: "skyblue", text: "New", type: "quiz", backgroundColorHex: "#e0ffff", colorHex: "#40e0d0",origin });
        case "draft":
            return UIRender({ color: "red", text: "Draft", type: "quiz", backgroundColorHex: "#ffaaaa", colorHex: "#ff0000",origin });
        case "active":
            return UIRender({ color: "green", text: "Active", type: "quiz", backgroundColorHex: "#d5ffd9", colorHex: "#00c915",origin });
        case "inactive":
            return UIRender({ color: "blue", text: "Inactive", type: "quiz", backgroundColorHex: "#e0edff", colorHex: "#1899d2",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "quiz" });
    }
};

// Only for Type in Module, There are Two Different Type of type in Module, PMC
export const RenderTypeInModule = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "core":
            return UIRender({ color: "skyblue", text: "Core", type: "typeInModule",origin });
        case "specialised":
            return UIRender({ color: "red", text: "Specialised", type: "typeInModule",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "typeInModule" });
    }
};

// Only for ModuleType, There are Two Different Type of type in Module, PMC
export const RenderModuleType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "information":
            return UIRender({ color: "cat-skyblue", text: "Information", type: "ModuleType",origin });
        case "mentored":
            return UIRender({ color: "cat-red", text: "Mentored", type: "ModuleType",origin });
        case "orientation":
            return UIRender({ color: "cat-green", text: "Orientation", type: "ModuleType",origin });
        case "scheduled":
            return UIRender({ color: "cat-yellowshade", text: "Scheduled", type: "ModuleType",origin });
        case "shortcourse":
            return UIRender({ color: "cat-blue", text: "Short Course", type: "ModuleType",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "ModuleType" });
    }
};

// Only for ModuleType, There are Two Different Type of type in Module, PMC
export const RenderModuleTerm = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "term1":
            return UIRender({ color: "skyblue", text: "Term1", type: "ModuleTerm",origin });
        case "term2":
            return UIRender({ color: "red", text: "Term1", type: "ModuleTerm",origin });
        case "term3":
            return UIRender({ color: "green", text: "Term1", type: "ModuleTerm",origin });
        case "term4":
            return UIRender({ color: "blue", text: "Term1", type: "ModuleTerm",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "ModuleTerm" });
    }
};

// Only for Type in Assessment
export const RenderAssessmentType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "assignment":
            return UIRender({ color: "cat-skyblue", text: "Assignment", type: "AssessmentType",origin });
        case "1":
            return UIRender({ color: "cat-skyblue", text: "Assignment", type: "AssessmentType",origin });    
        case "external exam":
            return UIRender({ color: "cat-red", text: "External Exam", type: "AssessmentType",origin });
        case "0":
            return UIRender({ color: "cat-red", text: "External Exam", type: "AssessmentType",origin });
        case "internal exam":
            return UIRender({ color: "cat-green", text: "Internal Exam", type: "AssessmentType",origin });
        case "2":
            return UIRender({ color: "cat-green", text: "Internal Exam", type: "AssessmentType",origin });
        case "poeformative":
            return UIRender({ color: "cat-blue", text: "PoE-Formative", type: "AssessmentType",origin });
        case "4":
            return UIRender({ color: "cat-blue", text: "PoE-Formative", type: "AssessmentType",origin });
        case "poeinformal activities":
            return UIRender({ color: "cat-yellowshade", text: "PoE-Informal Activities", type: "AssessmentType",origin });
        case "6":
            return UIRender({ color: "cat-yellowshade", text: "PoE-Informal Activities", type: "AssessmentType",origin });
        case "poesummative":
            return UIRender({ color: "cat-yellow", text: "PoE-Summative", type: "AssessmentType",origin });
        case "5":
            return UIRender({ color: "cat-yellow", text: "PoE-Summative", type: "AssessmentType",origin });
        case "test":
            return UIRender({ color: "cat-brown", text: "Test", type: "AssessmentType",origin });
        case "3":
            return UIRender({ color: "cat-brown", text: "Test", type: "AssessmentType",origin });
        case "scheduled":
            return UIRender({ color: "cat-green", text: "Scheduled", type: "course type",origin });
    
        case "mentored":
            return UIRender({ color: "cat-blue", text: "Mentored", type: "course type",origin });
    
        case "information":
            return UIRender({ color: "cat-red", text: "Information", type: "course type",origin });
    
        case "orientation":
            return UIRender({ color: "cat-brown", text: "Orientation", type: "course type",origin });
    
        case "shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
    
        case "short course":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
    
        case "rereg_shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "AssessmentType" });
    }
};

// Only for Laptop Spec
export const RenderLaptopSpec = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "basic_laptop":
            return UIRender({ color: "cat-skyblue", text: "Basic Spec Laptop", type: "laptopSpec",origin });
        case "high_laptop":
            return UIRender({ color: "cat-red", text: "High Spec Laptop", type: "laptopSpec",origin });
        case "medium_laptop":
            return UIRender({ color: "cat-green", text: "Medium Spec Laptop", type: "laptopSpec",origin });
        case "no_laptop":
            return UIRender({ color: "cat-blue", text: "No Laptop Required", type: "laptopSpec",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "laptopSpec" });
    }
};

// Only for Campus
export const RenderCampus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "capetown":
            return UIRender({ color: "cat-skyblue", text: "Cape Town", type: "Campus",origin });
        case "centpre":
            return UIRender({ color: "cat-red", text: "Centurion, Pretoria", type: "Campus",origin });
        case "correspondence":
            return UIRender({ color: "cat-green", text: "Correspondence", type: "Campus",origin });
        case "gdc":
            return UIRender({ color: "cat-blue", text: "Greenside", type: "Campus",origin });
        case "midjohn":
            return UIRender({ color: "cat-yellowshade", text: "Midrand", type: "Campus",origin });
        case "online":
            return UIRender({ color: "cat-yellow", text: "Online", type: "Campus",origin });
        case "hatfield":
            return UIRender({ color: "cat-lightgreen", text: "Hatfield", type: "Campus",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "Campus" });
    }
};


// This will only be used in Class type.
export const RenderUserRole = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "accounts":
            return UIRender({ color: "cat-red", text: "Accounts", type: "userRole",origin });
        case "admin":
            return UIRender({ color: "cat-skyblue", text: "Admin", type: "userRole",origin });
        case "content editor":
            return UIRender({ color: "cat-black", text: "Content Editor", type: "userRole",origin });
        case "developer":
            return UIRender({ color: "cat-red", text: "Developer", type: "userRole",origin });
        case "lecturer":
            return UIRender({ color: "cat-greenhex", text: "Lecturer", type: "userRole",origin });
        case "manager":
            return UIRender({ color: "cat-blue", text: "Manager", type: "userRole",origin });
        case "programme coordinator":
            return UIRender({ color: "cat-black", text: "Programme Coordinator", type: "userRole",origin });
        case "sales":
            return UIRender({ color: "cat-blue", text: "Sales", type: "userRole",origin });
        case "student support":
            return UIRender({ color: "cat-greenhex", text: "Student Support", type: "userRole",origin });
        case "":
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        case null:
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        case undefined:
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "userRole" });
    }
};

// This will only be used in user location.
export const RenderUserLocation = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "midrand":
            return UIRender({ color: "cat-red", text: "Midrand", type: "userLocation",origin });
        case "johannesburg":
            return UIRender({ color: "cat-skyblue", text: "Johannesburg", type: "userLocation",origin });
        case "cape town":
            return UIRender({ color: "cat-blue", text: "Cape Town", type: "userLocation",origin });
        case "hatfield":
            return UIRender({ color: "cat-pink", text: "Hatfield", type: "userLocation",origin });
        case "remote/online":
            return UIRender({ color: "cat-yellowshade", text: "Remote/Online", type: "userLocation",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "userLocation" });
    }
};

// This will only be used in user Company.
export const RenderUserCompany = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "academic institute of excellence (aie)":
            return UIRender({ color: "cat-red", text: "Academic Institute of Excellence (AIE)", type: "userCompany",origin });
        case "greenside design center (gdc)":
            return UIRender({ color: "cat-skyblue", text: "Greenside Design Center (GDC)", type: "userCompany",origin });
        case "learnfast":
            return UIRender({ color: "cat-blue", text: "Learnfast", type: "userCompany",origin });
        case "cadco":
            return UIRender({ color: "cat-red", text: "Cadco", type: "userCompany",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "userCompany" });
    }
};

// This will only be used in user Company.
export const RenderAuditTrailActionType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "created":
            return UIRender({ color: "cat-blue", text: "Created", type: "auditTrailActionType",origin });
        case "edited":
            return UIRender({ color: "cat-greenhex", text: "Edited", type: "auditTrailActionType",origin });
        case "deleted":
            return UIRender({ color: "cat-red", text: "Deleted", type: "auditTrailActionType",origin });
        case "profile picture edited":
            return UIRender({ color: "cat-yellowshade", text: "Picture Edited", type: "auditTrailActionType",origin });
        case "approved":
            return UIRender({ color: "cat-greenhex", text: "Approved", type: "auditTrailActionType", origin });
        case "declined":
            return UIRender({ color: "cat-red", text: "Declined", type: "auditTrailActionType", origin });
        case "imported":
            return UIRender({ color: "cat-yellowshade", text: "Imported", type: "auditTrailActionType", origin });
        case "uploaded":
            return UIRender({ color: "cat-yellowshade", text: "Uploaded", type: "auditTrailActionType", origin });
        case "logged in (desktop)":
            return UIRender({ color: "cat-yellowshade", text: "Logged In (Desktop)", type: "auditTrailActionType", origin });
        case "logged out (desktop)":
            return UIRender({ color: "cat-lightblue", text: "Logged Out (Desktop)", type: "auditTrailActionType", origin });
        case "logged in (mobile)":
            return UIRender({ color: "cat-blue", text: "Logged In (Mobile)", type: "auditTrailActionType", origin });
        case "logged out (mobile)":
            return UIRender({ color: "cat-yellow", text: "Logged Out (Mobile)", type: "auditTrailActionType", origin });
        case "read ticket":
            return UIRender({ color: "cat-blueshade", text: "Read Ticket", type: "auditTrailActionType", origin });
        case "replied to ticket":
            return UIRender({ color: "cat-pink", text: "Replied to Ticket", type: "auditTrailActionType", origin });
        case "opened resource":
            return UIRender({ color: "cat-brown", text: "Opened Resource", type: "auditTrailActionType", origin });
        case "read notification":
            return UIRender({ color: "cat-red", text: "Read Notification", type: "auditTrailActionType", origin });
        case "submitted assessment":
            return UIRender({ color: "cat-pinkshade", text: "Submitted Assessment", type: "auditTrailActionType", origin });
        case "declined":
            return UIRender({ color: "cat-skyblue", text: "Declined", type: "auditTrailActionType", origin });
        case "re-registration new":
            return UIRender({ color: "cat-yellowshade", text: "Re-Registration New", type: "auditTrailActionType", origin });
        case "remind me later":
            return UIRender({ color: "cat-greenhex", text: "Remind Me Later", type: "auditTrailActionType", origin });
        case "exam registration":
            return UIRender({ color: "cat-lightblue", text: "Exam Registration", type: "auditTrailActionType", origin });
        case "rejected":
            return UIRender({ color: "cat-orange", text: "Rejected", type: "auditTrailActionType" });    
        case "marked as final":
            return UIRender({ color: "cat-purple", text: "Marked as Final", type: "auditTrailActionType" });   
        case "merged":
            return UIRender({ color: "cat-skyblue", text: "Merged", type: "auditTrailActionType" });    
        case "set to accounts":
            return UIRender({ color: "cat-brown", text: "Set To Accounts", type: "auditTrailActionType" }); 
        case "posted to netsuite":
            return UIRender({ color: "cat-blue", text: "Posted To Netsuite", type: "auditTrailActionType" }); 
        case "save & add to live portal clicked":
            return UIRender({ color: "cat-green", text: "Save & Add to Live Portal Clicked", type: "auditTrailActionType" });      
        case "logged in (desktop)":
            return UIRender({ color: "cat-yellowshade", text: "Logged In (Desktop)", type:"auditTrailActionType" });
        case "logged out (desktop)":
            return UIRender({ color: "cat-lightblue", text: "Logged Out (Desktop)", type:"auditTrailActionType" });
        case "logged in (mobile)":
            return UIRender({ color: "cat-blue", text: "Logged In (Mobile)", type:"auditTrailActionType" });
        case "logged out (mobile)":
            return UIRender({ color: "cat-yellow", text: "Logged Out (Mobile)", type:"auditTrailActionType" });
        case "declined":
            return UIRender({ color: "cat-skyblue", text: "Declined", type:"auditTrailActionType" });
        case "opened resource":
            return UIRender({ color: "cat-brown", text: "Opened Resource", type:"auditTrailActionType" });
        case "re registration new":
            return UIRender({ color: "cat-yellowshade", text: "Re-Registration New", type:"auditTrailActionType" });
        case "read notification":
            return UIRender({ color: "cat-red", text: "Read Notification", type:"auditTrailActionType" });
        case "read ticket":
            return UIRender({ color: "cat-blueshade", text: "Read Ticket", type:"auditTrailActionType" });
        case "remind Me later":
            return UIRender({ color: "cat-greenhex", text: "Remind Me Later", type:"auditTrailActionType" });
        case "replied to ticket":
            return UIRender({ color: "cat-pink", text: "Replied to Ticket", type:"auditTrailActionType" });
        case "submitted assessment":
            return UIRender({ color: "cat-pinkshade", text: "Submitted Assessment", type:"auditTrailActionType" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "auditTrailActionType" });
    }
};

//only for Appeal Submission Status
export const RenderSubmissionStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "appealpending":
            return UIRender({ color: "cat-yellow", text: "Appeal-Pending", type: "submissionStatus",origin });
        case "appealapproved":
            return UIRender({ color: "cat-greenhex", text: "Appeal-Approved", type: "submissionStatus",origin });
        case "draft":
            return UIRender({ color: "cat-skyblue", text: "Draft", type: "submissionStatus",origin });
        case "marked":
            return UIRender({ color: "cat-green", text: "Marked", type: "submissionStatus",origin });
        case "not submitted":
            return UIRender({ color: "cat-yellowshade", text: "Not-Submitted", type: "submissionStatus",origin });
        case "submitted":
            return UIRender({ color: "cat-red", text: "Submitted", type: "submissionStatus",origin });
        case "closednot submitted":
            return UIRender({ color: "cat-dark-red", text: "Closed-Not Submitted", type: "submissionStatus",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "submissionStatus" });
    }
};

//this will only be used course report -> attendance type
export const RenderAttendanceType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "virtual":
            return UIRender({ color: "cat-greenhex", text: "Virtual Classroom", type: "attendanceType",origin });
        case "on campus":
            return UIRender({ color: "cat-red", text: "On Campus Class", type: "attendanceType",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "attendanceType" });
    }
};

//this will only be used for department.
export const RenderDepartment = (data,origin) => {
    let value = data.toString().toLowerCase().replace("-", " ")
    return UIRender({ color: stc(value), text: value, type: "department",origin });
};

//this will only be used for department.
export const RenderCategory = (data,origin) => {
    let value = data.toString().toLowerCase().replace("-", " ")
    return UIRender({ color: stc(value), text: value, type: "category",origin });
};

//Only used for version filter.
export const RenderVersion = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "v1":
            return UIRender({ color: "cat-skyblue", text: "V1", type: "versionFilter",origin });
        case "v2":
            return UIRender({ color: "cat-red", text: "V2", type: "versionFilter",origin });
        case "v3":
            return UIRender({ color: "cat-yellow", text: "V3", type: "versionFilter",origin });
        case "v4":
            return UIRender({ color: "cat-blue", text: "V4", type: "versionFilter",origin });
        case "v5":
            return UIRender({ color: "cat-greenhex", text: "V5", type: "versionFilter",origin });
        case "v6":
            return UIRender({ color: "cat-skyblue", text: "V6", type: "versionFilter",origin });
        case "v7":
            return UIRender({ color: "cat-red", text: "V7", type: "versionFilter",origin });
        case "v8":
            return UIRender({ color: "cat-yellow", text: "V8", type: "versionFilter",origin });
        case "v9":
            return UIRender({ color: "cat-blue", text: "V9", type: "versionFilter",origin });
        case "v10":
            return UIRender({ color: "cat-greenhex", text: "V10", type: "versionFilter",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "versionFilter" });
    }
};

//only for attendance health filter
export const RenderAttendanceHealth = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "critical":
            return UIRender({ color: "cat-red", text: "Critical", type: "attendanceHealth",origin });
        case "good":
            return UIRender({ color: "cat-green", text: "Good", type: "attendanceHealth",origin });
        case "moderate":
            return UIRender({ color: "cat-orange", text: "Moderate", type: "attendanceHealth",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "attendanceHealth" });
    }
};

//only for level for question
export const RenderQuestionLevel = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "easy":
            return UIRender({ color: "cat-green", text: "Easy", type: "questionLevel" });
        case "hard":
            return UIRender({ color: "cat-red", text: "Hard", type: "questionLevel" });
        case "medium":
            return UIRender({ color: "cat-blue", text: "Medium", type: "questionLevel" });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "questionLevel" });
    }
};

//only for question type
export const RenderQuestionType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "multiple choice single answer":
            return UIRender({ icon: <i class="fal fa-tasks"></i>, color: "cat-yellow", text: "Multiple Choice Single Answer", type: "questionType" });
        case "multiple choice multiple answer":
            return UIRender({ icon: <i class="far fa-check-square"></i>, color: "cat-greenhex", text: "Multiple Choice Multiple Answer", type: "questionType" });
        case "match the column":
            return UIRender({ icon: <i class="far fa-line-columns" style="font-size: 24px;"></i>, color: "cat-light-pink", text: "Match the Column", type: "questionType" });
        case "short answer":
            return UIRender({ icon: <i class="far fa-comment-alt-lines"></i>, color: "cat-blue", text: "Short Answer", type: "questionType" });
        case "long answer":
            return UIRender({ icon: <i class="fal fa-align-left"></i>, color: "cat-red", text: "Long Answer", type: "questionType" });
        case "choice matrix":
            return UIRender({ icon: <i class="fal fa-table"></i>,  color: "cat-light-green", text: "Choice Matrix", type: "questionType" });
        case "fill in the blank":
            return UIRender({ icon: <i class="far fa-keyboard"></i>, color: "cat-dark-blue", text: "Fill in the Blank", type: "questionType" });
        case "order list":
                return UIRender({ icon: <i class="fas fa-sort-numeric-down"></i>, color: "cat-extra-blue", text: "Order list", type: "questionType" });
        case "document upload":
            return UIRender({ icon: <i class="fal fa-file-upload"></i>, color: "cat-purple", text: "Document upload", type: "questionType" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "questionType" });
    }
}

//only for question type
export const RenderQuestionTypeForm = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "multiple choice single answer":
            return UIRender({ icon: <i class="fal fa-tasks"></i>, color: "cat-yellow", text: "Multiple Choice Single Answer", type: "questionTypeForm" });
        case "multiple choice multiple answer":
            return UIRender({ icon: <i class="far fa-check-square"></i>, color: "cat-greenhex", text: "Multiple Choice Multiple Answer", type: "questionTypeForm" });
        case "match the column":
            return UIRender({ icon: <i class="far fa-line-columns" style="font-size: 24px;"></i>, color: "cat-light-pink", text: "Match the Column", type: "questionTypeForm" });
        case "short answer":
            return UIRender({ icon: <i class="far fa-comment-alt-lines"></i>, color: "cat-blue", text: "Short Answer", type: "questionTypeForm" });
        case "long answer":
            return UIRender({ icon: <i class="fal fa-align-left"></i>, color: "cat-red", text: "Long Answer", type: "questionTypeForm" });
        case "choice matrix":
            return UIRender({ icon: <i class="fal fa-table"></i>,  color: "cat-light-green", text: "Choice Matrix", type: "questionTypeForm" });
        case "fill in the blank":
            return UIRender({ icon: <i class="far fa-keyboard"></i>, color: "cat-dark-blue", text: "Fill in the Blank", type: "questionTypeForm" });
        case "order list":
                return UIRender({ icon: <i class="fas fa-sort-numeric-down"></i>, color: "cat-extra-blue", text: "Order list", type: "questionTypeForm" });
        case "document upload":
            return UIRender({ icon: <i class="fal fa-file-upload"></i>, color: "cat-purple", text: "Document upload", type: "questionTypeForm" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "questionTypeForm" });
    }
}
//only for Resource Management Campus
export const RenderResourceCampus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "centpre":
            return UIRender({ color: "cat-yellow", text: "Centurion, Pretoria", icon : "fa-university", type: "resourceCampus" });
        case "capetown":
            return UIRender({ color: "cat-greenhex", text: "Cape Town", icon : "fa-university", type: "resourceCampus" });
        case "midjohn":
            return UIRender({ color: "cat-skyblue", text: "Midrand", icon : "fa-university", type: "resourceCampus" });
        case "online":
            return UIRender({ color: "cat-blue", text: "Online", icon : "fa-wifi", type: "resourceCampus" });
        case "gdc":
            return UIRender({ color: "cat-brown", text: "Greenside", icon : "fa-university", type: "resourceCampus" });
        case "correspondence":
            return UIRender({ color: "cat-red", text: "Correspondence", icon : "fa-university", type: "resourceCampus" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "resourceCampus" });
    }
};

export const RenderSClassType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "on_campus":
            return UIRender({ color: "cat-blue", text: "On-campus", type: "classType" });
        case "hybrid":
            return UIRender({ color: "cat-yellow", text: "Hybrid", type: "classType" });
        case "bbb_class":
            return UIRender({ color: "cat-green", text: "Virtual", type: "classType" });
        default:
            return UIRender({ color: "black", text: "N/A", type: "classType" });
    }
};

// If any change is required then add condition on type, type will return which function called UIRender
const UIRender = ({ color, text, type, backgroundColorHex, colorHex, origin, icon }) => {
    if(type == "questionType"){
        return {
            color,
            text,
            icon,
            html: (
                <div className='d-flex'>
                    <span className={"cat istyle " + color }>{text !== "-" && <i className={icon?.props?.class +" "+ "mr-1"}></i>}</span>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                </div>
                // <span className={"cat " + color} title={text} icon={icon}>
                //     {text !== "-" && <i className={"fa fa-"+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                // </span>
            ),
        }
    }
    if(type == "questionTypeForm"){
        return {
            color,
            text,
            icon,
            html: (
                <div className='d-flex'>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                    <span className={"cat ml-2 istyle " + color }>{text !== "-" && <i className={icon?.props?.class +" "+ "mr-1"}></i>}</span>
                </div>
                // <span className={"cat " + color} title={text} icon={icon}>
                //     {text !== "-" && <i className={"fa fa-"+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                // </span>
            ),
        }
    }
    if(type == "brandList"){
        return {
            color,
            text,
            html: (
                <span className={"cat " + color} title={text}>
                    {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    if(type == "resourceCampus"){
        return {
            color,
            text,
            html: (
                <span className={"cat "+ color} title={text}>
                    {text !== "-" && <i className={"fas "+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    if (type == "department" || type == "category") {
        return {
            color,
            text,
            html: (
                <span className={"cat "} style={{ color, textTransform: "capitalize" }} title={text}>
                    {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    let bgColor = "", colorCode = "";
    if (type == "pmcStatus" || type == "quiz") {
        bgColor = backgroundColorHex;
        colorCode = colorHex
    }
    return {
        color,
        text,
        bgColor,
        colorCode,
        html: (
            <span className={"cat " + color} title={text}>
                {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
            </span>
        ),
    }
}

export const exportRegistrationStatus = (data) => {
    switch (data) {
        case "Re-Registration-Accounts":
            return "as-skyblue-bg as-widget";
        case "assign-subjects":
            return "as-red-bg as-widget";
        case "Closed-Ordered":
            return "as-red-bg as-widget";
        case "Closed-Ordered2":
            return "as-red-bg as-widget";
        case "Final-Status":
            return "as-yellow-bg as-widget";
        case "In-Active":
            return "as-yellow-bg as-widget";
        case "new-status":
            return "as-green-bg as-widget";
        case "Preliminary Re-Registration":
            return "as-green-bg as-widget";
        case "Re-Registration":
            return "as-yellow-bg as-widget";
        case "Re-Registration Cancelled":
            return "as-red-bg as-widget";
        case "Re-Registration Rejected":
            return "as-yellowshade-bg as-widget";
        case "Re-Registration-Accounts Rejected":
            return "as-green-bg as-widget";
        case "Re-Registration-Final":
            return "as-green-bg as-widget";
        case "Re-Registration-RCO":
            return "as-red-bg as-widget";
        case "Re-Registration-Sales":
            return "as-blue-bg as-widget";
        case "Re-Registration-Sales Admin":
            return "as-green-bg as-widget";
        case "Re-Registration-Sales Invoiced":
            return "as-red-bg as-widget";
        case "Re-Registration-Sales-Rejected":
            return "as-skyblue-bg as-widget";
        case "Re-Registration-Study Kit Pending":
            return "as-green-bg as-widget";
        case "Registration Withdrawn":
            return "as-yellowshade-bg as-widget";
        case "Rejected":
            return "as-blue-bg as-widget";
        case "Review":
            return "as-blue-bg as-widget";
    }
}

const CampusRender = ({color, text, icon, type}) =>{
    if(type=="dropdown"){
        return {
            color,
            text,
            html : (
                <span className='cat ' style={{color:color ? color : "#000"}} title={text}>
                    {(text!=="-" && text!=="") &&<i className={"fas "+ icon +" "+ "mr-1"}></i>} {text} 
                </span>
            )
        }
    }else{

    }
}

export const RenderCampusDropdown = (campus)=>{
    switch (campus.label){
        case "Online":
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-wifi", type:"dropdown"});
        case "online":
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-wifi", type:"dropdown"});
        default:
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-university", type:"dropdown"});
    }
}

// only for brand
export const RenderBrands = (label,color) => {
    return UIRender({ color: color, text: label, type: "brandList" });
}

export const RenderBrandList = (brand_name) => {
    switch (brand_name){
        case "Academic Institute of Excellence (AIE)":
            return UIRender({color : "cat-blue", text : brand_name, type:"brandList"});
        case "Emendy Multimedia Institute":
            return UIRender({color : "cat-red", text : brand_name, type:"brandList"});
        case "Greenside Design Center (GDC)":
            return UIRender({color : "cat-green", text: brand_name, type:"brandList"});
        case "LearnFast":
            return UIRender({color : "cat-orange", text: brand_name, type:"brandList"});
        case "The CAD Corporation":
            return UIRender({color : "cat-brown", text: brand_name, type:"brandList"});
        default:
            return UIRender({ color: "NA", text: "-", type: "brandList" });    
    }
}
 

// export const RenderBrandList = (data) => {
//     switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
//         case "1":
//             return UIRender({ color: "blue", text: "In Progress", type: "courseStatus" });
//         case "28":
//             return UIRender({ color: "red", text: "Past", type: "courseStatus" });
//         case "13":
//             return UIRender({ color: "green", text: "Upcoming", type: "courseStatus" });
//         case "24":
//             return UIRender({ color: "skyblue", text: "Today", type: "courseStatus" });
//         default:
//             return UIRender({ color: "black", text: data, type: "courseStatus" });
//     }
// };


// only for programme or course type
export const RenderCertificateType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "course":
            return UIRender({ color: "cat-orange", text: "Course", type: "certificate",origin });
        case "subject":
            return UIRender({ color: "cat-orange", text: "Course", type: "certificate",origin });
        case "qualification":
            return UIRender({ color: "cat-blue", text: "Programme", type: "certificate",origin });
        case "not_applicable":
            return UIRender({ color: "cat-red", text: "Not Applicable", type: "certificate",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "certificate" });
    }
};

// only for Course Resource type
export const RenderCourseResourceType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "assemblies":
            return UIRender({ color: "cat-orange", text: "Assemblies", type: "resourceType" });
        case "assembly":
            return UIRender({ color: "cat-orange", text: "Assembly", type: "resourceType" });
        case "assesment":
            return UIRender({ color: "cat-blue", text: "Assesment", type: "resourceType" });
        case "assigned_program":
            return UIRender({ color: "cat-red", text: "Assigned Program", type: "resourceType" });
        case "course":
            return UIRender({ color: "cat-green", text: "Course", type: "resourceType" });
        case "course gradebook setting":
            return UIRender({ color: "cat-skyblue", text: "Course Gradebook Setting", type: "resourceType" });
        case "notification":
            return UIRender({ color: "cat-yellow", text: "Notification", type: "resourceType" });
        case "onlineclass":
            return UIRender({ color: "cat-greenhex", text: "Online Class", type: "resourceType" });
        case "submission":
            return UIRender({ color: "cat-brown", text: "Submission", type: "resourceType" });
        default:
            return UIRender({ color: "NA", text: "-", type: "resourceType" });
    }
};

// only for Student Resource type
export const RenderStudentResourceType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "logout_and_login":
            return UIRender({ color: "cat-orange", text: "Logout and Login", type: "resourceType" });
        case "exams_registrations":
            return UIRender({ color: "cat-lightblue", text: "Exam Registration", type: "resourceType" });
        case "re_registrations":
            return UIRender({ color: "cat-blue", text: "Re-Registrations", type: "resourceType" });
        case "logged in":
            return UIRender({ color: "cat-red", text: "Logged in", type: "resourceType" });
        case "logged out":
            return UIRender({ color: "cat-green", text: "Logged out", type: "resourceType" });
        case "reregistration declined":
            return UIRender({ color: "cat-skyblue", text: "Re-Registration Declined", type: "resourceType" });
        case "not yet registered":
            return UIRender({ color: "cat-yellow", text: "Not Yet Registered", type: "resourceType" });
        case "reregistration new":
            return UIRender({ color: "cat-greenhex", text: "Re-Registration New", type: "resourceType" });
        case "reregistration declined":
            return UIRender({ color: "cat-brown", text: "Re-registration Declined", type: "resourceType" });
        case "assessment":
            return UIRender({ color: "cat-greenhex", text: "Assessment", type: "resourceType" });
        case "articulationregistration declined":
            return UIRender({ color: "cat-pink", text: "Articulation-registration Declined", type: "resourceType" });
        case "articulationregistration":
            return UIRender({ color: "cat-dark-red", text: "Articulation-Registration", type: "resourceType" });
        case "reffering to student hub resources":
            return UIRender({ color: "cat-yellowshade", text: "Referring to Student Hub resources", type: "resourceType" });
        case "notification":
            return UIRender({ color: "cat-purple", text: "Notification", type: "resourceType" });
        case "reregistration":
            return UIRender({ color: "cat-blueshade", text: "Re-Registration", type: "resourceType" });
        case "general_info":
            return UIRender({ color: "cat-pinkshade", text: "General Info", type: "resourceType" });
        case "personal_info":
            return UIRender({ color: "cat-blueshade", text: "Personal Info", type: "resourceType" });
        case "student_note":
            return UIRender({ color: "cat-purple", text: "Student Note", type: "resourceType" });
        case "courseandprogram":
            return UIRender({ color: "cat-yellowshade", text: "Course And Program", type: "resourceType" });
        case "account_payer_info":
            return UIRender({ color: "cat-dark-red", text: "Account Payer Info", type: "resourceType" });
        case "education_details":
            return UIRender({ color: "cat-pink", text: "Education Details", type: "resourceType" });
        case "certificates":
            return UIRender({ color: "cat-greenhex", text: "Certificates", type: "resourceType" });
        case "personal_doc":
            return UIRender({ color: "cat-brown", text: "Personal Document", type: "resourceType" });
        case "student":
            return UIRender({ color: "cat-yellow", text: "Student", type: "resourceType" });
        case "notifications":
            return UIRender({ color: "cat-skyblue", text: "Notifications", type: "resourceType" });
        case "confirmation_letter":
            return UIRender({ color: "cat-green", text: "Confirmation Letter", type: "resourceType" });
        case "metric_certificate":
            return UIRender({ color: "cat-blue", text: "Metric Certificate", type: "resourceType" });
        
        default:
            return UIRender({ color: "cat-black", text: data, type: "resourceType" });
    }
};
