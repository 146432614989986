import React from "react";
import Swal from "sweetalert2";

export const RenderAttachList = (props) => {
  const downloadFile = (filePath, fileName) => {
    fetch(filePath, { method: "HEAD", mode: "no-cors" })
      .then((response) => {
        const url = filePath;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error:", error.message);
        Swal.fire({ icon: "info", text: "File has not yet been processed by NetSuite, please try again in 5 minutes" });
      });
  };
  // console.log("props.docType",props.docType)

    const renderFileIcon = (docType) => {
        switch (docType && docType.split(".").reverse()[0]) {
            case 'docx':
                return 'fal fa-file-word';
            case 'zip':
                return 'fal fa-file-archive';
            case 'pdf':
                return 'fal fa-file-pdf';
            case 'xlsx':
                return 'fal fa-file-excel';
            case 'jpg':
            case 'jpeg':
            case 'png':
                return 'fal fa-file-image';
            case 'txt':
                return 'fal fa-file-alt';
            case 'pptx':
                return 'fal fa-file-powerpoint';
            default:
                return 'fal fa-file';
        }
    }

    return (<div className="curser uploaded-files" title={props.fileName} onClick={() => downloadFile(props.filePath, props.fileName)}>
        <div className="uploded-files-item">
            <div className="uploded-files-item-txt" >
                <figure>
                    <i className={renderFileIcon(props.fileName)}></i>
                </figure>
                <figcaption >
                    <h4 className="oneline-ellipsis">{props.fileName}</h4>
                    <p>{props?.size}</p>
                </figcaption>
            </div>

            {props.showClose ?
                <button onClick={() => { props.removeF(props) }} type="button" className="btn file-remove-btn"><i className="fal fa-times"></i></button>
                :
                <span href={props.filePath.replaceAll("/home/myaie/public_html/", "")} target='_blank' className="btn file-remove-btn rm-box-shadow"><i className="fal fa-download"></i></span>
            }
        </div>
    </div>
    )
}