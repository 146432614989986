import React, { useEffect, useState, useMemo } from "react";
import {
  GetListOfRole,
  GetUserDetail,
  UpdateUserDetail,
  GetListOfUser,
} from "../../../services/UserService";
import {
  ProfileImageDetailService,
  RemoveProfileImage,
} from "../../../services/ProfileService";
import { useFormik } from "formik";
import { MOBILE_REGEX, NAME_REGEX, IMAGE_URL } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
// import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import {
  AddStudentTabListData,
  GetListOfStudentHubBrandList,
  GetStudentTabListDetail,
  EditStudentTabListData,
  GetListOfStudentHubTabList,
  GetListOfStudentHubCategories,
  DeleteCategoryList,
  GetStudentHubTabDetail,
  reArrangeStudentHubCategories,
  unlinkResource
} from "../../../services/StudentService";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import { Link, useLocation } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { getCurrentTimeHomePage } from "../../common/Helper";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import { TrimText } from "../../common/TrimText";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../../utils/ReArrangeFunction";
import AddResource from "./AddResource";

const TabDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [sectionId, setSectionId] = useState();
  const [tabDetail, setTabDetail] = useState();
  const [sectionData, setSectionData] = useState([]);
  const [linkedBrandList, setLinkedBrandList] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedDomainIds, setSelectedDomainIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);

  const getTabDetail = () => {
    GetStudentHubTabDetail({ tab_id: id })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.tabData;
          setTabDetail(data);
          setSectionData(data?.Category);
          if (data.domain) {
            setSelectedDomain(data?.domain.split("___"));
          }
          if (data.domainId) {
            setSelectedDomainIds(data?.domainId.split("___"));
          }
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  const getBrandListData = () => {
    GetListOfStudentHubBrandList().then((res) => {
      let x = {
        value: "",
        label: "",
      };
      x = res.data?.brand_setting_list.map((data) => {
        return {
          ...x,
          value: `${data.id}`,
          label: `${data.brand_name}`,
          // id: `${data.id}`
        };
      });
      //   setUserListData(x);
      setLinkedBrandList(x);
    });
  }

  useEffect(() => {
    getTabDetail()
    // getSectionListData()
    getBrandListData()
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tab_name: tabDetail?.tab_name,
      visible: tabDetail?.visible && tabDetail?.visible == 1 ? true : false,
      icon: tabDetail?.icon,
      domain: selectedDomain,
      domainId: selectedDomainIds,
    },
    validationSchema: Yup.object({
      tab_name: Yup.string().required("Tab Name is required"),
      visible: Yup.string().required("Visible is required"),
      icon: Yup.string().required("Icon is required"),
      domain: Yup.array().min(1, "atleast one domain is required").required(),
    }),
    onSubmit: (values) => {
      values.visible = values.visible == true ? 1 : 0;
      values = { ...values, id: id }
      EditStudentTabListData(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
        });
    },
  });

  const HandleChangeDomain = (data) => {
    const domainSelected = data?.map((obj) => {
      return obj.label
    });
    const domainSelectedIds = data?.map((obj) => {
      return obj.value
    });
    // setSelectedDomain(domainSelected);
    // setSelectedDomainIds(domainSelectedIds);
    formik.setFieldValue("domain", domainSelected);
    formik.setFieldValue("domainId", domainSelectedIds);
  };

  const handleDelete = (ID, index) => {
    // console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCategoryList({ categoryId: ID }).then((res) => {
          // setDeleteRow(!deleterow)
          if (res.status === 200) {
            Swal.fire("Deleted!", res.data.message, "success").then(() => {
              const sectionList = [...sectionData];
              sectionList.splice(index, 1)
              setSectionData([...sectionList])
            });
          }
        });

      }
    });
  };

  const handleSectionDrag = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderData = reorder(sectionData, result.source.index, result.destination.index)
    setSectionData([...reorderData])
    // console.log(result);
  }

  const handleResourceDrag = (result, idx) => {
    if (!result.destination) {
      return;
    }

    const dataList = [...sectionData];
    const dataItem = { ...dataList[idx] };
    const resourceList = dataList[idx].RelCategories;

    dataItem.RelCategories = reorder(resourceList, result.source.index, result.destination.index);
    dataList[idx] = dataItem;
    setSectionData(dataList)

  }

  const handleSaveData = () => {
    try {
      setDisable(true);
      const sendData = {
        dataSequence: {
          tab_id: id,
          detail: sectionData.map((section) => {
            return {
              cat_id: section.categoryId,
              resource: section.RelCategories.map((resource) => {
                return resource.resourcesId
              })
            }
          })
        }
      };
      reArrangeStudentHubCategories(sendData).then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then(() => {
            setDisable(false);
          });
        }
      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      })
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnlinkResource = (data) => {
    const param = { section_id: data.categoryId, resource_id: data.resourcesId };

    unlinkResource(param).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => {
          getTabDetail()
        });
      }
    }).catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.data}`,
      });
    })
  }

  const [renderResource, setRenderResource] = useState(false);

  return (
    <>
      {renderResource && <AddResource show={renderResource} sectionId={sectionId} refreshList={() => { getTabDetail(); }} onClose={() => setRenderResource(false)} />}
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">Tab: <span>{tabDetail?.tab_name}</span></h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs">
                    <li className="nav-item">
                      <Link to={`/support/student-hub-library/student-hub/open/${id}`} className="nav-link active" id="pills-tab1" >
                        <i className="fal fa-info-circle"></i>
                        Details
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={{ pathname: `/support/student-hub-library/student-hub/open/${id}/student-audit-trail`, state: { tabName: tabDetail?.tab_name } }} className="nav-link " id="pills-tab2" >
                        <i className="fal fa-history"></i>Audit trail
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      <div className="card card-profile-info-card stl-tab-card">
                        <div className="card-body-inr card-body-info">
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                              <div className="col-md-6 col-lg-3">
                                <div className="form-group" >
                                  <label>Tab Name*</label>
                                  <div className="form-icon-group mb-4">
                                    <input type="text" className={"form-control" + (formik.errors.tab_name && formik.touched.tab_name ? " is-invalid" : "")} name="tab_name" placeholder="Tab Name" title="Tab Name" value={formik.values.tab_name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.errors.tab_name && formik.touched.tab_name ? (
                                      <span className="exclamation">
                                        <i className="fal fa-exclamation-circle"></i>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3">

                                <div className="form-group" >
                                  <label>Brand Name*</label>
                                  <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                      <Select className={"form-control custom-select-box" + (formik.errors.domain && formik.touched.domain ? " is-invalid" : "")} name="domain" value={linkedBrandList.filter((obj) => formik.values.domainId && formik.values.domainId.includes(obj.value))} onChange={(value) => { HandleChangeDomain(value) }} onBlur={formik.handleBlur} options={linkedBrandList} maxMenuHeight={175} isMulti={true} />
                                      {formik.errors.domain && formik.touched.domain ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3">
                                <div className="form-group" >
                                  <label>Icon*</label>
                                  <div className="exclamation-tooltip-blk form-icon-group mb-4">
                                    <input type="text" className={"form-control" + (formik.errors.icon && formik.touched.icon ? " is-invalid" : "")} name="icon" placeholder="eg: fal fa-cog" title="Icon" value={formik.values.icon} onChange={formik.handleChange} onBlur={formik.handleBlur} />

                                    <div className="exclamation-tooltip">
                                      <i className="fal fa-info-circle"></i>
                                      <div className="exclamation-tooltip-content">
                                        To see available options, please go to <a href="https://fontawesome.com/icons/" target="_blank">https://fontawesome.com/icons/</a> and copy code from there
                                      </div>
                                    </div>
                                    {formik.errors.icon && formik.touched.icon ? (
                                      <span className="exclamation">
                                        <i className="fal fa-exclamation-circle"></i>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                  <label></label>
                                  <div className="custom-control custom-checkbox text-left mt-3">
                                    <input type="checkbox" className="custom-control-input" id="visible" name="visible" checked={formik.values.visible} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <label className="custom-control-label" htmlFor="visible">
                                      Published
                                    </label>
                                  </div>
                                  {formik.errors.visible && formik.touched.visible ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                            <div className="form-group-save-cancel">
                              <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!formik.dirty}>
                                  <i className="fal fa-save"></i>
                                  Save
                                </button>
                              </PermissionsGate>
                              <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                <i className="fal fa-times"></i>
                                Cancel
                              </button>
                            </div>
                            <div className="form-group form-group-save-cancel ml-new">
                              {formik.touched.tab_name && formik.errors.tab_name ? (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.tab_name}
                                </div>
                              ) : null}
                              {formik.touched.visible && formik.errors.visible ? (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.visible}
                                </div>
                              ) : null}
                              {formik.touched.icon && formik.errors.icon ? (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.icon}
                                </div>
                              ) : null}
                              {formik.touched.domain && formik.errors.domain ? (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.domain}
                                </div>
                              ) : null}
                            </div>
                          </form>
                        </div>
                        <hr />
                        <div className="card-body-inr card-body-info">
                          <div className="section-top d-flex">
                            <h5 className="heading">Sections</h5>
                            <div className="add-ticket-blk button-reset dropdown-comman">
                              <PermissionsGate scopes={["stuhmsadd"]} RenderError={() => (
                                <button disabled className="btn btn-primary ml-3" title="Add Section">
                                  <i className="fal fa-plus"></i>Add Section
                                </button>
                              )}>
                                <Link to={`/support/student-hub-library/student-hub/${id}/section/create/new`}>
                                  <button className="btn btn-primary ml-3" title="Add Section">
                                    <i className="fal fa-plus"></i>Add Section
                                  </button>
                                </Link>
                              </PermissionsGate>
                            </div>
                          </div>
                          <div className="section-content-container">
                            <DragDropContext onDragEnd={(result) => { handleSectionDrag(result) }}>
                              <Droppable droppableId="section-droppable" direction="vertical">
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {(Array.isArray(sectionData) && !loading) ? sectionData.map((section, idx) => (
                                      <Draggable key={section?.categoryId} draggableId={"section" + section?.categoryId} index={idx}>
                                        {(provided, snapshot) => (
                                          <>
                                            <div ref={provided.innerRef}  {...provided.draggableProps} className={"resource-container " + (snapshot.isDragging ? " stl-hub-dragging" : "")}>
                                              <div className="student-section-head">
                                                <h3 title={section.categoryName}>
                                                  {TrimText(section.categoryName)}
                                                </h3>
                                                <ul className="stl-action-list">
                                                  <li>
                                                    <PermissionsGate scopes={["stuhmsedit"]} RenderError={() => (
                                                      <button disabled title="Edit Section" className="btn btn-primary stl-action-btn">
                                                        <i className="far fa-edit"></i>
                                                      </button>
                                                    )}>
                                                      <Link to={`/support/student-hub-library/student-hub/${id}/section/open/${section?.categoryId}`}>
                                                        <button title="Edit Section" className="btn btn-primary stl-action-btn">
                                                          <i className="far fa-edit"></i>
                                                        </button>
                                                      </Link>
                                                    </PermissionsGate>
                                                  </li>
                                                  <PermissionsGate scopes={["stuhmsreregedit"]} RenderError={() => (
                                                    <button disabled title="Hold & Drag" className="btn btn-primary stl-action-btn">
                                                      <i className="far fa-random"></i>
                                                    </button>
                                                  )}>
                                                    <div title="Hold & Drag" className="btn btn-primary stl-action-btn" {...provided.dragHandleProps} style={{ cursor: "grab" }}>
                                                      <i className="far fa-random"></i>
                                                    </div>
                                                  </PermissionsGate>
                                                  <li>
                                                    <PermissionsGate scopes={["stuhmsdelete"]} errorProps={{ disabled: true }}>
                                                      <button title="Delete Section" onClick={() => { handleDelete(section.categoryId, idx) }} className="btn btn-danger stl-action-btn">
                                                        <i className="far fa-trash-alt"></i>
                                                      </button>
                                                    </PermissionsGate>
                                                  </li>
                                                </ul>
                                              </div>
                                              <DragDropContext onDragEnd={(result) => { handleResourceDrag(result, idx) }}>
                                                <Droppable droppableId="resource-droppable" direction="horizontal">
                                                  {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps} className="student-section-body">
                                                      {section?.RelCategories.map((resource, i) => (resource.Resources !=null ?
                                                        <Draggable key={resource?.id} draggableId={"res" + resource?.id} index={i}>
                                                          {(provided, snapshot) => (
                                                            <div ref={provided.innerRef}  {...provided.draggableProps} className={"resource-item " + (snapshot.isDragging ? " stl-hub-dragging" : "")}>
                                                              <PermissionsGate scopes={["stuhmsreregedit"]} RenderError={() => (
                                                                <div className="resource-item-left">
                                                                  <div className={"resource-icon " + (resource.Resources.isPublished === 0 ? "not-published" : "")}>
                                                                    {((resource?.Resources?.type).toString().toLowerCase() === "youtube"
                                                                      || (resource?.Resources?.type).toString().toLowerCase() === "vimeo")
                                                                      ? <i class="fal fa-video"></i>
                                                                      : (resource?.Resources?.type).toString().toLowerCase() === "label"
                                                                        ? <i className="fal fa-tag"></i>
                                                                        : (resource?.Resources?.type).toString().toLowerCase() === "article"
                                                                          ? <i className="fal fa-newspaper"></i>
                                                                          : (resource?.Resources?.type).toString().toLowerCase() === "link"
                                                                            ? <i className="fal fa-external-link"></i>
                                                                            : <i className="fal fa-file-pdf"></i>}
                                                                  </div>
                                                                  <div className="resource-title">
                                                                    <h6 className="h-6" title={resource?.Resources?.title}>{TrimText(resource?.Resources?.title)}</h6>
                                                                    <p> {((resource?.Resources?.type).toString().toLowerCase() === "youtube" || (resource?.Resources?.type).toString().toLowerCase() === "vimeo") ? <i className="far fa-clock"></i> : <i className="far fa-file"></i>} {resource?.Resources?.duration ? resource?.Resources?.duration : "-"}</p>
                                                                  </div>
                                                                </div>
                                                              )}>
                                                                <div {...provided.dragHandleProps} className="resource-item-left">
                                                                  <div title="Hold & Drag" className={"resource-icon " + (resource.Resources.isPublished === 0 ? "not-published" : "")}>
                                                                    {((resource?.Resources?.type).toString().toLowerCase() === "youtube"
                                                                      || (resource?.Resources?.type).toString().toLowerCase() === "vimeo")
                                                                      ? <i class="fal fa-video"></i>
                                                                      : (resource?.Resources?.type).toString().toLowerCase() === "label"
                                                                        ? <i className="fal fa-tag"></i>
                                                                        : (resource?.Resources?.type).toString().toLowerCase() === "article"
                                                                          ? <i className="fal fa-newspaper"></i>
                                                                          : (resource?.Resources?.type).toString().toLowerCase() === "link"
                                                                            ? <i className="fal fa-external-link"></i>
                                                                            : <i className="fal fa-file-pdf"></i>}
                                                                  </div>
                                                                  <div className="resource-title">
                                                                    <h6 className="h-6" title={resource?.Resources?.title}>{TrimText(resource?.Resources?.title)}</h6>
                                                                    <p> {((resource?.Resources?.type).toString().toLowerCase() === "youtube" || (resource?.Resources?.type).toString().toLowerCase() === "vimeo") ? <i className="far fa-clock"></i> : <i className="far fa-file"></i>} {resource?.Resources?.duration ? resource?.Resources?.duration : "-"}</p>
                                                                  </div>
                                                                </div>
                                                              </PermissionsGate>
                                                              <div className="resource-item-right">
                                                                <div className="resource-action">
                                                                  <PermissionsGate scopes={["stuhmsrview"]} RenderError={() => (
                                                                    <button disabled title="View Resource" className="btn btn-primary stl-action-btn" >
                                                                      <i className="fal fa-eye"></i>
                                                                    </button>
                                                                  )}>
                                                                    <Link to={`/support/student-hub-library/resource-management/edit/${resource.resourcesId}/details/digital`}>
                                                                      <button title="View Resource" className="btn btn-primary stl-action-btn" >
                                                                        <i className="fal fa-eye"></i>
                                                                      </button>
                                                                    </Link>
                                                                  </PermissionsGate>
                                                                  <PermissionsGate scopes={["stuhmsrdelete"]} errorProps={{ disabled: true }}>
                                                                    <button title="Unlink Resource" onClick={() => { handleUnlinkResource(resource) }} className="btn btn-danger stl-action-btn">
                                                                      <i className="fal fa-unlink"></i>
                                                                    </button>
                                                                  </PermissionsGate>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      :<></>))}
                                                    </div>
                                                  )}
                                                </Droppable>
                                              </DragDropContext>
                                              <div className="section-bottom mt-3">
                                                {/* <Link to={`/support/student-hub-library/student-hub/${id}/section/${section.categoryId}/resource/create`}>
                                                  </Link> */}
                                                <PermissionsGate errorProps={{ disabled: true }} scopes={["stuhmsradd"]}>
                                                  <button onClick={() => { setSectionId(section?.categoryId); setRenderResource(true) }} className="btn btn-primary" title="Add Resource">
                                                    <i className="fal fa-plus"></i>Add Resource
                                                  </button>
                                                </PermissionsGate>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </Draggable>
                                    )) : <SkeletonTicketList />}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                            {sectionData.length > 0 && <div className="form-group-save-cancel">
                              <PermissionsGate errorProps={{ disabled: true }} scopes={["stuhmsreregedit"]}>
                                <button className="btn btn-save btn-success" type="button" title="Save" onClick={() => { handleSaveData() }}>

                                  {disable === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                                  Save
                                </button>
                              </PermissionsGate>

                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabDetail;
