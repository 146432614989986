import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  ColorCode,
  ColorRender,
} from "../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  GetStudentList,
  StatmentIndividual,
} from "../../../services/CourseService";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  IMAGE_URL,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import PermissionsGate from "../../../utils/permissionGate";
import Hover from "../../common/Hover";
import { TrimText } from "../../common/TrimText";
import Tablefilter from "../../common/Tablefilter";
import {
  RenderCourseSStatus,
  RenderCourseStatus,
  RenderProgrammeStudentStatus,
  RenderQuizAttemptResultStatus,
} from "../../../utils/CommonStatusItems";
import BadgeIcon from "./../../../assets/images/badge-with-a-star-s.png";
import InProgress from "./../../../assets/images/In-Progress.png";
import PendingReview from "./../../../assets/images/Pending-Review.png";
import NotStarted from "./../../../assets/images/Not-Started.png";
import { exportFile } from "../../../utils/ExportData";
import {
  RenderAttendanceHealth,
  RenderLearningMethodGradebook,
} from "../../../utils/CommonGroupingItem";
import $ from "jquery";
import Swal from "sweetalert2";
import { InsertSignOff } from "../../../services/DashBoardServices";
import moment from "moment";
import {
  getCourseGradebook,
  getStudentBrand,
  getStudentGradeViaCourse,
} from "../../../services/GradeSettingService";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import LetterheadIntakePDF from "../../../LetterheadIntakePDF";
import { pdf } from "@react-pdf/renderer";
import { handleTableScroll } from "../../../utils/commonFunction";

const Students = (props) => {
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [attendanceHealth, setAttendanceHealth] = useState({
    arr: [],
    checkObj: {},
  });
  const [coursestatus, setCourseStatus] = useState({ arr: [], checkObj: {} });
  const [studentList, setStudentList] = useState([]);
  const [isStudents, setIsStudents] = useState(false);
  const [filterData, setFilterData] = useState({
    status: [],
    attendance: [],
    cStatus: [],
  });
  const [filterCheck, setFilterCheck] = useState("true");
  const [signOffData, setSignOffData] = useState({});
  const [validation, setValidation] = useState(false);
  const notificationInitialState = {
    class_id: "",
    subject: "",
    message: "",
    attachment: {},
    class_name: "",
  };
  const [notificationFields, setNotificationFields] = useState(
    notificationInitialState
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [bulkPdfDownloading, setBulkPdfDownloading] = useState(false);
  const [pdfData, setPDFData] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [studentID, setStudentID] = useState("");
  const [bulkDownload, setBulkDownload] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [courseDetail, setCourseDetail] = useState({});
  const [pdfsData, setPDFsData] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [isStudents])

  useEffect(() => {
    getSutdentData();
  }, []);

  // useEffect(() => {
  //   // setLoading(true);
  //   let data = {
  //     intake_id: id,
  //     // student_id: stdId,
  //     exportStatus: "false",
  //   };
  //   getStudentGradeViaCourse(data)
  //     .then((res) => {
  //       let details = res.data?.data;
  //       console.log(details);

  //       // setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error <<<<<< : ", error);
  //       // setLoading(false);
  //     });
  // }, [id]);

  const getSutdentData = () => {
    let data = filterCheck;
    GetStudentList(props.userId, data)
      .then((data) => {
        let allStdIds = [];
        let stdIds = data.data.studentList.map((std) => {
          allStdIds.push(std?.id);
        });
        setAllStudents(allStdIds);
        setStudentList([]);
        setStudentList(
          data && data.data && data.data.studentList
            ? data.data.studentList
            : []
        );
        if (filterCheck == "true") {
          setFilterData({
            ...data.data,
            status: data.data.statusTypeFilter,
            attendance: data.data.attendanceTypeFilter,
            cStatus: data.data.courseTypeFilter,
          });
          setFilterCheck("false");
        }
        setIsStudents(true);
      })
      .catch((error) => {
        console.log("<>><>< ", error);
        setIsStudents(true);
      });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setAttendanceHealth({ arr: [], checkObj: {} });
    setCourseStatus({ arr: [], checkObj: {} });
  };

  const dataToRender = () => {
    // console.log("data to render ---> ", status);
    let updatedData = [];
    let allData = studentList;
    if (search.length) {
      let tempStudent = allData.filter((item) => {
        let includes =
          item.first_name &&
          item.first_name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes = item.email
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempStudent, ...tempEmail, ...tempStudentNo];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
            item.status.toString().toLowerCase()
              ? item.status.toString().toLowerCase()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (attendanceHealth.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = attendanceHealth.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
              item.attendanceStatus.toString().toLowerCase() &&
            item.attendanceStatus.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (coursestatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = coursestatus.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
              item.subject_status.toString().toLowerCase() &&
            item.subject_status.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (
      search.length ||
      status.arr.length ||
      attendanceHealth.arr.length ||
      coursestatus.arr.length
    ) {
      return updatedData;
    } else {
      return studentList;
    }
  };
  useEffect(() => {
    setBulkPdfDownloading(true);
    const data = dataToRender();
    var filteredIds = [];
    const filterArray = data?.map((std) => {
      filteredIds.push(std?.id);
    });
    const Values = {
      intake_id: id,
      exportStatus: "true",
      is_student: filteredIds.length ? 1 : "",
      students: filteredIds?.length ? filteredIds : "",
    };
    getCourseGradebook(Values).then((res) => {
      const details = res?.data?.result;
      const courseData = res?.data?.getCourse;
      setCourseDetail(courseData);
      let assessments = details?.map((student) => ({
        ...student,
        assessments: student?.assessmentArr?.map((assessment) => ({
          id: student?.student_id,
          name: assessment?.assessment_name,
          weight: assessment?.grant_exemption
            ? "Exempted"
            : assessment.ass_weighting,
          result: assessment?.grant_exemption
            ? "Exempted"
            : assessment?.submited == "0" ||
              moment.unix(assessment?.close_date_time) > moment.unix(new Date())
            ? "N/A"
            : assessment.pass_status == "N/A"
            ? "N/A"
            : assessment.pass_status
            ? assessment.pass_status
            : "-",
          grade: assessment?.grant_exemption
            ? "Exempted"
            : assessment?.submited == "0" ||
              moment.unix(assessment?.close_date_time) > moment.unix(new Date())
            ? "N/A"
            : assessment?.grading_type == 3
            ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
            : assessment?.gradeText
            ? assessment?.gradeText
            : "N/A",
          isExclude: assessment?.isExclude,
          final_status: student?.pass_status,
          final_grade:
            student?.grading_type == 0
              ? Math.round(student?.scoreRecieved) + "%"
              : student?.grading_type == 3
              ? `${student?.getAssessmentCalculate_sum_ass_mark}/${student?.getAssessmentCalculate_sum_ass_total_mark}`
              : student?.gradeText,
        })),
        studentDetails: {
          course_name: courseData?.name,
          Student_name: student.full_name,
          ID_number: student?.number,
          Student_number: student?.student_crm_id
            ? student?.student_crm_id
            : student?.student_num,
          start: courseData?.start
            ? courseData.start?.toString().length == 10
              ? moment.unix(courseData?.start).format("DD MMMM YYYY")
              : moment(courseData?.start).format("DD MMMM YYYY")
            : "",
          end: courseData?.end
            ? courseData.end?.toString().length == 10
              ? moment.unix(courseData?.end).format("DD MMMM YYYY")
              : moment(courseData?.end).format("DD MMMM YYYY")
            : "",
          intake:
            courseData?.number && courseData?.year
              ? `${courseData?.number}-${courseData?.year}`
              : "",
          learning_method: courseData?.type
            ? RenderLearningMethodGradebook(courseData?.type).text
            : "",
        },
      }));
      setPDFsData(assessments);
      setBulkPdfDownloading(false);
    });
  }, [status, attendanceHealth, coursestatus]);

  const handlepdfData = (subTab) => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: "Downloading PDF",
      text: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    let pdfDetails = pdfsData?.length && pdfsData?.find((student) => student?.student_id == subTab);
    let brand = {};
    const data = {
      id: [JSON.stringify(subTab)],
    };
    getStudentBrand(data)
      .then((res) => {
        brand = res?.data;
        if (pdfDetails) {
          let modifiedSoR = pdfDetails.assessments?.length && pdfDetails.assessments
            ?.map((item) => ({
              ...item,
              final_grade: !isShowFinal ? "" : item?.final_grade,
              final_status: !isShowFinal ? "" : item?.final_status,
            }))
            .filter((assessment) => !isExcludeGrade || !assessment.isExclude);
          pdf(
            <LetterheadIntakePDF
              detailData={modifiedSoR}
              studentAndCourseDetails={pdfDetails?.studentDetails}
              brandData={brand?.getBrandDetail}
            />
          )
            .toBlob()
            .then((response) => {
              const blobURL = window.URL.createObjectURL(response);
              const a = document.createElement("a");
              a.href = blobURL;
              a.download = `${
                pdfDetails?.studentDetails?.Student_number
              }_${pdfDetails?.studentDetails.Student_name}_${
                pdfDetails?.studentDetails?.course_name
              }_SOR_${brandVisuals?.brand_name}_${moment(new Date()).format(
                "DD MM YY"
              )}.pdf`; // Set the desired filename for the download
              a.click();

              URL.revokeObjectURL(blobURL);
              setStudentID("");
              setIsChecked(false);
              setIsShowFinal(false);
              setIsExcludeGrade(false);
              swalPro.close();
              $("#downloadSoRModal").modal("hide");
              setPdfDownloading(false);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Download Successfully",
              });
            })
            .catch((err) => {
              setPdfDownloading(false);
              setStudentID("");
              setIsChecked(false);
              setIsShowFinal(false);
              setIsExcludeGrade(false);
              swalPro.close();
              $("#downloadSoRModal").modal("hide");
              Swal.fire({
                icon: "error",
                title: "Error",
                text: err,
              });
            });
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        name: "Student",
        selector: "first_name",
        // selector: row => `${row.first_name} ${row.last_name}`,
        sortable: true,
        cell: (row, index) => (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender("student support")
                }
              >
                {row.photo ? (
                  <img
                    src={`${IMAGE_URL}/${row.photo
                      .replaceAll("/home/myaie/public_html/", "")
                      .replace("public/", "")}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([row.first_name, row.last_name])
                )}
                <span
                  className={`profile-box-2-status ${
                    row.activity_status
                      ? row.activity_status.toLowerCase() == "online"
                        ? "Online"
                        : row.activity_status.toLowerCase() == "away"
                        ? "Away"
                        : "Offline"
                      : "Offline"
                  }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row.first_name}
                  lastName={row.last_name}
                  photo={row.photo}
                  email={row.email}
                  mobile={row.mobile}
                  right={true}
                  role={"student support"}
                  status={row.status}
                  activity_status={row.activity_status}
                  showNumber={true}
                  number={row.student_crm_id}
                />
              </span>
              <p>
                <Link
                  className="as-text-blue curser feature-name"
                  key={index}
                  to={{
                    pathname: `/studentAdministration/students/open/${row.student_id}/general`,
                  }}
                >
                  <span
                    className="textLimit100"
                    title={`${row.first_name} ${row.last_name}`}
                  >
                    {" "}
                    {`${row.first_name} ${row.last_name}`}
                  </span>
                </Link>
              </p>
            </div>
          </div>
        ),
      },
      {
        name: "myAIE Email",
        selector: "email",
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <span
            className="feature-name"
            title={
              row.microsoft_status == 1 && row.microsoft_email
                ? row.microsoft_email
                : "-"
            }
          >
            <span className="textLimit100">
              {row.microsoft_status == 1 && row.microsoft_email
                ? row.microsoft_email
                : "-"}
            </span>
          </span>
        ),
      },
      {
        name: "Email",
        selector: "email",
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <span className="feature-name" title={row.email}>
            <span className="textLimit100">{row.email}</span>
          </span>
        ),
      },
      {
        name: "Student Number",
        selector: "student_crm_id",
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <p>
            <span>{row?.student_crm_id ? row?.student_crm_id : "-"}</span>
          </p>
        ),
      },
      {
        name: "Attendance Health",
        selector: "attendanceStatus",
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.attendanceStatus
            ? RenderAttendanceHealth(row.attendanceStatus).html
            : "-",
      },
      {
        name: "Status",
        selector: "status",
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.status ? RenderProgrammeStudentStatus(row.status).html : "-",
      },
      {
        name: "Grade",
        selector: "grade",
        // reorder: true,
        sortable: false,
        cell: (row) =>
          row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
            ? "N/A"
            : row.calculateGradeOfStudentViaCourse &&
              row.calculateGradeOfStudentViaCourse?.allAss_ass_mark &&
              row.calculateGradeOfStudentViaCourse?.allAss_total_mark &&
              row.calculateGradeOfStudentViaCourse?.grading_type == 3
            ? `${row.calculateGradeOfStudentViaCourse?.allAss_ass_mark}/${row.calculateGradeOfStudentViaCourse?.allAss_total_mark}`
            : row.calculateGradeOfStudentViaCourse &&
              row.calculateGradeOfStudentViaCourse?.gradeText
            ? row.calculateGradeOfStudentViaCourse?.gradeText
            : "-",
      },
      {
        name: "Result",
        selector: "status",
        sortable: true,
        cell: (row) =>
          row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
            ? "N/A"
            : row.calculateGradeOfStudentViaCourse &&
              row.calculateGradeOfStudentViaCourse?.pass_status
            ? RenderQuizAttemptResultStatus(
                row.calculateGradeOfStudentViaCourse?.pass_status
              ).html
            : "-",
      },
      {
        name: "Course Status",
        selector: "subject_status",
        // reorder: true,
        sortable: true,
        cell: (row) => (
          // row.subject_status ? row.subject_status : "-"
          <>
            <span
              className={
                "mentored-subject-status "
                // (row.subject_status == "PENDING-SIGN OFF" ? "d-block" : "")
              }
            >
              <span className="sing_ard_badge">
                {row.subject_status == "PENDING-SIGN OFF" ? (
                  <img
                    src={BadgeIcon}
                    alt="PENDING-SIGN OFF"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status.toUpperCase() == "NOT STARTED" ? (
                  <img
                    src={NotStarted}
                    alt="NOT STARTED"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == "IN-PROGRESS" ? (
                  <img
                    src={InProgress}
                    alt="IN-PROGRESS"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == "FINISHED" ? (
                  <img
                    src={`https://www.myaie.ac/theme/img/icon-complete.png`}
                    alt="FINISHED"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == "Pending Review" ? (
                  <img
                    src={PendingReview}
                    alt="Pending Review"
                    style={{ height: 24 }}
                  ></img>
                ) : (
                  <></>
                )}
              </span>
              <span title={row.subject_status}>
                {row && row.subject_status
                  ? ColorCode(row.subject_status.toUpperCase())
                  : "-"}
              </span>
            </span>
            <p className="sing_ard_badge">
              {row.completed_date && (
                <>
                  {row.subject_status == "Pending-sign off" ||
                  row.subject_status == "Pending Review" ||
                  row.subject_status == "PENDING-SIGN OFF" ||
                  row.subject_status == "Pending-sigh off"
                    ? "Requested on"
                    : row.subject_status == "FINISHED"
                    ? "Completed on"
                    : ""}
                  {` ${moment
                    .unix(Number(row.completed_date))
                    .format("DD MMM 'YY")}`}
                  <br /> at{" "}
                  {moment
                    .unix(Number(row.completed_date))
                    .format(TABLE_TIME_FORMAT)}
                </>
              )}
              {row.subject_status &&
                (row.subject_status == "Pending-sign off" ||
                  row.subject_status == "Pending Review" ||
                  row.subject_status == "PENDING-SIGN OFF" ||
                  row.subject_status == "Pending-sigh off") && (
                  <button
                    className="btn btn-save btn-success ml-2"
                    type="button"
                    title="Sign-Off"
                    data-toggle="modal"
                    data-target="#sign-off"
                    onClick={() => {
                      setSignOffData(row);
                    }}
                  >
                    Sign-Off
                  </button>
                )}
            </p>
          </>
        ),
      },
      {
        name: "Dates",
        selector: "subject_Dates",
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.subject_Dates ? (
            <span className="feature-name">
              <span className="textLimit100">
                {row.subject_status.toUpperCase() === "NOT STARTED" &&
                row.type === "selfplaced"
                  ? "N/A"
                  : row.subject_Dates}
              </span>
            </span>
          ) : (
            "-"
          ),
      },
      // {
      //   name: "Course Status",
      //   selector: "CourseStatus",
      //   // reorder: true,
      //   sortable: true,
      //   cell: (row) =>row.courseStatus ? RenderCourseStatus(row.courseStatus).html : "-"
      // },
      {
        name: "Actions",
        selector: "Action",
        // sortable: true,
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <Link
                to={{
                  pathname: `/studentAdministration/students/open/${row.student_id}/general`,
                }}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
              <div className="dropdown btn-dropdown-item">
                <button
                  className="btn btn-primary rounded-circle dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <PermissionsGate
                    scopes={["cedit"]}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Create Notification"
                      onClick={() =>
                        history.push(
                          `/courseAdministration/coursesdetails/${props.userId}/notifications/create/student/${row.student_id}`
                        )
                      }
                    >
                      <i className="fa fa-paper-plane"></i>
                    </button>
                  </PermissionsGate>
                  <button
                    className="btn btn-primary rounded-circle"
                    onClick={() =>
                      exportFile(
                        "csv",
                        "Student Statement",
                        row.student_id,
                        "courseStudent",
                        id
                      )
                    }
                    title="Statement"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <Link
                    to={{
                      pathname:
                        "https://student.myaie.ac/viewStudent?email=" +
                        row.email +
                        "&password=" +
                        row.password,
                    }}
                    target="_blank"
                  >
                    <button
                      class="btn btn-sm btn-primary rounded-circle"
                      title="View"
                      data-toggle="modal"
                      data-target="#notifications"
                    >
                      <i class="fal fa-eye"></i>
                    </button>
                  </Link>
                  <Link
                    to={{
                      pathname: `/courseAdministration/coursesdetail/${id}/students/grades/${
                        row.id
                      }?courseName=${props?.courseName}&stdName=${
                        row?.first_name + " " + row?.last_name
                      }`,
                    }}
                  >
                    <button
                      class="btn btn-sm btn-primary rounded-circle"
                      title={
                        row?.grant_exemption == 1
                          ? "Student Exempted from Course"
                          : "Student Grade"
                      }
                      disabled={row?.grant_exemption == 1 ? true : false}
                    >
                      <i class="fal fa-bookmark"></i>
                    </button>
                  </Link>
                  <button
                    className="btn btn-primary rounded-circle"
                    data-toggle="modal"
                    data-target="#downloadSoRModal"
                    onClick={() => setStudentID(row?.id)}
                    disabled={bulkPdfDownloading}
                    title="Download SoR PDF"
                  >
                    {bulkPdfDownloading || pdfDownloading ? (
                      <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                    ) : (
                      <i class="fal fa-download download-SoR-icon"></i>
                    )}
                  </button>
                  <Link
                    to={`/studentAdministration/students/open/${row.student_id}/notes/${id}`}
                  >
                    <button
                      class="btn btn-sm btn-primary rounded-circle"
                      title="Notes"
                    >
                      <i class="fas fa-bars"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
    [bulkPdfDownloading]
  );

  const generateSinglePDF = async (data, studentDetail) => {
    let brand = [];
    const details = {
      id: allStudents,
    };
    let brandDetail = await getStudentBrand(details);
    brand = brandDetail.data.getBrandDetail;
    let matchingBrand = brand.filter((b) => {
      return b.student_id == data[0].id;
    });
    let modifiedSoR = data
      ?.map((item) => ({
        ...item,
        final_grade: !isShowFinal ? "" : item?.final_grade,
        final_status: !isShowFinal ? "" : item?.final_status,
      }))
      .filter((assessment) => !isExcludeGrade || !assessment.isExclude);
    const blob = await pdf(
      <LetterheadIntakePDF
        detailData={modifiedSoR}
        studentAndCourseDetails={studentDetail}
        brandData={matchingBrand}
      />
    ).toBlob();

    return blob;
  };

  const generatePDFs = async () => {
    const zip = new JSZip();
    for (let i = 0; i < pdfsData.length; i++) {
      const pdfBlob = await generateSinglePDF(
        pdfsData[i].assessments,
        pdfsData[i]?.studentDetails
      );
      zip
        // .folder(`${brandVisuals?.brand_name}_${pdfsData[i]?.studentDetails?.program_name}_SOR_${moment(new Date()).format("DD MM YY")}`)
        .file(
          `${pdfsData[i]?.studentDetails?.Student_number}_${
            pdfsData[i]?.studentDetails?.Student_name
          }_${pdfsData[i]?.studentDetails?.course_name}_SOR_${
            brandVisuals?.brand_name
          }_${moment(new Date()).format("DD MM YY")}.pdf`,
          pdfBlob
        );
    }
    return zip.generateAsync({ type: "blob" });
  };

  const handleDownload = async () => {
    // Display SweetAlert modal with progress bar
    const swalProgress = Swal.mixin({
      title: "Creating ZIP",
      text: "Please wait while we create the ZIP file...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    swalProgress.fire();

    try {
      const zipBlob = await generatePDFs();
      const fileName = `${brandVisuals?.brand_name}_${
        pdfsData[0]?.studentDetails?.course_name
      }_SOR_${moment(new Date()).format("DD MM YY")}${
        status.arr?.length ? `_${status.arr}` : ""
      }${attendanceHealth.arr?.length ? `_${attendanceHealth.arr}` : ""}${
        coursestatus.arr?.length ? `_${coursestatus.arr}` : ""
      }.zip`;

      // Hide the progress bar and show success icon
      swalProgress.close();
      setBulkDownload(false);
      setStudentID("");
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $("#downloadSoRModal").modal("hide");
      Swal.fire({
        title: "ZIP Created",
        text: "ZIP file has been created successfully!",
        icon: "success",
        timer: 2000, // Automatically close after 2 seconds
        showCancelButton: false,
        showConfirmButton: false,
      });

      // Trigger download
      saveAs(zipBlob, fileName);
    } catch (error) {
      // Hide the progress bar and show error icon
      swalProgress.close();
      setBulkDownload(false);
      setStudentID("");
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $("#downloadSoRModal").modal("hide");
      Swal.fire({
        title: "Error",
        text: "An error occurred while creating the ZIP file.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const approveSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.student_id,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 3,
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $("#sign-off").modal("hide");
          setNotificationFields(notificationInitialState);
          setValidation(false);
          Swal.fire("Success!", "Successfully approved.", "success").then(
            () => {
              getSutdentData();
            }
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const declineSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.student_id,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 4,
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $("#sign-off").modal("hide");
          setNotificationFields(notificationInitialState);
          setValidation(false);
          Swal.fire("Success!", "Successfully declined.", "success").then(
            () => {
              getSutdentData();
            }
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Student",
      "myAIE Email",
      "Email",
      "Student Number",
      "Attendance Health",
      "Status",
      "Grade",
      "Result",
      "Course Status",
      "Dates",
    ];

    data = data?.map((row) => ({
      Student: row?.first_name + " " + row?.last_name,
      "myAIE Email":
        row.microsoft_status == 1 && row.microsoft_email
          ? row.microsoft_email
          : "-",
      Email: row.email ? row.email : "NA",
      "Attendance Health": row.attendanceStatus
        ? RenderAttendanceHealth(row.attendanceStatus).text
        : "-",
      "Student Number": row.student_crm_id ? row.student_crm_id : "-",
      Status: row?.status
        ? RenderProgrammeStudentStatus(row?.status).text
        : "-",
      Grade:
        row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
          ? "N/A"
          : row.calculateGradeOfStudentViaCourse &&
            row.calculateGradeOfStudentViaCourse?.gradeText
          ? row.calculateGradeOfStudentViaCourse?.gradeText
          : "-",
      Result:
        row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
          ? "N/A"
          : row.calculateGradeOfStudentViaCourse &&
            row.calculateGradeOfStudentViaCourse?.pass_status
          ? RenderQuizAttemptResultStatus(
              row.calculateGradeOfStudentViaCourse?.pass_status
            ).text
          : "-",
      "Course Status": row.subject_status ? row.subject_status : "-",
      Dates: row.subject_Dates
        ? row.subject_status.toUpperCase() === "NOT STARTED" &&
          row.type === "selfplaced"
          ? "N/A"
          : row.subject_Dates
        : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        // console.log("row ---> ", row);
        // return;
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.Student,
          row["myAIE Email"],
          row.Email,
          row["Student Number"],
          row["Attendance Health"],
          row.Status,
          row["Grade"],
          row["Result"],
          row["Course Status"],
          row["Dates"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName="Status"
                        optionArr={filterData.status}
                        state={status}
                        setState={setStatus}
                        renderLabelFunction={RenderProgrammeStudentStatus}
                      />
                      <Tablefilter
                        filterName="Attendance Health"
                        optionArr={filterData.attendance}
                        state={attendanceHealth}
                        setState={setAttendanceHealth}
                        renderLabelFunction={RenderAttendanceHealth}
                      />

                      <Tablefilter
                        filterName="Course Status"
                        optionArr={filterData.cStatus}
                        state={coursestatus}
                        setState={setCourseStatus}
                        renderLabelFunction={RenderCourseSStatus}
                      />
                    </div>
                  </div>

                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        title="Download SoR's"
                        data-toggle="modal"
                        data-target="#downloadSoRModal"
                        onClick={() => setBulkDownload(true)}
                        disabled={bulkPdfDownloading}
                      >
                        {bulkPdfDownloading ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i class="fal fa-download"></i>
                        )}
                        Download SoR's
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export spreadsheet"
                        onClick={() => {
                          exportData("xlsx", "Students");
                        }}
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export CSV"
                        onClick={() => {
                          exportData("csv", "Students");
                        }}
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export PDF"
                        onClick={() => {
                          exportData("pdf", "Students");
                        }}
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isStudents && <SkeletonTicketList />}
          {isStudents && (
            <DataTable
              data={dataToRender()}
              columns={columns}
              pagination={true}
              defaultSortField="first_name"
              defaultSortAsc={true}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          )}
        </div>
      </div>
      <div
        className="topic-add-modal modal fade"
        id="sign-off"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-bars"></i>{" "}
                  <label>Requires Sign-Off</label>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>Feedback *</label>
                        <textarea
                          type="text"
                          rows={10}
                          className="form-control form-control-aatch-file"
                          value={notificationFields.subject}
                          onChange={(e) => {
                            setNotificationFields({
                              ...notificationFields,
                              ["subject"]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button
                    type="button"
                    className="btn btn-save btn-success"
                    onClick={() => {
                      approveSend();
                      setValidation(true);
                    }}
                  >
                    Approve
                  </button>

                  <button
                    type="button"
                    className="btn btn-close btn-danger"
                    onClick={() => {
                      declineSend();
                      setValidation(true);
                    }}
                  >
                    Decline
                  </button>
                  {!notificationFields.subject.length && validation && (
                    <div className="invalid-feedback d-block">
                      Feedback is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal fade"
        id="downloadSoRModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-600 modal-dialog modal-dialog-centered modal-m"
          role="document"
        >
          <div className="modal-content modal-border-update sor">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-download"></i> Statement of Results
                  Download Options
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-toggle="modal"
                  data-target="#downloadSoRModal"
                  onClick={() => {
                    setStudentID("");
                    setIsChecked(false);
                    setIsShowFinal(false);
                    setIsExcludeGrade(false);
                    setBulkDownload(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-17px">
                <i class="fal fa-cog SoR-icon"></i>
                <span className="SoR-settings"> Settings</span>
                <div className="toogles-inline mt-3 d-flex align-items">
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Show Final Results</label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isShowFinal}
                          onChange={handleShowFinalToggle}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Exclude Empty Grades</label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isExcludeGrade}
                          onChange={handleExcludeFinal}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group form-group-save-cancel mt-4">
                  <button
                    class="btn btn-save btn-success"
                    type="button"
                    title="Save"
                    onClick={() => {
                      if (bulkDownload) {
                        handleDownload();
                      } else {
                        handlepdfData(studentID)
                      }
                    }}
                  >
                    <i class="fal fa-download"></i> Download SoR
                  </button>
                  <button
                    class="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                  >
                    <i
                      class="fal fa-times"
                      onClick={() => {
                        setStudentID("");
                        setIsChecked(false);
                        setIsShowFinal(false);
                        setIsExcludeGrade(false);
                        setBulkDownload(false);
                        $("#downloadSoRModal").modal("hide");
                      }}
                    ></i>{" "}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Students;
