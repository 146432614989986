import React, { useEffect, useState, useMemo } from 'react';
import $ from "jquery";
import Select from 'react-select';
import { addResourceToSection, getResourceManagement } from '../../../services/StudentService';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Modal, ModalBody } from 'react-bootstrap';

import { Link, useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { GetResourceManagementFilters } from "../../../services/StudentService";
import { Format, RegStudentColorComb, RenderBooleanStatus } from "../../systemadministration/usermanagement/CheckRole";
import { RenderQuizTopic, RenderResourceAvailablity } from "../../../utils/CommonStatusItems";
import Tablefilter from "../../common/Tablefilter";
import { RenderCampusDropdown, RenderResourceCampus } from "../../../utils/CommonGroupingItem";
import { handleTableScroll } from '../../../utils/commonFunction';

export default function AddResource({ sectionId, refreshList, onClose, show }) {
    const [search, setSearch] = useState("");
    const [tablistData, setTablistData] = useState([]);    
    const [studentstatus, setStudentStatus] = useState({ arr: [], checkObj: {} });    
    const [availabilitystatus, setAvailabilityStatus] = useState({ arr: [], checkObj: {} });
    
    const [isLoaded, setIsLoaded] = useState(true);
    const [status, setStatus] = useState([]);
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const [topic, setTopic] = useState({ arr: [], checkObj: {} })
    const [linkedBrand, setLinkedBrand] = useState({ arr: [], checkObj: {} });
    const [published, setPublished] = useState({ arr: [], checkObj: {} });
  
    const [sortkey, setSortKey] = useState("title");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState({ format: [], campus: [], availablity: [], topic: [], brand:[], published:[] })
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    const handleAddResource = () =>{
        const sendData = {
            section_id: sectionId,
            resource: [...selectedRows]
        }
        addResourceToSection(sendData).then((res) => {
            if (res.status === 200) {
                // setDisable(false)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: res.data.message,
                }).then(() => {
                    refreshList();
                    onClose();
                });
            }
        }).catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `${err?.response?.data?.data}`,
            });
            console.log(err);
        })
    }   

    useEffect(() => {
        setIsLoaded(true);
        const data = {
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: false,
            key: sortkey,
            sort: sortOrder,
            viaCampus: studentstatus.arr.length > 0 ? studentstatus.arr : "",
            viaAvailability: availabilitystatus.arr.length > 0 ? availabilitystatus.arr : "",
            viaFormat: status.length > 0 ? status : "",
            viaTopics: topic.arr,
            viaBrands: linkedBrand.arr,
            viaPublished: published.arr,
            viaCategory: sectionId
        };
        getResourceManagement(data)
            .then((res) => {
                GetResourceManagementFilters().then((response) => {
                    setFilterData({
                        ...response.data,
                        format: response.data.formatFilter,
                        campus: response.data.campusFilter,
                        availablity: response.data.availabilityFilter,
                        brand: response.data.brandFilters,
                        topic: response.data.topicFilters,
                        published: response.data.publishedFilter
                    })
                    let arr = res?.data?.result?.data.map(item => {
                        let topicArr = response.data.topicFilters.filter(val => {
                            return item.topic ? item.topic.split(",").includes(val.value.toString()) : false
                        })
                        let brandArr = response.data.brandFilters.filter(val => {
                            return item.brand ? item.brand.split(",").includes(val.value.toString()) : false
                        })
                        return {
                            ...item,
                            topic: item.topic ? topicArr : [],
                            brand: item.brand ? brandArr : [],
                            campus: (item.campus && item.campus != "") ? response.data.campusFilter.filter(val => (val.value.toString() === item.campus))[0] : ""
                        }
                    })
                    setTablistData(arr)
                    setIsLoaded(false);
                }).catch(err => {
                    console.error("error :", err)
                    setTablistData(res?.data?.result?.data);
                })
                setTotalRows(res?.data?.result?.total)
            })
            .catch((err) => {
                console.log(err);
                setIsLoaded(false);
            });

    }, [search, page, perPage, sortkey, sortOrder, status, studentstatus, availabilitystatus, searchStatusCheck, topic, linkedBrand, published]);

    // TODO Searching
    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };


    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const handleStatusFilter = (e) => {
        const value = e.target.value;
        let arr = status;
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setStatus(arr);
        let id = e.target.id;
        setSearchStatusCheck({
            ...searchStatusCheck,
            [id]: !searchStatusCheck[id],
        });
    };

    // TODO Reset Filter
    const resetFilter = () => {
        setSearch("");
        setStatus([])
        setSearchStatusCheck({})
        setStudentStatus({ arr: [], checkObj: {} })
        setAvailabilityStatus({ arr: [], checkObj: {} })
        setTopic({ arr: [], checkObj: {} })
        setLinkedBrand({ arr: [], checkObj: {} });
        setPublished({ arr: [], checkObj: {} });
    };

    const handleSelect = (e, row) => {
        const { checked, id, name, value } = e.target;
        const ls = [...selectedRows];        
        if(checked){
            ls.push(row.id);
        }else{
            const ind = ls.indexOf(Number(row.id));
            ls.splice(ind,1);
        }
        setSelectedRows([...ls]);
    }

    const handleSelectAll = (e) =>{
        const { checked} = e.target;
        const checkedList = [...selectedRows];
        tablistData.map((rowItem)=>{
            if(checked){
                if(!checkedList.includes(rowItem.id)){
                    checkedList.push(rowItem.id);
                }
            }else{
                const index = checkedList.indexOf(Number(rowItem.id));
                checkedList.splice(index, 1)
            }
        });
        setSelectedRows([...checkedList]);
    }
    
    const checker = (arr, target) => target.every(v => arr.includes(v.id));

    const columns = useMemo(() => [
        {
            name: <input type="checkbox" name="select-all" onClick={(e)=>{handleSelectAll(e)}} checked={checker(selectedRows, tablistData)} />,
            cell: (row, ind) => {      
              return <input
                type={"checkbox"}
                name="select"
                id={row?.quid}
                onChange={(e) => handleSelect(e, row)}
                checked={selectedRows.includes(row?.id)}
              />
            }
        },
        {
            name: "Title",
            selector: "title",
            sortField: "title",
            sortable: true,
            // minWidth: "190px",
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="assigned-title-itm textLimit100" title={row.title}>
                        <Link className="as-text-blue curser " target="_blank" to={(row.type.toString() === "Book" || row.type.toString() === "ebook") ? `/support/student-hub-library/resource-management/edit/${row.id}/dashboard/physical` : `/support/student-hub-library/resource-management/edit/${row.id}/details/digital`} title={row.title}>
                            {row.title}
                        </Link>
                    </div>
                </div>
            ),
        },
        {
            name: "Format",
            selector: "format",
            sortField: "format",
            sortable: true,
            cell: (row) => (
                <Format status={row.type.toString()} />
            ),
        },
        {
            name: "Campus",
            selector: "campus",
            sortField: "campus",
            sortable: true,
            cell: (row) => (row.campus ? RenderCampusDropdown(row.campus).html : "-"),
        },
        {
            name: "Availability",
            selector: "availability",
            sortField: "availability",
            sortable: true,
            cell: (row) => (row.availability ? RenderResourceAvailablity(row.availability).html : "-"),
        },
        {
            name: "Dewey",
            selector: "dewey",
            sortField: "dewey",
            sortable: true,
            cell: (row) => (
                row?.dewey ? row?.dewey
                    : "-"),
        },
        {
            name: "Topics",
            selector: "topic",
            sortField: "topic",
            sortable: false,
            cell: (row) => (
                (row?.topic && row?.topic.length) ?
                    <div className="d-flex flex-wrap">
                        {row?.topic.map((item, index) => <span key={index} className="mr-1">{RenderQuizTopic(item.label, item.color).html}</span>)}
                    </div>
                    : "-"),
        },
        {
            name: "Linked Brands",
            selector: "brand",
            sortField: "brand",
            sortable: false,
            cell: (row) => (
                row?.brand && row?.brand.length ?
                    <div className="d-flex flex-wrap">
                        {row?.brand.map((item, index) => <span key={index} className="mr-1">{RenderQuizTopic(item.label, item.color).html}</span>)}
                    </div>
                    : "-"),
        },
        {
            name: "Published",
            selector: "isPublished",
            sortField: "isPublished",
            sortable: false,
            cell: (row) => (RenderBooleanStatus(row?.isPublished))
        }
    ]);

    // TODO Excel
    // TODO Csv
    // TODO Pdf
    const exportData = (fileType, fileName) => {
        let data
        const header = ["Title", "Format", "Campus", "Availability", "Dewey", "Topics", "Linked Brands", "Author", "Language", "Publisher", "Series", "ISBN", "Bar Code", "Pub Location", "Pub Date", "Published", "URL"];

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        getResourceManagement({
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: true,
            key: sortkey,
            sort: sortOrder,
            viaCampus: studentstatus.arr.length > 0 ? studentstatus.arr : "",
            viaAvailability: availabilitystatus.length > 0 ? availabilitystatus : "",
            viaFormat: status.length > 0 ? status : "",
            viaTopics: topic.arr,
            viaBrands: linkedBrand.arr,
            viaPublished: published.arr
        }).then((res) => {
            data = res.data.result;
            data = data.map((row) => ({
                Title: row?.title,
                Format: row.type ? row.type : "-",
                Campus: row.campus && filterData.campus.filter(val => (val.value == row.campus)).length ? filterData.campus.filter(val => (val.value == row.campus))[0].label : "-",
                Availability: row.availability ? RenderResourceAvailablity(row.availability).text : "-",
                Dewey: row.dewey ? row.dewey : "-",
                Topics: row.topic ? (filterData.topic.filter(val => (row.topic.split(",").includes(val.value.toString()))).map(item => item.label)).join(",") : "-",
                "Linked Brands": row.brand ? (filterData.brand.filter(val => (row.brand.split(",").includes(val.value.toString()))).map(item => item.label)).join(",") : "-",
                "Author": row.author ? row.author : "-",
                "Language": row.language ? row.language : "-",
                "Publisher": row.publisher ? row.publisher : "-",
                "Series": row.series ? row.series : "-",
                "ISBN": row.isbn ? row.isbn : "-",
                "Bar Code": row.barcode ? row.barcode : "-",
                "Pub Location": row.location ? row.location : "-",
                "Pub Date": row.publish_date ? row.publish_date : "-",
                "Published": row.published === 1 ? "Yes" : "No",
                "URL": row.url ? row.url : "-"
            }));

            if (fileType === "csv") {
                const csvString = Papa.unparse({ fields: header, data });

                const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                const blobURL = window.URL.createObjectURL(blob);

                const anchor = document.createElement("a");
                anchor.download = fileName;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = [
                    "text/csv",
                    anchor.download,
                    anchor.href,
                ].join(":");
                anchor.click();

                setTimeout(() => {
                    URL.revokeObjectURL(blobURL);
                }, 1000);
                Swal.close();
            } else if (fileType === "xlsx") {
                const compatibleData = data.map((row) => {
                    const obj = {};
                    header.map((col, index) => {
                        obj[col] = row[col];
                    });
                    return obj;
                });

                let wb = XLSX.utils.book_new();
                let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                    header,
                });
                XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                XLSX.writeFile(wb, `${fileName}.xlsx`);
                Swal.close();
                return false;
            }
            if (fileType === "pdf") {
                const compatibleData = data.map((row) => {
                    return [
                        row["Title"],
                        row["Format"],
                        row["Campus"],
                        row["Availability"],
                        row["Dewey"],
                        row["Topics"],
                        row["Linked Brands"],
                        row["Author"],
                        row["Language"],
                        row["Publisher"],
                        row["Series"],
                        row["ISBN"],
                        row["Bar Code"],
                        row["Pub Location"],
                        row["Pub Date"],
                        row["Published"],
                        row["URL"]
                    ];
                });
                const doc = new JsPDF();
                doc.autoTable({
                    head: [header],
                    body: compatibleData,
                    columnStyles: {
                        0: { columnWidth: 10 },
                        1: { columnWidth: 10 },
                        2: { columnWidth: 10 },
                        3: { columnWidth: 10 },
                        4: { columnWidth: 10 },
                        5: { columnWidth: 10 },
                        6: { columnWidth: 10 },
                        7: { columnWidth: 10 },
                        8: { columnWidth: 10 },
                        9: { columnWidth: 10 },
                        10: { columnWidth: 10 },
                        11: { columnWidth: 10 },
                        12: { columnWidth: 10 },
                        13: { columnWidth: 10 },
                        14: { columnWidth: 10 },
                        15: { columnWidth: 10 },
                        16: { columnWidth: 10 },
                        17: { columnWidth: 10 },
                    }
                });
                doc.save(`${fileName}.pdf`);
                Swal.close();
                return false;
            }
        });


    };

    return (
        <Modal className='modal fade' size={"xl"} show={show} centered onHide={onClose}>
            <ModalBody className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                    <h5 class="modal-title"><i class="fal fa-plus"></i> Add Resource</h5>
                    <button type="button" onClick={onClose} class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </div>
                <div className="p-30px">
                    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
                        <div className="custom-table-div filter-search-icon card-table-custom">
                            <div className="search-filter-div">
                                <div className="search-filter-div-left">
                                    <div className="system-administration-table table-responsive">
                                        <div className="table-responsive-div">
                                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                                        <label>
                                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                                        </label>
                                                        <div className="filter-eff filter-data-btn">
                                                            <button className="filter-buttons">
                                                                <i className="fal fa-filter"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-button-group">
                                            <div className="filter-scroll">
                                                <div className="assessment-table-filter">
                                                    <div className="btn-type-filter dropdown-comman">
                                                        <div className={`dropdown multiselect `}>
                                                            <button
                                                                className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""
                                                                    }`}
                                                                type="button"
                                                                id="dropdownMenu1"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="true"
                                                                title="Format"
                                                            >
                                                                <span>
                                                                    Format   <i className="fal fa-angle-down"></i>
                                                                </span>
                                                            </button>
                                                            <form className="dropdown-menu remove-list-style">
                                                                <ul aria-labelledby="dropdownMenu1">
                                                                    {filterData.format.map((resource, idx) => (
                                                                        <li key={idx} className="item-text-green">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={resource.value}
                                                                                value={resource.value}
                                                                                onClick={handleStatusFilter}
                                                                                checked={searchStatusCheck[resource.value]}
                                                                            />
                                                                            <label htmlFor={resource.value}>
                                                                                <Format status={resource.value.toString()} />
                                                                            </label>
                                                                        </li>

                                                                    ))}

                                                                </ul>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                                <Tablefilter
                                                    filterName="Campus"
                                                    optionArr={filterData.campus}
                                                    state={studentstatus}
                                                    setState={setStudentStatus}
                                                    uniqueId="campus"
                                                    renderLabelFunction={RenderCampusDropdown}
                                                />
                                                <Tablefilter
                                                    filterName="Availablity"
                                                    optionArr={filterData.availablity}
                                                    state={availabilitystatus}
                                                    setState={setAvailabilityStatus}
                                                    renderLabelFunction={RenderResourceAvailablity}
                                                />
                                                <Tablefilter
                                                    filterName="Topics"
                                                    optionArr={filterData.topic}
                                                    state={topic}
                                                    setState={setTopic}
                                                    renderLabelFunction={RenderQuizTopic}
                                                    uniqueId="topic"
                                                />
                                                <Tablefilter
                                                    filterName="Linked Brands"
                                                    optionArr={filterData.brand}
                                                    state={linkedBrand}
                                                    setState={setLinkedBrand}
                                                    renderLabelFunction={RenderQuizTopic}
                                                    uniqueId="brand"
                                                />
                                                <Tablefilter
                                                    filterName="Published"
                                                    optionArr={filterData.published}
                                                    state={published}
                                                    setState={setPublished}
                                                    renderLabelFunction={RenderBooleanStatus}
                                                    uniqueId="published"
                                                />
                                            </div>
                                            <div className="reset-btn-group">
                                                <div className="button-reset dropdown-comman" title="Reset">
                                                    <button className="btn btn-primary" onClick={resetFilter} >
                                                        <i className="fal fa-redo"></i>Reset
                                                    </button>
                                                </div>

                                                <div className="files-export-group">
                                                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Resource Management List"); }} title="Export spreadsheet">
                                                        <i className="fal fa-file-excel icon"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Resource Management List"); }} title="Export CSV">
                                                        <i className="fal fa-file-csv icon"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Resource Management List"); }} title="Export PDF">
                                                        <i className="fal fa-file-pdf icon"></i>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                data={tablistData}
                                defaultSortField="title"
                                defaultSortAsc={true}
                                columns={columns}
                                pagination={true}
                                noDataComponent={Str.noRecord}
                                paginationDefaultPage={page}
                                progressPending={isLoaded}
                                onSort={handleSort}
                                paginationServer
                                sortServer
                                progressComponent={<SkeletonTicketList />}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                highlightOnHover={false}
                                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                            />
                        </div>
                    </div>
                    <div className="form-group form-group-save-cancel mt-3">
                        <button className="btn btn-save  btn-success" title="Add Resource" onClick={()=>{handleAddResource()}} disabled={selectedRows.length<1 || loading}>
                            {loading === false ? <i className="fal fa-plus"></i> : <i className="fas fa-cog fa-spin"></i>}
                            Add Resource
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>        
    )
}
