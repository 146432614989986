import React, { useEffect, useState } from "react";
import { getAve } from "../../../services/DashBoardServices";

const RecordingStats = () => {
  const [duration, setDuration] = useState();
  useEffect(() => {
    getAve()
      .then((res) => {
        setDuration(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="card-new card card-profile-info-card online-class-table mb-30">
      <div className="basic-info-box">
        <div className="row align-items-center">
          <div className="col-md-4 col-lg-3">
            <div className="basic-info-box-list">
              <b className="ave-title">Avg view : </b>
              <span>{`${duration?.Aview?.h ? duration?.Aview?.h + "h " : "0h "}:${duration?.Aview?.m ? duration?.Aview?.m + "min" : "0min"}`}</span>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="basic-info-box-list">
              <b className="ave-title">Avg Download : </b>
              <span>{`${duration?.Adownload?.h ? duration?.Adownload?.h + "h " : "0h "}:${duration?.Adownload?.m ? duration?.Adownload?.m + "min" : "0min"}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingStats;
