import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { UploadClassRec } from "../../../../services/CourseService";
import $ from 'jquery';
import PermissionsGate from "../../../../utils/permissionGate";

const ClassRecordingForm = (props) => {
  //   2.  Upload   -  Block  1.  Video Type (Dropdown list)  1.  Vimeo   2.  Youtube   (  Consider terminology, maybe “Online”)
  //   3.  File  2.  Vimeo Video Code (Text input)   –  Only visible if” Type” is Vimeo
  //   3.  URL (Text input)   –  Only visible if “Type” is Youtube
  //   4.  File upload  input   –  Only visible if Type is “  File


  const [state, setState] = useState("");
  const videotype = [
    { value: "Vimeo", label: "Vimeo" },
    { value: "Youtube", label: "Youtube" },
    { value: "File", label: "File" },
  ];
  const [fileValue, setFileValue] = useState();

  // console.log("classId ++++++++ ", props)

  // useEffect(() => {

  // }, [])

  // function videoSelected(data) {
  //   if (data.video_type == 0 && data.file_type == "Manually") {
  //     setLink("http://vimeo.com/" + data.name, "_blank");
  //   } else if (data.video_type == 0 && data.file_type == "Recording") {
  //     setLink(data.record_url);
  //   }
  //   setState(data.value);
  // }

  // console.log("fileValue ===> ", fileValue);

  function upload() {
    let formData = new FormData();
    formData.append("video_type", state == "Vimeo" ? 0 : state == "Youtube" ? 1 : 2);
    formData.append("portal_class_id", props.classId);
    formData.append("item_resource_url", fileValue);
    // return
    UploadClassRec(formData).then(data => {
      if(data.status == 200) {
        Swal.fire({icon: "success",title: "Success",text: "Added successfully"});
        $('#classrecording').modal('hide')
      }
    }).catch(error => {
      console.log("error ---> ", error);
    })
  }

  function valueSelected(value) {
    if(value){
      setState(value.value)
    } else {
      setState("")
    }
    setFileValue("");
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Upload</div>
      </div>
      <div className="topic-add-modal-content">
        <div className="row center-label">
          <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>Video Type</label>
              <Select
                className={"form-control custom-select-box"}
                value={videotype?.filter(val=>val.value === state)}
                onChange={(value) => valueSelected(value)}
                // onChange={(value) => {setState(value.value) }}
                options={videotype}
                maxMenuHeight={175}
                placeholder={state ? state : "Select video type"}
                isClearable
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-group form-group-save-cancel mt-3">
      <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
        <button type="button" className="btn btn-save btn-success" onClick={() => { upload() }}>
          <i className="fal fa-save"></i>
          Submit
        </button>
        </PermissionsGate>
        <button type="button" className="btn btn-close btn-danger" data-dismiss="modal">
          <i className="fal fa-times"></i>
          Close
        </button>
      </div>
    </div>
  );
};

export default ClassRecordingForm;
