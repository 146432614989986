import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../common/Helper";
import { Link, useHistory, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { IMAGE_URL, SP_PASS, TABLE_DATE_FORMAT } from "../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../utils/permissionGate";
import { GetCourselist, deleteCourse, GetCouresListDropdownCancelToken, GetCourselistCancelToken } from "../../services/DashBoardServices";
import { ColorRender } from "../systemadministration/usermanagement/CheckRole";
import Hover from "../common/Hover";
import { RenderCourseType, RenderLearningMethod, RenderSchool } from "../../utils/CommonGroupingItem";
import axios from "axios";
import DataTableComponent from "../common/DataTableComponent";
import { RenderReadStatus } from "../../utils/CommonStatusItems";
import CopyCourse from "./CopyCourse";

function SelfPlacedCourses() {
    const history = useHistory();
    const { tab } = useParams();
    const [programmeIds, setProgrammeIds] = useState({ arr: [], checkObj: [] })
    const [search, setSearch] = useState("");
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [school, setSchool] = useState({ arr: [], checkObj: {} });
    const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
    const [reply, setReply] = useState({ arr: [], checkObj: {} });
    const [courseDropdown, setCourseDropdown] = useState({ programme: [], intake: [], learning: [], school: [], type: [] })
    const [deleteRow, setDeleteRow] = useState(false);
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "start",
        sortOrder: "DESC"
    })
    const [courseId, setCourseId] = useState("");
    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isShowingUser, setIsShowingUser] = useState([]);

    useEffect(() => {
        const cancelTokenSources = [];

        const getCourseList = async () => {
            setLoading(true);
        
            cancelTokenSources.forEach(source => {
              source.cancel('New request made');
            });
        
            const source = axios.CancelToken.source();
            // setCancelTokenSources([...cancelTokenSources, source]);
            cancelTokenSources.push(source);

            let data = {
                pageNo: tableState.page,
                size: tableState.perPage,
                intake: intake.arr,
                viaLearnType: ["selfplaced"],
                key: tableState.sortKey,
                sort: tableState.sortOrder,
                viaStatus: [],
                viaSearch: search,
                viaProgramme: programmeIds.arr,
                viaMessage: reply.arr,
                viaSchoolType: school.arr,
                viaSubjectType: courseType.arr,
                showing: []
            };

            try {
              const res = await GetCourselistCancelToken(data, source.token);
              setCourseList(res.data?.result);
              setTotalRows(res.data?.Pagination.total);
              if (res.status == 200) {
                setLoading(false);
              }
            } catch (error) {
              if (!axios.isCancel(error)) {
                console.error(error);
                setLoading(false);
              }
            }
          };

        getCourseList();
        
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [
        search,
        programmeIds,
        reply,
        intake,
        school,
        courseType,
        deleteRow,
        tableState,
        tab
    ])

    useEffect(() => {
        const cancelTokenDropdownSources = [];

        const fetchData = async () => {
            cancelTokenDropdownSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenDropdownSources.push(source);

            let data = {
                viaLearnType: [tab]
            }

            try {
                const res = await GetCouresListDropdownCancelToken(data, source.token);
                setCourseDropdown({ programme: [], intake: [], learning: [], school: [], type: [] });
                setCourseDropdown({ ...res.data, programme: res.data.programme, intake: res.data.yearsAndIntaks, learning: res.data.intakeType, school: res.data.schoolType ? res.data.schoolType : [], type: res.data.subjectTypeFilter });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();

        return () => {
            cancelTokenDropdownSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [tab]);

    const handleDeleteCourse = (id) => {
        const parameter = { intake: id };
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourse(parameter).then((res) => {
                    if (res.status === 200) {
                        Swal.fire("Deleted!", "Your record has been deleted.", "success");
                        setDeleteRow(!deleteRow)
                    }
                });
            }
        });
    }

    const handleShowingUser = (isShowing, id) => {
        let tempArr = isShowingUser;
        if (isShowing) {
          tempArr.splice(tempArr.indexOf(id), 1)
        } else {
          tempArr.push(id)
        }
        setIsShowingUser([...tempArr])
      }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="dashboard-message-count">
                        {row.messageCount != 0 && row.messageCount != null ? <Link to={`/courseAdministration/coursesdetails/${row.id}/post-feed/show`} target="_blank" className='mr-2'>
                            <i title="You have unread messages" className="fal fa-comment-lines message-count unread-message-icon curser"></i>
                            <i title="You have unread messages" className="fal fa-comment-lines message-count hidden-icon curser"></i>
                            {(row.messageCount) > 0 ? <small className="notify-count1 unread-message-count">{(row.messageCount) < 10 ? (row.messageCount) : '9+'}</small> : null}
                        </Link> : ""}
                        <div
                            className="assigned-title-itm overflow-ellipsis2 fst_line2"
                            title={row.name}
                        >
                            {/* <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}> */}
                            <Link
                                className="as-text-blue curser textLimit2"
                                to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
                                title={row.name}
                            >
                                {row.name ? row.name : "-"}
                            </Link>


                            {/* </PermissionsGate> */}
                        </div>
                    </div>

                </div>
            ),
        },
        {
            name: "Published Date",
            selector: "intakePublish",
            sortField: "intakePublish",
            sortable: true,
            cell: (row) => (
                <>
                    {row.intakePublish ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.intakePublish).format(TABLE_DATE_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Learning Method",
            selector: "study_type",
            sortable: true,
            sortField: "type",
            cell: (row) => (
                <p className="learningMethodText">
                    {row && row.type ? RenderLearningMethod(row.type).html : "-"}
                </p>
            ),
        },
        {
            name: "Programme",
            selector: "qualificationName",
            sortable: true,
            cell: (row) => (
                <span className="overflow-ellipsis2 fst_line2">
                    <Link
                        className="as-text-blue curser"
                        title={row.qualificationName}
                        to={{
                            pathname: `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`,
                        }}
                    >
                        {row.qualificationName ? row.qualificationName : "-"}
                    </Link>
                </span>
            ),
        },
        {
            name: <i className="fal fa-users" title="Students" />,
            selector: "totalRows",
            sortable: true,
            sortField: "students",
            cell: (row) => <p>{row.totalRows ? row["totalRows"] : "-"}</p>,
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            sortField: "intake",
            cell: (row) => (
                <p>
                    {row.year || row.number ? (
                        <>
                            {" "}
                            {row.year ? row.year : ""}-{row.number ? row["number"] : ""}
                        </>
                    ) : (
                        "-"
                    )}
                </p>
            ),
        },
        {
            name: "Lecturer",
            selector: "lECTURER",
            sortable: true,
            sortField: "lecturer",
            cell: (row) => (
                <>
                    {row.lECTURER ? (
                        <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                                <span
                                    className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("lecturer")
                                    }
                                >
                                    {row.lecturer_profile_pic ? (
                                        <img
                                            src={`${IMAGE_URL}/${row.lecturer_profile_pic.replace("public/", "").replaceAll(
                                                "/home/myaie/public_html/",
                                                ""
                                            )}`}
                                            alt="AIE"
                                        />
                                    ) : (
                                        InitialRender([
                                            row.lECTURER.split(" ")[0],
                                            row.lECTURER.replace(/ +/g, " ").split(" ")[1],
                                        ])
                                    )}
                                    <span className={`profile-box-2-status ${row.lecturer_activity_status ? row.lecturer_activity_status.toLowerCase() == "online" ? "Online" : row.lecturer_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                        firstName={row.lECTURER.split(" ")[0]}
                                        lastName={row.lECTURER.replace(/ +/g, " ").split(" ")[1]}
                                        photo={row.lecturer_profile_pic}
                                        email={row.lecturer_email}
                                        mobile={row.lecturer_contact ? row.lecturer_contact : ""}
                                        right={false}
                                        status={row.lecturer_status}
                                        activity_status={row.lecturer_activity_status}
                                        role={"lecturer"}
                                    />
                                </span>
                                <span
                                    title={row.lECTURER}
                                    className="as-text-blue curser feature-name width-155"
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${row.lECTURERID}/aboutYou`
                                        )
                                    }
                                >
                                    <span className="textLimit100">{row.lECTURER ? row["lECTURER"] : "-"}</span>
                                </span>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: <i className="fal fa-chalkboard" title="Coach & Support" />,
            selector: "programme_coordinator_name",
            sortable: true,
            sortField: "coach",
            cell: (row) => {
                const programmeCoordinator = row.programme_coordinator_name ? <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span
                            className={
                                "assigned-title-blk  name-icon cat-dark-red " +
                                ColorRender("Coach")
                            }
                        >
                            {row.programme_coordinator_profile_pic ? (
                                <img
                                    src={`${IMAGE_URL}/${row.programme_coordinator_profile_pic.replace("public/", "").replaceAll(
                                        "/home/myaie/public_html/",
                                        ""
                                    )}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([
                                    row.programme_coordinator_name.split(" ")[0],
                                    row.programme_coordinator_name
                                        .replace(/ +/g, " ")
                                        .split(" ")[1],
                                ])
                            )}
                            <span className={`profile-box-2-status ${row.coach_activity_status ? row.coach_activity_status.toLowerCase() == "online" ? "Online" : row.coach_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row.programme_coordinator_name.split(" ")[0]}
                                lastName={
                                    row.programme_coordinator_name
                                        .replace(/ +/g, " ")
                                        .split(" ")[1]
                                }
                                photo={row.programme_coordinator_profile_pic}
                                email={row.programme_coordinator_email}
                                mobile={
                                    row.programme_coordinator_contact
                                        ? row.programme_coordinator_contact
                                        : ""
                                }
                                status={row.coach_status}
                                activity_status={row.coach_activity_status}
                                right={false}
                                role={"Coach"}
                            />
                        </span>
                        {isShowingUser.includes(row.intakeID) || !row.SUPPORT ? <span
                            className="as-text-blue curser feature-name width-155"
                            title={row.programme_coordinator_name}
                            onClick={() =>
                                history.push(
                                    `/systemAdministration/userManagement/open/${row.COACHID}/aboutYou`
                                )
                            }
                        >
                            <span className="textLimit100">{row.programme_coordinator_name
                                ? row["programme_coordinator_name"]
                                : "-"}</span>
                        </span> : ""}
                    </div>
                </div> : ""

                const support = row.SUPPORT ? <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span
                            className={
                                "assigned-title-blk  name-icon cat-dark-red " +
                                ColorRender("student support")
                            }
                        >
                            {row.programme_support_profile_pic ? (
                                <img
                                    src={`${IMAGE_URL}/${row.programme_support_profile_pic.replace("public/", "").replaceAll(
                                        "/home/myaie/public_html/",
                                        ""
                                    )}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([
                                    row.SUPPORT.split("")[0],
                                    row.SUPPORT.replace(/ +/g, " ").split(" ")[1],
                                ])
                            )}
                            <span className={`profile-box-2-status ${row.support_activity_status ? row.support_activity_status.toLowerCase() == "online" ? "Online" : row.support_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row.SUPPORT}
                                photo={row.programme_support_profile_pic}
                                email={row.programme_support_email}
                                mobile={
                                    row.programme_support_contact
                                        ? row.programme_coordinator_contact
                                        : ""
                                }
                                status={row.support_status}
                                activity_status={row.support_activity_status}
                                right={false}
                                role={"student support"}
                            />
                        </span>
                        {isShowingUser.includes(row.intakeID) || !row.programme_coordinator_name ? <span
                            className="as-text-blue curser feature-name width-155"
                            title={row.SUPPORT}
                            onClick={() =>
                                history.push(
                                    `/systemAdministration/userManagement/open/${row.programme_support_id}/aboutYou`
                                )
                            }
                        >
                            <span className="textLimit100">{row.SUPPORT ? row["SUPPORT"] : "-"}</span>
                        </span> : ""}
                    </div>
                </div> : ""

                return (
                    row.programme_coordinator_name && !row.SUPPORT ? programmeCoordinator :
                        !row.programme_coordinator_name && row.SUPPORT ? support :
                            !row.programme_coordinator_name && !row.SUPPORT ? "-" :
                                isShowingUser.includes(row.intakeID) ?
                                    <div>
                                        <div className="assigned-profile-tit"><span>Coach</span>{programmeCoordinator}</div>
                                        <div className="assigned-profile-tit"><span>Support</span>{support}</div>
                                        <div className="table-expand-btn" onClick={() => handleShowingUser(true, row.intakeID)}>See Less</div>
                                    </div>
                                    : <div className="assigned-profile-overlay-out">
                                        <div className="assigned-profile-overlay">{programmeCoordinator} {support} </div>
                                        <div className="table-expand-btn" onClick={() => handleShowingUser(false, row.intakeID)}>See More</div>
                                    </div>
                )
            }
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            sortField: "school_type",
            cell: (row) => <span className='textLimit75 width-155'>{row.school_type ? RenderSchool(row.school_type).html : "-"}</span>
        },
        {
            name: "Type",
            selector: "subject_type",
            sortable: true,
            sortField: "subject_type",
            cell: (row) => row.subject_type ? RenderCourseType(row.subject_type).html : "-"
        },
        {
            name: "Action",
            selector: "Action",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <PermissionsGate scopes={["cedit"]} RenderError={() => (
                            <button className="btn btn-primary rounded-circle" title="Open" disabled>
                                <i className="fal fa-folder-open"></i>
                            </button>
                        )}>
                            <Link
                                className="btn btn-primary rounded-circle"
                                to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
                                title="Open"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <div className="dropdown btn-dropdown-item">
                            <button
                                className="btn btn-primary rounded-circle dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="More"
                            >
                                <i className="fal fa-ellipsis-h-alt"></i>
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <Link
                                    className="btn btn-primary rounded-circle"
                                    to={{
                                        pathname: `https://student.myaie.ac/courseinfo/${row.qualification_id}/subject/${row.intakeID}/${SP_PASS}`,
                                    }}
                                    target="_blank"
                                    title="Student View"
                                >
                                    <i className="fal fa-eye"></i>
                                </Link>
                                <PermissionsGate scopes={["cdelete"]} errorProps={{ disabled: true }}>
                                    <button className="btn btn-danger rounded-circle" title="Delete"
                                        onClick={() => handleDeleteCourse(row.id)}
                                    >
                                        <i className="fal fa-trash-alt"></i>
                                    </button>
                                </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ], [isShowingUser]);

    const exportData = (fileType, fileName) => {
        let data = courseList;
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        let header = [
            "Name",
            "Published Date",
            "Learning Method",
            "Programme",
            "Intake",
            "Student",
            "Lecturer",
            "Couch",
            "Support",
            "School",
            "Type"
        ];
        let data1 = {
            pageNo: tableState.page,
            size: tableState.perPage,
            intake: intake.arr,
            viaLearnType: ["selfplaced"],
            exportStatus: "true",
            viaStatus: [],
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            viaProgramme: programmeIds.arr,
            viaMessage: reply.arr,
            viaSearch: search,
            viaSchoolType: school.arr,
            viaSubjectType: courseType.arr,
            showing: []
        };
        GetCourselist(data1)
            .then((res) => {
                data = res.data.result;
                data = data?.map((row) => ({
                    Name: row.name,
                    "Start": row.start
                        ? `${moment.unix(row.start).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    "End": row.end
                        ? `${moment.unix(row.end).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    "Published Date": row.intakePublish
                        ? `${moment.unix(row.intakePublish).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
                    Programme: row.qualificationName ? row.qualificationName : "-",
                    Intake: `${row.year} - ${row.number}`,
                    Student: row.totalRows ? row.totalRows : "-",
                    Lecturer: row.lECTURER ? row.lECTURER : "-",
                    Couch: row.programme_coordinator_name
                        ? row.programme_coordinator_name
                        : "-",
                    Support: row.SUPPORT ? row.SUPPORT : "-",
                    School: row.school_type ? RenderSchool(row.school_type).text : "-",
                    Type: row.subject_type ? RenderCourseType(row.subject_type).text : "-"
                }));

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.forEach((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["Name"],
                            row["Published Date"],
                            row["Learning Method"],
                            row["Programme"],
                            row["Intake"],
                            row["Student"],
                            row["Lecturer"],
                            row["Couch"],
                            row["Support"],
                            row["School"],
                            row["Type"]
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((error) => {
                console.log("error >>>>>> ", error);
            });
    };
    return (
        <>
            <DataTableComponent
                data={courseList}
                loading={loading}
                state={tableState}
                setState={setTableState}
                setSearch={setSearch}
                totalRows={totalRows}
                columns={columns}
                exportFunction={exportData}
                exportFileName={"Course_list"}
                filters={[
                    {
                        filterName: "Programme",
                        optionArr: courseDropdown.programme,
                        state: programmeIds,
                        setState: setProgrammeIds,
                        isSearchFilter: true
                    },
                    {
                        filterName: "Intake",
                        optionArr: courseDropdown.intake,
                        state: intake,
                        setState: setIntake,
                        isOptionReversed: true
                    },
                    {
                        filterName: "Reply",
                        optionArr: [
                            {
                                label: "Read",
                                value: "read"
                            },
                            {
                                label: "Unread",
                                value: "unread"
                            }
                        ],
                        state: reply,
                        setState: setReply,
                        renderLabelFunction: RenderReadStatus
                    },
                    {
                        filterName: "School",
                        optionArr: courseDropdown.school,
                        state: school,
                        setState: setSchool,
                        renderLabelFunction: RenderSchool
                    },
                ].filter(item => item)}
                tableButton={[
                    <PermissionsGate
                        scopes={["cadd"]}
                        RenderError={() => (
                            <button className="btn btn-primary" title="Create New" disabled>
                                <i className="fal fa-plus"></i>Create New
                            </button>
                        )}
                    >
                        <Link to="/courseAdministration/courses/addcourse">
                            <button className="btn btn-primary" title="Create New">
                                <i className="fal fa-plus"></i>Create New
                            </button>
                        </Link>
                    </PermissionsGate>
                ]}
            />
            <CopyCourse courseId={courseId} refresh={() => { setDeleteRow(!deleteRow); setCourseId("") }} />
        </>
    );

};

export default SelfPlacedCourses