import React, { useState, useMemo, useEffect, useCallback } from "react";
import AssignedAssessments from "./AssignedAssessments";
import AvailableAssessment from "./AvailableAssessment";

const AssessmendTables = (props) => {

  const [zIndex, setZIndex] = useState(1);
  const [load, setLoad] = useState(false)
  
  return (
    <>
      <AssignedAssessments className={zIndex == 1 ? "z-i-h" : ""} change={props} userId={props.userId} setZIndex={setZIndex} zIndex={zIndex} load={load} setLoad={setLoad} />
      {/* <hr />
      <AvailableAssessment className={zIndex == 2 ? "z-i-h" : ""} change={props} userId={props.userId} setZIndex={setZIndex} zIndex={zIndex} setLoad={setLoad}/> */}
    </>
  );
};

export default AssessmendTables;
