import axiosInstance from "../utils/axios";
export const emailPendingList = async (values) => {
  return await axiosInstance().get("/getEmailList?type=pending", {
    params: values,
  });
};
export const emailGeneratedList = async (values) => {
  return await axiosInstance().get("/getEmailList?type=generated", {
    params: values,
  });
};

export const emailStoppedList = async (values) => {
  return await axiosInstance().get("/getEmailList?type=stopped", {
    params: values,
  });
};

export const emailList = async (values) => {
  return await axiosInstance().get("/getEmailTemplateList", {
    params: values,
  });
};

export const getBulkNotification = async (values) => {
  return await axiosInstance().get("/getBulkNotification", {
    params: values,
  });
};
export const emailListDetails = async (id) => {
  return await axiosInstance().get(`/getEmailTemplateDetails?id=${id}`);
};
export const getReceiverDetail = async (values) => {
  return await axiosInstance().post(`/getReceiverDetail`, values);
};
export const emailDetailsEdit = async (values) => {
  return await axiosInstance().post(`/editEmailTemplate`, values);
};
export const changeStatusEmail = async (values) => {
  return await axiosInstance().post(`/changeStatusEmail`, values);
};
export const getIntakeList = async (values) => {
  return await axiosInstance().post(`/getIntakeList`, values);
};

export const GetIntakeListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getIntakeList`, values, {
    cancelToken: cancelToken
  });
};

export const getEmailDetails = async (id) => {
  return await axiosInstance().get(`/getEmailDetails?id=${id}`);
};

export const insertbulkNotification = async (values) => {
  return await axiosInstance().post(`/insertbulkNotification`, values);
};

export const insertIndividualNotification = async (values) => {
  return await axiosInstance().post(`/QuickNotification`, values);
};