import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { StudentSendTrackingCommunication, StudentFileUploads, StudentSendConfirmationCommunication, deletePdfLetter, checkCheckboxRejectDetails } from "../../../../services/RegistrationService";
import Swal from "sweetalert2";
import { RenderAttachList } from "../../../common/RenderAttachList";
import { FORM_DATE_FORMAT, IMAGE_URL } from "../../../../utils/Constants";
import $ from 'jquery'
import RenderPreviousValues from "./RenderPreviousValues";
function StudyKits(props) {
  const [kitTypeData, setKitTypeData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');
  const [otherDocumentFormList, setOtherDocumentFormList] = useState([]);

  const formik = useFormikContext()

  useEffect(() => {
    setOtherDocumentFormList(props.studentData?.attach_way_bill)
    let data = props.studentData.re_reg_data;
    let kitTypeOption = []
    if (data.status == 'new-status' || data.status == 'Closed-Ordered2' || data.status == 'Rejected' || data.status == 'assign-subjects' || data.status == 'Closed-Ordered' || data.status == 'Registration Withdrawn' || data.status == 'Final-Status') {
      if (formik.values.school_id == "engineering" || formik.values.school_id == "business") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "eKit only",
            value: "ekit"
          },
        ]
      } else {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "No kit required",
            value: "no_kit_required"
          }
        ]
      }
      if (data.student_kit_sent == "ekit") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
        if ($('#lapreq').val() != '') { $('#lapreq').show(); }
        else { $('#lapreq').hide(); }
        $('#lapreqlabel').hide();

      }
    } else {
      if (formik.values.school_id == "engineering") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "eKit only",
            value: "ekit"
          },
        ]
      } else if (formik.values.school_id == "draughting") {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "No kit required",
            value: "no_kit_required"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
        ]
      } else {
        kitTypeOption = [
          {
            label: "Select Kit Type",
            value: ""
          },
          {
            label: "Deliver to Student",
            value: "deliver"
          },
          {
            label: "Student to Collect",
            value: "collect"
          },
        ]
        if (props.reRegData.laptop == 'no_laptop') {
          kitTypeOption.push({
            label: "No kit required",
            value: "no_kit_required"
          })
        }
      }
      if (data.student_kit_sent == "ekit") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
        $('#lapreq').hide();
        $('#lapreqlabel').hide();
      }

      if (data.student_kit_sent == "no_kit_required") {
        $('#collect_kit_div').hide();
        $('#sent_kit_div').hide();
      }
    }
    setKitTypeData(kitTypeOption)
    // if(data.student_kit_sent=="deliver"){
    //   $('#sent_kit_div').show();
    //   $('#collect_kit_div').hide();
    //   }

  }, [props, formik]);

  useEffect(() => {
    let data = props.studentData.re_reg_data;
    if (data.student_kit_sent == "deliver") {
      $('#sent_kit_div').show();
      $('#collect_kit_div').hide();
    }
    else if (data.student_kit_sent == "ekit") {
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();
      $('#lapreq').hide();
      $('#lapreqlabel').hide();

    }
    else if (data.student_kit_sent == "no_kit_required") {
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();
      $('#lapreq').show();
      $('#lapreqlabel').show();

    } else if (data.student_kit_sent == "collect") {
      $('#collect_kit_div').show();
      $('#sent_kit_div').hide();

    } else {
      if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
        $('#sent_kit_div').show();
        $('#collect_kit_div').hide();
      } else
        if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
        } else
          if (data.laptop == 'no_laptop') {
            $('#collect_kit_div').hide();
            $('#sent_kit_div').hide();
            $('#lapreq').show();
            $('#lapreqlabel').show();
          } else {
            $('#collect_kit_div').hide();
            $('#sent_kit_div').hide();
          }
    }
  }, [])

  const sendNotification = async () => {
    if (!formik.values.trackingNo) {
      await formik.setFieldTouched("trackingNo", true, true)
      await formik.setFieldError("trackingNo", "Tracking No is required")
      return
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("tracking_no", formik.values.trackingNo)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("notify_email", formik.values.studyKitEmail == true ? 1 : 0)
    formdata.append("notify_sms", false ? 1 : 0)
    formdata.append("re_reg", 1)

    StudentSendTrackingCommunication(formdata)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  }

  const sendConfirmationCommunication = async() => {
    if (!formik.values.dateCollected) {
       console.log("dfhsggd")
      await formik.setFieldTouched("dateCollected", true, true)
      await formik.setFieldError("dateCollected", "Kit Collected Date is required");
      return 
    }
    const formdata = new FormData();
    formdata.append("snew", 0)
    formdata.append("userid", studentId)
    formdata.append("kit_collected_date", formik.values.dateCollected)
    formdata.append("email", formik.values.Email)
    formdata.append("mobile", formik.values.Mobile)
    formdata.append("notify_email", formik.values.studyKitEmail == true ? 1 : 0)
    formdata.append("re_reg", 1)

    StudentSendConfirmationCommunication(formdata)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  }


  const handledocEdit = (e) => {
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "tracking_doc")
    formData.append("file_input", e.target.files[0])
    StudentFileUploads(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
  };
  const setDate = (date) => {
    formik.setFieldValue("dateCollected", date)
  };


  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePdfLetter({ id: id }).then((res) => { });

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        props.setrefresh(!props.refresh);
      }
    });
  };

  const showHideStudyKit = (event) => {
    if (event) {
      formik.setFieldValue("kitType", event.value);
      let data = props?.studentData?.re_reg_data
      if (data.status == 'new-status' || data.status == 'Closed-Ordered2' || data.status == 'Rejected' || data.status == 'assign-subjects' || data.status == 'Closed-Ordered' || data.status == 'Registration Withdrawn' || data.status == 'Final-Status') {
        if (event.value == "deliver") {
          $('#sent_kit_div').show();
          $('#collect_kit_div').hide();
        }
        else if (event.value == "ekit") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
          $('#lapreq').hide();
          $('#lapreqlabel').hide();

        }
        else if (event.value == "no_kit_required") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
          $('#lapreq').show();
          $('#lapreqlabel').show();

        } else if (event.value == "collect") {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
        } else {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
        }
      } else {
        if (event.value == "deliver") {
          $('#sent_kit_div').show();
          $('#collect_kit_div').hide();
          $('#lapreq').show();
          $('#lapreqlabel').show();
        }
        else if (event.value == "ekit") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
          $('#lapreq').hide();
          $('#lapreqlabel').hide();

        }
        else if (event.value == "no_kit_required") {
          $('#collect_kit_div').hide();
          $('#sent_kit_div').hide();
          $('#lapreq').show();
          $('#lapreqlabel').show();

        } else {
          $('#collect_kit_div').show();
          $('#sent_kit_div').hide();
          $('#lapreq').show();
          $('#lapreqlabel').show();
        }
      }
    } else {
      formik.setFieldValue("kitType", "");
      $('#collect_kit_div').hide();
      $('#sent_kit_div').hide();
      $('#lapreq').show();
      $('#lapreqlabel').show();
    }
  }

  const studyKitSeleted = () => {
    if (props.studentData && props.studentData.re_reg_data) {
      let data = props.studentData.re_reg_data;
      if (data.status == 'new-status' || data.status == 'Closed-Ordered2' || data.status == 'Rejected' || data.status == 'assign-subjects' || data.status == 'Closed-Ordered' || data.status == 'Registration Withdrawn' || data.status == 'Final-Status') {

        if (data.academy == "business" || data.academy == "engineering") {
          if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data.ekit != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "ekit", label: "eKit only" }
          }
        } else {
          if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data.student_kit_sent == 'no_kit_required') {
            return { value: "no_kit_required", label: "No kit required" }
          }
        }
      } else {
        if (data.academy == "engineering") {
          if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "no_kit_required", label: "No kit required" }
          }
          if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data.ekit != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
        } else if (data.academy == "draughting") {
          if (data.laptop != 'no_laptop' && data.kit_collected_date == '' && data.tracking_no == '') {
            return { value: "Select Kit Type", label: "Select Kit Type" }
          }
          if (data.laptop == 'no_laptop' && data.kit_collected_date == '' && data.tracking_no == '') {
            return { value: "no_kit_required", label: "No kit required" }
          }
          if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
        } else {
          if (data.tracking_no != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "deliver", label: "Deliver to Student" }
          }
          if (data.kit_collected_date != '' && data.student_kit_sent != 'no_kit_required') {
            return { value: "collect", label: "Student to Collect" }
          }
          if (data.laptop == 'no_laptop') {
            return { value: "no_kit_required", label: "No kit required" }
          }
        }
      }
      return { value: "", label: "Select Kit Type" }
    }
  }

  const laptopSrNoDiv = <div className="col-md-4" id="lapreqlabel">
    <label htmlFor="laptopSerialNr">Laptop Serial Nr</label>
    <div className="form-icon-group mb-4">
      <input
        className={"form-control" + (formik.errors.laptopSerialNr && formik.touched.laptopSerialNr ? " is-invalid" : "")}
        name="laptopSerialNr"
        id="laptopSerialNr"
        value={formik.values.laptopSerialNr}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"Please Enter Laptop Serial Nr"}
      />
    </div>
    <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.re_reg_subjects} previousValue={props.studentData?.RegStudentsPreviousDetails?.re_reg_subjects} />
  </div>

  const studentKitSendDiv = <div id="sent_kit_div">
    <div className="edit-icon new-card-header">
      <div className="card-subheading">Student Kit Sent</div>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-4">
        <label htmlFor="trackingNo">Tracking No </label>
        <div className="form-icon-group mb-4" title="trackingNo">
          <input type="text" className={"form-control" + (formik.errors.trackingNo && formik.touched.trackingNo ? " is-invalid" : "")} name="trackingNo" id="trackingNo" placeholder="Tracking Number  " title="trackingNo" value={formik.values.trackingNo} onChange={(e) => formik.setFieldValue("trackingNo", e.target.value)} />
        </div>
        <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.tracking_no} previousValue={props.studentData?.RegStudentsPreviousDetails?.tracking_no} />
      </div>
      {formik.values.kitType === "deliver" ? <div className="col-md-6 col-lg-4">
        <label htmlFor="kitType">Date Dispatched</label>
        <div className="form-group-blk mb-3">
          <DatePicker
            selected={formik.values.dateCollected}
            onChange={setDate}
            className="form-control w-100"
            title="Date Dispatched"
            dateFormat={FORM_DATE_FORMAT}
            placeholderText="Select Date Dispatched"
            onChangeRaw={(e) => e.preventDefault()}
          />
        </div>
        <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.kit_collected_date} previousValue={props.studentData?.RegStudentsPreviousDetails?.kit_collected_date} />
      </div> : ""}
      <div className="col-md-6 col-lg-2">
        <label htmlFor="trackingBill">Waybill</label>
        <div className="form-icon-group mb-4" title="trackingBill">
          {/* <input type="file" name="trackingBill" id="trackingBill"  title="Invoice Form" onChange={handledoc}  /> */}
          <input
            type="file"
            name="trackingBill"
            id="trackingBill"
            onChange={handledocEdit}
          />
        </div>
        <div className="frm-group">
          <ul className="list-unstyled attached-file-ul m-0">
            {otherDocumentFormList && otherDocumentFormList.map((item) => {
              return (
                <li key={item.id} className="mb-2">
                  <div className="col-md-11">
                    {RenderAttachList({
                      fileName: basename(item.url),
                      // size: "1205",
                      filePath: IMAGE_URL + "/" + item.url.replace("home/myaie/public_html/", "").replace("public", ""),
                      showClose: false,
                    })}
                  </div>
                  <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>

                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-md-6 col-lg-2">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
          <label> </label>
          <div className="custom-check custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="trackingEmail" name="select" checked={formik.values.trackingEmail} onChange={(e) => formik.setFieldValue("trackingEmail", e.target.checked)} />
            <label className="custom-control-label" htmlFor="trackingEmail">
              Email
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
          <button className="btn btn-save btn-success" type="button" title="Update" onClick={() => sendNotification()}>
            Send Tracking
          </button>
        </div>
      </div>
    </div>
  </div>

  const studyKitCollectDiv = <div id="collect_kit_div">
    <div className="edit-icon new-card-header">
      <div className="card-subheading">Study Kit Collected</div>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-4">
        <label htmlFor="kitType">Date Collected</label>
        <div className="form-group-blk mb-3">
          {
            <DatePicker
              selected={formik.values.dateCollected}
              onChange={setDate}
              className={"form-control w-100" +
              (formik.errors.dateCollected &&
              formik.touched.dateCollected
                ? " is-invalid"
                : "")
            }
              title="Date Collected"
              dateFormat={FORM_DATE_FORMAT}
              placeholderText="Select Date Collected"
              onChangeRaw={(e) => e.preventDefault()}
            />
          }
        </div>
      </div>
      <div className="col-md-6 col-lg-2">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
          <label> </label>
          <div className="custom-check custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="studyKitEmail" name="select" checked={formik.values.studyKitEmail} onChange={(e) => formik.setFieldValue("studyKitEmail", e.target.checked)} />
            <label className="custom-control-label" htmlFor="studyKitEmail">
              Email
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <label>&nbsp;</label>
        <div className="form-group-blk mb-3">
          <button className="btn btn-save btn-success" type="button" title="Update" onClick={sendConfirmationCommunication}>
            Send Confirmation
          </button>
        </div>
      </div>
    </div>
  </div>

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Study Kits</div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="kitType">Kit Type</label>
          <div className="form-icon-group mb-4">
            <Select
              className={
                "form-control custom-select-box " +
                (formik.errors.kitType && formik.touched.kitType
                  ? " is-invalid"
                  : "")
              }
              name="kitType"
              defaultValue={studyKitSeleted()}
              onChange={showHideStudyKit}
              onBlur={formik.handleBlur}
              options={kitTypeData}
              maxMenuHeight={175}
              isClearable
            />
          </div>
          <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.ekit} previousValue={props.studentData?.RegStudentsPreviousDetails?.ekit} type="dropdown" typeSupportingProps={{filterArray : kitTypeData}} />
        </div>
        {formik.values.laptop !== "no_laptop" && laptopSrNoDiv}
      </div>
      {studyKitCollectDiv}
      {studentKitSendDiv}
      {/* {formik.values.kitType === "deliver" ? studyKitdispatchDiv : ""} */}
      {formik.values.laptop !== "" && formik.values.laptop !== "no_laptop" ? <div className="row">
        <div className="col-md-4">
          <label>&nbsp;</label>
          <div className="form-group-blk mb-3">
            <label> </label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" className="custom-control-input" id="laptop_check" name="select" checked={formik.values.laptop_check} onChange={(e) => formik.setFieldValue("laptop_check", e.target.checked)} />
              <label className="custom-control-label" htmlFor="laptop_check">
                Laptop Check
              </label>
            </div>
          </div>
        </div>
      </div> : ""}
      {formik.values.laptop !== "no_laptop" ? <div className="row">
        <div className="col-md-6 col-lg-4">
          <label>&nbsp;</label>
          <div className="form-group-blk mb-3" style={{ paddingTop: "11px" }}>
            <label></label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="laptop_ordered"
                name="select"
                checked={formik.values.laptop_ordered}
                onChange={e => {
                  let data = {
                    action: "laptop_ordered",
                    id: studentId,
                    order: e.target.checked ? 1 : 0,
                  }
                  checkCheckboxRejectDetails(data).then(res => {
                    Swal.fire("Updated", res.data.message, "success")
                  }).catch(err => {
                    Swal.fire("Not Updated", err.response.data.message, "error")
                    console.error("error: ", err)
                  })
                  formik.setFieldValue("laptop_ordered", e.target.checked)
                }}
              />
              <label className="custom-control-label" htmlFor="laptop_ordered">Laptop Ordered</label>
            </div>
          </div>
        </div>
      </div> : ""}
    </div>
  );
}

export default StudyKits;
