import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import $ from "jquery";
import {
  downloadURL,
  FORM_DATE_TIME_FORMAT,
  FORM_TIME_FORMAT,
  IMAGE_URL,
} from "../../../../utils/Constants";
import Select from "react-select";
import {
  AddAssessment,
  GetAssessmentDetails,
  GetAssessmentsData,
  GetCourseDetails,
  GetQuestionsByQuiz,
  GetQuiz,
  SendAssessmentInstruction,
} from "../../../../services/CourseService";
import Swal from "sweetalert2";
import { Link, useHistory, useParams } from "react-router-dom";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { myCompare } from "../../../common/MyCompare";
import { CheckJSONString } from "../../../common/CheckJSONString";
import draftToHtml from "draftjs-to-html";
import { TrimText } from "../../../common/TrimText";
import AddQuizzTable from "./AddQuizzTable";
import { setAssessmentName } from "../../../../store/actions";
import ListOfTemplateModal from "../../CourseGradeSettings/ListOfTemplateModal";
import { GradeLetterTemplateList } from "../../../../services/GradeSettingService";
import ProgrammeGradeMixModal from "../../Programs/ProgrammeGradeMixModal";

const AssessmentForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { id, tab, type, subId, subTab } = useParams();
  const [assessmentType, setAssessmentType] = useState([]);
  const [moderatorAndMarker, setModeratorAndMarker] = useState([]);
  const [moderatorSelected, setModeratorSelected] = useState([]);
  const [assessmentTypeSelected, setassessmentTypeSelected] = useState();
  const [gradeSelected, setGradeSelected] = useState();
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [assessmentFile, setAssessmentFile] = useState();
  const [gradeType, setGradeType] = useState([]);
  const [gradeMixGradeType, setGradeMixGradeType] = useState([]);
  const [assessmentFileName, setAssessmentFileName] = useState();
  const [memoFile, setMemoFile] = useState(null);
  const [memofileName, setMemofileName] = useState();
  const [isEditable, setIsEditable] = useState(true);
  const [showAttachment, setShowAttachment] = useState(true);
  const [editorState, setEditorState] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [openDate, setOpenDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [loading, setLoading] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [quizTotal, setQuizTotal] = useState("");
  const [quizLabel, setQuizLabel] = useState("");
  const [quizLoading, setQuizLoading] = useState(false);
  const [assessmentLoad, setAssessmentLoad] = useState(false);
  const [quizLoad, setquizLoad] = useState(false);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [isDurationDisabled, setIsDurationDisabled] = useState(false);
  const [isAppealDisabled, setIsAppealDisabled] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [passMarkValue, setPassMarkValue] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedGradeMixTemplate, setSelectedGradeMixTemplate] = useState({});
  const [selectedTemplateObj, setSelectedTemplateObj] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(false);
  const [checkedAllRows, setCheckedAllRows] = useState([]);
  const [tempCheckedAllData, setTempCheckedAllData] = useState([]);
  const [lettertemplateIndex, setLettertemplateIndex] = useState();
  const [reload, setReload] = useState(false);
  const [gradingStandard, setGradingStandard] = useState();
  const [gradeMixError, setGradeMixError] = useState([]);

  useEffect(() => {
    let newData = tempCheckedAllData;
    if (newData.length && newData[lettertemplateIndex]?.selectedTemplate) {
      setTempCheckedAllData((prevData) => {
        const newData = [...prevData];
        newData[lettertemplateIndex].selectedTemplate =
          selectedGradeMixTemplate;
        let templateGrdaeMixPassMarkArr = [];
        let templateGrdaeMixArray =
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions?.length &&
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions.map(
            (item) => {
              templateGrdaeMixPassMarkArr.push({
                value: item?.id,
                label: item?.text,
              });
              newData[lettertemplateIndex].templateOptions =
                templateGrdaeMixPassMarkArr;
            }
          );
        return newData;
      });
    } else if (newData.length) {
      setTempCheckedAllData((prevData) => {
        const newData = [...prevData];
        newData[lettertemplateIndex] = {
          ...newData[lettertemplateIndex],
          selectedTemplate: selectedGradeMixTemplate,
        };
        let templateGrdaeMixPassMarkArr = [];
        let templateGrdaeMixArray =
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions?.length &&
          newData[lettertemplateIndex].selectedTemplate?.gradeOptions.map(
            (item) => {
              templateGrdaeMixPassMarkArr.push({
                value: item?.id,
                label: item?.text,
              });
              newData[lettertemplateIndex].templateOptions =
                templateGrdaeMixPassMarkArr;
            }
          );
        return newData;
      });
      // newData[lettertemplateIndex] = {...newData[lettertemplateIndex], selectedTemplate}
    }
  }, [selectedGradeMixTemplate, reload]);

  const Corona = "https://corona.myaie.ac:3344";
  let templatePassMarkArr = [];
  let templateArray =
    selectedTemplate?.gradeOptions?.length &&
    selectedTemplate?.gradeOptions.map((item) => {
      templatePassMarkArr.push({
        value: item?.id,
        label: item?.text,
      });
    });

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const passMarkRobotArr = [
    {
      label: "Red",
      value: 0,
    },
    {
      label: "Yellow",
      value: 49,
    },
    {
      label: "Green",
      value: 100,
    },
  ];

  useEffect(() => {
    if (props.lmType === "selfplaced") {
      formik.setFieldValue("allowAppeal", false);
    } else {
      formik.setFieldValue("allowAppeal", true);
    }
    setDataLoading(true);
    GetAssessmentsData()
      .then((res) => {
        let x = {
          value: "",
          label: "",
        };
        x = res?.data?.assessment_moderator_marker.map((data) => {
          return { ...x, value: `${data.UserID}`, label: `${data.name}` };
        });
        setModeratorAndMarker(x);
        //setting AssessmentType
        let tempAssessmentTypeArr = [];
        const assArr = res?.data?.assessment_type;
        Object.keys(assArr).map((key, index) => {
          tempAssessmentTypeArr.push({
            value: key,
            label: assArr[key],
            key: key,
          });
        });
        setAssessmentType(tempAssessmentTypeArr);
        //setting Grade
        let tempGradeArr = [
          {
            value: "2",
            label: "Letter",
            key: 2,
          },
          {
            value: "3",
            label: "Value",
            key: 3,
          },
        ];
        const gradeArr = res?.data?.grade;
        Object.keys(gradeArr).map((key, index) => {
          tempGradeArr.push({ value: key, label: gradeArr[key], key: key });
        });
        setGradeType(tempGradeArr);
        setGradeMixGradeType(tempGradeArr);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        console.log("error: ", error);
      });
    if (type !== "add") {
      GetAssessmentDetails(subId)
        .then((response) => {
          const tempAssessmentDetails = response?.data;
          // const tempDate2 = new Date(tempAssessmentDetails?.open_date_time).getDate();
          setAssessmentDetails(response?.data);
          formik.setFieldValue("isHybrid", response.data.isHybrid);
          // const blocksFromHTML = isJson(response?.data?.details)
          //   ? convertFromRaw(JSON.parse(response?.data?.details))
          //   : "";
          // setEditorState(EditorState.createWithContent(blocksFromHTML));
          dispatch(setAssessmentName(response.data.name));

          setAssessmentFile(response.data?.path);
          setAssessmentFileName(response.data?.path);
          setMemoFile(response.data?.path_marking);
          setMemofileName(response.data?.path_marking);
          setGradeSelected(tempAssessmentDetails?.grade);
          setModeratorSelected(
            tempAssessmentDetails && tempAssessmentDetails.assessment_moderator
              ? tempAssessmentDetails.assessment_moderator
              : []
          );
          setassessmentTypeSelected(response?.data?.ass_type);
          response?.data.assessment_marker_multiple &&
            setSelectedDomain(
              response?.data.assessment_marker_multiple.split(",")
            );
          quizFormik.setFieldValue("quiz_id", response.data?.quiz_id);
          // formik.setFieldValue("isDuration", true)
          // formik.setFieldValue("timeHour", response.data.timeHour)
          // formik.setFieldValue("timeMin", response.data.timeMin)
          if (response.data?.quiz_id) {
            formik.setFieldValue("quiz_id", true);
          }
          setQuizLoading(true);
          let check = CheckJSONString(response?.data?.details);
          if (check) {
            const blocksFromHTML2 = convertFromRaw(
              JSON.parse(response?.data?.details)
            );
            let dataDescr = EditorState.createWithContent(blocksFromHTML2);
            const detail = draftToHtml(
              convertToRaw(dataDescr.getCurrentContent())
            );
            setEditorState(detail ? detail : "<p><p/>");
            setApiDataLoaded(true);
          } else {
            setEditorState(response?.data?.details);
            setApiDataLoaded(true);
          }

          formik.setFieldValue(
            "maximum_attempts",
            response?.data?.maximum_attempts == 0
              ? "0"
              : response?.data?.maximum_attempts
          );
          // setQuizLabel(quizData)

          // response?.data.assessment_marker && setSelectedDomain([...selectedDomain, response?.data.assessment_marker_multiple.split(",")]);
          const data = {
            exportStatus: true,
          };

          GradeLetterTemplateList(data)
            .then((res) => {
              let tempObj = res.data.list_data?.find(
                (item) => item.id === response.data.grading_template_id
              );
              setSelectedTemplate(tempObj);
              let ls = [...checkedAllRows];
              let newData = response?.data?.getGradeMix.map(
                (gradeMix, index) => {
                  ls.push(Number(gradeMix?.grade_id));
                  let templateGrdaeMixPassMarkArr = [];
                  let tempMixObj = res.data.list_data?.find(
                    (item) => item.id === gradeMix?.grading_template_id
                  );
                  let templateGrdaeMixArray =
                    tempMixObj?.gradeOptions?.length &&
                    tempMixObj?.gradeOptions.map((item) => {
                      templateGrdaeMixPassMarkArr.push({
                        value: item?.id,
                        label: item?.text,
                      });
                    });
                  return {
                    ...gradeMix,
                    selectedTemplate: tempMixObj ? tempMixObj : {},
                    templateOptions: templateGrdaeMixPassMarkArr,
                  };
                }
              );
              setCheckedAllRows([...ls]);
              setTempCheckedAllData([...newData]);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
          // setSelectedTemplateId(response.data.grading_template_id)
        })
        .catch((error) => {
          console.log("error :", error);
        });
    } else {
      setApiDataLoaded(true);
    }
  }, [quizList]);

  const getTimeMinutes = (data) => {
    if (`${data}`.length < 2) {
      return "0" + data;
    } else {
      return `${data}`;
    }
  };

  const quizFormik = useFormik({
    initialValues: {
      quiz_id: "",
    },
    validationSchema: Yup.object({
      quiz_id: Yup.string().required("Quiz is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setquizLoad(true);
      $("#quiz").modal("hide");
      GetQuestionsByQuiz(values.quiz_id)
        .then((res) => {
          setQuizTotal(res.data.totalQuizScore);
          formik.setFieldValue(
            "AssessmentTotal",
            res.data.totalQuizScore.toString()
          );
          setTempCheckedAllData((prevData) => {
            const newData = prevData.map((item) => ({
              ...item,
              assessment_total: res.data.totalQuizScore || "",
            }));
            return newData;
          });
          formik.setFieldValue(
            "isDuration",
            res.data.totalQuizScore.toString()
          );
          formik.setFieldValue(
            "TimeLimitHours",
            res.data.description.time_limit_hours != null &&
              res.data.description.time_limit_hours > 0
              ? res.data.description.time_limit_hours
              : res.data.description.time_limit_minutes > 0
              ? "00"
              : ""
          );
          formik.setFieldValue(
            "TimeLimitMinutes",
            res.data.description.time_limit_minutes != null &&
              res.data.description.time_limit_minutes > 0
              ? res.data.description.time_limit_minutes
              : res.data.description.time_limit_hours > 0
              ? "00"
              : ""
          );
          // formik.setFieldValue("isDuration", 1);
          setShowAttachment(false);
          setQuizLoading(true);
          formik.setFieldValue("quiz_id", values.quiz_id);
          formik.setFieldValue(
            "maximum_attempts",
            res.data.description.maximum_attempts
          );
          formik.setFieldValue("isHybrid", res.data.description.isHybrid);

          setquizLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setquizLoad(false);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("appealOpenDate", formik.values.closeDateAndTime);
    if (quizFormik.values.quiz_id != "") {
      GetQuiz({ getPaginate: "true", quizId: quizFormik.values.quiz_id }).then(
        (res) => {
          res.data.map((val) => {
            if (val.quid == quizFormik.values.quiz_id) {
              // formik.setFieldValue("AssessmentTotal", val.quid);
              formik.setFieldValue("quiz_id", true);
              setIsDurationDisabled(true);
              setQuizLabel(val.quiz_name.trim());
            }
          });
        }
      );
    }
  }, [quizFormik.values.quiz_id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      AssessmentName:
        assessmentDetails && assessmentDetails?.name
          ? assessmentDetails?.name
          : "",
      AssessmentType: assessmentDetails
        ? assessmentDetails?.ass_type == 0
          ? "0"
          : assessmentDetails?.ass_type
        : "",
      AssessmentTotal: assessmentDetails
        ? assessmentDetails.ass_total_mark == 0
          ? "0"
          : assessmentDetails?.ass_total_mark
        : "",
      Weight:
        assessmentDetails && assessmentDetails?.ass_weighting
          ? assessmentDetails?.ass_weighting
          : "",
      Code:
        assessmentDetails && assessmentDetails?.code
          ? assessmentDetails?.code
          : "",
      GradeType: assessmentDetails && assessmentDetails?.grade,
      Marker:
        assessmentDetails && assessmentDetails?.assessment_marker
          ? assessmentDetails?.assessment_marker_multiple
            ? (
                assessmentDetails?.assessment_marker +
                "," +
                assessmentDetails?.assessment_marker_multiple
              ).split(",")
            : [assessmentDetails?.assessment_marker]
          : [],
      Moderator:
        assessmentDetails && assessmentDetails?.assessment_moderator
          ? assessmentDetails?.assessment_moderator
          : "",
      Moderated:
        assessmentDetails && assessmentDetails?.notify_moderator
          ? assessmentDetails?.notify_moderator
          : "0",
      AssessmentFile:
        assessmentDetails && assessmentDetails?.path
          ? assessmentDetails?.path
          : "",
      MemoFile:
        assessmentDetails && assessmentDetails?.path_marking
          ? assessmentDetails?.path_marking
          : "",
      TimeLimitHours:
        assessmentDetails.timeHour > 0 && assessmentDetails.timeHour != null
          ? `${assessmentDetails?.timeHour}`.length
            ? getTimeMinutes(assessmentDetails?.timeHour)
            : ""
          : assessmentDetails?.timeMin > 0
          ? "00"
          : "",
      TimeLimitMinutes:
        assessmentDetails?.timeMin > 0 && assessmentDetails?.timeMin != null
          ? `${assessmentDetails?.timeMin}`.length
            ? getTimeMinutes(assessmentDetails?.timeMin)
            : ""
          : assessmentDetails.timeHour > 0
          ? "00"
          : "",
      Identification:
        assessmentDetails && assessmentDetails?.identification ? true : false,
      Camera:
        assessmentDetails && assessmentDetails?.camera
          ? assessmentDetails?.camera
          : "",
      ScreenShare:
        assessmentDetails && assessmentDetails?.screen_share
          ? assessmentDetails?.screen_share
          : "",
      Audio:
        assessmentDetails && assessmentDetails?.audio
          ? assessmentDetails?.audio
          : "",
      openDateAndTime:
        assessmentDetails &&
        assessmentDetails.openDateAndTime &&
        assessmentDetails.openDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.openDateAndTime))
          : "",
      closeDateAndTime:
        assessmentDetails &&
        assessmentDetails.closeDateAndTime &&
        assessmentDetails.closeDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.closeDateAndTime))
          : "",
      deadlineDateAndTime:
        assessmentDetails &&
        assessmentDetails.deadlineDateAndTime &&
        assessmentDetails.deadlineDateAndTime !== "NaN"
          ? new Date(parseInt(assessmentDetails.deadlineDateAndTime))
          : "",
      appealCloseDate:
        assessmentDetails &&
        assessmentDetails.appealCloseDate &&
        assessmentDetails.appealCloseDate !== "NaN"
          ? new Date(parseInt(assessmentDetails.appealCloseDate))
          : "",
      appealOpenDate:
        assessmentDetails &&
        assessmentDetails.appealOpenDate &&
        assessmentDetails.appealOpenDate !== "NaN"
          ? new Date(parseInt(assessmentDetails.appealOpenDate))
          : "",
      allowAppeal:
        assessmentDetails && assessmentDetails.allowAppeal ? true : false,
      // deadlineDateAndTime: new Date(),
      // assessmentDetails && assessmentDetails.deadlineDateAndTime ? new Date(parseInt(assessmentDetails.deadlineDateAndTime)) : "",
      isDuration:
        assessmentDetails && assessmentDetails.timeLimitActive ? true : false,
      allowAppeal:
        assessmentDetails && assessmentDetails.allowAppeal ? true : false,
      multiplicator:
        assessmentDetails && assessmentDetails?.multiplicator
          ? assessmentDetails?.multiplicator
          : "",
      offset:
        assessmentDetails && assessmentDetails?.offset
          ? assessmentDetails?.offset
          : "",
      passMark:
        assessmentDetails && assessmentDetails?.pass_mark
          ? assessmentDetails?.pass_mark
          : "",
      details: "",
      quiz_id: "",
      maximum_attempts: "",
      quizCheck: assessmentDetails
        ? assessmentDetails.quiz_id
          ? true
          : false
        : true,
      isHybrid: false,
      gradeMix: [],
      grading_standard: props?.gradingStandard ? props?.gradingStandard : 4,
    },
    validationSchema: Yup.object({
      AssessmentName: Yup.string()
        .required("Assessment Name is required")
        .trim(),
      AssessmentType: Yup.string().required("Assessment Type is required"),
      quiz_id: Yup.string().when("quizCheck", {
        is: true,
        then: Yup.string().required("Quiz is required"),
      }),
      // AssessmentTotal: Yup.number()
      //   .required("Assessment Total is required")
      //   .min(0, "Assessment Total cannot be negative"),
      AssessmentTotal: Yup.number().when("grading_standard", {
        is: 2,
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
            .required("Assessment Total is required")
            .min(0, "Assessment Total cannot be Negative"),
      }),
      //Weight: Yup.number().required("Weight is required").min(0, "Weight cannot be negative"),
      // Code: Yup.string().required("Code is required").trim(),
      // GradeType: Yup.string().required("Grade Type is required"),
      GradeType: Yup.string().when("grading_standard", {
        is: 2,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required("Grade Type is required"),
      }),
      //Marker: Yup.string().required("Marker Name is required"),
      openDateAndTime: Yup.string().when("isRequired", {
        is: () => props.lmType !== "selfplaced",
        then: Yup.string().required("Open Date & Time is required"),
      }),
      closeDateAndTime: Yup.string().when("isRequired", {
        is: () => props.lmType !== "selfplaced",
        then: Yup.string()
          .required("Close Date & Time is required")
          .test(
            "is-greater",
            "Close Date & Time should be greater than open date time",
            function (value) {
              const { openDateAndTime } = this.parent;
              return moment(value).isSameOrAfter(moment(openDateAndTime));
            }
          ),
      }),
      maximum_attempts: Yup.string().when("quizCheck", {
        is: true,
        then: Yup.string().required("Maximum Attempts should be at least 1"),
      }),
      passMark: Yup.number().when("grading_standard", {
            is: 2,
            then: Yup.number().notRequired(),
            otherwise: Yup.number().when(["GradeType", "AssessmentTotal"], {
              is: (grade, assessmentTotal) => grade == 0 && assessmentTotal,
              then: Yup.number()
                .required("Pass mark is required")
                .max(100, "Pass mark cannot be more than 100%"),
                // .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total or 100"),
              otherwise: Yup.number().when(["GradeType", "AssessmentTotal"], {
                is: (grade, assessmentTotal) => grade == 3 && assessmentTotal,
                then: Yup.number()
                .required("Pass mark is required")
                .max(Yup.ref("AssessmentTotal"), "Pass mark cannot be more than Assessment Total"),         
                otherwise: Yup.number().required("Pass mark is required"),
              }) 
          }),
        }),
      gradeMix: Yup.mixed().test(
        "required",
        "Please Assign at least One Grade Mix",
        (value) => {
          if (!tempCheckedAllData.length && props.gradingStandard == 2) {
            return false;
          } else {
            let isValid = true;
            let errorArr = [];
      
            tempCheckedAllData.forEach((item, index) => {
              let errorObj = {
                field_name: [],
                errorMessage: [],
              };
      
              if (
                item.grading_type != 0 &&
                item.grading_type != 1 &&
                item.grading_type != 2 &&
                item.grading_type != 3
              ) {
                errorObj[`field_name`].push("Grade Type");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Grade Type is required`
                );
                isValid = false;
              }
      
              if (
                !item.assessment_total ||
                item.assessment_total == "" ||
                item.assessment_total == undefined ||
                item.assessment_total == null
              ) {
                errorObj[`field_name`].push("Assessment Total");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Assessment Total is required`
                );
                isValid = false; 
              }

              if (
                item.assessment_total <= 0
              ) {
                errorObj[`field_name`].push("Assessment Total");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Assessment Total Cannot be Negative or 0`
                );
                isValid = false; 
              }
      
              if (
                item.pass_mark == "" ||
                item.pass_mark == undefined ||
                item.pass_mark == null
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark is required`
                );
                isValid = false; 
              }

              if (
                (item.grading_type == 0 || item.grading_type == 3) && item.assessment_total && item.pass_mark && +item.pass_mark >= +item.assessment_total
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark cannot be more than Assessment Total`
                );
                isValid = false;
              }

              if (
                item.grading_type == 0 && 100 <= +item.pass_mark
              ) {
                errorObj[`field_name`].push("Pass Mark");
                errorObj[`errorMessage`].push(
                  `${item.name}'s Pass Mark cannot be more than 100%`
                );
                isValid = false; 
              }
      
              if (Object.keys(errorObj.field_name).length) {
                errorArr.push(errorObj);
              } else {
                errorArr.push(false)
              }
            });
            setGradeMixError(errorArr);
      
            return isValid;
          }
        }
      ),
      deadlineDateAndTime: Yup.string()
        .nullable()
        .when("isHybrid", {
          is: (isHybrid) =>
            (isHybrid == true || isHybrid == "true") &&
            props.lmType !== "selfplaced",
          then: Yup.string()
            .nullable()
            .required("Marking Deadline is required")
            .test(
              "is-greater",
              "Marking Date & Time should be greater than closing date time",
              function (value) {
                const { closeDateAndTime } = this.parent;
                return moment(value).isSameOrAfter(moment(closeDateAndTime));
              }
            ),
      }),
      TimeLimitHours: Yup.string().when(("quizCheck", "isDuration"), {
        is: (quizCheck, isDuration) => quizCheck == true || isDuration == true,
        then: Yup.string().required("Time Limit Hours is required"),
      }),
      TimeLimitMinutes: Yup.string().when(("quizCheck", "isDuration"), {
        is: (quizCheck, isDuration) => quizCheck == true || isDuration == true,
        then: Yup.string().required("Time Limit Minutes is required"),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      const gradeMix =
        tempCheckedAllData?.length &&
        tempCheckedAllData?.map((data) => ({
          id: data.grade_id ? data.grade_id : data.id, 
          offset: parseInt(data?.offset) ? parseInt(data.offset) : "",
          grading_type: parseInt(data?.grading_type),
          multiplicator: parseInt(data?.multiplicator)
            ? parseInt(data.multiplicator)
            : "",
          pass_mark: parseInt(data?.pass_mark),
          grading_template_id: data?.selectedTemplate?.id,
          assessment_total: parseInt(data?.assessment_total),
        }));
      if (
        (values.quizCheck === true || values.isDuration === true) &&
        values.TimeLimitHours == 0 &&
        values.TimeLimitMinutes == 0
      ) {
        formik.setFieldError(
          "TimeLimitHours",
          "Hours must be greater than zero."
        );
        formik.setFieldError(
          "TimeLimitMinutes",
          "Minutes must be greater than zero."
        );
        return;
      } else {
        setAssessmentLoad(true);
        setLoading(true);
        let formData = new FormData();
        type === "open" && formData.set("assessment_id", subId);
        formData.set("intake", id);
        formData.set("name", values?.AssessmentName);
        formData.set("due", 1);
        formData.set("code", values?.Code);
        formData.set(
          "open_date_time",
          !isNaN(Date.parse(values.openDateAndTime) / 1000)
            ? Date.parse(values.openDateAndTime) / 1000
            : ""
        );
        formData.set(
          "appeal_open_date",
          !isNaN(Date.parse(values.appealOpenDate) / 1000)
            ? Date.parse(values.appealOpenDate) / 1000
            : ""
        );
        formData.set(
          "appeal_close_date",
          !isNaN(Date.parse(values.appealCloseDate) / 1000)
            ? Date.parse(values.appealCloseDate) / 1000
            : ""
        );
        formData.set("allow_appeal", values.allowAppeal ? 1 : 0);
        if (values.allowAppeal === false) {
          formData.set("appeal_open_date", 0);
          formData.set("appeal_close_date", 0);
        }
        formData.set(
          "close_date_time",
          !isNaN(Date.parse(values.closeDateAndTime) / 1000)
            ? Date.parse(values.closeDateAndTime) / 1000
            : ""
        );
        formData.set(
          "marking_deadline_date",
          !isNaN(Date.parse(values.deadlineDateAndTime) / 1000)
            ? Date.parse(values.deadlineDateAndTime) / 1000
            : ""
        );
        if (props?.gradingStandard != 2) {
          formData.set("ass_total_mark", values?.AssessmentTotal);
          formData.set("grade", values.GradeType);
          formData.set("pass_mark", values.passMark);
          formData.set("grading_template_id", selectedTemplate?.id);
        }
        if (props?.gradingStandard == 2) {
          formData.set("gradeMix", JSON.stringify(gradeMix));
        }
        formData.set("ass_weighting", values?.Weight);
        formData.set(
          "ass_type",
          assessmentTypeSelected || formik.values.AssessmentType
        );
        formData.set("ass_marker_sent", 0);
        formData.set("ass_moderator_sent", 1);
        formData.set("timeHour", values?.TimeLimitHours);
        formData.set("timeMin", values?.TimeLimitMinutes);
        formData.set("audio", values?.Audio ? 1 : 0);
        formData.set("camera", values?.Camera ? 1 : 0);
        formData.set("identification", values?.Identification ? 1 : 0);
        formData.set("screen_share", values?.ScreenShare ? 1 : 0);
        formData.append("path_marking", memoFile);
        formData.append("timeLimitActive", values.isDuration ? 1 : 0);
        formData.set("details", editorState);
        if (formik.values.quizCheck == true) {
          formData.append("quiz_id", quizFormik.values.quiz_id);
          formData.append("maximum_attempts", values.maximum_attempts);
        }

        if (formik.values.quizCheck == false) {
          formData.append("path", values?.AssessmentFile);
          formData.set("assessment_marker", [...new Set(values.Marker)]);
          formData.set("assessment_moderator", values?.Moderator);
        }
        if (type == "open") {
          formData.append("notify_moderator", values.Moderated);
        }
        AddAssessment(formData)
          .then((res) => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            // resetForm();
            // setImage(user);
            setAssessmentLoad(false);
            if (type == "add") {
              history.push(
                `/courseAdministration/coursesdetails/${id}/assessments/show`
              );
            }
          })
          .catch((err) => {
            setAssessmentLoad(false);
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      }
    },
  });

  const handleSendInstruction = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("assessment_marker", selectedDomain);
    data.append("intake", id);
    data.append(
      "marking_deadline_date",
      `${
        formik.values.MarkingDeadLineYear
          ? formik.values.MarkingDeadLineYear
          : ""
      }-${formik.values.MarkingDeadLineMonth}-${
        formik.values.MarkingDeadLineDate
      } ${formik.values.MarkingDeadLineHour}:${
        formik.values.MarkingDeadLineMinute
      }`
    );
    data.append(
      "close_date_time",
      `${formik.values.CloseYear}-${formik.values.CloseMonth}-${formik.values.CloseDate} ${formik.values.CloseHour}:${formik.values.CloseMinute}`
    );
    data.append("assignmentid", subId);
    data.append("sender", user?.UserID);
    SendAssessmentInstruction(data)
      .then((res) => {
        Swal.fire("Send!", "", "success");
      })
      .catch((error) => {
        console.log("error :", error);
        Swal.fire("Error!", "", "error");
      });
  };

  function markerNameSelected(value) {
    const markerNames = value?.map((obj) => {
      return obj.value;
    });
    setSelectedDomain(markerNames);
    formik.setFieldValue("Marker", markerNames);
  }

  const handleMemoDelete = () => {
    setMemoFile("");
    setMemofileName("");
    formik.setFieldValue("MemoFile", "");
  };

  const handleQuizDelete = () => {
    quizFormik.setFieldValue("quiz_id", "");
    setQuizLabel("");
    setIsDurationDisabled(false);
    formik.setFieldValue(
      "AssessmentTotal",
      assessmentDetails
        ? assessmentDetails.ass_total_mark == 0
          ? "0"
          : assessmentDetails?.ass_total_mark
        : ""
    );
    setTempCheckedAllData((prevData) => {
      const newData = prevData.map((item) => ({
        ...item,
        assessment_total: "",
      }));
      return newData;
    });
    formik.setFieldValue(
      "TimeLimitHours",
      assessmentDetails?.timeHour && `${assessmentDetails?.timeHour}`.length
        ? getTimeMinutes(assessmentDetails?.timeHour)
        : "00"
    );
    formik.setFieldValue(
      "TimeLimitMinutes",
      assessmentDetails?.timeMin && `${assessmentDetails?.timeMin}`.length
        ? getTimeMinutes(assessmentDetails?.timeMin)
        : "00"
    );
    // formik.resetForm();
    formik.setFieldValue(
      "isDuration",
      assessmentDetails && assessmentDetails.timeLimitActive ? true : false
    );
    formik.setFieldValue(
      "isAppeal",
      assessmentDetails && assessmentDetails.allowAppeal ? true : false
    );
    formik.setFieldValue(
      "isAppeal",
      assessmentDetails && assessmentDetails.allowAppeal ? true : false
    );
    formik.setFieldValue("quiz_id", "");
    setQuizLoading(true);
    quizFormik.resetForm();
  };
  const hourHandle = () => {
    let hourArr = [];
    for (let i = 0; i <= 23; i++) {
      hourArr.push({
        value: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "h",
      });
    }
    return hourArr;
  };

  const MinutesHandle = () => {
    let minutesArray = [];

    for (let i = 0; i <= 59; i++) {
      minutesArray.push({
        value: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "min",
      });
    }
    // ()=>{setMinuteArray(minutesArray)}
    return minutesArray;
  };

  const handleAssessment = () => {
    setAssessmentFile("");
    setAssessmentFileName("");
    formik.setFieldValue("AssessmentFile", "");
  };

  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  const addQuizzHandler = (qid, qlabel, quizData) => {
    quizFormik.setFieldValue("quiz_id", qid);
    formik.setFieldValue("quiz_id", qid);
    setQuizLabel(qlabel);
    setIsDurationDisabled(true);
    quizFormik.handleSubmit();
    $("#quiz").modal("hide");
  };

  // const clearAppeal = (e) => {
  //   e.preventDefault();
  //   formik.setFieldValue("appealCloseDate", '');
  //   formik.setFieldValue("appealOpenDate", false);
  // }
  const appealOlddate = new Date(formik.values.closeDateAndTime);
  const defaultAppealCloseDate = appealOlddate.setDate(
    appealOlddate.getDate() + 7
  );

  return (
    <div className="card card-profile-info-card">
      <div className="">
        <div className="card-body-inr card-body-info">
          <form onSubmit={formik.handleSubmit}>
            <div className="edit-icon new-card-header justify-content-between">
              <div className="card-header">
                Details{" "}
                {dataLoading ? <i className="fas fa-cog fa-spin"></i> : ""}
              </div>
              {/* {!isEditable && type == "open" ? (
                <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-save btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditable(true);
                    }}
                    title="Edit"
                  >
                    <i className="fal fa-edit"></i>Edit
                  </button>
                </PermissionsGate>
              ) : (
                ""
              )} */}
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentName">Assessment Name *</label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="text"
                    title="Assessment Name"
                    className={
                      "form-control" +
                      (formik.errors.AssessmentName &&
                      formik.touched.AssessmentName
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Assessment Name"
                    name="AssessmentName"
                    id="AssessmentName"
                    value={formik.values.AssessmentName || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.AssessmentName &&
                  formik.touched.AssessmentName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="Weight">Weight</label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.Weight && formik.touched.Weight
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Weight"
                    name="Weight"
                    title="Weight"
                    id="Weight"
                    value={formik.values.Weight || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.Weight && formik.touched.Weight ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="Code">Code</label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.Code && formik.touched.Code
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter Code"
                    name="Code"
                    title="Code"
                    id="Code"
                    value={formik.values.Code || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.Code && formik.touched.Code ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              
              {props.gradingStandard != 2 && (
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="AssessmentTotal">Assessment Total *</label>
                    <input
                      disabled={quizLabel}
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.AssessmentTotal &&
                        formik.touched.AssessmentTotal
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Assessment Total"
                      name="AssessmentTotal"
                      title="Assessment Total"
                      id="AssessmentTotal"
                      value={
                        formik.values.AssessmentTotal ||
                        quizList.filter((val) => {
                          if (val.label == quizLabel) {
                            return val.value;
                          } else {
                            return "";
                          }
                        }) ||
                        ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      min={0}
                    />
                    {/* {formik.errors.AssessmentTotal &&
                    formik.touched.AssessmentTotal ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null} */}
                  </div>
                </div>
              )}
              {props.gradingStandard != 2 && (
                <div className="col-md-6 col-lg-2">
                  <div
                    className={
                      "form-group-blk mb-3" +
                      (formik.errors.GradeType && formik.touched.GradeType
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <label htmlFor="GradeType">Grade Type *</label>
                    <Select
                      isDisabled={!isEditable && type === "open"}
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.GradeType && formik.touched.GradeType
                          ? " is-invalid"
                          : "")
                      }
                      name="GradeType"
                      id="GradeType"
                      title="Grade Type"
                      placeholder={
                        formik.values.GradeType
                          ? formik.values.GradeType
                          : "Grade Type"
                      }
                      options={gradeType}
                      isClearable
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("GradeType", value.value);
                          setGradeSelected(value.value);
                          formik.setFieldValue("passMark", "");
                          setSelectedTemplate({});
                        } else {
                          formik.setFieldValue("GradeType", "");
                          formik.setFieldValue("passMark", "");
                          setGradeSelected("");
                        }
                      }}
                      value={gradeType.filter(function (option) {
                        return option.value == formik.values.GradeType;
                      })}
                    />
                  </div>
                </div>
              )}

              {props.lmType !== "selfplaced" ? (
                <>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="OpenDate">Open Date &#38; Time *</label>

                      <DatePicker
                        selected={formik.values.openDateAndTime}
                        onChange={(date) => {
                          formik.setFieldValue("openDateAndTime", date);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        // className="form-control"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.openDateAndTime &&
                          formik.touched.openDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={!isEditable && type === "open"}
                        title="Open Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="CloseDate">Close Date &#38; Time *</label>

                      <DatePicker
                        // popperPlacement="auto" // or "bottom-end"
                        // popperModifiers={{ flip: { behavior: ['right'] }, preventOverflow: { enabled: true }, hide: { enabled: false }, arrow: { enabled: true, placement: 'auto' } }}
                        selected={formik.values.closeDateAndTime}
                        onChange={(date) => {
                          formik.setFieldValue("closeDateAndTime", date);
                          formik.setFieldValue("appealOpenDate", date);
                          let d = new Date(date);
                          let dAppeal = new Date(date);
                          d.setDate(d.getDate() + 14);
                          formik.setFieldValue("deadlineDateAndTime", d);
                          dAppeal.setDate(dAppeal.getDate() + 7);
                          formik.setFieldValue("appealCloseDate", dAppeal);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        // className="form-control"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.closeDateAndTime &&
                          formik.touched.closeDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          (type === "add" && !formik.values.openDateAndTime)
                        }
                        title="Close Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                        minDate={formik.values.openDateAndTime}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentType">Type *</label>
                  <Select
                    isDisabled={!isEditable && type === "open"}
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.AssessmentType &&
                      formik.touched.AssessmentType
                        ? " is-invalid"
                        : "")
                    }
                    name="AssessmentType"
                    id="AssessmentType"
                    title="Assessment Type"
                    value={assessmentType.filter(function (option) {
                      return option.value == formik.values.AssessmentType;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("AssessmentType", value.value);
                        setassessmentTypeSelected(value.value);
                      } else {
                        formik.setFieldValue("AssessmentType", "");
                        setassessmentTypeSelected("");
                      }
                    }}
                    placeholder={
                      formik.values.AssessmentType
                        ? formik.values.AssessmentType
                        : "Select Type"
                    }
                    options={assessmentType.sort(
                      sort_by("label", false, (a) => a.toUpperCase())
                    )}
                    isClearable
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.AssessmentType &&
                  formik.touched.AssessmentType ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              { props.lmType !== "selfplaced" ? (
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="MarkingDeadLineDate">
                        Marking Deadline Date &#38; Time
                      </label>

                      <DatePicker
                        selected={formik.values.deadlineDateAndTime}
                        onChange={(date) => {
                          formik.setFieldValue("deadlineDateAndTime", date);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.deadlineDateAndTime &&
                          formik.touched.deadlineDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          !formik.values.closeDateAndTime
                        }
                        title="Deadline Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                        isClearable={
                          formik.values.quizCheck == true &&
                          formik.values.deadlineDateAndTime
                        }
                        minDate={formik.values.closeDateAndTime}
                      />
                    </div>
                  </div>
                  ) : null}
              {props.gradingStandard != 2 && (
                <>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="multiplicator">
                        Multiplicator{" "}
                        <i
                          className="fal fa-info-circle grade-icon"
                          title="Specify the factor by which the raw score of the assignment will be multiplied. This can be used to adjust the weight of the assignment in the overall grading scheme. For example, a multiplicator of 2 would double the score of this assignment in the context of overall grading. The default value is typically 1."
                        ></i>
                      </label>
                      <input
                        disabled={!isEditable && type === "open"}
                        type="number"
                        className={
                          "form-control" +
                          (formik.errors.multiplicator &&
                          formik.touched.multiplicator
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Multiplicator"
                        name="multiplicator"
                        title="Multiplicator"
                        id="Multiplicator"
                        value={formik.values.multiplicator || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                      {formik.errors.multiplicator &&
                      formik.touched.multiplicator ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Offset">
                        Offset{" "}
                        <i
                          className="fal fa-info-circle grade-icon"
                          title="Enter a value to be added to each student's raw score for this assignment. This can be used to adjust the difficulty of the assignment or to provide a bonus. For example, an offset of 5 would add 5 points to every student's score. A negative value can be used to subtract points. The default value is typically 0."
                        ></i>
                      </label>
                      <input
                        disabled={!isEditable && type === "open"}
                        type="number"
                        className={
                          "form-control" +
                          (formik.errors.offset && formik.touched.offset
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Offset"
                        name="offset"
                        title="Offset"
                        id="Offset"
                        value={formik.values.offset || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.offset && formik.touched.offset ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              {/* {gradeSelected == "2" || gradeSelected == 1 ? (
                  <div className="col-md-6 col-lg-2">
                  <div
                    className={
                      "form-group-blk mb-3" +
                      (formik.errors.passMark && formik.touched.passMark
                        ? " is-invalid"
                        : "")
                    }
                  >
                      <label>
                        Pass Mark <i className="fal fa-info-circle grade-icon"></i>
                      </label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                        name="pass_mark"
                        value={
                          gradeSelected == "2" ? (
                          templatePassMarkArr.filter(function (option) {
                          return option.value == formik.values.passMark;
                        })
                          ) : (
                          passMarkRobotArr.filter(function (option) {
                              return option.value == formik.values.passMark;
                            })
                          )
                        }
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("passMark", value.value)
                          } else {
                            formik.setFieldValue("passMark", "")
                          }
                        }}
                        isClearable
                        //   onBlur={formik.handleBlur}
                        options={gradeSelected == "2" ? templatePassMarkArr : passMarkRobotArr}
                        maxMenuHeight={175}
                        placeholder={"Pass Mark"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="passMark">Pass Mark <i className="fal fa-info-circle grade-icon"></i></label>
                  <input
                    disabled={!isEditable && type === "open"}
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.passMark && formik.touched.passMark
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Pass Mark"
                    name="passMark"
                    title="Pass Mark"
                    id="passMark"
                    value={formik.values.passMark || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholderText="Select Date & Time"
                    timeIntervals={15}
                    onChangeRaw={(e) => e.preventDefault()}
                    minDate={formik.values.appealOpenDate}
                  />
                  {formik.errors.passMark && formik.touched.passMark ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
                )} */}
              {props.lmType !== "selfplaced" ? (
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk">
                    <div className="">
                      <label>&nbsp;</label>
                      <div className="custom-check-group">
                        <div
                          className="custom-check custom-control custom-checkbox mt-2"
                          style={{ width: "100px" }}
                        >
                          <input
                            disabled={isAppealDisabled}
                            type="checkbox"
                            className="custom-control-input"
                            id="appealCheckbox"
                            name="select"
                            checked={
                              formik.values.allowAppeal
                                ? formik.values.allowAppeal
                                : ""
                            }
                            onChange={(e) =>
                              formik.setFieldValue(
                                "allowAppeal",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="appealCheckbox"
                          >
                            <div className="mr-3"></div> Appeal
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {formik.values.allowAppeal == true && (
                <>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="MarkingDeadLineDate">
                        Appeal Open Date
                      </label>
                      <DatePicker
                        selected={
                          formik.values.appealOpenDate
                            ? formik.values.appealOpenDate
                            : formik.values.closeDateAndTime
                        }
                        onChange={(date) => {
                          let d = new Date(date);
                          let dAppeal = new Date(date);
                          formik.setFieldValue("appealOpenDate", dAppeal);
                          d.setDate(d.getDate() + 7);
                          formik.setFieldValue("appealCloseDate", d);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.appealOpenDate &&
                          formik.touched.appealOpenDate
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          !formik.values.closeDateAndTime
                        }
                        title="Close Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        isClearable={formik.values.appealOpenDate}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="MarkingDeadLineDate">
                        Appeal Close Date
                      </label>
                      <DatePicker
                        selected={
                          formik.values.appealCloseDate
                            ? formik.values.appealCloseDate
                            : defaultAppealCloseDate
                        }
                        onChange={(date) => {
                          let d = new Date(date);
                          formik.setFieldValue("appealCloseDate", d);
                          setOpenDate(date);
                        }}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_TIME_FORMAT}
                        timeFormat={FORM_TIME_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.deadlineDateAndTime &&
                          formik.touched.deadlineDateAndTime
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          (!isEditable && type === "open") ||
                          (type === "add" && !formik.values.closeDateAndTime)
                        }
                        title="Appeal Date & Time"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date & Time"
                        timeIntervals={15}
                        isClearable={formik.values.appealCloseDate}
                        onChangeRaw={(e) => e.preventDefault()}
                        minDate={formik.values.appealOpenDate}
                      />
                    </div>
                  </div>
                </>
              )}
              {props.gradingStandard != 2 && (
                <>
                  {gradeSelected == "1" ? (
                    <div className="col-md-6 col-lg-2">
                      <div
                        className={
                          "form-group-blk mb-3" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <label>
                          Pass Mark{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title="Enter or select the minimum grade a student must achieve to pass this assignment."
                          ></i>
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          name="pass_mark"
                          value={passMarkRobotArr.filter(function (option) {
                            return (
                              option.value === parseInt(formik.values.passMark)
                            );
                          })}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("passMark", value.value);
                            } else {
                              formik.setFieldValue("passMark", "");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={passMarkRobotArr}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                        />
                      </div>
                    </div>
                  ) : gradeSelected == "2" ? (
                    <div className="col-md-6 col-lg-2">
                      <div
                        className={
                          "form-group-blk mb-3" +
                          (formik.errors.passMark && formik.touched.passMark
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <label>
                          Pass Mark{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title="Enter or select the minimum grade a student must achieve to pass this assignment."
                          ></i>
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          name="pass_mark"
                          value={templatePassMarkArr.filter(function (option) {
                            return option.value == formik.values.passMark;
                          })}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("passMark", value.value);
                            } else {
                              formik.setFieldValue("passMark", "");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={templatePassMarkArr}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6 col-lg-2">
                      <div className="form-group-blk mb-3">
                        <label htmlFor="passMark">
                          Pass Mark
                          <i
                            className="fal fa-info-circle grade-icon"
                            title="Enter or select the minimum grade a student must achieve to pass this assignment."
                          ></i>
                        </label>
                        <input
                          disabled={!isEditable && type === "open"}
                          type="number"
                          className={
                            "form-control" +
                            (formik.errors.passMark && formik.touched.passMark
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Pass Mark"
                          name="passMark"
                          title="Pass Mark"
                          id="passMark"
                          value={formik.values.passMark || ""}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue("passMark", e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.passMark && formik.touched.passMark ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}
                </>
              )}
              {props.gradingStandard != 2 && gradeSelected == "2" && (
                <div className="search-filter-div-right mb-4">
                  <label>&nbsp;</label>
                  <div className="filter-search-bar-blk">
                    <div className="add-ticket-blk dropdown-comman">
                      <button
                        // className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#letterTemplatemodal"
                        className="btn btn-save btn-success"
                        type="button"
                        title="Select Template"
                        // onClick={() =>
                        //   history.push(
                        //     `/courseAdministration/coursesdetails/${id}/${tab}/add`
                        //   )
                        // }
                        // title="Select Template"
                      >
                        <i className="fal fa-file-alt"></i>Select Template
                      </button>
                      {selectedTemplate && (
                        <>
                          {selectedTemplate && selectedTemplate?.name && (
                            <div className="d-flex select-delete">
                              <Link
                                className="as-text-blue curser"
                                title="Open"
                                to={`/courseAdministration/gradingTemplate/edit/${selectedTemplate?.id}`}
                                // onClick={() => {
                                //   $("#letterTemplatemodal").modal("hide");
                                // }}
                              >
                                <span className="textLimit100">
                                  {selectedTemplate?.name}
                                </span>
                              </Link>
                              <button
                                className="btn btn-danger rounded-circle btn-dropdown-item"
                                title="Delete"
                                onClick={() => setSelectedTemplate({})}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {props.gradingStandard == 2 && (
              <>
                <hr />
                <div className="assessment-grade-setting-header">
                  <div className="card-header">
                    Grade Settings{" "}
                    {dataLoading ? <i className="fas fa-cog fa-spin"></i> : ""}
                  </div>
                  <button
                    data-toggle="modal"
                    data-target="#grademixmodal"
                    className="btn btn-save btn-success"
                    type="button"
                    title="Assign Grade Mix"
                    // disabled={assessmentLoad ? true : false}
                  >
                    <i className="fal fa-plus"></i> Assign Grade Mix
                  </button>
                </div>
                <div>
                  <p>
                    <i className="fal fa-info-circle "></i> Assessments created
                    within Programmes using the “Grade Mix” Grading standard
                    require Grade Mix’s to be selected and grade settings to be
                    assigned individually to each Grade Mix to make up the total
                    assessment grade.
                  </p>
                </div>
                {tempCheckedAllData?.length ? (
                  tempCheckedAllData.map((gradeMix, index) => {
                    return (
                      <>
                        <div className="assessment-grade-setting-header">
                          <div className="card-header">
                            <Link
                            className="gradeMix-sidebar-color"
                            to={`/courseAdministration/gradeMix/${gradeMix?.qualification_id}/details/${type !== "add" ? gradeMix.grade_id : gradeMix.id}/open`}
                            >
                            <i className="fal fa-layer-group"></i>{" "}
                            {gradeMix?.name}
                            </Link>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-lg-2">
                            <div
                              className={
                                "form-group-blk mb-3" +
                                (gradeMixError[index]?.field_name?.includes("Grade Type")
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label htmlFor={`GradeType_${gradeMix.id}`}>
                                Grade Type *
                              </label>
                              <Select
                                // isDisabled={!isEditable && type === "open"}
                                // isDisabled={props.gradingStandard == 2}
                                className={
                                  "form-control custom-select-box" +
                                  (gradeMixError[index]?.field_name?.includes("Grade Type")
                                    ? " is-invalid"
                                    : "")
                                }
                                name={`GradeType_${gradeMix.id}`}
                                id={`GradeType_${gradeMix.id}`}
                                title="Grade Type"
                                placeholder={"Grade Type"}
                                options={gradeMixGradeType}
                                isClearable
                                // onBlur={formik.handleBlur}
                                onChange={(value) => {
                                  setTempCheckedAllData((prevData) => {
                                    const newData = [...prevData];
                                    newData[index].grading_type =
                                      value?.value || null;
                                    return newData;
                                  });
                                  // tempCheckedAllData[index].gradeType_value = value?.value || null;
                                  // setSelectedGrades((prevSelectedGrades) => {
                                  //   const updatedGrades = [
                                  //     ...prevSelectedGrades,
                                  //   ];
                                  //   updatedGrades[index] = value?.value || null;
                                  //   return updatedGrades;
                                  // });
                                  tempCheckedAllData[index].pass_mark = "";
                                  setSelectedGradeMixTemplate({});
                                }}
                                value={gradeMixGradeType.filter(function (
                                  option
                                ) {
                                  return option.value == gradeMix.grading_type;
                                })}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`AssessmentTotal_${gradeMix.id}`}>
                                Assessment Total *
                              </label>
                              <input
                                disabled={quizLabel}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Assessment Total")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Assessment Total"
                                name={`AssessmentTotal_${gradeMix.id}`}
                                title="Assessment Total"
                                id={`AssessmentTotal_${gradeMix.id}`}
                                value={
                                  gradeMix.assessment_total ||
                                  quizList.filter((val) => {
                                    if (val.label == quizLabel) {
                                      return val.value;
                                    } else {
                                      return "";
                                    }
                                  }) ||
                                  ""
                                }
                                onChange={(event) => {
              
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].assessment_total =
                                        event?.target?.value || ""
                                      return newData;
                                    });
                                  }
                                  // formik.setFieldTouched("gradeMix",true,true)
                                }}
                                min={0}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`Offset_${gradeMix.id}`}>
                                Offset{" "}
                                <i
                                  className="fal fa-info-circle grade-icon"
                                  title="Enter a value to be added to each student's raw score for this assignment. This can be used to adjust the difficulty of the assignment or to provide a bonus. For example, an offset of 5 would add 5 points to every student's score. A negative value can be used to subtract points. The default value is typically 0."
                                ></i>
                              </label>
                              <input
                                disabled={!isEditable && type === "open"}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Offset")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Offset"
                                name={`Offset_${gradeMix.id}`}
                                title="Offset"
                                id={`Offset_${gradeMix.id}`}
                                value={gradeMix?.offset || ""}
                                onChange={(event) => {
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].offset =
                                        event?.target?.value;
                                      return newData;
                                    });
                                  }
                                }}
                                // onBlur={formik.handleBlur}
                              />
                              {gradeMixError[index]?.field_name?.includes("Offset") ? (
                                <span className="exclamation">
                                  <i className="fal fa-exclamation-circle"></i>
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <div className="form-group-blk mb-3">
                              <label htmlFor={`multiplicator_${gradeMix.id}`}>
                                Multiplicator{" "}
                                <i
                                  className="fal fa-info-circle grade-icon"
                                  title="Specify the factor by which the raw score of the assignment will be multiplied. This can be used to adjust the weight of the assignment in the overall grading scheme. For example, a multiplicator of 2 would double the score of this assignment in the context of overall grading. The default value is typically 1."
                                ></i>
                              </label>
                              <input
                                disabled={!isEditable && type === "open"}
                                type="number"
                                className={
                                  "form-control" +
                                  (gradeMixError[index]?.field_name?.includes("Multiplicator")
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Multiplicator"
                                name={`multiplicator_${gradeMix.id}`}
                                title="Multiplicator"
                                id={`multiplicator_${gradeMix.id}`}
                                value={gradeMix.multiplicator || ""}
                                onChange={(event) => {
                                  if (
                                    event.target &&
                                    event.target.value !== undefined
                                  ) {
                                    event.persist();
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].multiplicator =
                                        event?.target?.value;
                                      return newData;
                                    });
                                  }
                                }}
                                // onBlur={formik.handleBlur}
                                placeholderText="Select Date & Time"
                                timeIntervals={15}
                                onChangeRaw={(e) => e.preventDefault()}
                              />
                              {gradeMixError[index]?.field_name?.includes("Multiplicator")? (
                                <span className="exclamation">
                                  <i className="fal fa-exclamation-circle"></i>
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {gradeMix.grading_type == "1" ? (
                            <div className="col-md-6 col-lg-2">
                              <div
                                className={
                                  "form-group-blk mb-3" +
                                  (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  Pass Mark{" "}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title="Enter or select the minimum grade a student must achieve to pass this assignment."
                                  ></i>
                                </label>
                                <Select
                                  // isDisabled={props.gradingStandard == 2}
                                  className={
                                    "form-control custom-select-box" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name={`pass_mark_${gradeMix.id}`}
                                  id={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  value={passMarkRobotArr.filter(function (
                                    option
                                  ) {
                                    return (
                                      option.value ==
                                      parseInt(gradeMix.pass_mark)
                                    );
                                  })}
                                  onChange={(value) => {
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].pass_mark =
                                        value?.value == 0
                                          ? 0
                                          : value?.value || "";
                                      return newData;
                                    });
                                  }}
                                  isClearable
                                  // onBlur={formik.handleBlur}
                                  options={passMarkRobotArr}
                                  maxMenuHeight={175}
                                  placeholder={"Pass Mark"}
                                />
                              </div>
                            </div>
                          ) : gradeMix.grading_type == "2" ? (
                            <div className="col-md-6 col-lg-2">
                              <div
                                className={
                                  "form-group-blk mb-3" +
                                  (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  Pass Mark{" "}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title="Enter or select the minimum grade a student must achieve to pass this assignment."
                                  ></i>
                                </label>
                                <Select
                                  // isDisabled={props.gradingStandard == 2}
                                  className={
                                    "form-control custom-select-box" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name={`pass_mark_${gradeMix.id}`}
                                  id={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  value={gradeMix.templateOptions?.filter(
                                    function (option) {
                                      return option.value == gradeMix.pass_mark;
                                    }
                                  )}
                                  onChange={(value) => {
                                    setTempCheckedAllData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].pass_mark =
                                        value?.value || "";
                                      return newData;
                                    });
                                  }}
                                  isClearable
                                  //   onBlur={formik.handleBlur}
                                  options={gradeMix?.templateOptions}
                                  maxMenuHeight={175}
                                  placeholder={"Mark"}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6 col-lg-2">
                              <div className="form-group-blk mb-3">
                                <label htmlFor={`pass_mark_${gradeMix.id}`}>
                                  Pass Mark{" "}
                                  <i
                                    className="fal fa-info-circle grade-icon"
                                    title="Enter or select the minimum grade a student must achieve to pass this assignment."
                                  ></i>
                                </label>
                                <input
                                  // disabled={!isEditable && type === "open"}
                                  // disabled={props.gradingStandard == 2}
                                  type="number"
                                  className={
                                    "form-control" +
                                    (gradeMixError[index]?.field_name?.includes("Pass Mark")
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Pass Mark"
                                  name={`pass_mark_${gradeMix.id}`}
                                  title="Pass Mark"
                                  id={`pass_mark_${gradeMix.id}`}
                                  value={gradeMix.pass_mark || ""}
                                  onChange={(event) => {
                                    if (
                                      event.target &&
                                      event.target.value !== undefined
                                    ) {
                                      event.persist();
                                      setTempCheckedAllData((prevData) => {
                                        const newData = [...prevData];
                                        newData[index].pass_mark =
                                          event?.target?.value;
                                        return newData;
                                      });
                                    }
                                  }}
                                  // onBlur={formik.handleBlur}
                                />
                                {(gradeMixError[index]?.field_name?.includes("Pass Mark")) ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {gradeMix.grading_type == "2" && (
                            <div className="search-filter-div-right mb-4">
                              <label>&nbsp;</label>
                              <div className="filter-search-bar-blk">
                                <div className="add-ticket-blk dropdown-comman">
                                  <button
                                    id={`lettertemplate_${gradeMix.id}`}
                                    data-toggle="modal"
                                    data-target="#letterTemplatemodal"
                                    className="btn btn-save btn-success"
                                    type="button"
                                    title="Select Template"
                                    // disabled={props.gradingStandard == 2}
                                    onClick={() => {
                                      setLettertemplateIndex(index);
                                      setReload(!reload);
                                    }}
                                  >
                                    <i className="fal fa-file-alt"></i>Select
                                    Template
                                  </button>
                                  {gradeMix?.selectedTemplate && (
                                    <>
                                      {gradeMix.selectedTemplate &&
                                        gradeMix.selectedTemplate.name && (
                                          <div className="d-flex select-delete">
                                            <Link
                                              className="as-text-blue curser"
                                              title="Open"
                                              to={`/courseAdministration/gradingTemplate/edit/${gradeMix?.selectedTemplate?.id}`}
                                              // onClick={() => {
                                              //   $("#letterTemplatemodal").modal("hide");
                                              // }}
                                            >
                                              <span className="textLimit100">
                                                {gradeMix.selectedTemplate.name}
                                              </span>
                                            </Link>
                                            <button
                                              className="btn btn-danger rounded-circle btn-dropdown-item"
                                              type="button"
                                              title="Delete"
                                              // disabled={
                                              //   props.gradingStandard == 2
                                              // }
                                              onClick={() => {
                                                setLettertemplateIndex(index);
                                                setSelectedGradeMixTemplate({});
                                                setTempCheckedAllData(
                                                  (prevData) => {
                                                    const newData = [
                                                      ...prevData,
                                                    ];
                                                    newData[
                                                      index
                                                    ].templateOptions = [];
                                                    newData[
                                                      index
                                                    ].pass_mark = "";
                                                    return newData;
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="fal fa-trash-alt"></i>
                                            </button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            )}
            <hr />
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group-blk mb-3">
                  <label>Detailed instructions</label>
                  {(apiDataLoaded || type == "add") && (
                    <HtmlInputEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                      readOnly={!isEditable && type === "open"}
                      isInValid={editorState}
                      isCKEditor={true}
                      hideSign={true}
                    />
                  )}
                  {/* <input
              disabled={!isEditable && type === "open"}
                type="text"
                className={"form-control" + (formik.errors.DetailedInstruction && formik.touched.DetailedInstruction ? " is-invalid" : "")}
                placeholder="Text editor here"
                name="DetailedInstruction"
                value={formik.values.DetailedInstruction || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
                  {formik.errors.DetailedInstruction &&
                  formik.touched.DetailedInstruction ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {type === "open" && (
              <div className="custom-check custom-control custom-checkbox mt-2 ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckModerated"
                  name="select"
                  checked={formik.values.Moderated == "1"}
                  disabled={!isEditable && type === "open"}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "Moderated",
                      e.target.checked == true ? "1" : "0"
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckModerated"
                >
                  Moderated
                </label>
              </div>
            )}
            <hr />
            <div className="edit-icon new-card-header">
              <div className="card-header">Resources</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-icon-group" title="">
                  <div className="prefix-sufix-box">
                    <div
                      style={{ zIndex: "inherit" }}
                      className="custom-control custom-radio custom-control-inline"
                      title="Quiz"
                    >
                      <input
                        type="radio"
                        id="quiz1"
                        name="quizCheck"
                        checked={formik.values.quizCheck == true}
                        onChange={() => {
                          formik.setFieldValue("quizCheck", true);
                          setIsDurationDisabled(true);
                        }}
                        className="custom-control-input"
                        // onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label custom-control-inline"
                        htmlFor="quiz1"
                      >
                        Quiz
                      </label>
                    </div>
                    <div
                      style={{ zIndex: "inherit" }}
                      className="custom-control custom-radio custom-control-inline"
                      title="Document"
                    >
                      <input
                        type="radio"
                        id="document"
                        name="quizCheck"
                        checked={formik.values.quizCheck == false}
                        onChange={() => {
                          formik.setFieldValue("quizCheck", false);
                          setIsDurationDisabled(false);
                        }}
                        className="custom-control-input"
                        // onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label custom-control-inline"
                        htmlFor="document"
                      >
                        Document
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            {formik.values.quizCheck == true ? (
              <>
                <div className="form-group-blk assest-custom-left">
                  <label>Add Quiz *</label>
                  <div className="form-group atttach-file m-0">
                    <label
                      className={
                        formik.errors.quiz_id && formik.touched.quiz_id
                          ? "file-req is-invalid"
                          : ""
                      }
                      data-toggle="modal"
                      type="button"
                      data-target="#quiz"
                      title="Add Quiz"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fal fa-plus"></i>Add Quiz
                    </label>
                  </div>
                </div>

                <div className="row assest-custom">
                  <div className="col-md-4">
                    <div className="d-flex">
                      <div className="mb-3">
                        {quizLabel && quizFormik.values.quiz_id > 0 && (
                          <>
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li>
                                  <a
                                    href={`/courseAdministration/assessment/quizzes/open/details/${quizFormik.values.quiz_id}`}
                                    target="_blank"
                                  >
                                    <i className="fal fa-file"></i>
                                    &nbsp;{" "}
                                    <span title={quizLabel}>
                                      {TrimText(quizLabel, 40)}
                                    </span>
                                  </a>
                                  <button
                                    className="btn btn-danger rounded-circle btn-dropdown-item"
                                    title="Delete"
                                    onClick={() => handleQuizDelete()}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Marker">Marker</label>
                      <Select
                        isDisabled={!isEditable && type === "open"}
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.Marker && formik.touched.Marker
                            ? " is-invalid"
                            : "")
                        }
                        name="Marker"
                        title="Marker"
                        // placeholder={formik.values.Marker ? formik.values.Marker : "Marker"}
                        onChange={(value) => {
                          if (value) {
                            let arr = [];
                            value.map((item) => {
                              arr.push(item.value);
                            });
                            formik.setFieldValue("Marker", arr);
                          } else {
                            formik.setFieldValue("Marker", []);
                          }
                        }}
                        // value={moderatorAndMarker?.filter(val=>val?.value === formik.values.Marker)}
                        value={moderatorAndMarker.filter((obj) => {
                          return formik.values.Marker.includes(obj.value);
                        })}
                        // value={formik.values.Marker}
                        isMulti={true}
                        options={moderatorAndMarker.sort(
                          sort_by("label", false, (a) => a.toUpperCase())
                        )}
                        maxMenuHeight={175}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  {type === "open" && (
                    <div className="col-md-2">
                      <div className="form-group-blk mb-3">
                        <label>&nbsp;</label>
                        <div className="form-group form-group-save-cancel">
                          <button
                            disabled={!formik.values.Marker}
                            className="btn btn-save btn-success"
                            // type="submit" title="Save"
                            onClick={(e) => handleSendInstruction(e)}
                          >
                            <i className="fal fa-paper-plane"></i>Send
                            Instruction
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 col-lg-4">
                    <div className="">
                      <label htmlFor="Moderator">Moderator</label>
                      <div className="form-icon-group mb-4 ">
                        <Select
                          isDisabled={!isEditable && type === "open"}
                          className={"form-control custom-select-box"}
                          name="Moderator"
                          title="Moderator"
                          placeholder={
                            formik.values.Moderator
                              ? formik.values.Moderator
                              : "Moderator"
                          }
                          maxMenuHeight={175}
                          options={moderatorAndMarker.sort(myCompare)}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("Moderator", value.value);
                              setModeratorSelected(value.value);
                            } else {
                              formik.setFieldValue("Moderator", "");
                              setModeratorSelected("");
                            }
                          }}
                          value={moderatorAndMarker.filter(function (option) {
                            // option.value == formik.values.Moderator && setSelectedDomain([...selectedDomain, option.value])
                            // if (option.value.toString() == formik.values.Moderator.toString()) console.log("matched: ", option);
                            return option.value == formik.values.Moderator;
                          })}
                          isClearable
                        />
                        {/* {formik.errors.Moderator && formik.touched.Moderator ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null} */}
                      </div>
                    </div>
                    {/* <div className="form-group-blk mb-3">
            <div className="form-group-check">
              
            </div>
          </div> */}
                  </div>
                </div>
                <div className="row assest-custom">
                  <div className="col-md-6 col-lg-4">
                    <div className="d-flex">
                      <div className="form-group-blk assest-custom-left">
                        <label>Assessment</label>
                        <div className="form-group atttach-file m-0">
                          <label
                            className={
                              formik.errors.AssessmentFile &&
                              formik.touched.AssessmentFile
                                ? "file-req is-invalid"
                                : ""
                            }
                          >
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                              disabled={!isEditable && type === "open"}
                              type="file"
                              title="Add Attachment"
                              className="form-control  form-control-aatch-file"
                              onChange={(e) => {
                                if (e.target.files.length) {
                                  formik.setFieldValue(
                                    "AssessmentFile",
                                    e.target.files[0]
                                  );
                                  setAssessmentFile(e.target.files[0]);
                                  setAssessmentFileName(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }
                              }}
                            />
                          </label>
                        </div>
                        {assessmentFileName && (
                          <>
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li>
                                  <a
                                    title={assessmentFileName}
                                    href={
                                      assessmentFileName.includes("blob")
                                        ? assessmentFileName
                                        : `${Corona}/${IMAGE_URL}/${assessmentFileName}`
                                            .replaceAll("/public/", "")
                                            .replaceAll(
                                              "home/myaie/public_html/",
                                              ""
                                            )
                                    }
                                    target="_blank"
                                  >
                                    <i className="fal fa-file"></i>
                                    &nbsp;
                                    {assessmentFile?.name
                                      ? TrimText(assessmentFile?.name, 40)
                                      : TrimText(
                                          formik.values.AssessmentFile.split(
                                            "/"
                                          ).reverse()[0],
                                          40
                                        )}
                                  </a>
                                  <button
                                    className="btn btn-danger rounded-circle btn-dropdown-item"
                                    title="Delete"
                                    onClick={() => handleAssessment()}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Memo</label>
                      <div className="form-group atttach-file m-0">
                        <label
                          className={
                            formik.errors.MemoFile &&
                            formik.touched.MemoFile &&
                            "file-req is-invalid"
                          }
                        >
                          <i className="fal fa-paperclip"></i>
                          <span>Add Attachment</span>
                          <input
                            disabled={!isEditable && type === "open"}
                            type="file"
                            className={"form-control form-control-aatch-file"}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "MemoFile",
                                e.target.files[0]
                              );
                              setMemoFile(e.target.files[0]);
                              setMemofileName(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                        </label>
                      </div>
                      {memofileName &&
                        memofileName != "null" &&
                        memofileName != undefined && (
                          <>
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li>
                                  <a
                                    title={memofileName}
                                    href={
                                      memofileName.includes("blob")
                                        ? memofileName
                                        : `${downloadURL}${IMAGE_URL}/${memofileName
                                            .replaceAll(
                                              "/home/myaie/public_html/",
                                              ""
                                            )
                                            .replace("/public/", "")}`
                                    }
                                    target="_blank"
                                  >
                                    <i className="fal fa-file"></i>
                                    &nbsp;
                                    {TrimText(
                                      memoFile?.name
                                        ? memoFile?.name
                                        : formik.values.MemoFile.split(
                                            "/"
                                          ).reverse()[0],
                                      40
                                    )}
                                  </a>
                                  <button
                                    className="btn btn-danger rounded-circle btn-dropdown-item"
                                    title="Delete"
                                    onClick={() => handleMemoDelete()}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}
            <hr />
            <div className="edit-icon new-card-header">
              <div className="card-header">Assessment Conditions</div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="d-flex w-100">
                  <div className="form-group-blk mb-3 mr-2">
                    <label>&nbsp;</label>
                    <div className="custom-check-group">
                      <div
                        className="custom-check custom-control custom-checkbox mt-2"
                        style={{ width: "100px" }}
                      >
                        <input
                          disabled={isDurationDisabled}
                          type="checkbox"
                          className="custom-control-input"
                          id="durationCheckbox"
                          name="select"
                          checked={formik.values.isDuration}
                          onChange={(e) =>
                            formik.setFieldValue("isDuration", e.target.checked)
                          }
                        />

                        <label
                          className="custom-control-label"
                          htmlFor="durationCheckbox"
                        >
                          <i className="fal fa-clock"></i> Duration
                        </label>
                      </div>
                    </div>
                  </div>
                  {formik.values.isDuration && (
                    <div className="form-group-blk mb-3 w-100">
                      <label>Time Limit</label>
                      {/* <div className="form-grp-dates time-select">
                        <DatePicker
                          className={"form-control cursor-pointer" + (formik.errors.TimeLimitHours && formik.touched.TimeLimitHours ? " is-invalid" : "")}
                          selected={formik.values.TimeLimitHours}
                          onChange={(date) => { formik.setFieldValue("TimeLimitHours", date) }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          title="To"
                          onBlur={formik.handleBlur}
                          placeholderText="Select To Time"
                          showTime={{ use12Hours: true, format: TABLE_TIME_FORMAT }}
                        />
                        </div> */}
                      <div className="row form-grp-dates time-select">
                        <Select
                          isDisabled={
                            (!isEditable && type === "open") ||
                            formik.values.isDuration == false
                          }
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.TimeLimitHours &&
                            formik.touched.TimeLimitHours
                              ? " is-invalid"
                              : "")
                          }
                          name="Hour"
                          title="Time Limit Hour"
                          placeholder={
                            formik.values.TimeLimitHours
                              ? formik.values.TimeLimitHours
                              : "Hours"
                          }
                          options={hourHandle()}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue(
                                "TimeLimitHours",
                                value.value
                              );
                            } else {
                              formik.setFieldValue("TimeLimitHours", "");
                            }
                          }}
                          value={hourHandle().filter((val) => {
                            return (
                              val.value == formik.values.TimeLimitHours
                              // Number(formik.values.TimeLimitHours).toLocaleString(
                              //   "en-US",
                              //   { minimumIntegerDigits: 2, useGrouping: false }
                              // )
                            );
                          })}
                          isClearable
                        />
                        <Select
                          isDisabled={
                            (!isEditable && type === "open") ||
                            formik.values.isDuration == false
                          }
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.TimeLimitMinutes &&
                            formik.touched.TimeLimitMinutes
                              ? " is-invalid"
                              : "")
                          }
                          name="Minute"
                          title="Time Limit Minute"
                          placeholder={
                            formik.values.TimeLimitMinutes
                              ? formik.values.TimeLimitMinutes
                              : "Minutes"
                          }
                          options={MinutesHandle()}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue(
                                "TimeLimitMinutes",
                                value.value
                              );
                            } else {
                              formik.setFieldValue("TimeLimitMinutes", "");
                            }
                          }}
                          value={MinutesHandle().filter((val) => {
                            return (
                              val.value == formik.values.TimeLimitMinutes
                              // Number(
                              //   formik.values.TimeLimitMinutes
                              // ).toLocaleString("en-US", {
                              //   minimumIntegerDigits: 2,
                              //   useGrouping: false,
                              // })
                            );
                          })}
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ml-auto d-flex justify-content-end">
                <div className="form-group-blk mb-3">
                  <label>&nbsp;</label>
                  <div className="custom-check-group">
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        name="select"
                        checked={formik.values.Identification || false}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "Identification",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        <i className="fal fa-portrait"></i> Identification
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                        name="select"
                        checked={formik.values.Camera || false}
                        onChange={(e) =>
                          formik.setFieldValue("Camera", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck2"
                      >
                        <i className="fal fa-webcam"></i> Camera
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mt-2 mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck3"
                        name="select"
                        checked={formik.values.ScreenShare || false}
                        onChange={(e) =>
                          formik.setFieldValue("ScreenShare", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck3"
                      >
                        <i className="fal fa-desktop"></i> Screenshare
                      </label>
                    </div>
                    <div className="custom-check custom-control custom-checkbox mb-2">
                      <input
                        disabled={!isEditable && type === "open"}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck4"
                        name="select"
                        checked={formik.values.Audio || false}
                        onChange={(e) =>
                          formik.setFieldValue("Audio", e.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck4"
                      >
                        <i className="fal fa-microphone"></i> Audio
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.quizCheck == true ? (
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="maximum_attempts">Maximum Attempts *</label>
                    <input
                      type="number"
                      title="Maximum Attempts"
                      className={
                        "form-control" +
                        (formik.errors.maximum_attempts &&
                        formik.touched.maximum_attempts
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Maximum Attempts"
                      name="maximum_attempts"
                      id="maximum_attempts"
                      value={formik.values.maximum_attempts || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group form-group-save-cancel mt-3 mb-0">
              <div>
                <button
                  className="btn btn-save btn-success"
                  onClick={formik.handleSubmit}
                  type="submit"
                  title="Save"
                  disabled={assessmentLoad ? true : false}
                >
                  {assessmentLoad === false ? (
                    <i className="fal fa-save"></i>
                  ) : (
                    <i className="fas fa-cog fa-spin"></i>
                  )}
                  Save
                </button>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    if (!isEditable || !formik.dirty) {
                      history.goBack();
                    } else {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "Your work will not be saved",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Go Back!",
                      })
                        .then((result) => {
                          if (result.isConfirmed) {
                            history.goBack();
                          }
                        })
                        .catch((error) => {
                          console.log("error: ", error);
                        });
                    }
                  }}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {Object.keys(formik.values).map((key) => {
                if (formik.touched[key] && formik.errors[key]) {
                  if (key == "gradeMix") {
                    if (tempCheckedAllData.length) {
                      return gradeMixError.map((item) => {

                      if (!item) {
                        return <></>
                      }
                     return item.errorMessage.map((error) => (
                          <div className="invalid-feedback d-block">
                            {error}
                          </div>
                        ))
                      }
                      );
                    } else {
                      return (
                        <div className="invalid-feedback d-block">
                          {formik.errors[key]}
                        </div>
                      );
                    }
                  }
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key]}
                    </div>
                  );
                }
              })}
              {/* {formik.touched.TimeLimitMinutes && formik.errors.TimeLimitMinutes ? <div className="invalid-feedback d-block">{formik.errors.TimeLimitMinutes}</div> : null} */}
            </div>
          </form>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="quiz"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-plus"></i> Add Quiz
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* <div className="t p-30px">
                {showModal && (
                  <form onSubmit={quizFormik.handleSubmit}>
                    <div className="form-group-blk mb-3">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (quizFormik.errors.quiz_id &&
                            quizFormik.touched.quiz_id
                            ? " is-invalid"
                            : "")
                        }
                        name="quiz_id"
                        id="quiz_id"
                        title="Add Quiz"
                        // placeholder={
                        //   quizFormik.values.quiz_id
                        //     ? quizFormik.values.quiz_id
                        //     : "Select Quiz"
                        // }
                        options={quizList.sort(
                          sort_by("label", false, (a) => a.toUpperCase())
                        )}
                        onBlur={quizFormik.handleBlur}
                        onChange={(value) => {
                          if (value) {
                            quizFormik.setFieldValue("quiz_id", value.value);
                            formik.setFieldValue("quiz_id",value.value)
                            setQuizLabel(value.label);
                          } else {
                            quizFormik.setFieldValue("quiz_id", "");
                            formik.setFieldValue("quiz_id","")
                            setQuizLabel("");
                          }                         
                        }}
                        value={quizList.filter(function (option) {
                          return option.value == quizFormik.values.quiz_id;
                        })}
                        isClearable
                      />
                    </div>
                    <div className="form-group form-group-save-cancel mt-3 mb-0">
                      <div>
                        <button
                          className="btn btn-save btn-success"
                          onClick={quizFormik.handleSubmit}
                          type="submit"
                          title="Save"
                          disabled={quizLoad ? true : false}
                        >
                            {loading === false ? (
                              <i className="fal fa-save"></i>
                            ) : (
                              <i className="fas fa-cog fa-spin"></i>
                            )} Save
                        </button>
                        <button
                          className="btn btn-close btn-danger"
                          type="button"
                          title="Cancel"
                          onClick={() => {
                            setShowModal(false);
                            quizFormik.setFieldValue("quiz_id", "");
                            setQuizLabel("");
                            $("#quiz").modal("hide");
                          }}
                        >
                          <i className="fal fa-times"></i>Cancel
                        </button>
                      </div>
                    </div>
                    {quizFormik.touched.quiz_id && quizFormik.errors.quiz_id ? (
                      <div className="invalid-feedback d-block">
                        {quizFormik.errors.quiz_id}
                      </div>
                    ) : null}
                  </form>
                )}
              </div> */}
              <AddQuizzTable addQuizzHandler={addQuizzHandler} />
            </div>
          </div>
        </div>
      </div>
      <ListOfTemplateModal
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}
      />
      <ProgrammeGradeMixModal
        setTempCheckedAllData={setTempCheckedAllData}
        tempCheckedAllData={tempCheckedAllData}
        setCheckedAllRows={setCheckedAllRows}
        checkedAllRows={checkedAllRows}
        courseId={id}
        setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}
      />
    </div>
  );
};

export default AssessmentForm;
