import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getStudentBrand,
  getStudentGradeDetailsCancelToken,
  updateProgameScore,
} from "../../../../../services/GradeSettingService";
import StudentGradeBookTable from "./StudentGradeBookTable";
import { RenderQuizAttemptResultStatus } from "../../../../../utils/CommonStatusItems";
import PermissionsGate from "../../../../../utils/permissionGate";
import Str from "../../../../common/Str";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import GradeBookSaveBar from "../../../../common/GradeBookSaveBar";
import Swal from "sweetalert2";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import LetterheadPDF from "../../../../../LetterheadPDF";
import moment from "moment";
import { RenderLearningMethodGradebook } from "../../../../../utils/CommonGroupingItem";
import $ from "jquery";
import axios from "axios";

function StudentGradeBook({ studentDetails }) {
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const { id } = useParams();
  const [studentGradeBookData, setStudentGradeBookData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [percentageView, setPercentageView] = useState(false);
  const [IDArray, setIDArray] = useState([]);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(0);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [programmeID, setProgrammeID] = useState("");
  useEffect(() => {
    const cancelTokenSources = [];

    const getGradeList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await getStudentGradeDetailsCancelToken(id, source.token);
        if (res.status === 200) {
          let details = res.data?.data;
        const pdfDataWithDetails = details.map((course) => ({
          ...course,
          coursesAlongAssessment: course?.courseWithAssessment?.map(
            (intake) => ({
              course_name: intake?.course_name,
              weight: intake?.grant_exemption
                ? "Exempted"
                : !intake?.assessments.length
                ? "N/A"
                : intake?.weight,
              grade: intake?.grant_exemption
                ? "Exempted"
                : !intake?.assessments.length
                ? "N/A"
                : percentageView || intake?.grading_type == 0
                ? Math.round(intake?.scoreRecieved) + "%"
                : intake?.grading_type && intake?.grading_type == 3
                ? `${intake?.getAssessmentCalculate_sum_ass_mark}/${intake?.getAssessmentCalculate_sum_ass_total_mark}`
                : course.gradeText
                ? course.gradeText
                : "N/A",
              result: intake?.grant_exemption
                ? "Exempted"
                : !intake?.assessments.length
                ? "N/A"
                : intake?.pass_status
                ? intake?.pass_status
                : "N/A",
              isExclude: intake?.isExclude,  
              assessments: intake?.assessments?.map((assessment) => ({
                name: assessment?.name,
                weight:
                  assessment?.grant_exemption || intake?.grant_exemption
                    ? "Exempted"
                    : assessment.ass_weighting,
                result:
                  assessment.grant_exemption || intake?.grant_exemption
                    ? "Exempted"
                    : assessment?.submited == "0" ||
                      moment.unix(assessment?.close_date_time) >
                        moment.unix(new Date())
                    ? "N/A"
                    : assessment.pass_status == "N/A"
                    ? "N/A"
                    : assessment.pass_status
                    ? assessment.pass_status
                    : "-",
                grade:
                  assessment.grant_exemption || intake?.grant_exemption
                    ? "Exempted"
                    : percentageView
                    ? assessment.ass_percentage
                    : assessment?.submited == "0" ||
                      moment.unix(assessment?.close_date_time) >
                        moment.unix(new Date())
                    ? "N/A"
                    : assessment?.grading_type == 3
                    ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                    : assessment?.gradeText
                    ? assessment?.gradeText
                    : "N/A",
                isExclude: assessment?.isExclude,       
              })),
              final_status: course.programCalculate?.pass_status,
              final_grade:
                percentageView || course.programCalculate?.grading_type == 0
                  ? Math.round(course.programCalculate?.scoreRecieved) + "%"
                  : course.programCalculate?.grading_type == 3
                  ? `${course.programCalculate?.programeAssMark}/${course.programCalculate?.programeAssTotalMark}`
                  : course.programCalculate?.gradeText,
            })
          ),
        }));
        setIsEdited(res.data?.allUpdateProgram);
        let itemIdsArray = details && details?.map((item) => item.id);
        setIDArray(itemIdsArray);
        setStudentGradeBookData(pdfDataWithDetails);
        setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getGradeList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);

  useEffect(() => {
    const data = {
      id: [id]
    }
    getStudentBrand(data)
      .then((res) => {
        setBrandData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handlepdfData = (programmeId) => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: "Downloading PDF",
      text: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    const pdfDoc = studentGradeBookData.filter((item) => {
      return item?.id === programmeId;
    });
    if (pdfDoc) {
      let modifiedSoR = pdfDoc[0]?.coursesAlongAssessment?.map((item) => ({
        ...item,
        assessments: !isChecked
          ? []
          : item?.assessments.filter(
              (assessment) => !isExcludeGrade || !assessment.isExclude
            ),
        final_grade: !isShowFinal ? "" : item?.final_grade,
        final_status: !isShowFinal ? "" : item?.final_status,
      }))
      .filter((course) => !isExcludeGrade || !course.isExclude);
      pdf(
        <LetterheadPDF
          detailData={modifiedSoR}
          studentAndCourseDetails={{
            program_name: pdfDoc[0]?.program_name,
            Student_name:
              studentDetails.first_name + " " + studentDetails.last_name,
            ID_number: studentDetails?.number,
            Student_number: studentDetails?.student_crm_id
              ? studentDetails?.student_crm_id
              : studentDetails?.student_num,
            start: pdfDoc[0]?.start
              ? pdfDoc[0].start?.toString().length == 10
                ? moment.unix(pdfDoc[0]?.start).format("DD MMMM YYYY")
                : moment(pdfDoc[0]?.start).format("DD MMMM YYYY")
              : "",
            end: pdfDoc[0]?.end
              ? pdfDoc[0].end?.toString().length == 10
                ? moment.unix(pdfDoc[0]?.end).format("DD MMMM YYYY")
                : moment(pdfDoc[0]?.end).format("DD MMMM YYYY")
              : "",
            intake:
              pdfDoc[0]?.number && pdfDoc[0]?.year
                ? `${pdfDoc[0]?.number}-${pdfDoc[0]?.year}`
                : "",
            learning_method: pdfDoc[0]?.type
              ? RenderLearningMethodGradebook(pdfDoc[0]?.type).text
              : "",
          }}
          brandData={brandData?.getBrandDetail}
        />
      )
        .toBlob()
        .then((response) => {
          const blobURL = window.URL.createObjectURL(response);
          const a = document.createElement("a");
          a.href = blobURL;
          a.download = `${
            studentDetails?.student_crm_id
              ? studentDetails?.student_crm_id
              : studentDetails?.student_num
          }_${studentDetails.first_name + " " + studentDetails.last_name}_${
            pdfDoc[0]?.program_name
          }_SOR_${brandVisuals?.brand_name}_${moment(new Date()).format(
            "DD MM YY"
          )}.pdf`; // Set the desired filename for the download
          a.click();

          URL.revokeObjectURL(blobURL);
          setPdfDownloading(false);
          setProgrammeID("")
          setIsChecked(false);
          setIsShowFinal(false);
          setIsExcludeGrade(false);
          swalPro.close();
          $("#availableprogrammeinRereg").modal("hide");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Download Successfully",
          });
        })
        .catch((err) => {
          setPdfDownloading(false);
          setProgrammeID("")
          setIsChecked(false);
          setIsShowFinal(false);
          setIsExcludeGrade(false);
          swalPro.close();
          $("#availableprogrammeinRereg").modal("hide");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err,
          });
        });
    }
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  const handleSaveGrades = () => {
    setSaveGradesLoading(true);
    const values = {
      id: IDArray,
      student_id: id,
    };
    updateProgameScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  return loading ? (
    <SkeletonTicketList />
  ) : studentGradeBookData.length ? (
    <>
      <div className="d-flex std-percentage-view">
        <span className="width-full"></span>
        <button
          className="btn btn-primary"
          title={percentageView ? "Standard View" : "Percentage View"}
          onClick={() => setPercentageView(!percentageView)}
        >
          <i className="fal fa-eye"></i>{" "}
          {percentageView ? "Standard View" : "Percentage View"}
        </button>
      </div>
      {studentGradeBookData.map((item, index) => {
        return (
          <div
            key={index}
            className="admin-home-acc accordion ac_over_none"
            id="accordionExample"
          >
            <div className="card-main">
              <div className="card-top">
                <div className="card-header1" id={`heading${item?.id}`}>
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapse${item?.id}`}
                      // aria-expanded={item?.id ? "true" : "false"}
                      aria-expanded={"false"}
                      aria-controls={`#collapse${item?.id}`}
                      //   onClick={() =>
                      //     // history.push(true ? "/home" : "/home/assessment/Today")
                      //   }
                    >
                      <div
                        className={
                          `title-border-box ` +
                          (item?.programCalculate?.pass_status == "PASS"
                            ? "pass-side-border-grade"
                            : item?.programCalculate?.pass_status == "FAIL"
                            ? "fail-side-border-grade"
                            : "sideBar-side-border-grade")
                        }
                      >
                        <Link
                          className="curser"
                          to={`/courseAdministration/Programmes/programme/open/${item?.id}/details`}
                        >
                          <p
                            style={{ color: "#000" }}
                            title={item?.program_name}
                          >
                            {item?.program_name}
                          </p>
                        </Link>
                        <div className="d-flex ml-auto align-items-center">
                          <div>
                            <i className="fal fa-chart-bar mr-2 font-weight-normal"></i>
                            <span className="curser feature-name font-weight-normal">
                              Final Course Grade
                            </span>
                          </div>
                          <div
                            className="resultstatus_flex arrowhide"
                            style={{ marginLeft: "50px" }}
                          >
                            {item?.programCalculate &&
                            item?.programCalculate?.pass_status == "N/A" ? (
                              <span className="mr-2">N/A</span>
                            ) : (
                              <>
                                {percentageView ||
                                item.programCalculate?.grading_type == 0 ? (
                                  <span className="font-weight-normal">
                                    {Math.round(
                                      item.programCalculate?.scoreRecieved
                                    )}{" "}
                                    %
                                  </span>
                                ) : item.programCalculate?.grading_type == 3 ? (
                                  <>
                                    <PermissionsGate
                                      scopes={["cassgradeassedit"]}
                                      errorProps={{ disabled: true }}
                                    >
                                      <input
                                        className={
                                          "grade-head-input form-control" +
                                          (false ? " is-invalid" : "")
                                        }
                                        type="number"
                                        defaultValue={
                                          item?.programCalculate
                                            ?.programeAssMark
                                        }
                                        disabled
                                        // onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.ass_total_mark, row)}
                                      />
                                    </PermissionsGate>
                                    <span className="font-weight-normal">
                                      /
                                      {
                                        item?.programCalculate
                                          ?.programeAssTotalMark
                                      }
                                    </span>
                                  </>
                                ) : (
                                  <span className="mr-2 font-weight-normal">
                                    {item?.programCalculate?.gradeText}
                                  </span>
                                )}
                                <p className="m-0 mr-2">
                                  {item?.programCalculate?.pass_status
                                    ? RenderQuizAttemptResultStatus(
                                        item?.programCalculate?.pass_status
                                      ).html
                                    : ""}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <button
                          className="btn btn-primary rounded-circle"
                          title="Download SoR PDF"
                          data-toggle="modal"
                          data-target="#availableprogrammeinRereg"
                          onClick={() => setProgrammeID(item?.id)}
                        >
                          {pdfDownloading ? (
                            <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                          ) : (
                            <i class="fal fa-download download-SoR-icon"></i>
                          )}
                        </button>
                      </div>{" "}
                      <div className="header-status">
                        <div className="status-gol card-blue-1">
                          <i className="fal fa-chevron-down rotate-icon"></i>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${item?.id}`}
                  className={`collapse ${false ? "show" : ""}  `}
                  aria-labelledby={`heading${item?.id}`}
                  data-parent="#accordionExample"
                >
                  <div className="card card-body" style={{ border: "0px" }}>
                    {item?.courseWithAssessment &&
                      item?.courseWithAssessment.length && (
                        <StudentGradeBookTable
                          courseWithAssessmentData={item?.courseWithAssessment}
                          setUpdateData={setUpdateData}
                          updateData={updateData}
                          qid={item.id}
                          isWeightShown={
                            item?.grading_method_id == 1 &&
                            item?.aggregation_method_id == 6
                          }
                          // iscontributionShown={percentageView || item?.grading_type == 0 || item.grading_type == 3}
                          iscontributionShown={false}
                          percentageView={percentageView}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            {studentGradeBookData.length && isEdited ? (
              <GradeBookSaveBar
                handleClick={() => handleSaveGrades()}
                saveGradesLoading={saveGradesLoading}
              />
            ) : (
              <></>
            )}
            <div
              className="topic-add-modal modal fade"
              id="availableprogrammeinRereg"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="static"
            >
              <div
                className="modal-600 modal-dialog modal-dialog-centered modal-m"
                role="document"
              >
                <div className="modal-content modal-border-update sor">
                  <div className="modal-body p-0">
                    <div className="modal-header modal-header-custom">
                      <h5 className="modal-title">
                        <i className="fal fa-download"></i> Statement of Results
                        Download Options
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-toggle="modal"
                        data-target="#availableprogrammeinRereg"
                        onClick={() => {
                          setProgrammeID("")
                          setIsChecked(false);
                          setIsShowFinal(false);
                          setIsExcludeGrade(false);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="t p-17px">
                      <i class="fal fa-cog SoR-icon"></i>
                      <span className="SoR-settings"> Settings</span>
                      <div className="toogles-inline mt-3 d-flex align-items">
                        <div className="d-flex align-items mr-3">
                          <label className="mb-0">Show Assessments</label>
                          <div className="toggle-switch ml-2">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleToggle}
                              />
                              <span class="slider slider-round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex align-items mr-3">
                          <label className="mb-0">Show Final Results</label>
                          <div className="toggle-switch ml-2">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={isShowFinal}
                                onChange={handleShowFinalToggle}
                              />
                              <span class="slider slider-round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex align-items mr-3">
                          <label className="mb-0">Exclude Empty Grades</label>
                          <div className="toggle-switch ml-2">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={isExcludeGrade}
                                onChange={handleExcludeFinal}
                              />
                              <span class="slider slider-round"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group form-group-save-cancel mt-4">
                        <button
                          class="btn btn-save btn-success"
                          type="submit"
                          title="Save"
                          onClick={() => handlepdfData(programmeID)}
                        >
                          <i class="fal fa-download"></i> Download SoR
                        </button>
                        <button
                          class="btn btn-close btn-danger"
                          type="button"
                          title="Cancel"
                          onClick={() => {
                            setIsChecked(false);
                            setIsShowFinal(false);
                            setIsExcludeGrade(false);
                            setProgrammeID("")
                            $("#availableprogrammeinRereg").modal("hide");
                          }}
                        >
                          <i class="fal fa-times"></i> Cancel
                        </button>
                      </div>

                      {/* <label
                    className={`custom-toggle-switch ${
                      isChecked ? "checked" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <span className="slider">
                      <span className={`icon ${isChecked ? "checked" : ""}`}>
                        {isChecked ? "U+2713" : "X"}
                      </span>
                    </span>
                  </label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  ) : (
    <div className="custom-table-msg">{Str.noRecord}</div>
  );
}

export default StudentGradeBook;
