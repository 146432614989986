import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FORM_DATE_FORMAT } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import Select from "react-select";
import {
    registrationStudentSearchList,
    issuedResource,
    getResourceDetails,
    GetCampusDropdown,
} from "../../../services/StudentService";
import { useParams } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import { GetCommonContent } from "../../../services/ProgrammeServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
registerPlugin(FilePondPluginImagePreview);
let Id = "";
let Action = "";

const IssueResource = ({ setResourceName }) => {
    const history = useHistory();

    const [condition, setCondition] = useState([]);
    const [campus, setCampus] = useState([]);
    const [issueTo, setIssueTo] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [basicInfo, setBasicInfo] = useState({})

    const [infoData, setInfoData] = useState({})
    if (history.location.state) {
        const { id, action } = history.location.state;
        Id = id;
        Action = action;
    }
    const { id } = useParams()

    const CommonDropdownData = useSelector(state => state.registerDropdownValues && Object.keys(state.registerDropdownValues).length ?
        state.registerDropdownValues : {});


    function setDropdownData() {
        if (Object.keys(CommonDropdownData).length) {            
            if (CommonDropdownData.BookConditionsType) {
                setCondition(CommonDropdownData?.BookConditionsType)
            }            
        }
    }

    useEffect(() => {
        setDropdownData();        
        registrationStudentSearchList({type:"dropdown"}).then((resp) => {
            // let respData = resp?.data?.data
            // let tempIssueTo = respData.map((item) => ({ value: item.id, label: item.first_name + " " + item.last_name }))
            // console.log(tempIssueTo);
            setIssueTo([...resp.data.data])
        });
        GetCampusDropdown().then(res=>{
            if(res.status===200){
                setCampus(res.data.dropdown)
            }
        }).catch((err)=>console.log(err))
        getResourceDetails(id)
            .then((res) => {
                setBasicInfo(res.data?.resourceDetails)
                setResourceName(res.data?.resourceDetails?.title)
                if (res.data?.ResourceInformationDetails) {
                    setInfoData(res.data?.ResourceInformationDetails)

                }
            })
            .catch((err) => { console.log(err); });
    }, [])

    useEffect(() => {
        issueTo && issueTo.map((issue) => {
            if (issue.value == infoData.issu_to) {
                formik.setFieldValue("issuetoName", issue.label)
            }
        })

        campus && campus.map((campus) => {
            if (campus.value == infoData.campus) {
                formik.setFieldValue("campusName", campus.label)
            }
        })

    }, [issueTo, campus])

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sresourcemedit"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: infoData?.id || "",
            title: basicInfo?.title || "",
            subtitle: basicInfo?.sub_title || "",
            condition: basicInfo?.book_condition || "",
            duedate: infoData?.reserved_due_date && ((infoData?.reserved_due_date.includes("T") == false) && (infoData?.reserved_due_date != 0)) ? new Date(moment.unix(Number(infoData?.reserved_due_date))) : "",
            campus: basicInfo?.campus || "",
            campusName: "",
            issueto: infoData?.issu_to || "",
            issuetoName: "",
            comments: "",
        },
        validationSchema: Yup.object({
            // condition: Yup.string().required("Condition is required"),
            duedate: Yup.string().required("Due Date is required"),
            issueto: Yup.string().required("Issue To is required"),
            // subtitle: Yup.string().required("Sub title is required"),
            // title: Yup.string().required("Title is required"),
        }),
        onSubmit: (values) => {
            setIsDisabled(true)
            let date = values.duedate

            if (values.duedate) {
                date = new Date(date).getTime().toString() / 1000;
            }
            values = { ...values }
            let body;
            body = {
                id: values.id,
                book_condition: values.condition,
                due_date: date,
                issu_to: values.issueto,
                comment: values.comments,
                resource_id: id,
                status: "issued",
                id: infoData.id,
                campus : values.campus
            }
            issuedResource(body)
                .then((res) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                        onClose: goToListing
                    });
                })
                .catch((err) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${err?.response?.data?.data}`,
                    });
                });
        },
    });

    const goToListing = () => {
        history.push(`/support/student-hub-library/resource-management/table`)
    }

    return (
        <div className="card-new card card-profile-info-card">
            <div className="card-header">Issue Resource</div>
            <div className="card-body-inr card-body-info">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Title</label>
                                <div className="form-icon-group mb-4">
                                    <input 
                                    type="text" 
                                    className={"form-control" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")} 
                                    name="title" 
                                    placeholder="Title" 
                                    title="Title" 
                                    value={formik.values.title} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Sub Title</label>
                                <div className="form-icon-group mb-4">
                                    <input 
                                    type="text" 
                                    className={"form-control" + (formik.errors.subtitle && formik.touched.subtitle ? " is-invalid" : "")} 
                                    name="subtitle" 
                                    placeholder="Sub Title" 
                                    title="Sub Title" 
                                    value={formik.values.subtitle} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group" >
                                <label>Campus</label>
                                <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                        <Select 
                                        className={"form-control custom-select-box" + (formik.errors.campus && formik.touched.campus ? " is-invalid" : "")} 
                                        name="campus"
                                            value={campus?.filter(val => val.value === formik.values.campus)}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("campus", value.value);
                                                } else {
                                                    formik.setFieldValue("campus", "");
                                                }
                                            }}
                                            placeholder={formik.values.campus && campus.length ? campus.filter(item => item.value == formik.values.campus)[0].label : "Select..."}
                                            onBlur={formik.handleBlur} 
                                            options={campus} 
                                            maxMenuHeight={175} 
                                            isClearable 
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Due Date*</label>
                                <DatePicker
                                    autoComplete="off"
                                    selected={formik.values.duedate}
                                    onChange={(date) => {
                                        formik.setFieldValue("duedate", date);
                                    }}
                                    name="date"
                                    dateFormat={FORM_DATE_FORMAT}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormatCalendar="MMMM"
                                    className={
                                        "form-control cursor-pointer" +
                                        (formik.errors.duedate &&
                                            formik.touched.duedate
                                            ? " is-invalid"
                                            : "")
                                    }
                                    title="Due Date"
                                    placeholderText="Due Date"
                                    timeIntervals={15}
                                    minDate={new Date()}
                                    onChangeRaw={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group" >
                                <label>Issue To*</label>
                                <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                        <Select 
                                        className={"form-control custom-select-box" + (formik.errors.issueto && formik.touched.issueto ? " is-invalid" : "")} 
                                        name="issueto"
                                            value={issueTo?.filter(val => val.label === formik.values.issuetoName)}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("issueto", value.value);
                                                    formik.setFieldValue("issuetoName", value.label);
                                                } else {
                                                    formik.setFieldValue("issueto", "");
                                                    formik.setFieldValue("issuetoName", "");
                                                }
                                            }}
                                            placeholder={formik.values.issuetoName ? formik.values.issuetoName : "Select..."}
                                            onBlur={formik.handleBlur} 
                                            options={issueTo} 
                                            maxMenuHeight={175} 
                                            isClearable 
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comments</label>
                                <div className="form-icon-group mb-4">
                                    <input 
                                    type="text" 
                                    className={"form-control" + (formik.errors.comments && formik.touched.comments ? " is-invalid" : "")} 
                                    name="comments" 
                                    placeholder="Comments" 
                                    Title="Comments" 
                                    value={formik.values.comments} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group" >
                                <label>Condition</label>
                                <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                        <Select 
                                        className={"form-control custom-select-box" + (formik.errors.condition && formik.touched.condition ? " is-invalid" : "")} 
                                        name="condition"
                                            value={condition?.filter(val => val.value === formik.values.condition)}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("condition", value.value);
                                                } else {
                                                    formik.setFieldValue("condition", "");
                                                }
                                            }}
                                            placeholder={formik.values.condition && condition.length ? (condition.filter(item => item.value === formik.values.condition)[0]).label : "Select..."}
                                            onBlur={formik.handleBlur} 
                                            options={condition} 
                                            maxMenuHeight={175} 
                                            isClearable 
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group-save-cancel ">
                        <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                            <button className="btn btn-save btn-success" type="button" onClick={formik.handleSubmit} title="Save" >
                                {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                                Save
                            </button>
                        </PermissionsGate>
                        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>
                    <div className="form-group form-group-save-cancel">
                    {Object.keys(formik.values).map((key) => (formik.touched[key] && formik.errors[key] ? (<div key={key} className="invalid-feedback d-block">{formik.errors[key]}</div>) : null))}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default IssueResource;
