import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { FORM_DATE_FORMAT, NAME_REGEX } from "../../../../../utils/Constants";
import Select from "react-select";
import {
  ApplicationStatus,
  StudentType,
  Gender,
  Race,
} from "../../../DataSelect";
import {
  CalculateTermMark,
  ExamsRegistrationsListEdit,
  ExamsRegistrationsUpdate,
  GenerateMarks,
  GetExamCenterDropDownList,
  GetExamRegistrationData,
  GetExamRegistrationStudentList,
} from "../../../../../services/ExternalExamServices";
import moment from "moment";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import PermissionsGate from "../../../../../utils/permissionGate";
import DataTableComponentFrontPagination from "../../../../common/DataTableComponentFrontPagination";
import { Link } from "react-router-dom";
import $ from 'jquery';

const RegistrationDetails = () => {
  const history = useHistory();
  const { id, subId,subType } = useParams();
  const [editorState, setEditorState] = React.useState();
  const [examRegistration, setExamRegistration] = useState({});
  const [iconLoading, setIconLoading] = useState(true);
  const [examCentreList, setExamCentreList] = useState([]);
  const [examSubject, setExamSubject] = useState([{subject:"", attempt: "", mark : "", attendance : "" , written : false, dhetResult:""}])
  const [studentList, setStudentList] = useState([]);
  const [subjectList, setSubjectList] = useState([])
  const [gearLoading, setGearLoading] = useState(false)
  const [markData, setMarkData] = useState([]);
  const [search, setSearch] = useState("");
  const [markLoading, setMarkLoading] = useState(false);
  const [generateMark, setGenerateMark] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  
  const handleStudentList=(data)=>{    
    GetExamRegistrationData(data.value,id).then((res)=>{
      let subjectData=[]
      const response = res.data;
      setExamRegistration(response.data);         
      response.subjectData.map((val)=>{
        subjectData.push({label : val.course_name, value : val.id})
      })
      setSubjectList([...subjectData])

    }).catch((err)=>{console.log(err)})
  }

  useEffect(() => {
    if(subType !== "add_details"){
      const param = {
        registration:id,
        exam:subId
      }
      ExamsRegistrationsListEdit(param)
      .then((res) => {
        if(res.status==200 && res.data.data){
          const respData = res.data.data;
          const examSubjects = respData.registrationsSubjects?.map((item)=>({subject: item.subject, attempt: item.first, mark: item.mark, attendance: item.attendance, written: item.written === 0 ? false : true, dhetResult: item.dhetresult}));
          setExamSubject(examSubjects);        
          setExamRegistration(respData);
          setEditorState(respData?.comment);
          setSubjectList(res.data.subjectDropDown)
          setIconLoading(false);
        }else{
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Record not Available",
          }).then(() => { history.goBack() });
        }
      })
      .catch((err) => {
        console.log(err);
        setIconLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Record not Available",
        }).then(() => { history.goBack() });
      });

    }
  }, [refreshData]);

  useEffect(() => {
    GetExamCenterDropDownList().then((res)=>{
      setExamCentreList(res.data.examsCenterDropDown);
    })
  }, [])
  
  useEffect(() => {
    if(subType == "add_details"){
      GetExamRegistrationStudentList(id).then((res)=>{
        let data =[];
         res.data.data.map((val)=>{
          data.push({label : val.first_name+" "+val.last_name,value : val.id})
        })
        setStudentList(data)
      }).catch((err)=>{console.log(err)})
    }
  }, [])
  
  const attemptOption=[
    {value : 1, label : "Yes"},
    {value : 0, label : "No"}]

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      application_status: examRegistration.status ? examRegistration.status : "",
      surname: examRegistration.last_name ? examRegistration.last_name : "",
      first_name: examRegistration.first_name ? examRegistration.first_name : "",
      second_name: examRegistration.second_name ? examRegistration.second_name : "",
      third_name: examRegistration.third_name ? examRegistration.third_name : "",
      student_type: examRegistration.student_type ? examRegistration.student_type : "",
      rsa_id: examRegistration.rsa_identity ? examRegistration.rsa_identity : "",
      exam_id: examRegistration.exam_id_number ? examRegistration.exam_id_number : "",
      gender: examRegistration.gender ? examRegistration.gender : "",
      race: examRegistration.race ? examRegistration.race : "",
      dob: examRegistration.bday_day ? new Date(moment.unix(examRegistration.bday_day)) : "",
      centre: examRegistration.centre ? examRegistration.centre : "",
      comment: editorState,
      student_id :examRegistration.student ? examRegistration.student : "",
      // registrationsSubjects : examRegistration.registrationsSubjects ? examRegistration.registrationsSubjects : [{}],
      // examSubjects: examSubject,
    },
    validationSchema: Yup.object({
      application_status: Yup.string().required(
        "Application Status is required "
      ),
      student_type: Yup.string().required("Student Type is required "),
      centre: Yup.string().required("Please select Exam centre "),
      first_name: Yup.string()
        .matches(NAME_REGEX, "Please enter valid first name")
        .trim("The first name cannot include leading and trailing spaces")
        .required("First Name is required"),
      // second_name: Yup.string()
      //   .matches(NAME_REGEX, "Please enter valid second name")
      //   .trim("The second name cannot include leading and trailing spaces")
      //   .required("Second Name is required"),
      // third_name: Yup.string()
      //   .matches(NAME_REGEX, "Please enter valid third name")
      //   .trim("The third name cannot include leading and trailing spaces")
      //   .required("Third Name is required"),
      surname: Yup.string()
        .matches(NAME_REGEX, "Please enter valid  surname")
        .trim("The surname cannot include leading and trailing spaces")
        .required("Surname is required"),
      rsa_id: Yup.string().required("RSA Id is required "),
      exam_id: Yup.string().required("Exam Id is required "),
      race: Yup.string().required("Race is required "),
      gender: Yup.string().required("Gender is required "),
      dob: Yup.string().required("DOB is required "),
      // comment: Yup.string().test(
      //   "required",
      //   "Application comment is required",
      //   (value) => {
      //     let feature = formatQuestion(editorState);
      //     let isInvalid = feature == "-";
      //     return !isInvalid;
      //   }
      // ),
      examSubjects: Yup.array().min(1,"At least one Exam Subject Information is required").of(
        Yup.object().shape({
          subject: Yup.string().required("Exam subject name is required")
        })
      )
    }),
    onSubmit: (values, { resetForm }) => {
      setGearLoading("save");
      let dob = values.dob.getTime() / 1000;

      let formData = new FormData();
      formData.set("status", values.application_status);
      formData.set("last_name", values.surname);
      formData.set("first_name", values.first_name);
      formData.set("second_name", values.second_name);
      formData.set("third_name", values.third_name);
      formData.set("student_type", values.student_type);
      formData.set("rsa_identity", values.rsa_id);
      formData.set("exam_id_number", values.exam_id);
      formData.set("gender", values.gender);
      formData.set("race", values.race);
      formData.set("student_id", values.student_id);
      console.log(values.student_id)
      if(subType !== "add_details"){
        formData.set("registration", id);
        formData.set("exam", subId);
        formData.set("comment",editorState)
      }else{
        formData.set("registration", 0);
        formData.set("exam", id);
      }
      formData.set("registration_bday", dob);
      if(examSubject.length){
        formData.set("subjects",JSON.stringify(examSubject))
      }
      formData.set("centre",values.centre)
      ExamsRegistrationsUpdate(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: subType=="add_details" ? "Added successfully" : "Updated Successfully",
          }).then(()=>{
            if(subType=="add_details"){
              history.push(`/studentAdministration/externalExam/details/open/registration/${id}`)
            }else{
              setRefreshData(!refreshData)
            }
          });
          // resetForm();
          setGearLoading(false);

          // history.push("/systemAdministration/userManagement/table");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
          setGearLoading(false);
        });
    },
  });
  const handleExamSubject=()=>{
    let newArr = [...examSubject];
    newArr.push({subject:"", attempt: "", mark : "", attendance : "" , written : false, dhetResult:""});
    setExamSubject(newArr)
  }

  const handleRemoveItem=(i)=>{
    let newArr = [...examSubject];
    newArr.splice(i,1);
    setExamSubject(newArr)
  }

  const handleExamData =(index,value,field)=>{
    let newArr=[...examSubject];
    newArr[index][field] = value;
    setExamSubject((newArr)=>[...newArr])
  }

  const handleViewCalculateMark = (subject_id) =>{
    const param = {
      exam: subId,
      registration: id,
      subject: subject_id
    }
    setMarkLoading(true);
    CalculateTermMark(param).then((res)=>{
      if(res.status==200){
        console.log(res.data.data);
        setMarkData(res.data.data);
        $("#viewCalculateMark").modal("show");
      }
    }).catch((err)=>{console.log(err)}).finally(()=>{setMarkLoading(false)});
  }
 
  const handleSelectMark = (rowData, checked) =>{
    let tempData = [...markData];
    tempData = tempData.map((item)=>{
      if(item.subject_id==rowData.subject_id && item.assigment_sub_id == rowData.assigment_sub_id && item.assignment_id == rowData.assignment_id && item.student == rowData.student){
        item.checkmark = (checked==true ? 1 : 0)
      }
      return item;
    })
    setMarkData([...tempData])
  }

  const sortingName = (rowA, rowB) => {
    const name1 = rowA?.aname ? rowA?.aname?.toString() : "";
    const name2 = rowB?.aname ? rowB?.aname?.toString() : "";
    return name1?.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name:"Course",
      selector:"subject",
      sortable:false,
      cell:(row)=>(
        <>
          {row.subject ? (
            <Link
              to={`/courseAdministration/coursesdetails/${row.subject_id}/dashboard/show`}
              className="as-text-blue curser feature-name"
              title={row.subject}
              target="_blank"
            >
              <span className="textLimit100">{row.subject}</span>
            </Link>
          ) : "-"}
        </>
      )
    },
    {
      name: "Intake",
      selector: "intake",
      sortable: false,
      cell: (row) => <>{row.intake ? row.intake : "-"}</>,
    },
    {
      name: "Assessment",
      selector: "aname",
      sortFunction: sortingName,
      sortable: true,
      cell: (row) => (
        <Link
          to={`/courseAdministration/coursesdetails/${row.subject_id}/assessments/open/assigned/Details/${row.assignment_id}`}
          className={"feature-name " + (row.big==1?"text-danger " :" as-text-blue curser")}
          title={row.aname}
          target="_blank"
        >
          <span className={"textLimit100" + (row.big==1?"text-danger " :"")}>
            {row.aname ? row.aname : "-"}
          </span>
        </Link>
      ),
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      cell: (row) => <>{row.code ? row.code : "-"}</>,
    },
    {
      name: "Weight",
      selector: "weight",
      sortable: true,
      cell: (row) => <>{row.weight ? row.weight : 0}</>,
    },
    {
      name: "Total Mark",
      selector: "total",
      sortable: true,
      cell: (row) => <>{row.total ? row.total : 0}</>,
    },
    {
      name: "Percentage",
      selector: "percentage",
      sortable: true,
      cell: (row) => <>{row.percentage ? row.percentage : 0}</>,
    },
    {
      name: "Total Weight",
      selector: "tweight",
      sortable: true,
      cell: (row) => <>{row.tweight ? row.tweight : 0}</>,
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
      <>
        <div className="custom-check custom-control custom-checkbox text-left">
          <input
            type="checkbox"
            className="custom-control-input"
            id={"checkMark"+row?.assignment_id+row?.assigment_sub_id}
            name={"checkmark-"+row?.assignment_id+row?.assigment_sub_id}
            checked={row?.checkmark==1 ? true : false}
            onChange={(e) => {handleSelectMark(row, e.target.checked)}}
          />    
          <label class="custom-control-label"></label>              
        </div>
      </>),
    },
  ])

  const dataToRender = () =>{
    let updatedData = [];
    let allData = markData;
    if(search.length){
      let filterByCourse = allData.filter((item) =>{
        let includes = item.subject.toString().toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes;
        }else{return null;}
      });

      let filterByAssessment = allData.filter((item)=>{
        let includes = item.aname.toString().toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes;
        }else{
          return null;
        }
      });

      let data = [...filterByCourse, ...filterByAssessment];
      let uniqueData = [...new Set(data)];

      updatedData = uniqueData;
    }else{
      updatedData = allData;
    }

    if(search.length){
      return updatedData;
    }else{
      return allData;
    }
  }

  const exportData = () =>{
    let data = dataToRender();
    const header = ["Course", "Intake", "Assessment", "Code", "Weight", "Total Mark", "Percentage", "Total Weight"];

    data = data.map((row)=>({
      "Course": row.subject ? row.subject : "-", 
      "Intake": row.intake ? row.intake : "-", 
      "Assessment": row.aname ? row.aname : "-", 
      "Code": row.code ? row.code : "-", 
      "Weight": row.weight ? row.weight : 0, 
      "Total Mark": row.total ? row.total : 0, 
      "Percentage": row.percentage ? row.percentage : "-", 
      "Total Weight": row.tweight ? row.tweight : 0
    }));

    const pdfData = data.map((row) => {
      return [
          row["Course"],
          row["Intake"],
          row["Assessment"],
          row["Code"],
          row["Weight"],
          row["Total Mark"],
          row["Percentage"],
          row["Total Weight"],          
      ];
    });

    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  }

 

  const handleGenerateMark = () =>{
    setGenerateMark(true);
    let totalWeight = 0;
    const selectedList = markData.filter(item=>item.checkmark==1).map((item)=>{
      totalWeight+=item.weight;
      return {
        subject_id:item.subject_id,
        weight:item.weight,
        assigment_sub_id:item.assigment_sub_id,
        assignment_id:item.assignment_id,
        student:item.student
      }
    });
    const nonSelectedList = markData.filter(item=>item.checkmark==0).map((item)=>({
      subject_id:item.subject_id,
      weight:item.weight,
      assigment_sub_id:item.assigment_sub_id,
      assignment_id:item.assignment_id,
      student:item.student
    }));    
    const payload = {
      registration:id,
      select:selectedList,
      nonselect:nonSelectedList
    }
    if(totalWeight==100){
      GenerateMarks(payload).then((res)=>{
        if(res.status==200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Marks generated successfully",
          });
          setRefreshData(!refreshData)
        }
      }).catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      }).finally(()=>{setGenerateMark(false);$("#viewCalculateMark").modal("hide");})
    }else{
      Swal.fire({
        icon: "info",
        title: "Invalid weight",
        text: `Weight should be 100`,
      }).then(()=>{setGenerateMark(false);});
    }
  }

  return (
    <div
      className="card card-profile-info-card mb-30 course-details-tab-sec"
      id="parentElem"
    >
      <div className="card-body">
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="course-info-sec">
              <div className="edit-icon new-card-header">
                <div className="card-header">
                  {subType!="add_details" && iconLoading && <i className="fas fa-cog fa-spin ml-1"></i>}
                </div>
              </div>
              <div className="card-body-inr card-body-info">
                <div className="row">
                  {subType == "add_details" && <div className="col-md-4">
                    <label htmlFor="student_id">Student List</label>
                    <div className="form-icon-group mb-4" title="Student List">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.student_id &&
                          formik.touched.student_id
                            ? " is-invalid"
                            : "")
                        }
                        name="student_id"
                        value={studentList.filter((val)=>{
                          return val.value == formik.values.student_id
                        }) || ""}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("student_id", value.value)
                            handleStudentList(value)
                          } else {
                            formik.setFieldValue("student_id", "")
                          }
                        }}
                        
                        isClearable
                        onBlur={formik.handleBlur}
                        options={studentList}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.student_id
                            ? formik.values.student_id
                            : "Student List  "
                        }
                      />
                    </div>
                  </div>}
                  <div className="col-md-4">
                    <label htmlFor="first_name">First Name*</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.first_name && formik.touched.first_name
                            ? " is-invalid"
                            : "")
                        }
                        name="first_name"
                        placeholder="First Name"
                        title="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.first_name && formik.touched.first_name ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="second_name">Second Name</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.second_name &&
                          formik.touched.second_name
                            ? " is-invalid"
                            : "")
                        }
                        name="second_name"
                        placeholder="Second Name"
                        title="Second Name"
                        value={formik.values.second_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.second_name &&
                      formik.touched.second_name ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="third_name">Third Name</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.third_name && formik.touched.third_name
                            ? " is-invalid"
                            : "")
                        }
                        name="third_name"
                        placeholder="Third Name"
                        title="Third Name"
                        value={formik.values.third_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.third_name && formik.touched.third_name ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="surname">Surname*</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.surname && formik.touched.surname
                            ? " is-invalid"
                            : "")
                        }
                        name="surname"
                        placeholder="Surname"
                        title="Surname"
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.surname && formik.touched.surname ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="rsa_id">RSA Id*</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.rsa_id && formik.touched.rsa_id
                            ? " is-invalid"
                            : "")
                        }
                        name="rsa_id"
                        placeholder="RSA Id"
                        title="RSA_Id"
                        value={formik.values.rsa_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.rsa_id && formik.touched.rsa_id ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="exam_id">Exam Id*</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.exam_id && formik.touched.exam_id
                            ? " is-invalid"
                            : "")
                        }
                        name="exam_id"
                        placeholder="Exam Id"
                        title="Exam_id"
                        value={formik.values.exam_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.exam_id && formik.touched.exam_id ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="status">Application Status*</label>
                    <div
                      className="form-icon-group mb-4"
                      title="Application Status"
                    >
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.application_status &&
                          formik.touched.application_status
                            ? " is-invalid"
                            : "")
                        }
                        name="application_status"
                        value={ApplicationStatus?.filter((res) => {
                          return res.value == formik.values.application_status;
                        })}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("application_status",value.value);
                          } else {
                            formik.setFieldValue("application_status","")
                          }
                        }
                          
                        }
                        isClearable
                        onBlur={formik.handleBlur}
                        options={ApplicationStatus}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.application_status
                            ? formik.values.application_status
                            : "Application Status  "
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="student_type">Student Type*</label>
                    <div className="form-icon-group mb-4" title="Student Type">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.student_type &&
                          formik.touched.student_type
                            ? " is-invalid"
                            : "")
                        }
                        name="student_type"
                        value={StudentType.filter((val)=>{
                          return val.value == formik.values.student_type
                        }) || ""}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("student_type", value.value)
                          } else {
                            formik.setFieldValue("student_type", "")
                          }
                        }
                          
                        }
                        isClearable
                        onBlur={formik.handleBlur}
                        options={StudentType}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.student_type
                            ? formik.values.student_type
                            : "Student Type  "
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="gender">Gender*</label>
                    <div className="form-icon-group mb-4" title="Gender">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.gender && formik.touched.gender
                            ? " is-invalid"
                            : "")
                        }
                        name="gender"
                        value={Gender.filter((val) => {
                          return val.value == formik.values.gender;
                        })}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("gender", value.value)
                          } else {
                            formik.setFieldValue("gender", "")
                          }
                        }
                          
                        }
                        isClearable
                        onBlur={formik.handleBlur}
                        options={Gender}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.gender ? formik.values.gender : "Gender"
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="race">Race*</label>
                    <div className="form-icon-group mb-4" title="Race">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.race && formik.touched.race
                            ? " is-invalid"
                            : "")
                        }
                        name="race"
                        value={Race.filter((val) => {
                          return val.value == formik.values.race;
                        })}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("race", value.value)
                          } else {
                            formik.setFieldValue("race", "")
                          }
                        }
                          
                        }
                        isClearable
                        onBlur={formik.handleBlur}
                        options={Race}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.race ? formik.values.race : "Race"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>DOB*</label>
                    <div className="form-group-blk mb-3" title="DOB">
                      <DatePicker
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.dob && formik.touched.dob
                            ? " is-invalid"
                            : "")
                        }
                        selected={formik.values.dob}
                        dateFormat={FORM_DATE_FORMAT}
                        onChange={(date) => formik.setFieldValue("dob", date)}
                        title="DOB"
                        placeholderText="Select DOB"
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        dateFormatCalendar="MMMM"
                        showYearDropdown
                        scrollableYearDropdown
                        maxDate={new Date()}
                        
                        // onChange={(e) => onHandleStartDate(e)}
                      />
                    </div>
                  </div>
                </div>
                <hr/>
              </div>
            </div>
            <div className="course-sec-gap course-resource-sec">
              <div className="edit-icon new-card-header">
                <div className="card-header">EXAM CENTRE INFORMATION*</div>
              </div>
              <div className="card-body-inr">
                <div className="row">
                  <div className="col-md-4">
                    <div
                      className="form-icon-group mb-4"
                      title="EXAM CENTRE INFORMATION"
                    >
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.centre && formik.touched.centre
                            ? " is-invalid"
                            : "")
                        }
                        name="centre"
                        value={examCentreList?.filter((res) => {
                          return res.value == formik.values.centre;
                        })}
                        onChange={(value) =>{
                          if(value){
                            formik.setFieldValue("centre", value.value)
                          } else {
                            formik.setFieldValue("centre", "")
                          }
                        }
                          
                        }
                        isClearable
                        onBlur={formik.handleBlur}
                        options={examCentreList.sort((a,b)=>{
                          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
                        })}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.centre
                            ? examCentreList.find(item=>(item.value == formik.values.centre))
                            : "EXAM CENTRE INFORMATION"
                        }
                      />
                      {console.log()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {subType!=="add_details" && <div className="course-sec-gap course-setting-sec">
              <hr />
              <div className="edit-icon new-card-header">
                <div className="card-header">Application Comment to student</div>
              </div>
              <div className="card-body-inr">
                <div className="row">
                  <div className="col-md-12">
                  {<HtmlInputEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                    hideSign
                    isCKEditor
                    isInvalid={
                      formik.touched.centerHeader &&
                      formik.errors.centerHeader
                    }
                  />}
                  </div>
                </div>
              </div>
            </div>}
              <hr/>
            <div className="course-sec-gap course-setting-sec">
              <div className="edit-icon new-card-header">
                  <div className="card-header">EXAM SUBJECT INFORMATION</div>
              </div>
              <div className="card-body-inr">
                <div className="row mb-2">
                  <div className="col-md-3">Course</div>
                  <div className="col-md-2">Is this your first attempt?</div>
                  <div className="col-md-2">Term Mark</div>
                  <div className="col-md-2">Attendance</div>
                  <div className="col-md-1">Written</div>
                  <div className="col-md-1">DHET Result</div>
                  <div className="col-md-1">Action</div>
                </div>
                {examSubject?.map((row,index)=>(            
                  <div className="row"  key={index}>
                    <div className="col-md-3">
                      <div className="form-icon-group mb-4">
                        <Select
                          className={"form-control custom-select-box" + (formik.errors["examSubjects"] !== undefined && formik.errors["examSubjects"][index].subjectName ? " is-invalid" : "")}
                          name="subject"
                          value={subjectList.filter(item => item.value==row.subject)}
                          onChange={(value) => {
                            if(value){
                              handleExamData(index,value.value,"subject")
                            } else {
                              handleExamData(index,"","subject")
                            }
                          }}
                          options={subjectList}
                          title="Course"
                          maxMenuHeight={175}
                          placeholder={"Course"}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-icon-group mb-4">
                        <Select
                          className={"form-control custom-select-box"}
                          name="attempt"
                          title="Is this your first attempt?"
                          value={attemptOption.filter((val)=>{
                            return val.value === row.attempt
                          })}
                          onChange={(value) => {
                            if(value){
                              handleExamData(index,value.value,"attempt")
                            } else {
                              handleExamData(index,"","attempt")
                            }
                          }}
                          options={attemptOption}
                          maxMenuHeight={175}
                          placeholder={"Select"}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-icon-group mb-4">
                        <input
                          type="text"
                          className={"form-control"}
                          name="term"
                          placeholder="Term Mark"
                          title="Term Mark"
                          value={row.mark}
                          onChange={(e)=>{handleExamData(index,e.target.value,"mark")}}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-icon-group mb-4">
                        <input
                          type="text"
                          className={"form-control"}
                          name="attendance"
                          placeholder="Attendance"
                          title="Attendance"
                          value={row.attendance}
                          onChange={(e)=>{handleExamData(index,e.target.value,"attendance")}}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                    <div className="form-group-blk mb-3">
                      <div className="custom-check custom-control custom-checkbox text-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="Important"
                          name="select"
                          checked={row.written}
                          onChange={(e) => handleExamData(index,e.target.checked,"written")}
                        />    
                        <label class="custom-control-label"></label>              
                      </div>
                    </div>                
                    </div>
                    <div className="col-md-1">
                      <div className="form-icon-group mb-4">
                        <input
                          type="text"
                          className={"form-control"}
                          name="dhetResult"
                          placeholder="DHET Result"
                          title="DHET Result"
                          value={row.dhetResult}
                          onChange={(e)=>handleExamData(index,e.target.value,"dhetResult")}
                        />
                      </div>
                    </div>
                    <div className="col-md-1 as-button mb-4">
                      {subType !== "add_details" && <button
                        className="btn btn-primary rounded-circle"
                        type="button"
                        title="View Calculation"
                        onClick={()=>{handleViewCalculateMark(row.subject)}}
                      >
                        <i className="fal fa-eye"></i>
                      </button>}
                      <button
                        type="button"
                        className="btn btn-danger rounded-circle"
                        title="Remove line item"
                        style={{marginLeft:"5px"}}
                        disabled={examSubject.length<2}
                        onClick={() => {handleRemoveItem(index)}}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    </div>                
                  </div>
                ))}
              </div>                    
            </div>               
            <div className="form-group form-group-save-cancel">
              <button
                className="btn btn-save btn-success"
                type="button"
                title="Add"
                onClick={()=>handleExamSubject()}
              >
                Add
              </button>
              <PermissionsGate scopes={subType=="add_details" ? ["eeradd"]: ["eeredit"]}>
              <button
                className="btn btn-save btn-success"
                type="submit"
                title="Save"
                disabled={gearLoading=="save"}
              >
                {gearLoading=="save" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                
                Save
              </button>
              </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={() => formik.resetForm()}
              >
                <i className="fal fa-times"></i>
                Cancel
              </button>
              {Object.keys(formik.errors).map((key)=>(
                Array.isArray(formik.errors[key]) && Array.isArray(formik.touched[key]) ? 
                  <div className="invalid-feedback d-block">
                    {formik.errors[key][0].subjectName}
                  </div>
                : formik.touched[key] && formik.errors[key] ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                ) : null
                ))}              
            </div>
          </form>
        </div>       
      </div>

      <div className="topic-add-modal modal fade" id="viewCalculateMark" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                    <i className="fal fa-info-circle"></i> View Calculated Marks
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <DataTableComponentFrontPagination 
                data={dataToRender()}
                columns={columns}
                loading={markLoading}
                search={search}
                setSearch={setSearch}
                exportData={exportData}
                exportFileName={"Calculate_Mark_Export"}
                isInsidePopUp={true}
                defaultSort={{ defaultSortColumn: "aname", defaultSortAsc: true }}
                bottomButton={[
                  <div className="form-group form-group-save-cancel">
                    <button
                      className="btn btn-save btn-success"
                      type="button"
                      title="Save"
                      onClick={handleGenerateMark}
                      disabled={generateMark ? true : false}
                    >
                      {generateMark === false ? (
                          <i className="fal fa-save"></i>
                      ) : (
                          <i className="fas fa-cog fa-spin"></i>
                      )}
                      Generate Mark
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        $("#viewCalculateMark").modal("hide");
                      }}
                    >
                        Cancel
                    </button>
                  </div>
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationDetails;
