import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import * as Yup from "yup";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  ExamsFinalMarksImportList,
  ExamsFinalMarksImportListFilter,
  ExamsFinalUpdatedion,
  ExamsMarksUpdate,
  ImportExamFinalMarksFile,
} from "../../../../services/ExternalExamServices";
import Tablefilter from "../../../common/Tablefilter";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import axios from "axios";

const MarksList = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [markList, setMarkList] = useState([]);
  const [flaggedData, setFlaggedData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("flagged_type");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [flaggedFilter, setFlaggedFilter] = useState({arr : [], checkObj : {}});
  const [gearLoading, setGearLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eefmiview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ExamsFinalMarksImportListFilter(id,filterSource.token);
        if(res.status==200){
          setFlaggedData(res.data.flaggedFilter);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');    
    };
  }, [])
  

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const mark = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exam: id,
        viaFlagged: flaggedFilter.arr,
      };

      try {
        const res = await ExamsFinalMarksImportList(mark);
        if(res.status==200){
          setMarkList(res.data.data.data);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }

    }
    
    getListData();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    flaggedFilter,
    id,
    refresh
  ]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Filtering

  // TODO Reset Filter

  const resetFilter = () => {
    setFlaggedFilter({arr : [], checkObj : {}});
    setSearch("");
  };

  // TODO Data

  const columns = useMemo(() => [
    {
      name: "RSA ID",
      selector: "rsa_id",
      sortField: "rsa_id",
      sortable: true,
      cell: (row) => (row.rsa_id ? row.rsa_id : "-"),
    },
    {
      name: "Exam ID",
      selector: "exam_id_num",
      sortField: "exam_id_num",
      sortable: true,
      cell: (row) => (row.exam_id_num ? row.exam_id_num : "-"),
    },

    // { name: "Email", selector: "Email", sortable: true, cell: (row) => TrimText(row.Email ? row.Email : "NA", 15) },

    {
      name: "Surname",
      selector: "last_name",
      sortField: "last_name",
      sortable: true,
      cell: (row) => (row.last_name ? row.last_name : "-"),
    },
    {
      name: "Course Code",
      selector: "code",
      sortField: "code",
      sortable: true,
      cell: (row) => (row.code ? row.code : "-"),
    },
    {
      name: "Course Name",
      selector: "subject_name",
      sortField: "subject_name",
      sortable: true,
      cell: (row) =>row.subject_name ? <Link title={row.subject_name} to={`/courseAdministration/coursesdetails/${row.subject}/detailCourses/show`} className="feature-name"> <span className="textLimit100">{row.subject_name}</span></Link> :"-"
    },
    {
      name: "DHET Result",
      selector: "dhetresult",
      sortField: "dhetresult",
      sortable: true,
      cell: (row) => (row.dhetresult.toString() ? row.dhetresult : "-"),
    },
    {
      name: "Pass Indicator",
      selector: "pass_indi",
      sortField: "pass_indi",
      sortable: true,
      cell: (row) => (row.pass_indi ? row.pass_indi : "-"),
    },
    {
      name: "Suspended Indicator",
      selector: "suspend_indi",
      sortField: "suspend_indi",
      sortable: true,
      cell: (row) => (row.suspend_indi.toString() ? row.suspend_indi : "-"),
    },
    {
      name: "Flagged",
      selector: "flagged_type",
      sortField: "flagged_type",
      sortable: true,
      cell: (row) => (row.flagged_type ? row.flagged_type : "-"),
    },
    {
      name: "File Name",
      selector: "file_name",
      sortField: "file_name",
      sortable: true,
      cell: (row) => (row.file_name ? row.file_name : "-"),
    },
    {
      name: "Update",
      selector: "updated",
      sortField: "updated",
      sortable: true,
      cell: (row) => (row.updated ? row.updated : "-"),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "RSA ID",
      "Exam ID",
      "Surname",
      "Course Code",
      "Course Name",
      "DHET Result",
      "Pass Indicator",
      "Suspended Indicator",
      "Flagged",
      "File Name",
      "Update",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const mark = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exam: id,
      viaFlagged: flaggedFilter.arr,
      exportStatus: "true",
    };
    ExamsFinalMarksImportList(mark)
      .then((res) => {
        data = res.data.data;
        data = data?.map((row) => ({
          ...row,
          "Exam ID": row?.exam_id_num ? row?.exam_id_num : "-",
          "RSA ID": row?.rsa_id ? row?.rsa_id : "-",
          Surname: row?.last_name ? row?.last_name : "-",
          "Course Code": row?.code ? row?.code : "-",
          "Course Name": row?.subject_name ? row?.subject_name : "-",
          "Pass Indicator": row?.pass_indi ? row?.pass_indi : "-",
          "Suspended Indicator": row?.suspend_indi.toString()
            ? row?.suspend_indi
            : "-",
          Flagged: row?.flagged_type ? row?.flagged_type : "-",
          "File Name": row?.file_name ? row?.file_name : "-",
          Update: row?.updated ? row?.updated : "-",
          "DHET Result": row?.dhetresult.toString() ? row?.dhetresult : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["RSA ID"],
              row["Exam ID"],
              row["Surname"],
              row["Course Code"],
              row["Course Name"],
              row["Pass Indicator"],
              row["Suspended Indicator"],
              row["Flagged"],
              row["File Name"],
              row["Update"],
              row["DHET Result"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = () => {
    setGearLoading(true);
    let formData = new FormData();
    formData.set("exam", id);
    ExamsMarksUpdate(formData)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Records successfully updated",
        });
        setGearLoading(false);
      })
      .catch((err) =>{ console.log(err);setGearLoading(false);});
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDs: "",
    },
    validationSchema: Yup.object({
      Ids: Yup.string().required("File is required"),
    }),
    onSubmit: (values) => {
      //   TODO API
    },
  });
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      Swal.fire({
        icon: "info",
        title: 'Processing File...',
        text: 'Upload may take a bit longer than usual',
        showConfirmButton: false
      });
      formik.setFieldValue("IDs",e.target.files[0]);
      const formData = new FormData();
      formData.append("exam", id);
      formData.append("attachment", e.target.files[0]);
      ImportExamFinalMarksFile(formData)
        .then((res) => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Added successfully",
          });
          setRefresh(!refresh);
        })
        .catch((err) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setRefresh(!refresh);
        })
    }
  };
  return (
    <div className="card card-profile-info-card mb-30 course-details-tab-sec">
      <div className="card-body">
        <div className="my-tickets-info-list Tickets-main-wrap">          
          <div className="edit-icon new-card-header">
            <div className="card-header">Upload result file for import</div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group-blk mb-3">
                  <label>Upload File *</label>
                  <div className="form-group atttach-file m-0">
                    <label
                      className={
                        formik.errors.IDs &&
                        formik.touched.IDs &&
                        "file-req is-invalid"
                      }
                    >
                      <i className="fal fa-paperclip"></i>
                      <span>Add Attachment</span>
                      {/* <PermissionsGate
                        errorProps={{ disabled: true }}
                        scopes={["spiedit"]}
                      > */}
                        <input
                          type="file"
                          title="Add Attachment"
                          className="form-control  form-control-aatch-file"
                          //  disabled={disabled}
                          accept="text/plain"
                          onChange={(e) => {
                            console.log("efiles",e.target.files)
                            handleFileChange(e);
                          }}
                        />
                      {/* </PermissionsGate> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-group form-group-save-cancel">
          <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>          

          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}                    
        </div> */}
          </form>
          <hr />
          <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec intake-year-z-i">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={search}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                        <Tablefilter
                          filterName={"Flagged"}
                          optionArr={flaggedData}
                          state={flaggedFilter}
                          setState={setFlaggedFilter}
                        />
                      </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", "Marks");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", "Marks");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", "Marks");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
              <DataTable
                paginationDefaultPage={page}
                progressPending={loading}
                progressComponent={<SkeletonTicketList />}
                data={markList}
                defaultSortField={sortkey}
                sortServer
                defaultSortAsc={false}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
                onSort={handleSort}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover={false}
              />
          </div>
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-md-4 col-lg-3 ml-auto text-right">
          <PermissionsGate scopes={["eefmiedit"]} RenderError={()=>(
            <>
              <button className="btn btn-primary mr-2" title="Update for 4/5" disabled>
              <i className="fa fa-paper-plane"></i>  Update for 4/5
              </button>
              <button className="btn btn-primary" title="Update Result" disabled>Update Result</button>
            </>
          )}>
            <button
              className="btn btn-primary mr-2"
              title="Update for 4/5"
              onClick={() => handleUpdate()}
              disabled={gearLoading}
            >
             {gearLoading ? <i className="fas fa-cog fa-spin"></i> :  <i className="fa fa-paper-plane"></i>} Update for 4/5
            </button>
            <Link
              to={`/studentAdministration/externalExam/details/open/updatedResult/${id}`}
            >
              <button className="btn btn-primary" title="Update Result">
                Update Result
              </button>
            </Link>
            </PermissionsGate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarksList;
