import axiosInstance from "../utils/axios";

export const getMyTickets = async (values) => {
    return await axiosInstance().get(`/getMyTickets?id_intake=${values}`);
  };

  export const GetStudentTicketList = async (data) => {
    return await axiosInstance().post(`/getStudentTicketList`,data);
  };

  export const GetStudentTicketListCancelToken = async (data, cancelToken) => {
    return await axiosInstance().post(`/getStudentTicketList`,data, {cancelToken});
  };

  export const GetStudentNoteList = async (values) => {
    return await axiosInstance().get(`/getStudentNoteList?uid=${values}`);
  };

  export const GetStudentNoteListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getStudentNoteList?uid=${values}`, {cancelToken});
  };

  export const GetNoteSubjectList = async (values) => {
    return await axiosInstance().get(`/getNoteSubjectList?uid=${values}`);
  };

  export const GetStudentNoteListFilter = async (values) => {
    return await axiosInstance().get(`/getStudentNoteListFilter?uid=${values}`);
  };

  export const AddOrUpdateStudentNote = async (values) => {
    return await axiosInstance().post(`/addOrUpdateStudentNote`, values);
  };

  export const GetStudentLicenseList = async (values) => {
    return await axiosInstance().post(`/getStudentLicenseList?uid=${values}`);
  };

  export const UpdateStudentLicense = async (values) => {
    return await axiosInstance().post(`/updateStudentLicense`, values);
  };

  export const GetCertificateDetail = async (values) => {
    return await axiosInstance().get(`/getCertificateDetail?uid=${values}`);
  };

  export const GetCertificateDetailCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getCertificateDetail?uid=${values}`, {cancelToken});
  };

  export const GetCertificateDetails = async (values) => {
    return await axiosInstance().get(`/getCertificateDetails?id=${values}`);
  };

  export const GetCertificateLinkedList = async (values, type) => {
    return await axiosInstance().get(`/getCertificateLinkedList?certificate_type=${type}&uid=${values}`);
  };

  export const UploadStudentCertificate = async (values) => {
    return await axiosInstance().post(`/replaceStudentCertificate`, values);
  };

  export const SaveStudentSingleCertificate = async (values) => {
    return await axiosInstance().post(`/saveStudentSingleCertificate`, values);
  };

  export const SaveStudentCertificate = async (values) => {
    return await axiosInstance().post(`/saveStudentCertificate`, values);
  };

  export const DeleteStudentCertificate = async (id, certificate) => {
    return await axiosInstance().get(`/deleteStudentCertificate?certificate=${certificate}&id=${id}`);
  };

  export const GetStudentTicketListFilters = async (values) => {
    return await axiosInstance().get(`/getStudentTicketListFilters?uid=${values}`);
  };

  export const StudentCertificatNotification = async (values) => {
    return await axiosInstance().post(`/studentCertificatNotification`,values);
  };

  export const SendLicensesStudentNotification = async (values) => {
    return await axiosInstance().post(`/sendLicensesStudentNotification`,values);
  };