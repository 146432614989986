import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import { RenderQuizAttemptResultStatus } from "../../../../../utils/CommonStatusItems";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { assessmentExemptionToggle } from "../../../../../services/GradeSettingService";
import moment from "moment";

const StudentGradeBookTable = (props) => {
  let percentageView = props.percentageView;
  const { id } = useParams();
  const [expandedRows, setExpandedRows] = useState([]);
  const [courseList, setCourseList] = useState(props?.courseWithAssessmentData);
  const [isWeightShown, setIsWeightShown] = useState(props?.isWeightShown);
  const [iscontributionShown, setiscontributionShown] = useState(props?.iscontributionShown);

  const handleRowToggle = (rowId, e, iconClick) => {
    if (
      e &&
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.parentElement
    ) {
      var parentElements = e.target.parentElement.parentElement.parentElement;
    }
    const isRowExpanded = expandedRows.includes(rowId);
    const rows = document.getElementsByClassName("expanded-course-row");
    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.remove("expanded-course-row");
    }
    let tempExpendedRows = expandedRows;
    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      tempExpendedRows = expandedRows.filter((id) => id !== rowId);
    } else {
      if (parentElements && iconClick) {
        parentElements.classList.add("expanded-course-row");
      } else {
        e.currentTarget.classList.add("expanded-course-row");
      }
      setExpandedRows([rowId]);
      tempExpendedRows = [rowId];
    }
    let tempArr = courseList.map((row) => {
      return {
        ...row,
        isExpanded: tempExpendedRows.includes(row.id),
      };
    });
    setCourseList(tempArr);
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };

  const handleUpdateExemption = async (assId, val, type, courseId) => {
    let data = {
      id: assId,
      student_id: id,
      grant_exemption: val,
    };
    if (type) {
      data = { ...data, type, qid: props.qid };
      let tempArr = courseList;
      let finalArr = tempArr.map((item) => {
        if (item.intake_id === assId) {
          return {
            ...item,
            grant_exemption: val,
          };
        }
        return item;
      });
      setCourseList(finalArr);
    } else {
      let arr = courseList;
      let finalArr = Promise.all(
        arr.map(async (item) => {
          if (item.id === courseId) {
            let secondArr = await item?.assessments.map((ass) => {
              if (ass.assessment_id === assId) {
                return {
                  ...ass,
                  grant_exemption: val,
                };
              }
              return ass;
            });
            return {
              ...item,
              assessments: secondArr,
            };
          }
          return item;
        })
      );
      finalArr
        .then((res) => {
          setCourseList(res);
        })
        .catch((err) => {
          props.setUpdateData(!props.updateData);
        });
    }
    assessmentExemptionToggle(data).then((res) => {
      Swal.fire("Updated!", res.data.message, "success")
      .then(res => props.setUpdateData(!props.updateData));
    });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div
                className="expand-toggle-button mr-2"
                onClick={(e) => handleRowToggle(row.id, e, "iconClick")}
              >
                {row.isExpanded ? (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-minus-circle"></i>
                  </span>
                ) : (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-plus-circle"></i>
                  </span>
                )}
              </div>
              <span title={row.course_name} className="overflow-ellipsis2 ">
                {row.course_name ? (
                  <Link
                    className="as-text-topbar-gradebook curser feature-name"
                    to={`/courseAdministration/coursesdetails/${row?.id}/detailCourses/show`}
                  >
                    <span className="textLimit100">{row.course_name}</span>
                  </Link>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </>
        );
      },
    },
    isWeightShown && {
      name: "Weight",
      selector: "code",
      cell: (row) => {
        return row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <>
            <div className="resultstatus_flex arrowhide">
              <span>{row?.weight}</span>
            </div>
          </>
        );
      },
    },
    {
      name: "Score Recieved",
      selector: "year",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <>
            {row.getAssessmentCalculate_sum_ass_mark &&
              row.getAssessmentCalculate_sum_ass_total_mark ? (
              <div className="resultstatus_flex arrowhide">
                {row.getAssessmentCalculate_sum_ass_mark} /{" "}
                {row.getAssessmentCalculate_sum_ass_total_mark}
              </div>
            ) : (
              "N/A"
            )}
          </>
        ),
    },
    {
      name: "Range",
      selector: "open_date_time",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <p>
            {row.getAssessmentCalculate_sum_ass_total_mark ? (
              <span>0 - {row.getAssessmentCalculate_sum_ass_total_mark}</span>
            ) : (
              "N/A"
            )}
          </p>
        ),
    },
    {
      name: "Grade",
      selector: "close_date_time_mili",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : percentageView ? (
          row.scoreRecieved + "%"
        ) : row?.grading_type && row?.grading_type == 3 ? (
          `${row?.getAssessmentCalculate_sum_ass_mark}/${row?.getAssessmentCalculate_sum_ass_total_mark}`
        ) : row?.gradeText ? (
          row?.gradeText
        ) : (
          ""
        ),
    },
    // iscontributionShown && {
    //   name: "Contribution to Final Score ",
    //   selector: "type",
    //   cell: (row) => {
    //     return row.grant_exemption ? (
    //       <span className="exempted-text">
    //         Exempted <i className="fal fa-info-circle"></i>
    //       </span>
    //     ) : !row?.assessments.length ||
    //       row?.submited == "0" ||
    //       moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
    //       "N/A"
    //     ) : percentageView && row.contributionMark ? (
    //       row.contributionMark.includes("%") ? (
    //         Math.round(
    //           parseFloat(row.contributionMark.replace("%", ""))
    //         ).toString() + "%"
    //       ) : (
    //         row.contributionMark
    //       )
    //     ) : (row?.grading_type == "0" || row?.grading_type == "3") &&
    //       row.contributionMark ? (
    //       row.contributionMark.includes("%") ? (
    //         Math.round(
    //           parseFloat(row.contributionMark.replace("%", ""))
    //         ).toString() + "%"
    //       ) : (
    //         row.contributionMark
    //       )
    //     ) : (
    //       ""
    //     );
    //   },
    // },
    {
      name: "Result",
      selector: "markerName",
      sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : row?.pass_status == "N/A" ? (
          "N/A"
        ) : row.pass_status ? (
          RenderQuizAttemptResultStatus(row.pass_status).html
        ) : (
          ""
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row.grant_exemption ? (
              <PermissionsGate
                scopes={["studentgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-success rounded-circle"
                  title="Remove from Exemption"
                  onClick={() => handleUpdateExemption(row.id, 0, 1)}
                >
                  <i class="fal fa-user-plus"></i>
                </button>
              </PermissionsGate>
            ) : (
              <PermissionsGate
                scopes={["studentgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Grant Exemption"
                  onClick={() => handleUpdateExemption(row.id, 1, 1)}
                >
                  <i className="fal fa-user-times"></i>
                </button>
              </PermissionsGate>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const ExpandedRowComponent = (props) => {
    let data = props.data.data;
    let isExempted = props.isExempted;
    const Assessmentcolumns = useMemo(() => [
      {
        name: "Name",
        selector: "name",
        sortField: "name",
        sortable: true,
        cell: (row) => {
          return (
            <span title={row.name} className="overflow-ellipsis2 ">
              {row.name ? (
                <Link
                  className="as-text-blue curser feature-name"
                  to={`/courseAdministration/coursesdetails/${props.data.intakeId}/assessments/open/assigned/Details/${row.assessment_id}`}
                >
                  <span className="textLimit100">{row.name}</span>
                </Link>
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
      isWeightShown && {
        name: "Weight",
        selector: "weight",
        sortField: "ass_weighting",
        sortable: true,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className="exempted-text">
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (
            <>
              <div
                className="resultstatus_flex arrowhide"
                style={{ width: "35%" }}
              >
                <div className="form-icon-group status-select-form">
                  <span>{row.ass_weighting}</span>
                </div>
              </div>
            </>
          ),
      },
      {
        name: "Score Recieved",
        selector: "ass_mark",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className="exempted-text">
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (
            <div className="resultstatus_flex arrowhide">
              <div className="form-icon-group status-select-form">
                <span className="min-width-max-content">
                  {row.ass_mark} / {row.ass_total_mark}
                </span>
              </div>
              {row?.assessmentMarkingStatus?.toString() === "notsubmitted" ||
                row?.assessmentMarkingStatus?.toString() === "NOT SUBMITTED" ? (
                <div
                  className="grade-info-ic grade-info-red ml-2"
                  title="Not Submitted"
                >
                  <i className="fal fa-file-times"></i>
                </div>
              ) : (
                ""
              )}
              {!row?.mark_edited || row?.mark_edited == "0" ? (
                ""
              ) : (
                <div
                  className="grade-info-ic grade-info-orange ml-2"
                  title="Mark Edited"
                >
                  <i className="fal fa-edit"></i>
                </div>
              )}
              {row?.assessmentStatus?.toString() === "OPEN" ? (
                <div
                  className="grade-info-ic grade-info-green ml-2"
                  title="Open/Extension"
                >
                  <i className="fal fa-calendar-day"></i>
                </div>
              ) : (
                ""
              )}
              {row?.assessmentStatus?.toString() === "PENDING" ? (
                <div
                  className="grade-info-ic grade-info-blue ml-2"
                  title="Upcoming Assessment"
                >
                  <i className="fal fa-calendar-alt"></i>
                </div>
              ) : (
                ""
              )}
            </div>
          ),
      },
      {
        name: "Range",
        selector: "ass_total_mark",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className="exempted-text">
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (
            <p>
              {row.ass_total_mark ? <span>0 - {row.ass_total_mark}</span> : "N/A"}
            </p>
          ),
      },
      {
        name: "Grade",
        selector: "grade",
        sortable: false,
        cell: (row) => {
          let options = [
            { label: "Red", value: "0" },
            { label: "Yellow", value: "49" },
            { label: "Green", value: "100" },
          ];
          return row.grant_exemption || isExempted ? (
            <span className="exempted-text">
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : percentageView ? (
            row.ass_percentage
          ) : row?.submited == "0" ||
            moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
            "N/A"
          ) : row?.grading_type == 3 ? (
            `${row?.ass_mark}/${row?.ass_total_mark}`
          ) : row?.gradeText ? (
            row?.gradeText
          ) : (
            "N/A"
          );
        },
      },
      // iscontributionShown && {
      //   name: "Contribution to Final Score ",
      //   selector: "contributionMark",
      //   sortable: false,
      //   cell: (row) => {
      //     return row.grant_exemption || isExempted ? (
      //       <span className="exempted-text">
      //         Exempted <i className="fal fa-info-circle"></i>
      //       </span>
      //     ) : row?.submited == "0" ||
      //       moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
      //       "N/A"
      //     ) : percentageView && row.contributionMark ? (
      //       row.contributionMark.includes("%") ? (
      //         Math.round(
      //           parseFloat(row.contributionMark.replace("%", ""))
      //         ).toString() + "%"
      //       ) : (
      //         row.contributionMark
      //       )
      //     ) : (row?.grading_type == "0" || row?.grading_type == "3") &&
      //       row.contributionMark ? (
      //       row.contributionMark.includes("%") ? (
      //         Math.round(
      //           parseFloat(row.contributionMark.replace("%", ""))
      //         ).toString() + "%"
      //       ) : (
      //         row.contributionMark
      //       )
      //     ) : row.contributionMark ? (
      //       row.contributionMark
      //     ) : (
      //       "N/A"
      //     );
      //   },
      // },
      {
        name: "Result",
        selector: "pass_status",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className="exempted-text">
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : row?.submited == "0" ||
            moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
            "N/A"
          ) : row.pass_status == "N/A" ? (
            "N/A"
          ) : row.pass_status ? (
            RenderQuizAttemptResultStatus(row.pass_status).html
          ) : (
            "-"
          ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              {row.grant_exemption ? (
                <PermissionsGate
                  scopes={["studentgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-success rounded-circle"
                    title="Remove from Exemption"
                    onClick={() =>
                      handleUpdateExemption(
                        row.assessment_id,
                        0,
                        0,
                        props.data.intakeId
                      )
                    }
                    disabled={row.lock_grades == "1"}
                  >
                    <i class="fal fa-user-plus"></i>
                  </button>
                </PermissionsGate>
              ) : (
                <PermissionsGate
                  scopes={["studentgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Grant Exemption"
                    onClick={() =>
                      handleUpdateExemption(
                        row.assessment_id,
                        1,
                        0,
                        props.data.intakeId
                      )
                    }
                    disabled={row.lock_grades == "1"}
                  >
                    <i className="fal fa-user-times"></i>
                  </button>
                </PermissionsGate>
              )}
            </div>
          </div>
        ),
      },
    ]);

    return (
      <DataTable
        noHeader
        noTableHead
        data={data}
        columns={Assessmentcolumns.filter((item) => item)}
        pagination={false}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        noDataComponent={
          <span className="my-10px">
            There are no assessments linked to this course
          </span>
        }
      />
    );
  };

  const ExpandedRowWrapper = ({ data }) => {
    return (
      <ExpandedRowComponent
        key={data?.id}
        isExempted={data?.grant_exemption}
        data={{ data: data?.assessments, intakeId: data?.id }}
      />
    );
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
        <div className="l-o-c-t filter-search-icon card card-table-custom ">
          <DataTable
            data={courseList}
            columns={columns.filter((item) => item)}
            pagination={true}
            defaultSortField="name"
            defaultSortAsc={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            expandableRows
            expandOnRowClicked
            expandableRowsComponent={<ExpandedRowWrapper />}
            onRowClicked={(row, e) => handleRowToggle(row?.id, e)}
            expandableIcon={
              <FontAwesomeIcon
                icon={faCirclePlus}
                className="custom-collapse-icon"
              />
            } // Customize the expand icon
            collapseIcon={
              <FontAwesomeIcon
                icon={faCircleMinus}
                className="custom-collapse-icon"
              />
            }
            expandableRowExpanded={(row) => isRowExpanded(row.id)} // Check if row is expanded
            className="gradebook-expand-table"
          />
        </div>
      </div>
    </>
  );
};

export default StudentGradeBookTable;
