import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";

import Str from "../../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../../../utils/permissionGate";
import { ExamsNotificationList, TrashNotification } from "../../../../../services/ExternalExamServices";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../../../../../utils/Constants";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import axios from "axios";

const NotificationList = () => {
 const {id}=useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});

  const [userData, setUserData] = useState([]);

  const [loading, setloading] = useState(true);
  const [deletedRow, setDeletedRow] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("posted_on");
  const [sortOrder, setSortOrder] = useState("DESC");
  const givenPermsisions = useSelector((state) => state.givenPermission);



  useEffect(() => {
    let response = hasPermission({ scopes: ["eenview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Reset Filter

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setSearch("");
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  useEffect(() => {
    const cancelTokenSources = [];
    const getList = async()=>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const exam = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStatus: status,
        exam: id,
      };

      try {
        const res = await ExamsNotificationList(exam, source.token);
        if(res.status==200){
          setUserData(res?.data?.data?.data);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    
    getList();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [page,perPage,sortkey,sortOrder,search,id, deletedRow]);

  const handleDelete = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        TrashNotification({type:"exam_notification", id :id}).then((res)=>{
          if(res.status===200){
            Swal.fire("Deleted!", "Deleted Successfully", "success");
            setDeletedRow(!deletedRow)
          }
        }).catch((err)=>{console.log(err)})
      }
    });
  }
  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortField:"subject",
      sortable: true,
      cell: (row) =>
        row.subject ? (
          <PermissionsGate scopes={["eenedit"]} RenderError={() => (
            <p>{row?.subject.trim() ? <span title={row.subject} className="feature-exam_period"> <span className="textLimit100">{row.subject}</span> </span> : "-"}</p>
          )}>
          <Link
            to={ `/studentAdministration/externalExam/details/open/notification/${id}/open/${row.id}`}
              title={row.subject}
              className="as-text-blue curser feature-name"
            >
              <span className="textLimit100">{row.subject}</span>
          </Link>
          </PermissionsGate>
        ) : (
          "-"
        ),
    },
    {
      name: "Exam Date",
      selector: "exam_period",
      sortField:"exam_period",
      sortable: true,
      cell: (row) => (row.exam_period ?`${row.exam_period} (${moment(row.exam_start).format(TABLE_DATE_FORMAT)} - ${moment(row.exam_end).format(TABLE_DATE_FORMAT)})` : "-"),
    },
    {
      name: "Posted Date",
      selector: "posted_on",
      sortField:"posted_on",
      sortable: true,
      cell: (row) => (row.posted_on ? moment(row.posted_on).format(TABLE_DATE_FORMAT) : "-"),
    },

    {
      name: "Action",
      selector: "",

      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["umddedit"]}> */}
            <PermissionsGate scopes={["eenedit"]} RenderError={()=>(
              <button className="btn btn-primary rounded-circle" title="Open" disabled>                    
              <i className="fal fa-folder-open"></i>                 
            </button>
            )}>
                <Link
                  to={ `/studentAdministration/externalExam/details/open/notification/${id}/open/${row.id}`}
                    title="Open"
                    className="btn btn-primary rounded-circle"
                    // onClick={() => handleEdit(row.id)}
                  >
                    <i className="fal fa-folder-open"></i>
                </Link>
              </PermissionsGate>
              <PermissionsGate scopes={["eendelete"]} errorProps={{disabled: true}}>
                <button 
                className="btn btn-danger rounded-circle"
                Title="Delete"
                onClick={()=>{handleDelete(row?.id)}}
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
                </PermissionsGate>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ]);


  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Subject", "Exam Date", "Posted Date"];

   
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const exam = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStatus: status,
      exam: id,
      exportStatus: "true",
    };
    ExamsNotificationList(exam)
      .then((res) => {
        console.log("ressss",res.data.data);
        data=res.data.data;
        data = data?.map((row) => ({
          ...row,
          // No: row?.no,
          Subject: row?.subject ? row?.subject : "-",
          "Exam Date": row.exam_period ?`${row.exam_period} (${row.exam_start}-${row.exam_end})` : "-",
          "Posted Date": row.posted_on ? row.posted_on : "-",
        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          console.log(csvString);
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
    
          // Returning false as downloading of file is already taken care of
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [row["Subject"], row["Exam Date"], row["Posted Date"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
       
      });

  
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap right-align-action">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
            <div className="table-responsive-div">
              <div
                id="assessment-table-main_wrapper"
                className="dataTables_wrapper no-footer"
              >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                </div>
              </div>
            </div>
            <div className="filter-button-group">
              <div className="reset-btn-group">
                <div className="button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    onClick={resetFilter}
                    title="Reset"
                  >
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Notification");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Notification");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Notification");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <Link to={`/studentAdministration/externalExam/details/open/notification/${id}/add`}>
                  <PermissionsGate
                    scopes={["eenadd"]}
                    errorProps={{disabled:true}}
                  >
                    <button className="btn btn-primary" title="New Notification">
                      <i className="fal fa-plus"></i>New Notification
                    </button>
                  </PermissionsGate>
                </Link>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            sortServer            
            defaultSortField={sortkey}
            data={userData}
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
          />
      </div>
    </div>
  );
};

export default NotificationList;
