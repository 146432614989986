import $ from "jquery";
import "jspdf-autotable";
import JsPDF from "jspdf";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
// import CoursesTable from "./CoursesTable";

import listIcon from "../../../assets/images/list-tree.svg";
import PermissionsGate from "../../../utils/permissionGate";
import { RenderCorrespondence, RenderLearningMethod, RenderProgrammeOrCourseType, RenderSchool } from "../../../utils/CommonGroupingItem";
import { getIntakeList } from "../../../services/EmailServices";
import CoursesTable from "../../StudentAdministration/registration/CoursesAndPrograms/CoursesTable";
import { GetCommonContentReReg } from "../../../services/ProgrammeServices";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";

function AssignedIntake(props) {
  const [dropdownValues, setDropdownValues] = useState({
    study_type: [
      { value: "cores", label: "Correspondence" },
      { value: "fulltimecontact", label: "Full Time (Contact)" },
      { value: "fulltimeonline", label: "Full Time (Online)" },
      { value: "full", label: "Full-Time" },
      { value: "part", label: "Part-Time" },
      { value: "parttimeonline", label: "Part-Time (Online)" },
    ],
  });
  const [salesPerson, setSalesPerson] = useState([]);
  const [assignQualification, setAssignQualification] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [intake, setIntake] = useState([]);
  const [intakeYear, setIntakeYear] = useState([]);
  const [searchIntakeYearCheck, setSearchIntakeYearCheck] = useState({});

  const [intakeNumberArr, setIntakeNumberArr] = useState([]);
  const [intakeNumber, setIntakeNumber] = useState([]);
  const [searchIntakeNumberCheck, setSearchIntakeNumberCheck] = useState({});
  const [courseType, setCourseType] = useState([]);
  const [courseTypeCheck, setCourseTypeCheck] = useState({});


  const [studyType, setStudyType] = useState([]);
  const [studyTypeArr, setStudyTypeArr] = useState([]);
  const [intakeList, setIntakeList] = useState([]);
  const [intakeArr, setIntakeArr] = useState([]);
  const [schoolFilterArr, setSchoolFilterArr] = useState([]);
  const [programmeType, setProgrammeType] = useState([]);
  const [searchStudyTypeCheck, setSearchStudyTypeCheck] = useState({});
  const [searchIntakeCheck, setSearchIntakeCheck] = useState({});
  const [intakeHash, setIntakeHash] = useState([]);
  const [searchCheck, setSearchTypeCheck] = useState({});
  const [searchIntakeHashCheck, setSearchIntakeHashCheck] = useState({});
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [school, setSchool] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [props])


  useEffect(() => {
    setAssignQualification(props?.data)
  }, [props?.data])


  useEffect(() => {
    GetCommonContentReReg()
      .then((res) => {
        setIntakeList(res.data.intake_year)
        setIntakeArr(res.data.intake_number)
        setStudyTypeArr(res.data.study_type)
        setSchoolFilterArr(res.data.school_type)
        setProgrammeType(res.data.programs)
      })
  }, [])

  function getValue(arrName, label) {
    let labelName = "";
    if (arrName === "sales_person") {
      for (let item of salesPerson) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }
      return labelName;
    } else {
      for (let item of dropdownValues[arrName]) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }

      if (labelName === "" && arrName === "re_registration_status") return label;
      return labelName;
    }
  }



  function moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  //move index 1(b) to index 2(c)
  // console.log(moveArrayItemToNewIndex(["a","b","c","d"], 1, 2)); // returns ["a", "c", "b", "d"]

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) =>
        row.name ? (
          // <p className="as-text-blue" title={row.course_name}>
          //   {TrimText(row.course_name, 20)}
          // </p>
          <p className="as-text-blue overflow-ellipsis2" title={row.name}>
            {row.name}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>,
    },
    {
      name: "School Type",
      selector: "school_type",
      sortable: true,
      cell: (row) => <p>{row.type ? (RenderSchool(row.school_type).html) : "-"}</p>,
    },
  ]);

  // function moveUp(index) {
  //   let arr = [...assignQualification];
  //   arr = moveArrayItemToNewIndex(arr, index, index - 1);
  //   setAssignQualification(arr);
  //   props.setData(arr);
  // }

  // function moveDown(index) {
  //   let arr = [...assignQualification];
  //   arr = moveArrayItemToNewIndex(arr, index, index + 1);
  //   setAssignQualification(arr);
  //   props.setData(arr);
  // }

  function dataToRender() {
    let updatedData = [];
    let allData = assignQualification.length > 0 && assignQualification[0].sub_item ? assignQualification[0].sub_item : [];
    if (search.length) {
      let tempproduct_name = allData.filter((item) => {
        let includes = item.name && item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSchool = allData.filter((item) => {
        let includes = item.school_type && item.school_type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let temptype = allData.filter((item) => {
        let includes = item.type && item.type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempyear = allData.filter((item) => {
        let includes = item.year && item.year.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempNumber = allData.filter((item) => {
        let includes = item.number && item.number.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempproduct_name, ...tempSchool, ...temptype, ...tempyear, ...tempNumber];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (intake.length) {
      let tempIntakeyear = updatedData;
      let tempResult = tempIntakeyear.filter((item) => {
        const startsWith = intake.find(function (post, index) {
          if (post.toString().toLowerCase() === item.year.toString() ? item.year.toString().toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (intakeHash.length) {
      let tempIntakeNo = updatedData;
      let tempResult = tempIntakeNo.filter((item) => {
        const startsWith = intakeHash.find(function (post, index) {
          if (post.toString().toLowerCase() === item.number.toString() ? item.number.toString().toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (school.length) {
      let tempSchool = updatedData;
      let tempResult = tempSchool.filter((item) => {
        const startsWith = school.find(function (post, index) {
          if (post.toString().toLowerCase() === item.school_type.toString().toLowerCase() ? item.school_type.toString().toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    // if (typeFilter.length) {
    //   let tempType = updatedData;
    //   let tempSearch = tempType.filter((item) => {
    //     const startsWith = typeFilter.find(function (user, index) {
    //       if (user.toLowerCase() == (item.program_type ? item.program_type.toLowerCase() : "")) return item;
    //     });
    //     if (startsWith) {
    //       return startsWith;
    //     } else return null;
    //   });

    //   updatedData = tempSearch;
    // }

    if (studyType.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = studyType.find(function (post, index) {
          if (post.toLowerCase() == (item.type ? item.type.toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (school.length || search.length
      //  || typeFilter.length
      || intake.length || intakeHash.length || studyType.length) {
      return updatedData;
    } else {
      return allData;
    }
  }


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };



  const handleStudyTypeFilter = (e) => {
    const value = e.target.value;
    let arr = studyType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStudyType(arr);
    let id = e.target.id;
    setSearchStudyTypeCheck({ ...searchStudyTypeCheck, [id]: !searchStudyTypeCheck[id] });
  };



  const handleIntakeFilter = (e) => {
    const value = e.target.value;
    let arr = intake;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setIntake(arr);
    let id = e.target.id;
    setSearchIntakeCheck({ ...searchIntakeCheck, [id]: !searchIntakeCheck[id] });
  };


  const handleTypeFilter = (e) => {
    const value = e.target.value;
    let arr = courseType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setCourseType(arr);
    let id = e.target.id;
    setCourseTypeCheck({ ...courseTypeCheck, [id]: !courseTypeCheck[id] });
  };



  const handleIntakeHashFilter = (e) => {
    const value = e.target.value;
    let arr = intakeHash;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setIntakeHash(arr);
    let id = e.target.id;
    setSearchIntakeHashCheck({ ...searchIntakeHashCheck, [id]: !searchIntakeHashCheck[id] });
  };




  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = school;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSchool(arr);
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  };



  function resetFilter() {
    setSearch("");
    setIntake([]);
    setIntakeNumber([]);
    setCourseType([]);
    setCourseTypeCheck({});
    setSearchIntakeYearCheck({});
    setSearchIntakeNumberCheck({});
    setSearchTypeCheck({});
    setIntake([])
    setIntakeHash([]);
    setSearchIntakeHashCheck({});
    setSearchIntakeCheck({});
    setStudyType([])
    setSearchStudyTypeCheck({});
    setSchool([])

  }

  const exportData = (fileType, fileName) => {
    let data = assignQualification;
    const header = ["Name", "Start", "End", "Intake", "Type", "Correspondence"];

    data = data?.map((row) => ({
      ...row,
      Name: row.course,
      "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
      "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
      Intake: row.year ? row.year + "-" + row.number : "-",
      Type: row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).text : "-",
      Correspondence: row.type ? RenderCorrespondence(row.type).text : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row["Start"], row["End"], row["Intake"], row.Type, row.Correspondence];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  async function showCourses(courses) {
    setSelectedCourses(courses);
    $("#viewCourses").modal("show");
  }

  async function closeCourses() {
    setSelectedCourses([]);
    $("#viewCourses").modal("hide");
  }

  function removeCourse(course) {
    assignQualification.map((elem, ind) => {
      if (elem.id === course.id) {
        if (!course.sub_item.length) {
          assignQualification.splice(ind, 1);
          setAssignQualification([...assignQualification]);
        } else {
          // assignQualification.splice(ind, 1);
          let tempArr = assignQualification;
          let selectedObj = tempArr.find((item) => item.id == course.id);
          const index = tempArr.indexOf(selectedObj);
          if (index !== -1) {
            tempArr[index] = course;
          }
          setAssignQualification(tempArr);
        }
        closeCourses();
      }
    });
  }

  function getChangedCourse(newCourse) {
    // console.clear();
    let arr = [...assignQualification];
    let index;
    arr = arr.map((elem, ind) => {
      if (elem.id === newCourse.id) {
        index = ind;
      } else {
        return elem;
      }
    });
    arr[index] = newCourse;
    setAssignQualification([...arr]);
  }

  return (
    <>
      <div className="card card-profile-info-card mb-30 p-a-c">
        <div className="card-body">
          <div className="card-body-inr card-body-info">
            <div className="my-tickets-info-list Tickets-main-wrap">
              <div className="edit-icon new-card-header">
                <div className="card-header">Assigned Intakes</div>
              </div>

              <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="custom-table-div filter-search-icon card card-table-custom filter-table-bg marLeft135 top-5">
                  <div className=" filter-search-bar-blk top-0">
                    <div className="filter-button-group">
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                      <div className="assessment-table-status">
                        <div className="btn-type-Status dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${studyType.length ? "btn-selected" : ""}`}
                              type="button"
                              id="dropdownMenu11"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="Learning Method"
                            >
                              <span>
                                Learning Method <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu11">
                                {studyTypeArr && studyTypeArr.sort((a, b) =>
                                  (a?.label ? a?.label?.trim().toLowerCase() : "") >
                                    (b ? b?.label?.trim().toLowerCase() : "")
                                    ? 1
                                    : -1
                                ).map((item) => (
                                  <li className="item-text-red">
                                    <input type="checkbox" id={"lm" + item.label} value={item.value} onClick={handleStudyTypeFilter} checked={searchStudyTypeCheck["lm" + item.label]} />
                                    <label htmlFor={"lm" + item.label}>
                                      {RenderLearningMethod(item.value).html}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="assessment-table-status">
                        <div className="btn-type-Status dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${intake.length ? "btn-selected" : ""} `}
                              type="button"
                              id="dropdownMenu12"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Intake Year"
                            >
                              <span>
                                Intake Year <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu12">
                                {intakeList &&
                                  intakeList.sort().map((item) => {
                                    return (
                                      <li className="cat" key={item.value}>
                                        <input type="checkbox" id={"intakeYear" + item.value} value={item.value} onClick={handleIntakeFilter} checked={searchIntakeCheck["intakeYear" + item.value]} />
                                        <label htmlFor={"intakeYear" + item.value}>
                                          <span className="cat ">{item.label}</span>
                                        </label>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="assessment-table-status">
                        <div className="btn-type-Status dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${intakeHash.length ? "btn-selected" : ""} `}
                              type="button"
                              id="dropdownMenu13"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Intake #"
                            >
                              <span>
                                Intake # <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu13">
                                {intakeArr &&
                                  intakeArr.map((item, index) => {
                                    return (
                                      <li className="cat" key={item.value}>
                                        <input type="checkbox" id={"intakeNumber" + item.value} value={item.value} onClick={handleIntakeHashFilter} checked={searchIntakeHashCheck["intakeNumber" + item.value]} />
                                        <label htmlFor={"intakeNumber" + item.value}>
                                          <span className="cat ">{item.label}</span>
                                        </label>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="assessment-table-status">
                        <div className="btn-type-Status dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${school.length ? "btn-selected" : ""}`}
                              type="button"
                              id="dropdownMenu14"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="School"
                            >
                              <span>
                                School <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu14">
                                {
                                  schoolFilterArr && schoolFilterArr.sort((a, b) =>
                                    (a?.label ? a?.label?.trim().toLowerCase() : "") >
                                      (b ? b?.label?.trim().toLowerCase() : "")
                                      ? 1
                                      : -1
                                  ).map((item) => (
                                    <li className="item-text-red" title={item.label}>
                                      <input type="checkbox" id={"sf" + item.label} value={item.value} onClick={handleStatusFilter} checked={searchStatusCheck["sf" + item.label]} />
                                      <label htmlFor={"sf" + item.label}>
                                        {RenderSchool(item.value).html}
                                      </label>
                                    </li>
                                  ))
                                }
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="button-reset dropdown-comman">
                        <button className="btn btn-primary" title="Reset" type="button" onClick={resetFilter}>
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      {/* <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Assigned_Programme_And_Course");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Assigned_Programme_And_Course");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Assigned_Programme_And_Course");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div> */}
                    </div>
                  </div>

                  <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_filter" className="dataTables_filter">
                            <label>
                              <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilters(e, "search")} value={search} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.loading === true ? <SkeletonTicketList /> : <DataTable
                    data={dataToRender()}
                    defaultSortField="start"
                    defaultSortAsc={true}
                    columns={columns}
                    pagination={true}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                  />}
                </div>
              </div>
            </div>

            {/* Modal to show table of child courses */}
            <div className="topic-add-modal modal fade" id="viewCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4>Courses</h4>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        closeCourses();
                      }}
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <CoursesTable getValue={getValue} courses={selectedCourses} isRemoveBtn={true} removeCourse={removeCourse} setCourse={getChangedCourse} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Modal to show table of child courses */}
    </>
  );
}

export default AssignedIntake;
