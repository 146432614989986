import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { GetListOfAssemblies, GetListOfAssembliesFilters, DeleteAssemblies, GetListOfAssembliesCancelToken } from "../../../services/CourseService";
import { Color } from "./UI";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { Link } from "react-router-dom";
import $ from "jquery";
import PermissionsGate from "../../../utils/permissionGate";
import Hover from "../../common/Hover";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import { RenderVersion } from "../../../utils/CommonGroupingItem";
import Tablefilter from "../../common/Tablefilter";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";

const Assemblies = ({ versionList }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [version, setVersion] = useState({ arr: [], checkObj: {} });
  const [deleterow, setDeleteRow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortkey, setSortKey] = useState("item_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({version : []});
  const [filterCheck, setFilterCheck] = useState("true")

  useEffect(() => {
    handleTableScroll()
  }, [loading])
  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const data = {
        page: page,
        limit: perPage,
        search: search ? search : "",
        key: sortkey,
        sort: sortOrder,
        version: version.arr.length ? version.arr : [],
        exportStatus : "false",
        dynamicFilter: filterData.version.length ? "false" : filterCheck
      }
  
      try {
        const res = await GetListOfAssembliesCancelToken(data, source.token);
        setData(res?.data?.assemblies_list.data);
        if(filterCheck == "true" && res.data.item_version_filter && res.data.item_version_filter.length){
          let version = res.data.item_version_filter.sort((a,b)=>{
            a=Number(a.name.replaceAll("V",""));
            b=Number(b.name.replaceAll("V",""));
            return a > b ? 1 : -1
          })
          setFilterData({...res.data, version : version})
        }
        setTotalRows(res.data?.assemblies_list?.total);
        setFilterCheck("false")
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [deleterow,page, perPage, search, sortOrder, sortkey, version]);

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <Link class="as-text-blue curser feature-name" to={`/courses/content/assemblies/edit/${row.id}`}>
            <span title={row.name ? row.name : "-"} className="textLimit100">{row.name ? row.name : "-"}</span>
          </Link>
        </div>
      ),
    },
    {
      name: "Created By",
      selector: "created_by",
      sortField: "Firstname",
      sortable: true,
      wrap: true,
      cell: (row) => (row.created_by ? <div className="assigned-title-block-new">
      <div className="assigned-title-itm">
        <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
          {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.Firstname, row.Lastname])}
          <span className={`profile-box-2-status ${row.created_by_activity_status ? row.created_by_activity_status.toLowerCase()=="online" ? "Online" :row.created_by_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
            <i className="fas fa-circle"></i>
          </span>
          <Hover firstName={row.Firstname} lastName={row.Lastname} photo={row.picture_me} email={row.Email} mobile={row.Mobile} status={row.created_by_status} activity_status={row.created_by_activity_status} right={true} />          
        </span>
       
        <p className="as-text-blue curser " >
          {row.Firstname + " " + row.Lastname}
        </p>
       
      </div>
    </div> : "-")
    },
    {
      name: "Created Date",
      selector: "item_date",
      sortable: true,
      sortField:"item_date",
      wrap: true,
      cell: (row) =>
        row.item_date && row.item_date !== "0000-00-00" ? (
          <div className="dateTime">
            <p className="right-space" title={moment(row.item_date).format(TABLE_DATE_FORMAT)}>{moment(row.item_date).format(TABLE_DATE_FORMAT)}</p>
            {/* <p>{moment(row.item_date).format(TABLE_TIME_FORMAT)}</p> */}
          </div>
        ) : (
          "-"
        ),
      // <p className="cat">{`${moment.utc(row.item_date).format(TABLE_DATE_FORMAT)} ${moment.utc(row.item_date).format(TABLE_TIME_FORMAT)}`}</p>
    },
    {
      name: "Version",
      selector: "item_version",
      sortable: true,
      sortField:"item_version",
      wrap: true,

      cell: (row) =>
        row.item_version ? (
          <p className={`cat ${Color(row.item_version)}`}>
            <i className="fas fa-circle mr-1"></i> {row.item_version}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate RenderError={() => (
              <button className="btn btn-primary rounded-circle" title="Open">
                <i className="fal fa-folder-open"></i>
              </button>
            )} scopes={["caedit"]}>
              <Link to={`/courses/content/assemblies/edit/${row.id}`}>
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>

            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" title="More" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-primary rounded-circle" title="Duplicate">
                    <i className="fal fa-copy"></i>
                  </button>
                )} scopes={["caedit"]}>
                  <Link to={`/courses/content/assemblies/duplicate/${row.id}`} className="mr-1">
                    <button className="btn btn-primary rounded-circle" title="Duplicate">
                      <i className="fal fa-copy"></i>
                    </button>
                  </Link>
                </PermissionsGate>
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                )} scopes={["cadelete"]}>

                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ],[]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssemblies({ id: id }).then((res) => setDeleteRow(!deleterow));
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      }
    });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setVersion({ arr: [], checkObj: {} });
    setSearch("");
  };

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["Name", "Created By", "Created Date", "Version"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const data1 = {
      page: page,
      limit: perPage,
      search: search ? search : "",
      key: sortkey,
      sort: sortOrder,
      version: version.arr.length ? version.arr : [],
      exportStatus : "true"
    }
    GetListOfAssemblies(data1).then((res) => {
      data = res.data.assemblies_list
      data = data?.map((row) => ({
        ...row,
        Name: row?.name,
        "Created By" : row?.created_by ? row?.created_by  : "NA",
        "Created Date": `${moment(row.item_date).format(TABLE_DATE_FORMAT)}`,
        Version: row?.item_version,
      }));
  
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  
        const blobURL = window.URL.createObjectURL(blob);
  
        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();
  
        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
          Swal.close();
        }, 1000);
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });
  
        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row.name, row["Created By"], row["Created Date"], row.Version, ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            // valign: "center",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    }).catch((err) => console.log(err));
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  return (
    <>
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">

                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName={"Version"}
                    optionArr={filterData.version}
                    state={version}
                    setState={setVersion}
                    renderLabelFunction={RenderVersion}
                  />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Assemblies"); }} title="Export spreadsheet">
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Assemblies"); }} title="Export CSV">
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Assemblies"); }} title="Export PDF">
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-primary" title="Create New">
                    {/* <button className="btn btn-primary" title="Create New" onClick={() => history.push(`/courses/content/assemblies/add`)}> */}
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}
                  scopes={["caadd"]}
                >
                  <Link class="curser " to={`/courses/content/assemblies/add`}>
                    <button className="btn btn-primary" title="Create New">
                      {/* <button className="btn btn-primary" title="Create New" onClick={() => history.push(`/courses/content/assemblies/add`)}> */}
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          data={data}
          columns={columns}
          pagination={true}
          defaultSortField="item_date"
          onSort={handleSort}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortAsc={true}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />

      </div>
    </>
  );
};

export default Assemblies;
