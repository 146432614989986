import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Tabs from "./Tabs";
import { getCurrentTimeHomePage } from "../common/Helper";
import Assemblies from "./ContentAssembliesBlock/Assemblies";
import ResourcePool from "./ContentAssembliesBlock/ResourcePool";
import AuditTrail from "./ContentAssembliesBlock/AuditTrail";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import AssembliesDetails from "./ContentAssembliesBlock/AssembliesDetails";
import ResourcePoolDetails from "./ContentAssembliesBlock/ResourcePoolDetails";
import SubCategoryDetails from "./ContentAssembliesBlock/SubCategoryDetails";
import ResourcePoolSubCatDetails from "./ContentAssembliesBlock/ResourcePoolSubCatDetails";
import { TrimText } from "../common/TrimText";
import LinkedCourse from "./ContentAssembliesBlock/AssembliesDetailsBlocks/LinkedCourse";
import AssignedResource from "./ContentAssembliesBlock/AssembliesDetailsBlocks/AssignedResource";
import { GetAssemblyResourceDetail, GetDetailOfAssemblies, GetLinkedCourses, GetListOfAssembliesFilters, GetListOfAssignedResources, GetListOfAvailableResources } from "../../services/CourseService";
import ListOfResources from "./ContentAssembliesBlock/ResourcePoolDetailsBlocks/ListOfResources";
import LinkedAssemblies from "./ContentAssembliesBlock/ResourcePoolDetailsBlocks/LinkedAssemblies";
import { useSelector } from "react-redux";
import hasPermission from "../../utils/hasMultiplePermission";
import { GlobalIconNames } from "../../utils/GlobalIconNames";

const ContentAssemblies = () => {

  const { id, tab, type, subType, subTab, subTabtype } = useParams();
  const history = useHistory();
  // const navigate = useNavigate();
  const location = useLocation();
  const selectedTab = location.pathname;
  const [headingName, setHeadingname] = useState();
  const [showTabs, setShowTabs] = useState(true);
  const [fName, setFName] = useState("Content");
  const [sName, setSName] = useState("Content");
  const [assignedData, setAssignedData] = useState();
  const [availableData, setAvailableData] = useState();
  const [linkedCourseData, setLinkedCourseData] = useState();
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [versionList, setVersionList] = useState([]);
  const [filterData, setFilterData] = useState({status : [], intake : [], learning : []})
  const [filterCheck, setFilterCheck] = useState("true")
  

  const givenPermsisions = useSelector((state) => state.givenPermission);


  useEffect(() => {
    let response = hasPermission({
      scopes: ["caview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {

    if (tab === "assemblies" && type === "table" && !versionList.length) {
      GetListOfAssembliesFilters().then((res) => {
        setVersionList(res?.data?.item_version);
      }).catch((err) => console.log(err));
    }

    if (tab !== "assemblies" && id) {
      GetAssemblyResourceDetail({ item_id: id }).then((res) => {
        setSName(res?.data?.resouce_detail?.item_name);
      });
    } else if (id) {
      GetDetailOfAssemblies(id).then((res) => {
        setSName(res?.data?.assemblies_detail?.name);
      }).catch((err) => console.log(err));
    }

    if ((tab === "assemblies" && (type === "add" || type === "duplicate")) || (tab === "resource-pool" && (type === "add" || type === "open" || type === "create" || type === "duplicate"))) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }

    if (id) fetchTableData();
    setTabHeading();
  }, [tab, type, headingName])

  function setTabHeading() {
    // Conditions to set heading's first name accordingly
    if (tab == "assemblies" && type == "table") {
      setFName("Content ");
    } if (tab == "assemblies" && type == "add") {
      setFName("Add ");
    } else if (tab == "assemblies" && type !== "table") {
      setFName("Content Assembly : ");
    } else if (tab == "resource-pool" && (type == "table" || type == "create")) {
      setFName("Resource ");
    } else if (tab == "resource-pool" && type == "add") {
      setFName("Add ");
    } else if (tab == "resource-pool" && type !== "table") {
      setFName("Resource: ");
    } else {
      setFName("Content ");
    }

    // Conditions to set heading's second name accordingly
    if (tab == "resource-pool" && (type == "table" || type == "create")) {
      setSName("Pool");
    } else if (tab == "resource-pool" && type == "add") {
      setSName("Resource Pool");
    } else if (tab == "assemblies" && (type == "table" || type == "create")) {
      setSName("Assemblies");
    } else if (tab == "assemblies" && type == "table") {
      setSName("Assemblies");
    } else if (tab == "assemblies" && type == "add") {
      setSName("Assembly");
    } else if (tab == "resource-pool" && type !== "table") {
      setSName(headingName);
    } else if (tab == "resource-pool" && type == "open" && !subType) {
      setSName(headingName);
    } else if (tab == "assemblies" && type !== "table") {
      setSName(headingName);
    } else {
      setSName("Assemblies");
    }
  }

  function assessmentRoutes() {
    if (tab == "assemblies" && type == "table") return `/courses/content/assemblies/table` ;
    if (tab !== "assemblies") {
      return `/courses/content/assemblies/table`;
    } else {
      return "/courses/content/assemblies/edit/" + id;
    }
  }

  function auditTrailRoutes() {
    if (type === "table") {
      return "/courses/content/audit-trail/table";
    } else {
      return `/courses/content/${tab}/edit/${id}/audit-trail`;
    }
  }

  function checkTabs() {
    if (type === "table") return;
    if (tab === "assemblies") {
      return innerTabsAssemblies();
    } else if (tab === "resource-pool") {
      return innerTabsResourcePool();
    }
  }

  function innerTabsAssemblies() {
    return (
      <>
        <li className="nav-item">
          <Link className={`nav-link ${tab == "assemblies" && type === "assignedResources" ? "active" : ""}`} to={`/courses/content/assemblies/assignedResources/${id}`}>
            <i className="fal fa-tasks"></i>Resources
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${tab == "assemblies" && type === "linkedCourses" ? "active" : ""}`} to={`/courses/content/assemblies/linkedCourses/${id}`}>
            <i className="fal fa-link"></i>Linked Courses
          </Link>
        </li>
      </>
    )
  }

  function innerTabsResourcePool() {
    return (
      <>
        <li className="nav-item">
          <Link className={`nav-link ${tab === "resource-pool" && type === "listOfResources" ? "active" : ""}`} to={`/courses/content/resource-pool/listOfResources/${id}`}>
            <i className="fal fa-list"></i>List Of Resources
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${tab === "resource-pool" && type === "linkedAssemblies" ? "active" : ""}`} to={`/courses/content/resource-pool/linkedAssemblies/${id}`}>
            <i className="fal fa-link"></i>Linked Assemblies
          </Link>
        </li>
      </>
    )
  }

  const fetchTableData = () => {
    if (tab == "assemblies" && type === "linkedCourses") {
      GetListOfAvailableResources({ assembly_id: id }).then((res) => {
        setAvailableData(res?.data?.avl_assemblies_list);
        setIsLoading1(false)
      }).catch((err) => { console.log(err); setIsLoading1(false) });

      GetListOfAssignedResources({ assembly_id: id }).then((res) => {
        setAssignedData(res?.data?.assigned_assemblies_list);
        setIsLoading2(false)
      }).catch((err) => { console.log(err); setIsLoading2(false) });

      GetLinkedCourses({ assembly_id: +id ,dynamicFilter: filterCheck }).then((res) => {
        // console.log("linked courses -=-=-> ", res?.data);
        setLinkedCourseData(res?.data?.linked_course_list);
        setFilterData({...res.data, status : res.data.aessmbliyStatusFiltere, learning : res.data.intakeTypeFilter, intake : res.data.yearsAndIntaksFilter})
        setIsLoading3(false);
        setFilterCheck("false")
      }).catch((err) => { console.log(err); setIsLoading3(false) });
    }
  }

  function tabSectionAssemblies() {
    return (<>
      {tab === "assemblies" && type === "linkedCourses" && <>
        <LinkedCourse data={linkedCourseData} getData={fetchTableData} isLoading3={isLoading3} filterData={filterData}/>
      </>}

      {tab === "assemblies" && type === "assignedResources" && <>
        <div className="card card-profile-info-card">
          <div className="">

            <div className="card-body-inr card-body-info">
              <div className="ass-bel-stuff">

                <AssignedResource data={assignedData} getData={fetchTableData} isLoading2={isLoading2} isLoading3={isLoading3} availableResData={availableData} />
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
    )
  }

  function tabSectionResourcePool() {
    return (
      <div className="card card-profile-info-card">
        <div className="">

          <div className="card-body-inr card-body-info">
            <div className="ass-bel-stuff">
              {tab === "resource-pool" && type === "listOfResources" && <>
                <div className="card-header">List of Resources</div>
                <ListOfResources setHeadingname={setHeadingname} />
              </>}

              {tab === "resource-pool" && type === "linkedAssemblies" && <>
                <div className="card-header">Linked Assemblies</div>
                <LinkedAssemblies />
              </>}

              {/* {tab === "assemblies" && type === "" && <>
              <div className="card-header mt-4">Available Resources</div>
              <AvailableResource data={availableData} getData={fetchTableData} isLoading3={isLoading3} />
            </>} */}

            </div>
          </div>
        </div>
      </div>)
  }

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {fName}
                        <span title={sName ? sName : ""}>
                          {sName ? TrimText(sName, 25) : ""}
                        </span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          {/* <button onClick={() => assessmentRoutes()} title="Back" className="btn btn-white-bordered"> */}
                          {/* <button onClick={() => { history.goBack(); }} title="Back" className="btn btn-white-bordered"> */}
                          {/* <button onClick={() => { navigate(-1) }} title="Back" className="btn btn-white-bordered"> */}
                          <button type="button" onClick={() => { history.go(-1) }} title="Back" className="btn btn-white-bordered">
                          {GlobalIconNames("leftarrow")}Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Content <span>Assemblies</span>
                  </h4> */}
                </div>

                <div className="tabs-wrap">
                  <ul className={"nav nav-pills mb-4 " + (showTabs ? "" : "d-none")} id="tabs" role="tablist">
                    {(tab == "resource-pool" && type == "table") || tab == "assemblies" || tab == "audit-trail" ?
                      <li className="nav-item">
                        <Link className={`nav-link ${tab === "assemblies" && (type === "table" || type === "edit") && subType !== "audit-trail" ? "active" : ""}`} to={assessmentRoutes()}>
                          {((tab=="assemblies" || tab === "audit-trail")&& type === "table") ? <><i className="fal fa-info-circle"></i>Content Assembly</> : GlobalIconNames("details").html }
                        </Link>
                      </li> : null}
                    {tab === "assemblies" && type !== "table" ? checkTabs() : null}
                    {/* {(tab == "assemblies" && type == "table") || tab == "resource-pool" || tab == "audit-trail" ?
                      <li className="nav-item">
                        <Link className={`nav-link ${tab === "resource-pool" && (type === "table" || type === "edit") && subType !== "audit-trail" ? "active" : ""}`} to={resourcePoolRoutes()}>
                          <i className="fal fa-user"></i>Resource Pool
                        </Link>
                      </li> : null} */}
                    {tab === "resource-pool" && type !== "table" ? checkTabs() : null}
                    <li className="nav-item" >
                      <Link className={`nav-link ${(tab === "audit-trail" || subType === "audit-trail") ? "active" : ""}`} to={auditTrailRoutes()}>
                      {GlobalIconNames("auditTrail").html}
                      </Link>
                    </li>
                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    <div className={"tab-pane fade active show"} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {tab == "assemblies" && type == "table" ? <Assemblies versionList={versionList} /> : ""}
                      {tab == "assemblies" && type == "edit" && !subType ? <AssembliesDetails setHeadingname={setHeadingname} /> : ""}
                      {tab == "assemblies" && type == "add" ? <AssembliesDetails setHeadingname={setHeadingname} /> : ""}
                      {tab == "assemblies" && type == "duplicate" ? <AssembliesDetails setHeadingname={setHeadingname} /> : ""}
                      {tab == "assemblies" && ["linkedCourses", "assignedResources"].includes(type) ? tabSectionAssemblies() : ""}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                      {tab == "resource-pool" && type == "table" && !subType && <ResourcePool />}
                      {tab == "resource-pool" && type == "edit" && !subType && <ResourcePoolDetails setHeadingname={setHeadingname} />}
                      {tab == "resource-pool" && type == "add" && !subType && <ResourcePoolDetails setHeadingname={setHeadingname} />}
                      {tab == "resource-pool" && type == "open" && !subType && <ResourcePoolDetails setHeadingname={setHeadingname} />}
                      {tab == "resource-pool" && type == "duplicate" && !subType && <ResourcePoolDetails setHeadingname={setHeadingname} />}
                      {tab == "resource-pool" && type !== "add" && subType == "add" && <SubCategoryDetails />}
                      {tab == "resource-pool" && type !== "add" && subType == "add" && <SubCategoryDetails />}
                      {tab == "resource-pool" && type !== "add" && subType == "open" && <SubCategoryDetails />}
                      {tab == "resource-pool" && type !== "add" && subType == "duplicate" && <SubCategoryDetails />}
                      {tab == "resource-pool" && ["listOfResources", "linkedAssemblies"].includes(type) ? tabSectionResourcePool() : ""}
                      {/* {tab == "resource-pool" && type == "open" && subTab == "sub-category" && subTabtype == "open" && <ResourcePoolSubCatDetails setHeadingname={setHeadingname} />} */}

                    </div>
                    <div className="tab-pane fade active show" id="pills-tab33" role="tabpanel" aria-labelledby="pills-tab3">
                      {(tab == "audit-trail" || ((tab === "resource-pool" || tab === "assemblies") && subType === "audit-trail")) && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentAssemblies;
