import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { GetCampusDropdown, getResourceDetails } from "../../../services/StudentService";
import { FORM_DATE_FORMAT, IMAGE_URL, TABLE_DATE_FORMAT } from "../../../utils/Constants";
import { TrimText } from "../../common/TrimText";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderQuizTopic, RenderResourceAvailablity } from "../../../utils/CommonStatusItems";
import { GetQuizTopics } from "../../../services/SmartAssessmentService";
import { checkIfImageExists } from "../../../utils/commonFunction";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import defaultImage from "../../../assets/images/defaultImage.jpeg"
const ResourceDashBoard = ({ setResourceName }) => {
    const { id } = useParams();
    const history = useHistory();
    const [basicInfo, setBasicInfo] = useState({})
    const [infoData, setInfoData] = useState({})
    const [campus, setCampus] = useState([]);
    const [videoId, setVideoId] = useState("")
    const [modalOpen, setModalOpen] = useState("")

    const [resourceIcon, setResourceIcon] = useState("")
    const [isImageShown, setIsImageShown] = useState(false)
    const [loading, setLoading] = useState(true);
    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sresourcemedit"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    useEffect(() => {
        getResourceData();
        GetCampusDropdown().then(res=>{
            if(res.status===200){
                setCampus(res.data.dropdown)
            }
        }).catch((err)=>console.log(err))
    }, [])

    useEffect(() => {
        let icon = "";
        switch (basicInfo?.type ? basicInfo.type.toLowerCase() : "") {
            // uncomment below cases to get icon for all types of resources
            //   case "link":
            //   case "white_paper":
            //     icon = "fad fa-link";
            //     break;
            //   case "video":
            //   case "vimeo":
            //   case "youtube":
            //     icon = "fad fa-video";
            //     break;
            case "book":
                icon = "fad fa-book";
                break;
            case "ebook":
                icon = "fad fa-wifi";
                break;
            //   case "document":
            //   case "pdf":
            //     icon = "fad fa-file";
            //     break;
            //   case "article":
            //     icon = "fad fa-newspaper";
            //     break;
            //   case "label":
            //     icon = "fad fa-tag";
            //     break;
        }
        setResourceIcon(icon);
        // setFavIcon(basicInfo?.favorite ? "fas fa-star" : "fal fa-star");
    }, [basicInfo]);

    useEffect(() => {
        checkIfImageExists(IMAGE_URL + "/" + basicInfo?.thumbnail).then((res) => {
            if (res) {
                setIsImageShown(true)
            } else {
                setIsImageShown(false)
            }
        }).catch(err => {
            setIsImageShown(false)
        })
    }, [basicInfo])


    const getResourceData = () => {
        setLoading(true);
        getResourceDetails(id)
            .then((res) => {
                GetQuizTopics().then(response => {
                    let topics = response.data.filter(val => {
                        return res.data.resourceDetails && res.data.resourceDetails.topic ? res.data.resourceDetails.topic.split(",").includes(val.id.toString()) : false
                    })
                    setBasicInfo({ ...res.data?.resourceDetails, topic: topics ? topics : [] });
                }).catch(err => {
                    console.log("error: ", err)
                    setBasicInfo(res.data?.resourceDetails);
                })
                setResourceName(res.data?.resourceDetails?.title);
                setInfoData(res.data?.ResourceInformationDetails)
                let link = basicInfo.url + res.data?.resourceDetails?.video_id
                if (!res.data?.resourceDetails?.video_id && res.data?.resourceDetails?.url && res.data?.resourceDetails?.url.includes("youtube")) {
                    const videoId = getId(res.data?.resourceDetails?.url);
                    const link = "https://www.youtube.com/embed/" + videoId
                    playerModelBox(link)
                }
                if (res.data?.resourceDetails?.video_id && Number(res.data?.resourceDetails?.video_id)) {
                    const videoId = res.data?.resourceDetails?.video_id;
                    const link = "https://player.vimeo.com/video/" + videoId
                    playerModelBox(link)
                }
            })
            .catch((err) => { console.log(err); })
            .finally(()=>{setLoading(false)});
    }



    const closeModal = () => {
        setModalOpen(false);
        setVideoId('')
    };

    const playerModelBox = (links) => {
        // setModalOpen(true);
        setVideoId(links)
    }


    function getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    return (
        <>
            <div className="modal-update">
                {(videoId.includes("vimeo") || videoId.includes("youtube")) && <ReactDynamicModal
                    data-backdrop="static"
                    initWidth={640}
                    initHeight={410}
                    onRequestClose={closeModal}
                    isOpen={modalOpen}
                    data={
                        <div className="body">
                            {videoId.includes("youtube") ? <ReactPlayer
                                url={videoId}
                                controls={true}
                                className="react-player"
                                width="100%"
                                height="100%"
                                pip={true}
                                stopOnUnmount={false}
                                youtube="youtube"
                            // vimeo="vimeo"
                            /> : <iframe src={videoId} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>}

                            {/* ?h=52a09fb4d7&color=d5d5d5&title=0&byline=0&portrait=0 */}
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/FkEhBfgD6nw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        </div>
                    }
                />}
            </div>
            
            {loading ? <SkeletonTicketList />
            :<div className="ultimate-guide-main card mb-30">
                <figure className="ultimate-guide-L">
                        {basicInfo?.thumbnail ?
                            <div className="ultimate-guide-img">                    
                                <a target="_blank" href={(basicInfo?.thumbnail.includes("http") ? "" : (IMAGE_URL + "/")) + basicInfo?.thumbnail?.replace("public/", "")} className="">
                                    <LazyLoadImage
                                        alt="Image Not Available"
                                        effect="blur"
                                        height={"100%"}
                                        placeholderSrc={defaultImage}
                                        src={(basicInfo?.thumbnail.includes("http") ? "" : (IMAGE_URL + "/")) + basicInfo?.thumbnail?.replace("public/", "").replace("home/myaie/public_html/", "")} 
                                    />
                                </a>
                            </div>
                            : <div className="advanced_serach-img-box-2">
                                <span className="serch-icon-upper">
                                    <i className={resourceIcon}></i>
                                </span>
                            </div>}
                    {basicInfo && basicInfo.type==="ebook" &&
                    <button onClick={()=>{window.open(basicInfo?.url, "_blank")}} className="btn w-100 btn-primary mt-2" title="View Resource">View Resource</button>}
                </figure>
                <figcaption className="ultimate-guide-R">
                    <div className="ultimate-guide-top d-flex ">
                        {basicInfo && basicInfo.type && <span className="as-widget-resource-bg bg-blue"> {basicInfo.type==="ebook" ? <i className="fal fa-wifi"></i> : <i className="fal fa-book"></i>} {basicInfo.type==="ebook" ? "E-Book" : "book"}</span>}
                        {basicInfo && basicInfo.type==="Book" && <div className="btn-group-box">
                            {(basicInfo.availability == "issued") && <Link to={`/support/student-hub-library/resource-management/edit/${id}/missing`}>
                                {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["shadd"]}> */}
                                <button className="btn btn-primary ml-2" title="Mark As Missing">
                                    ! Mark As Missing
                                </button>
                            </Link>}
                            {(basicInfo.availability == "missing") && <Link to={`/support/student-hub-library/resource-management/edit/${id}/found`}>
                                {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["shadd"]}> */}
                                <button className="btn btn-primary ml-2" title="Mark As Found">
                                    ! Mark As Found
                                </button>
                            </Link>}
                            {(basicInfo.availability == "is_available" || basicInfo.availability == "reserved" || basicInfo.availability == "returned") && <Link to={`/support/student-hub-library/resource-management/edit/${id}/issue`}>
                                <button className="btn btn-primary ml-2" title="Issue">
                                    <i className="fal fa-arrow-circle-up"></i>Issue
                                </button>
                            </Link>}
                            {basicInfo.availability == "issued" && <Link to={`/support/student-hub-library/resource-management/edit/${id}/return`}>
                                <button className="btn btn-primary ml-2" title="Return">
                                    <i className="fal fa-arrow-circle-up"></i>Return
                                </button>
                            </Link>}
                            {basicInfo.availability == "is_available" && <Link to={`/support/student-hub-library/resource-management/edit/${id}/reserve`}>
                                <button className="btn btn-primary ml-2" title="Reserve">
                                    <i className="fal fa-book-heart"></i>Reserve
                                </button>
                            </Link>}
                            {/* </PermissionsGate> */}
                        </div>}
                    </div>
                    <div className="ultimate-guide-body ">
                        {basicInfo && basicInfo.title && <h3 className="ultimate-guide-body-title mb-4">{basicInfo.title ? basicInfo.title : "-"}
                            {/* <span className="as-widget-resource-bg bg-red ml-3 mt-2">  Business</span> */}
                            {basicInfo.topic && basicInfo.topic.length ?
                                <div className="d-flex flex-wrap">
                                   {basicInfo.topic.map((item, index) => <React.Fragment key={index}>{RenderQuizTopic(item.options, item.color).html}</React.Fragment>)}
                                </div>
                                :""
                            }
                        </h3>}
                        {basicInfo && basicInfo.sub_title && <h4 className="ultimate-guide-body--sub-title">{basicInfo.sub_title ? TrimText(basicInfo.sub_title) : "-"}</h4>}
                        <ul className="ultimate-guide-info mb-4">
                            {basicInfo && basicInfo.author && <li> <i className="fal fa-user"></i>{basicInfo.author ? basicInfo.author : "-"}</li>}
                            {basicInfo && basicInfo.language && <li><i className="fal fa-globe"></i> {basicInfo.language ? basicInfo.language : "-"}</li>}
                        </ul>
                        {basicInfo && basicInfo.description && <div className="ultimate-guide-des">
                            <h4>Description</h4>
                            <p>{basicInfo.description ? basicInfo.description : "-"}</p>
                        </div>}
                    </div>
                </figcaption>
            </div>}

            {/* Additional Information Block */}
            {loading ? <SkeletonTicketList />
            :<div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="">
                    <div className="d-flex mb-3 ">
                        <div className="card-header">Additional Information</div>
                    </div>
                    <div className="card-body-inr card-body-info">
                        <div className="basic-info-box">
                            <div className="row ">
                                {basicInfo && basicInfo.publisher &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Publisher :</span> {" "}<span>{basicInfo && basicInfo.publisher ? " " + basicInfo.publisher : "-"}</span>
                                            </li>                                           
                                        </ul>
                                    </div>
                                </div>}
                                {basicInfo && (basicInfo.type == "Book" || basicInfo.type == "book") && (basicInfo.campus && campus.filter(val => val.value==basicInfo.campus).length) &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box space-box dash-box-2">
                                        <ul>
                                            <li>
                                                <span className="title">Campus :</span> {" "}
                                                <span>{(basicInfo.campus && campus.filter(val => val.value==basicInfo.campus).length) ? campus.filter(val => val.value==basicInfo.campus)[0].label : "-"}</span>
                                            </li>                                                
                                        </ul>
                                    </div>
                                </div>}
                                {basicInfo && basicInfo.barcode &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box space-box dash-box-2">
                                        <ul>
                                            <li>
                                                <span className="title">Barcode :</span> {" "}
                                                <span>{basicInfo && basicInfo.barcode ? basicInfo.barcode : "-"}</span>
                                            </li>                                            
                                        </ul>
                                    </div>
                                </div>}
                                {infoData && infoData.issued_date &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>                                            
                                           {basicInfo && (basicInfo.type === "Book" || basicInfo.type === "book") && <li>
                                                <span className="title">Issue Date :</span> {" "}
                                                <span>{infoData && infoData.issued_date ? moment.unix(+infoData.issued_date).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>}                                            
                                        </ul>
                                    </div>
                                </div>}
                                {basicInfo && (basicInfo.type == "Book" || basicInfo.type == "book") && basicInfo.availability  &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>                                                
                                            <li>
                                                <span className="title">Availability :</span> {" "}
                                                <span title={RenderResourceAvailablity(basicInfo.availability).text}>{basicInfo.availability ? RenderResourceAvailablity(basicInfo.availability).html : "-"}</span>
                                            </li>                                                
                                        </ul>
                                    </div>
                                </div>}
                                {basicInfo && (basicInfo.type === "Book" || basicInfo.type === "book" ) && basicInfo.dewey &&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Dewey Nr :</span> {" "}
                                                <span>{basicInfo.dewey ? basicInfo.dewey : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>}
                                {basicInfo && basicInfo.isbn ?
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>                                           
                                            <li>
                                                <span className="title">ISBN :</span> {" "}
                                                <span>{basicInfo && basicInfo.isbn ? basicInfo.isbn : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div> :<></>}
                                {basicInfo && (basicInfo.type == "Book" || basicInfo.type == "book" || basicInfo.type === "ebook") && basicInfo.series &&
                                <>                                                                        
                                    <div className="col-md-3 col-lg-4">
                                        <div className="basic-info-box-list space-box dash-box-2 space-box">
                                            <ul>                                                
                                                <li>
                                                    <span className="title">Series :</span> {" "}
                                                    <span>{basicInfo.series ? basicInfo.series : "-"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>}                                
                                {basicInfo && (basicInfo.type === "Book" || basicInfo.type === "book" ) && infoData && infoData.due_date&&
                                <div className="col-md-3 col-lg-4">
                                    <div className="basic-info-box-list space-box space-box dash-box-2">
                                        <ul>                                                                                                                                       
                                            <li>
                                                <span className="title">Return Date :</span> {" "}
                                                <span>{infoData && infoData.due_date ? moment.unix(infoData.due_date).format(FORM_DATE_FORMAT) : "-"}</span>
                                            </li>                                        
                                        </ul>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {infoData?.comment && <div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="">
                    <div className="d-flex mb-3 ">
                        <div className="card-header">Condition Details</div>
                    </div>
                    <div className="card-body-inr card-body-info">
                        <div className="basic-info-box">
                            <div className="row ">
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box dash-box-2">
                                        <ul>
                                            <li>
                                                <span className="title">Issued To:</span>{" "}
                                                <span>{infoData.issuedToname ? infoData.issuedToname : "-"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Comments :</span> {" "}
                                                <span>{infoData.comment ? infoData.comment : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-2">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Date Issued :</span> {" "}
                                                <span>{infoData.issued_date ? moment.unix(Number(infoData.issued_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-2">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Due Date :</span> {" "}
                                                <span>{infoData.due_date ? moment.unix(Number(infoData.due_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-2">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Days Overdue :</span> {" "}
                                                <span>{basicInfo.due_days ? basicInfo.due_days : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Issued By :</span> {" "}
                                                <span>{infoData.issuedByname ? infoData.issuedByname : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {basicInfo?.availability == "reserved" && <div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="">
                    <div className="d-flex mb-3 ">
                        <div className="card-header">Reserve Details</div>
                    </div>
                    <div className="card-body-inr card-body-info">
                        <div className="basic-info-box">
                            <div className="row ">
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box dash-box-2 space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Reserved For :</span> {" "}<span>{infoData.reservedToname ? " " + infoData.reservedToname : "-"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Comments :</span> {" "}
                                                <span>{infoData.comment ? infoData.comment : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Date reserved :</span> {" "}
                                                <span>{infoData.reserved_date ? moment.unix(Number(infoData.reserved_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Due Date :</span> {" "}
                                                <span>{infoData.reserved_due_date ? moment.unix(Number(infoData.reserved_due_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Approved By :</span> {" "}
                                                <span>{infoData.issuedByname ? infoData.issuedByname : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {basicInfo?.availability == "issued" && <div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="">
                    <div className="d-flex mb-3 ">
                        <div className="card-header">Issued Details</div>

                    </div>




                    <div className="card-body-inr card-body-info">
                        <div className="basic-info-box">
                            <div className="row ">
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box dash-box-2">
                                        <ul>
                                            <li>
                                                <span className="title">Issued To:</span>{" "}
                                                <span>{infoData.issuedToname ? infoData.issuedToname : "-"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Comments :</span> {" "}
                                                <span>{infoData.comment ? infoData.comment : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Date Issued :</span> {" "}
                                                <span>{infoData.issued_date ? moment.unix(Number(infoData.issued_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Due Date :</span> {" "}
                                                <span>{infoData.due_date ? moment.unix(Number(infoData.due_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Approved By :</span> {" "}
                                                <span>{infoData.issuedByname ? infoData.issuedByname : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}


            {basicInfo?.availability == "missing" && <div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="">
                    <div className="d-flex mb-3 ">
                        <div className="card-header">Marked as Lost Details</div>

                    </div>




                    <div className="card-body-inr card-body-info">
                        <div className="basic-info-box">
                            <div className="row ">
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box dash-box-2">
                                        <ul>
                                            <li>
                                                <span className="title">Lost By: </span>{" "}
                                                <span>{infoData.missingByname ? infoData.missingByname : "-"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Comments : </span>{" "}
                                                <span>{infoData.comment ? infoData.comment : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Date Marked As Lost : </span>{" "}
                                                <span>{infoData.reported_date ? moment.unix(Number(infoData.reported_date)).format(TABLE_DATE_FORMAT) : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <div className="basic-info-box-list space-box">
                                        <ul>
                                            <li>
                                                <span className="title">Marked By : </span>{" "}
                                                <span>{infoData.markedByname ? infoData.markedByname : "-"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    );
};

export default ResourceDashBoard;
