import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { GetClassSettings, UpdateClassSettings, OnlineClassChecker, UpdateClassInfo } from "../../../services/ClassMgtService";
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { FORM_TIME_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";

const OnlineClass = () => {
  const history = useHistory();
  const [dataArr, setDataArr] = useState([
    {
      'total_entries': "",
      "offset": ""
    }
  ])
  const [loaderPosition, setLoaderPosition] = useState(0);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    getClassSettings()
    classChecker()
  }, []);

  const getClassSettings = () => {
    GetClassSettings()
      .then((res) => {
        if (res?.status == 200) {
          let parseData = JSON.parse(res.data.res.setting_info)
          let stTime = parseData.start_time.split(':')
          let stMin = stTime[1].split(' ')
          let stHour = stMin[1] == 'PM' ? Number(stTime[0]) + 12 : stTime[0]
          let tempStartTime = new Date().setHours(stHour, stMin[0])

          let endTime = parseData.end_time.split(':')
          let endMin = endTime[1].split(' ')
          let endHour = endMin[1] == 'PM' ? Number(endTime[0]) + 12 : endTime[0]
          let tempEndTime = new Date().setHours(endHour, endMin[0])
          formik.setFieldValue('startDate', new Date(tempStartTime))
          formik.setFieldValue('endDate', new Date(tempEndTime))
        }
      })
      .catch((err) => console.log(err));
  }

  const classChecker = () => {
    OnlineClassChecker()
      .then((res) => {
        setDataArr([res.data.res])
        setloading(false);
      })
      .catch((err) => console.log(err));
  }

  const columns = useMemo(() => [
    {
      name: "Total Current Entries",
      cell: (row) => { return <>{row.total_entries ? row.total_entries : "Loading..."}</> },
    },

    {
      name: "Current Offset",
      cell: (row) => { return <>{row.offset ? row.offset : "Loading..."}</> },
    },
    {
      name: "Class Update Link",
      cell: (row) => (
        <div className="assessment-08">
          <div className="assessment-08 monitor-color">
          {/* <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save">
                  {loaderPosition === 1 && <><i className="fas fa-cog fa-spin"></i>Save</>}
                  {loaderPosition !== 1 && <><i className="fal fa-save"></i>
                    Save</>}
                </button>
              </div> */}
            <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" title="Update" onClick={() => { updateLink() }} disabled={loaderPosition === 2 ? true : false} > {loaderPosition === 2 && <i className="fas fa-cog fa-spin"></i>}Update</button>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  function updateLink() {
    setLoaderPosition(2);
    UpdateClassInfo().then(response => {
      // console.log("response ---> ", response);
      setLoaderPosition(0);
      getClassSettings()
      classChecker()
      Swal.fire("Updated!", response.data.message, "success");
    }).catch(error => {
      setLoaderPosition(0);
      console.log("error -=-=-> ", error);
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: '',
      endDate: '',
    },
    // validationSchema: Yup.object({
    //   hours: Yup.number().required('hours field required').integer().min(-24, "Min -24 hours can be adjusted").max(24, "Max 24 hours can be adjusted")
    // }),
    onSubmit: (values, { resetForm }) => {
      let stTime = moment(new Date(values.startDate)).format(FORM_TIME_FORMAT).toUpperCase()
      let endTime = moment(new Date(values.endDate)).format(FORM_TIME_FORMAT).toUpperCase()
      const body = {
        start_time: stTime,
        end_time: endTime,
      };

      // console.log("values", body);
      setLoaderPosition(1);
      UpdateClassSettings(body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setLoaderPosition(0);
          // resetForm();
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops!!!",
            text: "Something went wrong...",
          }).then((result) => { setLoaderPosition(0); });
        });
    },
  });

  return (
    <>
      <div className="card card-profile-info-card online-class-sec">
        <div className="card-header pt-0">Default Setting</div>
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="form-group">
                    <label>Start Time</label>
                    <DatePicker
                      selected={formik.values.startDate}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat={FORM_TIME_FORMAT}
                      timeFormat={FORM_TIME_FORMAT}
                      className={"form-control" + (formik.errors.startDate && formik.touched.startDate ? " is-invalid" : "")}
                      name="startDate"
                      onChange={date => formik.setFieldValue('startDate', date)}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="form-group">
                    <label>End Time</label>
                    <DatePicker
                      selected={formik.values.endDate}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat={FORM_TIME_FORMAT}
                      timeFormat={FORM_TIME_FORMAT}
                      className={"form-control" + (formik.errors.endDate && formik.touched.endDate ? " is-invalid" : "")}
                      name="endDate"
                      onChange={date => formik.setFieldValue('endDate', date)}
                      onBlur={formik.handleBlur}
                      minTime={new Date(
                        formik.values.startDate
                      ).setTime(
                        new Date(formik.values.startDate).getTime() +
                        15 * 60000
                      )}
                      maxTime={new Date().setHours(23, 59, 0, 0)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save">
                  {loaderPosition === 1 && <><i className="fas fa-cog fa-spin"></i>Save</>}
                  {loaderPosition !== 1 && <><i className="fal fa-save"></i>
                    Save</>}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="card-header pb-0">Class Information</div>
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="custom-table-div filter-search-icon card-table-custom">
            {loading ? <SkeletonTicketList />
              : 
              <>
                <DataTable
                  data={dataArr}
                  columns={columns}
                  noDataComponent={Str.noRecord}
                  paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineClass;
