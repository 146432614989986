import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "react-data-table-component";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { RegStudentColorComb } from "../../../systemadministration/usermanagement/CheckRole";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";

function ERPList(props) {
  const [studentData, setStudentData] = useState();
  const [erpData, setErpData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleTableScroll()
  }, [props])

  useEffect(() => {
    setStudentData(props.studentData);
    let temp =
      props && props.studentData && props.studentData.crm_data
        ? JSON.parse(props.studentData.crm_data)
        : {};
    // console.log("erp -=-=-> ", temp);
    setErpData(
      temp.status &&
        temp.results &&
        temp.results.opportunity &&
        temp.results.opportunity.length
        ? temp.results.opportunity
        : []
    );
    // setLoading(temp.status && temp.results && temp.results.opportunity ? false : true);
  }, [props]);

  const resetFilter = () => {
    setSearch("");
  };
  const columns = useMemo(() => [
    {
      name: "Full Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <p className="as-text-blue">{row.name ? row.name : "-"}</p>
      ),
    },
    {
      name: "Date",
      selector: "date_entered",
      sortable: true,
      cell: (row) => (
        <>
          {row.date_entered ? (
            <div className="dateTime">
              <p className="right-space">
                {moment(row.date_entered).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment(row.date_entered).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => (
        <>
          {row.current_status ? RegStudentColorComb(row.current_status.toLowerCase()) : "-"}
        </>
      ),
    },
    {
      name: "Assigned To",
      selector: "assingnee",
      sortable: true,
      cell: (row) => (
        <p className="as-text-blue">{row.assingnee ? row.assingnee : "-"}</p>
      ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link
              to={`/studentadministration/registration/open/registration?studentId=${row.uid}`}
            >
              <button title="Open" className="btn btn-primary rounded-circle">
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  function dataToRender() {
    let updatedData = [];
    let allData = erpData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes =
      //     item.id && item.id.toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempName = allData.filter((item) => {
        let includes = item.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempName
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    return updatedData;
  }

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["ID", "Full Name", "Date", "Type", "Assigned To", "Status"];

    data = data?.map((row) => ({
      ...row,
      ID: row?.id ? row?.id : "-",
      "Full Name": row.name,
      Date: row.created_date
        ? moment.unix(row.date_entered).format(TABLE_DATE_FORMAT) +
        " " +
        moment.unix(row.date_entered).format(TABLE_TIME_FORMAT)
        : "-",
      Type: row.type ? row.type : "-",
      "Assigned To": row.assingnee,
      Status: row.status ? row.status : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.id,
          row.name,
          row.date_entered,
          row.type,
          row.assingnee,
          row.status,
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="edit-icon new-card-header">
          <div className="card-header">CRM Data</div>
        </div>
      <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={(e) => handleFilters(e, "search")}
                            value={search}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>

                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button
                            className="btn btn-primary"
                            title="Reset"
                            type="button"
                            onClick={resetFilter}
                          >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "ERP_Data");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "ERP_Data");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "ERP_Data");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
              </div>
          </div>
      </div>

        {props.loading ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="Firstname"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>

      <hr />
    </div>
  );
}

export default ERPList;
