import { useFormik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getNationlityList } from "../../../../services/RegistrationService";
import RenderPreviousValues from "./RenderPreviousValues";

function Address(props) {

  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);
  const [studentData, setStudentData] = useState();
  const [dropdownValues, setDropdownValues] = useState(useSelector((state) => state.registerDropdownValues));

  const fixDataFormet = (obj) => {
    let tempArr = [];
    obj.map((item) => {
      tempArr.push({ value: item.crm_country_slug ? item.crm_country_slug : item.country_code, label: item.country });
    });
    return tempArr;
  };
  useEffect(() => {
    setStudentData(props.studentData);
    setProvince(dropdownValues.province)
  }, [props]);

  useEffect(() => {
    getNationlityList().then((res) => {
      setCountry(fixDataFormet(res.data.data));
    });
  }, [])

  const handleSameAsHome = async () => {
    await formik.setFieldValue("PostalStreetAddress", formik.values.StreetAddress);
    await formik.setFieldValue("PostalCity", formik.values.City);
    await formik.setFieldValue("PostalProvince", formik.values.Province);
    await formik.setFieldValue("PostalCountry", formik.values.Country);
    await formik.setFieldValue("PostalCodePostal", formik.values.PostalCode);
  };

  const formik = useFormikContext();

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Address</div>
      </div>
      <div className="edit-icon new-card-header">
        <div className="card-subheading">Home</div>
      </div>
      {/* <form onSubmit={formik.handleSubmit}></form> */}
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StreetAddress">Street Address </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="StreetAddress"
                className={"form-control" + (formik.errors.StreetAddress && formik.touched.StreetAddress ? " is-invalid" : "")}
                name="StreetAddress"
                placeholder="Enter Street Address"
                value={formik.values.StreetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
              {formik.errors.StreetAddress && formik.touched.StreetAddress ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_street} previousValue={studentData?.RegStudentsPreviousDetails?.ph_street} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="City">City </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="City"
                className={"form-control" + (formik.errors.City && formik.touched.City ? " is-invalid" : "")}
                name="City"
                placeholder="Enter City"
                value={formik.values.City}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="City"
              />
              {formik.errors.City && formik.touched.City ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_city} previousValue={studentData?.RegStudentsPreviousDetails?.ph_city} typeSupportingProps={{
              from: "city"
            }} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Province">Province </label>
            <div className="form-icon-group mb-4" title="Province">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Province && formik.touched.Province ? " is-invalid" : "")}
                name="Province"
                value={province.filter((item) => {
                  return item.value == formik.values.Province;
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("Province", value.value);
                  } else {
                    formik.setFieldValue("Province", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={province}
                maxMenuHeight={175}
                placeholder={formik.values.Province ? formik.values.Province : "Select"}
                isClearable
              />
              {formik.errors.Province && formik.touched.Province ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_province} previousValue={studentData?.RegStudentsPreviousDetails?.ph_province} type="dropdown" typeSupportingProps={{filterArray : province}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Country">Country </label>
            <div className="form-icon-group mb-4" title="Country">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Country && formik.touched.Country ? " is-invalid" : "")}
                name="Country"
                value={country.filter((item) => {
                  return item.value == formik.values.Country;
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("Country", value.value);
                  } else {
                    formik.setFieldValue("Country", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={country}
                maxMenuHeight={175}
                placeholder={formik.values.Country ? formik.values.Country : "Select"}
                isClearable
              />
              {formik.errors.Country && formik.touched.Country ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_country} previousValue={studentData?.RegStudentsPreviousDetails?.ph_country} type="dropdown" typeSupportingProps={{filterArray : country}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCode">Postal Code </label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="PostalCode"
                className={"form-control" + (formik.errors.PostalCode && formik.touched.PostalCode ? " is-invalid" : "")}
                name="PostalCode"
                placeholder="Enter Postal Code"
                value={formik.values.PostalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Postal Code"
              />
              {formik.errors.PostalCode && formik.touched.PostalCode ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_zip} previousValue={studentData?.RegStudentsPreviousDetails?.ph_zip} />
          </div>
        </div>
        <div className="edit-icon new-card-header d-flex">
          <div className="card-subheading">Postal Address</div>
        </div>

          <div className="row">
            <div className="col-md-4">
            <button className="btn btn-save btn-success ml-auto mb-4" type="button" title="Same As Home" onClick={() => handleSameAsHome()}>
              <i className="fal fa-copy"></i>
              Same As Home
            </button>
          </div>
        </div>
        
        {/* <div className="row">
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <div className="form-group form-group-save-cancel">

              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalStreetAddress">Street Address </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PostalStreetAddress"
                className={"form-control" + (formik.errors.PostalStreetAddress && formik.touched.PostalStreetAddress ? " is-invalid" : "")}
                name="PostalStreetAddress"
                placeholder="Enter Street Address"
                value={formik.values.PostalStreetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
              {formik.errors.PostalStreetAddress && formik.touched.PostalStreetAddress ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_street_postal} previousValue={studentData?.RegStudentsPreviousDetails?.ph_street_postal} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCity">City </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PostalCity"
                className={"form-control" + (formik.errors.PostalCity && formik.touched.PostalCity ? " is-invalid" : "")}
                name="PostalCity"
                placeholder="Enter Postal City"
                value={formik.values.PostalCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="City"
              />
              {formik.errors.PostalCity && formik.touched.PostalCity ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_city_postal} previousValue={studentData?.RegStudentsPreviousDetails?.ph_city_postal} typeSupportingProps={{from: "phCity"}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalProvince">Province </label>
            <div className="form-icon-group mb-4" title="Province">
              <Select
                className={"form-control custom-select-box " + (formik.errors.PostalProvince && formik.touched.PostalProvince ? " is-invalid" : "")}
                name="PostalProvince"
                value={province.filter((item) => {
                  return item.value == formik.values.PostalProvince;
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("PostalProvince", value.value);
                  } else {
                    formik.setFieldValue("PostalProvince", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={province}
                maxMenuHeight={175}
                placeholder={formik.values.PostalProvince ? formik.values.PostalProvince : "Select"}
                isClearable
              />
              {formik.errors.PostalProvince && formik.touched.PostalProvince ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_province_postal} previousValue={studentData?.RegStudentsPreviousDetails?.ph_province_postal} type="dropdown" typeSupportingProps={{filterArray : province}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCountry">Country </label>
            <div className="form-icon-group mb-4" title='Country'>
              <Select
                className={"form-control custom-select-box " + (formik.errors.PostalCountry && formik.touched.PostalCountry ? " is-invalid" : "")}
                name="PostalCountry"
                value={country.filter((item) => {
                  return item.value == formik.values.PostalCountry;
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("PostalCountry", value.value);
                  } else {
                    formik.setFieldValue("PostalCountry", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={country}
                maxMenuHeight={175}
                placeholder={formik.values.PostalCountry ? formik.values.PostalCountry : "Select Country"}
                isClearable
              />
              {formik.errors.PostalCountry && formik.touched.PostalCountry ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_country_postal} previousValue={studentData?.RegStudentsPreviousDetails?.ph_country_postal} type="dropdown" typeSupportingProps={{filterArray : country}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCodePostal">Postal Code </label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="PostalCodePostal"
                className={"form-control" + (formik.errors.PostalCodePostal && formik.touched.PostalCodePostal ? " is-invalid" : "")}
                name="PostalCodePostal"
                placeholder="Enter Postal Code"
                value={formik.values.PostalCodePostal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Postal Code"
              />
              {formik.errors.PostalCodePostal && formik.touched.PostalCodePostal ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.ph_zip_postal} previousValue={studentData?.RegStudentsPreviousDetails?.ph_zip_postal} />
          </div>
        </div>
      <hr />
    </div>
  );
}

export default Address;
