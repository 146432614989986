import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
    GetAllDropdownValues,
    AddOrUpdateReregistration,
    reRegistrationDetails,
} from "../../../services/RegistrationService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
    FORM_DATE_FORMAT,
    FORM_DATE_TIME_FORMAT,
    FORM_TIME_FORMAT,
    TABLE_ROWS_PER_PAGE,
    TABLE_DATE_FORMAT,
} from "../../../utils/Constants";
import { useHistory } from "react-router-dom";
import {
    AddOrUpdateArticulationReregistration,
    articulationDetails,
} from "../../../services/programArticulationService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Tablefilter from "../../common/Tablefilter";
import {
    RenderLearningMethod,
    RenderProgrammeType,
    RenderSchool,
} from "../../../utils/CommonGroupingItem";
import {
    GetProgramsList,
    GetProgramsListFilter,
} from "../../../services/ProgrammeServices";
import { copyProcess } from "../../../services/copyIntakeServices";
import $ from 'jquery'

var school = [];

const OpenCopiedIntake = () => {
    const { page, tab, id } = useParams();
    const history = useHistory();
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [registrationDetail, setRegistrationDetail] = useState("");
    const [schoolType, setSchoolType] = useState([]);
    const [intakeArr, setIntakeArr] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [articulationStatus, setArticulationStatus] = useState(false);
    const [studyTypeDropdown, setStudyTypeDropdown] = useState([]);
    const [datesValues, setDatesValues] = useState({
        start_date: new Date(),
        end_date: new Date(),
        early_bird_end_date: new Date(),
    });
    const [learningMethod, setLearningMethod] = useState({
        arr: [],
        checkObj: {},
    });
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [type, setType] = useState({ arr: [], checkObj: {} });
    const [school, setSchool] = useState({ arr: [], checkObj: {} });
    const [search, setSearch] = useState("");
    const [search1, setSearch1] = useState("");
    const [filterData, setFilterData] = useState({
        learning: [],
        intake: [],
        type: [],
        school: [],
    });
    const [loading, setloading] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(1);
    const [key, setKey] = useState("startDate");
    const [sort, setSort] = useState("DESC");
    const [totalRows, setTotalRows] = useState(0);
    const [programmeList, setProgrammeList] = useState([]);
    const [deleterow, setDeleteRow] = useState(false);
    const [assignedProgrammeList, setAssignedProgrammeList] = useState([]);
    const [assignIntake, setAssignIntake] = useState({ arr: [], checkObj: {} });
    const [assignSchool, setAssignSchool] = useState({ arr: [], checkObj: {} });
    const [assignLearningMethod, setAssignLearningMethod] = useState({
        arr: [],
        checkObj: {},
    });
    const [schoolArr, setSchoolArr] = useState([]);
    const [learningMethodArr, setLearningMethodArr] = useState([]);
    const [checkedAllRows, setCheckedAllRows] = useState([]);
    const [isAllSelectChecked, setIsAllSelectChecked] = useState(false);
    const [tempCheckedAllData, setTempCheckedAllData] = useState([]);
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [addNotifications, setAddNotifications] = useState([]);
    
     useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["intakeCopyAdd"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    const addRemoveRow = (row, type) => {
        let assigntemp = [...assignedProgrammeList];
        let availtemp = [...programmeList];

        if (type === "add") {
            const exists = assigntemp.some((data) => data.id === row.id);
            if (!exists) {
                assigntemp.push(row);
            }
        } else if (type === "delete") {
            assigntemp = assigntemp.filter((data) => data.id !== row.id);
        }

        setAssignedProgrammeList([...assigntemp]);
        setProgrammeList([...availtemp]);
    };
    

    const modalFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            start_date: "",
            end_date: "",
            q_start_date: "",
            q_end_date: "",
            isCopySubject: "",
            isViewOnly: "",
            isCopyQualifications: "",
            isCopyAssembly: "",
            isCopyAssessment: "",
            SendWhen: "",
            SendWhenDate: "",
            from_intake: "",
            to_intake: "",
            type_src: "",
            school_type: ""
        },
        validationSchema: Yup.object({
            from_intake: Yup.string().required("From Intake is required"),
            to_intake: Yup.string().required("To Intake is required"),
            start_date: Yup.string()
              .when("isCopySubject", {
                is: true,
                then: Yup.string().required("Start Date is required"),
              }),
            end_date: Yup.string()
              .when("isCopySubject", {
                is: true,
                then: Yup.string().required("End Date is required"),
              }),
            SendWhen: Yup.string().required("Send When is required"),
            SendWhen: Yup.number(),
            SendWhenDate: Yup.string()
                .when("SendWhen", {
                    is: 1,
                    then: Yup.string().required("Publish Date And Time is required"),
                }),
            q_start_date: Yup.string()
              .when("isCopyQualifications", {
                is: true,
                then: Yup.string().required("Programme Start Date is required"),
              }),
            q_end_date: Yup.string()
              .when("isCopyQualifications", {
                is: true,
                then: Yup.string().required("Programme End Date is required"),
              }), 
        }),
        onSubmit: (values) => {
            setLoading(true);
            setDisabled(true);
            let addNotificationsIds = []
            let tempData = addNotifications
            tempData.map((item) => {
                addNotificationsIds.push({ id: item?.id })
            })
            let formData = new FormData();
            formData.append("from_number_src", values.from_intake.split("/")[1]);
            formData.append("from_year_src", values.from_intake.split("/")[0]);
            formData.append("to_number_src", values.to_intake.split("/")[1]);
            formData.append("to_year_src", values.to_intake.split("/")[0]);
            formData.append("qualification_box", addNotifications?.length ? "Included Qualifications" : "");
            formData.append("start_date", values?.start_date ? moment(values?.start_date).format("YYYY-MM-DD") : "");
            formData.append("end_date", values?.end_date ? moment(values?.end_date).format("YYYY-MM-DD") : "");
            formData.append("publish", values.SendWhen == 0 ? 0 : 1);
            formData.append("publish_date", values?.SendWhenDate ? moment(values?.SendWhenDate).format("YYYY-MM-DD") : "");
            formData.append("publish_time", values?.SendWhenDate ? moment(values?.SendWhenDate).format("HH:mm") : "");
            formData.append("q_start_date", values?.q_start_date ? moment(values?.q_start_date).format("YYYY-MM-DD") : "");
            formData.append("q_end_date", values?.q_end_date ? moment(values?.q_end_date).format("YYYY-MM-DD") : "");
            formData.append("excludedQualification", addNotifications.length ? JSON.stringify(addNotificationsIds) : []);

            values?.isCopySubject && formData.append("copy_subjects_checkbox", values?.isCopySubject ? "copySubjects" : "");
            values.isViewOnly && formData.append("view_only", values.isViewOnly ? "show" : "");
            values.isCopyAssessment && formData.append("copy_assessment", values.isCopyAssessment ? "copyAssessment" : "");
            values.isCopyAssembly && formData.append("copy_assembly", values.isCopyAssembly ? "copyAssembly" : "");
            values.isCopyQualifications && formData.append("copy_qualification_checkbox", values.isCopyQualifications ? "copyQualifications" : "");

            if (!values.isCopySubject && !values.isCopyQualifications) {
                modalFormik.setFieldError('isCopyQualifications', 'Please select at least one copy course or copy programme.');
                setDisabled(false);
                setLoading(false);
                return; 
            }

            if (values.isCopyQualifications && addNotifications.length === 0) {
                modalFormik.setFieldError('isCopyQualifications', 'Please select at least one Programme to copy.');
                setDisabled(false);
                setLoading(false);
                return; 
            }
                Swal.fire({
                title: "Are you sure you want to copy intakes?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Copy!",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    copyProcess(formData)
                        .then((res) => {
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Successfully Copied Intakes`,
                            }).then(() => {
                                if (tab == "add") {
                                    history.push(
                                        `/courseAdministration/intake-qualification/list-copied-intake`
                                    );
                                }
                                setDisabled(false);
                                setLoading(false);
                            });
                        })
                        .catch((err) => {
                            Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: `${err?.response?.data?.message}`,
                            });
                            setDisabled(false);
                            setLoading(false);
                        });
                } else {
                  setLoading(false)
                  setDisabled(false)  
                }
            })
        },
    });

    useEffect(() => {
        GetProgramsListFilter()
            .then((res) => {
                setFilterData({
                    ...res.data,
                    learning: res.data.intakeTypeFilter,
                    type: res.data.ProgrammeFilter,
                    intake: res.data.yearsAndIntaksFilter,
                    school: res.data.schoolFilter,
                });
            })
            .catch((err) => console.log(err));
        GetAllDropdownValues().then((res) => {
            let tempIntakeArr = [];
            res.data.intake_year &&
                res.data.intake_year.length &&
                res.data.intake_number &&
                res.data.intake_number.length &&
                res.data.intake_year.map((year) => {
                    res.data.intake_number.map((number) => {
                        tempIntakeArr.push({
                            label: `${year.label}-${number.label}`,
                            value: `${year.value}/${number.value}`,
                        });
                    });
                });
            setIntakeArr(tempIntakeArr);
            setSchoolArr(res.data.school_type);
            setLearningMethodArr(res.data.study_type);
        });
    }, []);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const handleSearchFilter1 = (e) => {
        const value = e.target.value;
        setSearch1(value);
    };

    const resetFilter = () => {
        setSchool({ arr: [], checkObj: {} });
        setType({ arr: [], checkObj: {} });
        setIntake({ arr: [], checkObj: {} });
        setLearningMethod({ arr: [], checkObj: {} });
        setSearch("");
        setDebouncedTerm("")
        setCheckedAllRows([]);
        setTempCheckedAllData([]);
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (page == "open-articulation-registration" && tab == "open") {
            articulationDetails(id).then((res) => {
                setRegistrationDetail(res?.data?.data);
                setArticulationStatus(res?.data?.data?.status);
                // modalFormik.setFieldValue("item_desc", res?.data?.data?.description);
                // modalFormik.setFieldValue("item_status", res?.data?.data?.status);
                setDatesValues({
                    start_date: new Date(
                        moment.unix(
                            res?.data?.data?.start_date.length > 10
                                ? res?.data?.data?.start_date / 1000
                                : res?.data?.data?.start_date
                        )
                    ),
                    end_date: new Date(
                        moment.unix(
                            res?.data?.data?.end_date.length > 10
                                ? res?.data?.data?.end_date / 1000000
                                : res?.data?.data?.end_date
                        )
                    ),
                    hard_launch_date: new Date(
                        moment.unix(res?.data?.data?.hard_launch_date)
                    ),
                });
                // modalFormik.setFieldValue("start_date", new Date(moment.unix(res?.data?.data?.start_date.length > 10 ? res?.data?.data?.start_date / 1000 : res?.data?.data?.start_date)));
                // modalFormik.setFieldValue("end_date", new Date(moment.unix(res?.data?.data?.end_date.length > 10 ? res?.data?.data?.end_date / 1000000 : res?.data?.data?.end_date)));
                if (res?.data?.data?.hard_launch_date) {
                    // modalFormik.setFieldValue("hard_launch_date", new Date(moment.unix(res?.data?.data?.hard_launch_date)));
                }
            });
        }
    };

    useEffect(() => {
        setloading(true);
        const apiData = {
            limit,
            page: pages,
            key,
            sort,
            viaSearch: search,
            exportStatus: "false",
            viaSchool: school.arr,
            viaType: type.arr,
            intake: intake.arr,
            viaLearning: learningMethod.arr,
        };
        GetProgramsList(apiData)
            .then((res) => {
                let tempIntake = res.data.programs.data
                let tempIntakeIds = tempIntake.filter((temp)=>!checkedAllRows.includes(temp.id))
                setIsAllSelectChecked(tempIntakeIds.length ? false : true)
                setProgrammeList(res.data.programs.data);
                setTotalRows(res.data.programs.total);
                setloading(false);
            })
            .catch((err) => console.log(err));
    }, [
        deleterow,
        limit,
        pages,
        key,
        sort,
        search,
        school,
        type,
        intake,
        learningMethod,
        modalFormik.values.from_intake
    ]);

    const handleReset = (resetForm) => {
        resetForm();
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const sortingName = (rowA, rowB) => {
        const name1 = rowA.course.toString().toLowerCase();
        const name2 = rowB.course.toString().toLowerCase();
        return name1.localeCompare(name2);
    };

    const handleSelect = (e, row) => {
        const { checked, id, name, value } = e.target;
        const ls = [...checkedAllRows];
        const lsData = [...tempCheckedAllData];
        const deleteData = [...addNotifications]
        if (checked) {
          ls.push(Number(id));
          lsData.push(row);
        } else {
          const index = ls.indexOf(Number(id));
          ls.splice(index, 1);
          const indexData = lsData.indexOf((item) => item?.id == row?.id);
          lsData.splice(indexData, 1);
          const deleteIndexData = deleteData.indexOf((item) => item?.id == row?.id)
          deleteData.splice(index, 1)
        }
        setCheckedAllRows([...ls]);
        setTempCheckedAllData([...lsData]);
        setAddNotifications([...deleteData])
        let allChecked = programmeList.map((item) => ls.includes(item?.id)).filter(item => item)
        if(allChecked.length == limit) {
          setIsAllSelectChecked(true)
        } else {
          setIsAllSelectChecked(false)
        }
      }
    
      const handleAllSelect = async (e) => {
        let checked = e.target?.checked
        let intakeIds = programmeList.map(item => item.id)
        if (checked) {
          setCheckedAllRows(prevState => [...prevState, ...intakeIds])
          let completeArr = programmeList.filter(item => intakeIds.includes(item.id))
          setTempCheckedAllData((prevState) => [...prevState, ...completeArr])
        } else {
          let arr = checkedAllRows.filter(item => !intakeIds.includes(item))
          setCheckedAllRows(prevState => [...arr])
          let completeArr = tempCheckedAllData.filter(item => !arr.includes(item.id))
          setTempCheckedAllData(completeArr)
        }
        setIsAllSelectChecked(checked)
      }

      const addMembers = () => {
        let uniqueCheckedAlldata = [...new Set(tempCheckedAllData)]
        let uniqueCheckedAllRows = [...new Set(checkedAllRows)]
        setCheckedAllRows(uniqueCheckedAllRows)
        setTempCheckedAllData(uniqueCheckedAlldata)
        setAddNotifications(uniqueCheckedAlldata);
        closeCourses();
      }

      function closeCourses() {
        $("#availableprogrammeinRereg").modal('hide');
      }

    const columns = useMemo(() => [
        {
            name: <input type={"checkbox"} name="allSelect" onChange={handleAllSelect} checked={isAllSelectChecked} />,
            cell: (row, ind) => {
      
              return <input
                type={"checkbox"}
                name="select"
                id={row?.id}
                onChange={(e) => handleSelect(e, row)}
                checked={checkedAllRows.includes(row?.id)}
              />
            }
        },
        {
            name: "Name",
            selector: "course",
            sortable: true,
            sortField: "name",
            // minWidth: "190px",
            cell: (row) => (
                <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}>
                    <span
                        title={row.course}
                        className="as-text-blue feature-name"
                        // to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                    >
                        <span className="textLimit100">
                            {row.course ? row.course : ""}{" "}
                        </span>
                    </span>
                </PermissionsGate>
            ),
        },
        {
            name: "Start",
            selector: "start",
            sortable: true,
            sortField: "startDate",
            // sortFunction: sortingDate,
            cell: (row) => (
                <p>
                    {row.start ? (
                        <div className="dateTime">
                            <p className="right-space">
                                {row.start.toString().length == 10
                                    ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                                    : moment(row.start).format(TABLE_DATE_FORMAT)}
                            </p>
                        </div>
                    ) : (
                        "-"
                    )}
                </p>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortable: true,
            sortField: "endDate",
            cell: (row) => (
                <>
                    {row.end ? (
                        <div className="dateTime">
                            <p className="right-space">
                                {row.end.toString().length == 10
                                    ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                                    : moment(row.end).format(TABLE_DATE_FORMAT)}
                            </p>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Learning Method",
            selector: "type",
            sortable: true,
            sortField: "learningMethod",
            cell: (row) =>
                row.type ? RenderLearningMethod(row.type, "programme").html : "-",
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            sortField: "intake",
            cell: (row) =>
                row.number && row.year ? row.year + "-" + row.number : "-",
        },
        {
            name: "Type",
            selector: "program_type",
            // maxWidth: "240px",
            sortable: true,
            cell: (row) =>
                row.program_type
                    ? RenderProgrammeType(row.program_type, "programme").html
                    : "-",
        },
        {
            name: "Students",
            selector: "total_student_quali",
            sortable: true,
            sortField: "student",
            cell: (row) => row.total_student_quali,
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            sortField: "school",
            cell: (row) => {
                return row.school_type
                    ? RenderSchool(row.school_type, "programme").html
                    : "-";
            },
        },
    ]);

    const columns1 = useMemo(() => [
        {
            name: "Name",
            selector: "course",
            sortable: true,
            sortFunction: sortingName,
            cell: (row) => (
                <Link
                    to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                    title={row.course}
                    className="as-text-blue curser feature-name"
                >
                    <span className="textLimit100">{row.course}</span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            cell: (row) => row.year + "-" + row.number,
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            cell: (row) =>
                row.school_type ? RenderSchool(row.school_type).html : "-",
        },
        {
            name: "Learning Method",
            selector: "type",
            sortable: true,
            cell: (row) => (row.type ? RenderLearningMethod(row.type).html : "-"),
        },
        {
            name: "Start",
            selector: "start",
            sortable: true,
            cell: (row) => (
                <>
                    {row.start ? (
                        <div className="dateTime">
                            {/* <p className="right-space">{new Date(row.start)}</p> */}
                            <p className="right-space">
                                {row.start.toString().length == 10
                                    ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                                    : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}
                            </p>
                            {/* <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.start}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortable: true,
            cell: (row) => (
                <>
                    {row.end ? (
                        <div className="dateTime">
                            <p className="right-space">
                                {row.end.toString().length == 10
                                    ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                                    : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}
                            </p>
                            {/* <p>{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.end}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="row m-0">
                    <div className="assessment-08 btn-dropdown-grp">
                        <div className="as-buttons">
                            <Link
                                to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                                title="Open"
                                className="btn btn-primary rounded-circle"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                            {/* <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => checkCheckbox(row?.id, row?.checked)}> */}
                            <PermissionsGate
                                scopes={["cparaapdelete"]}
                                errorProps={{ disabled: true }}
                            >
                                <button
                                    className="btn btn-danger rounded-circle"
                                    type="button"
                                    title="Delete"
                                    onClick={() => {
                                        // Swal.fire({
                                        //     title: "Are you sure?",
                                        //     text: "You won't be able to revert this!",
                                        //     icon: "warning",
                                        //     showCancelButton: true,
                                        //     confirmButtonColor: "#3085d6",
                                        //     cancelButtonColor: "#d33",
                                        //     confirmButtonText: "Yes, delete it!",
                                        // }).then((result) => {
                                        //     if (result.isConfirmed) {
                                        //         addRemoveRow(row, "delete");
                                        //     }
                                        // });
                                        handleSelect({target: { checked: false, id: row?.id }}, row)
                                    }}
                                >
                                    <i className="fal fa-trash-alt"></i>
                                </button>
                            </PermissionsGate>
                        </div>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRender1 = () => {
        let updatedData = [];
        let allData = addNotifications;
        if (search1.length) {
            // let tempId = allData.filter((item) => {
            //     let includes = item.id.toString().toLowerCase().includes(search.toLowerCase());
            //     if (includes) {
            //         return includes;
            //     } else return null;
            // });
            let tempFirstname = allData.filter((item) => {
                let includes =
                    item.course &&
                    item.course.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempYear = allData.filter((item) => {
                let includes =
                    item.year && item.year.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempSchoolType = allData.filter((item) => {
                let includes =
                    item.school_type &&
                    item.school_type.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempType = allData.filter((item) => {
                let includes =
                    item.type && item.type.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartDate = allData.filter((item) => {
                let includes =
                    item.start &&
                    moment
                        .unix(item.start)
                        .format(TABLE_DATE_FORMAT)
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartEnd = allData.filter((item) => {
                let includes =
                    item.end &&
                    moment
                        .unix(item.end)
                        .format(TABLE_DATE_FORMAT)
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let data = [
                // ...tempId,
                ...tempFirstname,
                ...tempYear,
                ...tempSchoolType,
                ...tempType,
                ...tempStartDate,
                ...tempStartEnd,
            ];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (assignSchool.arr.length) {
            let tempRole = updatedData;
            let tempSearch = tempRole.filter((item) => {
                const startsWith = assignSchool.arr.find(function (user, index) {
                    if (
                        user.toLowerCase() ===
                        (item.school_type ? item.school_type.toLowerCase() : "")
                    )
                        return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });
            updatedData = tempSearch;
        }

        if (assignIntake.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = assignIntake.arr.find(function (post, index) {
                    if (
                        post ===
                        (item.year && item.number ? item.year + "/" + item.number : "")
                    )
                        return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        if (assignLearningMethod.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = assignLearningMethod.arr.find(function (
                    post,
                    index
                ) {
                    if (
                        item.type &&
                            post.toString().toLowerCase() === item.type.toString().toLowerCase()
                            ? item.type.toString().toLowerCase()
                            : ""
                    )
                        return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        return updatedData;
    };

    const exportData = (fileType, fileName) => {
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        const apiData = {
            limit,
            page: pages,
            key,
            sort,
            viaSearch: search,
            exportStatus: "true",
            viaSchool: school.arr,
            viaType: type.arr,
            intake: intake.arr,
            viaLearning: learningMethod.arr,
        };
        GetProgramsList(apiData)
            .then((res) => {
                let data = res.data.programs;
                const header = [
                    "Name",
                    "Start",
                    "End",
                    "Learning Method",
                    "Intake",
                    "Type",
                    "Students",
                    "School",
                ];

                data = data.map((row) => ({
                    ...row,
                    // ID: row?.id,
                    Name: row?.course,
                    Start: row?.start
                        ? row.start.toString().length == 10
                            ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                            : moment(row.start).format(TABLE_DATE_FORMAT)
                        : "-",
                    End: row?.end
                        ? row.end.toString().length == 10
                            ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                            : moment(row.end).format(TABLE_DATE_FORMAT)
                        : "-",
                    "Learning Method": row.type
                        ? RenderLearningMethod(row.type).text
                        : "-",
                    Intake: row.number ? row.year + "-" + row.number : "-",
                    Type: row.program_type
                        ? RenderProgrammeType(row.program_type).text
                        : "-",
                    Students:
                        row.total_student_quali !== null &&
                            row.total_student_quali.toString() &&
                            row.total_student_quali.toString
                            ? row.total_student_quali
                            : "-",
                    School: row.school_type ? RenderSchool(row.school_type).text : "-",
                }));
                // console.log(typeof data);
                // console.log(typeof result);

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();

                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row.Name,
                            row["Start"],
                            row["End"],
                            row["Learning Method"],
                            row.Intake,
                            row.Type,
                            row.Students,
                            row.School,
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        columnStyles: {
                            0: {
                                columnWidth: 30,
                            },
                            1: {
                                columnWidth: 20,
                            },
                            2: {
                                columnWidth: 20,
                            },
                            3: {
                                columnWidth: 20,
                            },
                            4: {
                                columnWidth: 15,
                            },
                            5: {
                                columnWidth: 30,
                            },
                            6: {
                                columnWidth: 10,
                            },
                            7: {
                                columnWidth: 30,
                            },
                        },
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();

                    return false;
                }
            })
            .catch((err) => console.error("error:", err));
    };

    const exportData1 = (fileType, fileName) => {
        let data = dataToRender1();
        const header = [
            "Name",
            "Intake",
            "School",
            "Learning Method",
            "Start",
            "End",
        ];

        data = data?.map((row) => ({
            ...row,
            Name: row.course,
            Intake: row.year ? row.year + "-" + row.number : "-",
            School: row.school_type ? RenderSchool(row.school_type).text : "-",
            "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
            Start: row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
            End: row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
        }));

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();
            setTimeout(() => {
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [
                    row.course,
                    row.year + " " + row.number,
                    RenderSchool(row.school_type).text,
                    row?.type ? RenderLearningMethod(row?.type).text : "-",
                    moment.unix(row.start).format(TABLE_DATE_FORMAT),
                    moment.unix(row.end).format(TABLE_DATE_FORMAT),
                ];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const handlePageChange = (pageNo) => {
        setPages(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setLimit(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setKey(column.sortField);
        setSort(sortDirection === "asc" ? "ASC" : "DESC");
    };

    function resetFilter1() {
        setSearch1("");
        setAssignIntake({ arr: [], checkObj: {} });
        setAssignSchool({ arr: [], checkObj: {} });
        setAssignLearningMethod({ arr: [], checkObj: {} });
    }

    return (
        <>
            <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="custom-table-div filter-search-icon card card-table-custom">
                    <div className="card-body">
                        <div className="card-body-inr card-body-info"></div>
                        <form onSubmit={modalFormik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group-blk mb-3" title="From Intake">
                                        <label>From Intake *</label>
                                        <Select
                                            className={
                                                "form-control custom-select-box " +
                                                (modalFormik.errors.from_intake && modalFormik.touched.from_intake
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            name="from_intake"
                                            value={intakeArr.filter((val) => {
                                                return val.value == modalFormik.values.from_intake;
                                            })}
                                            onChange={(value) => {
                                                if (value) {
                                                    modalFormik.setFieldValue("from_intake", value.value);
                                                    intake.arr.push(value.value)
                                                    let newKey = `intake${value.value}`
                                                    resetFilter();
                                                    setIntake({arr: [value.value], checkObj: { [newKey]: true } })
                                                } else {
                                                    intake.arr.splice(intake.arr.indexOf(modalFormik.values.from_intake), 1)
                                                    let newKey = `intake${modalFormik.values.from_intake}`
                                                    setIntake({ ...intake, checkObj: { ...intake.checkObj, [newKey]: false } })
                                                    modalFormik.setFieldValue("from_intake", "");
                                                    resetFilter();
                                                }
                                            }}
                                            onBlur={modalFormik.handleBlur}
                                            options={intakeArr}
                                            maxMenuHeight={175}
                                            placeholder={
                                                modalFormik.values.from_intake
                                                    ? modalFormik.values.from_intake
                                                    : "Select Intake"
                                            }
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group-blk mb-3" title="To Intake">
                                        <label>To Intake *</label>
                                        <Select
                                            className={
                                                "form-control custom-select-box " +
                                                (modalFormik.errors.to_intake && modalFormik.touched.to_intake
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            name="to_intake"
                                            value={intakeArr.filter((val) => {
                                                return val.value == modalFormik.values.to_intake;
                                            })}
                                            onChange={(value) => {
                                                if (value) {
                                                    modalFormik.setFieldValue("to_intake", value.value);
                                                } else {
                                                    modalFormik.setFieldValue("to_intake", "");
                                                }
                                            }}
                                            onBlur={modalFormik.handleBlur}
                                            options={intakeArr}
                                            maxMenuHeight={175}
                                            placeholder={
                                                modalFormik.values.to_intake
                                                    ? modalFormik.values.to_intake
                                                    : "Select Intake"
                                            }
                                            isClearable
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-2">
                                    <div className="form-group-blk">
                                        <div className="">
                                            <label>&nbsp;</label>
                                            <div className="">
                                                <div
                                                    className="custom-check custom-control custom-checkbox"
                                                // style={{ width: "100px" }}
                                                >
                                                    <input
                                                        //   disabled={isAppealDisabled}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="copySubjects"
                                                        name="select"
                                                        checked={
                                                            modalFormik.values.isCopySubject
                                                                ? modalFormik.values.isCopySubject
                                                                : ""
                                                        }
                                                        onChange={(e) =>
                                                            modalFormik.setFieldValue(
                                                                "isCopySubject",
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="appealCheckbox"
                                                    >
                                                        <div className="mr-3"></div> Copy Courses
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {modalFormik.values.isCopySubject && (
                                    <>
                                        <div className="col-md-6 col-lg-2">
                                            <div className="form-group-blk mb-3" title="Start Date">
                                                <label>Start Date *</label>
                                                <DatePicker
                                                    selected={modalFormik.values.start_date}
                                                    onChange={(date) => {
                                                        modalFormik.setFieldValue("start_date", date);
                                                    }}
                                                    dateFormat={FORM_DATE_FORMAT}
                                                    className={
                                                        "form-control w-100 " +
                                                        (modalFormik.errors.start_date &&
                                                            modalFormik.touched.start_date
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onChangeRaw={(e) => e.preventDefault()}
                                                    minDate={new Date()}
                                                    placeholderText="Select Start Date"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-2">
                                            <div className="form-group-blk mb-3" title="End Date">
                                                <label>End Date *</label>
                                                <DatePicker
                                                    autoComplete="off"
                                                    disabled={!modalFormik.values.start_date}
                                                    selected={modalFormik.values.end_date}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    onChange={(date) => {
                                                        modalFormik.setFieldValue("end_date", date);
                                                    }}
                                                    dateFormat={FORM_DATE_FORMAT}
                                                    className={
                                                        "form-control w-100 " +
                                                        (modalFormik.errors.end_date &&
                                                            modalFormik.touched.end_date
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onChangeRaw={(e) => e.preventDefault()}
                                                    minDate={new Date(
                                                        modalFormik.values.start_date
                                                    ).setDate(
                                                        new Date(modalFormik.values.start_date).getDate() +
                                                        1
                                                    )}
                                                    placeholderText="Select End Date"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-2">
                                            <label title="SendWhen">Publish Date *</label>
                                            <div className="form-icon-group mt-2" title="SendWhen">
                                                <div className="">
                                                    <div
                                                        class="custom-control custom-radio custom-control-inline"
                                                        title="Now"
                                                    >
                                                        <input
                                                            type="radio"
                                                            id="customRadioInline1"
                                                            name="customRadioInline"
                                                            class="custom-control-input"
                                                            checked={modalFormik.values.SendWhen == 0}
                                                            onChange={() =>
                                                                modalFormik.setFieldValue("SendWhen", 0)
                                                            }
                                                            onBlur={modalFormik.handleBlur}
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customRadioInline1"
                                                        >
                                                            Now
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="custom-control custom-radio custom-control-inline"
                                                        title="Later"
                                                    >
                                                        <input
                                                            type="radio"
                                                            id="customRadioInline2"
                                                            name="customRadioInline"
                                                            class="custom-control-input"
                                                            title="SendWhen"
                                                            checked={!modalFormik.values.SendWhen == 0}
                                                            onChange={() =>
                                                                modalFormik.setFieldValue("SendWhen", 1)
                                                            }
                                                            onBlur={modalFormik.handleBlur}
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customRadioInline2"
                                                        >
                                                            Later
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {!modalFormik.values.SendWhen == 0 ? (
                                            <div className="col-md-6 col-lg-2">
                                                <label>Date *</label>
                                                <div className="form-group-blk mb-3">
                                                    <DatePicker
                                                        selected={modalFormik.values.SendWhenDate}
                                                        onChange={(date) => {
                                                            modalFormik.setFieldValue("SendWhenDate", date);
                                                        }}
                                                        // showTimeSelect
                                                        filterTime={filterPassedTime}
                                                        minDate={new Date()}
                                                        dateFormat={FORM_DATE_TIME_FORMAT}
                                                        // className="form-control"
                                                        className={
                                                            "form-control cursor-pointer" +
                                                            (modalFormik.errors.SendWhenDate &&
                                                                modalFormik.touched.SendWhenDate
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        title="Date"
                                                        onBlur={modalFormik.handleBlur}
                                                        placeholderText="Enter Date"
                                                        showTimeSelect
                                                    />
                                                </div>
                                                {modalFormik.errors.SendWhenDate &&
                                                    modalFormik.touched.SendWhenDate ? (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                ) : null}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="col-md-6 col-lg-2">
                                            <div className="form-group-blk">
                                                <div className="">
                                                    <label>&nbsp;</label>
                                                    <div className="">
                                                        <div
                                                            className="custom-check custom-control custom-checkbox"
                                                        // style={{ width: "100px" }}
                                                        >
                                                            <input
                                                                //   disabled={isAppealDisabled}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="viewOnly"
                                                                name="select"
                                                                checked={
                                                                    modalFormik.values.isViewOnly
                                                                        ? modalFormik.values.isViewOnly
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    modalFormik.setFieldValue(
                                                                        "isViewOnly",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="viewOnly"
                                                            >
                                                                <div className="mr-3"></div> View Only
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-2">
                                            <div className="form-group-blk">
                                                <div className="">
                                                    <label>&nbsp;</label>
                                                    <div className="">
                                                        <div
                                                            className="custom-check custom-control custom-checkbox"
                                                        // style={{ width: "100px" }}
                                                        >
                                                            <input
                                                                //   disabled={isAppealDisabled}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="copyAssessment"
                                                                name="select"
                                                                checked={
                                                                    modalFormik.values.isCopyAssessment
                                                                        ? modalFormik.values.isCopyAssessment
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    modalFormik.setFieldValue(
                                                                        "isCopyAssessment",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="copyAssessment"
                                                            >
                                                                <div className="mr-3"></div> Copy Assessment
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-2">
                                            <div className="form-group-blk">
                                                <div className="">
                                                    <label>&nbsp;</label>
                                                    <div className="">
                                                        <div
                                                            className="custom-check custom-control custom-checkbox"
                                                        // style={{ width: "100px" }}
                                                        >
                                                            <input
                                                                //   disabled={isAppealDisabled}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="copyAssembly"
                                                                name="select"
                                                                checked={
                                                                    modalFormik.values.isCopyAssembly
                                                                        ? modalFormik.values.isCopyAssembly
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    modalFormik.setFieldValue(
                                                                        "isCopyAssembly",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="copyAssembly"
                                                            >
                                                                <div className="mr-3"></div> Copy Assembly
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6 col-lg-2">
                                    <div className="form-group-blk">
                                        <div className="">
                                            <label>&nbsp;</label>
                                            <div className="">
                                                <div
                                                    className="custom-check custom-control custom-checkbox"
                                                // style={{ width: "100px" }}
                                                >
                                                    <input
                                                        //   disabled={isAppealDisabled}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="copyQualifications"
                                                        name="select"
                                                        checked={
                                                            modalFormik.values.isCopyQualifications
                                                                ? modalFormik.values.isCopyQualifications
                                                                : ""
                                                        }
                                                        onChange={(e) =>
                                                            modalFormik.setFieldValue(
                                                                "isCopyQualifications",
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="copyQualifications"
                                                    >
                                                        <div className="mr-3"></div> Copy Programmes
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-2">
                                    <div className="form-group-blk mb-3" title="Start Date">
                                        <label>Start Date {modalFormik.values.isCopyQualifications && "*"}</label>
                                        <DatePicker
                                            selected={modalFormik.values.q_start_date}
                                            onChange={(date) => {
                                                modalFormik.setFieldValue("q_start_date", date);
                                            }}
                                            dateFormat={FORM_DATE_FORMAT}
                                            className={
                                                "form-control w-100 " +
                                                (modalFormik.errors.q_start_date &&
                                                    modalFormik.touched.q_start_date
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChangeRaw={(e) => e.preventDefault()}
                                            minDate={new Date()}
                                            placeholderText="Select Start Date"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dateFormatCalendar="MMMM"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-2">
                                    <div className="form-group-blk mb-3" title="End Date">
                                        <label>End Date {modalFormik.values.isCopyQualifications && "*"}</label>
                                        <DatePicker
                                            autoComplete="off"
                                            disabled={!modalFormik.values.q_start_date}
                                            selected={modalFormik.values.q_end_date}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dateFormatCalendar="MMMM"
                                            onChange={(date) => {
                                                modalFormik.setFieldValue("q_end_date", date);
                                            }}
                                            dateFormat={FORM_DATE_FORMAT}
                                            className={
                                                "form-control w-100 " +
                                                (modalFormik.errors.q_end_date &&
                                                    modalFormik.touched.q_end_date
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChangeRaw={(e) => e.preventDefault()}
                                            minDate={new Date(modalFormik.values.q_start_date).setDate(
                                                new Date(modalFormik.values.q_start_date).getDate() + 1
                                            )}
                                            placeholderText="Select End Date"
                                        />
                                    </div>
                                </div>
                                {modalFormik.values.isCopyQualifications && (
                                    <div className="col-md-6 col-lg-2">
                                        <div className="form-group-blk" title="Include Programmes">
                                            <div>
                                            <label>&nbsp;</label>
                                            </div>
                                            <PermissionsGate
                                                scopes={["cparaapadd"]}
                                                errorProps={{ disabled: true }}
                                            >
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#availableprogrammeinRereg"
                                                    title="Available Programmes"
                                                >
                                                    <i className="fal fa-plus"></i>Include Programmes
                                                </button>
                                            </PermissionsGate>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {addNotifications?.length ? (
                                <>
                                    <div className="search-filter-div">
                                        <div className="search-filter-div-left">
                                            <div className="system-administration-table table-responsive">
                                                <div className="table-responsive-div">
                                                    <div
                                                        id="assessment-table-main_wrapper"
                                                        className="dataTables_wrapper no-footer"
                                                    >
                                                        <div
                                                            id="assessment-table-main_filter"
                                                            className="dataTables_filter"
                                                        >
                                                            <label>
                                                                <input
                                                                    type="search"
                                                                    className=""
                                                                    placeholder="Search"
                                                                    aria-controls="assessment-table-main"
                                                                    onChange={(e) =>
                                                                        handleSearchFilter1(e, "searchAssigned")
                                                                    }
                                                                    value={search1}
                                                                />
                                                            </label>
                                                            <div className="filter-eff filter-data-btn">
                                                                <button className="filter-buttons">
                                                                    <i className="fal fa-filter"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="filter-button-group">
                                                    <div className="filter-scroll">
                                                        <div
                                                            className={`filter-scroll-inner  filter-custom-new`}
                                                        >
                                                            <Tablefilter
                                                                filterName={"Intake"}
                                                                optionArr={intakeArr}
                                                                state={assignIntake}
                                                                setState={setAssignIntake}
                                                                uniqueId={"assignIntake"}
                                                                isOptionReversed={true}
                                                            />
                                                            <Tablefilter
                                                                filterName={"School"}
                                                                optionArr={schoolArr}
                                                                state={assignSchool}
                                                                setState={setAssignSchool}
                                                                renderLabelFunction={RenderSchool}
                                                                uniqueId={"assignSchool"}
                                                            />
                                                            <Tablefilter
                                                                filterName={"Learning Method"}
                                                                optionArr={learningMethodArr}
                                                                state={assignLearningMethod}
                                                                setState={setAssignLearningMethod}
                                                                renderLabelFunction={RenderLearningMethod}
                                                                uniqueId={"assignLearningMethod"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="reset-btn-group">
                                                        <div className="button-reset dropdown-comman">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                title="Reset"
                                                                onClick={resetFilter1}
                                                            >
                                                                <i className="fal fa-redo"></i>Reset
                                                            </button>
                                                        </div>
                                                        <div className="files-export-group">
                                                            <button
                                                                type="button"
                                                                className="btn btn-files"
                                                                onClick={() => {
                                                                    exportData1("xlsx", "Assigned Programme");
                                                                }}
                                                                title="Export spreadsheet"
                                                            >
                                                                <i className="fal fa-file-excel icon"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-files"
                                                                onClick={() => {
                                                                    exportData1("csv", "Assigned Programme");
                                                                }}
                                                                title="Export CSV"
                                                            >
                                                                <i className="fal fa-file-csv icon"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-files"
                                                                onClick={() => {
                                                                    exportData1("pdf", "Assigned Programme");
                                                                }}
                                                                title="Export PDF"
                                                            >
                                                                <i className="fal fa-file-pdf icon"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {loading ? (
                                        <SkeletonTicketList />
                                    ) : (
                                        <DataTable
                                            data={dataToRender1()}
                                            defaultSortField="start"
                                            defaultSortAsc={true}
                                            columns={columns1}
                                            pagination={true}
                                            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                                            sortServer={false}
                                        //   progressPending={loading}
                                        //   progressComponent={<SkeletonTicketList />}
                                        />
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                            <div className="form-group form-group-save-cancel mt-4">
                                <PermissionsGate
                                    scopes={["intakeCopyAdd"]}
                                    errorProps={{ disabled: true }}
                                >
                                    <button
                                        className="btn btn-save btn-success"
                                        type="submit"
                                        title="Copy"
                                        onClick={modalFormik.handleSubmit}
                                        disabled={!modalFormik.dirty || disabled}
                                    >
                                        {Loading === false ? (
                                            <i className="fal fa-copy"></i>
                                        ) : (
                                            <i className="fas fa-cog fa-spin"></i>
                                        )}
                                        Copy
                                    </button>
                                </PermissionsGate>

                                <button
                                    className="btn btn-close btn-danger"
                                    type="button"
                                    title="Cancel"
                                    onClick={() => {
                                        handleReset(modalFormik.resetForm);
                                        if (id) {
                                            modalFormik.setFieldValue(
                                                "start_date",
                                                datesValues.start_date
                                            );
                                            modalFormik.setFieldValue(
                                                "end_date",
                                                datesValues.end_date
                                            );
                                            modalFormik.setFieldValue(
                                                "hard_launch_date",
                                                datesValues.hard_launch_date
                                            );
                                            modalFormik.setFieldValue(
                                                "item_status",
                                                articulationStatus
                                            );
                                        }
                                    }}
                                >
                                    <i className="fal fa-times"></i>Cancel
                                </button>
                            </div>
                            {Object.keys(modalFormik.values).map((key) => {
                                if (modalFormik.touched[key] && modalFormik.errors[key]) {
                                    return (
                                        <div className="invalid-feedback d-block">
                                            {modalFormik.errors[key]}
                                        </div>
                                    );
                                }
                            })}
                        </form>
                    </div>
                </div>
                <div
                    className="topic-add-modal modal fade"
                    id="availableprogrammeinRereg"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered available-assessment-modal"
                        role="document"
                    >
                        <div className="modal-content modal-border-update">
                            <div className="modal-body p-0">
                                <div className="modal-header modal-header-custom">
                                    <h5 className="modal-title">Available Programme</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        data-toggle="modal"
                                        data-target="#availableprogrammeinRereg"
                                        onClick={()=>resetFilter()}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="t p-30px">
                                    <div className="my-tickets-info-list Tickets-main-wrap">
                                        <div className="custom-table-div filter-search-icon card-table-custom">
                                            <div className="row">

                                            </div>
                                            <div className="search-filter-div">
                                                <div className="search-filter-div-left">
                                                    <div className="system-administration-table table-responsive">
                                                        <div className="table-responsive-div">
                                                            <div
                                                                id="assessment-table-main_wrapper"
                                                                className="dataTables_wrapper no-footer"
                                                            >
                                                                <div
                                                                    id="assessment-table-main_filter"
                                                                    className="dataTables_filter"
                                                                >
                                                                    <label>
                                                                        <input
                                                                            type="search"
                                                                            className=""
                                                                            placeholder="Search"
                                                                            aria-controls="assessment-table-main"
                                                                            onChange={handleSearchFilter}
                                                                            value={debouncedTerm}
                                                                        />
                                                                    </label>
                                                                    <div className="filter-eff filter-data-btn">
                                                                        <button className="filter-buttons">
                                                                            <i className="fal fa-filter"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="filter-button-group">
                                                            <div className="filter-scroll">
                                                                <div
                                                                    className={`filter-scroll-inner  filter-custom-new`}
                                                                >
                                                                    <Tablefilter
                                                                        filterName="Learning Method"
                                                                        optionArr={filterData.learning}
                                                                        state={learningMethod}
                                                                        setState={setLearningMethod}
                                                                        renderLabelFunction={RenderLearningMethod}
                                                                    />
                                                                    <Tablefilter
                                                                        filterName={"Intake"}
                                                                        optionArr={filterData.intake}
                                                                        state={intake}
                                                                        setState={setIntake}
                                                                        isOptionReversed={true}
                                                                    />
                                                                    <Tablefilter
                                                                        filterName="Type"
                                                                        optionArr={filterData.type}
                                                                        state={type}
                                                                        setState={setType}
                                                                        renderLabelFunction={RenderProgrammeType}
                                                                    />
                                                                    <Tablefilter
                                                                        filterName="School"
                                                                        optionArr={filterData.school}
                                                                        state={school}
                                                                        setState={setSchool}
                                                                        renderLabelFunction={RenderSchool}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="reset-btn-group">
                                                                <div className="button-reset dropdown-comman">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={resetFilter}
                                                                        title="Reset"
                                                                    >
                                                                        <i className="fal fa-redo"></i>Reset
                                                                    </button>
                                                                </div>
                                                                <div className="files-export-group">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-files"
                                                                        onClick={() => {
                                                                            exportData("xlsx", "Programmes");
                                                                        }}
                                                                        title="Export spreadsheet"
                                                                    >
                                                                        <i className="fal fa-file-excel icon"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-files"
                                                                        onClick={() => {
                                                                            exportData("csv", "Programmes");
                                                                        }}
                                                                        title="Export CSV"
                                                                    >
                                                                        <i className="fal fa-file-csv icon"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-files"
                                                                        onClick={() => {
                                                                            exportData("pdf", "Programmes");
                                                                        }}
                                                                        title="Export PDF"
                                                                    >
                                                                        <i className="fal fa-file-pdf icon"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <DataTable
                                                data={programmeList}
                                                defaultSortField="start"
                                                defaultSortAsc={false}
                                                columns={columns}
                                                pagination={true}
                                                noDataComponent={Str.noRecord}
                                                onSort={handleSort}
                                                paginationServer
                                                sortServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                highlightOnHover={false}
                                                progressPending={loading}
                                                progressComponent={<SkeletonTicketList />}
                                                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                                            />
                                            <div className="btn-sec">
                                                <button
                                                className="btn btn-primary"
                                                type="button"
                                                title="Add Programmes"
                                                onClick={() => {
                                                    addMembers();
                                                }}
                                                >
                                                <i className="fal fa-plus"></i>Add Programmes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OpenCopiedIntake;
