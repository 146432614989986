import React, { useState } from 'react'
import PopupComponent from "../common/PopupComponent";
import { useFormik } from 'formik';
import * as Yup from "yup";
import Swal from "sweetalert2";
import $ from "jquery";
import DatePicker from 'react-datepicker';
import { FORM_DATE_FORMAT } from '../../utils/Constants';
import { addDays } from '../../utils/commonFunction';
import { CopyCourses } from '../../services/CourseService';

export default function CopyCourse({courseId, refresh}) {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            course_id:courseId,
            name:"",
            start:"",
            end:"",
        },
        validationSchema: Yup.object({
            course_id:Yup.string().required("Course Id is required."),
            name : Yup.string().required("Name is required."),
            start : Yup.string().required("Start Date is required."),
            end : Yup.string().required("End Date is required."),
        }),
        onSubmit:(values, {resetForm}) =>{
            setLoading(true)
            const start_date = new Date(values.start).getTime().toString() / 1000;
            const end_date = new Date(values.end).getTime().toString() / 1000;
            
            if (start_date > end_date) {
                formik.setErrors({
                    start: "Start date should be less than end date",
                });
                return;
            }
            const sendData = {
                course_id:values.course_id,
                name:values.name,
                start:start_date,
                end:end_date
            }
            console.log(sendData)
            CopyCourses(sendData).then((res)=>{
                if(res.status===200){
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Course Copied Successfully",
                    });
                    refresh();
                }
            }).catch((err)=>{
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                });
            }).finally(()=>{
                resetForm();
                $("#copyCourse").modal("hide");
                setLoading(false)
            })

        }
    });

    const handleClose = () =>{
        formik.resetForm();        
    }

    return (
        <PopupComponent 
            id="copyCourse"
            type='form'
            closeFunction={()=>{handleClose()}}
            header={{iconName:"fa-copy", heading:"Copy Course"}}
            inputs={[
                <>
                    <div className="col-lg-12">
                        <div className="form-group-blk mb-3">
                            <label>Course Name *</label>
                            <input
                            className={"form-control " + (formik.errors.name && formik.touched.name ? " is-invalid" : "")}
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}                            
                            onBlur={formik.handleBlur}
                            placeholder={"Enter Course Name"}
                            title="Course Name"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group-blk mb-3">
                            <label>Start Date *</label>
                            <DatePicker
                            selected={formik.values.start}
                            dateFormat={FORM_DATE_FORMAT}
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormatCalendar="MMMM"
                            className={
                                "form-control cursor-pointer" +
                                (formik.errors.start &&
                                formik.touched.start
                                ? " is-invalid"
                                : "")
                            }
                            title="Start Date"
                            placeholderText="Select Start Date"
                            onChange={(date) => {
                                formik.setFieldValue("start", date);
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            minDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group-blk mb-3">
                            <label>End Date *</label>
                            <DatePicker
                            selected={formik.values.end}
                            dateFormat={FORM_DATE_FORMAT}
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormatCalendar="MMMM"
                            className={
                                "form-control cursor-pointer" +
                                (formik.errors.end &&
                                formik.touched.end
                                ? " is-invalid"
                                : "")
                            }
                            title="End Date"
                            placeholderText="Select End Date"
                            timeIntervals={15}
                            onChange={(date) => {
                                formik.setFieldValue("end", date);
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            minDate={formik.values.start}
                            />
                        </div>
                    </div>
                </>
            ]}
            bottomButton={[
                <button type="submit" className="btn btn-save btn-success" title="Save" onClick={()=>{formik.handleSubmit()}} disabled={loading}>
                    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
                </button>,
                <button type="reset" title="Cancel" className="btn btn-danger" data-dismiss="modal" onClick={() =>{handleClose()}}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>,
                Object.keys(formik.values).map((key, ind) => {
                  if (formik.touched[key] && formik.errors[key]) {
                  return (
                      <div className="invalid-feedback d-block" key={ind}>
                      {formik.errors[key]}
                      </div>
                  )
                  }
                })
              ]}
              otherProps={{secondPopupCss: "zindexadd"}}
        />
    )
}
