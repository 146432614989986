import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../loaders/SkeletonTicketList'
import { TABLE_ROWS_PER_PAGE } from '../../utils/Constants';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import Str from './Str';
import Tablefilter from './Tablefilter';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import { handleTableScroll } from '../../utils/commonFunction';

const defaultExportFunction = (fileType) => {
    let data = [];
    const header = [];
    if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
        });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = "fileName";
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
            URL.revokeObjectURL(blobURL);
        }, 1000);
    } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
                obj[col] = row[col];
            });
            return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${"fileName"}.xlsx`);

        return false;
    }
    if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
            return [];
        });
        const doc = new JsPDF();
        doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
                minCellHeight: 10,
                minCellWidth: 5,
                halign: "left",
                fontSize: 8,
            },
        });
        doc.save(`${"fileName"}.pdf`);

        return false;
    }
}

function DataTableComponent({ data = [], loading = false, state = {}, setState = () => { }, setSearch = () => { }, totalRows = 0, columns = [], exportFunction = defaultExportFunction, exportFileName = "", filters = [], tableButton = [], isInsidePopUp = false, bottomButton = [], otherTableProps = {}, isActionAlignRight = false }) {

    let page = state?.page;
    let perPage = state?.perPage;
    let sortKey = state?.sortKey;

    const [debouncedTerm, setDebouncedTerm] = useState("")

    const handlePageChange = (pageNo) => {
        setState((prevState) => ({ ...prevState, page: pageNo }));
    };

    const handlePerRowsChange = (newPerPage) => {
        setState((prevState) => ({ ...prevState, perPage: newPerPage }));
    };

    const handleSort = (column, sortDirection) => {
        setState((prevState) => ({ ...prevState, sortKey: column.sortField, sortOrder: sortDirection === "asc" ? "asc" : "desc" }));
    };

    const resetFilter = () => {
        filters.map(item => {
            if(!item.notReset){
                item.setState({ arr: [], checkObj: item.isSearchFilter ? [] : {} })
            }
        })
        setSearch("")
        setDebouncedTerm("")
    }

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className={isInsidePopUp ? "l-o-c-t custom-table-div filter-search-icon card-table-custom course-table-filter-sec new-table-box" : `custom-table-div filter-search-icon card card-table-custom program-table ${isActionAlignRight ? "log-copy-table" : ""}`}>
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={e => setDebouncedTerm(e.target.value)}
                                                value={debouncedTerm}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                {GlobalIconNames("filter")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        {filters.map((item, index) =>
                                            <div key={index}>
                                                <Tablefilter {...item} />
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            {GlobalIconNames("reset").html}
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportFunction("xlsx", exportFileName);
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            {GlobalIconNames("excel")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportFunction("csv", exportFileName);
                                            }}
                                            title="Export CSV"
                                        >
                                            {GlobalIconNames("csv")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportFunction("pdf", exportFileName);
                                            }}
                                            title="Export PDF"
                                        >
                                            {GlobalIconNames("pdf")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                {tableButton.map((item, index) => (
                                    <div key={index}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* {loading ? <SkeletonTicketList /> :  */}
                <DataTable
                    paginationDefaultPage={page}
                    data={data}
                    defaultSortField={sortKey}
                    // sorting doesn't work properly if loading is not added like this
                    progressPending={loading}
                    progressComponent={<SkeletonTicketList />}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    noDataComponent={Str.noRecord}
                    onSort={handleSort}
                    sortServer
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    {...otherTableProps}
                />
                {bottomButton.map((item, index) => (
                    <div key={index}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DataTableComponent