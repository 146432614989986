import axiosInstance from "../utils/axios";

export const GetGradeMixList = async (data) => {
    return await axiosInstance().post(`/getGradeMixList`, data);
};

export const AddGradeMix = async (data) => {
    return await axiosInstance().post(`/addGradeMix`, data);
};

export const EditGradeMix = async (data) => {
    return await axiosInstance().post(`/editGradeMix`, data);
};

export const GetGradeMixDetails = async (data) => {
    return await axiosInstance().get(`/getGradeMixDetails`, {params: data});
};

export const gradebookOfgradeMix = async (data) => {
    return await axiosInstance().post(`/gradebookOfgradeMix`, data);
};

export const getAssessmentSubmissionResultGrademix = async (data) => {
    return await axiosInstance().post(`/getAssessmentSubmissionResultGrademix`, data);
};

export const getGradeMixAssessments = async (id) => {
    return await axiosInstance().get(`/getGradeMixAssessments?id=${id}`);
};

export const getGrademixListViaAssessment = async (id) => {
    return await axiosInstance().get(`/getGrademixListViaAssessment?id=${id}`);
};

export const updateGradeMixScore = async (data) => {
    return await axiosInstance().post(`/updateGradeMixScore`, data);
};