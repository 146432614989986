import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GetClassRecordings } from "../../../../services/CourseService";
import $ from 'jquery';
import Str from "../../../common/Str";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";

const ClassRecordingModal = (props) => {

  const [recordings, setRecordings] = useState([]);
  const [isRecordingData, setIsRecordingData] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    handleTableScroll()
  }, [isRecordingData])

  useEffect(() => {
    getRecordings();
  }, [props.classId])

  const getRecordings = () => {
    setIsRecordingData(true);
    GetClassRecordings(props.classId).then(data => {
      setLink("");
      setRecordings([]);
      setRecordings(data && data.data && data.data.recording_classes ? data.data.recording_classes : []);setIsRecordingData(false);
    }).catch(error => {
      console.log("error ---> ", error);
      setIsRecordingData(false);
    })
  };

  const dataToRender = () => {
    // console.log("recordingds",recordings);
    return recordings;
  }

  function getLink(data) {
    $('#linkModal').modal('show');
    if (data.video_type == 0 && data.file_type == "Manually") {
      setLink("http://vimeo.com/" + data.name);
    } else if (data.video_type == 0 && data.file_type == "Recording") {
      setLink(data.record_url);
    } else if (data.video_type == 1) {
      setLink("http://vimeo.com/" + data.name);
    } else if (data.video_type == 2) {
      setLink(data.name);
    }
  }

  function openLink(data) {
    if (data.video_type == 0 && data.file_type == "Manually") {
      window.open("http://vimeo.com/" + data.name, "_blank");
    } else if (data.video_type == 0 && data.file_type == "Recording") {
      window.open(data.record_url);
    } else if (data.video_type == 1) {
      window.open("http://vimeo.com/" + data.name, "_blank");
    } else if (data.video_type == 2) {
      window.open(data.name, "_blank");
    }
  }

  const columns = [
    {
      name: "Type",
      selector: "file_type",
      sortable: true,
      selector: (row) => (
        <p>{row.file_type == "Manually" ? "Manual Upload" : row.file_type == "Recording" ? "Recording" : "-"}</p>
      ),
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => (
        <p>{row.name ? row.name : "-"}</p>
      ),
    },
    {
      name: "Recorded Date",
      selector: "date_recorded",
      sortable: true,
      selector: (row) => (
        <>{row.date_recorded ? <div className="dateTime">
          <p className="right-space">{moment(row.date_recorded).format(TABLE_DATE_FORMAT)}</p>
          <p>{moment(row.date_recorded).format(TABLE_TIME_FORMAT)}</p>
        </div> : "-"}</>
      )
    },
    {
      name: "Duration",
      selector: "duration",
      sortable: true,
      selector: (row) => (
        <p>{row.duration || row.duration == 0 ? row.duration : "-"}</p>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button type="button" className="btn btn-primary rounded-circle" title="View" onClick={() => { openLink(row) }}>
              <i className="fal fa-eye"></i>
            </button>
            <button type="button" className="btn btn-danger rounded-circle" title="Delete">
              <i className="fal fa-trash-alt"></i>
            </button>
            {/* <button type="button" className="btn btn-primary rounded-circle" title="Link" onClick={() => closeModal()} > */}
            <button type="button" className="btn btn-primary rounded-circle" title="View" onClick={() => { getLink(row) }} >
              {/* data-toggle="modal" data-target="#linkModal" */}
              <i className="fal fa-link"></i>
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
          <div className="custom-table-div filter-search-icon card-table-custom ">
            {isRecordingData ? <SkeletonTicketList /> :
            <DataTable
              columns={columns}
              noDataComponent={Str.noRecord}
              data={dataToRender()}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />}
          </div>
        </div>
     

      <div className="topic-add-modal modal zindexadd fade" id="linkModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-copy"></i> Make sure you copy the link below
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-9">
                      <div className="form-group-blk mb-3">
                        <input type="text" className="form-control" name="name" title="Name" placeholder="Name" value={link} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button type="button" className="btn btn-primary ml-2" onClick={() => { navigator.clipboard.writeText(link) }}>
                        <i className="fal fa-copy"></i>Copy
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button type="button" className="btn btn-danger" data-dismiss="modal">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassRecordingModal;
