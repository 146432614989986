import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PopupComponent from "../../common/PopupComponent";
import OnlineClassFunctions from "./OnlineClassFunctions";
import { AverageClassRatingBlock, ClassAttendanceBlock, CourseInfoBlock, LaunchPopupBlock, OnlineClassesBlock, SubmissionSummaryBlock, TodayClassesBlock, UpcomingImpDateBlock } from "./OnlineClassBlocks";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";

const DashBoard = (props) => {

  const [basicInfo, setBasicInfo] = useState({})
  const [upcomingImpDate, setUpcomingImpDate] = useState([])
  const [onlineClass, setOnlineClass] = useState([])
  const [avgRatingArr, setAvgRatingArr] = useState([]);
  const [attendanceArr, setAttendanceArr] = useState([])
  const [onlineClassSelectedDays, setOnlineClassSelectedDays] = useState(7);
  const [averageClassRatingDays, setAverageClassRatingDays] = useState(7);
  const [attendanceDays, setAttendanceDays] = useState(7)
  const [summarySelectedDays, setSummarySelectedDays] = useState(7);
  const [launchLoading, setLaunchLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [launchDetail, setLaunchDetail] = useState({});
  const [analysisBtn, setAnalysisBtn] = useState({ disable: true, link: "" });
  const [loader, setLoader] = useState([]);
  const [portalId, setPortalId] = useState("");
  const [todayClasses, setTodayClasses] = useState([])
  const [classId, setClassId] = useState(null);
  const [recurring, setRecurring] = useState("");
  const [avgRating, setAvgRating] = useState(0);
  const [submissionSummaryData, setSubmissionSummaryData] = useState([])
  const [updateTodayClassesList, setUpdateTodayClassesList] = useState(true);
  const [upcomingLoading, setUpcomingLoading] = useState(false)
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const [todayClassesLoading, setTodayClassesLoading] = useState(false);
  const [avgClassLoading, setAvgClassLoading] = useState(false)
  const [onlineClassLoading, setOnlineClassLoading] = useState(false)
  const [classAttendanceLoading, setClassAttendanceLoading] = useState(false)
  const { id } = useParams();

  useEffect(() => {
    OnlineClassFunctions({ setUpcomingImpDate }).UpcomingImpDateFunction(id, setUpcomingLoading)
    OnlineClassFunctions({ setBasicInfo }).getFirstGraphData(props.userId);
  }, [])

  useEffect(() => {
    OnlineClassFunctions({ setTodayClasses }).TodayClasses({
      pageNo: 1,
      limit: 4,
      search: "",
      key: "date",
      sort: "ASC",
      showing: [10],
      course_id: id,
      viaDate: [["Today"]]
    }, setTodayClassesLoading)
  }, [updateTodayClassesList])


  // average class rating api
  useEffect(() => {
    OnlineClassFunctions({ setSubmissionSummaryData }).SubmissionSummaryFunction(id, summarySelectedDays, setSubmissionLoading)
  }, [summarySelectedDays])

  // average class rating api
  useEffect(() => {
    OnlineClassFunctions({ setAvgRatingArr, setAvgRating }).AverageRating(id, averageClassRatingDays, setAvgClassLoading)
  }, [averageClassRatingDays])

  // Online class api
  useEffect(() => {
    OnlineClassFunctions({ setOnlineClass }).OnlineClassStats(id, onlineClassSelectedDays, setOnlineClassLoading)
  }, [onlineClassSelectedDays])

  // Class Attendance api
  useEffect(() => {
    OnlineClassFunctions({ setAttendanceArr }).ClassAttendance(id, attendanceDays, setClassAttendanceLoading)
  }, [attendanceDays])

  useEffect(() => {
    if (submissionSummaryData.length) {
      let arrElements = document.getElementsByClassName("highcharts-credits")
      if (arrElements) {
        arrElements[0].style.display = "none"
      }

      setTimeout(() => {
        let totalValueElements = document.getElementsByClassName('g2-html-annotation')
        if (totalValueElements.length > 1) {
          let totalCount = 0
          submissionSummaryData.forEach(item => {
            if (item.value !== 0) {
              totalCount = totalCount + item.value
            }
          })
          totalValueElements[1].innerHTML = totalCount
        }
      }, 500);
    }
  }, [submissionSummaryData, submissionLoading])


  return (
    <>
      <div className="card-new card card-profile-info-card mb-30 course-dashborad">
        <CourseInfoBlock basicInfo={basicInfo} id={id} />
      </div>
      {/* First Row */}
      <div className="row">
        <UpcomingImpDateBlock upcomingImpDate={upcomingImpDate} loading={upcomingLoading} />
        <SubmissionSummaryBlock submissionSummaryData={submissionSummaryData} summarySelectedDays={summarySelectedDays} setSummarySelectedDays={setSummarySelectedDays} loading={submissionLoading} />
        <TodayClassesBlock todayClasses={todayClasses} setOnlineClass={setOnlineClass} setLaunchDetail={setLaunchDetail} setLaunchLoading={setLaunchLoading} setPortalId={setPortalId} id={id} setRecurring={setRecurring} setClassId={setClassId} setAnalysisBtn={setAnalysisBtn} updateTodayClassesList={updateTodayClassesList} setUpdateTodayClassesList={setUpdateTodayClassesList} loading={todayClassesLoading} />
      </div>
      {/* Second Row */}
      <div className="row">
        <AverageClassRatingBlock averageClassRatingDays={averageClassRatingDays} setAverageClassRatingDays={setAverageClassRatingDays} avgRatingArr={avgRatingArr} avgRating={avgRating} loading={avgClassLoading} />
        <OnlineClassesBlock onlineClass={onlineClass} onlineClassSelectedDays={onlineClassSelectedDays} setOnlineClassSelectedDays={setOnlineClassSelectedDays} loading={onlineClassLoading} />
        <ClassAttendanceBlock attendanceDays={attendanceDays} setAttendanceDays={setAttendanceDays} attendanceArr={attendanceArr} loading={classAttendanceLoading} />
      </div>
      <PopupComponent
        type="customModalBody"
        id="launch"
        header={{ iconName: "fa-tachometer-alt-average", heading: "Class Launcher" }}
        modalBody={!launchLoading ? <LaunchPopupBlock showMore={showMore} setShowMore={setShowMore} launchDetail={launchDetail} setLoader={setLoader} portalId={portalId} analysisBtn={analysisBtn} onlineClass={onlineClass} loader={loader} setAnalysisBtn={setAnalysisBtn} setUpdateTodayClassesList={setUpdateTodayClassesList} updateTodayClassesList={updateTodayClassesList} setLaunchDetail={setLaunchDetail} /> : <span className="p-30px"><SkeletonTicketList /></span>}
      />
    </>
  );
};

export default DashBoard;
