const initialState = {
  configuration: new Object(),
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BRAND":
      state.configuration = action.payload[0];
      return { configuration: state.configuration };
    case "CLEAR_BRAND":
      state.configuration = new Object();
      return { configuration: state.configuration };
    default:
      return state;
  }
};

export default brandReducer;
