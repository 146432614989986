import React, { createRef, useEffect, useState, useMemo } from "react";
import {
    GetListOfRole,
    GetUserDetail,
    UpdateUserDetail,
    GetListOfUser,
} from "../../../services/UserService";
import {
    ProfileImageDetailService,
    RemoveProfileImage,
} from "../../../services/ProfileService";
import { useFormik } from "formik";
import { MOBILE_REGEX, NAME_REGEX, IMAGE_URL, API_BASE, FORM_DATE_FORMAT } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
// import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import {
    AddStudentTabListData,
    GetListOfStudentHubBrandList,
    GetStudentTabListDetail,
    EditStudentTabListData,
    GetListOfStudentHubTabList,
    GetListOfStudentHubCategories,
    DeleteCategoryList,
    addResourceManagement,
    registrationStudentSearchList,
    issuedResource,
    getResourceDetails,
    GetCampusDropdown,
} from "../../../services/StudentService";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import { Link, useLocation, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import { getToken } from "../../../utils/Auth";
import { GetCommonContent } from "../../../services/ProgrammeServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import {
    EditorState,
    convertToRaw,
    RichUtils,
    CompositeDecorator,
    ContentState,
    convertFromHTML,
    convertFromRaw,
    AtomicBlockUtils
} from "draft-js";

import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";

import { Editor } from "react-draft-wysiwyg";
import { Hours, Minutes } from "../../systemadministration/DataReactSelect";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { id } from "date-fns/locale";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
registerPlugin(FilePondPluginImagePreview);
let Id = "";
let Action = "";
const ReturnResource = ({setResourceName}) => {
    const history = useHistory();
    const location = useLocation();
    const [userData, setUserData] = useState();
    const [visibleList, setVisibleList] = useState([
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ]);
    const [userList, setUserListData] = useState([]);
    const [tablistData, setTablistData] = useState([]);
    const [categorieslistData, setCategorieslistData] = useState([]);
    const [tabslistData, setTabslistData] = useState([]);
    const [linkedBrandList, setLinkedBrandList] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState([]);
    const [deleterow, setDeleteRow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [search, setSearch] = useState("");
    const [showFilePond, setShowFilePond] = useState(false);
    const filePondRef = createRef();
    const [files, setFiles] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [type, setType] = useState([]);
    const [condition, setCondition] = useState([]);
    const [campus, setCampus] = useState([]);
    const [language, setLanguage] = useState([]);
    const [removeFile, setRemoveFile] = useState(null);
    const [showcursure, setShowCurser] = useState(false)
    const [selectedHash, setSelectedHash] = useState([]);
    const [selectHash, setSelectedKeywordsHash] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectKeywords, setSelectedKeywordsKeywords] = useState([]);
    const [issueTo, setIssueTo] = useState([]);
    const [openDate, setOpenDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
    );
    const [isDisabled, setIsDisabled] = useState(false);

    const [editorState, setEditorState] = React.useState(() =>
        ""
    );
    const [basicInfo, setBasicInfo] = useState({})

    const [infoData, setInfoData] = useState({})
    // const [insertedId, setInsertedId] = useState("");
    if (history.location.state) {
        const { id, action } = history.location.state;
        Id = id;
        Action = action;
    }
    const { id } = useParams()

    const givenPermsisions = useSelector((state) => state.givenPermission);
    const CommonDropdownData = useSelector(state => state.registerDropdownValues && Object.keys(state.registerDropdownValues).length ?
    state.registerDropdownValues : {});

    function setDropdownData() {
        if (Object.keys(CommonDropdownData).length) {
            if (CommonDropdownData.LibraryResourceType) {
                setType(CommonDropdownData?.LibraryResourceType);                
            }
            if (CommonDropdownData.BookConditionsType) {
                setCondition(CommonDropdownData?.BookConditionsType)
            }
            if (CommonDropdownData.language) {
                setLanguage(CommonDropdownData?.language)
            }
            // if (CommonDropdownData.campus) {
            //     setCampus(CommonDropdownData.campus)
            // }
            setIsLoaded(true);
        }
    }
    useEffect(() => {
        let response = hasPermission({
        scopes: ["sresourcemedit"],
        permissions: givenPermsisions,
        });
        if (!response) {
        history.push("/noaccess");
        }
    }, []);

    useEffect(() => {
        setDropdownData()
        registrationStudentSearchList({type:"dropdown"}).then((resp) => {
            // console.log("data from registrationStudentSearchList", data);
            let respData = resp?.data?.data
            // let tempIssueTo = [];
            // issueTo.map((issue) => {
            //     tempIssueTo.push({ value: issue.id, label: issue.first_name + " " + issue.last_name })
            // })
            // console.log("tempIssueTo", tempIssueTo);
            setIssueTo([...respData])


            // setFilterData(data?.data);
        });
        GetCampusDropdown().then(res=>{
            if(res.status===200){
                setCampus(res.data.dropdown)
            }
        }).catch((err)=>console.log(err))
        getResourceDetails(id)
            .then((res) => {
                setBasicInfo(res.data?.resourceDetails)
                setResourceName(res.data?.resourceDetails?.title)
                if (res.data?.ResourceInformationDetails) {
                    setInfoData(res.data?.ResourceInformationDetails)

                }
            })
            .catch((err) => { console.log(err); });



    }, [])

    useEffect(() => {
        issueTo && issueTo.map((issue) => {
            if (issue.value == infoData.returned_by) {
                formik.setFieldValue("issuetoName", issue.label)
            }
        })

        campus && campus.map((campus) => {
            if (campus.value == infoData.campus) {
                formik.setFieldValue("campusName", campus.label)
            }
        })

    }, [issueTo, campus])



    const HANDLE_REGEX = /\@[\w]+/g;

    function handleStrategy(contentBlock, callback, contentState) {
        findWithRegex(HANDLE_REGEX, contentBlock, callback);
    }

    function findWithRegex(regex, contentBlock, callback) {
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    const [image, setImage] = useState();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: basicInfo?.title || "",
            subtitle: basicInfo?.sub_title || "",
            condition: infoData?.book_condition || "",
            duedate: infoData?.due_date && ((infoData?.due_date.includes("T") == false) && (infoData?.due_date != 0)) ? new Date(moment.unix(Number(infoData?.due_date))) : "",
            campus: basicInfo?.campus || "",
            campusName: "",
            issueto: infoData?.issu_to || "",
            issuetoName: infoData?.issuedToname || "",
            comments: "",
        },
        validationSchema: Yup.object({
            // title: Yup.string().required("Title is required"),
            // subtitle: Yup.string().required("Sub Title is required"),
            // condition: Yup.string().required("Condition is required"),
            duedate: Yup.string().required("Due Date is required"),
            campus: Yup.string().required("Campus is required"),
            issueto: Yup.string().required("Returned By is required"),
            // comments: Yup.string().required("Comments is required"),
        }),
        onSubmit: (values) => {
            setIsDisabled(true)
            let date = values.duedate

            if (values.duedate) {
                date = new Date(date).getTime().toString() / 1000;
            }
            //    console.log("date from onsSubmit", Date);
            // if (location.pathname == "/support/student-hub-library/student-hub/create") {
            values = { ...values }
            // const editorJSON = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            // console.log("editorJSON", editorJSON)
            let body;
            body = {
                // title: values.title,
                // sub_title: values.subtitle,
                book_condition: values.condition,
                returned_date: date,
                campus: values.campus,
                returned_by: values.issueto,
                comment: values.comments,
                resource_id: id,
                status: "returned",
                id: infoData.id
            }



            issuedResource(body)
                .then((res) => {
                    setIsDisabled(false)
                    // setInsertedId(res?.data?.inserted_record)
                    // Id = res?.data?.inserted_record;
                    // Action = "open";
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                        onClose: goToListing
                    });
                })
                .catch((err) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${err?.response?.data?.data}`,
                    });
                });

            // } 
            // else {
            //     if (Action && Action == "open") {
            //         values = { ...values, id: Id }
            //         EditStudentTabListData(values)
            //             .then((res) => {
            //                 Swal.fire({
            //                     icon: "success",
            //                     title: "Success",
            //                     text: "Updated successfully",
            //                 });
            //             })
            //             .catch((err) => {
            //                 Swal.fire({
            //                     icon: "error",
            //                     title: "Error",
            //                     text: `${err?.response?.data?.data}`,
            //                 });
            //             });
            //     } 
            //     else {
            //         values = { ...values }
            //         AddStudentTabListData(values)
            //             .then((res) => {
            //                 Swal.fire({
            //                     icon: "success",
            //                     title: "Success",
            //                     text: "Updated successfully",
            //                 });
            //             })
            //             .catch((err) => {
            //                 Swal.fire({
            //                     icon: "error",
            //                     title: "Error",
            //                     text: `${err?.response?.data?.data}`,
            //                 });
            //             });
            //     }
            // }
        },
    });

    const goToListing = () => {
        history.push(`/support/student-hub-library/resource-management/table`)
    }


    function addDays(date, days) {
        var date = new Date(date);
        date.setDate(date.getDate() + days);
        return date;
    }



    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    const handleUploadedFiles = (response) => {
        // setAttachments((attachments) => [...attachments, response]);
        // console.log('the uploaded file is like this ', response)
        let newObj = {}
        let blocks = isJsonString(response)
        if (blocks) {
            newObj = JSON.parse(response)
            setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
            setSizes((pre) => [...pre, newObj.size]);
        }
        // console.log('the new res is here ', newObj)
    };


    const handleKeyDown = (event) => {
        let hash = selectHash;
        if (event.key === "Enter") {
            if (event.target.value) {
                hash.push(event.target.value);
                event.target.value = ""
                setSelectedHash((hash) => [...hash]);
                formik.setFieldValue("hash", hash);
            }

        }
    };

    const handleKeyDown1 = (event) => {
        let keywords = selectKeywords;
        if (event.key === "Enter") {
            if (event.target.value) {
                keywords.push(event.target.value);
                event.target.value = ""
                setSelectedKeywords((keywords) => [...keywords]);
                formik.setFieldValue("keywords", keywords);
            }

        }
    };


    const removeHash = (index) => {
        let keyword = selectHash;
        let keywords = keyword.splice(index, 1)
        setSelectedHash((keyword) => [...keyword])
        formik.setFieldValue("hash", keyword);
    };

    const removeKeywords = (index) => {
        let keyword = selectKeywords;
        let keywords = keyword.splice(index, 1)
        setSelectedKeywords((keyword) => [...keyword])
        formik.setFieldValue("keywords", keyword);
    };




    // const onEditorStateChange = editorState => setEditorState(editorState);


    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: url })
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    };


    // const handlePastedFiles = async (files) => {
    //     getBase64(files[0])
    //         .then(
    //             data => {
    //                 console.log('the converted image is ', data)
    //                 setEditorState(insertImage(data))
    //             }
    //         )
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }


    function uploadImageCallBack(file) {
        return new Promise(
            (resolve, reject) => {
                getBase64(file)
                    .then(
                        data => {
                            resolve({ data: { link: data } })
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
            },
        );
    }




    return (
        <div className="card card-new card-profile-info-card">
                <div className="card-header">Return Resource</div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>

                        <div className="row">


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Title*</label>
                                    <div className="form-icon-group mb-4">
                                        <input type="text" className={"form-control" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")} name="title" placeholder="Title" title="Title" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} readOnly />
                                        {formik.errors.title && formik.touched.title ? (
                                            <span className="exclamation">
                                                <i className="fal fa-exclamation-circle"></i>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">

                                <div className="form-group">
                                    <label>Sub Title*</label>
                                    <div className="form-icon-group mb-4">
                                        <input type="text" className={"form-control" + (formik.errors.subtitle && formik.touched.subtitle ? " is-invalid" : "")} name="subtitle" placeholder="Sub Title" title="Sub Title" value={formik.values.subtitle} onChange={formik.handleChange} onBlur={formik.handleBlur} readOnly />
                                        {formik.errors.subtitle && formik.touched.subtitle ? (
                                            <span className="exclamation">
                                                <i className="fal fa-exclamation-circle"></i>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">

                                <div className="form-group" >
                                    <label>Campus</label>
                                    <div className="form-icon-group mb-4">
                                        <div className="form-control custom-select-box">

                                            <Select className={"form-control custom-select-box" + (formik.errors.campus && formik.touched.campus ? " is-invalid" : "")} name="campus"
                                                // value={type.filter((obj) => selectedDomain?.includes(obj.value))}
                                                value={campus?.filter(val=>val.value === formik.values.campus)}
                                                //  onChange={(value) => { onAdtypeHandleChange(value); }}
                                                onChange={(value) => {
                                                    if(value){
                                                        formik.setFieldValue("campus",value.value);
                                                    } else {
                                                        formik.setFieldValue("campus","");
                                                    }                                                                                                        
                                                }}
                                                placeholder={formik.values.campus && campus.length ? campus.filter(item => item.value==formik.values.campus)[0].label : "Select..."}
                                                onBlur={formik.handleBlur} options={campus} maxMenuHeight={175} isClearable />

                                            {formik.errors.campus && formik.touched.campus ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3">

                                <div className="form-group">
                                    <label>Due Date*</label>
                                    <DatePicker
                                        autoComplete="off"
                                        selected={formik.values.duedate}
                                        onChange={(date) => {
                                            formik.setFieldValue("duedate", date);
                                            setOpenDate(date);
                                        }}
                                        name="date"
                                        dateFormat={FORM_DATE_FORMAT}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dateFormatCalendar="MMMM"
                                        className={
                                            "form-control cursor-pointer" +
                                            (formik.errors.duedate &&
                                                formik.touched.duedate
                                                ? " is-invalid"
                                                : "")
                                        }
                                        //   disabled={!formik.values.date}
                                        title="Due Date"
                                        placeholderText="Due Date"
                                        timeIntervals={15}
                                        onChangeRaw={(e) => {
                                            e.preventDefault();
                                        }}
                                    // minDate={addDays(formik.values.startDate, 1)}
                                    />
                                </div>
                            </div>





                        </div>


                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Comments</label>
                                    <div className="form-icon-group mb-4">
                                        <input type="text" className={"form-control" + (formik.errors.comments && formik.touched.comments ? " is-invalid" : "")} name="comments" placeholder="Comments" Title="Comments" value={formik.values.comments} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.errors.comments && formik.touched.comments ? (
                                            <span className="exclamation">
                                                <i className="fal fa-exclamation-circle"></i>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>




                            <div className="col-md-3">

                                <div className="form-group" >
                                    <label>Returned By*</label>
                                    <div className="form-icon-group mb-4">
                                        <div className="form-control custom-select-box">

                                            <Select className={"form-control custom-select-box" + (formik.errors.issueto && formik.touched.issueto ? " is-invalid" : "")} name="issueto"
                                                // value={type.filter((obj) => selectedDomain?.includes(obj.value))}
                                                value={issueTo?.filter(val=>val.label === formik.values.issuetoName)}
                                                //  onChange={(value) => { onAdtypeHandleChange(value); }}
                                                onChange={(value) => {
                                                    if(value){
                                                        formik.setFieldValue("issueto",value.value);
                                                        formik.setFieldValue("issuetoName",value.label);
                                                    } else {
                                                        formik.setFieldValue("issueto","");
                                                        formik.setFieldValue("issuetoName","");
                                                    }                                                    
                                                }}
                                                placeholder={formik.values.issuetoName ? formik.values.issuetoName : "Select..."}
                                                onBlur={formik.handleBlur} options={issueTo} maxMenuHeight={175} isClearable />

                                            {formik.errors.issueto && formik.touched.issueto ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label>Condition</label>
                                    <div className="form-icon-group mb-4">
                                        <div className="form-control custom-select-box">

                                            <Select className={"form-control custom-select-box" + (formik.errors.condition && formik.touched.condition ? " is-invalid" : "")} name="condition"
                                                // value={type.filter((obj) => selectedDomain?.includes(obj.value))}
                                                value={condition?.filter(val=>val.value === formik.values.condition)}
                                                //  onChange={(value) => { onAdtypeHandleChange(value); }}
                                                onChange={(value) => {
                                                    if(value){
                                                        formik.setFieldValue("condition",value.value);                                                    
                                                    } else {
                                                        formik.setFieldValue("condition","");
                                                    }
                                                }}
                                                placeholder={formik.values.condition && condition.length ? (condition.filter(item=>item.value===formik.values.condition)[0]).label : "Select..."}
                                                onBlur={formik.handleBlur} options={condition} maxMenuHeight={175} isClearable />


                                            {formik.errors.condition && formik.touched.condition ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>




                        <div className="form-group-save-cancel ">
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                <button className="btn btn-save btn-success" type="button" onClick={formik.handleSubmit} title="Save" >
                                    {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                                    Save
                                </button>
                            </PermissionsGate>
                            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                            {/* <div className="form-group mr-3 ml-auto mt-4">
                                <div className="custom-control custom-checkbox text-left">
                                    <input type="checkbox" className="custom-control-input" id="published" name="published" checked={formik.values.published} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <label className="custom-control-label" htmlFor="published">
                                        Published{(formik.values.domain == "vimeo" || formik.values.domain == "link") && "?"}
                                    </label>
                                </div>

                            </div>
                            {formik.values.domain == "vimeo" && <div className="form-group  mt-4">
                                <div className="custom-control custom-checkbox text-left">
                                    <input type="checkbox" className="custom-control-input" id="featured" name="featured" checked={formik.values.featured} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <label className="custom-control-label" htmlFor="featured">
                                        Featured?
                                    </label>
                                </div>

                            </div>} */}
                        </div>

                        <div className="form-group form-group-save-cancel ml-new">


                            {formik.touched.title && formik.errors.title ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.title}
                                </div>
                            ) : null}
                            {formik.touched.subtitle && formik.errors.subtitle ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.subtitle}
                                </div>
                            ) : null}
                            {formik.touched.condition && formik.errors.condition ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.condition}
                                </div>
                            ) : null}
                            {formik.touched.campus && formik.errors.campus ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.campus}
                                </div>
                            ) : null}
                            {formik.touched.duedate && formik.errors.duedate ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.duedate}
                                </div>
                            ) : null}
                            {formik.touched.issueto && formik.errors.issueto ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.issueto}
                                </div>
                            ) : null}
                            {formik.touched.comments && formik.errors.comments ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.comments}
                                </div>
                            ) : null}

                        </div>
                    </form>
                </div>
        </div>
    );
};

export default ReturnResource;
