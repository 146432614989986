import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from "react-data-table-component";
import { GetNewRoles, UpdateNewRolePermission } from '../../../services/PermissionService';
import PermissionsGate from '../../../utils/permissionGate';
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";

const NewRolesEdit = (props) => {

    const { id } = useParams();

    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [perWithName, setPerWithName] = useState([]);
    const [givenPermission, setGivenPermission] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [unSelectedIds, setUnSelectedIds] = useState([]);
    const [auditTrails, setAuditTrails] = useState({ old: {}, new: {} });
    const [isAuditTrails, setIsAuditTrails] = useState(false);

    const [loading, setloading] = useState(true);

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {
        getNewRolesData();
    }, [])

    function getNewRolesData() {
        setSelectedIds([]);
        setUnSelectedIds([]);
        setPerWithName([]);
        setGivenPermission([]);
        setAuditTrails({ old: {}, new: {} });
        GetNewRoles(id).then((data) => {
            // setPerWithName(data && data.data && data.data.permissions ? data.data.permissions : []);
            setPerWithName(data && data.data && data.data.permissions ? reArrangePermissions(data.data.permissions) : []);
            setGivenPermission(data && data.data && data.data.givenPermission ? data.data.givenPermission : []);
            setloading(false);
        }).catch((err) => {
        })
    }

    function reArrangePermissions(arr = []) {
        let arrangedPer = [];
        arr.map((elem, ind) => {
            if (elem.permission_group == 0) {
                arrangedPer.push(elem);
                arr.map((e, i) => {
                    if (elem.id == e.permission_group) {
                        arrangedPer.push(e);
                    }
                })
            }
        })
        if (arr.length === arrangedPer.length)
            return arrangedPer;
    }

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    // console.log("givenPermission ----> ", givenPermission);

    const dataToRender = () => {
        // return perWithName
        // console.log(" ------> ", perWithName);
        let updatedData = [];
        let allData = perWithName;
        if (search.length) {
            let role_name = allData.filter((item) => {
                let includes = item.name.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let data = [...role_name];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search.length) {
            return updatedData;
        } else {
            return perWithName;
        }
    };


    const columns = useMemo(() => [
        {
            name: "Module",
            selector: "name",
            sortable: false,
            // minWidth: "400px",
            cell: (row, index) => (
                <p>
                    {row.permission_group == 0 ? <span className="headingPermission"><b>{row.name}</b></span> : <span className="sub-menu-checkBox">{row.name}</span>}
                </p>
            ),
        },
        {
            name: "View",
            selector: "view",
            sortable: false,
            maxWidth: "250px",
            cell: (row, index) => (
                <p>
                    {row.permission_group !== 0 && row && row.groupPermissions && <>
                        {row.groupPermissions.map(elem => (
                            <>
                                {elem.permission_order == 1 &&

                                    <PermissionsGate errorProps={{ disabled: true }} scopes={["pmedit"]}>
                                        <input key="view-1" type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id, row.groupPermissions)} />
                                    </PermissionsGate>

                                }
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Add",
            selector: "add",
            sortable: false,
            maxWidth: "250px",
            cell: (row, index) => (
                <p>
                    {row.permission_group !== 0 && row && row.groupPermissions && <>
                        {row.groupPermissions.map(elem => (
                            <>
                                {elem.permission_order == 2 &&
                                    <PermissionsGate errorProps={{ disabled: true }} scopes={["pmedit"]}>
                                        <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id, row.groupPermissions)} />
                                    </PermissionsGate>
                                }
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Edit",
            selector: "menuEdit",
            sortable: false,
            maxWidth: "250px",
            // justifyContent: "left",
            cell: (row, index) => (
                <p>
                    {row.permission_group !== 0 && row && row.groupPermissions && <>
                        {row.groupPermissions.map(elem => (
                            <>
                                {elem.permission_order == 3 &&
                                    <PermissionsGate errorProps={{ disabled: true }} scopes={["pmedit"]}>
                                        <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id, row.groupPermissions)} />
                                    </PermissionsGate>
                                }
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Delete",
            selector: "delete",
            sortable: false,
            maxWidth: "250px",
            // justifyContent: "left",
            cell: (row, index) => (
                <p>
                    {row.permission_group !== 0 && row && row.groupPermissions && <>
                        {row.groupPermissions.map(elem => (
                            <>
                                {elem.permission_order == 4 &&
                                    <PermissionsGate errorProps={{ disabled: true }} scopes={["pmedit"]}>
                                        <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id, row.groupPermissions)} />
                                    </PermissionsGate>
                                }
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
    ])

    const checkAllCheckboxes = (id, order_type) => {
        let arr = [];
        let checked = !givenPermission.length ? false : true;
        perWithName.map((e, i) => {
            if (e.permission_group == id) {
                if (e.groupPermissions.length) {
                    e.groupPermissions.map((elem, ind) => {
                        if (elem.permission_order === order_type) {
                            arr.push(elem);
                        }
                    })
                }
            }
            if (i == perWithName.length - 1) {
                let givenPermissionIds = [];
                givenPermission.map((element, index) => {
                    givenPermissionIds.push(element.item_id);
                    if (index == givenPermission.length - 1) {
                        arr.map((elem, ind) => {
                            if (!givenPermissionIds.includes(elem.permission_id)) {
                                checked = false
                            }
                        })
                    }
                })
            }
        })
        return checked;
    }

    const markAllCheckboxes = (id, order_type) => {
        let arr = [];
        perWithName.map((e, i) => {
            if (e.permission_group == id) {
                if (e.groupPermissions.length) {
                    e.groupPermissions.map((elem, ind) => {
                        if (elem.permission_order === order_type) {
                            arr.push(elem);
                        }
                    })
                }
            }

            if (i == perWithName.length - 1) {
                if (!givenPermission.length) {
                    let ids = [];
                    let dataArr = [];
                    arr.map((elem, ind) => {
                        ids.push(elem.permission_id);
                        dataArr.push({ role_id: props.data, item_id: elem.permission_id })
                        if (ind === arr.length - 1) {
                            setGivenPermission([...givenPermission, ...dataArr])
                            setSelectedIds([...selectedIds, ...ids]);
                        }
                    })
                    return;
                }
                let givenPermissionIds = [];
                let checked = true;
                let record = [];
                givenPermission.map((element, index) => {
                    givenPermissionIds.push(element.item_id);
                    if (index == givenPermission.length - 1) {
                        arr.map((elem, ind) => {
                            if (!givenPermissionIds.includes(elem.permission_id)) {
                                checked = false;
                                record.push(elem);
                            }
                            if (arr.length - 1 == ind) {
                                if (checked === false) {
                                    let ids = [];
                                    let tempArr = [];
                                    record.map((rec, ind) => {
                                        tempArr.push({ item_id: rec.permission_id, role_id: props.data });
                                        ids.push(rec.permission_id);
                                        if (ind == record.length - 1) {
                                            setGivenPermission([...givenPermission, ...tempArr])
                                            setSelectedIds([...selectedIds, ...ids]);
                                        }
                                    })
                                } else {
                                    let ids = [];
                                    let permission_id = [];
                                    arr.map((i, ind) => {
                                        permission_id.push(i.permission_id);
                                        let tempArr = givenPermission;
                                        let newArr = [];
                                        if (ind == arr.length - 1) {
                                            givenPermission.map((post, index) => {
                                                if (post && permission_id.includes(post.item_id)) {
                                                    ids.push(post.id)
                                                } else {
                                                    newArr.push(post)
                                                }
                                                if (index == givenPermission.length - 1) {
                                                    setGivenPermission([...newArr])
                                                    setUnSelectedIds([...unSelectedIds, ...ids]);
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            }
        })
    }

    const checkCheckbox = (permission_id) => {
        var __FOUND = givenPermission.find(function (post, index) {
            if (post.item_id == permission_id) return true;
        })
        if (__FOUND === undefined) {
            return false;
        } else {
            return true;
        }
    }

    // console.log("given Permissions ---> ", givenPermission);
    // console.log("selectedIds =====> ", selectedIds);

    const checkUncheck = (permission_id, obj = {}) => {
        // console.log("permission Id : ", permission_id);
        // console.log("obj -==-=-> ", obj);
        auditTrailsOldData(obj, permission_id)
        let tempid;
        var __Found = givenPermission.find(function (post, index) {
            if (post.item_id == permission_id) {
                tempid = post.id;
                givenPermission.splice(index, 1);
                setGivenPermission([...givenPermission]);
                let ind = selectedIds.indexOf(permission_id);
                // console.log("ind -=-=-=-=> ", ind);
                selectedIds.splice(ind, 1);
                setSelectedIds([...selectedIds]);
                dataToRender();
                return post;
            }
        })

        if (__Found === undefined) {
            setGivenPermission([...givenPermission, { role_id: id, item_id: permission_id }]);
            setSelectedIds([...selectedIds, permission_id]);
        } else {
            if (tempid !== undefined)
                setUnSelectedIds([...unSelectedIds, tempid]);
        }
    }

    const auditTrailsOldData = (permissionGrp, permission_id) => {
        // console.log("permissionGrp --> ", permissionGrp);
        // console.log("givenPermission ----> ", givenPermission);
        // console.log(" ------> ", perWithName);
        let permission_group_id = permissionGrp[0].permission_group_item_id;
        let permissionName = "";
        perWithName.map((elem) => {
            if (elem.id === permission_group_id) {
                permissionName = elem.name;
            }
        })
        let permissionIds = [];
        givenPermission.map(elem => {
            permissionIds.push(elem.item_id)
        })
        let oldObj = auditTrails.old[permissionName] ? auditTrails.old[permissionName] : {};
        let newObj = auditTrails.new[permissionName] ? auditTrails.new[permissionName] : {};
        // console.log(">>>>>>>>>", oldObj);
        // console.log(">>>>>>>>>", newObj);
        permissionGrp.map(elem => {
            if (elem.permission_order === 1) {
                if (!auditTrails.old[permissionName]) {
                    if (permissionIds.includes(elem.permission_id)) {
                        oldObj['View'] = true;
                        newObj['View'] = true;
                    } else {
                        oldObj['View'] = false;
                        newObj['View'] = false;
                    }
                }
                if (elem.permission_id == permission_id) {
                    // if (permissionIds.includes(elem.permission_id)) {
                    newObj['View'] = !newObj['View'];
                }
                //     if (oldObj.View == newObj.View) {
                //         delete newObj['View'];
                //     }
                // }
            }
            if (elem.permission_order === 2) {
                if (!auditTrails.old[permissionName]) {
                    if (permissionIds.includes(elem.permission_id)) {
                        oldObj['Add'] = true;
                        newObj['Add'] = true;
                    } else {
                        oldObj['Add'] = false;
                        newObj['Add'] = false;
                    }
                }
                if (elem.permission_id == permission_id) {
                    // if (permissionIds.includes(elem.permission_id)) {
                    newObj['Add'] = !newObj['Add'];
                }
                // else {
                //     newObj['Add'] = false;
                // }
                //     if (oldObj.Add == newObj.Add) {
                //         delete newObj['Add'];
                //     }
                // }
            }
            if (elem.permission_order === 3) {
                if (!auditTrails.old[permissionName]) {
                    if (permissionIds.includes(elem.permission_id)) {
                        oldObj['Edit'] = true;
                        newObj['Edit'] = true;
                    } else {
                        oldObj['Edit'] = false;
                        newObj['Edit'] = false;
                    }
                }
                // if (permissionIds.includes(elem.permission_id)) {
                if (elem.permission_id == permission_id) {
                    newObj['Edit'] = !newObj['Edit'];
                }
                // else {
                // }
                //     if (oldObj.Edit == newObj.Edit) {
                //         delete newObj['Edit'];
                //     }
                // }
            }
            if (elem.permission_order === 4) {
                if (!auditTrails.old[permissionName]) {
                    if (permissionIds.includes(elem.permission_id)) {
                        oldObj['Delete'] = true;
                        newObj['Delete'] = true;
                    } else {
                        oldObj['Delete'] = false;
                        newObj['Delete'] = false;
                    }
                }
                // if (permissionIds.includes(elem.permission_id)) {
                if (elem.permission_id == permission_id) {
                    newObj['Delete'] = !newObj['Delete'];
                }
                //     if (oldObj.Delete == newObj.Delete) {
                //         delete newObj['Delete'];
                //     }
                // }
            }
        })
        // console.log(">>>>>>>>>", oldObj);
        // console.log(">>>>>>>>>", newObj);
        // if (!newObj.Add && !newObj.Edit && !newObj.Delete && !newObj.view) {
        //     let tempObj = auditTrails;
        //     delete tempObj.new[permissionName];
        //     delete tempObj.old[permissionName];
        //     setAuditTrails(tempObj);
        // } else {
        setAuditTrails({ ...auditTrails, old: { ...auditTrails.old, [permissionName]: oldObj }, new: { ...auditTrails.new, [permissionName]: newObj } })
        // }
    }

    const submit = () => {
        let obj = {
            role_id: id,
            item_id: selectedIds,
            id: unSelectedIds,
            auditTrails: auditTrails,
            name: props.roleName ? props.roleName : "",
            url: props.prevUrl ? props.prevUrl : "",
        }

        // console.log("new data >>>>>> ", newData);
        updatePermission(obj);
    }

    const updatePermission = (obj) => {
        UpdateNewRolePermission(obj).then((data) => {
            getNewRolesData();
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Updated successfully",
            }).then((result) => { });
        }).catch((err) => {
        })
    }

    const resetFilter = () => {
        setSearch("");
    };

    /**
     * Function to export the table data in pdf, csv, xlsx format
     * 
     * @param {*} fileType 
     * @param {*} fileName 
     * @returns 
     */
    const modifiedData = () => {
        let data = dataToRender();

        let arr = [];
        data = data?.map((row, index) => {
            let obj = { Module: row.permission_group === 0 ? row.name : " - " + row.name };
            if (row.permission_group === 0) {
                if (checkAllCheckboxes(row.id, 1)) {
                    obj['View'] = '1';
                } else {
                    obj['View'] = '0'
                }
                if (checkAllCheckboxes(row.id, 2)) {
                    obj['Add'] = '1';
                } else {
                    obj['Add'] = '0'
                }
                if (checkAllCheckboxes(row.id, 3)) {
                    obj['Edit'] = '1';
                } else {
                    obj['Edit'] = '0'
                }
                if (checkAllCheckboxes(row.id, 4)) {
                    obj['Delete'] = '1';
                } else {
                    obj['Delete'] = '0'
                }
                arr.push(obj)
            } else {
                if (row && row.groupPermissions && row.groupPermissions.length) {
                    row.groupPermissions.map((elem, i) => {
                        if (elem.permission_order === 1) {
                            if (checkCheckbox(elem.permission_id) === true) {
                                obj["View"] = "1";
                            } else {
                                obj["View"] = "0";
                            }
                        }
                        if (elem.permission_order === 2) {
                            if (checkCheckbox(elem.permission_id) === true) {
                                obj["Add"] = "1";
                            } else {
                                obj["Add"] = "0";
                            }
                        }
                        if (elem.permission_order === 3) {
                            if (checkCheckbox(elem.permission_id) === true) {
                                obj["Edit"] = "1";
                            } else {
                                obj["Edit"] = "0";
                            }
                        }
                        if (elem.permission_order === 4) {
                            if (checkCheckbox(elem.permission_id) === true) {
                                obj["Delete"] = "1";
                            } else {
                                obj["Delete"] = "0";
                            }
                        }
                        if (row.groupPermissions.length - 1 == i) arr.push(obj)
                    })
                }
            }
        })
        return arr
    }

    

    const exportData = async (fileType, fileName) => {
        let data = modifiedData();
        // return
        const header = ["Module", "Add", "View", "Edit", "Delete"];

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            // console.log("data =====> ", data);
            // return;
            const compatibleData = data.map((row) => {
                return [row.Module, row.View, row.Add, row.Edit, row.Delete];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };
  
    return (
        <div className="new-sec-permission-mgt">
            <div class="custom-table-div filter-search-icon card card-table-custom">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}> 
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button className="btn btn-primary" onClick={resetFilter}>
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Roles"); }} >
                                            <i className="fal fa-file-excel"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Roles"); }} >
                                            <i className="fal fa-file-csv"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Roles"); }} >
                                            <i className="fal fa-file-pdf"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {loader && <SkeletonTicketList />} */}

                {loading ? <SkeletonTicketList />
                    : 
                    <DataTable 
                    data={dataToRender()} 
                    columns={columns} 
                    defaultSortField="role" 
                    defaultSortAsc={false} 
                    pagination={false} 
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE} 
                    />
            
                }
                <div className="add-ticket-blk dropdown-comman form-group-save-cancel sticky-save-footer">               

                    <button className="btn btn-primary" onClick={() => { submit() }}>
                        <i className="fal fa-save"></i>Save
                    </button>
                    <button className="btn btn-close btn-danger" onClick={() => { getNewRolesData() }}>
                        <i className="fa fa-times"></i>Cancel
                    </button>
                </div>

            </div>
        </div>
    )
}

export default NewRolesEdit;