import axiosInstance from "../utils/axios";

export const GetListOfUser = async (data) => {
  return await axiosInstance().post(`/userList`, data);
};

export const GetListOfUserCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/userList`, values, {
    cancelToken: cancelToken
  });
};

export const GetListOfUserAuditTrail = async () => {
  return await axiosInstance().post(`/listUserData`, {});
};

export const GetListOfUserAuditTrailCancelToken = async (cancelToken) => {
  return await axiosInstance().post(`/listUserData`, {}, {
    cancelToken: cancelToken
  });
};

export const getStudentAuditTrail = async () => {
  return await axiosInstance().post(`/getStudentAuditTrail`, {});
};

export const AddDetail = async (values) => {
  return await axiosInstance().post(`/addUser`, values);
};

export const GetUserDetail = async (values) => {
  return await axiosInstance().post(`/getUserDetail`, values);
};

export const GetUserRoles = async () => {
  return await axiosInstance().get(`/getUserRoles`);
};

export const GetListOfRole = async () => {
  return await axiosInstance().get(`/listRole`);
};

export const GetListOfRoleCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listRole`, {
    cancelToken: cancelToken
  });
};

export const UpdateUserStatus = async (values) => {
  return await axiosInstance().post(`/updateUserStatus`, values);
};

export const UpdateUserDetail = async (values) => {
  return await axiosInstance().post(`/updateUserDetail`, values);
};

export const UpdateUserLoginDetail = async (values) => {
  return await axiosInstance().post(`/updateLoginDetail`, values);
};

export const DeleteUser = async (values) => {
  return await axiosInstance().post(`/deleteUser`, values);
};

export const UserAuditTrailDetails = async (values) => {
  return await axiosInstance().post(`/viewUserAuditTrail`, values);
};

export const RoleAuditTrailDetails = async (values) => {
  return await axiosInstance().post(`/viewActionAuditTrail`, values);
};

export const AllBrandList = async () => {
  return await axiosInstance().get(`/allBrandList`);
};

export const SoftPhoneUserDetailsUpdate = async (data) => {
  return await axiosInstance().post(`/softphoneUserDetailsUpdate`, data);
};

export const ListUserFilters = async () => {
  return await axiosInstance().get(`/listUserFilters`);
};

export const ListUserFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listUserFilters`, {
    cancelToken: cancelToken
  });
};
