import React from "react";
import { TrimText } from "../../common/TrimText";
export const checkRole = (LoggedInUserRole, UserRole) => {
  var response;
  switch (LoggedInUserRole) {
    case "Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principle/Deputy Principle",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
        "Programme Coordinator",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Super Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Portal Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Student Support":
      var arr = [
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
        "Programme Coordinator",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Manager":
      var arr = [
        "Accounts",
        "Lecturer",
        "Qualifications Coordinator",
        "Content Editor",
        "Sales",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Developer":
      var arr = [
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Qualifications Coordinator":
      var arr = ["Lecturer", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Principal/Deputy Principal":
      var arr = ["Lecturer", "Qualifications Coordinator", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Deputy Principal":
      var arr = ["Lecturer", "Qualifications Coordinator", "Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Programme Coordinator":
      var arr = ["Lecturer", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    default:
      return "disabled";
  }
};

export const DetailRegistrationStatusColor = (data) => {
  switch (data) {
    case "open":
      return RoleUi({ color: "cat-yellow", text: "Open" });

    case "closed":
      return RoleUi({ color: "cat-blue", text: "Closed" });

    case "REGISTRATION CLOSED":
      return RoleUi({ color: "cat-blue", text: "Closed" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const ExamColorCode = (data) => {
  switch (data) {
    case "fulltimeonline":
      return RoleUi({ color: "cat-red", text: "Full Time (Online)" });

    case "full":
      return RoleUi({ color: "cat-blue", text: "Full-Time" });

    case "fulltimecontact":
      return RoleUi({ color: "cat-greenhex", text: "Full Time (Contact)" });

    case "cores":
      return RoleUi({ color: "cat-yellow", text: "Correspondence" });

    case "part":
      return RoleUi({ color: "cat-skyblue", text: "Part-TIme" });

    case "parttimeonline":
      return RoleUi({ color: "cat-greenhex", text: "Part Time (Online)" });
  }
};

export const ExamStatusColor = (data) => {
  switch (data) {
    case "IN PROGRESS":
      return RoleUi({ color: "cat-yellow", text: "In Progress" });

    case "CLOSED":
      return RoleUi({ color: "cat-blue", text: "Closed" });

    case "HIDE":
      return RoleUi({ color: "cat-red", text: "Hide" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const RegistrationColor = (data) => {
  switch (data) {
    case "south-african-citizen":
      return RoleUi({ color: "cat-skyblue", text: "South African" });

    case "international-student":
      return RoleUi({ color: "cat-red", text: "International" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const RegistrationStatusColor = (data) => {
  let status = data.toLowerCase()
  switch (status) {
    case "submitted":
      return RoleUi({ color: "cat-skyblue", text: "Submitted" });

    case "withdrawn":
      return RoleUi({ color: "cat-blue", text: "Withdrawn" });

    case "approved":
      return RoleUi({ color: "cat-green", text: "Approved" });

    case "approved-inv":
      return RoleUi({ color: "cat-yellow", text: "Approved-INV" });

    case "approved-paid":
      return RoleUi({ color: "cat-red", text: "Approved-Paid" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const RegistrationStudentStatusColor = (data) => {
  switch (data) {
    case "On Hold":
      return RoleUi({ color: "cat-skyblue", text: "On Hold" });

    case "Active":
      return RoleUi({ color: "cat-green", text: "Active" });

    case "Drop Out":
      return RoleUi({ color: "cat-blue", text: "Drop Out" });

    case "Finished":
      return RoleUi({ color: "cat-yellow", text: "Finished" });

    case "In Active":
      return RoleUi({ color: "cat-red", text: "In Active" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const StudyTypeColor = (data) => {
  switch (data) {
    case "full":
      return RoleUi({ color: "cat-skyblue", text: "Full-Time" });

    case "part":
      return RoleUi({ color: "cat-red", text: "Part-Time" });

    case "fulltimeonline":
      return RoleUi({ color: "cat-green", text: "Full Time (Online)" });

    case "fulltimecontact":
      return RoleUi({ color: "cat-green", text: "Full Time (Contact)" });

    case "fulltimecontact":
      return RoleUi({ color: "cat-green", text: "Full Time (Contact)" });

    case "parttimeonline":
      return RoleUi({ color: "cat-green", text: "Part-Time (Online)" });

    case "parttimecontact":
      return RoleUi({ color: "cat-green", text: "Part Time (Contact)" });

    case "cores":
      return RoleUi({ color: "cat-green", text: "Correspondence" });

    default:
      return RoleUi({ color: "cat-black", text: "NA" });
  }
};

export const Color = (role_name) => {
  switch (role_name) {
    case "Admin":
      return "cat-skyblue";

    case "Super Admin":
      return "cat-skyblue";

    case "Portal Admin":
      return "cat-skyblue";

    case "Student Support":
      return "cat-greenhex";

    case "Manager":
      return "cat-blue";

    case "Developer":
      return "cat-red";

    case "Qualifications Coordinator":
      return "cat-lightblue";

    case "Principal/Deputy Principal":
      return "cat-yellowshade";

    case "Deputy Principal":
      return "cat-yellowshade";

    case "Lecturer":
      return "cat-greenhex";

    case "Editor":
      return "cat-lightblue";

    case "Sales":
      return "cat-blue";

    case "Accounts":
      return "cat-red";
  }
};
export const AttendanceStatusColor = (data) => {
  switch (data) {
    case "Absent":
      return "cat-red";
    case "Present":
      return "cat-green";
    case "Partially Attended":
      return "cat-yellow";
    case "Virtual Classroom":
      return "cat-red";
    case "On Campus Class":
      return "cat-green";
    case "On Campus":
      return "cat-green";
    case "Virtual":
        return "cat-red";
    default:
      return "cat-black";
  }
};

export const ColorCode = (data) => {
  var replaced = data ? data.replace(/-/g, " ") : data;     
  switch (replaced) {
    case "sent":
      return RoleUi({ color: "cat-brown", text: "Sent" });
    case "draft":
      return RoleUi({ color: "cat-yellow", text: "Draft" });
    case "Declined":
      return RoleUi({ color: "cat-skyblue", text: "Declined" });
    case "Re Registration New":
      return RoleUi({ color: "cat-yellowshade", text: "Re-Registration New" });
    case "Remind Me Later":
      return RoleUi({ color: "cat-greenhex", text: "Remind Me Later" });
    case "Exam Registration":
      return RoleUi({ color: "cat-lightblue", text: "Exam Registration" });
    case "Submitted Assessment":
      return RoleUi({ color: "cat-pinkshade", text: "Submitted Assessment" });
    case "Read Ticket":
      return RoleUi({ color: "cat-blueshade", text: "Read Ticket" });
    case "Replied to Ticket":
      return RoleUi({ color: "cat-pink", text: "Replied to Ticket" });
    case "Opened Resource":
      return RoleUi({ color: "cat-brown", text: "Opened Resource" });
    case "Read Notification":
      return RoleUi({ color: "cat-red", text: "Read Notification" });
    case "Logged In (Desktop)":
      return RoleUi({ color: "cat-yellowshade", text: "Logged In (Desktop)" });
    case "Logged Out (Desktop)":
      return RoleUi({ color: "cat-lightblue", text: "Logged Out (Desktop)" });
    case "Logged In (Mobile)":
      return RoleUi({ color: "cat-blue", text: "Logged In (Mobile)" });
    case "Logged Out (Mobile)":
      return RoleUi({ color: "cat-yellow", text: "Logged Out (Mobile)" });
    case "CourseDetailcores":
      return RoleUi({ color: "cat-yellow", text: "Correspondence" });
    case "CourseDetailfull":
      return RoleUi({ color: "cat-pink", text: "Full-Time" });
    case "CourseDetailfulltimeonline":
      return RoleUi({ color: "cat-blue", text: "Full Time(Online)" });
    case "CourseDetailpart":
      return RoleUi({ color: "cat-skyblue", text: "Part-Time" });
    case "CourseDetailparttimeonline":
      return RoleUi({ color: "cat-green", text: "Part-Time(Online)" });
    case "CourseDetailfulltimecontact":
      return RoleUi({ color: "cat-red", text: "Full Time(Contact)" });
    case "Appeal Approved":
      return RoleUi({ color: "cat-blue", text: "Appeal-Approved" });
    case "typeAssignment":
      return RoleUi({ color: "cat-green", text: "Assignment" });
    case "typeInternal Exam":
      return RoleUi({ color: "cat-blue", text: "Internal Exam" });
    case "typeExternal Exam":
      return RoleUi({ color: "cat-yellow", text: "External Exam" });
    case "typeTest":
      return RoleUi({ color: "cat-red", text: "Test" });
    case "typePoE Formative":
      return RoleUi({ color: "cat-skyblue", text: "PoE-Formative" });
    case "typePoE Summative":
      return RoleUi({ color: "cat-brown", text: "PoE-Summative" });
    case "typePoE Informal Activities":
      return RoleUi({ color: "cat-yellowshade", text: "PoE-Informal Activities" });
    case "learningcores":
      return RoleUi({ color: "cat-yellow", text: "Correspondence" });
    case "learningfull":
      return RoleUi({ color: "cat-pink", text: "Full-Time" });
    case "learningfulltimecontact":
      return RoleUi({ color: "cat-red", text: "Full Time (Contact)" });
    case "learningfulltimeonline":
      return RoleUi({ color: "cat-blue", text: "Full Time (Online)" });
    case "learningpart":
      return RoleUi({ color: "cat-skyblue", text: "Part-Time" });
    case "learningparttimeonline":
      return RoleUi({ color: "cat-greenhex", text: "Part-Time (Online)" });
    case "markingPast Due":
      return RoleUi({ color: "cat-skyblue", text: "Past Due" });
    case "markingPending":
      return RoleUi({ color: "cat-red", text: "Pending" });
    case "markingMarked":
      return RoleUi({ color: "cat-grrenhex", text: "Marked" });
    case "past":
      return RoleUi({ color: "cat-skyblue", text: "PAST" });
    case "Active":
      return RoleUi({ color: "cat-skyblue", text: "ACTIVE" });
    case "Upcoming":
      return RoleUi({ color: "cat-red", text: "UPCOMING" });
    case "Canceled":
      return RoleUi({ color: "cat-greenhex", text: "CANCELED" });
    case "Today":
      return RoleUi({ color: "cat-greenhex", text: "TODAY" });
    case "full":
      return RoleUi({ color: "cat-pink", text: "Full-Time" });
    case "part":
      return RoleUi({ color: "cat-skyblue", text: "Part-Time" });
    case "fulltimecontact":
      return RoleUi({ color: "cat-red", text: "Full Time (Contact)" });
    case "parttimeonline":
      return RoleUi({ color: "cat-greenhex", text: "Part-Time (Online)" });
    case "cores":
      return RoleUi({ color: "cat-red", text: "Correspondence" });
    case "fulltimeonline":
      return RoleUi({ color: "cat-blue", text: "Full Time (Online)" });
    case "Admin":
      return RoleUi({ color: "cat-skyblue", text: "Admin" });

    case "Super Admin":
      return RoleUi({ color: "cat-skyblue", text: "Super Admin" });

    case "Portal Admin":
      return RoleUi({ color: "cat-skyblue", text: "Portal Admin" });

    case "Student Support":
      return RoleUi({ color: "cat-greenhex", text: "Student Support" });

    case "Manager":
      return RoleUi({ color: "cat-blue", text: "Manager" });

    case "Developer":
      return RoleUi({ color: "cat-red", text: "Developer" });

    case "Qualifications Coordinator":
      return RoleUi({
        color: "cat-lightblue",
        text: "Qualifications Coordinator",
      });

    // case "Principle/Deputy Principle":
    //   return RoleUi({ color: "cat-yellowshade", text: "Principle/Deputy Principle" });

    case "Deputy Principal":
      return RoleUi({ color: "cat-yellowshade", text: "Deputy Principal" });

    case "Lecturer":
      return RoleUi({ color: "cat-greenhex", text: "Lecturer" });

    case "Editor":
      return RoleUi({ color: "cat-lightblue", text: "Editor" });

    case "Content Editor":
      return RoleUi({ color: "", text: "Content Editor" });

    case "Programme Coordinator":
      return RoleUi({ color: "", text: "Programme Coordinator" });

    case "Sales":
      return RoleUi({ color: "cat-blue", text: "Sales" });

    case "Accounts":
      return RoleUi({ color: "cat-red", text: "Accounts" });

    case "Midrand":
      return RoleUi({ color: "cat-skyblue", text: "Midrand" });

    case "Johannesburg":
      return RoleUi({ color: "cat-greenhex", text: "Johannesburg" });

    case "Cape Town":
      return RoleUi({ color: "cat-blue", text: "Cape Town" });
    case "Hatfield":
        return RoleUi({ color: "cat-pink", text: "Hatfield" });
    case "Remote/Online":
      return RoleUi({ color: "cat-yellowshade", text: "Remote/Online" });

    case "User Created":
      return RoleUi({ color: "cat-skyblue", text: "User Created" });

    case "Edited":
      return RoleUi({ color: "cat-greenhex", text: "Edited" });

    case "Save & Add to Live Portal Clicked":
      return RoleUi({ color: "cat-greenhex", text: "Save & Add to Live Portal Executed" });
    case "Assigned":
      return RoleUi({ color: "cat-blue", text: "Assigned" });

    case "Unassigned":
      return RoleUi({ color: "cat-yellowshade", text: "Unassigned" });

    case "User Deleted":
      return RoleUi({ color: "cat-red", text: "User Deleted" });

    case "Profile Picture Edited":
      return RoleUi({
        color: "cat-yellowshade",
        text: "Profile Picture Edited",
      });

    case "Academic Institute of Excellence (AIE)":
      return CompanyUi({
        color: "cat-skyblue",
        text: "Academic Institute of Excellence (AIE)",
      });

    case "Learnfast":
      return CompanyUi({ color: "cat-blue", text: "Learnfast" });

    case "Cadco":
      return CompanyUi({ color: "cat-red", text: "Cadco" });

    case "Greenside Design Center (GDC)":
      return CompanyUi({
        color: "cat-greenhex",
        text: "Greenside Design Center (GDC)",
      });
    // return CompanyUi({ color: "cat-skyblue", text: "Greenside Design Center (GDC)" });

    case "multiple choice single answer":
      return CompanyUi({
        color: "cat-skyblue",
        text: "Multiple Choice Single Answer",
      });

    case "multiple choice multiple answer":
      return CompanyUi({
        color: "cat-greenhex",
        text: "Multiple Choice Multiple Answer",
      });

    case "match the column":
      return CompanyUi({ color: "cat-blue", text: "Match The Column" });

    case "short answer":
      return CompanyUi({ color: "cat-yellowshade", text: "Short Answer" });

    case "long answer":
      return CompanyUi({ color: "cat-red", text: "Long Answer" });

    case "difficult":
      return CompanyUi({ color: "cat-red", text: "Difficult" });

    case "hard":
      return CompanyUi({ color: "cat-red", text: "Hard" });

    case "medium":
      return CompanyUi({ color: "cat-blue", text: "Medium" });

    case "easy":
      return CompanyUi({ color: "cat-yellowshade", text: "Easy" });
    // return CompanyUi({ color: "cat-greenhex", text: "Greenside Design Center (GDC)" });

    case "In Progress":
      return RoleUi({ color: "cat-skyblue", text: "In Progress" });

    case "Upcoming":
      return RoleUi({ color: "cat-greenhex", text: "Upcoming" });

    case "Past":
      return RoleUi({ color: "cat-red", text: "Past" });

    case "Critical":
      return RoleUi({ color: "cat-red", text: "Critical" });
    case "Moderate":
      return RoleUi({ color: "cat-orange", text: "Moderate" });
    case "Good":
      return RoleUi({ color: "cat-green", text: "Good" });

    case "other":
      return RoleUi({ color: "cat-skyblue", text: "Other" });

    case "studykits":
      return RoleUi({ color: "cat-greenhex", text: "Study kits" });

    case "complaint":
      return RoleUi({ color: "cat-blue", text: "Complaint" });

    case "assessments":
      return RoleUi({ color: "cat-red", text: "Assessments" });

    case "student portal":
      return RoleUi({ color: "cat-greenhex", text: "Student Portal" });

    case "external exam query":
      return RoleUi({ color: "cat-dark-red", text: "External Exam Query" });

    case "accounts":
      return RoleUi({ color: "cat-yellow", text: "Accounts" });

    case "external-exam-query":
      return RoleUi({ color: "cat-dark-red", text: "External Exam Query" });

    case "internal exam query":
      return RoleUi({ color: "cat-dark-red", text: "Internal Exam Query" });

    case "academic query":
      return RoleUi({ color: "cat-dark-red", text: "Academic Query" });

    case "new studies":
      return RoleUi({ color: "cat-yellowshade", text: "New Studies" });

    case "current studies":
      return RoleUi({ color: "cat-yellowshade", text: "Current Studies" });

    case "software":
      return RoleUi({ color: "cat-greenhex", text: "Software" });

    case "online/virtual classes":
      return RoleUi({ color: "cat-greenhex", text: "Online/Virtual Classes" });

    case "portal on hold":
      return RoleUi({ color: "cat-yellow", text: "Portal on Hold" });

    case "general account query":
      return RoleUi({ color: "cat-yellow", text: "General Account Query" });

    case "statement request":
      return RoleUi({ color: "cat-greenhex", text: "Statement Request" });

    default:
      return RoleUi({ color: "black", text: "NA" });

    case "PAST":
      return RoleUi({ color: "cat-red", text: "Past" });
    case "In progress":
      return RoleUi({ color: "cat-skyblue", text: "In-Progress" });
    case "IN-PROGRESS":
      return RoleUi({ color: "cat-skyblue", text: "In-Progress" });
    case "NOT STARTED":
      return RoleUi({ color: "cat-greenhex", text: "Not Started" });

    case "other":
      return RoleUi({ color: "cat-skyblue", text: "other" });

    case "studykits":
      return RoleUi({ color: "cat-greenhex", text: "Study kits" });

    case "complaint":
      return RoleUi({ color: "cat-blue", text: "complaint" });

    case "assessments":
      return RoleUi({ color: "cat-red", text: "Assessments" });

    case "student portal":
      return RoleUi({ color: "cat-greenhex", text: "student portal" });

    case "external exam query":
      return RoleUi({ color: "cat-dark-red", text: "external exam query" });

    case "accounts":
      return RoleUi({ color: "cat-yellow", text: "accounts" });

    case "external-exam-query":
      return RoleUi({ color: "cat-dark-red", text: "external-exam-query" });

    case "internal exam query":
      return RoleUi({ color: "cat-dark-red", text: "internal exam query" });

    case "academic query":
      return RoleUi({ color: "cat-dark-red", text: "academic query" });

    case "academic-query":
      return RoleUi({ color: "cat-dark-red", text: "academic query" });

    case "student-portal":
      return RoleUi({ color: "cat-dark-red", text: "academic query" });

    case "student-support":
      return RoleUi({ color: "cat-dark-red", text: "academic query" });

    case "online/virtual-classes":
      return RoleUi({ color: "cat-dark-red", text: "academic query" });

    case "new studies":
      return RoleUi({ color: "cat-yellowshade", text: "new studies" });

    case "current studies":
      return RoleUi({ color: "cat-yellowshade", text: "current studies" });

    case "software":
      return RoleUi({ color: "cat-greenhex", text: "Software" });

    case "online/virtual classes":
      return RoleUi({ color: "cat-greenhex", text: "online/virtual classes" });

    case "portal on hold":
      return RoleUi({ color: "cat-yellow", text: "portal on hold" });

    case "general account query":
      return RoleUi({ color: "cat-yellow", text: "general account query" });

    case "statement request":
      return RoleUi({ color: "cat-greenhex", text: "general account query" });

    case "Full Time":
      return studyTypeUi({ color: "cat-pink", text: "Full-Time" });

    case "Part Time":
      return studyTypeUi({ color: "cat-skyblue", text: "Part-Time" });

    case "Full-Time":
      return studyTypeUi({ color: "cat-pink", text: "Full-Time" });

    case "Part-Time":
      return studyTypeUi({ color: "cat-yellow", text: "Part-Time" });

    case "Full Time":
      return studyTypeUi({ color: "cat-greenhex", text: "Full-Time" });

    case "Part Time":
      return studyTypeUi({ color: "cat-yellow", text: "Part-Time" });

    case "scheduled":
      return studyTypeUi({ color: "cat-yellow", text: "Scheduled" });

    case "mentored":
      return studyTypeUi({ color: "cat-dark-red", text: "Mentored" });

    case "information":
      return studyTypeUi({ color: "cat-blue", text: "Information" });

    case "orientation":
      return studyTypeUi({ color: "cat-greenhex", text: "Orientation" });

    case "shortcourse":
      return studyTypeUi({ color: "cat-skyblue", text: "Short Course" });

    case "v1":
      return studyTypeUi({ color: "cat-greenhex", text: "V1" });

    case "v2":
      return studyTypeUi({ color: "cat-blue", text: "V2" });

    case "v3":
      return studyTypeUi({ color: "cat-yellow", text: "V3" });

    case "v4":
      return studyTypeUi({ color: "cat-red", text: "V4" });

    case "NOT SUBMITTED":
      return submissionStatusUi({ color: "cat-red", text: "Not Submitted" });

    case "SUBMITTED":
      return submissionStatusUi({ color: "cat-skyblue", text: "Submitted" });

    case "MARKED":
      return submissionStatusUi({ color: "cat-green", text: "Marked" });

    case "DRAFT":
      return submissionStatusUi({ color: "cat-yellow", text: "Draft" });

    case "fulltimecontact":
      return CompanyUi({ color: "cat-red", text: "Full Time Contact" });

    case "parttimeonline":
      return CompanyUi({ color: "cat-greenhex", text: "Part Time Online" });

    case "fulltimeonline":
      return CompanyUi({ color: "cat-blue", text: "Full Time Online" });

    case "visual_design":
      return CompanyUi({
        color: "cat-skyblue",
        text: "School of Visual Design, Marketing & Branding",
      });

    case "business":
      return CompanyUi({
        color: "cat-red",
        text: "School of Business, Entrepreneurship & Finance",
      });

    case "architecture":
      return CompanyUi({
        color: "cat-pinkshade",
        text: "School of Architecture & The Built Environment",
      });

    case "draughting":
      return CompanyUi({
        color: "cat-greenhex",
        text: "School of Draughting & Technical Design",
      });

    case "IT":
      return CompanyUi({
        color: "cat-blue",
        text: "School of Information Technology & Data Science",
      });
      case "fg":
        return CompanyUi({
          color: "cat-green",
          text: "School of Game Design & Development",
        });
        case "fst":
          return CompanyUi({
            color: "cat-orange",
            text: "School of Music & Sound Production",
          });
    case "engineering":
      return CompanyUi({
        color: "cat-yellow",
        text: "School of Engineering & Science",
      });

    case "online":
      return CompanyUi({ color: "cat-yellowshade", text: "Online" });

    case "Diploma":
      return CompanyUi({ color: "cat-green", text: "Diploma" });

    case "1":
      return CompanyUi({ color: "cat-blue", text: "Certificate" });

    case "2":
      return CompanyUi({ color: "cat-green", text: "Diploma" });

    case "3":
      return CompanyUi({ color: "cat-red", text: "Higher Certificate" });

    case "4":
      return CompanyUi({ color: "cat-orange", text: "Bachelor’s Degree" });

    case "5":
      return CompanyUi({ color: "cat-skyblue", text: "Honours Degree" });

    case "6":
      return CompanyUi({ color: "cat-brown", text: "Master’s Degree" });

    case "Certificate":
      return CompanyUi({ color: "cat-green", text: "Certificate" });

    case "higherCertificate":
      return CompanyUi({ color: "cat-green", text: "Higher Certificate" });

    case "bachelorsDegree":
      return CompanyUi({ color: "cat-green", text: "Bachelor's Degree" });

    case "honoursDegree":
      return CompanyUi({ color: "cat-green", text: "Honours Degree" });

    case "mastersDegree":
      return CompanyUi({ color: "cat-green", text: "Masters Degree" });

    case "online":
      return CompanyUi({ color: "cat-yellowshade", text: "Online" });

    case "full":
      return ModuleTypeUi({ color: "cat-green", text: "Full-Time" });

    case "part":
      return ModuleTypeUi({ color: "cat-skyblue", text: "Part-Time" });

    case "cores":
      return ModuleTypeUi({ color: "cat-yellow", text: "Correspondence" });

    case "scheduled":
      return ModuleTypeUi({ color: "cat-green", text: "Scheduled" });

    case "mentored":
      return ModuleTypeUi({ color: "cat-blue", text: "Mentored" });

    case "information":
      return ModuleTypeUi({ color: "cat-red", text: "Information" });

    case "orientation":
      return ModuleTypeUi({ color: "cat-greenhex", text: "Orientation" });

    case "shortcourse":
      return ModuleTypeUi({ color: "cat-yellow", text: "Short Course" });

    case "short course":
      return ModuleTypeUi({ color: "cat-yellow", text: "Short Course" });

    case "Brand Created":
      return CompanyUi({ color: "cat-blue", text: "Created" });

    case "Brand Edited":
      return CompanyUi({ color: "cat-greenhex", text: "Edited" });

    case "Brand Deleted":
      return CompanyUi({ color: "cat-red", text: "Deleted" });

    case "fulltimecontact":
      return CompanyUi({ color: "cat-red", text: "Full Time Contact" });

    case "parttimeonline":
      return CompanyUi({ color: "cat-greenhex", text: "Part Time Online" });

    case "fulltimeonline":
      return CompanyUi({ color: "cat-blue", text: "Full Time Online" });

    case "visual_design":
      return CompanyUi({
        color: "cat-skyblue",
        text: "School of Visual Design, Marketing & Branding",
      });

    case "business":
      return CompanyUi({
        color: "cat-red",
        text: "School of Business, Entrepreneurship & Finance",
      });

    case "architecture":
      return CompanyUi({
        color: "cat-brown",
        text: "School of Architecture & The Built Environment",
      });

    case "draughting":
      return CompanyUi({
        color: "cat-greenhex",
        text: "School of Draughting & Technical Design",
      });

    case "IT":
      return CompanyUi({
        color: "cat-blue",
        text: "School of Information Technology & Data Science",
      });
      case "fg":
        return CompanyUi({
          color: "cat-green",
          text: "School of Game Design & Development",
        });
        case "fst":
          return CompanyUi({
            color: "cat-orange",
            text: "School of Music & Sound Production",
          });
    case "engineering":
      return CompanyUi({
        color: "cat-yellow",
        text: "School of Engineering & Science",
      });

    case "online":
      return CompanyUi({ color: "cat-yellowshade", text: "Online" });

    case "Diploma":
      return CompanyUi({ color: "cat-green", text: "Diploma" });

    case "1":
      return CompanyUi({ color: "cat-blue", text: "Certificate" });

    case "2":
      return CompanyUi({ color: "cat-green", text: "Diploma" });

    case "3":
      return CompanyUi({ color: "cat-red", text: "Higher Certificate" });

    case "4":
      return CompanyUi({ color: "cat-orange", text: "Bachelor’s Degree" });

    case "5":
      return CompanyUi({ color: "cat-skyblue", text: "Honours Degree" });

    case "6":
      return CompanyUi({ color: "cat-brown", text: "Master’s Degree" });

    case "Certificate":
      return CompanyUi({ color: "cat-green", text: "Certificate" });

    case "higherCertificate":
      return CompanyUi({ color: "cat-green", text: "Higher Certificate" });

    case "bachelorsDegree":
      return CompanyUi({ color: "cat-green", text: "Bachelor's Degree" });

    case "honoursDegree":
      return CompanyUi({ color: "cat-green", text: "Honours Degree" });

    case "mastersDegree":
      return CompanyUi({ color: "cat-green", text: "Masters Degree" });

    case "online":
      return CompanyUi({ color: "cat-yellowshade", text: "Online" });

    case "Created":
      return RoleUi({ color: "cat-skyblue", text: "Created" });

    case "Edited":
      return RoleUi({ color: "cat-greenhex", text: "Edited" });

    case "Deleted":
      return RoleUi({ color: "cat-red", text: "Deleted" });
    case "approved":
      return RoleUi({ color: "cat-greenhex", text: "Approved" });

    case "declined":
      return RoleUi({ color: "cat-red", text: "Declined" });

    case "Profile Picture Edited":
      return RoleUi({
        color: "cat-yellowshade",
        text: "Profile Picture Edited",
      });
    case "full":
      return ModuleTypeUi({ color: "cat-green", text: "Full-Time" });

    case "part":
      return ModuleTypeUi({ color: "cat-skyblue", text: "Part-Time" });

    case "Full-Time":
      return ModuleTypeUi({ color: "cat-green", text: "Full-Time" });

    case "Part-Time":
      return ModuleTypeUi({ color: "cat-skyblue", text: "Part-Time" });

    case "cores":
      return ModuleTypeUi({ color: "cat-yellow", text: "Correspondence" });

    case "campus rooms":
      return CompanyUi({ color: "cat-yellowshade", text: "Campus Rooms" });

    case "campus":
      return CompanyUi({ color: "cat-blue", text: "Campus" });
    case "scheduled":
      return ModuleTypeUi({ color: "cat-green", text: "Scheduled" });

    case "mentored":
      return ModuleTypeUi({ color: "cat-blue", text: "Mentored" });

    case "information":
      return ModuleTypeUi({ color: "cat-red", text: "Information" });

    case "orientation":
      return ModuleTypeUi({ color: "cat-brown", text: "Orientation" });

    case "shortcourse":
      return ModuleTypeUi({ color: "cat-yellow", text: "Short Course" });

    case "short course":
      return ModuleTypeUi({ color: "cat-yellow", text: "Short Course" });

    case "Brand Created":
      return CompanyUi({ color: "cat-blue", text: "Created" });

    case "ft online":
      return studyTypeUi({ color: "cat-greenhex", text: "FT Online" });

    case "ft contact":
      return studyTypeUi({ color: "cat-yellow", text: "FT Contact" });

    case "part time online":
      return studyTypeUi({ color: "cat-dark-red", text: "Part-Time Online" });

    case "Correspondence":
      return RoleUi({ color: "cat-yellow", text: "Correspondence" });
    case "Full Time (Contact)":
      return RoleUi({ color: "cat-red", text: "Full Time (Contact)" });
    case "Part Time (Online)":
      return RoleUi({ color: "cat-greenhex", text: "Part-Time (Online)" });

    case "FT Online":
      return RoleUi({ color: "cat-blue", text: "FT Online" });
    case "FT Contact":
      return RoleUi({ color: "cat-red", text: "FT Contact" });
    case "Part-Time Online":
      return RoleUi({ color: "cat-greenhex", text: "Part-Time Online" });

    case "Full-Time":
      return RoleUi({ color: "cat-greenhex", text: "Full-Time" });

    case "Part-Time":
      return RoleUi({ color: "cat-yellow", text: "Part-Time" });

    case "Correspondence":
      return RoleUi({ color: "cat-red", text: "Correspondence" });

    case "Full Time (Contact)":
      return RoleUi({ color: "cat-skyblue", text: "Full Time (Contact)" });

    case "Full Time (Online)":
      return RoleUi({ color: "cat-blue", text: "Full Time (Online)" });

    case "Part-Time (Online)":
      return RoleUi({ color: "cat-yellowshade", text: "Part-Time (Online)" });

    case "rereg_programs":
      return RoleUi({ color: "cat-yellowshade", text: "Programs" });

    case "rereg_shortcourse":
      return RoleUi({ color: "cat-blue", text: "Short Course" });

    case "programs":
      return RoleUi({ color: "cat-yellowshade", text: "Programs" });

    case "shortcourse":
      return RoleUi({ color: "cat-blue", text: "Short Course" });

    // added by yash
    case "Not started":
      return RoleUi({ color: "cat-green", text: "Not started" });
    case "In-progress":
      return RoleUi({ color: "cat-greenhex", text: "In-progress" });
    case "IN PROGRESS":
      return RoleUi({ color: "cat-greenhex", text: "In-progress" });

    case "Pending sign off":
      return CompanyUi({ color: "cat-yellow", text: "Pending-sign off" });

    case "PENDING SIGN OFF":
      return CompanyUi({ color: "cat-yellow", text: "Pending-sign off" });

    case "Pending sigh off":
      return CompanyUi({ color: "cat-yellow", text: "Pending-sign off" });
    
    case "Pending Review":
      return CompanyUi({ color: "cat-blue", text: "Pending Review" });

    case "PENDING REVIEW":
      return CompanyUi({ color: "cat-blue", text: "Pending Review" });

    case "Progress":
      return CompanyUi({ color: "cat-red", text: "Progress" });

    case "Finished":
      return CompanyUi({ color: "cat-blue", text: "Finished" });

    case "FINISHED":
      return CompanyUi({ color: "cat-blue", text: "Finished" });

    case "Start & End Date":
      return DatesUi({ color: "cat-greenhex", text: "Start & End Date" });
    case "Start & Completed Date":
      return DatesUi({ color: "cat-skyblue", text: "Start & Completed Date" });
    case "Start & Comitted End Date":
      return DatesUi({ color: "cat-yellow", text: "Start & Comitted End Date" });
    case "Recommended Start & End Date":
      return DatesUi({ color: "cat-red", text: "Recommended Start & End Date" });

    case "draft":
      return DatesUi({ color: "cat-red", text: "Draft" });
    case "final":
      return DatesUi({ color: "cat-green", text: "Final" });
    case "new":
      return DatesUi({ color: "cat-skyblue", text: "New" });
    case "approved":
      return DatesUi({ color: "cat-yellowshade", text: "Approved" });
    case "inactive":
      return DatesUi({ color: "cat-blue", text: "In active" });

  }
};

const RoleUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {text}
    </span>
  );
};

const studyTypeUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {text}
    </span>
  );
};

const submissionStatusUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {text}
    </span>
  );
};

const CompanyUi = ({ color, text }) => {
  return (
    <p className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {text}
    </p>
  );
};

const ModuleTypeUi = ({ color, text }) => {
  return (
    <p className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 15)}
    </p>
  );
};

export const ColorRender = (props) => {
  let str = props ? props.toLowerCase() : props;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "statuspending":
      return "as-red-bg as-widget";
    case "statusopen":
      return "as-green-bg as-widget";
    case "statusclosed":
      return "as-blue-bg as-widget";
    case "admin":
      return "bgSkyblue";

    case "super admin":
      return "bgSkyblue";

    case "portal admin":
      return "bgSkyblue";

    case "student support":
      return "bgGreenHex";

    case "lecturer":
      return "bgGreenHex";

    case "manager":
      return "bgBlue";

    case "sales":
      return "bgBlue";

    case "developer":
      return "bgRed";

    case "accounts":
      return "bgRed";

    case "qualifications coordinator":
      return "bgBlue";

    case "editor":
      return "bgBlue";

    case "principle deputy principle":
      return "bgYellow";

    case "closedassigned":
      return "as-red-bg as-widget"

    case "openassigned":
      return "as-blue-bg as-widget"

    case "pendingassigned":
      return "as-green-bg as-widget"

    case "closedassigned":
      return "as-red-bg as-widget"

    case "openassigned":
      return "as-blue-bg as-widget"

    case "pendingassigned":
      return "as-green-bg as-widget"

    default:
      return "bgDarkRed";
  }
};

// export const ColorRenderDept = (props) => {
//   let str = props ? props.toLowerCase() : props;
//   var replaced = str ? str.replace(/-/g, " ") : str;
//   switch (replaced) {
//     case "admin":
//       return "bgSkyblue";

//     case "super admin":
//       return "bgSkyblue";

//     case "portal admin":
//       return "bgSkyblue";

//     case "student support":
//       return "bgGreenHex";

//     case "lecturer":
//       return "bgGreenHex";

//     case "manager":
//       return "bgBlue";

//     case "sales":
//       return "bgBlue";

//     case "developer":
//       return "bgRed";

//     case "accounts":
//       return "bgRed";

//     case "qualifications coordinator":
//       return "bgBlue";

//     case "editor":
//       return "bgBlue";

//     case "principle deputy principle":
//       return "bgYellow";

//     default:
//       return "bgDarkRed";
//   }
// };

export const ColorRenderDept = (props) => {
  let str = props ? props.toLowerCase() : props;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "admin":
      return "bgSkyblue";

    case "super admin":
      return "bgSkyblue";

    case "portal admin":
      return "bgSkyblue";

    case "student support":
      return "bgGreenHex";

    case "lecturer":
      return "bgGreenHex";

    case "manager":
      return "bgBlue";

    case "sales":
      return "bgBlue";

    case "developer":
      return "bgRed";

    // case "accounts":
    //   return "bgRed";

    case "qualifications coordinator":
      return "bgBlue";

    case "editor":
      return "bgBlue";

    case "principle deputy principle":
      return "bgYellow";

    case "other":
      return "cat-skyblue";

    case "studykits":
      return "cat-greenhex";

    case "complaint":
      return "cat-blue";

    case "assessments":
      return "cat-red";

    case "student portal":
      return "cat-greenhex";

    case "external exam query":
      return "cat-dark-red";

    case "accounts":
      return "cat-yellow";

    case "external-exam-query":
      return "cat-dark-red";

    case "internal exam query":
      return "cat-dark-red";

    case "academic query":
      return "cat-dark-red";

    case "new studies":
      return "cat-yellowshade";

    case "current studies":
      return "cat-yellowshade";

    case "software":
      return "cat-greenhex";

    case "online/virtual classes":
      return "cat-greenhex";

    case "portal on hold":
      return "cat-yellow";

    case "general account query":
      return "cat-yellow";

    case "statement request":
      return "cat-greenhex";

    default:
      return "cat-skyblue";
  }
};

export const OldToNew = (role_name) => {
  switch (role_name) {
    case "Admin":
      return "Admin";

    case "Super Admin":
      return "Admin";

    case "Portal Admin":
      return "Admin";

    case "Student Support":
      return "Student Support";

    case "Manager":
      return "Manager";

    case "Developer":
      return "Developer";

    case "Qualifications Coordinator":
      return "Qualifications Coordinator";

    case "Principal":
      return "Principal";

    case "Deputy Principal":
      return "Deputy Principal";

    case "Lecturer":
      return "Lecturer";

    case "Editor":
      return "Editor";

    case "Sales":
      return "Sales";

    case "Accounts":
      return "Accounts";
  }
};

const DeptUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {text}
    </span>
  );
};

const ResourceUi = ({ color, text, icon }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i>
      {text}
    </span>
  );
};

const ResourceUi2 = ({ color, text, icon }) => {
  return (
    <span className={"cat " + color}>
      <i className={icon + " mr-1"}></i>
      {text}
    </span>
  );
};

const CampusUi = ({ color, text, icon }) => {
  return (
    <span
    // className={"cat " + color}
    >
      {/* <i className="fas fa-circle mr-1"></i>  */}
      {text}
    </span>
  );
};

export const DepartmentRender = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "administration":
      return DeptUi({ color: "cat-greenhex", text: "Administration" });

    case "academic":
      return DeptUi({ color: "cat-blue", text: "Academic" });

    case "student support":
      return DeptUi({ color: "cat-skyblue", text: "Student Support" });

    case "sales":
      return DeptUi({ color: "cat-yellow", text: "Sales" });

    case "finance1":
      return DeptUi({ color: "cat-dark-red", text: "Finance" });
    case "finance":
      return DeptUi({ color: "cat-dark-red", text: "Finance" });

    default:
      return DeptUi({ color: "cat-dark-black", text: str ? str : "NA" });
  }
};

export const ResourceType = (type) => {
  switch (type) {
    case "1":
      return "Module/category";

    case "2":
      return "Video";

    case "3":
      return "Sub Category";

    case "4":
      return "Document";

    case "5":
      return "Link";

    case "6":
      return "Assessment";

    case "7":
      return "Label";
  }
};

export const StatusRender = (props) => {
  switch (props.status ? props.status.toLowerCase() : props.status) {
    case "launched":
      return <p title={props?.title} className="as-launched-bg as-widget no-text-transformation">{"Launched"}</p>;
    case "assessmentmarked":
      return <p title={props?.title} className="as-green-bg as-widget">Marked</p>;
    case "assessmentsubmitted":
      return <p title={props?.title} className="as-skyblue-bg as-widget">{TrimText("SUBMITTED")}</p>;
    case "assessmentappeal - approved":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Appeal - Approved")}</p>;
    case "assessmentdraft":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">DRAFT</p>;
    case "assessmentnot submitted":
      return <p title={props?.title} className="as-orange-bg as-widget">{TrimText("Not Submitted")}</p>;

    case "closed":
      return <p title={props?.title} className="as-green-bg as-widget">Closed</p>;

    case "new":
      return <p title={props?.title} className="as-skyblue-bg as-widget">New</p>;
    case "canceled":
      return <p title={props?.title} className="as-cancel-bg as-widget">{TrimText("Canceled")}</p>;
    case "today":
      return <p title={props?.title} className="as-skyblue-bg as-widget">Today</p>;

    case "pending-student":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Pending-Student")}</p>;

    case "pending-academy":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("Pending-Academy")}</p>;

    case "pending-investigation":
      return (
        <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("Pending-Investigation")}</p>
      );

    case "open":
      return <p title={props?.title} className="as-yellow-bg as-widget">Open</p>;

    case "past":
      return <p title={props?.title} className="as-past-bg as-widget mr-2 no-text-transformation">Past</p>;

    case "cancelled":
      return <p title={props?.title} className="as-cancel-bg as-widget no-text-transformation">{"Cancelled"}</p>;

    case "not launched":
      return <p title={props?.title} className="as-not-launched-bg as-widget no-text-transformation">{"Not Launched"}</p>;

    case "not-launched":
      return <p title={props?.title} className="as-not-launched-bg as-widget no-text-transformation">{"Not-Launched"}</p>;

    case "live-not launched":
      return <p title={props?.title} className="as-red-bg as-widget no-text-transformation">{TrimText("Live-Not Launched")}</p>;

    case "live-launched":
      return <p title={props?.title} className="as-green-bg as-widget no-text-transformation">{TrimText("Live-Launched")}</p>;

    case "past-not launched":
      return <p title={props?.title} className="as-yellowshade-bg as-widget no-text-transformation">{TrimText("Past-Not Launched")}</p>;

    case "ended":
      return <p title={props?.title} className="as-ended-bg as-widget no-text-transformation">Ended</p>;

    case "live":
      return <p title={props?.title} className="as-live-bg as-widget no-text-transformation">Live</p>;

    case "upcoming":
      return <p title={props?.title} className="as-upcoming-bg as-widget no-text-transformation">Upcoming</p>;

    case "active":
      return <p title={props?.title} className="as-green-bg as-widget">ACTIVE</p>;

    case "in active":
      return <p title={props?.title} className="as-red-bg as-widget">INACTIVE</p>;

    case "drop out":
      return <p title={props?.title} className="as-blue-bg as-widget">DROPOUT</p>;

    case "on hold":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">ON HOLD</p>;

    case "finished":
      return <p title={props?.title} className="as-orange-bg as-widget">{TrimText("FINISHED")}</p>;

    case "in progress":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("IN PROGRESS")}</p>;

    case "approve":
      return <p title={props?.title} className="as-orange-bg as-widget">{TrimText("APPROVED",10)}</p>;

    case "approved":
      return <p title={props?.title} className="as-orange-bg as-widget">{TrimText("APPROVED")}</p>;

    case "appeal-pending":
      return <p title={props?.title} className="as-red-bg as-widget">PENDING</p>;

    case "declined":
      return <p title={props?.title} className="as-green-bg as-widget">{TrimText("DECLINED",10)}</p>;

    case "submitted":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("SUBMITTED")}</p>;

    case "approved-inv":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("APPROVED-INV")}</p>;

    case "approved-paid":
      return <p title={props?.title} className="as-skyblue-bg as-widget">{TrimText("APPROVED-PAID")}</p>;

    case "WITHDRAWN":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("WITHDRAWN")}</p>;

    case "final status":
      return <p title={props?.title} className="as-green-bg as-widget">{TrimText("Final Status")}</p>;

    case "re-registration cancelled":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Re-Registration Cancelled")}</p>;

    case "new-status":
      return <p title={props?.title} className="as-green-bg as-widget">{TrimText("New-Status")}</p>;

    case "re-registration":
      return <p title={props?.title} className="as-green-bg as-widget">{TrimText("Re-Registration New")}</p>;

    case "re-registration-sales":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("Re-Registration-Sales")}</p>;

    case "preliminary re-registration":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("preliminary re-registration")}</p>;

    case "re-registration-Accounts":
      return (
        <p title={props?.title} className="as-skyblue-bg as-widget">{TrimText("re-registration-Accounts")}</p>
      );

    case "re-registration Rejected":
      return (
        <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("re-registration Rejected")}</p>
      );

    case "in-active":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("in-active")}</p>;

    case "re-registration-accounts rejected":
      return (
        <p title={props?.title} className="as-green-bg as-widget">
          {TrimText("re-registration-accounts rejected")}
        </p>
      );

    case "re-registration-Sales invoiced":
      return (
        <p title={props?.title} className="as-red-bg as-widget">{TrimText("re-registration-Sales invoiced")}</p>
      );

    case "re-registration-sales admin":
      return (
        <p title={props?.title} className="as-green-bg as-widget">{TrimText("re-registration-sales admin")}</p>
      );

    case "re-registration-study kit pending":
      return (
        <p title={props?.title} className="as-green-bg as-widget">
          {TrimText("re-registration-study kit pending")}
        </p>
      );

    case "application_status":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("application_status")}</p>;

    case "registration withdrawn":
      return (
        <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("registration withdrawn")}</p>
      );

    case "re-registration-rco":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("re-registration-rco")}</p>;

    case "re-registration-final":
      return <p title={props?.title} className="as-green-bg as-widget">{TrimText("re-registration-final")}</p>;

    case "closed-ordered":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("closed-ordered")}</p>;

    case "up coming":
      return <p title={props?.title} className="as-orange-bg as-widget">{TrimText("Up coming")}</p>;
    case "declined":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Declined")}</p>;

    // added by yash
    case "closed":
      return <p title={props?.title} className="as-green-bg as-widget">Closed</p>;

    case "new":
      return <p title={props?.title} className="as-skyblue-bg as-widget">New</p>;

    case "pending-student":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Pending-Student")}</p>;

    case "pending-academy":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("Pending-Academy")}</p>;

    case "pending-investigation":
      return (
        <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("Pending-Investigation")}</p>
      );

    case "open":
      return <p title={props?.title} className="as-yellow-bg as-widget">Open</p>;

    case "active":
      return <p title={props?.title} className="as-green-bg as-widget">Active</p>;

    case "cancelled":
      return <p title={props?.title} className="as-cancel-bg as-widget">{TrimText("Cancelled")}</p>;

    case "drop out":
      return <p title={props?.title} className="as-red-bg as-widget">{TrimText("Drop Out")}</p>;

    case "finished":
      return <p title={props?.title} className="as-blue-bg as-widget">{TrimText("Finished")}</p>;

    case "in active":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">{TrimText("In Active")}</p>;

    case "on hold":
      return <p title={props?.title} className="as-yellow-bg as-widget">On Hold</p>;

    case "issued":
      return <p title={props?.title} className="as-red-bg as-widget">Issued</p>;

    case "missing":
      return <p title={props?.title} className="as-blue-bg as-widget">Missing</p>;

    case "found":
      return <p title={props?.title} className="as-yellowshade-bg as-widget">Found</p>;

    case "reserved":
      return <p title={props?.title} className="as-yellow-bg as-widget">Reserved</p>;

    case "returned":
      return <p title={props?.title} className="as-green-bg as-widget">Returned</p>;

    case "is_available":
      return <p title={props?.title} className="as-skyblue-bg as-widget">Available</p>;

    default:
      return "-";
  }
};

export default StatusRender;

export const ResourceTypeIcon = (type) => {
  switch (type) {
    case "1":
      return <i class="fas fa-hyphen"></i>;

    case "2":
      return <i class="fas fa-video"></i>;

    case "3":
      return <i class="fas fa-hyphen"></i>;

    case "4":
      return <i class="fas fa-file"></i>;

    case "5":
      return <i class="fas fa-link"></i>;

    case "6":
      return <i class="fas fa-file"></i>;

    case "7":
      return <i class="fas fa-tag"></i>;
  }
};

export const StudentStatusRender = (props) => {
  switch (props.status ? props.status.toLowerCase() : props.status) {
    case "active":
      return <p className="as-green-bg as-widget">Active</p>;

    case "on hold":
      return <p className="as-skyblue-bg as-widget">On Hold</p>;

    case "in active":
      return <p className="as-red-bg as-widget">In Active</p>;

    case "drop out":
      return <p className="as-yellow-bg as-widget">Drop Out</p>;

    case "cancelled":
      return <p className="as-blue-bg as-widget">Cancelled</p>;

    case "finished":
      return <p className="as-yellowshade-bg  as-widget">Finished</p>;

    default:
      return <p>-</p>;
  }
};

export const QuizStatusRender = (props) => {
  switch (props.status ? props.status.toLowerCase() : props.status) {
    // case "active":
    //   return <p className="as-green-bg as-widget">Active</p>;

    case "draft":
      return <p className="as-red-bg as-widget">Draft</p>;

    case "approved":
      return <p className="as-yellowshade-bg as-widget">Approved</p>;

    case "final":
      return <p className="as-green-bg as-widget">Final</p>;

    case "inactive":
      return <p className="as-blue-bg as-widget">Inactive</p>;

    case "new":
      return <p className="as-skyblue-bg as-widget">New</p>;

    default:
      return <p>-</p>;
  }
};

export const Level = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "1":
      return DeptUi({ color: "cat-greenhex", text: "1" });

    case "2":
      return DeptUi({ color: "cat-blue", text: "2" });

    case "3":
      return DeptUi({ color: "cat-skyblue", text: "3" });

    case "4":
      return DeptUi({ color: "cat-dark-red", text: "4" });

    default:
      return DeptUi({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};

export const Format = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "vimeo":
      return ResourceUi2({ color: "cat-greenhex", text: "Vimeo", icon: "fas fa-video" });

    case "book":
      return ResourceUi2({ color: "cat-blue", text: "Book", icon: "fas fa-book" });

    case "ebook":
      return ResourceUi2({ color: "cat-dark-red", text: "E-Book", icon: "fas fa-wifi" });
  
    case "link":
      return ResourceUi2({ color: "cat-skyblue", text: "Link", icon: "fas fa-link" });

    case "pdf":
      return ResourceUi2({ color: "cat-dark-red", text: "Document", icon: "fas fa-file" });

    case "article":
      return ResourceUi2({ color: "item-text-yellow", text: "Article", icon: "fas fa-newspaper" });

    case "youtube":
      return ResourceUi2({ color: "cat-red", text: "Youtube", icon: "fas fa-video" });

    case "label":
      return ResourceUi2({ color: "cat-brown", text: "Label", icon: "fas fa-tag" });

    case "white_paper":
      return ResourceUi2({ color: "cat-skyblue", text: "White Paper", icon: "fas fa-link" });

    default:
      return ResourceUi2({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};

export const Campus = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status;
  
  var replaced = str ? str.replace(/-/g, " ") : str;
  
  switch (replaced) {
    case "cape town":
      return ResourceUi2({ color: "cat-blue", text: "Cape Town", icon: "fal fa-university" });

    case "cent pre":
      return ResourceUi2({ color: "cat-greenhex", text: "Centurion, Pretoria", icon: "fal fa-university" });

    case "correspondence":
      return ResourceUi2({ color: "cat-dark-red", text: "Correspondence", icon: "fal fa-university" });

    case "greenside":
      return ResourceUi2({ color: "cat-skyblue", text: "Greenside", icon: "fal fa-university" });
    
    case "gdc":
        return ResourceUi2({ color: "cat-skyblue", text: "Greenside", icon: "fal fa-university" });

    case "midrand":
      return ResourceUi2({ color: "cat-orange", text: "Midrand", icon: "fal fa-university" });

    case "mid john":
        return ResourceUi2({ color: "cat-orange", text: "Midrand", icon: "fal fa-university" });
  
    case "online":
      return ResourceUi2({ color: "cat-green", text: "Online", icon: "far fa-wifi" });

    default:
      return ResourceUi2({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};

export const CampusBlock = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "cape town":
      return CampusUi({ color: "cat-blue", text: "Cape Town", icon: props.icon });

    case "centurion pretoria":
      return CampusUi({ color: "cat-greenhex", text: "Centurion Pretoria", icon: props.icon });

    case "correspondence":
      return CampusUi({ color: "cat-dark-red", text: "Correspondence", icon: props.icon });

    case "greenside":
      return CampusUi({ color: "cat-skyblue", text: "Greenside", icon: props.icon });

    case "midrand":
      return CampusUi({ color: "cat-dark-orange", text: "Midrand", icon: props.icon });

    case "online":
      return CampusUi({ color: "cat-dark-yellow", text: "Online", icon: props.icon });

    default:
      return CampusUi({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};

export const ColorRenderLevel = (props) => {
  let str = props ? props.toLowerCase() : props;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "1":
      return "cat-greenhex";

    case "2":
      return "cat-blue";

    case "3":
      return "cat-skyblue";

    case "4":
      return "cat-dark-red";

    default:
      return "bgDarkRed";
  }
};

export const BGColor = (type) => {
  switch (type) {
    case "part":
      return BGColorUI({ color: "as-orange-bg", text: "Part Time" });

    case "full":
      return BGColorUI({ color: "as-yellow-bg", text: "Full-Time" });

    case "online":
      return BGColorUI({ color: "as-skyblue-bg", text: "online" });

    case "fulltimecontact":
      return BGColorUI({ color: "as-green-bg", text: "Full Time (Contact)" });

    case "parttimeonline":
      return BGColorUI({ color: "as-red-bg", text: "Part Time (Online)" });

    case "fulltimeonline":
      return BGColorUI({ color: "as-blue-bg", text: "Full Time (Online)" });

    case "Full Time (Online)":
      return BGColorUI({ color: "as-blue-bg", text: "Full Time (Online)" });
  }
};

const BGColorUI = ({ color, text }) => {
  return (
    <p title={text}>
      <span className={color + " as-widget"}>{TrimText(text, 15)}</span>
    </p>
  );
};
export const RegStudentColorComb = (role_name) => {
  // console.log("role_name", role_name)
  switch (role_name) {
    case "part":
      return "cat-greenhex";
    case "correspondence":
      return "cat-lightblue";
    case "cores":
      return "cat-lightblue";
    case "full":
      return "cat-green";

    case "engineering":
      return "cat-skyblue";

    case "business":
      return "cat-greenhex";

    case "gdc":
      return "cat-orange";

    case "architecture":
      return "cat-red";

    case "draughting":
      return "cat-lightblue";

    case "it":
      return "cat-yellowshade";

    case "visual_design":
      return "cat-blue";

    case "full-time":
      return "cat-greenhex";

    case "part-time":
      return "cat-lightblue";

    case "online":
      return "cat-blue";

    case "fulltimeonline":
      return "cat-skyblue";

    case "fulltimecontact":
      return "cat-red";

    case "parttimeonline":
      return "cat-yellowshade";

    case "correspondence":
      return "cat-lightblue";

    case "active":
      return StatusUi({ color: "as-green-bg", text: "Active" });

    case "drop out":
      return StatusUi({ color: "as-orange-bg", text: "Drop Out" });

    case "on hold":
      return StatusUi({ color: "as-red-bg", text: "On Hold" });

    case "finished":
      return StatusUi({ color: "as-blue-bg", text: "Finished" });

    case "draft fo":
      return StatusUi({ color: "as-skyblue-bg", text: "Draft FO" });

    case "in active":
      return StatusUi({ color: "as-yellowshade-bg", text: "In Active" });

    case "closed":
      return StatusUi({ color: "as-red-bg", text: "Closed" });

    case "open":
      return StatusUi({ color: "as-green-bg", text: "Open" });

    case "no":
      return StatusUi({ color: "as-red-bg", text: "No" });

    case "yes":
      return StatusUi({ color: "as-green-bg", text: "Yes" });

    case "camp-mid-john":
      return "cat-skyblue";

    case "camp-cent-pre":
      return "cat-lightblue";

    case "camp-cape-town":
      return "cat-red";

    case "camp-correspondence":
      return "cat-greenhex";

    case "camp-online":
      return "cat-blue";

    case "camp-gdc":
      return "cat-yellowshade";
    case "aie":
      return "cat-lightblue";

    case "new-status":
      return StatusUi({ color: "as-green-bg", text: "New-Status" });

    case "re-registration":
      return StatusUi({ color: "as-green-bg", text: "Re-Registration New" });

    case "re-registration-Sales":
      return StatusUi({ color: "as-blue-bg", text: "Re-Registration-Sales" });

    case "preliminary re-registration":
      return StatusUi({
        color: "as-red-bg",
        text: "Preliminary Re-Registration",
      });

    case "re-registration-accounts":
      return StatusUi({
        color: "as-skyblue-bg",
        text: "Re-Registration-Accounts",
      });

    case "re-registration rejected":
      return StatusUi({
        color: "as-yellowshade-bg",
        text: "Re-Registration Rejected",
      });

    case "rejected":
      return StatusUi({
        color: "as-blue-bg",
        text: "Rejected",
      });

    case "in-active":
      return StatusUi({ color: "as-red-bg", text: "In-Active" });

    case "re-registration-accounts rejected":
      return StatusUi({
        color: "as-green-bg",
        text: "Re-Registration-Accounts Rejected",
      });

    case "re-registration-Sales invoiced":
      return StatusUi({
        color: "as-red-bg",
        text: "Re-Registration-Sales Invoiced",
      });

    case "re-registration-sales":
      return StatusUi({
        color: "as-blue-bg",
        text: "Re-Registration-Sales",
      });

    case "re-registration-sales admin":
      return StatusUi({
        color: "as-green-bg",
        text: "Re-Registration-Sales Admin",
      });

    case "re-registration-study kit pending":
      return StatusUi({
        color: "as-green-bg",
        text: "Re-Registration-Study Kit Pending",
      });

    case "final-status":
      return StatusUi({ color: "as-orange-bg", text: "Final-Status" });

    case "review":
      return StatusUi({ color: "as-blue-bg", text: "Review" });

    case "re-registration cancelled":
      return StatusUi({
        color: "as-red-bg",
        text: "Re-Registration Cancelled",
      });

    case "re-registration-sales-rejected":
      return StatusUi({
        color: "as-skyblue-bg",
        text: "Re-Registration-Sales-Rejected",
      });

    case "registration withdrawn":
      return StatusUi({
        color: "as-yellowshade-bg",
        text: "Registration Withdrawn",
      });

    case "re-registration-rco":
      return StatusUi({ color: "as-red-bg", text: "Re-Registration-RCO" });

    case "re-registration-final":
      return StatusUi({ color: "as-green-bg", text: "Re-Registration-Final" });

    case "closed-ordered":
      return StatusUi({ color: "as-red-bg", text: "Closed-Ordered" });

    case "closed-ordered2":
      return StatusUi({ color: "as-red-bg", text: "Closed-Ordered2" });

    case "assign-subjects":
      return StatusUi({ color: "as-red-bg", text: "assign-subjects" });
    case "not yet registered":
       return StatusUi({ color: "as-grey-bg", text: "Not Yet Registered" });    
    case "not yet registered - contacted":
       return StatusUi({ color: "as-lavendershade-bg", text: "NOT YET REGISTERED-CONTACTED" });   
    case "not yet responded":
        return StatusUi({ color: "as-grey-bg", text: "NOT YET RESPONDED" });
    case "not yet responded - contacted":
        return StatusUi({ color: "as-lavendershade-bg", text: "NOT YET RESPONDED-CONTACTED" });
    case "declined - contacted":
        return StatusUi({ color: "as-orangeshade-bg", text: "DECLINED-CONTACTED" });
    case "declined":
        return StatusUi({ color: "as-red-bg", text: "Declined" });
    case "interested":
         return StatusUi({ color: "as-blue-bg", text: "Intrested" });
    case "interested - contacted":
        return StatusUi({ color: "as-limeshade-bg", text: "INTRESTED-CONTACTED" });
    case "re-registration-re-posted":
        return StatusUi({ color: "as-limeshade-bg", text: "Re-Registration-Re-Posted" });
    case "sales":
        return StatusUi({ color: "as-limeshade-bg", text: "Sales" });
    
    default:
      return "-"
  }
};

const StatusUi = ({ color, text }) => {
  return (
    // <p className={"cat " + color}>
    //   <i className="fas fa-circle mr-1"></i> {text}
    // </p>
    
      <span className={color + " as-widget"}>{text}</span>
    
  );
};

export const StatusRenderList = (values) => {
  // console.log(props.status)
  switch (values ? values.toLowerCase() : values) {
    case "cores":
      return "as-green-bg as-widget";
    case "closed":
      return "as-green-bg as-widget";

    case "new":
      return "as-skyblue-bg as-widget";
    case "canceled":
      return "as-skyblue-bg as-widget";
    case "today":
      return "as-skyblue-bg as-widget";

    case "pending-student":
      return "as-red-bg as-widget";

    case "pending-academy":
      return "as-blue-bg as-widget";

    case "pending-investigation":
      return "as-yellowshade-bg as-widget";

    case "open":
      return "as-yellow-bg as-widget";

    case "past":
      return "as-past-bg as-widget";

    case "cancelled":
      return "as-skyblue-bg as-widget";

    case "not launched":
      return "as-not-launched-bg as-widget";

    case "not-launched":
      return "as-not-launched-bg as-widget";

    case "live-not launched":
      return "as-red-bg as-widget";

    case "live-launched":
      return "as-green-bg as-widget";

    case "ended":
      return "as-ended-bg as-widget";

    case "live":
      return "as-live-bg as-widget";

    case "upcoming":
      return "as-upcoming-bg as-widget";

    case "active":
      return "as-green-bg as-widget";

    case "in active":
      return "as-red-bg as-widget";

    case "drop out":
      return "as-blue-bg as-widget";

    case "on hold":
      return "as-yellowshade-bg as-widget";

    case "finished":
      return "as-orange-bg as-widget";

    case "in progress":
      return "as-yellowshade-bg as-widget";

    default:
      return <p>N/A</p>;
  }
};
const DatesUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 10)}
    </span>
  );
};

export const RenderBooleanStatus = (status) =>{
  switch(status){
    case "no":
      return StatusUi({ color: "as-red-bg", text: "No" });
    case "yes":
      return StatusUi({ color: "as-green-bg", text: "Yes" });
    case "0":
      return StatusUi({ color: "as-red-bg", text: "No" });
    case "1":
      return StatusUi({ color: "as-green-bg", text: "Yes" });
    case 0:
      return StatusUi({ color: "as-red-bg", text: "No" });
    case 1:
      return StatusUi({ color: "as-green-bg", text: "Yes" });          
    default : 
      return "-"
  }
}