import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { ExamsRegReportList } from "../../../../services/ExternalExamServices";
import { StudyTypeColor } from "../../../systemadministration/usermanagement/CheckRole";
import Tablefilter from "../../../common/Tablefilter";
import { RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../utils/commonFunction";
import axios from "axios";

const RegistrationReportList = () => {
  const loc = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [userData, setUserData] = useState([]);

  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("first_name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [studyFilterData, setStudyFilterData] = useState([])
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} })
  const givenPermsisions = useSelector((state) => state.givenPermission);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Filtering

  // TODO Reset Filter

  useEffect(() => {
    let response = hasPermission({ scopes: ["eerrview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const exam = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStudyType: learningMethod?.arr?.length ? learningMethod?.arr : [],
        exam: id,
      };

      try {
        const res = await ExamsRegReportList(exam, source.token);

        if(res.status==200){
          setUserData(res?.data?.data?.data);
          setTotalRows(res.data?.data?.total);
          let learning=[];
          let newArr=res.data.study_type!=null && res.data.study_type;
          Object.keys(newArr).length && Object.keys(newArr).map((val)=>{
            learning.push({value:val,label:newArr[val]})
          })
          setStudyFilterData(learning);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }

    }
    
    getListData();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [page,perPage,sortkey,sortOrder,search,status,id,learningMethod]);

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setLearningMethod({ arr: [], checkObj: {} })

    setSearch("");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const renderCourses = (course) => {
    return (
      <span className="feature-name">
        <span className="textLimit100">
          {course.map(item => {
            return (
              <>
                <Link to={`/courseAdministration/coursesdetails/${item?.getSubjects?.id}/detailCourses/show`} className="as-text-blue curser feature-name" title={item?.getSubjects?.SpCourse[0]?.name}>{item?.getSubjects?.SpCourse[0]?.name}</Link>
                <br />
              </>
            )
          })}
        </span>
      </span>
    )
  }

  const renderLearningMethods = (course) => {
    return (
      <span className="feature-name">
        <span className="textLimit100">
          {course.map(item => RenderLearningMethod(item?.getSubjects?.type).html)}
        </span>
      </span>
    )
  }

  const columns = useMemo(() => [
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => (row.first_name ? (
        <span className="textLimit100" title={row.first_name}>
          {row.first_name}
        </span>
        ) : "-"),
    },
    {
      name: "Second Name",
      selector: "second_name",
      sortable: true,
      cell: (row) => (row.second_name ? (
        <span className="textLimit100" title={row.second_name}>
          {row.second_name}
        </span>
        ) : "-"),
    },
    {
      name: "Third Name",
      selector: "third_name",
      sortable: true,
      cell: (row) => (row.third_name ? (
        <span className="textLimit100" title={row.third_name}>
          {row.third_name}
        </span>
        ) : "-"),
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      cell: (row) => (row.last_name ? (
        <span className="textLimit100" title={row.last_name}>
          {row.last_name}
        </span>
        ) : "-"),
    },
    {
      name: "Student Reg Number",
      selector: "student_reg_number",
      sortable: true,
      cell: (row) => (row?.student_reg_number ? (
        <span className="textLimit100" title={row.student_reg_number}>
          {row.student_reg_number}
        </span>
        ) : "-"),
    },
    {
      name: "Centre",
      selector: "centre",
      sortable: true,
      cell: (row) => (row.registrationsCentresNames ? 
      <Link 
      to={`/studentAdministration/externalExam/examCentres/edit/form/${row.registrationsCentresNames.id}`} 
      className="feature-name as-text-blue curser " title={`${row.registrationsCentresNames.code}(${row.registrationsCentresNames.name})`}>
        <span className="textLimit100">
          {`${row.registrationsCentresNames.code}(${row.registrationsCentresNames.name})`}
          </span>
          </Link>: "-"),
    },
    {
      name: "Courses",
      // selector: "couses",
      // sortable: true,
      // cell: (row) => row.registrationsSubjects.length && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects.SpCourse.length && row.registrationsSubjects[0].getSubjects.SpCourse[0].name ? <span className="feature-name"> <span className="textLimit100">{row.registrationsSubjects[0].getSubjects.SpCourse[0].name}</span></span> : "-"
      cell: (row)=> row?.registrationsSubjects.length ? renderCourses(row?.registrationsSubjects) : "-",
    },
    {
      name: "Learning Method",
      // selector: "study_type",
      // sortable: true,
      // cell: (row) => row.registrationsSubjects.length && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects.type ? RenderLearningMethod(row.registrationsSubjects[0].getSubjects.type).html : "-"
      cell: (row)=> row.registrationsSubjects.length ? renderLearningMethods(row?.registrationsSubjects) : "-",
    },
    {
      name: "Race",
      selector: "race",
      sortable: true,
      cell: (row) => (row.race ? capitalize(row.race) : "-"),
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
      cell: (row) => (row.gender ? capitalize(row.gender) : "-"),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "First Name",
      "Second Name",
      "Third Name",
      "Last Name",
      "Student Reg Number",
      "Centre",
      "Courses",
      "Learning Method",
      "Race",
      "Gender",
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const exam = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStatus: status,
      exam: id,
      exportStatus:"true"
    };

    ExamsRegReportList(exam)
      .then((res) => {
        data=res.data.data;
        data = data?.map((row) => ({
          ...row,
          "First Name": row?.first_name ? row?.first_name : "-",
          "Second Name": row?.second_name ? row?.second_name : "-",
          "Third Name": row?.third_name ? row?.third_name : "-",
          "Last Name": row?.last_name ? row?.last_name : "-",
          "Student Reg Number": row.exam_id_number ? row.exam_id_number : row.rsa_identity?row.rsa_identity :"-",
          Centre: row?.registrationsCentresNames ? `${row.registrationsCentresNames.code}${row.registrationsCentresNames.name}` : "-",
          Courses: row.registrationsSubjects.length && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects.SpCourse.length && row.registrationsSubjects[0].getSubjects.SpCourse[0].name ? row.registrationsSubjects[0].getSubjects.SpCourse[0].name : "-",
          "Learning Method": row.registrationsSubjects.length && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects && row.registrationsSubjects[0].getSubjects.type ? RenderLearningMethod(row.registrationsSubjects[0].getSubjects.type).text : "-" ,
          Race: row?.race ? row?.race :"-",
          Gender: row?.gender ? row?.gender : "-",
         
        }));
    
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          console.log(csvString);
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
            Swal.close();
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["First Name"],
              row["Second Name"],
              row["Third Name"],
              row["Last Name"],
              row[ "Student Reg Number"],
              row.Centre,
              row.registrationsSubjects[0].getSubjects.SpCourse[0].name,
              RenderLearningMethod(row.registrationsSubjects[0].getSubjects.type).text,
              row.Race,
              row.Gender,
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
            columnStyles: {
              0: {
                columnWidth: 12,
              },
              1: {
                columnWidth: 14,
              },
              2: {
                columnWidth: 12,
              },
              3: {
                columnWidth: 12,
              },
              4: {
                columnWidth: 20,
              },
              5: {
                columnWidth: 40,
              },
              6: {
                columnWidth: 33,
              },
              7: {
                columnWidth: 12,
              },
              8: {
                columnWidth: 12,
              },

            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                </div>
              </div>
              <div className="filter-button-group">
                {/* <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={studyFilterData}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                  </div>
                </div> */}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Registration Report");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Registration Report");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Registration Report");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            data={userData}
            defaultSortField="first_name"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
          />
      </div>
    </div>
  );
};

export default RegistrationReportList;
