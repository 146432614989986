// import React, { useEffect, useState } from "react";
// import { useFormik } from "formik";
// import DatePicker from "react-datepicker";
// import Select from "react-select";
// import "react-datepicker/dist/react-datepicker.css";
// import { GetAssessmentDetail, UpdateAssessment, GetStudentDropdownList } from "../../../../../services/SmartAssessmentService";
// import { useParams } from "react-router";
// import moment from "moment";
// import { Hours, Minutes } from "../../../../systemadministration/DataReactSelect";
// import Swal from "sweetalert2";
// import * as Yup from "yup";
// import PermissionsGate from "../../../../../utils/permissionGate";
// import { useHistory } from "react-router";
// import { useSelector } from "react-redux";
// import hasPermission from "../../../../../utils/hasMultiplePermission";

// const Detail = () => {
//   const history = useHistory();
//   const { id } = useParams();

//   const givenPermsisions = useSelector((state) => state.givenPermission);

//   const [data, setData] = useState();
//   const [disabled, setDisabled] = useState(false);
//   const [userList, setUserList] = useState([]);
//   const [viewAns, setViewAns] = useState()
//   const [screenShare, setScreenShare] = useState()
//   const [camera, setCamera] = useState()
//   const [audio, setAudio] = useState()

//   useEffect(() => {
//       let response = hasPermission({ scopes: ["saaview"], permissions: givenPermsisions });
//       if (!response) {
//         history.push("/noaccess");
//       }
//   }, [])

//   useEffect(() => {
//     GetStudentDropdownList().then((res) =>{
//       setUserList(res.data.students)
//     })
//     .catch(err => {
//       console.error("error : ", err)
//     })
//     GetAssessmentDetail({ id: id }).then((res) => {
//       setData(res.data[0])
//     }).catch((err) => console.log(err));
//   }, [])

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       name: data?.name ? data?.name : "",
//       ass_weighting: data?.ass_weighting !== null && data?.ass_weighting.toString() ? data?.ass_weighting : "",
//       ass_type: 4,
//       code: data?.code ? data?.code : "",
//       open_date_time: data?.open_date_time ? moment.unix(data?.open_date_time)._d : "",
//       close_date_time: data?.close_date_time ? moment.unix(data?.close_date_time)._d : "",
//       maximum_attempts: data?.maximum_attempts ? data?.maximum_attempts : "",
//       pass_percentage: data?.pass_percentage ? data?.pass_percentage : "",
//       notification_email: data?.notification_email && userList.length ? data?.notification_email.split(",").map((email)=>{
//         console.log("email",email);
//         let emailData=userList.filter((data)=>{
//           return data.value == email
//         })
//         console.log("emailData",emailData);
//         if(emailData.length){
//           return emailData[0]
//         }
//         return emailData;
//       }) : "Select Notification List",
//       identification: data?.identification == 1 ? true : false   ,
//       view_answer: viewAns == true ? 1 : viewAns == false ? 0 : data?.view_answer == 1 ? true : false ,
//       screen_share: screenShare == true ? 1 : screenShare == false ? 0 : data?.screen_share == 1 ? true : false,
//       camera:  camera == true ? 1 : camera == false ? 0 : data?.camera == 1 ? true : false,
//       audio:  audio == true ? 1 : audio == false ? 0 : data?.audio == 1 ? true : false,
//       timeLimitActive: 0,
//       timeHour: data?.timeHour ? data.timeHour : "",
//       timeMin: data?.timeMin ? data.timeMin : "",
//       isDuration: data?.isDuration == 1 ? true : false
//     },
//     validationSchema: Yup.object({
//       name: Yup.string().required("Name is required"),
//       // notification_email: Yup.string().email("Invalid Email address"),
//       maximum_attempts: Yup.string().required("Maximum Attempts is required").matches("^[0-9]*$", "Maximum Attempts Only accept numbers").test("Is positive?", "Maximum Attempts must be between One and Ten", value => parseInt(value) > 0 && parseInt(value) < 11),
//       timeHour: Yup.string().required("Hours is required"),
//       timeMin: Yup.string().required("Min is required"),
//       ass_type: Yup.string().required("Assessment Type is required"),
//       ass_weighting: Yup.string().matches("[+-]?([0-9]*[.])?[0-9]+", "Weight Only accept numbers"),
//       pass_percentage: Yup.string().matches("^[0-9]*$", "Pass Percentage Only accept numbers"),
//     }),
//     onSubmit: (values) => {
//       setDisabled(true)
//       let start_date = formik.values.open_date_time ? moment(formik.values.open_date_time).format("X") : "";
//       let end_date = formik.values.close_date_time ? moment(formik.values.close_date_time).format("X") : "";

//       let tempNotificationEmail =[];

//       let final = values.notification_email.map(item => tempNotificationEmail.push(item.value))
//       values.isDuration = values.isDuration == true ? 1 : 0
//       values.view_answer = values.view_answer == true ? 1 : 0
//       values.identification = values.identification == true ? 1 : 0
//       values.camera = values.camera == true ? 1 : 0
//       values.screen_share = values.screen_share == true ? 1 : 0
//       values.audio = values.audio == true ? 1 : 0

//       UpdateAssessment({ ...values, assessmentId: id, start_date: start_date, end_date: end_date, notification_email: tempNotificationEmail.join(","), weight: formik.values.ass_weighting })
//       // UpdateAssessment({ assessmentId: id})
//         .then((res) => {
//           Swal.fire({
//             icon: "success",
//             title: "Success",
//             text: "Updated Successfully"
//           })
//           setDisabled(false)
//           history.push("/courseAdministration/smartAssessment/assessment/list")
//         }).catch((err) => {
//           Swal.fire({
//             icon: "error",
//             title: "Errors",
//           });
//           setDisabled(false)
//         });
//     },
//   });

//   const filterPassedTime = (time) => {
//     const currentDate = new Date();
//     const selectedDate = new Date(time);
//     return currentDate.getTime() < selectedDate.getTime();
//   };

//   return (
//     <div>
//       <div className="card card-profile-info-card course-details-tab-sec">
//         <div>
//           <form onSubmit={formik.handleSubmit}>
//             <div className="course-info-sec">
//               <div className="edit-icon new-card-header">
//                 <div className="card-header">Assessment Details</div>
//               </div>
//               <div className="card-body-inr card-body-info">
//                 <div className="row">
//                   <div className="col-md-4">
//                     <label htmlFor="Assessment-name">Assessment Name *</label>
//                     <div className="form-icon-group mb-4" title="Title">
//                       <input type="text" className={"form-control" + (formik.errors.name && formik.touched.name ? " is-invalid" : "")} name="name" id="Assessment-name" placeholder="Assessment Name" title="Assessment Name" value={formik.values.name ? formik.values.name : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
//                     </div>

//                     <label htmlFor="weight">Weight </label>
//                     <div className="form-icon-group mb-4 " title="weight">
//                       <div className="form-icon-group mb-4">
//                         <input type="number" className={"form-control" + (formik.errors.ass_weighting && formik.touched.ass_weighting ? " is-invalid" : "")} name="ass_weighting" id="ass_weighting" placeholder="Weight" title="Weight" value={formik.values.ass_weighting ? formik.values.ass_weighting : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="col-md-4">
//                     <label htmlFor="Type">Type *</label>
//                     <div className="form-icon-group mb-4">
//                       <input type="text" className={"form-control" + (formik.errors.ass_type && formik.touched.ass_type ? " is-invalid" : "")} name="ass_type" title="Smart Assessment" id="Type" placeholder="Smart Assessment" disabled />
//                     </div>

//                     <label htmlFor="open_date_time">Open Date</label>
//                     <div className="form-icon-group mb-4">
//                       <div className="form-group-blk w-100">
//                         <DatePicker
//                           name="open_date_time"
//                           id="open_date_time"
//                           selected={formik?.values?.open_date_time}
//                           onChange={(date) => {
//                             formik.setFieldValue("open_date_time", date);
//                             // setOpenDate(date);
//                           }}
//                           showTimeSelect
//                           filterTime={filterPassedTime}
//                           className={"form-control cursor-pointer" + (formik.errors.open_date_time && formik.touched.open_date_time ? " is-invalid" : "")}
//                           title="Open Date & Time"
//                           onBlur={formik.handleBlur}
//                           placeholderText="Select Date & Time"
//                           timeIntervals={15}
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="col-md-4">
//                     <label htmlFor="Code">Code </label>

//                     <div className="form-icon-group mb-4">
//                       <input type="text" className={"form-control" + (formik.errors.code && formik.touched.code ? " is-invalid" : "")} name="code" id="Code" placeholder="Code" title="Code" value={formik.values.code ? formik.values.code : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
//                     </div>

//                     <label htmlFor="close_date_time">Close Date</label>
//                     <div className="form-icon-group mb-4">
//                       <div className="form-group-blk w-100">
//                         <DatePicker
//                           name="close_date_time"
//                           id="close_date_time"
//                           selected={formik?.values?.close_date_time}
//                           onChange={(date) => {
//                             formik.setFieldValue("close_date_time", date);
//                             // setOpenDate(date);
//                           }}
//                           showTimeSelect
//                           filterTime={filterPassedTime}
//                           className={"form-control cursor-pointer" + (formik.errors.close_date_time && formik.touched.close_date_time ? " is-invalid" : "")}
//                           title="Close Date & Time"
//                           onBlur={formik.handleBlur}
//                           placeholderText="Select Date & Time"
//                           timeIntervals={15}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="course-info-sec">
//               <div className="edit-icon new-card-header">
//                 <div className="card-header">Assessment Settings</div>
//               </div>
//               <div className="card-body-inr card-body-info">
//                 <div className="row">
//                   <div className="col-md-4">
//                     <label htmlFor="maxattempts">Maximum Attempts *</label>
//                     <div className="form-icon-group mb-4">
//                       <input type="number" className={"form-control" + (formik.errors.maximum_attempts && formik.touched.maximum_attempts ? " is-invalid" : "")} name="maximum_attempts" id="maxattempts" placeholder="Maximum Attempts" title="Maximum Attempts" value={formik.values.maximum_attempts ? formik.values.maximum_attempts : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
//                     </div>

//                     <div className="form-group">
//                       <label htmlFor="maximum_attempts">Notification List</label>
//                       <div className="form-icon-group mb-4">
//                         <Select
//                           className={
//                             "form-control custom-select-box " +
//                             (formik.errors.notification_email && formik.touched.notification_email
//                               ? " is-invalid"
//                               : "")
//                           }
//                           name="Notification List"
//                           onChange={(value) => {
//                             formik.setFieldValue("notification_email",value)
//                           }}
//                           onBlur={formik.handleBlur}
//                           options={userList.splice(userList - 100, 100)}
//                           maxMenuHeight={175}
//                           isMulti
//                           placeholder={formik.values.notification_email || "Select"}
//                           // value={formik.values.notification_email}
//                           // value={userList.filter((val)=>{
//                           // formik.values.notification_email.map((email)=>{
//                           //     return val.value==email
//                           //   })
//                           // })}
//                           value={formik.values.notification_email || ""}
//                         />
//                         {/* <input type="text" className={"form-control" + (formik.errors.notification_email && formik.touched.notification_email ? " is-invalid" : "")} name="maximum_attempts" id="maximum_attempts" placeholder="Notification Email" title="Notification Email" value={formik.values.notification_email ? formik.values.notification_email : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} /> */}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-4">
//                     <label htmlFor="pass_percentage">Minimum Percentage</label>
//                     <div className="form-icon-group mb-4">
//                       <input type="number" className={"form-control" + (formik.errors.pass_percentage && formik.touched.pass_percentage ? " is-invalid" : "")} name="pass_percentage" id="pass_percentage" placeholder="Minimum Percentage" title="Minimum Percentage" value={formik.values.pass_percentage ? formik.values.pass_percentage : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />
//                     </div>
//                   </div>
//                   <div className="col-md-4">
//                     <div className="form-icon-group mt-4">
//                       <div className="custom-check-group mt-4">
//                         <div className="custom-check custom-control custom-checkbox mt-2">
//                           <input type="checkbox" className="custom-control-input" id="customCheck5" name="view_answer" checked={formik.values.view_answer} onChange={(e)=>{formik.setFieldValue("view_answer", e.target.checked)}} onBlur={formik.handleBlur} />
//                           <label className="custom-control-label" htmlFor="customCheck5">
//                             Show Correct Answers
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="course-info-sec">
//               <div className="edit-icon new-card-header">
//                 <div className="card-header">Assessment Conditions</div>
//               </div>
//               <div className="card-body-inr card-body-info">
//                 <div className="row">
//                 <div className="col-md-1">
//                 <div className="form-group-blk mb-3">
//                   <label>&nbsp;</label>
//                   <div className="custom-check-group">
//                     <div className="custom-check custom-control custom-checkbox mt-2">
//                       <input type="checkbox" className="custom-control-input" id="durationCheckbox" name="select" checked={formik.values.isDuration || false} onChange={(e) => formik.setFieldValue("isDuration", e.target.checked)} />
//                       <label className="custom-control-label" htmlFor="durationCheckbox">
//                         Duration
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//                   <div className="col-md-4">
//                     <div className="form-group-blk mb-3">

//                       <label htmlFor="timeHour">Time Limit *</label>
//                       <div className="row form-grp-dates time-select">
//                         <Select
//                           // isDisabled={!isEditable && type === "open"}
//                           isDisabled={ (formik.values.isDuration === false)}
//                           className={"form-control custom-select-box" + (formik.errors.timeHour && formik.touched.timeHour ? " is-invalid" : "")}
//                           name="Hour"
//                           id="timeHour"
//                           title="Time Limit Hour"
//                           placeholder={formik.values.timeHour ? formik.values.timeHour : "Hours"}
//                           options={Hours}
//                           onBlur={formik.handleBlur}
//                           onChange={(value) => {
//                             formik.setFieldValue("timeHour", value.value);
//                           }}
//                           value={formik.values.timeHour || ""}
//                           />
//                         <Select
//                           // isDisabled={!isEditable && type === "open"}
//                           isDisabled={ (formik.values.isDuration === false)}
//                           className={"form-control custom-select-box" + (formik.errors.timeMin && formik.touched.timeMin ? " is-invalid" : "")}
//                           name="Minute"
//                           id="timeMin"
//                           title="Time Limit Minute"
//                           placeholder={formik.values.timeMin ? formik.values.timeMin : "Minutes"}
//                           options={Minutes}
//                           onBlur={formik.handleBlur}
//                           onChange={(value) => {
//                             formik.setFieldValue("timeMin", value.value);
//                           }}
//                           value={formik.values.timeMin || ""}
//                           />
//                           </div>
//                     </div>
//                   </div>
//                   <div className="col-md-5">
//                     <div className="form-group-blk mb-3">

//                       <div className="custom-check-group mt-4">
//                         <div className="custom-check custom-control custom-checkbox mt-2">
//                           <input type="checkbox" className="custom-control-input" id="customCheck1" name="identification" checked={formik.values.identification || false} onChange={(e) => {formik.setFieldValue("identification", e.target.checked)}} />
//                           <label className="custom-control-label" htmlFor="customCheck1">
//                             Identification
//                           </label>
//                         </div>
//                         <div className="custom-check custom-control custom-checkbox mt-2">
//                           <input type="checkbox" className="custom-control-input" id="customCheck2" name="camera" checked={formik.values.camera || false} onChange={(e) => {formik.setFieldValue("camera", e.target.checked)}} />
//                           <label className="custom-control-label" htmlFor="customCheck2">
//                             Camera
//                           </label>
//                         </div>
//                         <div className="custom-check custom-control custom-checkbox mt-2">
//                           <input type="checkbox" className="custom-control-input" id="customCheck3" name="screen_share" checked={formik.values.screen_share || false} onChange={(e) => {formik.setFieldValue("screen_share", e.target.checked)}} />
//                           <label className="custom-control-label" htmlFor="customCheck3">
//                             Screenshare
//                           </label>
//                         </div>
//                         <div className="custom-check custom-control custom-checkbox mt-2">
//                           <input type="checkbox" className="custom-control-input" id="customCheck4" name="audio" checked={formik.values.audio || false} onChange={(e) => {formik.setFieldValue("audio", e.target.checked)}} />
//                           <label className="custom-control-label" htmlFor="customCheck4">
//                             Audio
//                           </label>
//                         </div>

//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//             </div>

//             <div className="form-group form-group-save-cancel mt-3">
//               <PermissionsGate scopes={["saaedit"]} errorProps={{ disabled: true }}>
//               <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled}>
//                 {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
//                 Save
//               </button>
//               </PermissionsGate>
//               <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()} >
//                 <i className="fal fa-times"></i>Cancel
//               </button>

//               {formik.touched.name && formik.errors.name ? <div className="invalid-feedback d-block">{formik.errors.name}</div> : null}
//               {formik.touched.notification_email && formik.errors.notification_email ? <div className="invalid-feedback d-block">{formik.errors.notification_email}</div> : null}
//               {formik.touched.timeHour && formik.errors.timeHour ? <div className="invalid-feedback d-block">{formik.errors.timeHour}</div> : null}
//               {formik.touched.timeMin && formik.errors.timeMin ? <div className="invalid-feedback d-block">{formik.errors.timeMin}</div> : null}
//               {formik.touched.ass_type && formik.errors.ass_type ? <div className="invalid-feedback d-block">{formik.errors.ass_type}</div> : null}
//               {formik.touched.ass_weighting && formik.errors.ass_weighting ? <div className="invalid-feedback d-block">{formik.errors.ass_weighting}</div> : null}
//               {formik.touched.pass_percentage && formik.errors.pass_percentage ? <div className="invalid-feedback d-block">{formik.errors.pass_percentage}</div> : null}
//               {formik.touched.maximum_attempts && formik.errors.maximum_attempts ? <div className="invalid-feedback d-block">{formik.errors.maximum_attempts}</div> : null}

//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Detail;

import React, { useEffect, useState } from "react";
import Header from "../../../../common/Header";
import SideBar from "../../../../common/SideBar";
import Tabs from "../../../Tabs";
import { getCurrentTimeHomePage } from "../../../../common/Helper";
import { useFormik } from "formik";
import Select from "react-select";
import {
  Hours,
  Minutes,
  QuizHours,
  QuizMinutes,
} from "../../../../systemadministration/DataReactSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import * as Yup from "yup";
import {
  AddsmartAssessment,
  createQuiz,
  GetAssessmentDetail,
  getQuizDetails,
  GetQuizTopics,
  GetStudentDropdownList,
  UpdateAssessment,
  updateQuiz,
} from "../../../../../services/SmartAssessmentService";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getCommonContent } from "../../../../../services/ProgramService";
import draftToHtml from "draftjs-to-html";
import { CheckJSONString } from "../../../../common/CheckJSONString";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import HtmlParser from "react-html-parser";
import { RenderPMCStatus, RenderQuizStatus } from "../../../../../utils/CommonGroupingItem";
import PermissionsGate from "../../../../../utils/permissionGate";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { te } from "date-fns/locale";

const Detail = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [editorState1, setEditorState1] = React.useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userList, setUserList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [data, setData] = useState();

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [topicArray,setTopicArray] = useState([]);
  const [typeArray,setTypeArray] = useState([]);

  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
        return primer(x[field]);
      }
      : function (x) {
        return x[field];
      };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  useEffect(() => {
    let response = hasPermission({ scopes: ["cquizmview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }

    setLoading(true)
    GetStudentDropdownList()
      .then((res) => {
        setUserList(res.data.students);
      })
      .catch((err) => {
        console.error("error : ", err);
      });
    id &&
      getQuizDetails({ id: id })
        .then((res) => {
          setData(res.data[0]);
          setEditorState1(res.data[0].description);
          let generalNote= CheckJSONString(res.data[0].description);
            if(generalNote){
              const blocksFromHTML2 = convertFromRaw(JSON.parse(res.data[0].description));
              let dataDescr=EditorState.createWithContent(blocksFromHTML2);
              const note = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
              setEditorState1(note ? note :"<p><p/>")
            }
            else{
              setEditorState1(res.data[0].description)
            }
          if(res.data[0].assigned_question_no>0){
            setMaxCount(res.data[0].assigned_question_no)
          }
          setLoading(false)
          // formik.setFieldValue("status", data?.status)
          // let keywords = res.data[0].keywords.length > 0 ? res.data[0].keywords.split(",").map(item=>item.replaceAll('\"',"").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          let keywords = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
          setSelectedKeywords(keywords);
          // let topics =  res.data[0].topic.length > 0 ? res.data[0].topic.split(",").map(item => item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          let topics =  (res.data[0].topic && ((res.data[0].topic).split(",")).length > 0) ? ((res.data[0].topic).split(",")).map(item => item) : [];
          setTopicArray(topics);
        })
        .catch((err) => console.log(err));
    // id && GetAssessmentDetail({ id: id }).then((res) => {
    //   console.log("responsew", res.data[0].ass_weighting.toString() ? res.data[0].ass_weighting : "")
    //   setData(res.data[0]);
    //   // const contentBlock = convertFromRaw(JSON.parse(res.data[0].description));
    //   // setEditorState1(EditorState.createWithContent(contentBlock));
    //   setEditorState1(res.data[0].description);
    // }).catch((err) => console.log(err));

    getCommonContent()
      .then((res) => {
        setStatusList(res?.data?.quizStatus);
      })
      .catch((err) => {
        console.error("error : ", err);
      });
      GetQuizTopics().then(res=>{
        let tempArr = res?.data?.map(item=>{return {value:item?.id,label:HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
        setTopicList(tempArr);
      }).catch(error=>console.error("GetQuizTopics",error));
  }, []);
  
  let selectedTopics = [];
  let Topics = topicList?.map(item=>{
    return topicArray?.map(val=>{
      if(item.value == val){
        return selectedTopics.push(item);
      }
    })
  })
  
 
  let selectedTypes = [];
  

  const statusArr = [
    {
      value: "Draft",
      label: HtmlParser(`<span class="red" title="Draft">Draft</span> `)
    },
    {
      value: "New",
      label: HtmlParser(`<span class="skyblue" title="New">New </span>`)
    },
    {
      value: "Final",
      label: HtmlParser(`<span class="green" title="Final">Final</span> `)
    },
    {
      value: "Approved",
      label: HtmlParser(`<span class="yellowshade" title="Approved">Approved</span>`)
    },
    // {
    //   value: "Active",
    //   label: HtmlParser(`<span class="green" title="Final">Active</span>`)
    // },
    {
      value: "Inactive",
      label: HtmlParser(`<span class="blue" title="Inactive">Inactive</span>`)
    },
  ]
  

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const multiSelectColorStyles = {      
    multiValueLabel: (styles, { data }) => ({
      ...styles,
        '& span':{
          background: 'transparent !important',
          color: '#333 !important',
        }
    }),
  };
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.quiz_name ? data?.quiz_name : "",
      maximum_attempts: data?.maximum_attempts ? data?.maximum_attempts : "",
      pass_percentage: data?.pass_percentage ? data?.pass_percentage : "",
      noOfQuestion: data?.number_of_questions ? data?.number_of_questions : "",
      timeHour: data?.time_limit_hours ? data.time_limit_hours : 0,
      timeMin: data?.time_limit_minutes ? data.time_limit_minutes : 0,
      status: data?.status || "",
      duplicateId: "",
      view_answer: data?.show_correct_answer == 1 ? true : false,
      is_random: data?.is_random == 1 ? true : false,
      description: "",
      keywords: selectKeywords,
      topic: selectedTopics,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").trim(),
      status: Yup.string().required("Status is required"),
      maximum_attempts: Yup.string()
        .required("Max Attempts is required")
        .matches("^[0-9]*$", "Maximum Attempts Only accept numbers")
        .test(
          "Is positive?",
          "Maximum Attempts must be between One and Ten",
          (value) => parseInt(value) > 0 && parseInt(value) < 11
        ),
      timeMin: Yup.string().when(["timeHour"], {
        is : (timeHour)=>(timeHour=="" || timeHour==undefined || parseInt(timeHour)<1),
        then : Yup.string().test("Is Valid", "Time Limit should be at least 5 min.", (value) => parseInt(value)>=5)
      }),
      pass_percentage: Yup.string()
        .matches("^[0-9]*$", "Minimum Percentage Only accept numbers")
        .required("Minimum Percentage is required"),
      keywords: Yup.array().min(1, "At least one Keyword is required").required("Keyword is required").nullable(),
      topic: Yup.array().min(1, "At least one Topic is required").required("Topic is required").nullable(),
      // todo make maximum  number allowed dynamic
      noOfQuestion: Yup.number().min(1, "Number of question must be greater than zero").max(maxCount, `Number of question must be less than ${maxCount}`),
    }),

    onSubmit: (values) => {
      setDisabled(true);
      let check_no_dated = formik.values.open_date_time || formik.values.close_date_time ? 0 : 1;

      const selectedTopics = values.topic.map(item=>{return item?.value}).join(",");
      const keywords = values.keywords;

      const body = {
        quiz_name: values.name,
        maximum_attempts: values.maximum_attempts,
        pass_percentage: values.pass_percentage,
        show_correct_answer: values.view_answer == true ? 1 : 0,
        is_random: values.is_random == true ? 1 : 0,
        time_limit_hours: values.timeHour,
        time_limit_minutes: values.timeMin,
        status: values.status,
        description: editorState1,
        id: id,
        keywords: JSON.stringify(keywords),
        topic: selectedTopics,
        number_of_questions:values.noOfQuestion ? values.noOfQuestion : 0
      };
      updateQuiz(body)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          setDisabled(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setDisabled(false);
        });
    },
  });
  
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handlereset = () => {
    formik.resetForm();
    setEditorState1(EditorState.createEmpty());
  };

  const hourHandle = () => {
    let hourArr = [];
    for (let i = 0; i <= 23; i++) {
      hourArr.push({
        value:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "h",
      });
    }
    // ()=>setHourArray(hourArr)
    return hourArr;
  };

  const MinutesHandle = () => {
    let minutesArray = [];

    for (let i = 0; i <= 59; i++) {
      minutesArray.push({
        value:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "min",
      });
    }
    // ()=>{setMinuteArray(minutesArray)}
    return minutesArray;
  };

  const handleKeyDown1 = (event) => {
    let keywords = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keywords.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keywords);
      }
    }
  };
  const removeKeywords = (index) => {
    let keyword = selectKeywords;
    let keywords = keyword.splice(index, 1);
    formik.setFieldValue("keywords", keyword);
  };

  return (
    <>
      <div className="tabs-wrap">
        <div className="">
          <div className="card card-profile-info-card course-details-tab-sec">
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="course-info-sec">
                  {/* <div className="edit-icon new-card-header">
                                <div className="card-header">Assessment Details</div>
                              </div> */}
                  <div className="card-body-inr card-body-info">
                    <div className="row">
                      <div className="col-md-6 col-lg-2">
                        <label>Name *</label>
                        <div className="form-icon-group mb-4" title="Name">
                          <input
                            type="text"
                            className={
                              "form-control" +
                              (formik.errors.name && formik.touched.name
                                ? " is-invalid"
                                : "")
                            }
                            name="name"
                            placeholder="Name"
                            title="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>                      
                      <div className="col-md-6 col-lg-2">
                        <label>Maximum Attempts *</label>
                        <div
                          className="form-icon-group mb-4"
                          title="Maximum Attempts"
                        >
                          <input
                            type="number"
                            className={
                              "form-control" +
                              (formik.errors.maximum_attempts &&
                              formik.touched.maximum_attempts
                                ? " is-invalid"
                                : "")
                            }
                            name="maximum_attempts"
                            placeholder="Maximum Attempts"
                            title="Maximum Attempts"
                            value={formik.values.maximum_attempts}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2">
                        <label>Minimum Percentage *</label>
                        <div
                          className="form-icon-group mb-4"
                          title="Pass percentage"
                        >
                          <input
                            type="number"
                            className={
                              "form-control" +
                              (formik.errors.pass_percentage &&
                              formik.touched.pass_percentage
                                ? " is-invalid"
                                : "")
                            }
                            name="pass_percentage"
                            placeholder="Minimum Percentage"
                            title="Minimum Percentage"
                            value={formik.values.pass_percentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                          <label>Time Limit </label>
                          <div className="row form-grp-dates time-select">
                            <Select
                              // isDisabled={!isEditable && type === "open"}
                              isDisabled={formik.values.isDuration === false}
                              className={
                                "form-control custom-select-box time-box" +
                                (formik.errors.timeHour &&
                                formik.touched.timeHour
                                  ? " is-invalid"
                                  : "")
                              }
                              name="Hour"
                              title="Time Limit Hour"
                              placeholder={
                                formik.values.timeHour
                                  ? formik.values.timeHour
                                  : "Hours"
                              }
                              options={hourHandle()}
                              onBlur={formik.handleBlur}
                              onChange={(value) => {
                                console.log("inside",value);
                                if (value) {
                                  formik.setFieldValue("timeHour", value.value);
                                } else {
                                  formik.setFieldValue("timeHour", "");
                                }
                              }}
                              value={hourHandle().filter((val) => {
                                return (
                                  val.value == 
                                  Number(
                                    formik.values.timeHour
                                  ).toLocaleString("en-US", {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                  })
                                );
                              })}
                              // isClearable
                            />
                            <Select
                              // isDisabled={!isEditable && type === "open"}
                              isDisabled={formik.values.isDuration === false}
                              className={
                                "form-control custom-select-box time-box" +
                                (formik.errors.timeMin && formik.touched.timeMin
                                  ? " is-invalid"
                                  : "")
                              }
                              name="Minute"
                              title="Time Limit Minute"
                              placeholder={
                                formik.values.timeMin
                                  ? formik.values.timeMin
                                  : "Minutes"
                              }
                              options={MinutesHandle()}
                              onBlur={formik.handleBlur}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("timeMin", value.value);
                                } else {
                                  formik.setFieldValue("timeMin", "");
                                }                                
                              }}
                              value={MinutesHandle().filter(
                                (val) => {
                                  return (
                                    val.value == 
                                    Number(
                                      formik.values.timeMin
                                    ).toLocaleString("en-US", {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    })
                                  );
                                }
                              )}
                              isClearable
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2">
                        <label>Status *</label>
                        <div
                          className="form-icon-group mb-4"
                          title="Status"
                        >
                          <Select
                            styles={colourStyles}
                            className={
                              "form-control color-drop  custom-select-box " +
                              (formik.errors.status && formik.touched.status
                                ? " is-invalid"
                                : "")
                            }
                            name="status"
                            value={statusArr?.filter(val=>val.value === formik.values.status)}
                            onChange={(selectedOption) => {
                              console.log("notificationN4em", selectedOption);
                              if (selectedOption) {
                                formik.setFieldValue("status",selectedOption.value);
                              } else {
                                formik.setFieldValue("status","");
                              }
                            }}
                            onBlur={formik.handleBlur}
                            options={statusArr}
                            maxMenuHeight={175}
                            isClearable
                            // isMulti={true}
                            placeholder={formik.values.status ? formik.values.status.charAt(0).toUpperCase() + formik.values.status.slice(1) : "Select"}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2 mb-4">
                        <label>Keywords*</label>
                        <div className="form-icon-group">
                          <div className="form-control custom-select-box">
                            <input
                              type="text"
                              className={"form-control " + (formik.errors.keywords && formik.touched.keywords ? " is-invalid" : "")}
                              name="keywords"
                              placeholder="keywords"
                              title="keywords"
                              onBlur={formik.handleBlur}
                              onKeyDown={handleKeyDown1}
                            />
                          </div>
                        </div>
                        {selectKeywords.length > 0 && (
                          <ul className="tag-list mb-0">
                            {selectKeywords?.map((ele, index) => (
                              <li key={index}>
                                {ele}
                                <span onClick={() => removeKeywords(index)}>
                                  <i className="fal fa-times"></i>
                                </span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div className="col-md-6 col-lg-2 mb-4">
                        <div className="form-group-blk" title="Pre-Requisites">
                          <label>Topic</label>
                          <Select
                            styles={multiSelectColorStyles}
                            className={
                              "form-control color-drop custom-select-box is-multi" +
                              (formik.errors.topic && formik.touched.topic
                                ? " is-invalid"
                                : "")
                            }
                            name="topic"
                            value={formik.values.topic}
                            onChange={(value) => formik.setFieldValue("topic", value)}
                            onBlur={formik.handleBlur}
                            options={topicList}
                            maxMenuHeight={175}
                            isMulti={true}
                            placeholder="Select Topic"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2 mb-4">
                        <div className="form-group-blk">
                          <label>Number Of Questions</label>
                          <input
                            type="number"
                            className={
                              "form-control" +
                              (formik.errors.noOfQuestion &&
                              formik.touched.noOfQuestion
                                ? " is-invalid"
                                : "")
                            }
                            name="noOfQuestion"
                            placeholder="Number Of Questions"
                            title="The amount of questions that will be asked to each student, leave blank if all questions must be asked"
                            value={formik.values.noOfQuestion}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2">
                        <div className="form-icon-group mt-4">
                          <div className="custom-check-group ">
                            <div className="custom-check custom-control custom-checkbox mt-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck54"
                                name="view_answer"
                                checked={formik.values.view_answer}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck54"
                              >
                                <b>Show Correct Answers</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-2">
                        <div className="form-icon-group mt-4">
                          <div className="custom-check-group ">
                            <div className="custom-check custom-control custom-checkbox mt-2" title="If selected, the questions/groups will be asked in a random order">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck59"
                                name="is_random"
                                checked={formik.values.is_random}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck59"
                              >
                                <b>Randomize Questions</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                      <label>Description</label>
                        {!loading && <HtmlInputEditor
                          editorState={editorState1}
                          setEditorState={setEditorState1}
                          hideSign={true}
                          belowNoMargin={true}
                          isCKEditor={true}
                          isInvalid={formik.touched.description && formik.errors.description}
                          // from="quizManagement"
                        />}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group form-group-save-cancel mt-3">
                  <PermissionsGate scopes={["cquizmedit"]} errorProps={{disabled:true}}>
                  <button
                    className="btn btn-save btn-success"
                    type="button"
                    title="Save"
                    disabled={disabled}
                    onClick={() => formik.handleSubmit()}
                  >
                    {disabled ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                  </PermissionsGate>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => handlereset()}
                  >
                    <i className="fal fa-times"></i>Cancel
                  </button>
                  {Object.keys(formik.values).map((key, ind) => {
                    if (formik.touched[key] && formik.errors[key]) {
                    return (
                        <div className="invalid-feedback d-block" key={ind}>
                        {formik.errors[key]}
                        </div>
                    )}
                  })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;