import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { TABLE_DATE_FORMAT,TABLE_DATE_TIME_FORMAT,TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../../utils/permissionGate";

import { TrimText } from "../../../common/TrimText";
import { DetailExamListData } from "../../../../services/ExternalExamServices";
import Tablefilter from "../../../common/Tablefilter";
import { RenderExternalExamStatus, RenderExternalRegistrationStatus } from "../../../../utils/CommonStatusItems";
import hasPermission from "../../../../utils/hasMultiplePermission";
import axios from "axios";

const DetailsList = () => {
  const history=useHistory();
  const [search, setSearch] = useState("");
  const [registrationStatusFilter, setRegistrationStatusFilter] = useState([]);
  const [searchRegistrationStatus, setSearchRegistrationStatus] = useState({});

  const [examStatusFilter, setexamStatusFilter] = useState([]);
  const [searchExamStatus, setSearcExamStatusStatus] = useState({});
  const [userData, setUserData] = useState([]);
  const [examPeriodStatusFilter, setExamPeriodStatusFilter] = useState([]);
  const [examPeriodFilter, setExamPeriodFilter] = useState([]);
  const [examPeriodStatus, setExamPeriodStatus] = useState({});

  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("start");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [registrationStatus, setRegistrationStatus] = useState({ arr: [], checkObj: {} });
  const [examStatus, setExamStatus] = useState({ arr: [], checkObj: {} });
  const [examPeriod, setExamperiod] = useState({ arr: [], checkObj: {} });
  const givenPermsisions = useSelector((state) => state.givenPermission);

  // TODO Api Call

const registrationStatusArr = [
  {
  value: "open",
  label: "Open"
},
{
  value: "closed",
  label: "Closed"
},
]

const examStatusArr = [
  {
  value: "hide",
  label: "Hide"
},
{
  value: "IN PROGRESS",
  label: "In Progress"
},
{
  value: "closed",
  label: "Closed"
},
]
  useEffect(() => {
    let response = hasPermission({ scopes: ["eerleview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      
      const exam = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaExamStatus: examStatus.arr,
        viaRegExamStatus: registrationStatus.arr,
        viaPeriod: examPeriod.arr,
      };

      try {
        const res = await DetailExamListData(exam, source.token);
        if(res.status=200){
          setUserData(res?.data?.data?.data);
          setExamPeriodFilter(res?.data?.periodFilter);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    
    getListData();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [
    page,
    perPage,
    sortkey,
    sortOrder,
    search,
    examStatusFilter,
    registrationStatusFilter,
    examPeriodStatusFilter,
    searchExamStatus,
    examPeriodStatus,
    searchRegistrationStatus,
    examStatus,
    examPeriod,
    registrationStatus
  ]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Filtering

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Reset Filter

  const resetFilter = () => {
    setRegistrationStatus({ arr: [], checkObj: {} })
    setExamStatus({ arr: [], checkObj: {} })
    setExamperiod({ arr: [], checkObj: {} })
  };

  // TODO Data

  const columns = useMemo(() => [
    {
      name: "Exam Period",
      selector: "period",
      sortable: true,
      sortField: "period",
      cell: (row) => (
      <PermissionsGate scopes={["eerleedit"]} RenderError={() => (
        <p>{row?.period.trim() ? <span className="feature-exam_period"> <span className="textLimit100">{row.period}</span> </span> : "-"}</p>
      )}>
      <Link title={row.period} className="as-text-blue curser feature-name" to={`/studentAdministration/externalExam/details/open/detail/${row.id}`}> 
      <span className="textLimit100">
      {row.period ? row.period : "-"}
    </span>
    </Link>
    </PermissionsGate>
      )
     
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      sortField: "start",
      cell: (row) => (
        <p>{row.start_date ?
          <div className="dateTime">
            <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(row.start_date).format(TABLE_DATE_FORMAT)}</p>
            
          </div>
          : "-"
        }</p>
      )
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      sortField: "end",
      cell: (row) => (
        <>{row.end_date          ?
          <div className="dateTime">
            <p className="right-space">{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(row.end_date).format(TABLE_DATE_FORMAT)}</p>
          </div>
          : "-"
        }</>
      )
    },
    {
      name: "Exam Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      // cell: (row) =>
      //   row.status ? (
      //     ExamStatusColor(row.status)
      //   ) : (
      //     <p className="cat">
      //       <i className="fas fa-circle mr-1"></i> NA
      //     </p>
      //   ),
      cell: (row) => row.status ? RenderExternalExamStatus(row.status).html : "-",
    
    },
    {
      name: "Registration Status",
      selector: "register_status",
      sortable: true,
      sortField: "register_status",
      // cell: (row) =>
      //   row.register_status ? (
      //     DetailRegistrationStatusColor(row.register_status.toLowerCase())
      //   ) : (
      //     <p className="cat">
      //       <i className="fas fa-circle mr-1"></i> NA
      //     </p>
      //   ),
      cell: (row) => row.register_status ? RenderExternalRegistrationStatus(row.register_status, "programme").html : "-",
    },
    {
      name: "Reg Close Date",
      selector: "close",
      sortable: true,
      sortField: "close",
      cell: (row) => (
        <>{row.end ?
          <div className="dateTime">
            <p className="right-space">{row.close.toString().length == 10 ? moment.unix(row.close).format(TABLE_DATE_FORMAT) : moment(row.close).format(TABLE_DATE_FORMAT)}</p>
          </div>
          : "-"
        }</>
      )
    },

    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["eerleedit"]} RenderError={()=>(
              <button
                title="Open"
                className="btn btn-primary rounded-circle"
                disabled                
              >                
                <i className="fal fa-folder-open"></i>               
              </button>
            )}>
              <Link
                title="Open"
                className="btn btn-primary rounded-circle"
                to={`/studentAdministration/externalExam/details/open/detail/${row.id}`}
              >                
                <i className="fal fa-folder-open"></i>               
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Exam Period",
      "Start Date",
      "End Date",
      "Exam Status",
      "Registration Status",
      "Reg Close Date",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const exam = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaExamStatus: examStatus.arr,
      viaRegExamStatus: registrationStatus.arr,
      viaPeriod: examPeriod.arr,
      exportStatus: "true",
    };
    DetailExamListData(exam)
      .then((res) => {
        data = res?.data?.data;

        data = data.map((row) => ({
          ...row,
          "Exam Period": row.period
            ? `${row.period}(${row.start_date}-${row.end_date})`
            : "-",
          "Start Date": row.start
            ?row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(row.start).format(TABLE_DATE_FORMAT)
            : "-",
          "End Date": row.end ? row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(row.end).format(TABLE_DATE_FORMAT) : "-",
          "Exam Status": row.status ? row.status : "-",
          "Registration Status": row.register_status
            ? RenderExternalRegistrationStatus(row.register_status).text
            : "-",
          "Reg Close Date": row.close
            ? row.close.toString().length == 10 ? moment.unix(row.close).format(TABLE_DATE_FORMAT) : moment(row.close).format(TABLE_DATE_FORMAT)
            : "-",
        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          // console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          // console.log("xlxs 1");
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          // console.log("xlsx 2");
          Swal.close();

          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Exam Period"],
              row["Start Date"],
              row["End Date"],
              row["Exam Status"],
              row["Registration Status"],
              row["Reg Close Date"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((error) => {
        console.log("error", error);
        setloading(false);
      });
  };

  return (    
    <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable right-align-action">
      <div className="custom-table-div filter-search-icon card card-table-custom appeals-tables">
      <div className="search-filter-div">
      <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Registration Status"
                      optionArr={registrationStatusArr}
                      state={registrationStatus}
                      setState={setRegistrationStatus}
                      renderLabelFunction={RenderExternalRegistrationStatus}
                    />
                    <Tablefilter
                      filterName="Exam Status"
                      optionArr={examStatusArr}
                      state={examStatus}
                      setState={setExamStatus}
                      renderLabelFunction={RenderExternalExamStatus}
                    />
                    <Tablefilter
                      filterName="Exam Period"
                      optionArr={examPeriodFilter}
                      state={examPeriod}
                      setState={setExamperiod}
                      // renderLabelFunction={RenderProgrammeType}
                    />
                    {/* <Tablefilter
                      filterName="School"
                      optionArr={schoolFilterArr}
                      state={school}
                      setState={setSchool}
                      renderLabelFunction={RenderSchool}
                    /> */}
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "External Exam");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "External Exam");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "External Exam");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["eerleadd"]} RenderError={()=>(
                  <button className="btn btn-primary" title="Create New" disabled>                    
                    <i className="fal fa-plus"></i>Create New                    
                  </button>
                )}>
                  <Link className="btn btn-primary" title="Create New" to={`/studentAdministration/externalExam/details/add/detail`}>                    
                    <i className="fal fa-plus"></i>Create New                    
                  </Link>
                </PermissionsGate>
              </div>
            </div>

          </div>
      </div>
  
      <DataTable
        paginationDefaultPage={page}
        progressPending={loading}
        data={userData}
        defaultSortField={sortkey}
        sortServer
        defaultSortAsc={false}
        columns={columns}
        pagination={true}
        noDataComponent={Str.noRecord}
        onSort={handleSort}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover={false}
        progressComponent={<SkeletonTicketList />}
      />
      </div>
    </div>
  );
};

export default DetailsList;
