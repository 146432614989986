import React, { useState } from "react";
import { checkIfImageExists } from "../../utils/commonFunction";
import { RenderHoverStatus, RenderProgrammeStudentStatus } from "../../utils/CommonStatusItems";
import { IMAGE_URL } from "../../utils/Constants";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import { ColorRender } from "../systemadministration/usermanagement/CheckRole";
import { InitialRender, originalValues } from "./Helper";

const Hover = ({ firstName, lastName, photo, email, mobile, status, right, role, wholeUi, showNumber, number, activity_status }) => {

  const [copyText1, setCopyText1] = useState(0);
  const [copyText2, setCopyText2] = useState(0);
  const [copyText3, setCopyText3] = useState(0);

  const copyEmailText = (text, num) => {
    if (num === 1) {
      setCopyText1(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText1(0); }, 1000);
    } else if (num === 2) {
      setCopyText2(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText2(0); }, 1000);
    } else if (num === 3) {
      setCopyText3(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText3(0); }, 1000);
    }
  };

  /**
   * 
   * @param {*} img will get the url of image
   * @returns This function will check what is included in url then replace accordingly.
   */
  function getImageUrl(img) {
    let imgUrl = img.replaceAll("/home/myaie/public_html/", "").replace("public/", "");
    if(imgUrl.includes("http://myaie.ac")) {
      return imgUrl
    }
    return IMAGE_URL+ "/" + imgUrl;
  }

  /**
   * 
   * @returns This function will return the whole ui including that picture and name of user on which hover will call
   */
  function wholeHoverUi() {
    return (<div className="assigned-title-block-new">
      <div className="assigned-title-itm">
        <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(role ? role : "")}>
          {/* {photo ? <img src={getImageUrl(photo)} alt="AIE" />: InitialRender([firstName, lastName])} */}
          {photo ? <><img src={getImageUrl(photo)} alt="AIE" onError={(e)=> {
                                      e.target.style.height = "0px"
                                      e.target.style.width = "0px"
                                    }} /> {InitialRender([firstName, lastName])}</> : (InitialRender([firstName, lastName]))}
          
          {/* // <img src={`${IMAGE_URL}/${photo.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> 
            // <img src={`${IMAGE_URL}${row.description.User.URL.includes("/home/myaie/public_html/", "") ? row.description.User.URL.replace("/home/myaie/public_html/", "") : row.description.User.URL.replace("public", "")}`} alt="AIE" /> */}
          {/* <span className={`profile-box-2-status hfjhdfjdhj ${activity_status && activity_status=="online" ? "Online" : "Offline"}`}>
          <i className="fas fa-circle"></i>
          </span> */}

          {justHoverUi()}

        </span>

        <p className="as-text-blue curser " >
          {firstName + " " + lastName}
        </p>

      </div>
    </div>)
  }

  /**
   * 
   * @returns This function will return only the dom elem will be rendered in popup on hover
   */
  function justHoverUi() {
    return (<div className={right ? "assigned-title-info popup-right" : "assigned-title-info"} >
      <article>
        <figure className={ColorRender(role)}>
          {photo ? (
            <><img
              src={getImageUrl(photo)}
              alt="AIE"
              onError={(e)=> {
                e.target.style.height = "0px"
                e.target.style.width = "0px"
              }} /> {InitialRender([firstName, lastName])}</>
          ) : (
            InitialRender([firstName, lastName])
          )}
          <span className={`profile-box-2-status hover-profile-icon ${activity_status ? activity_status.toLowerCase()=="online" ? "Online" : activity_status.toLowerCase()== "away" ? "Away" : "Offline" : "Offline"}`}>
          <i className="fas fa-circle"></i>
          </span>
        </figure>
        
        <figcaption>
          <h4 className="hdjhasjdhasjdh">{firstName} {lastName}</h4>

          
            <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(email, 1)} >
              {GlobalIconNames("email")}
              {email ? <> {email} {copyText1 ? <i class="fas fa-check-circle cat-greenhex ml-1"></i> : 
                <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(email)} ></i>}</>
              : " NA"}
              
            </span>
            
            <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(mobile, 2)} >
                {GlobalIconNames("mobile")}
                {mobile ? <> {mobile} {copyText2 ? <i class="fas fa-check-circle cat-greenhex ml-1"></i> : 
                  <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(mobile)} ></i>}</>
                 : " NA"}
            </span>
            
            
          {showNumber ?
            (
              <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(number, 3)} >
               {GlobalIconNames("studentNo")}
                {number ? <> {number} {copyText3 ? 
                  <i class="fas fa-check-circle cat-greenhex ml-1"></i>
                 : 
                  <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(number)} ></i>}
                </> : " NA"}
              </span>
            )
            : null}
            {status && RenderHoverStatus(status).html}
        </figcaption>
      </article>
    </div>)
  }

  return (

    <> {wholeUi === true ? wholeHoverUi() : justHoverUi()} </>
  );
};

export default React.memo(Hover);
