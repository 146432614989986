import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
import { GetBannerList, DeleteBannerList } from "../../../services/BannerService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
function Listofbanners(props) {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState([]);
  const [searchVisibleCheck, setSearchVisibleCheck] = useState({});
  const [linkedBrand, setLinkedBrand] = useState([]);
  const [searchLinkedBrandCheck, setSearchLinkedBrandCheck] = useState({});
  const [report, setReport] = useState([]);
  const [searchReportCheck, setSearchReportCheck] = useState({});

  const [deleterow, setDeleteRow] = useState(false);

  const [tablistData, setTablistData] = useState([]);
  const [tabList, setTabList] = useState(1);
  const [auditTrail, setAuditTrail] = useState(0);

  const user = useSelector((state) => state.user);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  // TODO Api Call
  useEffect(() => {
    setIsLoaded(true);
    GetBannerList()
      .then((res) => {
        setTablistData(res?.data?.data_list)
        setIsLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(false);
      });

    //   GetListOfStudentHubBrandList().then((res) => {
    //     console.log(res.data.brand_setting_list);
    //     setLinkedBrandList(res.data?.brand_setting_list);
    //   });
  }, [deleterow]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["ssbview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Filtering
  const handleVisibleFilter = (e) => {
    const value = e.target.value;
    let arr = visible;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setVisible(arr);
    let id = e.target.id;
    setSearchVisibleCheck({
      ...searchVisibleCheck,
      [id]: !searchVisibleCheck[id],
    });
  };
  const resetFilter = () => {
    setVisible([]);
    setSearchVisibleCheck({});
    setLinkedBrand([]);
    setSearchLinkedBrandCheck({});
    setSearchReportCheck({});
    setSearch("");
  };

  // TODO Data

  const dataToRender = () => {
    let updatedData = [];
    // console.log("tablistData from dataToRender", tablistData);
    let allData = tablistData;
    if (search?.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempHeading = allData.filter((item) => {
        let includes =
          item.banner_heading &&
          item.banner_heading.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSubject = allData.filter((item) => {
        let includes = item.banner_subject && item.banner_subject.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempHeading,
        ...tempSubject,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (visible.length) {
      let tempVisible = updatedData;
      let tempResult = tempVisible.filter((item) => {
        const startsWith = visible.find(function (visible, index) {
          // console.log("visible", visible);
          if (
            visible.toString() === item.status.toString()
              ? item.status.toString()
              : ""
          )
            return item;
        });
        // console.log("startsWith", startsWith);
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (linkedBrand.length) {
      let tempLinkedBrand = updatedData;
      let tempSearch = tempLinkedBrand.filter((item) => {
        const startsWith = linkedBrand.find(function (brand, index) {
          if (
            // brand.toLowerCase() ==
            // (item.domain ? item.domain.toLowerCase() : "")
            // brand.toLowerCase() ==
            (item.domain ? item.domain.toLowerCase().includes(brand.toLowerCase()) : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (visible.length || search.length || linkedBrand.length) {
      return updatedData;
    } else {
      return tablistData;
    }
  };
  const headingSort = (rowA, rowB) => {
    const name1 = rowA.banner_heading;
    const name2 = rowB.banner_heading;
    return name1.localeCompare(name2);
  };
  const subjectSort = (rowA, rowB) => {
    const name1 = rowA.banner_subject;
    const name2 = rowB.banner_subject;
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Heading",
      selector: "banner_heading",
      sortable: true,
      sortFunction:headingSort,
      cell: (row) => {
        return (
          <PermissionsGate scopes={["ssbedit"]} RenderError={() => (
            <span title={row.banner_heading} className="feature-name">
              {" "}
              <span className="textLimit100">{row.banner_heading}</span>
            </span>
          )}>
          <Link
          className="as-text-blue curser feature-name" 
          title={row.banner_heading} 
          to={`/support/studentBanners/open/${row.id}`}
          >
            <span className="textLimit75">{row.banner_heading ? row.banner_heading : ""}</span>
          </Link>
          </PermissionsGate>
        );
      },
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      sortFunction:subjectSort,
      cell: (row) => (row.banner_subject ? <span className="feature-name" title={row.banner_subject}><span className="textLimit75">{row.banner_subject}</span></span> : "-"),
    },
    {
      name: "Visible",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <span
          className={
            row.status == 1
              ? "as-green-bg as-widget "
              : "as-red-bg as-widget "
          }
        >
          {row.status && row.status == 1 ? "Yes" : "No"}
        </span>
      ),
    },

    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["ssbedit"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}>

              <Link
                className="btn btn-primary rounded-circle"
                to={`/support/studentBanners/open/${row.id}`}
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate errorProps={{ disabled: true }} scopes={["ssbdelete"]}>
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteBannerList({ id: ID }).then((res) =>
          setDeleteRow(!deleterow)
        );

        Swal.fire("Deleted!", "Your Banner has been deleted.", "success");
      }
    });
  };

  const handleEdit = (name,ID) => {
    history.push(`/support/studentBanners/open/${ID}?name=${name}`);
  };


  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Heading", "Subject", "Visible"];
    data = data?.map((row) => ({
      ...row,
      Heading: row?.banner_heading,
      Subject: row?.banner_subject,
      Visible: row.status === 1 ? "Yes" : "No",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.banner_heading, row.banner_subject, row.status === 1 ? "Yes" : "No"];
        // return [row.id, row.banner_heading, row.banner_subject, row.visible, row.icon, row.domain];
      });
      const doc = new JsPDF();
      // console.log("doc", doc)
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                      <div
                        id="assessment-table-main_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          id="assessment-table-main_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className=""
                              placeholder="Search"
                              aria-controls="assessment-table-main"
                              onChange={handleSearchFilter}
                              value={search}
                            />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${visible.length ? "btn-selected" : ""
                                  }`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  Visible{" "}
                                  <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">
                                  <li className="item-text-green">
                                    <input
                                      type="checkbox"
                                      id="action1"
                                      value="1"
                                      onClick={handleVisibleFilter}
                                      checked={searchVisibleCheck.action1}
                                    />
                                    <label htmlFor="action1">
                                      <span className="as-green-bg as-widget">
                                        Yes
                                      </span>
                                    </label>
                                  </li>

                                  <li className="item-text-red">
                                    <input
                                      type="checkbox"
                                      id="action2"
                                      value="0"
                                      onClick={handleVisibleFilter}
                                      checked={searchVisibleCheck.action2}
                                    />
                                    <label htmlFor="action2">
                                      <span className="as-red-bg as-widget">
                                        No
                                      </span>
                                    </label>
                                  </li>
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>

                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", "BannerList");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", "BannerList");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", "BannerList");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-filter-div-right">
                <div className="filter-search-bar-blk">
                  <div className="add-ticket-blk button-reset">
                    
                      <PermissionsGate 
                      RenderError={() => (
                      <button
                        className="btn btn-primary"
                        title="Create New"
                        disabled
                      >
                        <i className="fal fa-plus"></i>Create New
                      </button>
                  )} scopes={["ssbadd"]}>
                      <Link to={`/support/studentBanners/create`}>
                        <button className="btn btn-primary" title="Create New">
                          <i className="fal fa-plus"></i>Create New
                        </button>
                      </Link>
                      </PermissionsGate>
                      
                   
                  </div>
                </div>
              </div>
            </div>

            {isLoaded && <SkeletonTicketList />}

            <DataTable
              data={dataToRender()}
              defaultSortField="tab_name"
              defaultSortAsc={true}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          </div>
        </div>

  );
};

export default Listofbanners