import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { UserAuditTrailDetails } from "../../../services/UserService";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import Swal from "sweetalert2";
import moment from "moment";
import { useSelector } from "react-redux";
import { InitialRender } from "../../common/Helper";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import { RenderAuditTrailActionType } from "../../../utils/CommonGroupingItem";
import generateArrayOfYears from "../../common/generateArrayOfYears";
import Hover from "../../common/Hover";

const ProfileAuditTrail = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  const [userData, setUserData] = useState([]);

  const [actionType, setActionType] = useState({ arr: [], checkObj: {} });

  const [month, setMonth] = useState({ arr: [], checkObj: {} });

  const [year, setYear] = useState({ arr: [], checkObj: {} });
  const [yearArr, setYearArr] = useState([]);

  const [loading, setloading] = useState(true);

  useEffect(() => {
    UserAuditTrailDetails({ userId: user.UserID })
      .then((res) => {
        try {
          let tempData = []
          res.data._parsed.map(item => {
            tempData.push({
              ...item, 
              description: item.description ? JSON.parse(item.description) : {}
            })
          })
          setUserData(tempData);
        } catch (err) {
          setUserData(res?.data);
        }
        setloading(false);
      })
      .catch((err) => console.log(err));

    let tempArr = generateArrayOfYears().map(item => {
      return { value: item, label: item }
    })
    setYearArr(tempArr.splice(0, 3))
  }, []);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.description.object.definition.New.Firstname.toString();
    const name2 = rowB.description.object.definition.New.Firstname.toString();
    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description.User.URL ? <img src={`${IMAGE_URL}/${row.description.User.URL.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.description.User.name, row.description.User.name])}

              <span className={`profile-box-2-status ${row.description.User.status ? row.description.User.status : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.description.User.name}
                lastName={""}
                photo={row.description.User.URL.replaceAll("/home/myaie/public_html/", "")}
                email={row.description.User.mbox}
                mobile={row.description.User.Mobile}
                status={row.description.object.definition.New.is_active}
                right={true}
                role={row.description.object.definition.New?.role_name} />
            </span>
            <p><b>{row.description.User.name}</b></p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      cell: (row) => RenderAuditTrailActionType(row.description.verb.Action).html,
    },

    {
      name: "Resource Type",
      cell: (row) => "User",
    },
    {
      name: "Resource Name",
      selector: "Name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        if (row.description.verb.Action === "User Deleted") {
          return row.description.object.definition.New.Firstname + " " + row.description.object.definition.New.Lastname;
        } else {
          return (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span className={"assigned-title-blk name-icon " + ColorRender(row.description.object.definition.New?.role_name?.toLowerCase())}>
                  {row.description.object.definition.URL ? <img src={`${IMAGE_URL}/${row.description.object.definition?.URL.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.description.object.definition.New.Firstname ? row.description.object.definition.New.Firstname : "N", row.description.object.definition.New.Lastname ? row.description.object.definition.New.Lastname : "A"])}
                  <span className={`profile-box-2-status ${row.description.object.definition.New.Status ? row.description.object.definition.New.Status : "Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.description.object.definition.New.Firstname}
                    lastName={row.description.object.definition.New.Lastname}
                    photo={row.description.object.definition?.URL.replaceAll("/home/myaie/public_html/", "")}
                    email={row.description.object.definition.New.Email}
                    mobile={row.description.object.definition.New.Mobile}
                    status={row.description.object.definition.New.is_active}
                    right={true}
                    role={row.description.object.definition.New?.role_name} />
                </span>
                <p className="as-text-blue curser" onClick={() => history.push("/edituser", { id: row.description.object.user_id })}>
                  {row.description.object.definition.New.Firstname}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      name: "Old Value",
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.Old);

        if (row.description.verb.Action === "Created") {
          return "NA";
        } else if (row.description.verb.Action === "Edited") {
          const diff = difference(row.description.object.definition?.Old, row.description.object.definition?.New);
          return diff.length !== 0
            ? <div className="tooltip-custom" title={`${diff.sort().map(entry => entry.toString())}`.split(',').join("\n")}>
              {diff.sort().map((entry, index) => {
                return (index < 2 && <p key={index}>
                  {entry.toString().length > 30 ? entry.toString().slice(0, 30) + "..." : entry}
                </p>)
              })}
              {diff.length > 2 ? "..." : null}
            </div>
            : "NA";
        } else {
          return entries.map((entry, index) => (
            <p key={index} title={String(entry[1])}>
              {entry[0]}:{entry[1] ? (entry[1].toString().length > 30 ? entry[1].toString().slice(0, 30) + "..." : entry[1]) : "N/A"}
            </p>
          ));
        }
      },
    },
    {
      name: "New Value",
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.New);
        if (row.description.verb.Action === "Edited") {
          const diff = difference(row.description.object.definition?.New, row.description.object.definition?.Old);
          return diff.length !== 0
            ? <div className="tooltip-custom" title={`${diff.sort().map(entry => entry.toString())}`.split(',').join("\n")}>
              {diff.sort().map((entry, index) => {
                return (index < 2 && <p key={index}>
                  {entry.toString().length > 30 ? entry.toString().slice(0, 30) + "..." : entry}
                </p>)
              })}
              {diff.length > 2 ? "..." : null}
            </div>
            : "NA";
        } else if (row.description.verb.Action === "Profile Picture Edited") {
          return (
            <p title={row.description.object.definition?.New?.picture_me}>
              {row.description.object.definition?.New?.picture_me.toString().length > 30
                ? "picture_me:" + row.description.object.definition?.New?.picture_me.toString().slice(0, 30) + "..."
                : row.description.object.definition?.New?.picture_me}
            </p>
          );
        } else {
          return (
            <div className="tooltip-custom" title={`${entries.map(entry => entry[0] + ":" + (entry[1] ? entry[1] : "NA"))}`.split(',').join("\n")}>
              {/* <span className="tooltip-custom-box">{entries.map(entry => <p>{entry[0]}:{entry[1] ? entry[1] : "NA"}</p>)}</span> */}
              {entries.map((entry, index) => {
                return (index < 2) && (<p key={index} >
                  {entry[0]}:{entry[1] ? (entry[1].toString().length > 30 ? entry[1].toString().slice(0, 30) + "..." : entry[1]) : "N/A"}
                </p>)
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          );
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row.description.timestamp ? (
          <>
            <p>{moment(row.description.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "NA"
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => {
                Swal.fire({ html: `<div class="audit-trial-json"><pre>${JSON.stringify(row.description, undefined, 1)}</pre></div>` });
              }}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempUser = allData.filter((item) => {
        let includes = item.description.User.name.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((item) => {
        let includes = (item.description.object.definition.New.Firstname ? item.description.object.definition.New.Firstname : item.description.object.definition.Old.Firstname).toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser, ...tempName];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actionType.arr.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actionType.arr.find((post, index) => {
          if (post.toString() === item.description.verb.Action.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.arr.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.arr.find((post, index) => {
          let monthNumber = new Date(item.description.timestamp).getMonth();
          if (post.toString() === (++monthNumber).toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.arr.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.arr.find((post, index) => {
          let yearNumber = new Date(item.description.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(row.description.object.definition?.Old, row.description.object.definition?.New);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    }
    else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(row.description.object.definition?.New, row.description.object.definition?.Old);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString().length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row) => ({
      ...row,
      User: row.description.User.name,
      "Action Type": row.description.verb.Action,
      "Resource Type": "User",
      "Resource Name": row.description.object.definition.New.Firstname + " " + row.description.object.definition.New.Lastname,
      "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? oldValue(row) : "NA",
      "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? NewValue(row) : "NA",
      Date: moment(row.description.timestamp).format(TABLE_DATE_TIME_FORMAT),
    }));

    return data;
  };

  const exportData = () => {
    let data = modifiedData();
    const header = ["User", "Action Type", "Resource Type", "Resource Name", "Old Value", "New Value", "Date"];
    const pdfData = data.map((row) => {
      return [row["User"], row["Action Type"], row["Resource Type"], row["Resource Name"], row["Old Value"], row["New Value"], row["Date"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <DataTableComponentFrontPagination
      data={dataToRender()}
      columns={columns}
      loading={loading}
      search={search}
      setSearch={setSearch}
      exportData={exportData}
      exportFileName={"Users Audit Trail"}
      defaultSort={{ defaultSortColumn: "Date", defaultSortAsc: false }}
      filters={[
        {
          filterName: "Action Type",
          optionArr: [
            { value: "Created", label: "Created" },
            { value: "Edited", label: "Edited" },
            { value: "Deleted", label: "Deleted" },
            { value: "Profile Picture Edited", label: "Picture Edited" },
          ],
          state: actionType,
          setState: setActionType,
          renderLabelFunction: RenderAuditTrailActionType,
        },
        {
          filterName: "Month",
          optionArr: [{ value: "1", label: "January" }, { value: "2", label: "February" }, { value: "3", label: "March" }, { value: "4", label: "April" }, { value: "5", label: "May" }, { value: "6", label: "June" }, { value: "7", label: "July" }, { value: "8", label: "August" }, { value: "9", label: "September" }, { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" }],
          state: month,
          setState: setMonth,
          stopOptionSorting: true
        },
        {
          filterName: "Year",
          optionArr: yearArr,
          state: year,
          setState: setYear,
          isOptionReversed: true
        }
      ]}
    />
  );
};

export default ProfileAuditTrail;
