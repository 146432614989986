import React, { createRef, useEffect, useState } from 'react'
import Select from "react-select";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { QuizHours, QuizMinutes, QuizSeconds } from '../../systemadministration/DataReactSelect';
import HtmlInputEditor from '../../common/HtmlInputEditor';
import { GetQuizTopics } from '../../../services/SmartAssessmentService';
import HtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';

function AddResourceNewDesign({ isLoaded, values, errors, touched, setFieldValue, handleChange, handleBlur, typeArr, language, thumbnailFiles, setThumbnailFiles }) {
    const [showFilePond, setShowFilePond] = useState(false);
    const filePondRef = createRef();

    const { type: paramType } = useParams()

    const [topicArr, setTopicArr] = useState([]);

    useEffect(() => {
        GetQuizTopics().then(res => {
            setTopicArr(res.data.map(item => {
                return {
                    value: item.id.toString(),
                    label: HtmlParser(`<span class="status-color-select" style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`),
                    stringLabel: item.options,
                    color: item.color
                }
            }))
        })
    }, [])

    function autosize() {
        var text = document.querySelectorAll('#messageTyping');
      
        text.forEach(function(elem) {
          elem.setAttribute('rows', 1);
          var kk = resize(elem);
        });
      
        text.forEach(function(elem) {
          elem.addEventListener('input', function() {
            var k = resize(elem);
          });
        });
      
        function resize(elem) {
          elem.style.height = 'auto';
          // elem.style.height = elem.scrollHeight + 'px';
          elem.style = `height: ${elem.scrollHeight}px !important;`
          return elem.scrollHeight;
        }
      }

    const handleDragEnter = (event) => {
        setShowFilePond(true);
        // event.stopPropogation()
        // event.preventDefault()
      };

    return (
        <>
            <div className="card card-profile-info-card">
                <div className="card-body-inr card-body-info">
                    <form>
                        <div className='row'>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Thumbnail
                                        {!isLoaded && (
                                            <i className="fas fa-cog fa-spin"></i>
                                        )}
                                    </label>
                                    <div className="prefix-suffix-box">
                                        <div className="custom-control custom-radio custom-control-inline" title={"Upload " + values.item_type_label}>
                                            <input
                                                type="radio"
                                                id="upload_document"
                                                name="upload_type"
                                                checked={values.thumbnail_type == 0}
                                                onChange={() => { setFieldValue("thumbnail_type", 0) }}
                                                className="custom-control-input"
                                            />
                                            <label className="custom-control-label custom-control-inline" htmlFor="upload_document">Upload File</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline" title="Select from library">
                                            <input
                                                type="radio"
                                                id="upload_library"
                                                name="upload_type"
                                                checked={values.thumbnail_type == 1}
                                                onChange={() => { setFieldValue("thumbnail_type", 1) }}
                                                className="custom-control-input"
                                            />
                                            <label className="custom-control-label custom-control-inline" htmlFor="upload_library">Specify Link</label>
                                        </div>
                                    </div>
                                    <div className="mb-5 mt-3">
                                        {values.thumbnail_type == 0 && <div className="drag_card_btn">
                                            <FilePond
                                                name="thumbnail"
                                                allowMultiple={true}
                                                maxFiles={1}
                                                ref={filePondRef}
                                                files={thumbnailFiles}
                                                onupdatefiles={setThumbnailFiles}
                                                acceptedFileTypes={['image/*']}
                                            />
                                        </div>}
                                        {values.thumbnail_type == 1 && <div className="form-icon-group mb-4">
                                            <input
                                                type="text"
                                                className={"form-control" + (errors.thumbnail_link && touched.thumbnail_link ? " is-invalid" : "")}
                                                name="thumbnail_link"
                                                placeholder="Specify Link to thumbnail"
                                                title="Specify Link to thumbnail"
                                                value={values.thumbnail_link}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-9">
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group" >
                                            <label>Type*</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box">
                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.type && touched.type ? " is-invalid" : "")}
                                                        name="type"
                                                        value={typeArr?.filter(val => val?.value == values.type)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setFieldValue("type", value.value);
                                                            } else {
                                                                setFieldValue("type", "");
                                                            }
                                                        }}
                                                        placeholder={values.type ? values.type : "Select..."}
                                                        onBlur={handleBlur}
                                                        options={typeArr}
                                                        maxMenuHeight={175}
                                                        isDisabled={paramType == "edit"}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-9">
                                        <div className="form-group">
                                            <label>Title*</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.title && touched.title ? " is-invalid" : "")}
                                                    name="title"
                                                    placeholder="Title"
                                                    title="Title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group-blk">
                                            <label>Topics</label>
                                            <Select
                                                className={"form-control color-drop custom-select-box is-multi" + (errors.topic && touched.topic ? " is-invalid" : "")}
                                                name="topic"
                                                value={topicArr.filter(val => values.topic.includes(val.value.toString()))}
                                                onChange={(value) => {
                                                    let tempArr = []
                                                    if (value?.length) {
                                                        value.map(item => {
                                                            tempArr.push(item.value)
                                                        })
                                                    }
                                                    setFieldValue("topic", tempArr);
                                                }}
                                                placeholder={values.topic.length ? values.topic : "Select..."}
                                                onBlur={handleBlur}
                                                options={topicArr.sort((a, b) => a.stringLabel.localeCompare(b.stringLabel))}
                                                maxMenuHeight={175}
                                                isClearable
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Sub Title</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.subtitle && touched.subtitle ? " is-invalid" : "")}
                                                    name="subtitle"
                                                    placeholder="Sub Title"
                                                    title="Sub Title"
                                                    value={values.subtitle}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">

                                        <div className="form-group">
                                            <label>Author</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.author && touched.author ? " is-invalid" : "")}
                                                    name="author"
                                                    placeholder="Author"
                                                    title="Author"
                                                    value={values.author}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(values.type == "Book" || values.type == "ebook") && <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group" >
                                            <label>Language</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box">
                                                    <Select className={"form-control custom-select-box" + (errors.language && touched.language ? " is-invalid" : "")} name="language"
                                                        value={language?.filter(val => val.value === values.language)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setFieldValue("language", value.value);
                                                            } else {
                                                                setFieldValue("language", "");
                                                            }
                                                        }}
                                                        placeholder={values.language ? values.language : "Select..."}
                                                        onBlur={handleBlur}
                                                        options={language}
                                                        maxMenuHeight={175}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-md-6 col-lg-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <div className="form-icon-group mb-4">
                                                <textarea
                                                    className="form-control"
                                                    name="description"
                                                    placeholder="Description Text"
                                                    title="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    // onKeyDown={autosize}
                                                    // onDragEnter={handleDragEnter}
                                                    onBlur={handleBlur}
                                                    rows={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {(values.type == "vimeo" || values.type == "Vimeo") && <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label>URL</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    title="URL"
                                                    className={"form-control "}
                                                    placeholder="Enter Vimeo Url"
                                                    name="vimeoBaseUrl"
                                                    value={"https://player.vimeo.com/video/"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    {(values.type == "Youtube") && <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>URL*</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    title="Url"
                                                    className={"form-control " + (errors.url && touched.url ? " is-invalid" : "")}
                                                    placeholder="URL"
                                                    name="url"
                                                    value={values.url}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    {(values.type == "vimeo" || values.type == "Vimeo") && <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Video ID*</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control " + (errors.videoId && touched.videoId ? " is-invalid" : "")}
                                                    placeholder="Enter Video Id"
                                                    name="videoId"
                                                    title="Video Id"
                                                    value={values.videoId}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    {(values.type == "vimeo" || values.type == "Vimeo") && <div className="col-md-6 col-lg-5">
                                        <div className="form-group" >
                                            <label>Duration</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box mr-2">
                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.TimeLimitHours && touched.TimeLimitHours ? " is-invalid" : "")}
                                                        name="Hour"
                                                        title="Hour"
                                                        placeholder={values.TimeLimitHours ? `${values.TimeLimitHours} h` : "Hours"}
                                                        options={QuizHours}
                                                        onBlur={handleBlur}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setFieldValue("TimeLimitHours", value.value);
                                                            } else {
                                                                setFieldValue("TimeLimitHours", "");
                                                            }
                                                        }}
                                                        value={QuizHours?.filter(val => val.value === values.TimeLimitHours)}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-control custom-select-box mr-2">
                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.TimeLimitMinutes && touched.TimeLimitMinutes ? " is-invalid" : "")}
                                                        name="Minute"
                                                        title="Time Limit Minute"
                                                        placeholder={values.TimeLimitMinutes ? `${values.TimeLimitMinutes} min` : "Minutes"}
                                                        options={QuizMinutes}
                                                        onBlur={handleBlur}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setFieldValue("TimeLimitMinutes", value.resValue);
                                                            } else {
                                                                setFieldValue("TimeLimitMinutes", "");
                                                            }
                                                        }}
                                                        value={QuizMinutes?.filter(val => (val.value === values.TimeLimitMinutes || val.resValue === values.TimeLimitMinutes))}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="form-control custom-select-box">
                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.TimeLimitSeconds && touched.TimeLimitSeconds ? " is-invalid" : "")}
                                                        name="Seconds"
                                                        title="Time Limit Seconds"
                                                        placeholder={values.TimeLimitSeconds ? `${values.TimeLimitSeconds} sec` : "Seconds"}
                                                        options={QuizSeconds}
                                                        onBlur={handleBlur}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setFieldValue("TimeLimitSeconds", value.resValue);
                                                            } else {
                                                                setFieldValue("TimeLimitSeconds", "");
                                                            }
                                                        }}
                                                        value={QuizSeconds?.filter(val => (val.value === values.TimeLimitSeconds || val.resValue === values.TimeLimitSeconds))}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {(values.type == "Article" || values.type == "article" || values.type === "Label") && <div className="col-md-12">
                                        <div className="form-group" >
                                            <label>Content*</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box">
                                                    <HtmlInputEditor
                                                        name="textBody"
                                                        isCKEditor={true}
                                                        editorState={values.textBody}
                                                        hideSign={true}
                                                        belowNoMargin={true}
                                                        setEditorState={(val) => { setFieldValue("textBody", val) }}
                                                        isInvalid={touched.textBody && errors.textBody}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddResourceNewDesign