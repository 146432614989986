import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { GetListOfStudents, RegistrationStudentSearchList, SearchStudent } from "../../../services/SupportService";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import { GetAllDropdownValues } from "../../../services/RegistrationService";
import Hover from "../../common/Hover";
import { Link } from "react-router-dom";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import $ from 'jquery';
import { TrimText } from "../../common/TrimText";
import { checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";

const StudentsModalTable = ({ selection }) => {
  const [search, setSearch] = useState("");
  const [debounceTerm, setDebounceTerm] = useState("");
  const [studyType, setStudyType] = useState([]);
  const [searchStudyTypeCheck, setSearchStudyTypeCheck] = useState({});
  const [intake, setIntake] = useState([]);
  const [searchIntakeCheck, setSearchIntakeCheck] = useState({});
  const [intakeYear, setIntakeYear] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedID, setselectedID] = useState(null);
  const [selectedName, setselectedName] = useState(null);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");


  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  const fetchUsers = () => {
    setLoading(true);
    // if (search.length > 0 || intake.length > 0 || studyType.length > 0) {
    //   SearchStudent({ "type": studyType.length > 0 ? studyType : [""], "intakeYear": intake.length > 0 ? intake : [""], "fname": search, "pageNo": page, "size": perPage })
    //     .then((res) => {
    //       setData(res?.data?.data);
    //       setTotalRows(res?.data?.total);
    //       setLoading(false);
    //     }).catch((err) => console.log(err));
    // } else {
    // GetListOfStudents({ "pageNo": page, "size": perPage, "key": sortKey, "sort":sortOrder, "viaSearch":search, "type": studyType.length > 0 ? studyType : [""], "intakeYear": intake.length > 0 ? intake : [""], }).then((res) => {
    //   setData(res?.data?.data);
    //   setTotalRows(res?.data?.total);
    //   setLoading(false);
    // }).catch((err) => console.log(err));
    // }
    GetAllDropdownValues().then((response) => {
      setIntakeYear(response.data.intake_year)
    })
    RegistrationStudentSearchList()
      .then((res) => {
        setData(res?.data?.data);
        // setTotalRows(res?.data?.total);
        setLoading(false);
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      });

  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // useEffect(() => {
  //   fetchUsers();
  // }, []);
  useEffect(() => {
    fetchUsers();
  }, [page, perPage, search, studyType, searchStudyTypeCheck, intake, searchIntakeCheck, sortKey, sortOrder]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebounceTerm(value);
  };

  const handleRedirection = (ID) => {
    if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/general`;
    } else if (hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/personal`;
    } else if (hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/education`;
    } else if (hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/account`;
    } else if (hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
    } else if (hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/assessments`;
    } else if (hasPermission({ scopes: ["snview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/notifications`;
    } else if (hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/classAttendance`;
    } else if (hasPermission({ scopes: ["slview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/licenses`;
    } else if (hasPermission({ scopes: ["scview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/certificates`;
    } else if (hasPermission({ scopes: ["saview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/appeals`;
    } else if (hasPermission({ scopes: ["stview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/tickets`;
    } else if (hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/notes`;
    } else {
      return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
    }
    // history.push(redirectLink)
  };

  const handleSort = (column, sortDirection) => {

    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const columns = [
    {
      name: "",
      cell: (row) => <input onClick={() => {
        setselectedID(row.id);
        setselectedName(row.first_name)
      }}
        type="radio"
        value={row.id}
        name="ID"
        checked={row.id == selectedID}
      />,
    },
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortField: "id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      sortField: "name",
      sortable: true,
      selector: "name",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender("")}>

              {row.photo ? checkIfImageExists(row.photo.replace("home/myaie/public_html/", "").replace("public/","")) ? <img src={`${IMAGE_URL}/${row.photo.replace("home/myaie/public_html/", "").replace("public/","")}`} alt="AIE" /> : InitialRender([row.first_name, row.last_name]) : InitialRender([row.first_name, row.last_name])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.first_name} lastName={row.last_name} photo={row.photo} email={row.email} mobile={row.mobile} right={true} role={"student"} status={row.status} activity_status={row.activity_status} showNumber={true} number={row.student_crm_id} />
              {/* <div className="assigned-title-info popup-right">
                <article>
                  <figure className={ColorRender("")}>
                    {row.photo ? <img src={`${IMAGE_URL}${row.photo.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.first_name, row.last_name])}
                  </figure>
                  <figcaption>
                    <h4>
                      {row.first_name} {row.last_name}
                    </h4>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.email)}>
                      <i className="fal fa-envelope"></i> {row.email ? row.email : "NA"}
                    </a>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.mobile)}>
                      <i className="fal fa-phone-alt"></i> {row.mobile ? row.mobile : "NA"}
                    </a>
                    <h6 className={row.status ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.status ? "ACTIVE" : "INACTIVE"}</h6>
                  </figcaption>
                </article>
              </div> */}
            </span>
            <Link to={handleRedirection(row.id)} onClick={() => {
              $("#add-student").modal("hide");
            }} className="as-text-blue curser ">{row.first_name ? TrimText(row.first_name + " " + row.last_name, 20) : "-"}</Link>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Email",
    //   sortField: "email",
    //   sortable: true,
    //   selector: "email",
    //   cell: (row) => (row.email ? row.email : "-"),
    // },
    {
      name: "Student No.",
      sortField: "student_crm_id",
      sortable: true,
      selector: "student_crm_id",
      cell: (row) => (row.student_crm_id ? row.student_crm_id : "-"),
    },
    {
      name: "Status",
      sortField: "status",
      sortable: true,
      selector: "status",
      cell: (row) => (
        <p>
          <span className={row.status == "Active" ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.status == "Active" ? "ACTIVE" : "INACTIVE"}</span>
        </p>
      ),
    },
  ];

  // const handleChange = ({ selectedRows }) => {
  //   setSelectedRows(selectedRows);
  // };

  const handleSubmit = (e) => {
    // let students = selectedRows;
    selection([{ first_name: selectedName, id: selectedID }]);
  };

  const handleStudyTypeFilter = (e) => {
    const value = e.target.value;
    let arr = studyType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStudyType(arr);
    let id = e.target.id;
    setSearchStudyTypeCheck({ ...searchStudyTypeCheck, [id]: !searchStudyTypeCheck[id] });
  };

  const handleIntakeFilter = (e) => {
    const value = e.target.value;
    let arr = intake;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setIntake(arr);
    let id = e.target.id;
    setSearchIntakeCheck({ ...searchIntakeCheck, [id]: !searchIntakeCheck[id] });
  };

  const resetFilter = () => {
    setSearch("");
    setDebounceTerm("");
    setStudyType([]);
    setSearchStudyTypeCheck({});
    setIntake([]);
    setSearchIntakeCheck({});
  };

  const renderIntakeUI = () => {
    // let arr = ["2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"];
    let arr = intakeYear;
    return arr?.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={i.value} value={i.value} onClick={handleIntakeFilter} checked={searchIntakeCheck[i.value]} />
          <label htmlFor={i.value}>
            <span className="cat ">{i.label}</span>
          </label>
        </li>
      );
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {

      let tempFirstname = allData.filter((item) => {
        let fName = item && item.first_name && item.last_name ? item.first_name + " " + item.last_name : "";
        let includes = fName.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempFirstname,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (intake.length) {
      let tempIntake = updatedData;
      let tempIntakeYear = tempIntake.filter((item) => {
        const startsIntakeYear = intake.find(function (post, index) {
          if (item.high_year && post.toString().toLowerCase() === item.high_year.toString().toLowerCase() ? item.high_year.toString().toLowerCase() : "")
            return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });
      updatedData = tempIntakeYear;
    }

    if (search.length || intakeYear.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debounceTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debounceTerm])

  return (
    <div>
      {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fal fa-times"></i></button> */}
      <div className="my-tickets-info-list Tickets-main-wrap modal-inner-std">
        <div className="custom-table-div filter-search-icon card-table-custom scroll-height">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={debounceTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          {loading ? <SkeletonTicketList /> :
            <DataTable
              columns={columns}
              data={dataToRender()}
              pagination
              // progressPending={loading} onSort={handleSort} progressComponent={<SkeletonTicketList />} paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} 
              defaultSortAsc={true}
              defaultSortField="name"
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          }

          {!loading && <div className="form-group form-group-save-cancel">
            <button className="btn btn-primary" onClick={handleSubmit} data-dismiss="modal" title="Save">
              <i className="fal fa-save"></i> Save
            </button>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              data-toggle="modal"
              data-target="#add-student"
            >
              <i className="fal fa-times"></i>
              Cancel
            </button>
          </div>}
        </div>
      </div>
    </div>

  );
};

export default StudentsModalTable;
