import axiosInstance from "../utils/axios";
export const NetSuiteCustomerService = async (values) => {
  return await axiosInstance().post("/netSuitCustomer", values);
};
export const NetSuiteCustomerList = async (values) => {
  return await axiosInstance().post("/netSuiteCustomerList", values);
};

export const GetStudentList = async () => {
  return await axiosInstance().get("/getStudentList");
};

export const GetStudentListDetail = async (id) => {
  return await axiosInstance().get(`/getStudentDetail?id=${id}`);
};
export const GetReRegistrationStudentListDetail = async (id) => {
  return await axiosInstance().get(`/getReRigisterStudentDetails?id=${id}`);
};

export const UpdateCustomerNetsuiteId = async (data) => {
  return await axiosInstance().post(`/updateCustomerNetsuiteId`, data);
};
export const UpdateReRigisterStudentCustomerId = async (data) => {
  return await axiosInstance().post(`/updateReRigisterStudentCustomerId`, data);
};

export const netsuiteCustomerAuditTrail = async (data) => {
  return await axiosInstance().post(`/netsuiteCustomerAuditTrail`, data);
};

export const ImportCSVStudentCrmNumber = async (data) => {
  return await axiosInstance().post(`/importCSVStudentCrmNumber`, data);
};

export const GetAuditTrailNetsuite = async () => {
  return await axiosInstance().get(`/getAuditTrailNetsuite`);
};
