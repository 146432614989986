import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { GetMicrosoftAcountCancelToken, getStudentDataCancelToken, getStudentDocsCancelToken } from '../../../../services/StudentsSettingServices'
import hasPermission from '../../../../utils/hasMultiplePermission'
import AddressBlock from './PersonalTabs/AddressBlock'
import BasicDetailsBlock from './PersonalTabs/BasicDetailsBlock'
import ConfirmationLetterBlock from './PersonalTabs/ConfirmationLetterBlock'
import ContactDetailsBlock from './PersonalTabs/ContactDetailsBlock'
import LoginDetailsBlock from './PersonalTabs/LoginDetailsBlock'
import Office365Details from './PersonalTabs/Office365Details'
import PersonalDocumentBlock from './PersonalTabs/PersonalDocumentBlock'
import PermissionsGate from "../../../../utils/permissionGate";
import axios from 'axios'

function Personal() {
  const { id } = useParams();
  const [sData, setSData] = useState();
  const [loading, setLoading] = useState({ getStudentData: false, getStudentDocs: false, getMicrosoftAC: false });
  const [updateRow, setUpdateRow] = useState(true);
  const [officeData, setOfficeData] = useState();
  const [filePreview, setFilePreview] = useState(false);

  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["spiview"], permissions: givenPermsisions });
    let response2 = hasPermission({ scopes: ["spcview"], permissions: givenPermsisions });
    if (!response && ! response2) {
      history.push("/noaccess");
    }
  }, [])
  

  useEffect(() => {
    const cancelTokenSources = [];

    const GetMicrosoftAcountData = async () => {
      setLoading(prev => ({...prev, getMicrosoftAC: true }))
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const formData = new FormData();
      formData.append("student_id", id);
      formData.append("tabtype", "personal");
  
      try {
        const res = await GetMicrosoftAcountCancelToken(formData, source.token);
        if (res.status === 200) {
          setOfficeData(res.data);
          setLoading(prev => ({...prev, getMicrosoftAC: false }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(prev => ({...prev, getMicrosoftAC: false }))
        }
      }
    };
  
    GetMicrosoftAcountData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
    // setLoading({ getStudentData: true, getStudentDocs: true, getMicrosoftAC: true })
    // setFilePreview(false)
  }, [updateRow])

  useEffect(() => {
    const cancelTokenSources = [];

    const getStudentDatas = async () => {
      setLoading(prev => ({...prev, getStudentData: true }))
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await getStudentDataCancelToken(id, source.token);
        if (res.status === 200) {
          setSData(prev => ({ ...prev, getStudent: res.data?.getStudent, currentBrand: res.data?.currentBrand, brand: res.data?.brand }))
          setLoading(prev => ({...prev, getStudentData: false }))
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
        console.error(error);
        setLoading(prev => ({...prev, getStudentData: false }))
        }
      }
    };
  
    getStudentDatas();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateRow])

  useEffect(() => {
    const cancelTokenSources = [];

    const getStudentDoc = async () => {
      setLoading(prev => ({...prev, getStudentDocs: false }))
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await getStudentDocsCancelToken(id, source.token);
        if (res.status === 200) {
          setSData(prev => ({ ...prev, getPersonalDocs: res.data?.getPersonalDocs, getlatterwithlog: res.data?.getlatterwithlog }))
          setLoading(prev => ({...prev, getStudentDocs: false }))
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
        console.error(error);
        setLoading(prev => ({...prev, getStudentDocs: false }))
        }
      }
    };
  
    getStudentDoc();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateRow])

  return (
    <div className="card card-profile-info-card">
      <div>
        <div className="card-body-inr card-body-info">
          <PermissionsGate scopes={['spiview']}>
          <BasicDetailsBlock studentData={sData ? sData.getStudent : ""} loading={loading.getStudentData} updateRow={updateRow} setUpdateRow={setUpdateRow} setFilePreview={setFilePreview} filePreview={filePreview} />
          <hr />
          </PermissionsGate>
          <PermissionsGate scopes={['spcview']}>
          <ContactDetailsBlock studentData={sData ? sData.getStudent : ""} loading={loading.getStudentData} />
          <hr />
          </PermissionsGate>
          <PermissionsGate scopes={['spiview']}>
          <AddressBlock studentData={sData ? sData.getStudent : ""} updateRow={updateRow} setUpdateRow={setUpdateRow} loading={loading.getStudentData} />
          <hr />
          <LoginDetailsBlock studentData={sData ? sData.getStudent : ""} loading={loading.getStudentData} />
          <hr />
          <Office365Details studentData={officeData ? officeData.getMicrosoftAC : ""} microsoftLog={officeData && officeData.getMicroAcLog ? officeData.getMicroAcLog.what : ""} loading={loading.getMicrosoftAC} />
          <hr />
          <PersonalDocumentBlock studentData={sData ? sData.getPersonalDocs : ""} loading={loading.getStudentDocs} updateRow={updateRow} setUpdateRow={setUpdateRow} />
          <hr />
          <ConfirmationLetterBlock studentData={sData ? sData.getlatterwithlog : ""} loading={loading.getStudentDocs} updateRow={updateRow} setUpdateRow={setUpdateRow} />
          </PermissionsGate>
        </div>
      </div>
    </div>
  )
}

export default Personal