import React from 'react'
import { GetProgramAuditTrialCancelToken, GetProgramAuditTrialDropdownCancelToken, getProgramAuditTrial, getProgramAuditTrialDropdown } from '../../../services/ProgrammeServices';
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function AuditTrails() {

  const { id } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type, otherData) => {
    switch (type) {
      case "Programme Gradebook Setting":
        return `/courseAdministration/Programmes/programme/open/${id}/grade/table`
    
      default:
        return `/courseAdministration/Programmes/programme/open/${id}/details`
    }
  }

  return (
    <AuditTrailsTable
      apiFunction={GetProgramAuditTrialCancelToken}
      apiParams={{parent_action_id: id}}
      columnsToShow={columnsToShow}
      filterArrApi={GetProgramAuditTrialDropdownCancelToken}
      actionId={id}
      exportFileName={"Programme_auditTrail"}
      resourceNameRedirect={handleRedirect}
      isResourceNameShown={true}
    />
  )
}

export default AuditTrails