import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { EditBrandSettingAPI, GetBrandSettingDetail } from "../../../services/BrandServices";
import * as Yup from "yup";
import { useFormik } from "formik";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { getCurrentTimeHomePage } from "../../common/Helper";
import Tabs from "../Tabs";
import { IMAGE_URL } from "../../../utils/Constants";

function VisualsTab() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false); 
  const [brandSettingData, setBrandSettingData] = useState({});
  const [previewIndexLogo, setPreviewIndexLogo] = useState({ image: false });
  const [previewLoginBackground, setPreviewLoginBackground] = useState({ image: false });
  const [previewLoginScreenHeader, setPreviewLoginScreenHeader] = useState({ image: false });
  const [previewLoginMobileScreenHeader, setPreviewLoginMobileScreenHeader] = useState({ image: false });
  const [previewMobileLoginBackground, setPreviewMobileLoginBackground] = useState({ image: false });
  const [indexLogo, setIndexLogo] = useState();
  const [indexLogoName, setIndexLogoName] = useState({ name: "" });
  const [loginBackgroundName, setLoginBackgroundName] = useState({ name: "" });
  const [loginScreenHeaderName, setLoginScreenHeaderName] = useState({ name: "" });
  const [loginMobileScreenHeaderName, setLoginMobileScreenHeaderName] = useState({ name: "" });
  const [mobileLoginBackgroundName, setMobileLoginBackgroundName] = useState({ name: "" });
  const [loginBackground, setLoginBackground] = useState();
  const [loginScreenHeader, setLoginScreenHeader] = useState();
  const [loginMobileScreenHeader, setLoginMobileScreenHeader] = useState();
  const [mobileLoginBackground, setMobileLoginBackground] = useState();
  useEffect(() => {
    setPreviewLoginBackground({ image: brandSettingData ? brandSettingData?.appLoginBackground : false });
    setPreviewLoginScreenHeader({ image: brandSettingData ? brandSettingData?.login_header : false });
    setPreviewLoginMobileScreenHeader({ image: brandSettingData ? brandSettingData?.mobileloginHeader : false });
    setPreviewIndexLogo({ image: brandSettingData ? brandSettingData?.logo : false, name: "" });
    setPreviewMobileLoginBackground({ image: brandSettingData ? brandSettingData?.background_logo : false });
  }, [brandSettingData]);

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      TopBar: brandSettingData ? brandSettingData?.topbar_color : "",
      SideBar: brandSettingData ? brandSettingData?.sidebar_color : "",
      Highlight: brandSettingData ? brandSettingData?.highlight_color : "",
      Hover: brandSettingData ? brandSettingData?.hover_color : "",
      Active: brandSettingData ? brandSettingData?.active_color : "",
      LoginHeader: brandSettingData ? brandSettingData?.login_header_color : "",
      Button: brandSettingData ? brandSettingData?.button_color : "",
      ButtonHover: brandSettingData ? brandSettingData?.button_hover_color : "",
      ButtonClick: brandSettingData ? brandSettingData?.button_active_color : "",
      LoginBackground: brandSettingData ? brandSettingData?.background_logo : "",
      LoginScreenHeader: brandSettingData ? brandSettingData?.login_header : "",
      MobileLoginScreenHeader: brandSettingData ? brandSettingData?.mobileloginHeader : "",
      MobileLoginBackground: brandSettingData ? brandSettingData?.appLoginBackground : "",
      Logo: brandSettingData ? brandSettingData?.logo : "",
    },
    validationSchema: Yup.object({
      TopBar: Yup.string().required("Top Bar Color is required").nullable(),
      SideBar: Yup.string().required("SideBar Color is required").nullable(),
      Highlight: Yup.string().required("Highlight Color is required").nullable(),
      Hover: Yup.string().required("Hover Color is required").nullable(),
      Active: Yup.string().required("Active Color is required").nullable(),
      LoginHeader: Yup.string().required("Login Header Color is required").nullable(),
      Button: Yup.string().required("Button Color is required").nullable(),
      ButtonHover: Yup.string().required("Button Hover Color is required").nullable(),
      ButtonClick: Yup.string().required("Button Click Color is required").nullable(),
      Logo: Yup.mixed().required("Logo file required"),
      LoginBackground: Yup.mixed().required("Login Background file required"),
      LoginScreenHeader: Yup.mixed().required("Login Screen Header file required"),
      MobileLoginScreenHeader: Yup.mixed().required("Mobile Login Screen Header file required"),
      MobileLoginBackground: Yup.mixed().required("Mobile Login Background file required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true)
      let formData = new FormData();
      formData.set("type", "portal_visual");
      formData.set("id", id);
      formData.set("topbar_color", values.TopBar);
      formData.set("sidebar_color", values.SideBar);
      formData.set("highlight_color", values.Highlight);
      formData.set("hover_color", values.Hover);
      formData.set("active_color", values.Active);
      formData.set("login_header_color", values.LoginHeader);
      formData.set("button_color", values.Button);
      formData.set("button_hover_color", values.ButtonHover);
      formData.set("button_active_color", values.ButtonClick);
      loginScreenHeader && formData.set("login_header", loginScreenHeader);
      loginMobileScreenHeader && formData.set("mobileloginHeader", loginMobileScreenHeader);
      mobileLoginBackground && formData.set("appLoginBackground", mobileLoginBackground);
      loginBackground && formData.set("background_logo", loginBackground);
      indexLogo && formData.set("logo", indexLogo);
      EditBrandSettingAPI(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          location.pathname.includes("add") && history.push("/administration/brandSetting/list");
          setDisabled(false)
        })
        .catch((err) => {
          console.log("error: ", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.error?.message}`,
          });
        });
    },
  });
  useEffect(() => {
    GetBrandSettingDetail(id)
      .then((response) => {
        if (response?.status === 200) {
          setBrandSettingData(response?.data?.brand_setting);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);
  const basename = (path) => {
    return path.split("/").reverse()[0];
  };
  useEffect(() => {
    brandSettingData && brandSettingData.logo && brandSettingData.logo !== null ? setIndexLogoName({ name: basename(brandSettingData?.logo) }) : setIndexLogoName({ name: "" });
    brandSettingData && brandSettingData?.background_logo && brandSettingData?.background_logo !== null
      ? setLoginBackgroundName({ name: basename(brandSettingData?.background_logo) })
      : setLoginBackgroundName({ name: "" });
    brandSettingData && brandSettingData?.login_header && brandSettingData?.login_header !== null
      ? setLoginScreenHeaderName({ name: basename(brandSettingData?.login_header) })
      : setLoginScreenHeaderName({ name: "" });
    brandSettingData && brandSettingData?.mobileloginHeader && brandSettingData?.mobileloginHeader !== null
      ? setLoginMobileScreenHeaderName({ name: basename(brandSettingData?.mobileloginHeader) })
      : setLoginMobileScreenHeaderName({ name: "" });
    brandSettingData && brandSettingData?.appLoginBackground && brandSettingData?.appLoginBackground !== null
      ? setMobileLoginBackgroundName({ name: basename(brandSettingData?.appLoginBackground) })
      : setMobileLoginBackgroundName({ name: "" });

    // setLetterheadFooterName(tempFooterName && tempFooterName[tempFooterName.length-1])
  }, [brandSettingData]);

  useEffect(() => {
    var logo;
    var background_logo;
    var login_header;
    var mobileloginHeader;
    var appLoginBackground;
    if (brandSettingData && brandSettingData.logo && brandSettingData.logo.includes("public/")) {
      logo = IMAGE_URL + "/" + brandSettingData.logo.replace("public/", "");
    } else if (brandSettingData && brandSettingData.logo && brandSettingData.logo.includes("https://www.myaie.ac")) {
      logo = brandSettingData.logo;
    } else if (brandSettingData && brandSettingData.logo && !brandSettingData.logo.includes("public/")) {
      logo = brandSettingData.logo;
    } else {
      logo = false;
    }
    if (brandSettingData && brandSettingData.background_logo && brandSettingData.background_logo.includes("public/")) {
      background_logo = IMAGE_URL + "/" + brandSettingData.background_logo.replace("public/", "");
    
    } else if (brandSettingData && brandSettingData.background_logo && brandSettingData.background_logo.includes("https://www.myaie.ac")) {
      background_logo = brandSettingData.background_logo;
    } else if (brandSettingData && brandSettingData.background_logo && !brandSettingData.background_logo.includes("public/")) {
      background_logo = brandSettingData.background_logo;
    } else {
      background_logo = false;
    }
    if (brandSettingData && brandSettingData.login_header && brandSettingData.login_header.includes("public/")) {
      login_header = IMAGE_URL + "/" + brandSettingData.login_header.replace("public/", "");
    } else if (brandSettingData && brandSettingData.login_header && brandSettingData.login_header.includes("https://www.myaie.ac")) {
      login_header = brandSettingData.login_header;
    } else if (brandSettingData && brandSettingData.login_header && !brandSettingData.login_header.includes("public/")) {
      login_header = brandSettingData.login_header;
    } else {
      login_header = false;
    }
    if (brandSettingData && brandSettingData.mobileloginHeader && brandSettingData.mobileloginHeader.includes("public/")) {
      mobileloginHeader = IMAGE_URL + "/" + brandSettingData.mobileloginHeader.replace("public/", "");
    } else if (brandSettingData && brandSettingData.mobileloginHeader && brandSettingData.mobileloginHeader.includes("https://www.myaie.ac")) {
      mobileloginHeader = brandSettingData.mobileloginHeader;
    } else if (brandSettingData && brandSettingData.mobileloginHeader && !brandSettingData.mobileloginHeader.includes("public/")) {
      mobileloginHeader = brandSettingData.mobileloginHeader;
    } else {
      mobileloginHeader = false;
    }
    if (brandSettingData && brandSettingData.appLoginBackground && brandSettingData.appLoginBackground.includes("public/")) {
      appLoginBackground = IMAGE_URL + "/" + brandSettingData.appLoginBackground.replace("public/", "");
    } else if (brandSettingData && brandSettingData.appLoginBackground && brandSettingData.appLoginBackground.includes("https://www.myaie.ac")) {
      appLoginBackground = brandSettingData.appLoginBackground;
    } else if (brandSettingData && brandSettingData.appLoginBackground && !brandSettingData.appLoginBackground.includes("public/")) {
      appLoginBackground =  brandSettingData.appLoginBackground;
    } else {
      appLoginBackground = false;
    }
    setPreviewIndexLogo({ image: logo });
    setPreviewLoginBackground({ image: background_logo });
    setPreviewLoginScreenHeader({ image: login_header });
    setPreviewLoginMobileScreenHeader({ image: mobileloginHeader });
    setPreviewMobileLoginBackground({ image: appLoginBackground });
  }, [brandSettingData]);

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div className="d-flex align-items-center ">
                  <div className="name-w-head d-flex align-items-center ">
                    <h4 className="text-left-align landing-heading heading_color_dynamic">Brand: <span>{brandSettingData && brandSettingData?.brand_name}</span></h4>
                  </div>
                  <div className="text-md-right action2-blk">
                    <div className="ticket-view-btn d-flex align-items-center">
                      <div className="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" className="btn btn-white-bordered">
                          <i className="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => history.push(location.pathname.includes("add") ? `/administration/brandSetting/addNewBrand` : `/administration/brandSetting/editBrand/${id}`)}
                    >
                      <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-info-circle"></i>Details
                      </a>
                    </li>
                    {/* <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/detailsTab/${id}` : `/administration/brandSetting/editPortalSetting/detailsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i class="fal fa-books"></i>Portal Settings
                      </a>
                    </li> */}
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/visualsTab/${id}` : `/administration/brandSetting/editPortalSetting/visualsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i className="fal fa-laptop"></i>Visuals
                      </a>
                    </li>
                    {location.pathname.includes("add") ? "" : (<li
                      className="nav-item"
                      onClick={() => history.push(`/administration/brandSetting/singleAuditTrail/${id}`)}
                      title={"Audit Trail"}
                    >
                      <Link
                        className="nav-link "
                        aria-disabled={false}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={"/administration/brandSetting/singleAuditTrail"}
                        role="listitem"
                        // aria-controls={isPortalEnabled && "pills-tab3"}
                        // aria-selected={isPortalEnabled}
                      >
                        <i className="fal fa-history"></i>Audit Trail
                      </Link>
                    </li>)}
                  </ul>
                  {/* <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/detailsTab/${id}` : `/administration/brandSetting/editPortalSetting/detailsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link  " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i className="fal fa-file-alt"></i>Details
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/visualsTab/${id}` : `/administration/brandSetting/editPortalSetting/visualsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i class="fal fa-laptop"></i>Visuals
                      </a>
                    </li>
                  </ul> */}
                  <div className="card card-body-inr">
                    <form onSubmit={formik.handleSubmit}>
                      {/* <h4 className="text-left-align landing-heading heading_color_dynamic">Visuals Tab</h4> */}
                      <div className="row">
                        <div className="col-md-6 col-lg-4">
                          <label className="" htmlFor="TopBar">
                            TopBar *
                          </label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.TopBar && formik.touched.TopBar ? " is-invalid" : "")}
                              name="TopBar"
                              id="TopBar"
                              title="Top Bar"
                              value={formik.values.TopBar}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.TopBar && formik.touched.TopBar ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="SideBar">SideBar *</label>
                          <div className="form-icon-group mb-4">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.SideBar && formik.touched.SideBar ? " is-invalid" : "")}
                              name="SideBar"
                              id="SideBar"
                              title="Brand Name"
                              value={formik.values.SideBar}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.SideBar && formik.touched.SideBar ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="Highlight">Highlight *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.Highlight && formik.touched.Highlight ? " is-invalid" : "")}
                              name="Highlight"
                              id="Highlight"
                              title="Highlight"
                              value={formik.values.Highlight}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.Highlight && formik.touched.Highlight ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="Hover">Hover *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.Hover && formik.touched.Hover ? " is-invalid" : "")}
                              name="Hover"
                              id="Hover"
                              title="Hover"
                              value={formik.values.Hover}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.Hover && formik.touched.Hover ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="Active">Active *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.Active && formik.touched.Active ? " is-invalid" : "")}
                              name="Active"
                              id="Active"
                              title="Active"
                              value={formik.values.Active}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.Active && formik.touched.Active ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="LoginHeader">Login Header *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.LoginHeader && formik.touched.LoginHeader ? " is-invalid" : "")}
                              name="LoginHeader"
                              title="Login Header"
                              id="LoginHeader"
                              value={formik.values.LoginHeader}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.LoginHeader && formik.touched.LoginHeader ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="Button">Button *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.Button && formik.touched.Button ? " is-invalid" : "")}
                              name="Button"
                              title="Button"
                              id="Button"
                              value={formik.values.Button}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.Button && formik.touched.Button ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="ButtonHover">Button Hover *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.ButtonHover && formik.touched.ButtonHover ? " is-invalid" : "")}
                              name="ButtonHover"
                              id="ButtonHover"
                              title="Button Hover"
                              value={formik.values.ButtonHover}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.ButtonHover && formik.touched.ButtonHover ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="ButtonClick">Button Click *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="color"
                              className={"form-control" + (formik.errors.ButtonClick && formik.touched.ButtonClick ? " is-invalid" : "")}
                              name="ButtonClick"
                              title="Button Click"
                              id="ButtonClick"
                              value={formik.values.ButtonClick}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.ButtonClick && formik.touched.ButtonClick ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="IndexLogo">Index Logo *</label>
                          <div className="form-group atttach-file">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                id="input"
                                className="form-control  form-control-aatch-file"
                                name="file[]"
                                accept={SUPPORTED_FORMATS}
                                title={indexLogoName.name ? indexLogoName.name : "No file selected"}
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPreviewIndexLogo({ image: e.target.result });
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                    formik.setFieldValue("Logo", e.target.files[0]);
                                    setIndexLogo(e.target.files[0]);
                                    setIndexLogoName({ name: e.target.files[0].name });
                                  } else {
                                    setPreviewIndexLogo({ image: false });
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {previewIndexLogo.image && (
                            <>
                              <div className="form-icon-group" title="Index Logo">
                                <figure className="upload-img-size" title="Index Logo">
                                  <img className="" src={previewIndexLogo?.image} alt={"Index Logo"} />
                                </figure>
                              </div>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a href={previewIndexLogo?.image} target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{indexLogoName.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}

                          {formik.errors.Logo && formik.touched.Logo ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="IndexLogo">Login Background *</label>
                          <div className="form-group atttach-file">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                id="input"
                                className="form-control  form-control-aatch-file"
                                name="file[]"
                                accept={SUPPORTED_FORMATS}
                                title={loginBackgroundName.name ? loginBackgroundName.name : "No file selected"}
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPreviewLoginBackground({ image: e.target.result });
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                    formik.setFieldValue("LoginBackground", e.target.files[0]);
                                    setLoginBackground(e.target.files[0]);
                                    setLoginBackgroundName({ name: e.target.files[0].name });
                                  } else {
                                    setPreviewLoginBackground({ image: false });
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {previewLoginBackground.image && (
                            <>
                              <div className="form-icon-group" title="Login Background">
                                <figure className="upload-img-size" title="Login Background">
                                  <img className="" src={previewLoginBackground?.image} alt={"Login Background"} />
                                </figure>
                              </div>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a href={previewLoginBackground?.image} target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{loginBackgroundName.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}

                          {formik.errors.LoginBackground && formik.touched.LoginBackground ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="IndexLogo">Login Screen Header *</label>
                          <div className="form-group atttach-file">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                id="input"
                                className="form-control  form-control-aatch-file"
                                name="file[]"
                                accept={SUPPORTED_FORMATS}
                                title={loginScreenHeaderName.name ? loginScreenHeaderName.name : "No file selected"}
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPreviewLoginScreenHeader({ image: e.target.result });
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                    formik.setFieldValue("LoginScreenHeader", e.target.files[0]);
                                    setLoginScreenHeader(e.target.files[0]);
                                    setLoginScreenHeaderName({ name: e.target.files[0].name });
                                  } else {
                                    setPreviewLoginScreenHeader({ image: false });
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {previewLoginScreenHeader.image && (
                            <>
                              <div className="form-icon-group" title="Login Screen Header">
                                <figure className="upload-img-size" title="Login Screen Header">
                                  <img className="" src={previewLoginScreenHeader?.image} alt={"Login Screen Header"} />
                                </figure>
                              </div>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a href={previewLoginScreenHeader?.image} target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{loginScreenHeaderName.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}

                          {formik.errors.LoginScreenHeader && formik.touched.LoginScreenHeader ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="IndexLogo">Mobile Login Screen Header *</label>
                          <div className="form-group atttach-file">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                id="input"
                                className="form-control  form-control-aatch-file"
                                name="file[]"
                                accept={SUPPORTED_FORMATS}
                                title={loginMobileScreenHeaderName.name ? loginMobileScreenHeaderName.name : "No file selected"}
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPreviewLoginMobileScreenHeader({ image: e.target.result });
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                    formik.setFieldValue("MobileLoginScreenHeader", e.target.files[0]);
                                    setLoginMobileScreenHeader(e.target.files[0]);
                                    setLoginMobileScreenHeaderName({ name: e.target.files[0].name });
                                  } else {
                                    setPreviewLoginMobileScreenHeader({ image: false });
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {previewLoginMobileScreenHeader.image && (
                            <>
                              <div className="form-icon-group" title="Mobile Login Screen Header">
                                <figure className="upload-img-size" title="Mobile Login Screen Header">
                                  <img className="" src={previewLoginMobileScreenHeader?.image} alt={"Mobile Login Screen Header"} />
                                </figure>
                              </div>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a href={previewLoginMobileScreenHeader?.image} target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{loginMobileScreenHeaderName.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}

                          {formik.errors.MobileLoginScreenHeader && formik.touched.MobileLoginScreenHeader ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label htmlFor="IndexLogo">Mobile Login Background *</label>
                          <div className="form-group atttach-file">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                id="input"
                                className="form-control  form-control-aatch-file"
                                name="file[]"
                                accept={SUPPORTED_FORMATS}
                                title={mobileLoginBackgroundName.name ? mobileLoginBackgroundName.name : "No file selected"}
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPreviewMobileLoginBackground({ image: e.target.result });
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                    formik.setFieldValue("MobileLoginBackground", e.target.files[0]);
                                    setMobileLoginBackground(e.target.files[0]);
                                    setMobileLoginBackgroundName({ name: e.target.files[0].name });
                                  } else {
                                    setPreviewMobileLoginBackground({ image: false });
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {previewMobileLoginBackground.image && (
                            <>
                              <div className="form-icon-group" title="Mobile Login Background">
                                <figure className="upload-img-size" title="Mobile Login Background">
                                  <img className="" src={previewMobileLoginBackground?.image} alt={"Mobile Login Background"} />
                                </figure>
                              </div>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a href={previewMobileLoginBackground?.image} target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{mobileLoginBackgroundName.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}

                          {formik.errors.MobileLoginBackground && formik.touched.MobileLoginBackground ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group form-group-save-cancel">
                        <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty)}>
                        {disabled ? <i className="fas fa-cog fa-spin"></i>: <i className="fal fa-save"></i>}
                          Save
                        </button>
                        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.push("/administration/brandSetting/list")}>
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>

                        {formik.touched.TopBar && formik.errors.TopBar ? <div className="invalid-feedback d-block">{formik.errors.TopBar}</div> : null}
                        {formik.touched.SideBar && formik.errors.SideBar ? <div className="invalid-feedback d-block">{formik.errors.SideBar}</div> : null}
                        {formik.touched.Highlight && formik.errors.Highlight ? <div className="invalid-feedback d-block">{formik.errors.Highlight}</div> : null}
                        {formik.touched.Hover && formik.errors.Hover ? <div className="invalid-feedback d-block">{formik.errors.Hover}</div> : null}
                        {formik.touched.Active && formik.errors.Active ? <div className="invalid-feedback d-block">{formik.errors.Active}</div> : null}
                        {formik.touched.LoginHeader && formik.errors.LoginHeader ? <div className="invalid-feedback d-block">{formik.errors.LoginHeader}</div> : null}
                        {formik.touched.Button && formik.errors.Button ? <div className="invalid-feedback d-block">{formik.errors.Button}</div> : null}
                        {formik.touched.ButtonHover && formik.errors.ButtonHover ? <div className="invalid-feedback d-block">{formik.errors.ButtonHover}</div> : null}
                        {formik.touched.ButtonClick && formik.errors.ButtonClick ? <div className="invalid-feedback d-block">{formik.errors.ButtonClick}</div> : null}
                        {formik.touched.Logo && formik.errors.Logo ? <div className="invalid-feedback d-block">{formik.errors.Logo}</div> : null}
                        {formik.touched.LoginBackground && formik.errors.LoginBackground ? <div className="invalid-feedback d-block">{formik.errors.LoginBackground}</div> : null}
                        {formik.touched.LoginScreenHeader && formik.errors.LoginScreenHeader ? <div className="invalid-feedback d-block">{formik.errors.LoginScreenHeader}</div> : null}
                        {formik.touched.MobileLoginBackground && formik.errors.MobileLoginBackground ? <div className="invalid-feedback d-block">{formik.errors.MobileLoginBackground}</div> : null}
                        {formik.touched.MobileLoginScreenHeader && formik.errors.MobileLoginScreenHeader ? (
                          <div className="invalid-feedback d-block">{formik.errors.MobileLoginScreenHeader}</div>
                        ) : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VisualsTab;
