import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {
  TermPeriods,
} from "../../systemadministration/DataReactSelect";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {
  AddProgram,
  UpdateProgramme,
  GetSchoolList,
  GetProgramDetailCancelToken
} from "../../../services/ProgramService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { RenderPMCStatus } from "../../../utils/CommonGroupingItem";
import HtmlParser from "react-html-parser";
import axios from "axios";

const statusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
  },
  {
    value: "New",
    label: HtmlParser(`<span className="skyblue" title="New"> New </span>`)
  },
  {
    value: "Final",
    label: HtmlParser(` <span className="green" title="Final">Final</span>`)
  },
  {
    value: "Approved",
    label: HtmlParser(`<span className="yellowshade" title="Approved">Approved</span>`)
  },
  {
    value: "Inactive",
    label: HtmlParser(`<span className="blue" title="Inactive">Inactive</span>`)
  },
]

const NQF_LevelArr = [
  {
    value: "1",
    label: "1"
  },
  {
    value: "2",
    label: "2"
  },
  {
    value: "3",
    label: "3"
  },
  {
    value: "4",
    label: "4"
  },
  {
    value: "5",
    label: "5"
  },
  {
    value: "6",
    label: "6"
  },
  {
    value: "7",
    label: "7"
  },
  {
    value: "8",
    label: "8"
  },
  {
    value: "9",
    label: "9"
  },
  {
    value: "10",
    label: "10"
  },
]

const ProgramDetail = ({ detail }) => {
  const history = useHistory();
  const { id, type } = useParams();
  const [programId, setProgramID] = useState(id);
  const [disabled, setDisabled] = useState(false)
  const [editorState1, setEditorState1] = useState("");
  // const [detail, setDetail] = useState();
  const [admissionReq, setAdmissionReq] = useState("")
  const [specialAdmissionReq, setSpecialAdmissionReq] = useState("")
  const [careerOpportunity, setCareerOpportunity] = useState("")
  const [articulationOption, setArticulationOption] = useState("")
  const [regulatoryInfo, setRegulatoryInfo] = useState("");
  const [impNotes, setImpNotes] = useState("");
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [editorToShow, setEditorToShow] = useState(false);
  const [schoolList, setSchoolList] = useState([]);

  useEffect(() => {
    GetSchoolList().then((res) => {
      if (res.status == 200) {
        setSchoolList(res.data.data)
      }
    })
  }, [type]);

  useEffect(() => {
    if (detail) {
      setLearningMethodArr(detail.Delivery_Methods_Arr ? detail.Delivery_Methods_Arr : [])
      setAdmissionReq(detail.admission_req)
      setSpecialAdmissionReq(detail.special_admission_req)
      setCareerOpportunity(detail.career_opportunities)
      setArticulationOption(detail.articultion_option)
      setRegulatoryInfo(detail.regulatory_inform)
      setImpNotes(detail.important_notes)
      setEditorState1(detail.P_Description)
      setEditorToShow(true)
    }
  }, [detail]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmcpedit"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const formik = useFormik({
    enableReinitialize: type === "open" || type === "duplicate" ? true : false,
    initialValues: {
      Code: detail?.P_Code ? detail.P_Code : "",
      SAQA_Name: detail?.SAQA_Name ? detail.SAQA_Name : "",
      SAQA_Link: detail?.SAQA_Link ? detail.SAQA_Link : "",
      NQF_Level: detail?.NQF_Level ? detail.NQF_Level : "",
      Credits: detail?.Credits ? detail.Credits : "",
      Duration: detail?.P_Duration ? detail.P_Duration : "",
      School: detail?.School ? detail.School : "",
      termPeriod: detail?.Term_Period ? detail.Term_Period : "",
      Delivery_Methods: detail?.Delivery_Methods ? detail.Delivery_Methods : "",
      admission_req: "",
      special_admission_req: "",
      career_opportunities: "",
      articultion_option: "",
      regulatory_inform: "",
      important_notes: "",
      status: detail?.status ? detail.status : "Draft",
      name: detail?.name ? detail?.name : ""
    },
    validationSchema: Yup.object({
      SAQA_Name: Yup.string()
        .required("SAQA Name is required").max(255, "SAQA Name should not be greater than 255 Character")
        .trim(),
      name: Yup.string()
        .required("Shorted Name is required").max(255, "Shorted Name should not be greater than 255 Character")
        .trim(),
      NQF_Level: Yup.number()
        .typeError("NQF Level must be number")
        .min(1, "NQF can be 1 minimum")
        .max(10, "NQF can be 10 maximum")
        .required("NQF_Level is required"),
      School: Yup.string().required("School is required").trim(),
      termPeriod: Yup.string().required("termPeriod is required").trim(),
      status: Yup.string().required("Status is required"),
      Delivery_Methods: Yup.string()
        .required("Learning Methods is required")
        .trim(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let Description = editorState1
      let admission_req = admissionReq
      let special_admission_req = specialAdmissionReq
      let career_opportunities = careerOpportunity
      let articultion_option = articulationOption
      let regulatory_inform = regulatoryInfo
      let important_notes = impNotes
      values = { ...values, Description, admission_req, special_admission_req, career_opportunities, articultion_option, regulatory_inform, important_notes };
      if (type === "open") {
        values = { ...values, programId: id };
        UpdateProgramme(values)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated Successfully",
            });
            history.push(
              `/program-management-center/programmes/programme/table`
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
          });
      } else {
        if (type == "duplicate") {
          values = { ...values, duplicateId: id };
        }
        AddProgram(values)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setProgramID(res.data.id);
            history.push(
              `/program-management-center/programmes/programme/table`
            );
          })
          .catch((err) => console.log(err));
      }
    },
  });

  const handlereset = async () => {
    history.push(`/program-management-center/programmes/programme/table`);
  };


  const editorArr = [
    {
      name: "Purpose & Rationale",
      id: "seven",
      component: <HtmlInputEditor
        editorState={editorState1}
        setEditorState={setEditorState1}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: false
    },
    {
      name: "Admission Requirements",
      id: "one",
      component: <HtmlInputEditor
        editorState={admissionReq}
        setEditorState={setAdmissionReq}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.admission_req && formik.touched.admission_req
    },
    {
      name: "Special Admission Requirements",
      id: "two",
      component: <HtmlInputEditor
        editorState={specialAdmissionReq}
        setEditorState={setSpecialAdmissionReq}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.special_admission_req && formik.touched.special_admission_req
    },
    {
      name: "Career Opportunity",
      id: "three",
      component: <HtmlInputEditor
        editorState={careerOpportunity}
        setEditorState={setCareerOpportunity}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.career_opportunities && formik.touched.career_opportunities
    },
    {
      name: "Articulation Option",
      id: "four",
      component: <HtmlInputEditor
        editorState={articulationOption}
        setEditorState={setArticulationOption}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.articultion_option && formik.touched.articultion_option
    },
    {
      name: "Regulatory Information",
      id: "five",
      component: <HtmlInputEditor
        editorState={regulatoryInfo}
        setEditorState={setRegulatoryInfo}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.regulatory_inform && formik.touched.regulatory_inform
    },
    {
      name: "Important Notes",
      id: "six",
      component: <HtmlInputEditor
        editorState={impNotes}
        setEditorState={setImpNotes}
        hideSign={true}
        belowNoMargin={true}
        isCKEditor={true}
      />,
      isInvalid: formik.errors.important_notes && formik.touched.important_notes
    },
  ]

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: !isDisabled
        //     ? isSelected
        //       ? data.color
        //       : color.alpha(0.3).css()
        //     : undefined,
        // },
      };
    },
  };
  return (
    <>
      <div className="card card-profile-info-card filter-table-bg">
        <div className="">
          {/* <div className="new-card-header">
        <div className="card-header">Programme Details</div>
      </div> */}
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="name">SAQA Registered Name *</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors["SAQA_Name"] &&
                          formik.touched["SAQA_Name"]
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter SAQA Name"
                      name="SAQA_Name"
                      id="name"
                      title="SAQA Registered Name"
                      value={formik.values["SAQA_Name"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="name">Short Name *</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors["name"] &&
                          formik.touched["name"]
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Shorted Name"
                      name="name"
                      id="name"
                      title="Shorted Name"
                      value={formik.values["name"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="code">Code</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.Code && formik.touched.Code
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter code"
                      name="Code"
                      id="code"
                      title="Code"
                      value={formik.values.Code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3" title="NQF Level">
                    <label htmlFor="level">NQF Level *</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.NQF_Level &&
                          formik.touched.NQF_Level
                          ? " is-invalid"
                          : "")
                      }
                      name="NQF_Level"
                      value={NQF_LevelArr.filter(val => val?.value === formik.values.NQF_Level)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("NQF_Level", value.value)
                        } else {
                          formik.setFieldValue("NQF_Level", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={NQF_LevelArr}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.NQF_Level
                          ? formik.values.NQF_Level
                          : "Select NQF Level  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group-blk mb-3" title="School">
                    <label htmlFor="school">School *</label>
                    <Select
                      className={"form-control custom-select-box " + (formik.errors.School && formik.touched.school ? " is-invalid" : "")}
                      name="School"
                      value={schoolList.filter((item) => {
                        return item.value == formik.values.School;
                      })}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("School", value.value);
                        } else {
                          formik.setFieldValue("School", "");
                        }
                      }}
                      // isClearable
                      onBlur={formik.handleBlur}
                      options={schoolList}
                      maxMenuHeight={175}
                      // placeholder={formik.values.school ? formik.values.school : "Select"}
                      placeholder={"Select School"}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="credit">Credits</label>
                    <input
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.Credits && formik.touched.Credits
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Credits"
                      name="Credits"
                      id="credit"
                      title="Credits"
                      value={formik.values.Credits}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="link">SAQA Link</label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors["SAQA_Link"] &&
                          formik.touched["SAQA_Link"]
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter SAQA Link"
                      name="SAQA_Link"
                      id="link"
                      title="SAQA Link"
                      value={formik.values["SAQA_Link"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3" title="Status">
                    <label>Status *</label>
                    <Select
                      styles={colourStyles}
                      className={
                        "form-control color-drop custom-select-box" +
                        (formik.errors.status &&
                          formik.touched.status
                          ? " is-invalid"
                          : "")
                      }
                      name="status"
                      value={statusArr.filter(val => val?.value === formik.values.status)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("status", value.value)
                        } else {
                          formik.setFieldValue("status", "")
                        }
                      }}
                      // menuIsOpen={true}
                      onBlur={formik.handleBlur}
                      options={statusArr}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.status
                          ? formik.values.status
                          : "Select Status  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3" title="Learning Method">
                    <label>Learning Method *</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.Delivery_Methods &&
                          formik.touched.Delivery_Methods
                          ? " is-invalid"
                          : "")
                      }
                      name="Delivery_Methods"
                      value={learningMethodArr.filter((obj) => {
                        return obj.value == formik.values.Delivery_Methods
                      })}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("Delivery_Methods", value.value)
                        } else {
                          formik.setFieldValue("Delivery_Methods", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={learningMethodArr}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.Delivery_Methods
                          ? formik.values.Delivery_Methods
                          : "Select Learning Method  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3" title="Term Periods">
                    <label htmlFor="timeperiod">Term Periods *</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.termPeriod &&
                          formik.touched.termPeriod
                          ? " is-invalid"
                          : "")
                      }
                      name="termPeriod"
                      value={TermPeriods?.filter(val => val.value === formik.values.termPeriod)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("termPeriod", value.value)
                        } else {
                          formik.setFieldValue("termPeriod", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      id="timeperiod"
                      options={TermPeriods}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.termPeriod
                          ? formik.values.termPeriod
                          : "Select Term Period  "
                      }
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="duration">Duration (Weeks)</label>
                    <input
                      type="number"
                      className={
                        "form-control" +
                        (formik.errors.Duration && formik.touched.Duration
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Enter Duration (Weeks)"
                      name="Duration"
                      id="duration"
                      title="Duration (Weeks)"
                      value={formik.values.Duration}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div
                className="admin-home-acc accordion ac_over_none"
                id="accordionExample"
              >
                {editorArr.map((item, index) => {
                  return (
                    <div className="card-main" key={index}>
                      <div className="card-top">
                        <div className="card-header1" id={`heading${item.id}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              type="button" data-toggle="collapse" data-target={`#collapse${item.id}`} aria-expanded="false" aria-controls={`collapse${item.id}`}
                            >

                              <div className={`title-border-box ${item.isInvalid ? "editor-block-invalid" : ""} `}>{item.name}</div>


                              <div className="header-status">
                                <div className="status-gol card-blue-1">
                                  <i className="fal fa-chevron-down rotate-icon"></i>
                                </div>
                              </div>

                            </button>
                          </h2>

                        </div>
                        <div
                          id={`collapse${item.id}`} className="card-body1 collapse" aria-labelledby={`heading${item.id}`} data-parent="#accordionExample"
                        >
                          <div className="card-body inner-tab-card">
                            {(editorToShow || type == "add") && item.component}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="form-group form-group-save-cancel mt-4">
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={disabled}
                  onClick={formik.handleSubmit}
                >
                  {disabled ? (
                    <i className="fas fa-cog fa-spin"></i>
                  ) : (
                    <i className="fal fa-save"></i>
                  )}
                  Save
                </button>

                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => handlereset()}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {/** map function to render all errors  */}
              {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                    </div>
                  )
                }
              })}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramDetail;
