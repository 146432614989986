import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { InitialRender } from "../../common/Helper";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import {
  checkRole,
  ColorRender,
} from "../../systemadministration/usermanagement/CheckRole";
import {
  GetStudentsList,
  GetStudentsListCancelToken,
  GetStudentsListsFilter,
  GetStudentsListsFilterCancelToken,
  StatmentIndividual,
} from "../../../services/StudentsSettingServices";
import moment from "moment";
import { StudentDetailsLink } from "../../common/Links";
import Hover from "../../common/Hover";
import { TrimText } from "../../common/TrimText";
import hasPermission from "../../../utils/hasMultiplePermission";
import { StudentStatus } from "../../../utils/StudentStatusColorCode";
import Tablefilter from "../../common/Tablefilter";
import { RenderProgrammeStudentStatus } from "../../../utils/CommonStatusItems";
import { exportFile } from "../../../utils/ExportData";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";

function ListOfStudents() {

  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [deleterow, setDeleteRow] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const user = useSelector((state) => state.user);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("student_crm_id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({status : []});
  // const [cancelTokenSources, setCancelTokenSources] = useState([]);
 
  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })
  useEffect(() => {
    const cancelTokenSources = [];

    const getStudentList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      const studentData = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStatus: status.arr
      };
  
      try {
        const res = await GetStudentsListCancelToken(studentData, source.token);
        setStudentData(res?.data?.data?.data);
        setTotalRows(res.data?.data?.total);
        setPerPage(res.data?.data?.perPage);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }

    getStudentList();
    
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page, perPage, sortOrder, sortkey, search, status, searchStatusCheck]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetStudentsListsFilterCancelToken(dropdownSource.token);
        setFilterData({...res.data, status : res.data.status_list_filter ? res.data.status_list_filter : []});
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);
  
  useEffect(() => {
    let response = hasPermission({
      scopes: ["sview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleRedirection = (ID) => {
    if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
      return `/studentAdministration/students/open/${ID}/general`;
    } else if (
      hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/personal`;
    } else if (
      hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/education`;
    } else if (
      hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/account`;
    } else if (
      hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
    } else if (
      hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/assessments`;
    } else if (
      hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/notifications`;
    } else if (
      hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/classAttendance`;
    } else if (
      hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/licenses`;
    } else if (
      hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/certificates`;
    } else if (
      hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/appeals`;
    } else if (
      hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/tickets`;
    } else if (
      hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
    ) {
      return `/studentAdministration/students/open/${ID}/notes`;
    } else {
      return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
    }
    // history.push(redirectLink)
  };

  const sortingName = (rowA, rowB) => {
    const name1 = (rowA.first_name + " " + rowA.last_name).trim().toLowerCase();
    const name2 = (rowB.first_name + " " + rowB.last_name).trim().toLowerCase();
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "full_name",
      sortField: "full_name",
      sortable: true,
      // minWidth: "190px",
      sortFunction: sortingName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span
              className={
                "assigned-title-blk  name-icon cat-dark-red " +
                ColorRender(row.role_name)
              }
            >
              {row.picture_me ? (
                <img
                  src={`${IMAGE_URL}/${row.picture_me.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  ).replace("public/","")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([row.first_name, row.last_name])
              )}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.picture_me}
                email={row.email}
                mobile={row.mobile}
                status={row.status}
                activity_status={row.activity_status}
                right={true}
                showNumber={true} 
                number={row.student_crm_id}
              />
            </span>
            {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
            <Link
              className="as-text-blue curser feature-name"
              to={handleRedirection(row.id)}
              title={row.first_name + " " + row.last_name}
            >
              <span className="textLimit100">{row.first_name + " " + row.last_name}</span>
            </Link>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortField: "email",
      sortable: true,
      cell: (row) => (
        <>
          {row.email ? <span className="feature-name" title={row.email}> <span className="textLimit100">{row.email}</span> </span> : "-"}
        </>
      ),
    },
    {
      name: "Student No.",
      selector: "student_crm_id",
      sortField: "student_crm_id",
      sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100" title={row.student_crm_id}>
            {row.student_crm_id ? row.student_crm_id : "-"}
          </span>
        </span>
      ),
    },
    {
      name: "ID",
      selector: "number",
      sortField: "number",
      sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <>
          {row.number ?
            <div className="feature-name">
              <span className="textLimit100" title={row.number}>
                {row.number ? row.number : "-"}
              </span>
            </div>
            : "-"
          }
        </>
      ),
    },

    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (row.status ? RenderProgrammeStudentStatus(row.status).html : "-"),
    },
    {
      name: "Last Online",
      selector: "last_login",
      sortField: "last_login",
      sortable: true,
      cell: (row) => (
        <>
          {row.last_login ? (
            <div
              className="dateTime"
              title={moment.unix(row.last_login).format(TABLE_DATE_TIME_FORMAT)}
            >
              <p className="right-space">
                {moment.unix(row.last_login).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment.unix(row.last_login).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            {/* <PermissionsGate
              scopes={["sgiview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  // to={StudentDetailsLink(row.id)}
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            > */}
            <Link
              className="btn btn-primary rounded-circle"
              to={handleRedirection(row.id)}
              title="Open"
            // onClick={() => {
            //   localStorage.setItem("studentDetail", JSON.stringify(row));
            //   handleRedirection(row.id);
            // }}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {/* </PermissionsGate> */}
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                title="More"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate
                  scopes={["sstudview"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Student View"
                      disabled
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  )}
                >
                  <a
                    href={`https://student.myaie.ac/viewStudent?email=${row.email}&password=${row.password}`}
                    target={"_blank"}
                    className="btn btn-primary rounded-circle"
                    title="Student View"
                  >
                    <i className="fal fa-eye"></i>
                  </a>
                </PermissionsGate>
                <button
                  className="btn btn-primary rounded-circle"
                  title="Student Statement"
                  onClick={() => exportFile("csv","Student Statement",row.id,"students")}
                >
                  <i className="fal fa-file-excel icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf
  const handlePdfGenerate = (id) => {
    StatmentIndividual({ "student_id": id }).then((res) => {
      let subjectInternal = res.data.subject_internal;

      var doc = new JsPDF();
      Object.keys(subjectInternal).map(key => {
        let subjectKey = [];
        Object.keys(subjectInternal[key]).map(value => {

          let internalData = {
            "SUBJECTS INTERNAL CERTIFICATE": subjectInternal[key][value].subject,
            "SYMBOL": subjectInternal[key][value].percentage
          }
          subjectKey.push(internalData)


        })
        let finalData = subjectKey.map((row) => {
          return [
            row["SUBJECTS INTERNAL CERTIFICATE"],
            row["SYMBOL"]
          ]
        })
        // doc.text(60, 60, 'INTERIM STATEMENT OF RESULTS')
        doc.autoTable({
          head: [["SUBJECTS INTERNAL CERTIFICATE", "SYMBOL"]],
          body: finalData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });

        doc.autoTable({
          // head: [["SUBJECTS INTERNAL CERTIFICATE","SYMBOL"]],
          body: res.data.grads,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "centre",
            fontSize: 8,
          },
        });

      })
      doc.save(`student.pdf`);
    }).catch((err) => {
      console.log(err);
    })


    return;
  }

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Student", "Email", "Student No.", "ID", "Status", "Last Online"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const studentData = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStatus: status.arr,
      exportStatus: "true",
    };
    GetStudentsList(studentData)
      .then((res) => {
        data = res.data.data;
        data = data?.map((row) => ({
          ...row,
          Student: row?.first_name + " " + row.last_name,
          Email: row.email ? row.email : "-",
          "Student No.": row?.student_crm_id ? row.student_crm_id : "-",
          ID: row?.number ? row?.number : "-",
          Status: row?.status ? RenderProgrammeStudentStatus(row.status).text
            : "-",
          Location: row.Location ? row.Location : "-",
          "Last Online": row.last_login
            ? moment.unix(row.last_login).format(TABLE_DATE_TIME_FORMAT)
            : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row.Student,
              row.Email,
              row["Student No."],
              row["ID"],
              row.Status,
              row["Last Online"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
        clearTimeout(timer);
    }
  }, [debouncedTerm])

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>
                            <Tablefilter
                              filterName="Status"
                              optionArr={filterData.status}
                              state={status}
                              setState={setStatus}
                              renderLabelFunction={RenderProgrammeStudentStatus}
                            />
                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button
                            className="btn btn-primary"
                            onClick={resetFilter}
                            title="Reset"
                          >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "Students_list");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "Students_list");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "Students_list");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="search-filter-div-right">
              <div class=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate
                    RenderError={() => (
                      <button
                        type="button"
                        className="btn btn-primary"
                        title="Create new"
                        disabled
                      >
                        <i className="fal fa-plus"></i>Create New
                      </button>
                    )}
                    scopes={["sadd"]}
                  >
                    <Link to={`/studentAdministration/students/add`}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        title="Create new"
                      >
                        <i className="fal fa-plus"></i>Create New
                      </button>
                    </Link>
                  </PermissionsGate>
                </div>
              </div>
          </div>
      </div>
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            data={studentData}
            progressComponent={<SkeletonTicketList />}
            defaultSortField={sortkey}
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            // noDataComponent={Str.noRecord}
            onSort={handleSort}
            sortServer
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
      </div>
    </div>
  );
}

export default ListOfStudents;
