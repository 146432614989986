import React from "react";
import ProgrammeGradeMix from "./ProgrammeGradeMix";

function ProgrammeGradeMixModal({
  setTempCheckedAllData,
  tempCheckedAllData,
  setCheckedAllRows,
  checkedAllRows,
  courseId,
  setSelectedGradeMixTemplate
}) {
  return (
    <div
      // ref={editorRef}
      className="topic-add-modal modal fade"
      id="grademixmodal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered available-assessment-modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header modal-header-custom">
            <h5 className="modal-title">
              <i className="fal fa-layer-group"></i> Assign Grade Mix
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ProgrammeGradeMix
              setTempCheckedAllData={setTempCheckedAllData}
              tempCheckedAllData={tempCheckedAllData}
              setCheckedAllRows={setCheckedAllRows}
              checkedAllRows={checkedAllRows}
              courseId={courseId}
              setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgrammeGradeMixModal;
