import React from 'react'
import { TrimText } from '../components/common/TrimText';
import PendingReview from "../assets/images/Pending-Review.png";

const UIRender = ({ color, text, type }) => {
    return {
        color,
        text,
        html: (
            <p className={`as-${color}-bg as-widget`}>{text}</p>
        ),
    }
}

const SpanUIRender = ({ color, text, type, otherProps }) => {
    if (type === "classType") {
        return {
            color,
            text,
            html: (
                <span title={text} className={`as-${color}-bg as-widget no-text-transformation mr-2`}>{otherProps.icon}&nbsp;{text}</span>
            ),
        }
    }
    if (type === "icononlyclassType") {
        return {
            color,
            text,
            html: (
                <span title={text} className={`as-${color}-bg as-widget no-text-transformation mr-2`}>{otherProps.icon}&nbsp;</span>
            ),
        }
    }
    if (type === "classStatus") {
        return {
            color,
            text,
            html: (
                <span title={text} className={`as-${color}-bg as-widget no-text-transformation mr-2`}>{text}</span>
            ),
        }
    }
    if (type === "activityStatus") {
        return {
            color,
            text,
            html: (
                <span title={text} className={`no-text-transformation mr-2`}>{text}</span>
            ),
        }
    }
    return {
        color,
        text,
        html: (
            type === "resourceAvailablity" && text === "Issued" ?
                <span className={`as-${color}-bg as-widget`}>{text}</span> :
                <span title={text} className={`as-${color}-bg as-widget`}>{text}</span>
        ),
    }
}

const HoverUIRender = ({ color, text, type }) => {
    return {
        color,
        text,
        html: (
            <h6 style={{ color: '#fff' }} className={`as-${color}-bg as-widget`}>{text}</h6>
        ),
    }
}

const BrandRender = ({ color, text, type }) => {
    return {
        color,
        text,
        html: (
            <span className={"as-widget ml-2"} style={{ background: color, color: "#fff" }}>{text}</span>
        ),
    }
}
const UITopic = ({ color, text, type }) => {
    if (type == "reason") {
        return {
            color,
            text,
            html: (
                <p title={text} style={{ color: color }} className='cat decline-reason-comment'><i className="fas fa-circle mr-1"></i> {text}</p>
            ),
        }
    }
    return {
        color,
        text,
        html: (
            <span title={text} className="as-widget mr-1 mt-1" style={{ color: '#fff', background: color }}>{text}</span>
        ),
    }
}

const UIKeyword = ({ text, type }) => {
    return {
        text,
        html: (
            <span className="as-widget" style={{ color: '#333333', background: '#e6e6e6' }}>{text}</span>
        ),
    }
}

const UIDate = ({text}) => {
    return {
        text,
        html: (
            <span className="as-widget-date" style={{color: '#333333'}}>{text}</span>
        ),
    }
}

const ImageRender = ({ color, text, type, pic }) => {
    return {
        color,
        text,
        html: (
            <span className="as-widget"><img className='mr-2' style={{ height: 24 }} src={pic} />{text}</span>
        ),
    }
}

const sapnUiLevelRender = ({ color, text }) => {
    return {
        color,
        text,
        html: (
            <span className={`cat cat-${color}`}><i className="fas fa-circle mr-1"></i>{text}</span>
        ),
    }
}

//only for Status in ERPData table
export const RenderERPStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "closed won":
            return UIRender({ color: "green", text: "Closed Won", type: "erpStatus" });
        case "called":
            return UIRender({ color: "blue", text: "Called", type: "erpStatus" });
        case "closed pending":
            return UIRender({ color: "orange", text: "Closed Pending", type: "erpStatus" });
        case "customer-closed won":
            return UIRender({ color: "orange", text: "CUSTOMER-Closed Won", type: "erpStatus" });
        case "Final":
            return UIRender({ color: "green", text: "Final", type: "erpStatus" });
        default:
            return UIRender({ color: "red", text: data, type: "erpStatus" });
    }
};

//only for Status in Programme Student table
export const RenderProgrammeStudentStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "Active", type: "programmeStudent" });
        case "inactive":
            return SpanUIRender({ color: "skyblue", text: "InActive", type: "programmeStudent" });
        case "in active":
            return SpanUIRender({ color: "skyblue", text: "In Active", type: "programmeStudent" });
        case "draft bo":
            return SpanUIRender({ color: "brown", text: "Draft BO", type: "programmeStudent" });
        case "drop bo":
            return SpanUIRender({ color: "brown", text: "Draft BO", type: "programmeStudent" });
        case "drop fo":
            return SpanUIRender({ color: "blue", text: "Draft BO", type: "programmeStudent" });
        case "draft fo":
            return SpanUIRender({ color: "blue", text: "Draft FO", type: "programmeStudent" });
        case "drop out":
            return SpanUIRender({ color: "yellow", text: "Drop Out", type: "programmeStudent" });
        case "finished":
            return SpanUIRender({ color: "yellowshade", text: "Finished", type: "programmeStudent" });
        case "on hold":
            return SpanUIRender({ color: "red", text: "On Hold", type: "programmeStudent" });
        case "cancelled":
            return SpanUIRender({ color: "purple", text: "Cancelled", type: "programmeStudent" });
        case "results witheld":
            return SpanUIRender({ color: "purple", text: "Results Witheld", type: "programmeStudent" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "programmeStudent" });
    }
};

//only for Status in exam center status table
export const RenderCenterStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "closed":
            return SpanUIRender({ color: "red", text: "CENTRE CLOSED", type: "centerStatus" });
        case "active":
            return SpanUIRender({ color: "green", text: "CENTRE ACTIVE", type: "centerStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "centerStatus" });
    }
};

//only for Status in Course table
export const RenderCourseStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "in progress":
            return SpanUIRender({ color: "blue", text: "In Progress", type: "courseStatus" });
        case "past":
            return SpanUIRender({ color: "past", text: "Past", type: "courseStatus" });
        case "upcoming":
            return SpanUIRender({ color: "upcoming", text: "Upcoming", type: "courseStatus" });
        case "today":
            return SpanUIRender({ color: "skyblue", text: "Today", type: "courseStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "courseStatus" });
    }
};

export const RenderActivityStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "subject_approved":
            return SpanUIRender({ color: "", text: "Subject Approved", type: "activityStatus" });
        case "ad_hoc":
            return SpanUIRender({ color: "", text: "Ad-hoc Note", type: "activityStatus" });
        case "subject_completed":
            return SpanUIRender({ color: "", text: "Subject Completed", type: "activityStatus" });
        case "subject_started":
            return SpanUIRender({ color: "", text: "Subject Started", type: "activityStatus" });
        case "subject_declined":
            return SpanUIRender({ color: "", text: "Subject Declined", type: "activityStatus" });
        case "subject_updated":
            return SpanUIRender({ color: "", text: "Subject Updated", type: "activityStatus" });         
        default:
            return SpanUIRender({ color: "", text: data, type: "activityStatus" });
    }
};

//only for Status in Assessment table
export const RenderAssessmentStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "pending":
            return SpanUIRender({ color: "green", text: "Pending", type: "assessmentStatus" });
        case "open":
            return SpanUIRender({ color: "blue", text: "Open", type: "assessmentStatus" });
        case "closed":
            return SpanUIRender({ color: "red", text: "Closed", type: "assessmentStatus" });
        case "past due":
            return SpanUIRender({ color: "past", text: "Past Due", type: "assessmentStatus" });
        case "marked":
            return SpanUIRender({ color: "red", text: "Marked", type: "assessmentStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "assessmentStatus" });
    }
};

export const RenderLevelStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "easy":
            return sapnUiLevelRender({ color: "yellowshade", text: "Easy" });
        case "medium":
            return sapnUiLevelRender({ color: "blue", text: "Medium" });
        case "hard":
            return sapnUiLevelRender({ color: "red", text: "Hard" });
        default:
            return sapnUiLevelRender({ color: "black", text: data });
    }
};

//Only for Registration Status
export const RenderRegistrationStatus = (data) => {
    switch (data ? data.toString().replaceAll("-", "").toLowerCase() : "") {
        case "reregistrationaccounts":
            return SpanUIRender({ color: "cwrej", text: "Re-Reg Accounts", type: "registraionStatus" });
        case "assignsubjects":
            return SpanUIRender({ color: "cwred", text: "[CW] Assign Subjects", type: "registraionStatus" });
        case "closedordered":
            return SpanUIRender({ color: "skpengreen", text: "[CW] Study Kit Pending", type: "registraionStatus" });
        case "closedordered2":
            return SpanUIRender({ color: "skpengreen", text: "[CW] Study Kit Pending", type: "registraionStatus" });
        case "finalstatus":
            return SpanUIRender({ color: "completegreen", text: "[CW] Complete", type: "registraionStatus" });
        case "rereg closed lost":
            return SpanUIRender({ color: "regblue", text: "[CL] Re-Reg Closed Lost", type: "registraionStatus" });
        case "inactive":
            return SpanUIRender({ color: "regblue", text: "[CL] In-Active", type: "registraionStatus" });
        case "newstatus":
            return SpanUIRender({ color: "cwred", text: "Closed Won", type: "registraionStatus" });
        case "preliminary reregistration":
            return SpanUIRender({ color: "prelimrred", text: "Prelim Re-Reg Review", type: "registraionStatus" });
        case "reregistration":
            return SpanUIRender({ color: "cwred", text: "Re-Reg Request", type: "registraionStatus" });
        case "reregistration cancelled":
            return SpanUIRender({ color: "regblue", text: "[CL] Re-Reg Cancelled", type: "registraionStatus" });
        case "rereg closed cancelled":
            return SpanUIRender({ color: "regblue", text: "[CL] Re-Reg Closed Cancelled", type: "registraionStatus" });
        case "reregistration rejected":
            return SpanUIRender({ color: "acadrejred", text: "Re-Reg Academic Rejected", type: "registraionStatus" });
        case "reregistrationaccounts rejected":
            return SpanUIRender({ color: "accrejred", text: "Re-Reg Accounts Rejected", type: "registraionStatus" });
        case "reregistrationfinal":
            return SpanUIRender({ color: "completegreen", text: "[CW] Re-Reg Complete", type: "registraionStatus" });
        case "reregistrationrco":
            return SpanUIRender({ color: "rcogreen", text: "[CW] Re-Reg RCO", type: "registraionStatus" });
        case "reregistrationsales":
            return SpanUIRender({ color: "calledyel", text: "Re-Reg Called", type: "registraionStatus" });
        case "rereg info pack":
            return SpanUIRender({ color: "ipyellow", text: "Re-Reg Info Pack", type: "registraionStatus" });
        case "rereg quote":
            return SpanUIRender({ color: "quoteyel", text: "Re-Reg Quote", type: "registraionStatus" });
        case "rereg application form":
            return SpanUIRender({ color: "appformgren", text: "Re-Reg Application Form", type: "registraionStatus" });
        case "rereg closed pending":
            return SpanUIRender({ color: "cpgreen", text: "Re-Reg Closed Pending", type: "registraionStatus" });
        case "reregistrationsales admin":
            return SpanUIRender({ color: "cwgreen", text: "Re-Reg Closed Won", type: "registraionStatus" });
        case "rereg called":
            return SpanUIRender({ color: "calledyel", text: "Re-Reg Called", type: "registraionStatus" });
        case "rereg invoiced":
            return SpanUIRender({ color: "yellowshade", text: "Sales-Invoiced", type: "registraionStatus" });
        case "reregistrationsalesrejected":
            return SpanUIRender({ color: "reregrejgren", text: "[CW] Re-Reg Rejected", type: "registraionStatus" });
        case "reregistrationstudy kit pending":
            return SpanUIRender({ color: "reregskpgreen", text: "[CW] Re-Reg Study Kit Pending", type: "registraionStatus" });
        case "registration withdrawn":
            return SpanUIRender({ color: "regblue", text: "[CL] Registration Withdrawn", type: "registraionStatus" });
        case "rejected":
            return SpanUIRender({ color: "cwrej", text: "[CW] Rejected", type: "registraionStatus" });
        case "review":
            return SpanUIRender({ color: "previewgreen", text: "[CW] Review", type: "registraionStatus" });
        case "study kit pending":
            return SpanUIRender({ color: "skpengreen", text: "[CW] Study Kit Pending", type: "registraionStatus" });
        case "not yet registered":
            return SpanUIRender({ color: "grey", text: "Not Yet Registered", type: "registraionStatus" });
        case "declined":
            return SpanUIRender({ color: "red", text: "Declined", type: "registraionStatus" });
        case "reposted":
            return SpanUIRender({ color: "repostyel", text: "[CW] Re Posted", type: "registraionStatus" });
        case "rereg reposted":
            return SpanUIRender({ color: "reportgreen", text: "[CW] Re-Reg Re-Posted", type: "registraionStatus" });
        default:
            return SpanUIRender({ color: "yellow", text: data, type: "registraionStatus" });
    }
}

// Only for status in pmc
export const RenderPMCStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "new":
            return SpanUIRender({ color: "skyblue", text: "New", type: "pmcStatus", backgroundColorHex: "#e0ffff", colorHex: "#40e0d0" });
        case "draft":
            return SpanUIRender({ color: "red", text: "Draft", type: "pmcStatus", backgroundColorHex: "#ffaaaa", colorHex: "#ff0000" });
        case "final":
            return SpanUIRender({ color: "green", text: "Final", type: "pmcStatus", backgroundColorHex: "#d5ffd9", colorHex: "#00c915" });
        case "approved":
            return SpanUIRender({ color: "yellowshade", text: "Approved", type: "pmcStatus", backgroundColorHex: "#e7e8d7", colorHex: "#abb072" });
        case "inactive":
            return SpanUIRender({ color: "blue", text: "Inactive", type: "pmcStatus", backgroundColorHex: "#e0edff", colorHex: "#1899d2" });
        default:
            return SpanUIRender({ color: "black", text: "N/A", type: "pmcStatus" });
    }
};
// Only for status in class
export const RenderClassStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "cancelled":
            return SpanUIRender({ color: "cancel", text: "Cancelled", type: "classStatus" });
        case "live":
            return SpanUIRender({ color: "live", text: "Live", type: "classStatus" });
        case "past":
            return SpanUIRender({ color: "past", text: "Past", type: "classStatus" });
        case "upcoming":
            return SpanUIRender({ color: "upcoming", text: "Upcoming", type: "classStatus" });
        case "not_launched":
        case "not launched":
            return SpanUIRender({ color: "not-launched", text: "Not Launched", type: "classStatus" });
        case "ended":
            return SpanUIRender({ color: "ended", text: "Ended", type: "classStatus" });
        case "launched":
            return SpanUIRender({ color: "launched", text: "Launched", type: "classStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "classStatus" });
    }
};
// Only for status in mentored student
export const RenderStudentStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "Active", type: "studentStatus" });
        case "on hold":
            return SpanUIRender({ color: "skyblue", text: "On Hold", type: "studentStatus" });
        case "in active":
            return SpanUIRender({ color: "red", text: "In Active", type: "studentStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "studentStatus" });
    }
};

// Only for status in mentored student
export const RenderAppealStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "appealpending":
            return SpanUIRender({ color: "green", text: "Pending", type: "appealStatus" });
        case "approved":
            return SpanUIRender({ color: "orange", text: "Approved", type: "appealStatus" });
        case "declined":
            return SpanUIRender({ color: "red", text: "Declined", type: "appealStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "appealStatus" });
    }
};

// Only for status in users
export const RenderUserStatus = (data) => {
    switch (data !== undefined && data !== null ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "Active", type: "userStatus" });
        case "1":
            return SpanUIRender({ color: "green", text: "Active", type: "userStatus" });
        case "inactive":
            return SpanUIRender({ color: "red", text: "InActive", type: "userStatus" });
        case "0":
            return SpanUIRender({ color: "red", text: "InActive", type: "userStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "userStatus" });
    }
};

//Only for externalExam status
export const RenderExtexamStudentStatus = (data) => {
    switch (data ? data.toString().toLowerCase() : "") {
        case "submitted":
            return SpanUIRender({ color: "skyblue", text: "Submitted", type: "ExtexamStudentStatus"});
        case "approved":
            return SpanUIRender({ color: "green", text: "Approved", type: "ExtexamStudentStatus" });
            case "approved-inv":
                return SpanUIRender({ color: "yellow", text: "Approved-Inv", type: "ExtexamStudentStatus"});
            case "approved-paid":
                return SpanUIRender({ color: "red", text: "Approved-Paid", type: "ExtexamStudentStatus" });
                case "withdrawn":
                    return SpanUIRender({ color: "blue", text: "Withdrawn", type: "ExtexamStudentStatus"});
        default:
            return SpanUIRender({ color: "black", text: data , type: "ExtexamStudentStatus" });
    }
};

//only for ticket status
export const RenderTicketStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "open":
            return SpanUIRender({ color: "yellow", text: "OPEN", type: "ticketStatus" });
        case "new":
            return SpanUIRender({ color: "skyblue", text: "NEW", type: "ticketStatus" });
        case "closed":
            return SpanUIRender({ color: "green", text: "CLOSED", type: "ticketStatus" });
        case "pendinginvestigation":
            return SpanUIRender({ color: "yellowshade", text: "PENDING-INVESTIGATION", type: "ticketStatus" });
        case "pendingstudent":
            return SpanUIRender({ color: "red", text: "PENDING-STUDENT", type: "ticketStatus" });
        case "pendingacademy":
            return SpanUIRender({ color: "blue", text: "PENDING-ACADEMY", type: "ticketStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "ticketStatus" });
    }
};

//only for ticket status
export const RenderSubmissionAndResultStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "ACTIVE", type: "submissionStatus" });
        case "cancelled":
            return SpanUIRender({ color: "red", text: "CANCELLED", type: "submissionStatus" });
        case "drop out":
            return SpanUIRender({ color: "yellow", text: "DROPOUT", type: "submissionStatus" });
        case "finished":
            return SpanUIRender({ color: "yellowshade", text: "FINISHED", type: "submissionStatus" });
        case "in active":
            return SpanUIRender({ color: "skyblue", text: "INACTIVE", type: "submissionStatus" });
        case "on hold":
            return SpanUIRender({ color: "red", text: "ONHOLD", type: "submissionStatus" });
        case "results witheld":
            return SpanUIRender({ color: "purple", text: "RESULTS WITHELD", type: "submissionStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "submissionStatus" });
    }
};

// using it for hover.js
export const RenderHoverStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return HoverUIRender({ color: "green", text: "Active", type: "programmeStudent" });
        case "inactive":
            return HoverUIRender({ color: "skyblue", text: "InActive", type: "programmeStudent" });
        case "in active":
            return HoverUIRender({ color: "skyblue", text: "In Active", type: "programmeStudent" });
        case "draft bo":
            return HoverUIRender({ color: "brown", text: "Draft BO", type: "programmeStudent" });
        case "drop bo":
            return HoverUIRender({ color: "brown", text: "Draft BO", type: "programmeStudent" });
        case "drop fo":
            return HoverUIRender({ color: "blue", text: "Draft BO", type: "programmeStudent" });
        case "draft fo":
            return HoverUIRender({ color: "blue", text: "Draft FO", type: "programmeStudent" });
        case "drop out":
            return HoverUIRender({ color: "yellow", text: "Drop Out", type: "programmeStudent" });
        case "finished":
            return HoverUIRender({ color: "yellowshade", text: "Finished", type: "programmeStudent" });
        case "on hold":
            return HoverUIRender({ color: "red", text: "On Hold", type: "programmeStudent" });
        case "cancelled":
            return HoverUIRender({ color: "purple", text: "Cancelled", type: "programmeStudent" });
        case "results witheld":
            return HoverUIRender({ color: "purple", text: "Results Witheld", type: "programmeStudent" });
        case "1":
            return HoverUIRender({ color: "green", text: "Active", type: "programmeStudent" });
        case "0":
            return HoverUIRender({ color: "skyblue", text: "InActive", type: "programmeStudent" });
        default:
            return HoverUIRender({ color: "black", text: data, type: "programmeStudent" });
    }
};

// This will only be used in Class type.
export const RenderClassType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "on_campus":
            return SpanUIRender({ color: "campus", text: "On Campus", type: "classType", otherProps: { icon: <i className="fal fa-university" /> } });
        case "hybrid":
            return SpanUIRender({ color: "hybrid", text: "Hybrid", type: "classType", otherProps: { icon: <><i className="fal fa-university" /><i className="fal fa-plus" /><i className="fal fa-house-signal" /></> } });
        case "bbb_class":
            return SpanUIRender({ color: "virtual", text: "Virtual", type: "classType", otherProps: { icon: <i className="fal fa-house-signal" /> } });
        case "icononlyon_campus":
            return SpanUIRender({ color: "campus", text: "On Campus", type: "icononlyclassType", otherProps: { icon: <i className="fal fa-university" /> } });
        case "icononlyhybrid":
            return SpanUIRender({ color: "hybrid", text: "Hybrid", type: "icononlyclassType", otherProps: { icon: <><i className="fal fa-university" /><i className="fal fa-plus" /><i className="fal fa-house-signal" /></> } });
        case "icononlybbb_class":
            return SpanUIRender({ color: "virtual", text: "Virtual", type: "icononlyclassType", otherProps: { icon: <i className="fal fa-house-signal" /> } });
        default:
            return SpanUIRender({ color: "black", text: "N/A", type: "classType", otherProps: { icon: <></> } });
    }
};

// This will only be used in class report -> attendance status .
export const RenderAttendanceStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "present":
            return SpanUIRender({ color: "green", text: "Present", type: "attendanceStatus" });
        case "partial":
        case "partially attended":
            return SpanUIRender({ color: "yellow", text: "Partially Attended", type: "attendanceStatus" });
        case "absent":
            return SpanUIRender({ color: "red", text: "Absent", type: "attendanceStatus" });
        case "online":
            return SpanUIRender({ color: "red", text: "Online", type: "attendanceStatus" });
        case "offline":
            return SpanUIRender({ color: "red", text: "Offline", type: "attendanceStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "attendanceStatus" });
    }
};

// only for external exam registration status.
export const RenderExternalRegistrationStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "open":
            return SpanUIRender({ color: "ext-reg-blue", text: "REGISTRATION OPEN", type: "registrationStatus" });
        case "closed":
            return SpanUIRender({ color: "ext-reg-red", text: "REGISTRATION CLOSED", type: "registrationStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "registrationStatus" });
    }
};

export const RenderExternalExamStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "hide":
            return SpanUIRender({ color: "green", text: "Hide", type: "exam Status" });
        case "closed":
            return SpanUIRender({ color: "ext-reg-red", text: "Closed", type: "exam Status" });
        case "in progress":
            return SpanUIRender({ color: "ext-reg-blue", text: "In Progress", type: "exam Status" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "exam Status" });
    }
};
//only for quiz status
export const RenderQuizStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "Active", type: "quizStatus" });
        case "draft":
            return SpanUIRender({ color: "blue", text: "Draft", type: "quizStatus" });
        case "new":
            return SpanUIRender({ color: "yellow", text: "New", type: "quizStatus" });
        case "approved":
            return SpanUIRender({ color: "yellowshade", text: "Approved", type: "quizStatus" });
        case "final":
            return SpanUIRender({ color: "skyblue", text: "Final", type: "quizStatus" });
        case "inactive":
            return SpanUIRender({ color: "red", text: "In active", type: "quizStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "quizStatus" });
    }
};
//only for Resource Management -> Availablity
export const RenderResourceAvailablity = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "is_available":
            return SpanUIRender({ color: "green", text: "Available", type: "resourceAvailablity" });
        case "available":
            return SpanUIRender({ color: "green", text: "Available", type: "resourceAvailablity" });
        case "missing":
            return SpanUIRender({ color: "purple", text: "Missing", type: "resourceAvailablity" });
        case "issued":
            return SpanUIRender({ color: "yellow", text: "Issued", type: "resourceAvailablity" });
        case "found":
            return SpanUIRender({ color: "yellowshade", text: "Found", type: "resourceAvailablity" });
        case "reserved":
            return SpanUIRender({ color: "skyblue", text: "Reserved", type: "resourceAvailablity" });
        case "returned":
            return SpanUIRender({ color: "red", text: "Returned", type: "resourceAvailablity" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "resourceAvailablity" });
    }
};

// only for student hub published filter
export const RenderHubPublished = (data) => {
    switch (data.toString() ? data.toString() == "0" ? 2 : data : "") {
        case 1:
            return SpanUIRender({ color: "green", text: "YES", type: "hubPublished" });
        case 2:
            return SpanUIRender({ color: "red", text: "NO", type: "hubPublished" });
        default:
            return SpanUIRender({ color: "black", text: "N/A", type: "hubPublished" });
    }
};

// only for listed brand
export const RenderLinkedBrands = (label, color) => {
    return BrandRender({ color: color, text: label, type: "linkedBrands" });
}

//only for quiz Attempt Result status
export const RenderQuizAttemptResultStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "pass":
            return SpanUIRender({ color: "green", text: "Pass", type: "quizResultStatus" });
        case "fail":
            return SpanUIRender({ color: "red", text: "Fail", type: "quizResultStatus" });
        default:
            return SpanUIRender({ color: "black", text: data, type: "quizResultStatus" });
    }
};

//only for Question Result
export const RenderQuizResult = (data) => {    
    console.log(data)
    if (data!="notmarked" && (parseFloat(data) > 0 && parseFloat(data) < 1)) {
        data = "partiallycorrect"
    }
    if (data!="notmarked" && (parseFloat(data) > 1)) {
        data = "correct"
    }
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "1":
            return SpanUIRender({ color: "green", text: "Correct", type: "quizResult" });
        case "correct":
            return SpanUIRender({ color: "green", text: "Correct", type: "quizResult" });
        case "0":
            return SpanUIRender({ color: "red", text: "Incorrrect", type: "quizResult" });
        case "incorrect":
            return SpanUIRender({ color: "red", text: "Incorrrect", type: "quizResult" });
        case "partiallycorrect":
            return SpanUIRender({ color: "blue", text: "Partially Correct", type: "quizResult" });
        case "notmarked":
            return SpanUIRender({ color: "yellow", text: "Pending Marking", type: "quizResult" });
        default:
            return SpanUIRender({ color: "cat-black", text: data, type: "quizResult" });
    }
};

//only for read unread status
export const RenderReadStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "read":
            return SpanUIRender({ color: "blue", text: "Read", type: "readStatus" });
        case "unread":
            return SpanUIRender({ color: "red", text: "Unread", type: "readStatus" });
        default:
            return SpanUIRender({ color: "cat-black", text: data, type: "readStatus" });
    }
};

//this is only for quiz and question topic
export const RenderQuizTopic = (label, color) => {
    return UITopic({ color: color, text: label, type: "quizTopic" });
};

export const RenderKeyword = (label) => {
    return UIKeyword({ text: label, type: "keyword" });
};

export const RenderDate = (label) => {
    return UIDate({text: label});
};

//This function is only for course -> student -> courseStatus
export const RenderCourseSStatus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "pendingsign off":
            return ImageRender({ color: "blue", text: "PENDING-SIGN OFF", type: "courseSStatus", pic: `https://www.myaie.ac/theme/img/badge-with-a-star-s.png` });
        case "pending review":
            return ImageRender({ color: "blue", text: "PENDING REVIEW", type: "courseSStatus", pic: PendingReview });
        case "not started":
            return ImageRender({ color: "red", text: "NOT STARTED", type: "courseSStatus", pic: `https://www.myaie.ac/theme/img/icon-pending.png` });
        case "inprogress":
            return ImageRender({ color: "red", text: "IN-PROGRESS", type: "courseSStatus", pic: `https://www.myaie.ac/theme/img/icon-InProg.png` });
        case "finished":
            return ImageRender({ color: "red", text: "FINISHED", type: "courseSStatus", pic: `https://www.myaie.ac/theme/img/icon-complete.png` });
        default:
            return ImageRender({ color: "cat-black", text: data, type: "courseSStatus" });
    }
};

//This function is only for course -> student -> courseStatus
export const RenderStatusForAttendance = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "active":
            return SpanUIRender({ color: "green", text: "Active", type: "statusForAttendance" });
        case "in active":
            return SpanUIRender({ color: "red", text: "In Active", type: "statusForAttendance" });
        case "in progress":
            return SpanUIRender({ color: "yellowshade", text: "In Progress", type: "statusForAttendance" });
        case "on hold":
            return SpanUIRender({ color: "yellow", text: "On Hold", type: "statusForAttendance" });
        case "past":
            return SpanUIRender({ color: "past", text: "Past", type: "statusForAttendance" });
        case "upcoming":
            return SpanUIRender({ color: "upcoming", text: "Upcoming", type: "statusForAttendance" });
        case "cancelled":
            return SpanUIRender({ color: "cancel", text: "Cancelled", type: "statusForAttendance" });
        case "live":
            return SpanUIRender({ color: "live", text: "Live", type: "statusForAttendance" });
        case "ended":
            return SpanUIRender({ color: "ended", text: "Ended", type: "statusForAttendance" });
        case "not_launched":
            return SpanUIRender({ color: "not-launched", text: "Not Launched", type: "statusForAttendance" });
        default:
            return SpanUIRender({ color: "cat-black", text: data, type: "statusForAttendance" });
    }
};

//This function is only for Re-reg -> student list -> Decline reson status
export const RenderReason = (label, color) => {
    return UITopic({ color: color, text: label, type: "reason" });
};

export const wordSpliter = (str, type) => {
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length ? strArr?.map((item, index) => {
        return <React.Fragment key={index}>{item ? <p className="as-widget mr-1 mt-1" style={{ color: '#333333', background: '#e6e6e6' }}>{item}</p> : '-'}</React.Fragment>;
    }) : "-";
    return newStrArr;
}

//only for quiz types
export const RenderQuizType = (data) => {
    return UITopic({ color: data?.color, text: data?.label, type: "quiz_type" });
};
