const initialState = {
    isOpen:false,
    videoId:null
};

const modalStatus = (state = initialState,action) => {

    switch(action.type) {
        case 'MODAL_OPEN' :
            state.isOpen = true;
            state.videoId = action.payload;
            return { ...state };
        case 'MODAL_CLOSE' :
            state.isOpen = false;
            state.videoId = null;
            return { ...state };
        default :
            return state;
    }

}

export default modalStatus;