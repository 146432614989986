import React from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";
import { DeleteOnlineClass, GetLuanchButtonData, GetOnlineClassGraphData, JoinClassAsAdmin, LaunchVirtualClass, UpcomingImportantDates, classLaunch, getCourseAvgRating, getCourseClassAttend, getSubmissionSummary, onlineClassesStatistics } from "../../../services/CourseService";
import { GetLearningDashboardURL, GetListOfDashboard } from "../../../services/DashBoardServices";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";

const OnlineClassFunctions = ({ launchDetail, setLaunchDetail, setBasicInfo, setOnlineClass, setLaunchLoading, portalId, setPortalId, loader, setLoader, setAnalysisBtn, setUpcomingImpDate, setTodayClasses, setAvgRatingArr, setAttendanceArr, setAvgRating, setSubmissionSummaryData, updateTodayClassesList, setUpdateTodayClassesList }) => {

    const UpcomingImpDateFunction = async (course_id, setLoading) => {
        setLoading(true)
        UpcomingImportantDates({ course_id }).then(res => {
            setUpcomingImpDate(res.data?.data || [])
            setLoading(false)
        }).catch(err => {
            console.error("error: ", err)
            setLoading(false)
        })
    }

    const SubmissionSummaryFunction = (intake_id, viaDays, setLoading) => {
        setLoading(true)
        getSubmissionSummary({ intake_id, viaDays }).then(res => {
            let arr = []
            arr.push({ category: 'Outstanding', value: res.data.outstanding })
            arr.push({ category: 'Submitted', value: res.data.completed })
            arr.push({ category: 'Not Submitted', value: res.data.overDue })
            let isAllEmpty = false;
            if (res.data.outstanding === 0 && res.data.completed === 0 && res.data.overDue === 0) {
                isAllEmpty = true
            }
            setSubmissionSummaryData(arr)
            setLoading(false)
        }).catch(err => {
            console.error("error: ", err)
            setLoading(false)
        })
    }

    const OnlineClassStats = (course_id, viaDays, setLoading) => {
        setLoading(true)
        onlineClassesStatistics({ course_id, viaDays }).then(res => {
            let tempArr = []
            Object.keys(res.data?.data || {}).forEach(key => {
                tempArr.push({
                    status: key,
                    Classes: res.data?.data[key]
                })
            })
            setOnlineClass(tempArr)
            setLoading(false)
        }).catch(err => {
            console.error("error: ", err)
            setLoading(false)
        })
    }

    const TodayClasses = (apiData, setLoading) => {
        setLoading(true)
        GetListOfDashboard(apiData).then(res => {
            if (res.data?.result1?.length > 4) {
                res.data.result1.length = 4
            }
            setTodayClasses(res.data?.result1 || [])
            setLoading(false)
        }).catch(err => {
            console.error("error: ", err)
            setLoading(false)
        })
    }

    const AverageRating = (id, averageClassRatingDays, setLoading) => {
        setLoading(true)
        getCourseAvgRating({ inatke_id: id, viaDays: averageClassRatingDays }).then(res => {
            let arr = res.data.data
            // arr.length = 13
            // arr.splice(0, 8)
            arr.map(item => item?.main_avg_rating)
            setAvgRatingArr(arr.map(item => item?.main_avg_rating))
            setAvgRating(res.data.AvgRatingforAllDays ?? 0)
            setLoading(false)
        }).catch(err => {
            console.error("error", err)
            setLoading(false)
        })
    }

    const ClassAttendance = (id, attendanceDays, setLoading) => {
        setLoading(true)
        getCourseClassAttend({ inatke_id: id, viaDays: attendanceDays }).then(res => {
            let arr = res.data.data.map(item => {
                return {
                    ...item,
                    date: moment(item.date).format('DD-MM'),
                    "Attendance": item.getAttendance
                }
            })
            // arr.length = 13
            // arr.splice(0, 8)
            setAttendanceArr(arr)
            setLoading(false)
        }).catch(err => {
            console.error("error", err)
            setLoading(false)
        })
    }

    const getFirstGraphData = (userId) => {
        GetOnlineClassGraphData(userId).then(response => {
            setBasicInfo(response && response.data ? response.data : {})
        }).catch(error => {
            console.error("error ----> ", error);
        })
    }

    const upcomingImpDateColumn = (row, id, setRecurring, setClassId, updateTodayClassesList, setUpdateTodayClassesList) => <div className="assessment-08 btn-dropdown-grp">
        <div className="as-buttons">
            <button
                className="btn btn-primary rounded-circle"
                title="Launch"
                data-toggle="modal"
                data-target="#launch"
                onClick={() => {
                    launchButton(row);
                    setOnlineClass({
                        class_id: row.class_id,
                        course_id: row.courseID,
                    });
                }}
            >
                <i className="fal fa-rocket"></i>
            </button>
            <div className="dropdown btn-dropdown-item">
                <button
                    className="btn btn-primary rounded-circle dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >

                    {row.status == "Past" && <PermissionsGate
                        scopes={["cocview"]}
                        RenderError={() => (
                            <button
                                className="btn btn-primary rounded-circle"
                                title="Class Report"
                            >
                                <i className="fal fa-users"></i>
                            </button>
                        )}
                    >
                        <Link
                            to={`/classReport/summary/${id}/${row.class_id}`}
                            className="btn btn-primary rounded-circle"
                            title="Class Report"
                        >
                            <i className="fal fa-users"></i>
                        </Link>
                    </PermissionsGate>}
                    {row.status !== "Live" && ((row.launch_disable != 1 && row.status !== "Past") && (
                        (row.isRecurring == "0" || (row.isRecurring == 1 && row.isRecurringDifferent == 1)) ?
                            <PermissionsGate
                                scopes={["cocedit"]}
                                RenderError={() =>
                                    <button
                                        disabled
                                        className="btn btn-primary rounded-circle"
                                        title="Open"
                                    >
                                        <i className="fal fa-folder-open"></i>
                                    </button>}
                            >
                                <Link
                                    to={{ pathname: "/courseAdministration/coursesdetails/" + id + "/onlineClasses/" + row.id + "/open/" + row.isRecurring }}
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                >
                                    <i className="fal fa-folder-open"></i>
                                </Link>
                            </PermissionsGate> :
                            <PermissionsGate
                                scopes={["cocedit"]}
                                errorProps={{ disabled: true }}
                            >
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    data-toggle="modal"
                                    data-target="#editModal"
                                    onClick={() => {
                                        setRecurring(row.isRecurring);
                                        setClassId(row.id)
                                    }}
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            </PermissionsGate>
                    ))}
                    {row.status != "Past" &&
                        <PermissionsGate
                            scopes={["cocview"]}
                            errorProps={{ disabled: true }}
                        >
                            <Link
                                target="_blank"
                                to={`/classReport/summary/${id}/${row.class_id}`}
                            >
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Class Report"
                                >
                                    <i className="fal fa-users"></i>
                                </button>
                            </Link>
                        </PermissionsGate>}
                    <PermissionsGate
                        scopes={["cocadd"]}
                        errorProps={{ disabled: true }}
                    >
                        <button
                            className="btn btn-primary rounded-circle"
                            title="Class Recording"
                            data-toggle="modal"
                            data-target="#classrecording"
                            onClick={() => {
                                setClassId(row.id);
                            }}
                        >
                            <i className="fal fa-video"></i>
                        </button>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={["cocdelete"]}
                        errorProps={{ disabled: true }}
                    >
                        <button
                            className="btn btn-danger rounded-circle"
                            title="Unlink class"
                            onClick={() => handleDelete(row.id, "unlink", row, id, updateTodayClassesList, setUpdateTodayClassesList)}
                        >
                            <i className="fal fa-unlink"></i>
                        </button>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={["cocdelete"]}
                        errorProps={{ disabled: true }}
                    >
                        <button
                            className="btn btn-danger rounded-circle"
                            title="Cancel Class"
                            onClick={() => handleDelete(row.id, "cancel", row, id, updateTodayClassesList, setUpdateTodayClassesList)}
                        >
                            <i className="fal fa-times"></i>
                        </button>
                    </PermissionsGate>
                    <PermissionsGate
                        scopes={["cocdelete"]}
                        errorProps={{ disabled: true }}
                    >
                        <button
                            className="btn btn-danger rounded-circle"
                            title="Delete"
                            onClick={() => handleDelete(row.id, "remove", row, id, updateTodayClassesList, setUpdateTodayClassesList)}
                        >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </PermissionsGate>
                </div>
            </div>
        </div>
    </div>

    const getIconNameBasedOnType = (key) => {
        switch (key.toLowerCase()) {
            case "assessment":
                return "fa-file-alt icon-blue";

            case "class":
                return "fa-chalkboard-teacher icon-green";

            case "exam":
            case "exams":
                return "fa-pen icon-red";

            case "upcoming_marking":
                return "fa-pen-alt icon-yellow";

            case "overdue_marking":
            case "making_over_due":
                return "fa-exclamation-circle icon-grey";

            default:
                return "fa-file-alt icon-blue";
        }
    }

    const getLabelNameBasedOnType = (key) => {
        switch (key.toLowerCase()) {
            case "assessment":
                return "Assignment";

            case "class":
                return "Scheduled Live Class";

            case "exam":
            case "exams":
                return "Exam";

            case "upcoming_marking":
                return "Upcoming Marking";

            case "overdue_marking":
            case "making_over_due":
                return "Overdue Marking";

            default:
                return "Assignment";
        }
    }

    function launchButton(row) {
        setLaunchLoading(true)
        setPortalId(row.id);
        // setStatusData(row.class_type);
        setLaunchDetail({});
        let formData = new FormData();
        formData.append("vclassID", row.id);
        formData.append("class_type", row.class_type);
        GetLuanchButtonData(formData)
            .then((data) => {
                const launchBtnData = data && data.data ? data.data : {};
                setLaunchDetail({ ...row, ...launchBtnData });
                const sendData = {
                    server_id: row.server_id,
                    bbb_class_id: row.big_blue_button_id
                }
                GetLearningDashboardURL(sendData).then((res) => {
                    if (res.status === 200) {
                        if (res.data.URL) {
                            setAnalysisBtn({ disable: false, link: res.data.URL })
                        }
                    }
                }).catch((err) => { console.error(err) })
                setLaunchLoading(false)
            })
            .catch((error) => {
                console.error("error >>>>>>> ", error);
                setLaunchLoading(false)
            });
    }

    function joinAsAdmin(joinData) {
        if (launchDetail.status !== "Live" && launchDetail.subStatus !== "Launched")
            return;
        const formData = new FormData();
        formData.append("class_id", launchDetail.id);
        formData.append(
            "learner_name",
            launchDetail.lerner ? launchDetail.lerner : ""
        );
        JoinClassAsAdmin(formData)
            .then((res) => {
                if (joinData == "copy") {
                    Swal.fire({
                        title: "Copied",
                    }).then(() => {
                        closeLaunchPopup();
                        navigator.clipboard.writeText(res.data.joinMeetingURL);
                    })
                } else {
                    window.open(res.data.joinMeetingURL.toString(), "_blank");
                    closeLaunchPopup();
                }
            })
            .catch((err) => {
                console.error("error while joining class as admin ---> ", err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data[0].message}`,
                });
            });
    }

    function closeLaunchPopup() {
        setAnalysisBtn({ disable: true, link: "" });
        $("#launch").modal("hide");
    }

    function launchVClass(id, loaderAt, isStart = true, updateTodayClassesList, setUpdateTodayClassesList) {
        Swal.fire({
            title: "Loading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        if (launchDetail.class_type === "hybrid" && isStart === true) {
            setLoader([...loader, 1, 2]);
        } else if (launchDetail.class_type === "hybrid" && isStart === false) {
            setLoader([...loader, 3, 4]);
        } else {
            setLoader([loaderAt]);
        }
        let formData = new FormData();
        formData.append("portalID", id);
        LaunchVirtualClass(formData, isStart)
            .then((data) => {
                setLoader([]);
                Swal.close()
                if (data.data?.message) {
                    Swal.fire("Class Ended!", data.data?.message, "success");
                    closeLaunchPopup();
                }
                if (data.data?.data?.url) {
                    window.open(data.data?.data?.url.toString(), "_blank");
                    closeLaunchPopup();
                } else {
                    Swal.fire(isStart ? "Launched!" : "Ended!", data.data?.data?.message, "success").then(() => {
                        closeLaunchPopup();
                    });
                }
                if (isStart) {
                    setUpdateTodayClassesList(!updateTodayClassesList)
                } else {
                    setTimeout(() => {
                        setUpdateTodayClassesList(!updateTodayClassesList)
                    }, 1000);
                }
            })
            .catch((error) => {
                Swal.close()
                setLoader([]);
                console.log(">>>>> ", error);
                setUpdateTodayClassesList(!updateTodayClassesList)
            });
    }

    const launchCampusClass = (action, setUpdateTodayClassesList, updateTodayClassesList) => {
        Swal.fire({
            title: "Loading",
            onOpen: function () {
                Swal.showLoading()
            }
        })
        let launchData = new FormData();
        launchData.append("portal_id", portalId);
        launchData.append("action", action);
        classLaunch(launchData)
            .then((res) => {
                Swal.close()
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated successfully",
                });
                $("#launch").modal("hide");
                setUpdateTodayClassesList(!updateTodayClassesList)

            })
            .catch((err) => {
                console.log("launch error", err.response.data.message);
                Swal.close()
                Swal.fire({
                    text: err.response.data.message,
                    icon: "warning",
                });
                setUpdateTodayClassesList(!updateTodayClassesList)
            });
    };

    const handleDelete = (no, type = "", row, id, updateTodayClassesList, setUpdateTodayClassesList) => {
        let msgType = "";
        let msg = "";
        let btnText = "";
        if (type == "remove") {
            btnText = "Yes, delete it!"
            msgType = "Deleted!";
            msg = "Class deleted successfully.";
        } else if (type == "cancel") {
            btnText = "Yes, cancel it!"
            msgType = "Canceled!";
            msg = "Class cancelled successfully.";
        } else if (type == "unlink") {
            btnText = "Yes, unlink it!"
            msgType = "Unlinked!";
            msg = "Class unlinked successfully.";
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: btnText,
        }).then((result) => {
            if (result.isConfirmed) {
                // TODO Check
                // setPopUpState({ cid: no, intake: id, act: type })
                if (type == "remove" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
                    $("#deleteClass").modal("show");
                    return
                } else if (type == "cancel" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
                    $("#cancelClass").modal("show");
                    return
                } else if (type == "unlink" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
                    $("#unlinkClass").modal("show");
                    return
                }
                let formData = new FormData();
                formData.append("cid", no);
                formData.append("intake", id);
                formData.append("act", type);

                DeleteOnlineClass(formData)
                    .then((data) => {
                        Swal.fire(msgType, msg, "success");
                        setUpdateTodayClassesList(!updateTodayClassesList)
                    })
                    .catch((error) => {
                        console.error("error ===> ", error);
                        setUpdateTodayClassesList(!updateTodayClassesList)
                    });
            }
        });
    };

    function printRatingStars(rating) {
        let totalRating = [];
        for (var i = 0; i < 5; i++) {
            totalRating.push(i);
        }
        return totalRating.map((val) => {
            return (
                <span className="icon-w" key={val}>
                    {val < rating ? <i
                        className="fa fa-star"
                        style={{ color: "#467fcf" }}
                        title="Rating"
                    ></i> : <i
                        className="fal fa-star"
                        style={{ color: "#467fcf" }}
                    ></i>}
                </span>
            );
        });
    }

    return {
        getFirstGraphData, getIconNameBasedOnType, upcomingImpDateColumn, launchButton, launchCampusClass, launchVClass, joinAsAdmin, closeLaunchPopup, UpcomingImpDateFunction, OnlineClassStats, TodayClasses, AverageRating, ClassAttendance, handleDelete, printRatingStars, SubmissionSummaryFunction, getLabelNameBasedOnType
    }
}


export default OnlineClassFunctions;