import axiosInstance from "../utils/axios";

export const GetRoles = async (role_id, isNewPortal) => {
    return await axiosInstance().get(`/getRoles/${role_id}/${isNewPortal}`);
}

export const UpdateRolePermission = async (value) => {
    return await axiosInstance().post(`/update-role-permission/`, value);
}

export const GetNewRoles = async (role_id) => {
    return await axiosInstance().get(`/get-new-roles/${role_id}`);
}

export const UpdateNewRolePermission = async (value) => {
    return await axiosInstance().post(`/update-new-role-permission/`, value);
}

export const GetAllPermissions = async (role_id) => {
    return await axiosInstance().get(`/get-all-permissions/${role_id}`);
}

export const GetListOfPMAuditTrail = async () => {
    return await axiosInstance().post(`/auditTrailPM`, {});
  };