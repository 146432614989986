import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../utils/permissionGate";
import { RenderAssessmentType } from "../../../utils/CommonGroupingItem";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  GradeLetterAggregationMethods,
  GradeLetterTemplateDetail,
  GradeLetterTypes,
  getGradeDetails,
  getGradeMixDropdown,
  updateGradeDetails,
} from "../../../services/GradeSettingService";
import ListOfTemplateModal from "../CourseGradeSettings/ListOfTemplateModal";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import Tablefilter from "../../common/Tablefilter";
import { EditGradeMix, GetGradeMixDetails } from "../../../services/GradeMixService";

let options = [
  { label: 'Red', value: '0' },
  { label: 'Yellow', value: '49' },
  { label: 'Green', value: '100' },
]

const GradeMixDetails = ({ setGradeName }) => {
  const history = useHistory()
  const { id, subId } = useParams();
  const [search, setSearch] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gradingMethodValue, setGradingMethodValue] = useState("1");
  const [aggregationMethodValue, setAggregationMethodValue] = useState();
  const [gradeTypeValue, setGradeTypeValue] = useState();
  const [dropoutValue, setDropoutValue] = useState("");
  const [passMark, setPassMark] = useState("");
  const [passMarkValue, setPassMarkValue] = useState("");
  const [formulaValue, setFormulaValue] = useState("");
  const [gradeTypeArr, setGradeTypeArr] = useState([]);
  const [aggregationTypeArr, setAggregationTypeArr] = useState([]);
  const [excludeEmptyGrade, setExcludeEmptyGrade] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [page, setPage] = useState(1)
  const [sortOrder, setSortOrder] = useState("ASC")
  const [sortKey, setSortKey] = useState("name");
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [isGradeLocked, setIsGradeLocked] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [editedRows, setEditedRows] = useState([]);
  const [gradeMixName, setGradeMixName] = useState("");
  const [gradeMixSORName, setGradeMixSORName] = useState("");
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const tooltipObj = {
    aggregationMethodTooltip: "Median of grades - If this is selected, the programme grade must be determined by is the middle value (or the mean of the two middle values) when percentages are arranged in order of value for the courses.\nLowest Grade – If this is selected, the programme grade will be the lowest grade achieved for any of its courses.\nHighest Grade - If this is selected, the programme grade will be the highest grade achieved for any of its courses.\nMode of Grades - If this is selected, the programme grade will be the grade that occurs the most frequently.\nNatural - If this is selected, the programme grade will be the sum of all course grades, scaled by their relative weights.",
    dropoutTootltip: "The number entered will be used to exclude X number of courses which have the lowest final grade from the final calculation, this will only consider courses which have a weight if natural is selected"
  }
  const [courseIds, setCourseIds] = useState({ arr: [], checkObj: [] })
  const [linkedCourseDropdown, setLinkedCourseDropdown] = useState({ linkedCourse: [], type: [] })
  const [type, setType] = useState({ arr: [], checkObj: {} });

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({ scopes: ["pgmixview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  let templatePassMarkArr = [];
  let templateArray =
    selectedTemplate?.gradeOptions?.length &&
    selectedTemplate?.gradeOptions.map((item) => {
      templatePassMarkArr.push({
        value: item?.id,
        label: item?.text,
      });
    });
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  useEffect(() => {
    setLoading(true)
    let params = {
      page: page,
      limit: perPage,
      key: sortKey,
      sort: sortOrder,
      search: search,
      exportStatus: "true",
      id: subId,
      viaCourse: courseIds.arr,
      viaAssessmentType: type.arr
    };
    GetGradeMixDetails(params)
      .then((res) => {
        let details = res.data?.details;
        let assessmentList = res?.data?.result;
        setTotalRows(res?.data?.pagination?.total);
        setAssessmentList(assessmentList ? assessmentList : []);
        setGradeMixName(details?.name)
        setGradeName(details?.name)
        setGradeMixSORName(details?.sor_name)
        setAggregationMethodValue(details?.aggregation_method_id);
        setExcludeEmptyGrade(details?.exclude_empty_grades);
        setGradeTypeValue(details?.grading_type);
        setFormulaValue(details?.formula);
        if (details?.grading_type == "1" || details?.grading_type == "2") {
          setPassMarkValue(details?.pass_mark)
        } else {
          setPassMark(details?.pass_mark);
        }
        setDropoutValue(details?.drop_lowest_amount == 0 ? "" : details?.drop_lowest_amount);
        if (details?.grading_template_id) {
          GradeLetterTemplateDetail({ id: details?.grading_template_id })
            .then((res) => {
              setSelectedTemplate(res?.data?.list_data[0])
              setEditedRows([])
              setLoading(false)
            }).catch((err) => {
              setLoading(false)
              console.log(err)
            })
        }
        if (!details?.grading_template_id) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error("error :", err)
        setLoading(false)
      });

  }, [page, perPage, sortKey, sortOrder, search, updateData, courseIds, type]);

  useEffect(() => {
     let data = {
      gradeMixIds: subId,
      type: "grademix"
     }
     getGradeMixDropdown(data)
     .then((res) => {
      setLinkedCourseDropdown({...res.data, linkedCourse: res?.data?.intake ?  res?.data?.intake : [], type: res?.data?.assType ?  res?.data?.assType : [] })
     }).catch((err)=>console.log(err))
  }, [])

  useEffect(() => {
    GradeLetterTypes()
      .then((res) => {
        setGradeTypeArr(res?.data);
      })
      .catch((err) => console.log(err));
    GradeLetterAggregationMethods()
      .then((res) => {
        setAggregationTypeArr(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleWeightChange = async (e, assId) => {
    const assList = assessmentList
    let value = e.target.value
    if (!value) {
      value = 0
    } else {
      value = +value
    }
    let filteredObj = assList.find(item => item.id === assId);
    if (!filteredObj) {
      return
    }
    let alreadyAdded = editedRows.find(item => filteredObj.id === item.id)
    if (alreadyAdded) {
      alreadyAdded.ass_weighting = value
      await editedRows.splice(editedRows.findIndex(item => item.id == assId), 1)
      editedRows.push(alreadyAdded)
    } else {
      filteredObj.ass_weighting = value
      editedRows.push(filteredObj)
    }
  }

  const handlePassRequiredChange = async (checked, assId, key = "") => {
    const assList = assessmentList
    let filteredObj = assList.find(item => item.id === assId);
    if (!filteredObj) {
      return
    }
    let alreadyAdded = editedRows.find(item => filteredObj.id === item.id)
    let editedState = editedRows;
    if (alreadyAdded) {
      alreadyAdded[key] = checked ? 1 : 0
      await editedState.splice(editedState.findIndex(item => item.id == assId), 1)
      editedState.push(alreadyAdded)
    } else {
      filteredObj[key] = checked ? 1 : 0
      await editedState.push(filteredObj)
    }
    setEditedRows([...editedState])
  }

  const columns = useMemo(() => [
    {
      name: "Assessment",
      selector: "name",
      sortable: true,
      sortField: "name",
      cell: (row) => {
        return (
          <span title={row.name} className="overflow-ellipsis2 ">
            {row.name ? (
              <Link
                className="as-text-blue curser feature-name"
                to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/Details/${row.id}`}
              >
                <span className="textLimit100">{row.name}</span>
              </Link>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
        name: "Courses",
        selector: "intake_name",
        sortField: "intake_name",
        sortable: true,
        cell: (row) => {
          return (
            <span title={row.intake_name} className="overflow-ellipsis2 ">
              {row.name ? (
                <Link
                  className="as-text-blue curser feature-name font-weight-bold"
                  to={`/courseAdministration/coursesdetails/${row?.intake}/detailCourses/show`}
                >
                  <span className="textLimit100">{row.intake_name}</span>
                </Link>
              ) : (
                "-"
              )}
            </span>
          );
        },
    },
    aggregationMethodValue == 6 && {
      name: "Weight",
      selector: "ass_weighting",
      sortable: false,
      cell: (row) => (
        <div className="input_wt02 resultstatus_flex arrowhide">
          <PermissionsGate
            scopes={["cagsedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              className={
                "form-control custom-select-box" + (false ? " is-invalid" : "")
              }
              type="number"
              defaultValue={row.ass_weighting ? row?.ass_weighting : ""} // Set the initial value from the data
              title="Weight"
              onChange={(e) => {
                // handleWeightingChange(row.id, e.target.value, row?.pass_required);
                // handleWeightBlur(row, e?.target?.value);
                handleWeightChange(e, row.id)
              }} // Define a function to handle value changes
            />
          </PermissionsGate>
        </div>
      ),
    },
    {
      name: "Maximum Grade",
      selector: "maximumGrade",
      sortable: false,
      sortField: "maximumGrade",
      cell: (row) => (row?.maximumGrade ? row?.maximumGrade : "-"),
    },
    {
      name: "Pass Mark",
      selector: "nestedPassMark",
      sortable: true,
      sortField: "nestedPassMark",
      cell: (row) => (row?.nestedPassMark ? row?.nestedPassMark : "-"),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      sortField: "type",
      cell: (row) => (row.type ? RenderAssessmentType(row.type).html : "-"),
    },
    {
      name: "Pass Required",
      selector: "pass_required",
      sortable: false,
      cell: (row) => (
        <div className="custom-control custom-checkbox text-left table-curser" title="Pass Required">
          <PermissionsGate
            scopes={["cagsedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`pass_required${row.id}`}
              name="pass_required"
              checked={row.pass_required} // Set the initial value from the data
              // defaultChecked={row.pass_required}
              onChange={(e) => {
                // handlePassRequiredBlur(row, e.target?.checked);
                handlePassRequiredChange(e.target.checked, row.id, "pass_required")
              }} // Define a function to handle value changes
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    aggregationMethodValue != 6 && {
      name: "Exclude from calculation",
      selector: "exclude_from_calculation",
      sortable: false,
      cell: (row) => (
        <div className="custom-control custom-checkbox text-left table-curser" title="Pass Required">
          <PermissionsGate
            scopes={["cagsedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`exclude_from_calculation${row.id}`}
              name="exclude_from_calculation"
              defaultChecked={row.exclude_from_calculation} // Set the initial value from the data
              // defaultChecked={row.exclude_from_calculation}
              onChange={(e) => {
                handlePassRequiredChange(e.target.checked, row.id, "exclude_from_calculation")
              }} // Define a function to handle value changes
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["castedit"]}
              errorProps={{ disabled: true }}
            >
              <Link
                className="mr-1"
                to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/Details/${row.id}`}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Assessment",
      "Course",
      "Weight",
      "Maximum Grade",
      "Pass Mark",
      "Type",
      "Pass Required",
    ];

    let params = {
      page: page,
      limit: perPage,
      key: sortKey,
      sort: sortOrder,
      search: search,
      exportStatus: "true",
      id: subId,
    };
    GetGradeMixDetails(params)
      .then((res) => {
        data = res?.data?.result
        data = data?.map((row) => ({
          ...row,
          "Assessment": row?.name ? row.name : "-",
          "Course": row?.intake_name ? row?.intake_name : "-",
          "Weight": row?.ass_weighting ? row?.ass_weighting : "-",
          "Maximum Grade": row?.maximumGrade ? row?.maximumGrade : "-",
          "Pass Mark": row?.nestedPassMark ? row?.nestedPassMark : "-",
          "Type": row.type ? RenderAssessmentType(row.type).text : "-",
          "Pass Required": row.pass_required == "1" ? "True" : "False",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Assessment"],
              row["Course"],
              row["Weight"],
              row["Maximum Grade"],
              row["Pass Mark"],
              row["Type"],
              row["Pass Required"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          return false;
        }
      }).catch((err) => console.log(err))

  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleUpdateData = () => {
    if (gradeMixName === undefined || gradeMixName == "") {
        setErrorMessage("Grade Mix Name is Required")
        return;
    }
    if (gradeMixSORName === undefined || gradeMixSORName == "") {
        setErrorMessage("Grade Mix SOR Name is Required")
        return;
    }
    if (gradeTypeValue === undefined || gradeTypeValue == "5") {
      setErrorMessage("Grade Type is Required")
      return;
    }
    if (aggregationMethodValue === undefined && gradeTypeValue != 3) {
      setErrorMessage("Aggregation Method is Required")
      return;
    }
    if (gradeTypeValue == "2" || gradeTypeValue == "1") {

      if (passMarkValue == "") {
        setErrorMessage("Pass Mark is Required")
        return;
      }
    } else {
      if (passMark == "") {
        setErrorMessage("Pass Mark is Required")
        return;
      }
    }
    // if (dropoutValue > totalRows - 1 && gradeTypeValue != 3) {
    //   setErrorMessage("Dropout Value Must Be Smaller Than Total Assessments")
    //   return;
    // }
    if (!selectedTemplate?.id && gradeTypeValue == "2") {
      setErrorMessage("Template is Required")
      return;
    }
    setErrorMessage("")
    setIsDisabled(true);
    let data = {
      id: subId,
      name: gradeMixName,
      sor_name: gradeMixSORName,
      qualification_id: parseInt(id),
      aggregation_method_id: aggregationMethodValue,
      drop_lowest_amount: dropoutValue ? parseInt(dropoutValue) : 0,
      exclude_empty_grades: excludeEmptyGrade,
      // formula: formulaValue,
      // grading_method_id: gradingMethodValue,
      grading_template_id: selectedTemplate?.id ? selectedTemplate?.id : 0,
      grading_type: gradeTypeValue,
      pass_mark: gradeTypeValue == "1" || gradeTypeValue == "2" && passMarkValue ? passMarkValue : passMark ? passMark : 0,
      assessment: JSON.stringify(editedRows),
    };
    if (gradingMethodValue == "1") {
      data.aggregation_method_id = gradeTypeValue != 3 ? aggregationMethodValue : 1
    }
    EditGradeMix(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        });
        setIsDisabled(false);
        setEditedRows([])
        setUpdateData(!updateData)
        history.push(`/courseAdministration/Programmes/programme/open/${id}/gradeMix/table`);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something Went Wrong",
        });
        setUpdateData(!updateData)
        setIsDisabled(false);
        console.error("err: ", err);
      });
  };

  const resetFilter = () => {
    setSearch("");
    setCourseIds({ arr: [], checkObj: [] })
    setType({ arr: [], checkObj: [] })
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="grade-setting-box">
        <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">Details {loading ? <i className="fas fa-cog fa-spin"></i> : ""} </div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row grade-store-box ">
                <div className="col-md-6 col-lg-6">
                  <label htmlFor="Name"> Name *</label>
                  <div className="form-icon-group mb-4" title="Name">
                    <input
                      type="text"
                      className={"form-control" + (false ? " is-invalid" : "")}
                      name="Name"
                      id="Name"
                      placeholder="Name"
                      title="Name"
                      value={gradeMixName}
                      onChange={(e) => setGradeMixName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <label htmlFor="SOR_Name">SOR Name *</label>
                  <div className="form-icon-group mb-4" title="Name">
                    <input
                      type="text"
                      className={"form-control" + (false ? " is-invalid" : "")}
                      name="SOR Name"
                      id="SOR_Name"
                      placeholder="SOR Name"
                      title="SOR Name"
                      value={gradeMixSORName}
                      onChange={(e)=> setGradeMixSORName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">Grade Settings {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row grade-store-box ">
                <div className="col-md-4 col-lg-2">
                  <div className="form-icon-group mb-4 d-block" title="Grade Type">
                    <label>
                      Grade Type{" "}
                    </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (false ? " is-invalid" : "")
                      }
                      name="grade_type"
                      value={gradeTypeArr.filter(function (option) {
                        return option.value === gradeTypeValue;
                      })}
                      onChange={(value) => {
                        if (value) {
                          setGradeTypeValue(value.value);
                          setAggregationMethodValue()
                          setDropoutValue("")
                          setPassMarkValue("")
                          setPassMark("")
                          setSelectedTemplate({})
                        } else {
                          setGradeTypeValue();
                          setPassMarkValue("")
                          setPassMark("")
                          setAggregationMethodValue()
                          setDropoutValue("")
                          setSelectedTemplate({})
                        }
                      }}
                      isClearable
                      //   onBlur={formik.handleBlur}
                      options={gradeTypeArr}
                      maxMenuHeight={175}
                      placeholder={"Grade Type"}
                      isDisabled={isGradeLocked}
                    />
                  </div>
                </div>
                {gradeTypeValue || +gradeTypeValue === 0 ? <>
                  {gradeTypeValue != "3" && (
                    <div className="col-md-4 col-lg-2">
                      <div className="form-icon-group mb-4 d-block" title="Aggregation Method">
                        <label>
                          Aggregation Method{" "}
                          <i className="fal fa-info-circle grade-icon" title={tooltipObj.aggregationMethodTooltip}></i>
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (false ? " is-invalid" : "")
                          }
                          name="aggregation_method"
                          value={aggregationTypeArr.filter(function (option) {
                            return option.value == aggregationMethodValue;
                          })}
                          onChange={(value) => {
                            if (value) {
                              setAggregationMethodValue(value.value);
                            } else {
                              setAggregationMethodValue();
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={aggregationTypeArr}
                          maxMenuHeight={175}
                          placeholder={"Aggregation Method"}
                          isDisabled={isGradeLocked}
                        />
                      </div>
                    </div>
                  )}
                  {gradeTypeValue != "3" && (
                    <div className="col-md-4 col-lg-2" title="Dropout Value">
                      <label>
                        Dropout Value{" "}
                        <i className="fal fa-info-circle grade-icon" title={tooltipObj.dropoutTootltip}></i>
                      </label>
                      <div className="form-icon-group mb-4">
                        <input
                          className={
                            "form-control" + (false ? " is-invalid" : "")
                          }
                          type="number"
                          onChange={(e) => {
                            setDropoutValue(e.target.value)
                          }}
                          value={dropoutValue}
                          placeholder="Dropout Value"
                          disabled={isGradeLocked}
                        />
                      </div>
                    </div>
                  )}
                  {gradeTypeValue == "1" ? (
                    <div className="col-md-4 col-lg-2">
                      <div className="form-icon-group mb-4 d-block" title="Pass Mark">
                        <label>
                          Pass Mark{" "}
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (false ? " is-invalid" : "")
                          }
                          name="pass_mark"
                          value={options.filter(function (option) {
                            return option.value == passMarkValue;
                          })}
                          onChange={(value) => {
                            if (value) {
                              setPassMarkValue(value.value);
                            } else {
                              setPassMarkValue("");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={options}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                        />
                      </div>
                    </div>
                  ) : gradeTypeValue == "2" ? (
                    <div className="col-md-4 col-lg-2">
                      <div className="form-icon-group mb-4 d-block" title="Pass Mark">
                        <label>
                          Pass Mark{" "}
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (false ? " is-invalid" : "")
                          }
                          name="pass_mark"
                          value={templatePassMarkArr.filter(function (option) {
                            return option.value == passMarkValue;
                          })}
                          onChange={(value) => {
                            if (value) {
                              setPassMarkValue(value.value);
                            } else {
                              setPassMarkValue("");
                            }
                          }}
                          isClearable
                          //   onBlur={formik.handleBlur}
                          options={templatePassMarkArr}
                          maxMenuHeight={175}
                          placeholder={"Mark"}
                          isDisabled={isGradeLocked}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4 col-lg-2" title="Pass Mark">
                      <label>
                        Pass Mark{" "}
                      </label>
                      <div className="form-icon-group mb-4">
                        <input
                          className={
                            "form-control" + (false ? " is-invalid" : "")
                          }
                          type="number"
                          onChange={(e) => {
                            if (e.target.value) {
                              setPassMark(e.target.value)
                            } else {
                              setPassMark("")
                            }
                          }}
                          value={passMark}
                          placeholder="Pass Mark"
                          disabled={isGradeLocked}
                        />
                      </div>
                    </div>
                  )}
                  {gradeTypeValue == "2" && (
                    <div className="search-filter-div-right mb-4">
                      <label>&nbsp;</label>
                      <div className="filter-search-bar-blk">
                        <div className="add-ticket-blk dropdown-comman">
                          <button
                            // className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#letterTemplatemodal"
                            className="btn btn-save btn-success"
                            type="button"
                            title="Select Template"
                            disabled={isGradeLocked}
                          >
                            <i className="fal fa-file-alt"></i>Select Template
                          </button>
                          {selectedTemplate &&
                            <>
                              {selectedTemplate && selectedTemplate?.name && <div className="d-flex select-delete">
                                <Link
                                  className="as-text-blue curser"
                                  title="Open"
                                  to={`/courseAdministration/courses/addTemplate/${selectedTemplate?.id}`}
                                >
                                  <span className="textLimit100">
                                    {selectedTemplate?.name}
                                  </span>
                                </Link>
                                <button
                                  className="btn btn-danger rounded-circle btn-dropdown-item"
                                  title="Delete"
                                  onClick={() => setSelectedTemplate({})}
                                  disabled={isGradeLocked}
                                >
                                  <i className="fal fa-trash-alt"></i>
                                </button>
                              </div>}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </> : ""}
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                {/* <div className="form-group-blk exclude-check-box-template"> */}
                <div
                  className="custom-check custom-control custom-checkbox mt-2 mx-4"
                  title="Exclude empty grades"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="empty_grade"
                    name="select"
                    checked={excludeEmptyGrade}
                    onChange={(e) =>
                      setExcludeEmptyGrade(e.target.checked ? 1 : 0)
                    }
                  />
                  <label className="custom-control-label" htmlFor="empty_grade">
                    Exclude empty grades
                  </label>
                </div>
                {/* </div> */}
                 <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                     filterName="Courses"
                     optionArr={linkedCourseDropdown.linkedCourse}
                     state={courseIds}
                     setState={setCourseIds}
                     isSearchFilter={true}
                    />
                    <Tablefilter
                      filterName={"Type"}
                      optionArr={linkedCourseDropdown.type}
                      state={type}
                      setState={setType}
                      uniqueId="gradeMixAssType"
                      renderLabelFunction={RenderAssessmentType}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export spreadsheet"
                      onClick={() => {
                        exportData("xlsx", "Grade Settings List");
                      }}
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export CSV"
                      onClick={() => {
                        exportData("csv", "Grade Settings List");
                      }}
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export PDF"
                      onClick={() => {
                        exportData("pdf", "Grade Settings List");
                      }}
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="search-filter-div-right">
            <div className="filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  scopes={["castadd"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      history.push(
                        `/courseAdministration/coursesdetails/${id}/${tab}/add`
                      )
                    }
                    title="Create New"
                  >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div> */}
        </div>
        {/* {loading ? <SkeletonTicketList /> : ( */}
        <DataTable
          data={assessmentList}
          columns={columns.map(item => item)}
          defaultSortField="name"
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          defaultSortAsc={true}
          onSort={handleSort}
          // pagination
          // paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        {/* )} */}
        <div className="form-group form-group-save-cancel without-pagination-btns">
          <PermissionsGate
            scopes={["pgmixedit"]}
            errorProps={{ disabled: true }}
          >
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
              onClick={handleUpdateData}
              disabled={isDisabled}
            >
              {isDisabled ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Save
            </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => {
              setUpdateData(!updateData)
            }}
          >
            <i className="fal fa-times"></i>Cancel
          </button>
          {errorMessage ? <div className="invalid-feedback d-block">
            {errorMessage}
          </div> : ""}
        </div>
      </div>
      <ListOfTemplateModal
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
      />
    </div>
  );
};

export default GradeMixDetails;
