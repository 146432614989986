import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { CheckJSONString } from "../../common/CheckJSONString";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import HtmlParser from "react-html-parser";
import { TrimText } from "../../common/TrimText";
import Select from "react-select";
import { MathJax } from "better-react-mathjax";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../../utils/ReArrangeFunction";
import Dropzone from 'react-dropzone-uploader';

function OptionList({
  item,
  index,
  from,
  selectedOptionId
}) {
  return (
    <div className="">
      {/* que-custom-space */}
     {from == "viewAnswer" ? 
     <input
     type="radio"
     className="cursor-pointer"
     name="example"
     id={`customRadio_${item.q_option + index}`}
     checked={selectedOptionId && selectedOptionId?.UserChooseOptionId && selectedOptionId?.UserChooseOptionId == item?.oid}
     readOnly
   /> :
     <input
        type="radio"
        className="cursor-pointer"
        name="example"
        id={`customRadio_${item.q_option + index}`}
      />}

      <label className="ml-2 cursor-pointer" htmlFor={`customRadio_${item.q_option + index}`}>
        {(from == "addQuestion" || from == "duplicateQuestion" || from == "viewQuestion"
         ) ? item.q_option : from == "viewAnswer" ? HtmlParser(item.q_option) : HtmlParser(item.q_option)}
      </label>


      {/* {JSON.parse(item.q_option).blocks.length > 1 ?
        <div className="form-icon-group" title={CheckJSONString(item.q_option) ? formatQuestion(item.q_option) : ReactHtmlParser(
          `${index + 1}) ${item.q_option}`.replace(/<\/?[^>]+>/gi, "")
        )}>
          <figure className="upload-img-size">
            <img src={JSON.parse(item.q_option).entityMap["0"].data.src} alt={CheckJSONString(item.q_option) ? formatQuestion(item.q_option) : ReactHtmlParser(
              `${index + 1}) ${item.q_option}`.replace(/<\/?[^>]+>/gi, "")
            )} />
          </figure>
        </div> : ""} */}
    </div>
  );
}

function OptionListCheckbox({
  item,
  index,
  from,
  selectedOptionId
}) {
  return (
    <div className="custom-control custom-checkbox">
      {from == "viewAnswer" ? 
       <input
       className="custom-control-input"
       name="example"
       type="checkbox"
       id={`customCheck_${item.q_option + index}`}
       checked={selectedOptionId?.UserChooseOptionId?.find((id) => id == item?.oid ? true : false)}
       disabled
     /> 
      : <input
        className="custom-control-input"
        name="example"
        type="checkbox"
        id={`customCheck_${item.q_option + index}`}
      />}

      <label className="custom-control-label" for={`customCheck_${item.q_option + index}`}>
        {(from == "addQuestion" || from == "duplicateQuestion" || from == "viewQuestion"
        // || from == "assignedQTable"
        ) ? item.q_option : from == "viewAnswer" ? HtmlParser(item.q_option) : HtmlParser(item.q_option)}
        {/* {CheckJSONString(item.q_option) ? formatQuestion(item.q_option) : ReactHtmlParser(
          `${index + 1}) ${item.q_option}`.replace(/<\/?[^>]+>/gi, "")
        )} */}
      </label>
      {/* {JSON.parse(item).blocks.length > 1 ? <img src={JSON.parse(item).entityMap["0"].data.src} alt="preec" /> : ""} */}
    </div>
  );
}


function OptionListMatch({from,question}){
  // let userResultStr = question?.answerData?.UserAnswer && question?.answerData?.UserAnswer?.split(",")[index].split("___")
  const [optionData, setOptionData] = useState([...question.options]);  
  const handleDragEnd = (result) =>{
    if(!result.destination){
      return
    }
    let dragData = [...optionData];
    dragData = reorder(dragData, result.source.index, result.destination.index);
    setOptionData([...dragData])
  }
  
  return (
    <div className="row">
      <div className="col-md-6"> 
        <ul className="list-unstyled view-match-column">
          {question.options.map((item,ind)=>( item.q_option ?
            <li className="" key={ind}>
            {item.q_option ? ( CheckJSONString(item.q_option) ? formatQuestion(item.q_option) : ReactHtmlParser(
              `<span>${item.q_option}</span>`
            )):null}
            </li>
          :null))}     
        </ul>
      </div>
      <div className="col-md-6">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ul className="list-unstyled view-match-column-drag">              
                {optionData.map((item,ind)=>(
                  <Draggable key={ind} draggableId={"drag"+ind} index={ind}>
                    {(provided, snapshot)=>(
                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {item.q_option_match ? ( CheckJSONString(item.q_option_match) ? formatQuestion(item.q_option_match) : ReactHtmlParser(
                        `<span>${item.q_option_match}</span>`
                      )):null}
                      </li>
                    )}
                  </Draggable>
                ))}     
              </ul>
            </div>
          )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}


function OptionFillintheBlank(item, options){
  let userAnswers;
  if(options && options.UserAnswer && options.UserAnswer!=""){
    userAnswers = options.UserAnswer.split(",").map(item=>(item.split("___")[1]));
  }
  console.log(item, "\r\n")
  const paragraph = item.replace( /(<([^>]+)>)/ig, '').replace(/\n|\r/g, "").trim()
  console.log([paragraph])
  const words = paragraph.split(' ');
  console.log(words)
  let inputCount = -1;
  return (
    <div className="form-group">
      <div className="fillstyle">
      {words.map((word, index) => {
        if(word == "___" ){
          inputCount++;
        }
      return(
        <React.Fragment key={index}>
          {index !== 0 && ' '} {/* Add a space before each word except the first one */}
          {word !== "___" ? (
            <span style={{margin:"3px"}}>{word}</span>
          ) : (
            <input value={userAnswers ? userAnswers[inputCount].replace('["',"").replace('"]',"") : ""} className="form-control col-sm-2" type="text"/>
          )}
        </React.Fragment>
      )})}
    </div>
    </div>
  )
}

function OptionShortAnswer({from, item}) {
  return (
    <div className="form-group">
     { from == "viewAnswer" ? 
     <textarea
        type="text"
        className="form-control"
        disabled
      >{formatQuestion(item?.answerData?.UserAnswer)}</textarea>  :<textarea
        type="text"
        className="form-control"
        placeholder="Short Answer"
      ></textarea>
      }
    </div>
  );
}

function OptionLongAnswer({from, item}) {
  return (
    <div className="form-group">
     { from == "viewAnswer" ? 
     <textarea
        type="text"
        className="form-control"
        disabled
      >{formatQuestion(item?.answerData?.UserAnswer)}</textarea>  :<textarea
        type="text"
        className="form-control"
        placeholder="Long Answer"
      ></textarea>
      }
    </div>
  );
}

function OptionChoiceMatrix({from, item}){
  let matrixArray = item.options.map((item, i) => {
    let tempObj = {
      titleValue: item.titleValue, 
      optionid: item.oid,
      rowTitle: item.titleValue, 
      options: item?.q_option ? 
                JSON.parse(item?.q_option).length ? 
                  JSON.parse(item?.q_option).map((ele, j) => {
                    return {
                      ...ele, 
                      text: ele?.titleValue
                    }
                  }) : 
                [] : 
              []
    }    
    return tempObj;
  })
  return (
    <div className="view-matrix-table">
      <label className="ml-1">Option *</label>
      <table className="export-table-border-header">
          <thead>
              <tr>
                  <th></th>
                  {matrixArray?.length && matrixArray[0].options.map((option, index) => (
                      <th key={index}>{option.text}</th>
                  ))}
              </tr>
          </thead>
          <tbody>
              {matrixArray?.length && matrixArray.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                      <td>{rowData?.rowTitle}</td>
                      {rowData?.options.map((option, optionIndex) => (
                          <td key={optionIndex}>
                              <div className="form-icon-group">
                                  <div
                                  >
                                      <input
                                          type="radio"
                                          readOnly
                                          defaultChecked={from==="viewAnswer" ? option.isSelected : false}
                                      />
                                  </div>
                              </div>
                          </td>
                      ))}
                  </tr>
              ))}
          </tbody>
      </table>
    </div>
  )
}

const DocumentUploadPreview = () => {
  const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' }
  }

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta)
  }

  const handleSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  return (
    <div className="profile-doc-upload">
      <Dropzone
        disabled
        onSubmit={handleSubmit}
        classNames={{ submitButton: 'btn btn-primary', submitButtonContainer: ' btn-upload' }}
        styles={{ dropzone: { /*minHeight: 225*/ } }}
        accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf"
        inputContent={
          <lable>
            <i className="fas fa-file-upload"></i>
            <h4>
              To submit question, Drop your file here, or <span>browse</span>
            </h4>
            <p>Supports: PDF, DOCX and more</p>
          </lable>
        }
        maxSizeBytes="1000MB"
        submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
      />
    </div>
  )
}

const imgAndText = (data, ques, options) => {
  // let tempState = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
  // const forFormik = draftToHtml(
  //   convertToRaw(tempState.getCurrentContent())
  // );
  // return HtmlParser(forFormik)
  // console.log("data from imngText", data);
  let generalNote = CheckJSONString(data);
  if (generalNote) {
    const blocksFromHTML2 = convertFromRaw(JSON.parse(data));
    let dataDescr = EditorState.createWithContent(blocksFromHTML2);
    const note = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
    return note ? note : "<p><p/>"

  }
  else {
   if(ques == "Fill in the blank"){
     return OptionFillintheBlank(data,options)
   } else{
     return HtmlParser(data)
   }
  }


  // return HtmlParser(data)
}

const OrderListPreview = ({from, item}) =>{
  const [optionData, setOptionData] = useState([...item.options]);  
  const handleDragEnd = (result) =>{
    if(!result.destination){
      return
    }
    let dragData = [...optionData];
    dragData = reorder(dragData, result.source.index, result.destination.index);
    setOptionData([...dragData])
  }

  return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot)=>(
            <div class="order-list-wrapper" ref={provided.innerRef} {...provided.droppableProps}>
              {optionData.map((opt, idx)=>(
                <Draggable key={idx} draggableId={"drag"+idx} index={idx}>
                  {(provided, snapshot)=>(
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="order-list-item">
                      {HtmlParser(opt.q_option)}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
  )
}

function DisplayQuestion(props) {
  const { question, from } = props;
  return (
    <div>
      {Object.keys(question).length > 0 && (
        <div className="card-quiz card-header-dark">
          <div className="">
            <div className="form-group">
              <div className="row ml-0 mr-0">
                <label className="d-block">
                  <strong>
                    {/* {CheckJSONString(question.question) ? formatQuestion(question.question) : ReactHtmlParser(question.question)} */}
                    <MathJax> 
                    {question.question !== null && question.question_type != "Fill in the blank" ? imgAndText(question.question):  imgAndText(question.question, "Fill in the blank", question.answerData)}
                    {/* {question.question !== null &&  imgAndText(question.question)} */}
                    </MathJax>
                  </strong>
                  {/* <p className="">
                    {question.description !== null && imgAndText(question.description)}
                    custom-des-space
                    mt-1 
                    {CheckJSONString(question.description) ? formatQuestion(question.description) : ReactHtmlParser(
                      question.description.replace(/<\/?[^>]+>/gi, "")
                    )}
                  </p> */}
                </label>  
              </div>
              <div className="row ml-0 mr-0">
                <label className="d-block">
                  {/* <strong>
                    {CheckJSONString(question.question) ? formatQuestion(question.question) : ReactHtmlParser(question.question)}
                    {question.question !== null && imgAndText(question.question)}
                  </strong> */}
                  <MathJax>
                  <p className="">
                    {/* custom-des-space */}
                    {/* mt-1  */}
                    {question.description !== null && imgAndText(question.description)}
                    {/* {CheckJSONString(question.description) ? formatQuestion(question.description) : ReactHtmlParser(
                      question.description.replace(/<\/?[^>]+>/gi, "")
                    )} */}
                  </p>
                  </MathJax>
                </label>
              </div>
              <div className={(question.question_type == "Multiple Choice Single Answer" || question.question_type == "Multiple Choice Multiple Answer") ? "row ml-0 mr-0" : ""}>
              <div className="radio-group-custom">
                {/* Multiple Choice Single Answer */}
                {question.question_type == "Multiple Choice Single Answer" &&
                  question.options.map((item, index) => (
                    <MathJax>
                    <OptionList
                      item={item}
                      index={index}
                      key={index}
                      from={from}
                      selectedOptionId={question.answerData}
                    />
                    </MathJax>
                  ))}
                {/* Multiple Choice Multiple Answer */}
                {question.question_type == "Multiple Choice Multiple Answer" &&
                  question.options.map((item, index) => (
                    <MathJax>
                    <OptionListCheckbox
                      item={item}
                      index={index}
                      from={from}
                      selectedOptionId={question.answerData}
                    />
                    </MathJax>
                  ))}
                {/* Match the Column */}
                {(question.question_type == "Match the Column" || question.question_type == "Match The Column") && (                  
                    <MathJax>
                      <OptionListMatch
                        question={question}
                        from={from}
                      />
                    </MathJax>
                )}
                {/* Short Answer */}
                {question.question_type == "Short Answer" && (
                  <MathJax>
                    <OptionShortAnswer from={from} item={question}/>
                  </MathJax>
                  )}
                {/* Long Answer */}
                {question.question_type == "Long Answer" && (
                   <MathJax>
                  <OptionLongAnswer from={from} item={question}/>
                  </MathJax>
                )}
                {/* Choice Matrix */}
                {question.question_type == "Choice Matrix" && (
                  <MathJax>
                    <OptionChoiceMatrix from={from} item={question} />
                  </MathJax>
                )}
                {/* Document Upload */}
                {question.question_type == "Document Upload" &&(
                  <DocumentUploadPreview />
                )}
                {/* Order List */}
                {question.question_type == "Order List" &&(
                  <OrderListPreview from={from} item={question} />
                )}
              </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
}

export default DisplayQuestion;
