import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { modalOpen } from "../../../store/actions";
import { GetPostFeedMessages, GetSubjectContentService, GetPostFeedMessagesPaginate } from "../../../services/PostsAndFeedService";
import { getToken } from "../../../utils/Auth";
// import SubjectHeadBox from "../course/SubjectHeadBox";
import { toast } from "react-toastify";
import FeedMessageForm from "./FeedMessageForm";
import FeedMessages from "./FeedMessages";
import SkeletonMessage from "../../../loaders/SkeletonMessage";
// import SubjectAssignments from "../course/SubjectAssignments";
// import SubjectAssignmentsLatest from "../course/SubjectAssignmentsLatest";
// import SubjectAssemblies from "../course/SubjectAssemblies";
// import TeamBox from "../course/TeamBox";
// import Assignment from "./Assignment";
// import Appeal from "./Appeal";
import SocketConnection from "../../../utils/SocketConnection";
// import SkeletonSubjectHeadBox from "../../loaders/SekletonSubjectHeadBox";
import SkeletonReRegistrationList from "../../../loaders/SkeletonReRegistrationList";
// import OnlineClasses from "../course/OnlineClasses";
import $ from "jquery";
// import ViewQuiz from "./quiz/ViewQuiz";
// import QuizDescription from "./quiz/QuizDescription";
// import ViewQuestion from "./quiz/ViewQuestion";
// import SubmitConfirmation from "./quiz/SubmitConfirmation";
// import ReviewQuiz from "./quiz/ReviewQuiz";
import _, { debounce } from "underscore";
import "../../../assets/css/chat.css";
// import MentoredSubject from "./MentoredSubject";
import ReactHtmlParser from "react-html-parser";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
import { Scrollbar } from "react-scrollbars-custom";
import { getRandomInt } from "../../../utils/commonFunction";
import { GetClassFiles, readAllCommentMessages } from "../../../services/CourseService";
import Swal from "sweetalert2";
import { download } from "../../../utils/FileDownload";


let subscription, connection, typeEvent;

const saveData= (blob,fileName,this_var) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  this_var.button('reset');
  Swal.close();
  Swal.fire({
   icon: "success",
   title: "Downloading Finished",
   showConfirmButton: true
 }).then((result) => {
 });
}

const downloadVideo = (url,name,this_var) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.onload = function () {
  saveData(this.response, name,this_var); 
  };
  xhr.send();
}


function PostsAndFeed(props) {
  const [subjectContent, setSubjectContent] = useState([]);
  const { id } = useParams();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [feeds, setFeeds] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAssignment, setShowAssignment] = useState(false);
  const [showAppeal, setShowAppeal] = useState(false);
  const [welcome, setWelcome] = useState(null);
  const [typing, setTyping] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [QuizId, setQuizId] = useState("");
  const [rId, setrId] = useState("");
  const [tab, setTab] = useState("overview");
  const [feedActive, setFeedActive] = useState("")
  const [overviewActive, setOverviewActive] = useState("")
  const [supportTeamActive, setSupportTeamActive] = useState("")
  const [assemblyActive, setAssemblyActive] = useState("")
  const [assessmentActive, setAssessmentActive] = useState("")
  const [viewAssessmentActive, setViewAssessmentActive] = useState("")
  const [viewAssessmentLatestActive, setViewAssessmentLatestActive] = useState("")
  const [onlineClassActive, setOnlineClassActive] = useState("")
  const [videoId, setVideoId] = useState("")
  const [modalOpen, setModalOpen] = useState("")
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [chatUnreadCount, setChatUnreadCount] = useState(0)
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  // const [toggleScroll, setToggleScroll] = useState(false);
  const [openReplyId, setOpenReplyIds] = useState('');
  const [editableArr, setEditableArr] = useState([]);
  const [editablePost, setEditablePost] = useState('');
  const [showNewStatus, setShowNewStatus] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [reRender, setReRender] = useState(1);
  const [count, setCount] = useState(0);
  const [chatLoading, setChatLoading] = useState(false)

  const assignmentLink = useRef(null);
  const assignmentsLink = useRef(null);
  const scrollbarsRef = useRef(null);
  const [lastY, setLastY] = useState(0);
  const timeoutIdsRef = useRef([]);
  // console.log(scrollbarsRef.current.scrollHeight,"scrollbarsRef.current.scrollHeight")


  useEffect(()=>{
    $(document).on("click",".download-video",function() {
      Swal.close();
      Swal.fire({
      icon: "info",
      title: "Downloading Started",
      showConfirmButton: false
    }).then((result) => {
    });
      let id = $(this).attr("class_id");
      let action="download_recording";
      let url=$(this).attr("url");
      let name=$(this).attr("name");
      let this_var=$(this);
      this_var.button('loading').queue();
      downloadVideo(url,name,this_var);
    });

    $(document).on("click",".class-files",function() {
      let big_blue_button_id = $(this).attr("big_blue_button_id");
      let action="download_recording";
      let url=$(this).attr("url");
      let name=$(this).attr("name");
      let this_var=$(this);
      this_var.button('loading').queue();

      GetClassFiles({big_blue_button_id})
      .then((res) => {
        if(res.data.data.Contents.length>0){
          Swal.close();
          res.data.data.Contents.forEach((element,index) => {
            let absolutePath='https://playback.myaie.ac/'+element.Key;
            let assArray = element.Key.split("/");
            let assArrayLen = assArray.length;
            let fileName = assArray[assArrayLen - 1];
            let value = download(absolutePath, fileName, false);
            Swal.close();
            Swal.fire({
            icon: "info",
            title: index+1+" of "+res.data.data.Contents.length+" are downloading",
            showConfirmButton: false
          }).then((result) => {
          });
        });
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Downloading Finished",
          showConfirmButton: true
        }).then((result) => {
        });
        }else{
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "No files available for download",
            showConfirmButton: true
          }).then((result) => {});
        }
      })
      .catch((error) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error while downloading files",
          text: error,
        }).then((result) => {});
      });
    });

  },[])

  useEffect(() => {
    SocketConnection.connect();    
    return () => {
      timeoutIdsRef.current.forEach(id => clearTimeout(id));
    };
  }, [])

  useEffect(() => {
    setSubjectContent(props.subjectContent)
    setChatUnreadCount(props.chatUnreadCount)
  }, [props])

  useEffect(() => {
    setPage(0)
    setFeeds([])
    getMessages();
    setChatUnreadCount(subjectContent.messageCount)
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditableArr([])
  }, [id, reRender])

  useEffect(() => {
    getMessages();
  }, [id])


  const getMessages = () => {
    // GetPostFeedMessages({ room_id: id })
    //   .then(data => {
    //     setFeeds(data.data);
    //   })
    //   .catch(err => console.log(err))

    setFeeds([])
    setSkeletonLoader(true)
    setLastPage(0);
    setPage(0);
    GetPostFeedMessagesPaginate({ room_id: id }, 1, user.id)
      .then(data => {
        setLastPage(data.data.lastPage);
        setPage(data.data.page);
        let feedUpdate = data.data.data;
        feedUpdate.map(item => {
          if (item.user == null) {
            item.user = item.adminuser
            item.user_id = item.admin_id
            item.userType = 'admin'
          }
        })
        if(!feedUpdate.length){
          setChatLoading(true)
        }
        if(feedUpdate.length){
          setFeeds(feedUpdate);
        }
        setSkeletonLoader(false);
        scrollBottom();
        if(scrollbarsRef.current){
          setLastY(scrollbarsRef.current.scrollHeight);
        }
        if(props.unreadCommentCount){
          readAllCommentMessages({user_id:props.user_id, sid:props.userId}).then((res)=>{
            props.setUnreadCommentCount(0);
            setChatUnreadCount(0)
          }).catch(err=>{console.log(err)})                  
        }
      })
      .catch(err => {
        console.log(err)
        setSkeletonLoader(false)
      })
  }
  const scrollBottom = (behavior = "auto") => {
    const element = document.getElementById("content");
    if(element){
      element.scrollIntoView({ behavior: behavior, block: "end", inline: "nearest" });
    }
  }

  // const userSubscription = async () => {
  //   if (subjectContent?.subject) {
  //     if (subscription) subscription.close();

  //     subscription = await SocketConnection.subscribe(
  //       `adminchat:${subjectContent?.subject.id}`,
  //       "test"
  //     );

  //     await subscription.on("message", (message) => {
  //       console.log('the value of sent msg is ', message)
  //       setFeeds((prev) => [message, ...prev]);
  //       return false;
  //     });

  //     await subscription.on("new:user", (connected_user) => {
  //       if (_.findWhere(users, connected_user.id) == null) {
  //         setUsers((users) => [...users, connected_user.id]);
  //       }
  //     });

  //     if (typeEvent) typeEvent.close();
  //     typeEvent = await SocketConnection.subscribe(
  //       `typing:${subjectContent?.subject.id}`,
  //       "test"
  //     );

  //     await typeEvent.on("typing", (message) => {
  //       setTyping(true);
  //       setTimeout(() => {
  //         setTyping(false);
  //       }, 1000);
  //     });
  //     setWelcome(subjectContent?.subject.welcome);
  //   }
  // };

  const updateUserInfo = (arr) => {
    if (arr.user == null) {
      arr.user = arr.adminuser
      arr.user_id = arr.admin_id
      arr.userType = 'admin'
    }
    return arr
  }

  const userSubscription = async () => {
    if (subjectContent.subject) {
      if (subscription) subscription.close();

      subscription = await SocketConnection.subscribe(
        `adminchat:${subjectContent.subject.id}`,
        "test"
      );

      await subscription.on("message", (message) => {

        if (message && message.message_type && message.message_type == 'edited') {
          let updatedMsg = updateUserInfo(message)
          updateEdited(updatedMsg)
        } else {
          let updatedMsg = updateUserInfo(message)
          setFeeds((prev) => [...prev, updatedMsg]);
          setChatLoading(false);
          const t = setTimeout(() => { scrollBottom("smooth"); }, 1000);
          timeoutIdsRef.current.push(t);
        }
        return false;
      });

      await subscription.on("new:user", (connected_user) => {
        if (_.findWhere(users, connected_user.id) == null) {
          setUsers((users) => [...users, connected_user.id]);
        }
      });

      await subscription.on("messageDelete", (message) => {
        setReRender(getRandomInt(5000))
      });

      await subscription.on("likeDislike", (message) => {
        setFeeds((pre) => {
          const targetMessage = pre.findIndex(item => item.id === message.data.message_id);

          pre[targetMessage].likes = message.likes
          pre[targetMessage].likes.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].dislikes = message.dislikes
          pre[targetMessage].dislikes.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].love = message.love
          pre[targetMessage].love.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].laugh = message.laugh
          pre[targetMessage].laugh.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].surprised = message.surprised
          pre[targetMessage].surprised.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].sad = message.sad
          pre[targetMessage].sad.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          pre[targetMessage].angry = message.angry
          pre[targetMessage].angry.map(i => {
            if (i.user == null) {
              i.user = i.adminuser
              i.user_id = i.admin_id
              i.userType = 'admin'
            }
            return updateUserInfo(i)
          })
          return [...pre]
        });
      });

      if (typeEvent) typeEvent.close();
      typeEvent = await SocketConnection.subscribe(
        `typing:${subjectContent.subject.id}`,
        "test"
      );

      await typeEvent.on("typing", (message) => {
        setTyping(true);
        const t = setTimeout(() => {
          setTyping(false);
        }, 1000);
        timeoutIdsRef.current.push(t);
      });
      setWelcome(subjectContent.subject.welcome);
    }
  };

  useEffect(() => {
    userSubscription();
  }, [subjectContent?.subject?.id]);

  useEffect(
    () => () => {
      if (subscription) {
        subscription.close();
      }
    },
    []
  );

  const updateEdited = (data) => {
    setFeeds((pre) => {
      const targetMessage = pre.findIndex(item => item.id === data.id);
      pre[targetMessage].message = data.message
      pre[targetMessage].subject = data.subject
      pre[targetMessage].important = data.important
      pre[targetMessage].attachments = data.attachments
      return [...pre]
    });
  }

  const sendMessage = async (message) => {
    message.user_id = user.UserID;
    message.role = 'admin';
    subscription.emit("message", message);
  };

  const TypingMessage = () => {
    //typeEvent.emit("typing", true);
  };



  const handleScroll = (scrollValues, prevScrollValues = {}) => {
    if ((page > 0) && (page < lastPage)) {
      if (scrollbarsRef.current.scrollTop < 1) {
        setSkeletonLoader(true)
        if (page < lastPage) {
          GetPostFeedMessagesPaginate({ room_id: id }, page + 1, user.id).then(data => {
            setLastPage(data.data.lastPage)
            setPage(data.data.page)
            let feedUpdate = data.data.data
            data.data.data.map(item => {
              if (item.user == null) {
                item.user = item.adminuser
                item.user_id = item.admin_id
                item.userType = 'admin'
              }
            })
            setFeeds((prev) => [...feedUpdate, ...prev]);
            const t = setTimeout(() => {
              let height = scrollbarsRef.current.scrollHeight - lastY
              scrollbarsRef.current.scrollTo(0, height);
              setLastY(scrollbarsRef.current.scrollHeight);
            }, 1000);
            timeoutIdsRef.current.push(t);
            setLoader(false);
          })
            .catch(err => { console.log(err) })
        }
      } else {
        if (skeletonLoader) setSkeletonLoader(false)
      }
      setLoader(false)
    }
  }


  const openReplyHandle = (openId) => {
    setOpenReplyIds(openId)
    setShowNewStatus(false)
    setEditableArr([])
    setEditablePost('')
  }

  const editIdHandle = (editId) => {
    setEditableArr(editId)
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditablePost('')
  }

  const editIdPostHandle = (editId) => {
    setEditablePost(editId)
    setEditableArr([])
    setShowNewStatus(false)
    setOpenReplyIds('')
  }

  const newDiscussHandle = (newStatus) => {
    setShowNewStatus(newStatus)
    setOpenReplyIds('')
    setEditableArr([])
    setEditablePost('')
  }

  const resetEditor = () => {
    setShowNewStatus(false)
    setOpenReplyIds('')
    setEditableArr([])
    setEditablePost('')
  }

  const deleteHandle = (data) => {
    data.user_id = user.UserID;
    data.role = data.admin_id ? 'admin' : "student";
    // console.log("about to deleting message");
    subscription.emit("messageDelete", data);
  };

  const likeDislikeHandle = (data) => {
    data.user_id = user.UserID;
    data.role = 'admin';
    subscription.emit("likeDislike", data);
  };

  const updatePostImportant = (postData, value) => {
    setFeeds((pre) => {
      const targetMessage = pre.findIndex(item => item.id === postData.id);
      pre[targetMessage].important = value ? 1 : 0
      return [...pre]
    });
  }

  const updateReadMessages = (msg_id, cmt_id) => {
    let tempFeed = feeds
    tempFeed.map(i => {
      if (i.id == msg_id) {
        if (i.readMessages && i.readMessages.id) {
          i.readMessages.message_id = msg_id
          i.readMessages.comment_id = cmt_id
        }
        else {
          i.readMessages = { message_id: msg_id, comment_id: cmt_id }
        }
      }
    })
    setFeeds(tempFeed)
  }

  const sendPost = (messageValue, allData_id, attachments, sizes, subject, userId, userType) => {
    let tempImportant = false
    let tempData = feeds
    let deletedFiles = []
    let remainFiles = []
    feeds.map(i => {
      if (i.id == allData_id) {
        i.attachments.map(j => {
          if (j.isDeleted) {
            deletedFiles.push(j.attachment)
          }
          else {
            remainFiles.push(j)
          }
        })
        tempImportant = i.important
        attachments = [...remainFiles.map(x => x.attachment), ...attachments]
        deletedFiles = deletedFiles
        sizes = [...remainFiles.map(x => x.size ? x.size : 'NA'), ...sizes]
      }
    })

    subscription.emit("message", {
      message: messageValue,
      subject: subject,
      feed: allData_id,
      comment_id: allData_id,
      message_id: allData_id,
      type: "message",
      message_type: 'edited',
      files: attachments,
      sizes: sizes,
      taggedUsers: taggedUsers ? taggedUsers : [],
      important: tempImportant,
      deletedFiles: deletedFiles,
      user_id: userId && userId!=="" ? userId :user.UserID,
      role: userType && userType!=="" ? userType : 'admin'
    });
    resetEditor()
  };

  const deletedFileHandle = (id, path) => {
    let tempData = feeds
    tempData.map(i => {
      if (i.id == id) {
        i.attachments.map(j => {
          if (path != j.attachment) {
            if (!j.isDeleted) {
              j.isDeleted = false
            }
          }
          else {
            j.isDeleted = true
          }
        })
      }
    })
  }

  const reduceCount = (newCount, room_id) => {
    setCount(count + newCount)
    // setChatUnreadCount(chatUnreadCount - (newCount ? count : 0))
    props.setUnreadCommentCount(chatUnreadCount - (count + newCount))
  }

  return (
    <>
      {/* <FeedMessageForm
        pSendMessage={sendMessage}
        TypingMessage={TypingMessage}
        isTyping={typing}
        taggingUsers={subjectContent?.listUsers}
      />
      {loading && <SkeletonMessage />}
      {!loading && feeds && feeds.length ? (
        <FeedMessages
          feeds={feeds}
          websocket={SocketConnection}
          online={users}
          taggingUsers={subjectContent?.listUsers}
        />
      ) : null} */}

      {/* {console.log("checkdata",chatLoading,feeds,feeds.length)} */}
      
      <div className="ps-fd-card post-root ps-card-edit">
        <FeedMessageForm
          pSendMessage={sendMessage}
          TypingMessage={TypingMessage}
          isTyping={typing}
          taggingUsers={subjectContent?.listUsers}
          setShowNewStatus={newDiscussHandle}
          showNewStatus={showNewStatus}
        />
        {chatLoading && 
          <div className="blank-chat-con">
            <div className="blank-chat-icon">
              <i class="fad fa-comments-alt"></i>
              <i class="fad fa-comments chat-icon-1"></i>
              <i class="fad fa-comment-dots chat-icon-2"></i>
            </div>
            <div className="blank-chat-text">
              <p>Nothing to chat about</p>
              <p>Why not start a conversation <i class="fad fa-smile-wink"></i></p>
            </div>
          </div>
        }
       
        {!chatLoading && <div className="scroll-sec-feed-msg" ref={scrollbarsRef} onScroll={debounce(() =>{handleScroll()},300)}>
          <FeedMessages
            feeds={feeds}
            websocket={SocketConnection}
            online={users}
            loadMore={skeletonLoader}
            taggingUsers={subjectContent?.listUsers}
            updateMsg={getMessages}
            setOpenReplyId={openReplyHandle}
            openReplyId={openReplyId}
            setShowHideEdit={editIdHandle}
            showHideEditArr={editableArr}
            setShowHidePostEdit={editIdPostHandle}
            showHidePostEdit={editablePost}
            resetEditor={resetEditor}
            deleteHandle={deleteHandle}
            likeDislikeHandle={likeDislikeHandle}
            updatePostImportant={updatePostImportant}
            sendClick={sendPost}
            deletedFile={deletedFileHandle}
            reduceCount={reduceCount}
            updateReadMessages={updateReadMessages}
            roomId={id}
          />
          <div id='content' />
        </div>}
      
      </div>

    </>
  );
}

export default React.memo(PostsAndFeed);
