import axiosInstance from "../utils/axios";

// Dashboard virtual online classes Api  start
export const GetListOfDashboard = async (values) => {
  return await axiosInstance().get(`/getDashboardVirtualClasses`, {
    params: values,
  });
};
export const GetListOfDashboardCancleToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getDashboardVirtualClasses`, {
    params: values,
    cancelToken: cancelToken
  });
};

export const GetList = async () => {
  return await axiosInstance().get("/intakes-list-edit");
};

export const GetfilterlistDashboard = async () => {
  return await axiosInstance().get("/getcommoncontent");
};
// Dashboard End

// Assessment api start
export const GetAssesmentsList = async (values) => {
  return await axiosInstance().post("/getAssesmentsList", values);
};

export const GetAssesmentsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getAssesmentsList", values, {
  cancelToken
});
};
// Assessment api end

// Mentored api start
export const GetMentoredStudentsList = async (values) => {
  return await axiosInstance().post("/mentoredStudents", values);
};

export const GetMentoredStudentsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/mentoredStudents`, values, {
    cancelToken: cancelToken
  });
};
// mentored end

// Course table api start//
export const GetCourselist = async (values) => {
  return await axiosInstance().post(`/couresList`, values);
};

export const GetCourselistCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/couresList`, values, {
    cancelToken: cancelToken
    
  });
};

export const ProgrammesListDropdown = async () => {
  return await axiosInstance().get(`/getDropdownProgrammes`);
};
// course api end

// Appeals api start
export const GetAppealslist = async (values) => {
  return await axiosInstance().post(`/getAppealList`, values);
};

export const GetAppealslistCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getAppealList`, values, {
    cancelToken
  });
};
// Appeals api end

// Ticket summary api
export const GetTicketSummary = async (values) => {
  return await axiosInstance().post(`/getTicketCount`, values);
};

export const GetlistTicketForDashboard = async (values) => {
  return await axiosInstance().post(`/listTicketForDashboard`, values);
};

export const GetlistTicketForDashboardCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/listTicketForDashboard`, values, {
    cancelToken: cancelToken
  });
};

export const GetVritualClassDropdown = async (data) => {
  return await axiosInstance().post(`/getVritualClassDropdown`,data);
};

export const GetVritualClassDropdownCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post(`/getVritualClassDropdown`,data, {
    cancelToken
  });
};

export const CouresListDropdown = async (data) => {
  return await axiosInstance().post(`/couresListDropdown`,data);
};

export const GetCouresListDropdownCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/couresListDropdown`, values, {
    cancelToken: cancelToken
    
  });
};

export const GetAppealDropdown = async (data) => {
  return await axiosInstance().post(`/getAppealDropdown`,data);
};

export const GetAssesmentsDropdown = async (data) => {
  return await axiosInstance().post(`/getAssesmentsDropdown`,data);
};

export const GetMentoredStudentsDropdown = async (data) => {
  return await axiosInstance().post(`/getMentoredStudentsDropdown`,data);
};

export const GetMentoredStudentsDropdownCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getMentoredStudentsDropdown`, values, {
    cancelToken: cancelToken 
  });
};

export const ListTicketDropdown = async (data) => {
  return await axiosInstance().post(`/listTicketDropdown`,data);
};
// Ticket api End

export const DeleteOnlineClass = async (data) => {
  return await axiosInstance().post("/b_remove_online_class/", data);
};

export const GetLuanchButtonData = async (data) => {
  return await axiosInstance().post("getLaunchButtonData", data);
};

export const LaunchVirtualClass = async (data, isStart = true) => {
  return await axiosInstance().post(
    isStart ? "moderatorRedirect" : "endmeeting",
    data
  );
};

export const JoinClassAsAdmin = async (params) => {
  return await axiosInstance().post("/joinAsGuest", params);
};

export const classLaunch = async (data) => {
  return await axiosInstance().post("/classLaunch", data);
};

export const GetAssessmentDropdown = async (data) => {
  return await axiosInstance().get("/getAssesmentsDropdown");
};
export const GetfilterlistAssessment = async () => {
  return await axiosInstance().get("/getcommoncontent");
};

export const GetLinkList = async () => {
  return await axiosInstance().get("/getLinkList");
};

export const DeleteLink = async (id) => {
  return await axiosInstance().get(`/deleteLink?id=${id}`);
};

export const CreateOrUpdateQuickLink = async (data) => {
  return await axiosInstance().post(`/createOrUpdateQuickLink`, data);
};

export const SendQuickNotification = async (data) => {
  return await axiosInstance().post("/sendQuickNotification/", data);
};

export const InsertSignOff = async (data) => {
  return await axiosInstance().post("/insertSignOff/", data);
};

export const UpdateClassRecordStatus = async (id, status) => {
  // https://aiesuperadmin.devtechnosys.tech:17278/api/v1/toggleRecording?meetingId=615ab6d347480-1633334995&action=true
  return await axiosInstance().get(
    "/toggleRecording?meetingId=" + id + "&action=" + status
  );
};

export const GetDropdownCourseList = async () => {
  return await axiosInstance().get("/getDropdownCourseList");
};
export const GetDropdownLecturer = async () => {
  return await axiosInstance().get("/getDropdownCmsUser");
};
export const GetDropdownProgrammes = async () => {
  return await axiosInstance().get("/getDropdownProgrammes");
};
export const GetDropdownCategory = async () => {
  return await axiosInstance().get("/getDropdownCategory");
};
export const GetDropdownDepartments = async () => {
  return await axiosInstance().get("/getDropdownDepartments");
};
export const getAve = async () => {
  return await axiosInstance().get("/durationCalculation");
};
export const getAttendanceVirtualClass = async (id) => {
  return await axiosInstance().get(`/getAttendanceVirtualClass?class_id=${id}`);
};

export const GetUnreadCouresNotificationCount = async () => {
  return await axiosInstance().get("/getUnreadCouresNotificationCount");
};

export const GetDashboardAllCounts = async ()=>{
  return await axiosInstance().get("/getDashboardAllCounts");
}

export const GetMentoredStudentsCount = async ()=>{
  return await axiosInstance().get("/getMentoredStudentsCount");
}

export const GetLearningDashboardURL = async (data)=>{
  return await axiosInstance().post("/getLearningDashboardURL", data);
}

export const GetListTicketFilters = async (data) => {
  return await axiosInstance().get(`/listTicketFilters?viaTicket[]=${data}`);
};

export const GetListTicketFiltersCancelToken = async (data, cancelToken) => {
  return await axiosInstance().get(`/listTicketFilters?viaTicket[]=${data}`, {
    cancelToken: cancelToken
  });
};

export const GetClassAttendanceCount = async () => {
  return await axiosInstance().post("/getClassAttendanceCount");
};

export const deleteCourse = async (data) => {
  return await axiosInstance().post("/delete-intake", data);
};

export const getClassFiles = async (params) => {
  return await axiosInstance().get("/getClassFiles", {params});
};