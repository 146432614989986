const initialState = {
    groups:[]
};

const chatGropsReducer = (state = initialState ,action) => {

    switch(action.type) {
        case 'SET_MY_GROUPS' :
            return {...state,
                groups:[...state.groups,...action.payload]
            }
            case 'UNSET_MY_GROUPS' :
                return initialState;
            default :
            return state;
    }

}

export default chatGropsReducer;