import axiosInstance from "../utils/axios";

export const articulationDetails = async (values) => {
    return await axiosInstance().get(`/getArticulationRegistrationDetails?id=${values}`);
};

export const articulationsRegistrationsList = async (values) => {
    return await axiosInstance().post(`/getArticulationRegistrationsList`, values);
}

export const GetArticulationsRegistrationsListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getArticulationRegistrationsList`, values, {
      cancelToken: cancelToken
    });
};

export const AddOrUpdateArticulationReregistration = async (values) => {
    return await axiosInstance().post("/addEditArticulationRegistration", values);
};

export const articulationRegistrationDelete = async (value) => {
    return await axiosInstance().post(`/deleteArticulationRegistration?id=${value}`);
};

export const getArticulationRegistrationsListFilter = async () => {
    return await axiosInstance().get(`/getArticulationRegistrationsListFilter`);
};

export const GetArticulationRegistrationsListFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getArticulationRegistrationsListFilter`, {
      cancelToken: cancelToken
    });
  };

export const getArticulationQualificationList = async (values) => {
    return await axiosInstance().get(`/getArticulationQualificationList`, {params: values});
};

export const assignedArticulationToQualification = async (values) => {
    return await axiosInstance().post("/assignedArticulationToQualification", values);
};

export const unassignedArticulationToQualification = async (values) => {
    return await axiosInstance().post("/unassignedArticulationToQualification", values);
};

export const getArticulationRegistrationsAuditTrails = async (data) => {
    return await axiosInstance().post(`/getArticulationRegistrationsAuditTrails`,data);
}

export const GetArticulationRegistrationsAuditTrailsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getArticulationRegistrationsAuditTrails`, values, {
    cancelToken: cancelToken
  });
};

export const getStudentListBasedOnArticulation = async (value) => {
    return await axiosInstance().post(
      `/getStudentListBasedOnArticulation`, value
    );
};

export const GetStudentListBasedOnArticulationCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getStudentListBasedOnArticulation`, values, {
      cancelToken: cancelToken
    });
  };

export const getStudentListBasedOnArticulationFilters = async (id) => {
    return await axiosInstance().get(`/getStudentListBasedOnArticulationFilters?articulation_id=${id}`);
};

export const GetStudentListBasedOnArticulationFiltersCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get(`/getStudentListBasedOnArticulationFilters?articulation_id=${id}`, {
      cancelToken: cancelToken
    });
  };

export const articulationContactedSubmitStatus = async (data) => {
    return await axiosInstance().post(`/articulationSubmitStatus`,data);
}