import React, { useEffect, useState } from "react";
import {
  AddCourse,
  GetCourseDetails,
  GetCrmProductList,
  GetList,
  GetNetsuiteProductList,
  GetCommonContent
} from "../../../services/CourseService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import Swal from "sweetalert2";
import "jspdf-autotable";
import moment from "moment";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import draftToHtml from "draftjs-to-html";
import { CheckJSONString } from "../../common/CheckJSONString";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { AllBrandList, GetAllDropdownValues, GetQualificationOptions, GetSchoolList } from "../../../services/RegistrationService";
import { FORM_DATE_FORMAT, IMAGE_URL } from "../../../utils/Constants";
import { TrimText } from "../../common/TrimText";
import  {useHistory} from 'react-router-dom'
import { useSelector } from "react-redux";
import ReactDOM from 'react-dom/server';
import HtmlParser from "react-html-parser";

const CourseDetails = (props) => {
  const history = useHistory();
  const [courseDetails, setCourseDetails] = useState({});
  const [assignedRec, setAssignedRec] = useState([]);
  const [intakeArr, setIntakeArr] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [subjectType, setSubjectType] = useState([]);
  const [courseDataDetails, setCourseDataDetails] = useState({});
  const [crmList, setCrmList] = useState([]);
  const [netsuiteList, setNetsuiteList] = useState([]);
  const [iconLoading, setIconLoading] = useState(true);
  const [timeTableDoc, setTimeTableDoc] = useState("");
  const [timeTableDocName, setTimeTableDocName] = useState("");
  const [dataUpdate, setDataUpdate] = useState(true);
  const [campusArr, setCampusArr] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  // const dropdownStates = useSelector(state => state.registerDropdownValues);  
  const [editDataCKeditor, setEditCKeditor] = useState({
    overview: "",
    certificate: "",
  });

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = React.useState("");
  const [editorState2, setEditorState2] = React.useState("");
  const [isEditorStateReady, setIsEditorStateReady] = useState(false);
  const [studyTypeDropdown, setStudyTypeDropdown] = useState([])
  const [courseTypeFilter, setCourseTypeFilter] = useState([])
  const [studyTypeDropdownAcademic, setStudyTypeDropdownAcademic] = useState([])

  useEffect(() => {   
    if (schoolId!="") {
      GetQualificationOptions(schoolId).then(res => {
        setNetsuiteList(res && res.data && res.data.data && res.data.data.items ? res.data.data.items : []);
      })
    }else{
      GetNetsuiteProductList().then((data) => {
        setNetsuiteList(
          data && data.data && data.data.data && data.data.data.items
            ? data.data.data.items
            : []
        );
      });
    }
  }, [schoolId])

  useEffect(() => {
    GetAllDropdownValues().then((data)=>{
      setStudyTypeDropdown(data.data.study_type);
      setStudyTypeDropdownAcademic(data.data.study_type_2)
    }).catch((err)=>{
      console.log(err);
    })
    GetSchoolList().then((res)=>{
      if(res.status==200){
        setSchoolList(res.data.data)
      }
    })
  }, [])

  useEffect(() => {
    setDataUpdate(true);
    getData();
    GetList().then((response) => {
      setCourseTypeFilter(response.data.courseType);
      let tempStudyTypeArr = [];
      response.data.study_typeArr &&
        Object.keys(response.data.study_typeArr).map((key) => {
          tempStudyTypeArr.push({
            label: response.data.study_typeArr[key],
            value: key,
          });
        });
      let tempIntakeArr = [];
      response.data.intake_yearArr.map((year) => {
        Object.keys(response.data.intakeArr).map((number) => {
            tempIntakeArr.push({
              value: `${year.year}/${number}`,
              label: `${year.year}-${number}`,
            });
        });
      });
      setIntakeArr(tempIntakeArr);
    });
    GetCrmProductList()
      .then((data) => {
        setCrmList(
          data &&
            data.data &&
            data.data.crmProducts &&
            data.data.crmProducts.results
            ? data.data.crmProducts.results
            : []
        );
      })
      .catch((error) => {
        console.log("error ---> ", error);
      }); 
    GetCommonContent()
    .then((res) => {
      setCampusArr(res.data.program_campus);
    }).catch((err) => console.log("error: ", err)); 
    AllBrandList()
    .then(response => {
      let brands = [];
      let res = response.data?.allBrandList
      res.map((data) => {
        brands.push({ value: data.id, label: data.brand_name })
      })
      setAllBrands(brands)
    }).catch(error => console.log("error >>>>> ", error))  
  }, []);

  const getData = () => {
    GetCourseDetails(props.userId)
      .then((data) => {
        setCourseDetails(data && data.data ? data.data : {});
        props.setEnableChat(data?.data?.enable_chat)
        if(dataUpdate){
          setTimeTableDoc(data?.data?.intakeTimeTable);
          setTimeTableDocName(data?.data?.intakeTimeTable);
        }
        // formik.setFieldValue("TimeTable", data?.data?.intakeTimeTable);
        setIconLoading(false);
        //temp arrays to be used in future
        let tempCorrespondence = [];
        let tempSubjectType = [];
        let tempStartYear = [];

        //array used in page
        const correspondenceArr = data.data?.study_typeArr;
        const subjectTypeArr = data.data?.subject_type_arr;
        const startYearArr = data.data?.intake_yearArr;

        //setting correspondence Array
        Object.keys(correspondenceArr)
          .sort()
          .map((key, index) => {
            tempCorrespondence.push({
              value: key,
              label: correspondenceArr[key],
              key: key,
            });
          });
        setCorrespondence(tempCorrespondence);

        //subjectType
        // Object.keys(subjectTypeArr).map((key, index) => {
        //   tempSubjectType.push({
        //     value: key,
        //     label: subjectTypeArr[key],
        //     key: key,
        //   });
        // });
        setSubjectType(tempSubjectType);

        //setting start year arr
        startYearArr.map((item) => {
          tempStartYear.push({ value: item.year, label: item.year, key: item });
        });
        setAssignedRec(
          data && data.data && data.data.active ? data.data.active : []
        );

        setCourseDataDetails(data && data.data ? data.data : {});
        let generalNote = CheckJSONString(data.data.general_note_info);
        if (generalNote) {
          const blocksFromHTML2 = convertFromRaw(
            JSON.parse(data.data.general_note_info)
          );
          let dataDescr = EditorState.createWithContent(blocksFromHTML2);
          const note = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
          setEditorState(note ? note : "<p><p/>");
          setEditCKeditor((prevState) => ({
            ...prevState,
            overview: note ? note : "<p><p/>",
          }));
          setIsEditorStateReady(true);
        } else {
          setEditorState(data.data.general_note_info);
          setEditCKeditor((prevState) => ({
            ...prevState,
            overview: data.data.general_note_info,
          }));
          setIsEditorStateReady(true);
        }
        let certificate = CheckJSONString(data.data.certificate);
        if (certificate) {
          const blocksFromHTML2 = convertFromRaw(
            JSON.parse(data.data.certificate)
          );
          let dataDescr = EditorState.createWithContent(blocksFromHTML2);
          const cert = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
          setEditorState2(cert ? cert : "<p><p/>");
          setEditCKeditor((prevState) => ({
            ...prevState,
            certificate: cert ? cert : "<p><p/>",
          }));
        } else {
          setEditorState2(data.data.certificate);
          setEditCKeditor((prevState) => ({
            ...prevState,
            certificate: data.data.certificate,
          }));
        }
        setDataUpdate(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setIconLoading(false);
        setDataUpdate(false);
      });
  };

  const getKeyOfObject = (obj, num, type = "") => {
    let value = "";
    if (type == "crm") {
      obj.map((elem) => {
        if (elem[0] == num) value = elem[1] + " (" + elem[4] + ") " + elem[2];
      });
    } else {
      Object.keys(obj).map((key, index) => {
        if (num.toLowerCase() == obj[key].toLowerCase()) value = key;
      });
    }
    return value;
  };

  function addDays(date, days) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: courseDetails.subject_name ? courseDetails.subject_name : "",
      // courseType: "",
      courseType: courseDetails.subject_type ? courseDetails.subject_type : "",
      courseTypeName: courseDetails.subject_type
        ? courseDetails.subject_type.toLowerCase()
        : "",
      studyType: courseDetails.study_type ? courseDetails.study_type : "",
      studyTypeName: courseDetails.study_type
        ? getKeyOfObject(courseDetails.study_typeArr, courseDetails.study_type)
        : "",
      dhetCheckbox: courseDetails.DHET_exam_subject == 1 ? true : false,
      correspondence: courseDetails.correspondence
        ? courseDetails.correspondence
        : "",
      correspondenceName: courseDetails.correspondence
        ? courseDetails.correspondence
        : "",
      subjectCode: courseDetails.subject_code ? courseDetails.subject_code : "",
      intake:
        courseDataDetails.selected_intake_year &&
        courseDataDetails.selected_intake
          ? `${
              courseDataDetails.selected_intake_year
            }/${courseDataDetails.selected_intake.replace("Intake ", "")}`
          : "",
      lecturer: courseDetails.lecturer_id
        ? getDefaultName(
            courseDetails.lecturer_coach,
            courseDetails.lecturer_id
          )
        : 0,
      lecturerId: courseDetails.lecturer_id ? courseDetails.lecturer_id : 0,
      programCoordinator: courseDetails.coach_id
        ? getDefaultName(courseDetails.lecturer_coach, courseDetails.coach_id)
        : 0,
      programCoordinatorId: courseDetails.coach_id ? courseDetails.coach_id : 0,
      programSupport: courseDetails.support_id
        ? getDefaultName(courseDetails.lecturer_coach, courseDetails.support_id)
        : 0,
      programSupportId: courseDetails.support_id ? courseDetails.support_id : 0,
      enableChat: courseDetails.enable_chat == 1 ? true : false,
      autoEmail:
        courseDetails.email == 0 ||
        courseDetails.email == "" ||
        !courseDetails.email
          ? false
          : true,
      requiredSignOf:
        courseDataDetails.require_sign_off &&
        courseDetails.require_sign_off == 1
          ? true
          : false,
      crmProduct: courseDataDetails.crm_product_id
        ? getKeyOfObject(crmList, courseDataDetails.crm_product_id, "crm")
        : "",
      crmProductId: courseDataDetails.crm_product_id
        ? courseDataDetails.crm_product_id
        : "",
      certificateTemplate: "",
      startDate: courseDataDetails.intakeStart
        ? new Date(moment.unix(courseDataDetails.intakeStart))
        : "",
      endDate: courseDataDetails.intakeEnd
        ? new Date(moment.unix(courseDataDetails.intakeEnd))
        : "",
      netsuiteProductId: courseDataDetails.netsuiteProductId
        ? courseDataDetails.netsuiteProductId
        : "",
      netsuiteProductName: courseDataDetails.netsuiteProductId
        ? getDefaultName(
            netsuiteList,
            courseDataDetails.netsuiteProductId,
            "netsuite"
          )
        : "",
      publish_date: courseDataDetails.publish
        ? courseDataDetails.publish.toString().length == 10
          ? new Date(moment.unix(courseDataDetails.publish))
          : courseDataDetails.publish.toString().length > 12
          ? courseDataDetails.publish.toString().length > 15
            ? new Date(moment.unix(courseDataDetails.publish / 1000000))
            : new Date(moment.unix(courseDataDetails.publish / 1000))
          : new Date(moment.unix(courseDataDetails.publish))
        : "",
      overview: "",
      TimeTable: (courseDetails && courseDetails?.intakeTimeTable) ? courseDetails?.intakeTimeTable : "",
      duration: courseDataDetails.subject_duration
        ? courseDataDetails.subject_duration
        : "",
      Campus: courseDataDetails && courseDataDetails.campus ? courseDataDetails.campus : "",
      mentoredCheckbox: courseDetails.mentored_check_box == 1 ? true : false,
      startRecommendedDate: courseDataDetails.start_recommended_date
        ? new Date(moment.unix(courseDataDetails.start_recommended_date))
        : "",
      endRecommendedDate: courseDataDetails.end_recommended_date
        ? new Date(moment.unix(courseDataDetails.end_recommended_date))
        : "",
      school : courseDataDetails.school ? courseDataDetails.school: "",
      brand : courseDataDetails.brand ? courseDataDetails.brand: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").nullable().trim(),
      correspondence: Yup.string().required("Should select Learning Method"),
      courseType: Yup.string().required("Should select Course Type"),           
      intake: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "mentored" || courseType=== "scheduled"),
        then : Yup.string().required("Intake is required")
      }),
      startDate: Yup.string().when(['correspondence'], {
        is : (correspondence) =>(correspondence!== "selfplaced"),
        then : Yup.string().required("Start Date is required.")
      }),
      endDate: Yup.string().when(['correspondence', 'courseType'], {
        is : (correspondence, courseType) =>(correspondence!== "selfplaced" && courseType!=='shortcourse'),
        then : Yup.string().required("End Date is required.")
      }),
      duration: Yup.string().when(['correspondence'], {
        is : (correspondence) =>(correspondence=== "selfplaced"),
        then : Yup.string().required("Duration is required.")
      }),
      Campus: Yup.string().when(['correspondence', 'courseType'], {
        is : (correspondence, courseType) =>((correspondence=== "full" || correspondence=== "fulltimeonline") && courseType !== 'shortcourse'),
        then : Yup.string().required("Campus is required.")
      }),  
      school: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "shortcourse"),
        then : Yup.string().required("School is required.")
      }),  
      brand: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "shortcourse"),
        then : Yup.string().required("Brand is required.")
      }), 
    }),
    onSubmit: (values) => {
      let startDate = values.startDate;
      let endDate = values.endDate;      
      let  endRecommendedDate = values.endRecommendedDate
      let  startRecommendedDate = values.startRecommendedDate

      startDate = startDate ? new Date(startDate).getTime().toString() / 1000 : "";
      endDate = endDate ? new Date(endDate).getTime().toString() / 1000 : "";
      endRecommendedDate = endRecommendedDate ? new Date(endRecommendedDate).getTime().toString() / 1000 : "";
      startRecommendedDate = startRecommendedDate ? new Date(startRecommendedDate).getTime().toString() / 1000 : "";

      let publish = new Date(values.publish_date).getTime().toString() / 1000;

      if (startDate > endDate) {
        formik.setErrors({
          startDate: "Start date should be less than end date",
        });
        return;
      }

      setLoading(true);
      let rec = assignedRec.map((e) => e.qualification);

      // let obj = {
      //   start_date: startDate,
      //   end_date: endDate ? endDate : "",
      //   lecturer_id: values.lecturerId ? values.lecturerId : "",
      //   coach_id: values.programCoordinatorId ? values.programCoordinatorId : "",
      //   support_id: values.programSupportId ? values.programSupportId : "",
      //   subject_name: values.name,
      //   subject_type: values.courseType,
      //   subject_code: values.subjectCode,
      //   year: values.intake.split("/")[0],
      //   number: values.intake.split("/")[1],
      //   study_type: values.studyTypeName,
      //   correspondence: values.correspondenceName,
      //   DHET_exam_subject: values.dhetCheckbox == true ? 1 : 0,
      //   notify_email: values.autoEmail == true ? 1 : 0,
      //   enable_chat: values.enableChat == true ? 1 : 0,
      //   general_note_info: editorState,
      //   crm_product_id: values.crmProductId,
      //   certificate: editorState2,
      //   intake: props.userId,
      //   require_sign_off: values.requiredSignOf == true ? 1 : 0,
      //   netsuiteProductId: values.netsuiteProductId
      //     ? values.netsuiteProductId
      //     : "",
      //   assigned_program: JSON.stringify(rec),
      //   publish: publish,
      // };
      let formData = new FormData();
      formData.append("start_date", startDate);
      formData.append("end_date", endDate ? endDate : "");
      formData.append(
        "lecturer_id",
        values.lecturerId ? values.lecturerId : ""
      );
      formData.append(
        "coach_id",
        values.programCoordinatorId ? values.programCoordinatorId : ""
      );
      formData.append(
        "support_id",
        values.programSupportId ? values.programSupportId : ""
      );
      formData.append("subject_name", values.name);
      formData.append("subject_type", (values.courseType==="mentored" && values.mentoredCheckbox == false) ? "scheduled" : values.courseType);
      formData.append("year", values?.intake ? values?.intake.split("/")[0] : "");
      formData.append("number", values?.intake ? values.intake.split("/")[1] : "");
      formData.append("study_type", values.studyTypeName);
      formData.append("correspondence", values.correspondence);
      // formData.append("support_email", values?.EmailDomain);
      formData.append("DHET_exam_subject", values.dhetCheckbox == true ? 1 : 0);
      formData.append("notify_email", values.autoEmail == true ? 1 : 0);
      formData.append("enable_chat", values.enableChat == true ? 1 : 0);
      formData.append("general_note_info", editorState);
      formData.append("crm_product_id", values.crmProductId);
      formData.append("certificate", editorState2);
      formData.append(
        "netsuiteProductId",
        values.netsuiteProductId ? values.netsuiteProductId : ""
      );
      formData.append("publish", publish ? publish : startDate);
      formData.append("timeTable_attachment", values.TimeTable);
      formData.append("subject_code", values.subjectCode);
      formData.append("intake", props.userId)
      formData.append("mentored_check_box", values.mentoredCheckbox===true ? 1 : 0)
      formData.append("end_recommended_date", endRecommendedDate );
      formData.append("start_recommended_date", startRecommendedDate );
      formData.append("subject_duration", values.duration);
      formData.append("campus", values?.Campus);
      formData.append("school", values.school);
      formData.append("brand", values.brand);

      AddCourse(formData)
        .then((data) => {
          setLoading(false);
          Swal.fire("Updated!", "Your record has been updated.", "success");
          scrollToTop();
          setDataUpdate(true);
          getData();
        })
        .catch((error) => {
          setLoading(false);
          console.log("error ---->", error);
        });
    },
  });

  const modifiyOptionsForSelect = (arr, type = "") => {
    let modifiedArr = [];
    if (
      type == "courseType" ||
      type == "studyType" ||
      type == "correspondence"
    ) {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: arr[key], label: arr[key], key: key });
      });
    } else if (
      type == "lecturer" ||
      type == "programCoordinator" ||
      type == "programSupport"
    ) {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.name, label: ele.name, id: ele.UserID });
      });
    } else if (type == "intakeYear") {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.year, label: ele.year });
      });
      modifiedArr.sort(function (a, b) {
        return b.value - a.value;
      });
    } else if (type == "intakeNumber") {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: key, label: key });
        // modifiedArr.push({ value: arr[key], label: arr[key], number: key });
      });
    } else if (type == "crm") {
      arr.map((elem) => {
        modifiedArr.push({
          value: elem[1] + " (" + elem[4] + ") " + elem[2],
          label: elem[1] + " (" + elem[4] + ") " + elem[2],
          id: elem[0],
        });
      });
    } else if (type === "netsuite") {
      arr.map((elem) => {
        modifiedArr.push({ value: elem.id, label: elem.displayname, departmentId: elem.department });
      });
    }

    return modifiedArr;
  };

  function getDefaultName(arr, value, type = "") {
    let name = "";
    if (type == "netsuite") {
      arr.map((ele) => {
        if (ele.id.toString() === value.toString()) name = ele.displayname;
      });
    } else {
      arr.map((ele) => {
        if (ele.UserID == value) name = ele.name;
      });
    }
    return name;
  }

  const handleReset = (resetForm) => {
    // resetForm();
    // scrollToTop();
    history.goBack();
  };

  function scrollToTop() {
    var element = document.getElementById("parentElem");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
  const handleTimeTable = () => {
    formik.setFieldValue("TimeTable", "");
    setTimeTableDoc("");
    setTimeTableDocName("");
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-9 col-12">
            <div
              className="card card-profile-info-card course-details-tab-sec"
              id="parentElem"
            >
              <div className="course-info-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header">
                    Course Information
                    {iconLoading && <i className="fas fa-cog fa-spin ml-1"></i>}
                  </div>
                </div>
                <div className="card-body-inr card-body-info">
                  <div className="row">
                    <div className="col-md-6 col-lg-3">
                      <div className="form-icon-group mb-4">
                        <label>Name *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (formik.errors.name && formik.touched.name
                              ? " is-invalid"
                              : "")
                          }
                          name="name"
                          title="Name"
                          placeholder="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.name && formik.touched.name ? (
                          <span className="exclamation">
                            {" "}
                            <i className="fal fa-exclamation-circle"></i>{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ">
                      <div className="form-icon-group mb-4">
                        <label>Course Type</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.courseType && formik.touched.courseType
                              ? " is-invalid"
                              : "")
                          }
                          name="courseType"
                          // value={formik.values.courseType || ""}
                          value={courseTypeFilter ? courseTypeFilter.filter( (val) =>{
                            return val?.value == formik?.values?.courseType;
                          }) : "" }
                          onChange={(value) => {
                            if(value){
                              formik.setFieldValue("courseType", value.value);
                              formik.setFieldValue("courseTypeName", value.key);
                              if(value.value=="mentored" && formik.values.correspondence=="selfplaced"){
                                formik.setFieldValue("correspondence","");
                                formik.setFieldValue("correspondenceName","");
                              }
                            } else {
                              formik.setFieldValue("courseType", "");
                              formik.setFieldValue("courseTypeName", "");
                            }
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={courseTypeFilter}
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.courseType === "scheduled" || formik.values.courseType === "information" || formik.values.courseType === "orientation"
                              ? "Academic"
                              :formik.values.courseType === ""
                              ? "Course Type"
                              : formik.values.courseType
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="form-icon-group mb-4">
                        <label>Learning Method *</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.correspondence &&
                              formik.touched.correspondence
                              ? " is-invalid"
                              : "")
                          }
                          name="correspondence"
                          // value={formik.values.correspondence || ""}
                          value={correspondence.filter(function (option) {
                            return option.value == formik.values.correspondence;
                          })}
                          onChange={(value) => {
                            if(value){
                              formik.setFieldValue("correspondence", value.value);
                              formik.setFieldValue("correspondenceName", value.key);
                              if(value.value=="selfplaced"){
                                formik.setFieldValue("enableChat", false)
                              }
                            } else {
                              formik.setFieldValue("correspondence", "");
                              formik.setFieldValue("correspondenceName", "");
                            } 
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={formik.values.courseType === "shortcourse"?  studyTypeDropdown: studyTypeDropdownAcademic}
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.correspondence
                              ? formik.values.correspondence
                              : "Learning Method"
                          }
                        />
                        {formik.errors.correspondence &&
                          formik.touched.correspondence ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {formik.values.courseType === "mentored" || formik.values.courseType === "scheduled" ? (<div className="col-md-6 col-lg-3">
                      <div className="form-icon-group mb-4">
                        <label>
                          Intake{" "}
                          {formik.values.courseType !== "shortcourse" ? (
                            <>*</>
                          ) : null}
                        </label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.intake &&
                              formik.touched.intake
                              ? " is-invalid"
                              : "")
                          }
                          name="intake"
                          value={intakeArr.filter((val)=>{
                            return val.value == formik.values.intake
                          })}
                          onChange={(value) =>{
                            if(value){
                              formik.setFieldValue("intake",value.value)
                            } else {
                              formik.setFieldValue("intake","")
                            }
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={intakeArr}
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.intake
                              ? formik.values.intake
                              : "Year"
                          }
                        />
                      </div>
                    </div>) : null}
                    
                    <div className="col-md-6 col-lg-3">
                      <div className="form-icon-group mb-4">
                        <label>Subject Code</label>
                        <input
                          type="text"
                          className={"form-control"}
                          placeholder="Subject Code"
                          title="Subject Code"
                          name="subjectCode"
                          value={formik.values.subjectCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.subjectCode &&
                          formik.touched.subjectCode ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {formik.values.correspondence !== "selfplaced" ? (
                      <>
                        <div className="col-md-6 col-lg-2">
                          <div className="form-icon-group mb-4">
                            <label>Start Date *</label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.startDate}
                              onChange={(date) => {
                                formik.setFieldValue("startDate", date);
                              }}
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.startDate && formik.touched.startDate
                                  ? " is-invalid"
                                  : "")
                              }
                              title="Start Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select Start Date"
                              timeIntervals={15}
                              disabledKeyboardNavigation
                              onChangeRaw={(e) => e.preventDefault()}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                          <div className="form-icon-group mb-4">
                            <label>End Date {formik.values.courseType == "shortcourse" ? "" : "*"}</label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.endDate}
                              onChange={(date) => {
                                formik.setFieldValue("endDate", date);
                              }}
                              // showTimeSelect
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.endDate && formik.touched.endDate
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={!formik.values.startDate}
                              title="End Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select End Date"
                              timeIntervals={15}
                              onChangeRaw={(e) => e.preventDefault()}
                              minDate={formik.values.courseType === "shortcourse" ? formik.values.startDate : addDays(formik.values.startDate, 1)}
                            />
                          </div>
                        </div>
                      </>
                    ):null}
                    <div className="col-md-6 col-lg-2">
                      <div className="form-icon-group mb-4">
                        {/* Intake number was here */}
                        <label>Published Date</label>
                        <DatePicker
                          selected={
                            formik.values.publish_date
                              ? formik.values.publish_date
                              : formik.values.startDate
                          }
                          // disabled
                          // showTimeSelect
                          filterTime={filterPassedTime}
                          dateFormat={FORM_DATE_FORMAT}
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormatCalendar="MMMM"
                          // className="form-control"
                          className={
                            "form-control cursor-pointer" +
                            (formik.errors.publish_date &&
                              formik.touched.publish_date
                              ? " is-invalid"
                              : "")
                          }
                          // disabled={!isEditable && type === "open"}
                          title="Publish Date"
                          placeholderText="Select Publish Date"
                          timeIntervals={15}
                          onChange={(date) => {
                            formik.setFieldValue("publish_date", date);
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                    {formik.values.correspondence === "selfplaced" ? (
                      <div className="col-md-6 col-lg-3">
                        <div className="form-icon-group mb-4">
                          <label>Duration (Months) *</label>
                          <input
                            type="number"
                            className={
                              "form-control" +
                              (formik.errors.duration && formik.touched.duration
                                ? " is-invalid"
                                : "")
                            }
                            name="duration"
                            title="duration"
                            placeholder="duration"
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.duration && formik.touched.duration ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ): null}
                    {formik.values.courseType === "shortcourse" ? (
                      <>
                        <div className="col-md-6 col-lg-3">
                          <div className="form-icon-group mb-4">
                            <label>School *</label>
                            <Select
                              className={"form-control custom-select-box " + (formik.errors.school && formik.touched.school ? " is-invalid" : "")}
                              name="school"
                              value={schoolList.filter((item) => {
                                return item.value == formik.values.school;
                              })}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("school", value.value);
                                  setSchoolId(value.netsuite_department_id);
                                } else {
                                  formik.setFieldValue("school", "");
                                  setSchoolId("");
                                }
                                formik.setFieldValue("netsuiteProductId", "");
                                formik.setFieldValue("netsuiteProductName", "");
                              }}
                              // isClearable
                              onBlur={formik.handleBlur}
                              options={schoolList}
                              maxMenuHeight={175}
                              // placeholder={formik.values.school ? formik.values.school : "Select"}
                              placeholder={"Select"}
                            />
                        </div> 
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <label htmlFor="brand">Brand *</label>
                          <div className="form-icon-group mb-4" title="Brand">
                            <Select
                              className={"form-control custom-select-box " + (formik.errors.brand && formik.touched.brand ? " is-invalid" : "")}
                              name="brand"
                              value={allBrands.filter((item) => {
                                return item.value == formik.values.brand;
                              })}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("brand", value.value);
                                } else {
                                  formik.setFieldValue("brand", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={allBrands.sort((a, b) => {
                                let name1 = a.label;
                                let name2 = b.label
                                return name1.localeCompare(name2)
                              })}
                              maxMenuHeight={175}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-5">
                          <div className="form-icon-group mb-4">
                            <label>Netsuite Product</label>
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.netsuiteProductId &&
                                  formik.touched.netsuiteProductId
                                  ? " is-invalid"
                                  : "")
                              }
                              name="netsuiteProductId"
                              value={formik.values.netsuiteProductId || ""}
                              onChange={(value) => {
                                if (value) {  
                                  formik.setFieldValue("netsuiteProductId", value.value);
                                  formik.setFieldValue("netsuiteProductName", value.label);
                                } else {
                                  formik.setFieldValue("netsuiteProductId", "");
                                  formik.setFieldValue("netsuiteProductName", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={
                                netsuiteList
                                  ? modifiyOptionsForSelect(
                                    netsuiteList,
                                    "netsuite"
                                  )
                                  : []
                              }
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.netsuiteProductName
                                  ? formik.values.netsuiteProductName
                                  : "Netsuite Product"
                              }
                            />
                          </div>
                        </div>                        
                      </>
                    ) : null}
                    {formik.values.courseType === "shortcourse" ?
                    (<div className="col-md-6 col-lg-3">                  
                      {(formik.values.courseType === "shortcourse" ||
                        formik.values.courseType == "Short Course") && (
                          <div className="form-icon-group mb-4">
                            <label>CRM Product</label>
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.crmProduct &&
                                  formik.touched.crmProduct
                                  ? " is-invalid"
                                  : "")
                              }
                              name="crmProduct"
                              value={formik.values.crmProduct || ""}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("crmProduct", value.value);
                                  formik.setFieldValue("crmProductId", value.id);
                                } else {
                                  formik.setFieldValue("crmProduct", "");
                                  formik.setFieldValue("crmProductId", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={
                                crmList
                                  ? modifiyOptionsForSelect(crmList, "crm")
                                  : []
                              }
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.crmProduct
                                  ? formik.values.crmProduct
                                  : "CRM Product"
                              }
                            />
                          </div>
                        )}
                    </div>):null}
                    {formik.values.courseType != "shortcourse" && (formik.values.correspondence === "full" || formik.values.correspondence === "fulltimeonline")  ? (
                      <div className="col-md-6 col-lg-2">
                        <label htmlFor="Campus">Campus *</label>
                        <div className="">
                          <div className="form-icon-group mb-4 ">
                            <Select
                              className={
                                "form-control custom-select-box " +
                                (formik.errors.Campus && formik.touched.Campus
                                  ? " is-invalid"
                                  : "")
                              }
                              name="Campus"
                              value={campusArr.filter(function (option) {
                                return option.value == formik.values.Campus;
                              })}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("Campus", value.value);
                                } else {
                                  formik.setFieldValue("Campus", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={campusArr}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.Campus ? formik.values.Campus : "Select"
                              }
                            />
                            {formik.errors.Campus && formik.touched.Campus ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ): null}
                    {(formik.values.courseType === "shortcourse") && 
                      <div className="col-md-6 col-lg-4 mb-4">
                        <label htmlFor="IndexLogo" style={{ marginBottom: 13 }}>
                          Time Table
                        </label>
                        <div className="form-group atttach-file">
                          <label
                            className={
                              formik.errors.TimeTable &&
                              formik.touched.TimeTable &&
                              " file-req is-invalid"
                            }
                          >
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                              type="file"
                              id="input"
                              className="form-control  form-control-aatch-file"
                              name="file"
                              title="Time Table"
                              onChange={(e) => {
                                if (e.target.files.length) {
                                  formik.setFieldValue("TimeTable", e.target.files[0]);
                                  setTimeTableDoc(e.target.files[0]);
                                  setTimeTableDocName(URL.createObjectURL(e.target.files[0]));
                                }
                              }}
                            />
                          </label>
                        </div>
                        {timeTableDoc && timeTableDoc !== "undefined" && (
                          <>
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li>
                                
                                    <a
                                      title={timeTableDocName}
                                      href={
                                        timeTableDocName.includes("blob")
                                          ? timeTableDocName
                                          : `${IMAGE_URL}/${timeTableDocName}`.replaceAll(
                                              "public/",
                                              ""
                                            ).replaceAll("home/myaie/public_html/", "")
                                      }
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;{
                                              timeTableDoc?.name
                                              ? TrimText(timeTableDoc?.name,40)
                                              : TrimText(formik.values.TimeTable.split(
                                                "/"
                                              ).reverse()[0],40)
                                                
                                            }
                                    </a>
                                    <button
                                    className="btn btn-danger rounded-circle btn-dropdown-item"
                                    title="Delete"
                                    onClick={() => handleTimeTable()}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                  
                                  
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>}
                    {(formik.values.courseType === "shortcourse" ||
                    formik.values.courseType == "Short Course") && (
                      <div className={"col-md-12"}>
                        <div className="form-icon-group mb-4">
                          <label>Certificate Template</label>
                          <HtmlInputEditor
                            editorState={editorState2}
                            setEditorState={setEditorState2}
                            isCKEditor={true}
                            hideSign={true}
                          // isInValid={editorState2}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              </div>
              <div className="course-sec-gap course-resource-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header">Course Resources</div>
                </div>
                <div className="card-body-inr">
                  <div className="row">
                    {formik.values.courseType !== "shortcourse" || formik.values.correspondence !== "selfplaced"?(
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4">
                        <label>Lecturer</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.lecturer && formik.touched.lecturer
                              ? " is-invalid"
                              : "")
                          }
                          name="lecturer"
                          value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                            courseDetails.lecturer_coach,
                            "lecturer"
                          ).filter((val)=>{
                            return val.value == formik.values.lecturer
                          }) : ""}
                          onChange={(value) => {
                            if(value){
                              formik.setFieldValue("lecturer",value.value);
                              formik.setFieldValue("lecturerId",value.id);
                            } else {
                              formik.setFieldValue("lecturer","");
                              formik.setFieldValue("lecturerId","");
                            }
                            
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={
                            courseDetails.lecturer_coach
                              ? modifiyOptionsForSelect(
                                courseDetails.lecturer_coach,
                                "lecturer"
                              )
                              : []
                          }
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.lecturer
                              ? formik.values.lecturer
                              : "Lecturer"
                          }
                        />
                        {formik.errors.lecturer && formik.touched.lecturer ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>):null}
                    {formik.values.mentoredCheckbox?(
                      <div className="col-md-6 col-lg-4  d-none d-md-block d-lg-none">
                        <div className="form-icon-group mb-4">
                          <label>Mentor</label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.lecturer && formik.touched.lecturer
                                ? " is-invalid"
                                : "")
                            }
                            name="Mentor"
                            value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                              courseDetails.lecturer_coach,
                              "Mentor"
                            ).filter((val)=>{
                              return val.value == formik.values.mentor
                            }) : ""}
                            onChange={(value) => {
                              if(value){
                                formik.setFieldValue("lecturer",value.value);
                                formik.setFieldValue("lecturerId",value.id);
                              } else {
                                formik.setFieldValue("lecturer","");
                                formik.setFieldValue("lecturerId","");
                              }                          
                            }}
                            isClearable
                            onBlur = {formik.handleBlur}
                            options = {
                              courseDetails.lecturer_coach
                                ? modifiyOptionsForSelect(
                                  courseDetails.lecturer_coach,
                                  "lecturer"
                                )
                                : []
                            }
                            maxMenuHeight={175}
                            placeholder={
                              formik.values.lecturer
                                ? formik.values.lecturer
                                : "Lecturer"
                            }
                          />
                          {formik.errors.lecturer && formik.touched.lecturer ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ) :null}
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4">
                        <label>Programme Coordinator</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.programCoordinator &&
                              formik.touched.programCoordinator
                              ? " is-invalid"
                              : "")
                          }
                          name="programCoordinator"
                          value={
                            courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                  courseDetails.lecturer_coach,
                                  "programCoordinator"
                                ).filter((val)=>{
                                  return val.value == formik.values.programCoordinator
                                }) : ""
                          }
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("programCoordinator", value.value);
                              formik.setFieldValue("programCoordinatorId", value.id);
                            } else {
                              formik.setFieldValue("programCoordinator", "");
                              formik.setFieldValue("programCoordinatorId", "");
                            }                        
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={
                            courseDetails.lecturer_coach
                              ? modifiyOptionsForSelect(
                                courseDetails.lecturer_coach,
                                "programCoordinator"
                              )
                              : []
                          }
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.programCoordinator
                              ? formik.values.programCoordinator
                              : "Programme Coordinator"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4">
                        <label>Programme Support</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.programSupport &&
                              formik.touched.programSupport
                              ? " is-invalid"
                              : "")
                          }
                          name="programSupport"
                          value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                            courseDetails.lecturer_coach,
                            "programCoordinator"
                          ).filter((val)=>{
                            return val.value == formik.values.programSupport
                          }) : ""}
                          onChange={(value) => {
                            if(value){
                              formik.setFieldValue("programSupport",value.value);
                              formik.setFieldValue("programSupportId", value.id);
                            } else {
                              formik.setFieldValue("programSupport","");
                              formik.setFieldValue("programSupportId", "");
                            } 
                          }}
                          isClearable
                          onBlur={formik.handleBlur}
                          options={
                            courseDetails.lecturer_coach
                              ? modifiyOptionsForSelect(
                                courseDetails.lecturer_coach,
                                "programSupport"
                              )
                              : []
                          }
                          maxMenuHeight={175}
                          placeholder={
                            formik.values.programSupport
                              ? formik.values.programSupport
                              : "Programme Support"
                          }
                        />
                        {formik.errors.lecturer_coach &&
                          formik.touched.lecturer_coach ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-icon-group mb-4">
                        <label>Overview</label>
                        {isEditorStateReady && <HtmlInputEditor
                          editorState={editorState}
                          setEditorState={setEditorState}
                          isCKEditor={true}
                          hideSign={true}
                        // isInValid={false}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="course-sec-gap course-setting-sec d-none d-md-block d-lg-none">
                <div className="edit-icon new-card-header">
                  <div className="card-header">Course Settings</div>
                </div>
                <div className="card-body-inr">
                  <div className="row">
                    {formik.values.correspondence !== "selfplaced" ? (
                    <div className="col-md-4">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enableCheck"
                            name="enableChat"
                            checked={formik.values.enableChat}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enableCheck"
                          >
                            Enable Chat
                          </label>
                        </div>
                      </div>
                    </div>):null}
                    {(!formik.values.mentoredCheckbox) ? (
                    <div className="col-md-4">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="dhet"
                            name="dhetCheckbox"
                            checked={formik.values.dhetCheckbox}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="custom-control-label" htmlFor="dhet">
                            DHET Exam Subject
                          </label>
                        </div>
                        {formik.errors.Lastname && formik.touched.Lastname ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                        {/* End date was here */}
                      </div>
                    </div>
                    ): null}
                    <div className="col-md-4">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="mcheck"
                            name="mentoredCheckbox"
                            checked={formik.values.mentoredCheckbox}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="custom-control-label" htmlFor="mcheck">
                            Mentored
                          </label>
                        </div>
                        {formik.errors.Lastname && formik.touched.Lastname ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                        {/* End date was here */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="autoEmail"
                            name="autoEmail"
                            checked={formik.values.autoEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            title="If ticked, it will send an email automatically to a student when the course is assigned to them"
                            className="custom-control-label"
                            htmlFor="autoEmail"
                          >
                            Send automatic email
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.values.mentoredCheckbox && (
                      <div className="col-md-6 col-lg-4">
                        <div className="form-icon-group mb-4">
                          <div className="custom-control custom-checkbox text-left">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="requiredSignOf"
                              name="requiredSignOf"
                              checked={formik.values.requiredSignOf}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="requiredSignOf"
                            >
                              Requires Sign-off
                            </label>
                          </div>
                        </div>
                      </div>
                    )}                    
                    {formik.values.mentoredCheckbox ? (
                      <>                    
                        <div className="col-md-6 col-lg-4">
                          <div className="form-icon-group mb-4">
                            <label>Recommended Start Date * </label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.startRecommendedDate}
                              onChange={(date) => {
                                formik.setFieldValue("startRecommendedDate", date);
                              }}
                              // showTimeSelect
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.startRecommendedDate && formik.touched.startRecommendedDate
                                  ? " is-invalid"
                                  : "")
                              }
                              // disabled={!formik.values.startDate}
                              title="Start Recommended Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select End Date"
                              timeIntervals={15}
                              onChangeRaw={(e) => e.preventDefault()}                              
                              maxDate={addDays(formik.values.endRecommendedDate, 1)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-icon-group mb-4">
                            <label>Recommended End Date * </label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.endRecommendedDate}
                              onChange={(date) => {
                                formik.setFieldValue("endRecommendedDate", date);
                              }}
                              // showTimeSelect
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.endRecommendedDate && formik.touched.endRecommendedDate
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={!formik.values.startRecommendedDate}
                              title="End Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select End Date"
                              timeIntervals={15}
                              onChangeRaw={(e) => e.preventDefault()}
                              minDate={addDays(formik.values.startRecommendedDate, 1)}
                            />
                          </div>
                        </div>
                      </>                
                    ) : null}                    
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={
                    (loading 
                      || (
                        !formik.dirty 
                        && ReactDOM.renderToString(HtmlParser(editDataCKeditor.overview)) === ReactDOM.renderToString(HtmlParser(editorState)) 
                        && ReactDOM.renderToString(HtmlParser(editDataCKeditor.certificate)) === ReactDOM.renderToString(HtmlParser(editorState2))
                        )
                    ) ? true : false
                  }
                >
                  {loading === false ? (
                    <i className="fal fa-save"></i>
                  ) : (
                    <i className="fas fa-cog fa-spin"></i>
                  )}
                  Save
                </button>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    handleReset(formik.resetForm);
                  }}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>

                {Object.keys(formik.values).map(key => {
                  if (formik.touched[key] && formik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block">
                        {formik.errors[key]}
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="card card-profile-info-card course-details-tab-sec" id="parentElem">
              <div className="course-sec-gap course-setting-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header">Course Settings</div>
                </div>
                <div className="card-body-inr">
                  <div className="row">
                    {formik.values.correspondence !== "selfplaced" ? (
                    <div className="col-md-12">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enableCheck"
                            name="enableChat"
                            checked={formik.values.enableChat}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enableCheck"
                          >
                            Enable Chat
                          </label>
                        </div>
                      </div>
                    </div>):null}                    
                    <div className="col-md-12">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="mcheck"
                            name="mentoredCheckbox"
                            checked={formik.values.mentoredCheckbox}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="custom-control-label" htmlFor="mcheck">
                            Mentored
                          </label>
                        </div>
                        {formik.errors.Lastname && formik.touched.Lastname ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                        {/* End date was here */}
                      </div>
                    </div>
                    {formik.values.mentoredCheckbox?(
                      <div className="col-md-12">
                        <div className="form-icon-group mb-4">
                          <label>Mentor</label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.lecturer && formik.touched.lecturer
                                ? " is-invalid"
                                : "")
                            }
                            name="Mentor"
                            value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                              courseDetails.lecturer_coach,
                              "Mentor"
                            ).filter((val)=>{
                              return val.value == formik.values.mentor
                            }) : ""}
                            onChange={(value) => {
                              if(value){
                                formik.setFieldValue("lecturer",value.value);
                                formik.setFieldValue("lecturerId",value.id);
                              } else {
                                formik.setFieldValue("lecturer","");
                                formik.setFieldValue("lecturerId","");
                              }                          
                            }}
                            isClearable
                            onBlur = {formik.handleBlur}
                            options = {
                              courseDetails.lecturer_coach
                                ? modifiyOptionsForSelect(
                                  courseDetails.lecturer_coach,
                                  "lecturer"
                                )
                                : []
                            }
                            maxMenuHeight={175}
                            placeholder={
                              formik.values.lecturer
                                ? formik.values.lecturer
                                : "Lecturer"
                            }
                          />
                          {formik.errors.lecturer && formik.touched.lecturer ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ) :null}
                    <div className="col-md-12">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="autoEmail"
                            name="autoEmail"
                            checked={formik.values.autoEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            title="If ticked, it will send an email automatically to a student when the course is assigned to them"
                            className="custom-control-label"
                            htmlFor="autoEmail"
                          >
                            Send automatic email
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.values.mentoredCheckbox && (
                      <div className="col-md-12">
                        <div className="form-icon-group mb-4">
                          <div className="custom-control custom-checkbox text-left">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="requiredSignOf"
                              name="requiredSignOf"
                              checked={formik.values.requiredSignOf}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="requiredSignOf"
                            >
                              Requires Sign-off
                            </label>
                          </div>
                        </div>
                      </div>
                    )}  
                    {(!formik.values.mentoredCheckbox) ? (
                    <div className="col-md-12">
                      <div className="form-icon-group mb-4">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="dhet"
                            name="dhetCheckbox"
                            checked={formik.values.dhetCheckbox}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="custom-control-label" htmlFor="dhet">
                            DHET Exam Subject
                          </label>
                        </div>
                        {formik.errors.Lastname && formik.touched.Lastname ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                        {/* End date was here */}
                      </div>
                    </div>
                    ): null}                  
                    {formik.values.mentoredCheckbox ? (
                      <>                    
                        <div className="col-md-12">
                          <div className="form-icon-group mb-4">
                            <label>Recommended Start Date * </label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.startRecommendedDate}
                              onChange={(date) => {
                                formik.setFieldValue("startRecommendedDate", date);
                              }}
                              // showTimeSelect
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.startRecommendedDate && formik.touched.startRecommendedDate
                                  ? " is-invalid"
                                  : "")
                              }
                              // disabled={!formik.values.startDate}
                              title="Start Recommended Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select End Date"
                              timeIntervals={15}
                              onChangeRaw={(e) => e.preventDefault()}
                              // minDate={addDays(formik.values.startDate, 1)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-icon-group mb-4">
                            <label>Recommended End Date * </label>
                            <DatePicker
                              autoComplete="off"
                              selected={formik.values.endRecommendedDate}
                              onChange={(date) => {
                                formik.setFieldValue("endRecommendedDate", date);
                              }}
                              // showTimeSelect
                              filterTime={filterPassedTime}
                              dateFormat={FORM_DATE_FORMAT}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.endRecommendedDate && formik.touched.endRecommendedDate
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={!formik.values.startRecommendedDate}
                              title="End Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Select End Date"
                              timeIntervals={15}
                              onChangeRaw={(e) => e.preventDefault()}
                              minDate={addDays(formik.values.startRecommendedDate, 1)}
                            />
                          </div>
                        </div>
                      </>                
                    ) : null}                                        
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CourseDetails;
