import React, { useEffect, useState } from 'react'
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Modal, ModalBody } from 'react-bootstrap';
import FilterComponent from '../../../common/FilterComponent';
import { AddAssembliesResources, EditAssemblyResourceDetail, GetAssessmentDropdown } from '../../../../services/CourseService';
import { ResourceType } from '../../../systemadministration/usermanagement/CheckRole';
import { downloadURL, IMAGE_URL, URL_REGEX } from '../../../../utils/Constants';
import { RenderAttachList } from '../../../common/RenderAttachList';
import DocumentLibrary from './DocumentLibrary';
import VideoLibrary from './VideoLibrary';
import { TrimText } from '../../../common/TrimText';
import AddQuizzTable from '../../DetailCoursesBlocks/AssessmentBlocks/AddQuizzTable';

const itemType = [
  { value: 2, label: "Video" },
  { value: 3, label: "Sub Category" },
  { value: 4, label: "Document" },
  { value: 5, label: "Link" },
  { value: 6, label: "Assessment" },
  { value: 7, label: "Label" },
];
const itemSubCatType = [
  { value: 2, label: "Video" },
  { value: 4, label: "Document" },
  { value: 5, label: "Link" },
  { value: 6, label: "Assessment" },
  { value: 7, label: "Label" },
];
const documentType = [
  { value: 0, label: "Word" },
  { value: 1, label: "Excel" },
  { value: 2, label: "PDF" },
  { value: 3, label: "ZIP" },
  { value: 4, label: "Image" },
];
const videoType = [
  { value: 0, label: "Vimeo" },
  { value: 1, label: "Youtube" },
];

export default function EditSubCategory({ show, close, editData, assembly_id, refreshList }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [acceptFileName, setAcceptFileName] = useState("");
  const [document, setDocument] = useState({
    name: null,
    file: null
  });
  const [assessDrop, setAssessDrop] = useState([]);
  const [value, onChange] = useState([]);
  const [recIds, setRecIds] = useState([]);
  const [docLib, setDocLib] = useState(false);
  const [videoLib, setVideoLib] = useState(false);

  function dropdownableValues(arr = [], key1, key2) {
    let newArr = arr.map((e, i) => {
      return { label: e[key1], value: e[key2] };
    })
    if (editData.item_type === 6) {
      let selected_val = newArr.filter((val) => val.value === editData?.quiz_id);
      setRecIds([editData?.quiz_id]);
      onChange(selected_val);
    }
    return newArr;
  }
  useEffect(() => {
    GetAssessmentDropdown().then((res) => {
      setAssessDrop(res && res.data && res.data.data ? dropdownableValues(res.data.data, 'quizName', 'quid') : []);
    }).catch((err) => {
      console.log(err);
    });
    if (editData !== undefined && editData !== null && Object.keys(editData).length) {
      onSelectSubData()
    }


    return (() => {
      setAssessDrop([]);
      onChange([]);
      setRecIds([]);
    });
  }, [editData])

  const ResourceSchema = Yup.object().shape({
    item_name: Yup.string().when(["item_type", "item_type_label"], {
      is: (item_type, item_type_label) => {
        return item_type != 6 && item_type_label != "Assessment"
      },
      then: Yup.string().required("Name is required").max(255, "Name can't be more than 255 characters")
    }),
    item_desc: Yup.string().when(["item_type", "item_type_label"], {
      is: (item_type, item_type_label) => {
        return item_type != 6 && item_type_label != "Assessment"
      },
      then: Yup.string().required("Description is required")
    }),
    item_type: Yup.string().required("Item Type is required"),
    video_type: Yup.string().when(["item_type", "item_type_label", "upload_type"], {
      is: (item_type, item_type_label, upload_type) => {
        return ((item_type == 2 && item_type_label == "Video") && upload_type == "upload")
      },
      then: Yup.string().required("Video Type is required")
    }),
    vimeoUrl: Yup.string().when(["item_type", "video_type", "upload_type"], {
      is: (item_type, video_type, upload_type) => {
        return ((item_type == 2 && video_type == 0) && upload_type == "upload")
      },
      then: Yup.string().required("Vimeo Video Code is required")
    }),
    youtubeUrl: Yup.string().when(['item_type', 'video_type', 'upload_type'], {
      is: (item_type, video_type, upload_type) => {
        return ((item_type == 2 && video_type == 1) && upload_type == "upload")
      },
      then: Yup.string().required("Youtube url is required.")
    }),
    document_type: Yup.string().when(['item_type', 'item_type_label', 'upload_type'], {
      is: (item_type, item_type_label, upload_type) => {
        return ((item_type == 4 && item_type_label == "Document") && upload_type == "upload")
      },
      then: Yup.string().required("Document type is required")
    }),
    document_file: Yup.string().when(['item_type', 'document_type', 'upload_type'], {
      is: (item_type, document_type, upload_type) => {
        return ((item_type == 4 && document_type) && upload_type == "upload")
      },
      then: Yup.string().required("file is required")
    }),
    Url: Yup.string().when(['item_type', 'item_type_label'], {
      is: (item_type, item_type_label) => {
        return (item_type == 5 && item_type_label == "Link")
      },
      then: Yup.string().required("Url is required")
    }),
    avl_assessment: Yup.array().when(['item_type', 'item_type_label'], {
      is: (item_type, item_type_label) => {
        return (item_type == 6 && item_type_label == "Assessment")
      },
      then: Yup.array().required("Please select a assessment").min(1)
    }),
    upload_link: Yup.string().when(['item_type', 'upload_type'], {
      is: (item_type, upload_type) => {
        return ((item_type == 2 || item_type == 4) && upload_type == "library")
      },
      then: Yup.string().required("Please select a resource")
    })
  });


  const onSelectSubData = () => {
    let type = ResourceType(editData?.item_type.toString());
    // setSelectedTypeEdit(type);
    let docType =
      editData?.document_type == 0
        ? "Word"
        : editData?.document_type == 1
          ? "Excel"
          : editData?.document_type == 2
            ? "PDF"
            : editData?.document_type == 3
              ? "ZIP"
              : editData?.document_type == 4
                ? "Image"
                : "";
    // setSelectedDocumentTypeEdit(docType);
    let videoType =
      editData?.video_type == 0
        ? "Vimeo"
        : editData?.video_type == 1
          ? "Youtube"
          : "";
    // setSelectedVideoTypeEdit(videoType);
    // setSelectedSubData(resource);

    // if(resource && resource.documentName && resource.link.includes("https")){
    //   // link = resource.link
    //   setDocumentFileUrl(resource.documentName)
    // } else{
    let DocLink;
    if (
      editData &&
      editData.documentName
    ) {
      DocLink = downloadURL+IMAGE_URL + "/Library/AssemblyDocs/" + editData.documentName.replace("public/", "").replace("/home/myaie/public_html/","");
      // setDocumentFileUrl(DocLink);
    } else {
      // setDocumentFileUrl("");
    }
    // else if(resource && resource.documentName && !resource.documentName.includes("public/") && resource.type=="Link") {
    //   link = resource.link;
    // } else if(resource && resource.link && !resource.link.includes("public/")) {
    //   link = IMAGE_URL + "/" + resource.link;
    // }

    // }

    if (type === "Assessment") {
      setRecIds([editData?.quiz_id]);
    }

    setValues({
      ...values,
      item_type_label: type,
      document_type_label: docType,
      video_type_label: videoType,
      document_file: DocLink,
      avl_assessment: [editData?.quiz_id]
    });
    if (editData?.document_type == 4) {
      setAcceptFileName("image/*");
    } else if (editData?.document_type == 0) {
      setAcceptFileName(".docx, .doc");
    } else if (editData?.document_type == 1) {
      setAcceptFileName(".xlsx");
    } else if (editData?.document_type == 2) {
      setAcceptFileName(".pdf");
    } else if (editData?.document_type == 3) {
      setAcceptFileName(".zip");
    }
    // subItemResourceId && setSubParentItemId(subItemResourceId);
  };
  // console.log(editData);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_id: editData?.item_id,
      item_name: editData?.item_name,
      item_desc: editData?.item_desc,
      item_type: editData?.item_type,
      item_type_label: '',
      document_type: editData?.document_type,
      document_type_label: '',
      document_file: downloadURL+IMAGE_URL + "/Library/AssemblyDocs/" + editData?.documentName,
      documentName: editData?.documentName,
      video_type: editData?.video_type,
      video_type_label: '',
      vimeoUrl: editData?.item_url ? editData?.item_url : "",
      youtubeUrl: editData?.youtube_url ? "https://youtu.be/" + editData?.youtube_url : "",
      upload_type: editData?.upload_type ? editData?.upload_type : 'upload',
      upload_link: editData?.upload_link ? editData?.upload_link : "",
      upload_item: '',
      Url: editData?.link_url ? editData?.link_url : "",
      avl_assessment: [],
      showDesc: editData?.label_show_description === 0 ? false : true,
      parent_id: editData?.parent_id,
      assembly_id: assembly_id,
    },
    validationSchema: ResourceSchema,
    onSubmit: (values, { resetForm }) => {
      setIsDisabled(true);
      const formData = new FormData();

      formData.append("item_id", values.item_id);
      formData.append("item_type", values.item_type);
      if (values.item_type != 6) {
        formData.append("item_name", values.item_name);
        formData.append("item_desc", values.item_desc);
      }
      formData.append("parent_id", values.parent_id);
      formData.append("action_assembly_id", assembly_id);

      // Library Upload
      if ((values.item_type == 2 || values.item_type == 4) && values.upload_type == 'library') {
        formData.append("upload_type", values.upload_type);
        formData.append("upload_link", values.upload_link);
      }

      // Video Data
      if (values.item_type == 2 && values.upload_type == 'upload') {
        formData.append("video_type", values.video_type);
        if (values.video_type == 0) {
          formData.append("item_url", values.vimeoUrl);
        }
        if (values.video_type == 1) {
          formData.append("youtube_url", values.youtubeUrl);
        }
      }

      // Document Data
      if ((values.item_type == 4 && document.file) && values.upload_type == 'upload') {
        formData.append("document_resource", document.file);
        formData.append("document_type", values.document_type);
      }

      // Link Data
      if (values.item_type == 5) {
        formData.append("link_url", values.Url);
      }

      // Assessment Data
      if (values.item_type == 6) {

        formData.append("label_show_description", values.showDesc == true ? 1 : 0);
        formData.append("avl_assessment", JSON.stringify(recIds));
      }

      // Label Data
      if (values.item_type == 7) {
        formData.append("label_show_description", values.showDesc == true ? 1 : 0);
      }

      AddAssembliesResources(formData).then((res) => {
        setIsDisabled(false)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        }).then(() => {
          handleClose();
          resetForm();
          refreshList();
        });


      }).catch((err) => {
        setIsDisabled(false);
        Swal.fire({
          icon: "error",
          title: "error",
          text: `${err?.response?.data?.message}`,
        });
      });
    }
  })
  const { values, errors, touched, handleChange, handleSubmit, setValues, handleBlur, resetForm } = formik;

  const handleChangeDocumentType = (value) => {
    setValues({
      ...values,
      document_type: value.value,
      document_type_label: value.label
    });
    if (value.label === "Image") {
      setAcceptFileName("image/*");
    } else if (value.label === "Word") {
      setAcceptFileName(".docx, .doc");
    } else if (value.label === "Excel") {
      setAcceptFileName(".xlsx");
    } else if (value.label === "PDF") {
      setAcceptFileName(".pdf");
    } else if (value.label === "ZIP") {
      setAcceptFileName(".zip");
    }
  }

  const handleChangeDocument = (e) => {
    if(e.target.files.length){
      setDocument({
        ...document,
        file: e.target.files[0],
        name: e.target.files[0].name
      })
      setValues({
        ...values,
        document_file: URL.createObjectURL(e.target.files[0]),
        documentName: e.target.files[0].name
      })
    }
  }

  const handleClose = () => {
    close();
    resetForm();
    onChange([]);
    setRecIds([]);
  }

  const handleCloseDocLib = () => {
    setDocLib(false)
  }

  const handleSubmitDocLib = (result) => {
    setValues({
      ...values,
      upload_item: result.item,
      upload_link: result.item_value
    })
    setDocLib(false);
  }

  const handleCloseVideoLib = () => {
    setVideoLib(false)
  }

  const handleSubmitVideoLib = (result) => {
    setValues({
      ...values,
      upload_item: result.item,
      upload_link: result.item_value
    })
    setVideoLib(false);
  }

  const addQuizzHandler = (quid, quiz_name) => {
    let tempArr = recIds;
    if(tempArr.includes(quid)){
        setRecIds(tempArr);
        setValues({...values,avl_assessment:tempArr});
    } else{
        tempArr.push(quid);
        setRecIds(tempArr);
        setValues({...values,avl_assessment:tempArr});
    }
    // console.log("addQuizzHandler----------->",recIds);
}

  return (
    <>
      <Modal className='modal content-assembly fade' size='xl' show={show} centered onHide={handleClose}>
        <Modal.Header className='modal-header-custom' closeButton>
          <h5 class="modal-title"><i class="fal fa-edit"></i> Resource Detail</h5>
        </Modal.Header>
        <ModalBody className='p-0'>          
          <div className="t p-30px">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="row center-label mb-3">
                  <div className="col-md-6">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="item_type">Item Type*</label>
                      <Select
                        name="item_type"
                        id="item_type"
                        value={values?.item_type_label}
                        onChange={(value) => { setValues({ ...values, item_type: value.value, item_type_label: value.label }); }}
                        className={"form-control custom-select-box " + (errors.item_type && touched.item_type ? " is-invalid" : "")}
                        onBlur={handleBlur}
                        options={itemType}
                        maxMenuHeight={175}
                        placeholder={values.item_type_label ? values.item_type_label : "Select Type"}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  {values.item_type_label !== "Assessment" ?
                    <>
                      <div className="col-md-6">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="item_name">Name*</label>
                          <input
                            type="text"
                            title="Name"
                            className={"form-control " + (errors.item_name && touched.item_name ? " is-invalid" : "")}
                            placeholder="Enter Name"
                            name="item_name"
                            value={values.item_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="item_desc">Description*</label>
                          <input
                            type="text"
                            title="Description"
                            className={"form-control " + (errors.item_desc && touched.item_desc ? " is-invalid" : "")}
                            placeholder="Enter Description"
                            name="item_desc"
                            value={values.item_desc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </>
                    :
                    <div className="col-md-6">
                      {/* <div className="form-group-blk mb-3">
                        <label htmlFor="item_desc">Description*</label>
                        {(Array.isArray(assessDrop) && assessDrop.length) ? <FilterComponent
                          value={value}
                          onChange={(e) => { onChange(e); setValues({ ...values, avl_assessment: e }) }}
                          dataResponse={assessDrop}
                          backendResponse={setRecIds}
                        /> : <i className="fas fa-cog fa-spin"></i>}
                      </div> */}
                      <AddQuizzTable addQuizzHandler={addQuizzHandler} />
                    </div>
                  }
                  {(values.item_type_label === "Document" || values.item_type_label === "Video") &&
                    <div className="col-md-12">
                      <div className="form-icon-group">
                        <div className="prefix-suffix-box">
                          <div className="custom-control custom-radio custom-control-inline" title={"Upload " + values.item_type_label}>
                            <input
                              type="radio"
                              id="upload_document"
                              name="upload_type"
                              checked={values.upload_type == 'upload'}
                              onChange={() => { setValues({ ...values, upload_type: "upload", upload_item: "", upload_link: "" }) }}
                              className="custom-control-input"
                            // onBlur={formik.handleBlur}
                            />
                            <label className="custom-control-label custom-control-inline" htmlFor="upload_document">Upload {values.item_type_label}</label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline" title="Select from library">
                            <input
                              type="radio"
                              id="upload_library"
                              name="upload_type"
                              checked={values.upload_type == 'library'}
                              onChange={() => { setValues({ ...values, upload_type: "library", upload_item: "", upload_link: "" }); }}
                              className="custom-control-input"
                            // onBlur={formik.handleBlur}
                            />
                            <label className="custom-control-label custom-control-inline" htmlFor="upload_library">Select from library</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {(values.item_type_label === "Document" && values.upload_type === "upload") && (
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>Document Type*</label>
                        <Select
                          name="document_type"
                          value={values.document_type_label}
                          onChange={(value) => { handleChangeDocumentType(value) }}
                          className={"form-control custom-select-box " + (errors.document_type && touched.document_type ? " is-invalid" : "")}
                          onBlur={handleBlur}
                          options={documentType}
                          maxMenuHeight={175}
                          placeholder={values.document_type_label ? values.document_type_label : "Select Type"}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group atttach-file">
                            <label>
                              <i class="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                title="File"
                                name="document"
                                className={"form-control form-control-aatch-file " + (errors.document_file && touched.document_file ? " is-invalid" : "")}
                                accept={acceptFileName}
                                onChange={handleChangeDocument}
                                disabled={values.document_type_label ? false : true}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {values.item_type === 4 && values.documentName &&
                            RenderAttachList({
                              fileName: values.documentName,
                              // size: "1205",
                              filePath: values.document_file,
                              showClose: false,
                              docType: values?.document_type_label,
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                  {(values.item_type_label === "Document" && values.upload_type === "library") && (
                    <div className="col-md-12 mt-3">
                      {values.upload_link.length > 0 && <div className="frm-group w-50">
                        <ul className="list-unstyled attached-file-ul m-0">
                          <li>
                          {RenderAttachList({
                              fileName: values.upload_link.split("/").reverse()[0],
                              // size: "1205",
                              filePath: values.upload_link.includes("http") ? values.upload_link : `${IMAGE_URL}/${values.upload_link}`.replace("/public/","/"),
                              showClose: false,
                              docType: values?.document_type_label,
                            })}
                            {/* <a
                              title={values.upload_link}
                              href={values.upload_link.includes("http") ? values.upload_link : `${IMAGE_URL}/${values.upload_link}`.replace("/public/","/")}
                              target="_blank"
                            >
                              <i className="fal fa-file"></i>
                              &nbsp;
                              {TrimText(
                                values.upload_link
                                  ? values.upload_link
                                  : values.upload_link.split(
                                    "/"
                                  ).reverse()[0])}
                            </a> */}
                            <button
                              className="btn btn-danger rounded-circle btn-dropdown-item"
                              title="Delete"
                              onClick={() => setValues({ ...values, upload_item: "", upload_link: "" })}
                            >
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </li>
                        </ul>
                      </div>}
                      <button
                        title="Add Document"
                        type="button"
                        onClick={() => { setDocLib(true) }}
                        className='btn btn-primary'>
                        <i className="fal fa-plus"></i> Add Document
                      </button>
                    </div>
                  )}
                  {(values.item_type_label === "Video" && values.upload_type === "upload") && (
                    <>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Video Type*</label>
                          <Select
                            className={
                              "form-control custom-select-box " +
                              (errors.video_type &&
                                touched.video_type
                                ? " is-invalid"
                                : "")
                            }
                            name="video_type"
                            value={values.video_type}
                            onChange={(value) => { setValues({ ...values, video_type: value.value, video_type_label: value.label }); }}
                            onBlur={handleBlur}
                            options={videoType}
                            maxMenuHeight={175}
                            placeholder={
                              values.video_type_label
                                ? values.video_type_label
                                : "Select Type"
                            }
                          />
                        </div>
                      </div>
                      {values.video_type_label === "Vimeo" && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                              <label>Vimeo Video Url*</label>
                              <input
                                type="text"
                                title="Vimeo Video Url"
                                className={"form-control "}
                                placeholder="Enter Vimeo Url"
                                name="vimeoBaseUrl"
                                value={
                                  "https://player.vimeo.com/video/"
                                }
                                disabled
                              // onChange={addSubModalFormik.handleChange}
                              // onBlur={addSubModalFormik.handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                              <label>Vimeo Video Code*</label>

                              <input
                                type="text"
                                title="Vimeo Video Code"
                                className={
                                  "form-control " +
                                  (errors.vimeoUrl &&
                                    touched.vimeoUrl
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Vimeo Code"
                                name="vimeoUrl"
                                value={
                                  values.vimeoUrl
                                }
                                onChange={
                                  handleChange
                                }
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {values.video_type_label === "Youtube" && (
                        <div className="col-md-12">
                          <div className="form-group-blk mb-3">
                            <label>URL*</label>
                            <input
                              type="text"
                              title="youtubeUrl"
                              className={"form-control " + (errors.youtubeUrl && touched.youtubeUrl ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              name="youtubeUrl"
                              value={values.youtubeUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {(values.item_type_label === "Video" && values.upload_type === "library") && (
                    <div className="col-md-12 mt-3">
                      {values.upload_link.length > 0 && <div className="frm-group w-50">
                        <ul className="list-unstyled attached-file-ul m-0">
                          <li>
                            <a
                              title={values.upload_link}
                              href={URL_REGEX.test(values.upload_link) || /http:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?)&)/.test(values.upload_link) ? values.upload_link : "https://player.vimeo.com/video/" + values.upload_link}
                              target="_blank"
                            >
                              <i className="fal fa-video"></i>
                              &nbsp;
                              {(URL_REGEX.test(values.upload_link) || /http:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?)&)/.test(values.upload_link)) ?  TrimText(values.upload_link) : (values.upload_link).includes("http") ? values.upload_link : "https://player.vimeo.com/video/" + values.upload_link}
                            </a>
                            <button
                              className="btn btn-danger rounded-circle btn-dropdown-item"
                              title="Delete"
                              onClick={() => setValues({ ...values, upload_item: "", upload_link: "" })}
                            >
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </li>
                        </ul>
                      </div>}
                      <button
                        title="Add Video"
                        type="button"
                        onClick={() => { setVideoLib(true) }}
                        className='btn btn-primary'>
                        <i className="fal fa-plus"></i> Add Video
                      </button>
                    </div>
                  )}
                  {values.item_type_label === "Link" && (
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>URL*</label>
                        <input
                          type="text"
                          title="Url"
                          className={"form-control " + (errors.Url && touched.Url ? " is-invalid" : "")}
                          placeholder="Enter Url"
                          name="Url"
                          value={values.Url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  )}
                  {values.item_type_label === "Label" && (
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            title="Description"
                            className="custom-control-input"
                            id="showDesc"
                            name="showDesc"
                            checked={values.showDesc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="showDesc"
                          >
                            Show Description
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {Object.keys(errors).map((key) => (errors[key] && touched[key] ? <div className="invalid-feedback d-block">{errors[key]}</div> : null))}
                <div className="form-group form-group-save-cancel mt-4">
                  <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!formik.dirty}>
                    {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                    Save
                  </button>

                  <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => handleClose()}>
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </ModalBody>
      </Modal>
      {docLib && <DocumentLibrary
        show={docLib}
        handleClose={handleCloseDocLib}
        handleSelect={(result) => { handleSubmitDocLib(result) }}
        selectedItem={values.upload_item}
      />}
      {videoLib && <VideoLibrary
        show={videoLib}
        handleClose={handleCloseVideoLib}
        handleSelect={(result) => { handleSubmitVideoLib(result) }}
        selectedItem={values.upload_item}
      />}
    </>
  )
}
