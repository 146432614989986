import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { GetQualificationOptions, GetSchoolList, checkCheckboxRejectDetails } from "../../../../services/RegistrationService";
import { FORM_DATE_FORMAT } from "../../../../utils/Constants";
import { addDays } from "date-fns";
import RenderPreviousValues from "./RenderPreviousValues";
import Swal from "sweetalert2";

function RegistrationDetails({ studentData, clickedCheck, salesPerson, allBrands, studentId, school }) {

  const dropdownValues = useSelector((state) => state.registerDropdownValues);
  const [intakeArr, setIntakeArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [qualOption, setQualOption] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const [schoolValue, setSchoolValue] = useState("");
  const [status, setStatus] = useState("");
  const [studentStartDate, setStudentStartDate] = useState(new Date());
  const [studentEndDate, setStudentEndDate] = useState(new Date());
  const [schoolList, setSchoolList] = useState([]);

  const AcademicLearningMethod = useSelector((state) => state.registerDropdownValues.study_type_2);
  const ShortLearningMethod = useSelector((state) => state.registerDropdownValues.study_type);

  function getValue(arrName, label) {
    let labelName = "";
    for (let item of dropdownValues[arrName]) {
      if (item.value.toString() == label.toString()) {
        labelName = item.label;
        break;
      }
    }

    if (labelName === "" && arrName === "re_registration_status") return label;
    return labelName;
  }

  const fixNetsuiteArr = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        value: item.id,
        label: item.displayname,
        departmentId: item.department,
      };
      tempArr.push(singleItem);
    });
    // console.log("new Array1", tempArr);
    setQualOption(oldVal => [...oldVal, ...tempArr]);
  };

  useEffect(() => {
    if (dropdownValues) {
      let tempIntakeArr = []
      dropdownValues.intake_year.map(year => {
        dropdownValues.intake_number.map(number => {
          tempIntakeArr.push({ value: `${year.value}/${number.label}`, label: `${year.value}-${number.label}` })
        })
      })
      setIntakeArr(tempIntakeArr)
    }
  }, [dropdownValues])

  useEffect(() => {
    // if (schoolValue == "fg" || schoolValue == "fst") {
    //   const apiData = {
    //     exportStatus: "true",
    //     viaSchool: ["fg", "fst"],
    //   }
    //   GetProgramsList(apiData).then(res => {
    //     let tempArr = res.data.programs.map(item => {
    //       return ({
    //         value: item.id,
    //         label: item.course,
    //         departmentId: 0
    //       })
    //     })
    //     setQualOption(oldVal => [...oldVal, ...tempArr]);
    //   })
    // }
    if (schoolId) {
      GetQualificationOptions(schoolId).then(res => {
        fixNetsuiteArr(res && res.data && res.data.data && res.data.data.items ? res.data.data.items : []);
      })
    }
  }, [schoolValue])

  useEffect(() => {
    GetSchoolList().then((res) => {
      if (res.status == 200) {
        const respData = res.data.data;
        setSchoolList(respData);
        respData.map((item) => {
          if (item.value === school) {
            setSchoolId(item.netsuite_department_id)
            setSchoolValue(item.value)
          }
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }, [school])

  useEffect(() => {
    if (studentData && salesPerson) {
      formik.setFieldValue("early_bird_discount", studentData.re_reg_data?.early_bird_discount);
      let time = studentData.re_reg_data?.created_date;
      if (!(studentData.re_reg_data?.created_date.length > 10)) {
        time = (parseInt(studentData.re_reg_data?.created_date) * 1000)
      }
      setStartDate(time);
      if (studentData.re_reg_data && studentData.re_reg_data.status) {
        setStatus(getValue("re_registration_status", studentData.re_reg_data.status));
      }
      let studentStartTime = studentData.re_reg_data?.start_date;
      if (studentStartTime && !(studentStartTime > 10)) {
        studentStartTime = (parseInt(studentStartTime) * 1000)
      }
      setStudentStartDate(studentStartTime);
      let studentEndTime = studentData.re_reg_data?.end_date;
      if (studentEndTime && !(studentEndTime.length > 10)) {
        studentEndTime = (parseInt(studentEndTime) * 1000)
      }
      setStudentEndDate(studentEndTime);

    }

  }, [studentData, salesPerson]);

  const formik = useFormikContext()

  const setDate = (date) => {
    setStartDate(date)
    formik.setFieldValue("submission_date", date)
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Registration Details</div>
      </div>
      {/* <form onSubmit={formik.handleSubmit}></form > */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label htmlFor="school">School *</label>
          <div className="form-icon-group mb-4" title='School'>
            <Select
              className={"form-control custom-select-box " + (formik.errors.school && formik.touched.school ? " is-invalid" : "")}
              name="school"
              value={schoolList.filter((item) => {
                return item.value == formik.values.school_id;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("school", value.label);
                  formik.setFieldValue("school_id", value.value);
                  setSchoolId(value.netsuite_department_id);
                  setSchoolValue(value.value)
                } else {
                  formik.setFieldValue("school", "");
                  formik.setFieldValue("school_id", "");
                  setSchoolId("");
                  setSchoolValue("")
                }
                formik.setFieldValue("qualification", "");
                formik.setFieldValue("netsuite_department_id", "");
                setQualOption([]);
              }}
              // isClearable
              onBlur={formik.handleBlur}
              options={schoolList}
              maxMenuHeight={175}
              // placeholder={formik.values.school ? formik.values.school : "Select"}
              placeholder={"Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.academy} previousValue={studentData?.RegStudentsPreviousDetails?.academy} type="dropdown" typeSupportingProps={{filterArray : schoolList}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="qualification">Qualification {(status && (status == "Re-Registration" || status == "Preliminary Re-Registration")) && "*"}</label>
          <div className="form-icon-group mb-4" title="Qualification">
            <Select
              className={"form-control custom-select-box " + (formik.errors.qualification && formik.touched.qualification ? " is-invalid" : "")}
              name="qualification"
              value={qualOption.filter((item) => {
                return item.value.toString() == formik.values.qualification.toString();
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("qualification", value.value);
                  formik.setFieldValue("netsuite_department_id", value.departmentId);
                } else {
                  formik.setFieldValue("qualification", "");
                  formik.setFieldValue("netsuite_department_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={qualOption.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1.localeCompare(name2)
              })}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.qualification ? formik.values.qualification : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.qualification} previousValue={studentData?.RegStudentsPreviousDetails?.qualification} 
          type="dropdown" typeSupportingProps={{filterArray : qualOption, isQualification: true, oldSchoolValue: studentData?.RegStudentsPreviousDetails?.academy}} 
          />
        </div>
        <div className="col-md-6 col-lg-4 ">
          <label htmlFor="delivery_method">Course Type *</label>
          <div className="form-icon-group mb-4" title="Course Type">
            <Select
              className={"form-control custom-select-box " + (formik.errors.course_type && formik.touched.course_type ? " is-invalid" : "")}
              name="course_type"
              value={dropdownValues.type_course.filter((item) => {
                return item.value == formik.values.course_type;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("course_type", value.value);
                  if (value.value == "mentored" && formik.values.delivery_method == "selfplaced") {
                    formik.setFieldValue("delivery_method", "");
                    formik.setFieldValue("delivery_method_id", "");
                  }
                } else {
                  formik.setFieldValue("course_type", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.type_course}
              maxMenuHeight={175}
              placeholder={formik.values.course_type ? formik.values.course_type : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.course_type} previousValue={studentData?.RegStudentsPreviousDetails?.course_type} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.type_course}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="delivery_method">Learning Method</label>
          <div className="form-icon-group mb-4" title="Learning Method">
            <Select
              className={"form-control custom-select-box " + (formik.errors.delivery_method && formik.touched.delivery_method ? " is-invalid" : "")}
              name="delivery_method"
              value={ShortLearningMethod.filter((item) => {
                return item.value == formik.values.delivery_method;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("delivery_method", value.value);
                  formik.setFieldValue("delivery_method_id", value.label);
                } else {
                  formik.setFieldValue("delivery_method", "");
                  formik.setFieldValue("delivery_method_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={formik.values.course_type === "shortcourse" ? ShortLearningMethod : AcademicLearningMethod}
              maxMenuHeight={175}
              placeholder={formik.values.delivery_method ? ShortLearningMethod.find((item) => (item.value == formik.values.delivery_method))?.label : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.delivery_methode} previousValue={studentData?.RegStudentsPreviousDetails?.delivery_methode} type="dropdown" typeSupportingProps={{filterArray : formik.values.course_type === "shortcourse" ? ShortLearningMethod : AcademicLearningMethod}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="campus">Campus *</label>
          <div className="form-icon-group mb-4" title="Campus">
            <Select
              className={"form-control custom-select-box " + (formik.errors.campus && formik.touched.campus ? " is-invalid" : "")}
              name="campus"
              value={dropdownValues.program_campus.filter((item) => {
                return item.value == formik.values.campus;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("campus", value.value);
                  formik.setFieldValue("campus_id", value.value);
                } else {
                  formik.setFieldValue("campus", "");
                  formik.setFieldValue("campus_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.program_campus.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1.localeCompare(name2)
              })}
              maxMenuHeight={175}
              placeholder={formik.values.campus ? formik.values.campus : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.campus_selected} previousValue={studentData?.RegStudentsPreviousDetails?.campus_selected} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.program_campus, from :"asdf"}} />
        </div>
        {formik.values.course_type == "mentored" ?
          <div className="col-md-6 col-lg-4">
            <label htmlFor="intake">Intake </label>
            <div className="form-icon-group mb-4" title="Intake">
              <Select
                className={"form-control custom-select-box " + (formik.errors.intake && formik.touched.intake ? " is-invalid" : "")}
                name="intake"
                value={intakeArr?.filter(val => val.value === formik.values.intake)}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("intake", value.value);
                  } else {
                    formik.setFieldValue("intake", "");
                  }
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={intakeArr}
                maxMenuHeight={175}
                placeholder={formik.values.intake ? formik.values.intake : "Select"}
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.intake_year && studentData?.re_reg_data?.intake_number ? `${studentData?.re_reg_data?.intake_year}/${studentData?.re_reg_data?.intake_number}` : ''} previousValue={studentData.RegStudentsPreviousDetails?.intake_year && studentData.RegStudentsPreviousDetails?.intake_number ? `${studentData.RegStudentsPreviousDetails?.intake_year}/${studentData.RegStudentsPreviousDetails?.intake_number}` : ''} type="dropdown" typeSupportingProps={{filterArray : intakeArr, from: "intake"}} />
          </div>
          : <></>}
        <div className="col-md-6 col-lg-4">
          <label htmlFor="submission_date">Submission Date</label>
          <div className="form-group-blk mb-3" title="Submission Date">
            <DatePicker
              selected={startDate}
              disabled
              onChange={setDate}
              className="form-control w-100"
              onChangeRaw={(e) => e.preventDefault()}
              dateFormat={FORM_DATE_FORMAT}
              title="Submission Date"
              onBlur={formik.handleBlur}
              placeholderText="Select Submission Date"
            />
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData.re_reg_data?.created_date} previousValue={studentData.RegStudentsPreviousDetails?.created_date} typeSupportingProps={{from: "date"}}  />
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="finance_option">Finance Option</label>
          <div className="form-icon-group mb-4" title="Finance Option">
            <Select
              className={"form-control custom-select-box " + (formik.errors.finance_option && formik.touched.finance_option ? " is-invalid" : "")}
              name="finance_option"
              value={dropdownValues.finance_option_chosen.filter((item) => {
                return item.value == formik.values.finance_option_id;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("finance_option", value.value);
                  formik.setFieldValue("finance_option_id", value.value);
                } else {
                  formik.setFieldValue("finance_option", "");
                  formik.setFieldValue("finance_option_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={dropdownValues.finance_option_chosen.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1.localeCompare(name2)
              })}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.finance_option ? formik.values.finance_option : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.finance_option_chosen} previousValue={studentData?.RegStudentsPreviousDetails?.finance_option_chosen} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.finance_option_chosen}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="laptop">Laptop *</label>
          <div className="form-icon-group mb-4" title="Laptop">
            <Select
              className={"form-control custom-select-box " + (formik.errors.laptop && formik.touched.laptop ? " is-invalid" : "")}
              name="laptop"
              value={dropdownValues.laptop.filter((item) => {
                return item.value == formik.values.laptop;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("laptop", value.value);
                  formik.setFieldValue("laptop_id", value.value);
                } else {
                  formik.setFieldValue("laptop", "");
                  formik.setFieldValue("laptop_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={dropdownValues.laptop}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.laptop ? formik.values.laptop : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.laptop} previousValue={studentData?.RegStudentsPreviousDetails?.laptop} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.laptop}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="brand">Brand *</label>
          <div className="form-icon-group mb-4" title="Brand">
            <Select
              className={"form-control custom-select-box " + (formik.errors.brand && formik.touched.brand ? " is-invalid" : "")}
              name="brand"
              value={allBrands.filter((item) => {
                return item.value == formik.values.brand;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("brand", value.value);
                  formik.setFieldValue("brand_id", value.value);
                } else {
                  formik.setFieldValue("brand", "");
                  formik.setFieldValue("brand_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={allBrands.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1.localeCompare(name2)
              })}
              maxMenuHeight={175}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.brand_reg} previousValue={studentData?.RegStudentsPreviousDetails?.brand_reg} type="dropdown" typeSupportingProps={{filterArray : allBrands}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="sales_person">Sales Person</label>
          <div className="form-icon-group mb-4" title="Sales Person">
            <Select
              className={"form-control custom-select-box " + (formik.errors.sales_person && formik.touched.sales_person ? " is-invalid" : "")}
              name="sales_person"
              value={salesPerson.filter((item) => {
                return item.value == formik.values.sales_person_id
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("sales_person", value.label);
                  formik.setFieldValue("sales_person_id", value.value);
                } else {
                  formik.setFieldValue("sales_person", "");
                  formik.setFieldValue("sales_person_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={salesPerson.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1.localeCompare(name2)
              })}
              maxMenuHeight={175}
              placeholder={formik.values.sales_person ? formik.values.sales_person : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData.re_reg_data?.sale_person_id} previousValue={studentData.RegStudentsPreviousDetails?.sale_person_id} type="dropdown" typeSupportingProps={{filterArray : salesPerson}} />
        </div>
        {(formik.values.course_type != "mentored" && (formik.values.course_type == "shortcourse" && formik.values.delivery_method != "selfplaced")) ?
          <>
            <div className="col-md-6 col-lg-4">
              <label htmlFor="sales_person">Start Date</label>
              <div className="form-group-blk mb-3" title="Start Date">
                <DatePicker
                  autoComplete="off"
                  selected={formik.values.start_date}
                  name="start_date"
                  onChange={(date) => {
                    setStudentStartDate(date)
                    formik.setFieldValue("start_date", date)
                  }}
                  className="form-control w-100"
                  onChangeRaw={(e) => e.preventDefault()}
                  dateFormat={FORM_DATE_FORMAT}
                  title="Start Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Select Start Date"
                />
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.start_date} previousValue={studentData?.RegStudentsPreviousDetails?.start_date} typeSupportingProps={{from : "date"}}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <label htmlFor="sales_person">End Date</label>
              <div className="form-group-blk mb-3" title="End Date">
                <DatePicker
                  selected={studentEndDate}
                  onChange={(date) => {
                    setStudentEndDate(date)
                    formik.setFieldValue("end_date", date)
                  }}
                  className="form-control w-100"
                  onChangeRaw={(e) => e.preventDefault()}
                  dateFormat={FORM_DATE_FORMAT}
                  title="End Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Select End Date"
                  disabled={!formik.values.start_date}
                  timeIntervals={15}
                  minDate={addDays(formik.values.start_date, 0)}
                  dateFormatCalendar="MMMM"
                />
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData.re_reg_data?.end_date} previousValue={studentData.RegStudentsPreviousDetails?.end_date} typeSupportingProps={{from: "date"}} />
              </div>
            </div>
          </> : null}
        {studentData && studentData.re_reg_data && studentData.re_reg_data.academy == "business" && studentData.re_reg_data.status !== "Final-Status" &&
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentSelection">Student Selection *</label>
            <div className="form-icon-group mb-4">
              {/* <Field name="StudentSelection" placeholder="Student Selection" type="text" className={"form-control"} id="StudentSelection" title="Street Address" /> */}
              <textarea
                cols={5}
                type="text"
                id="StudentSelection"
                className={"form-control" + (formik.errors.StudentSelection && formik.touched.StudentSelection ? " is-invalid" : "")}
                name="StudentSelection"
                placeholder="Student Selection"
                value={formik.values.StudentSelection}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.re_reg_subjects} previousValue={studentData?.RegStudentsPreviousDetails?.re_reg_subjects} />
          </div>
        }
      </div>

      <div className="form-group form-group-save-cancel">
        {studentData && studentData.matched_program ?
          <div>
            {studentData.matched_program.length
              ? <b>Matched: {studentData.matched_program.map((item, index) =>
                <Link
                  className="as-text-blue curser "
                  to={formik.values.course_type == "mentored" ? `/courseAdministration/Programmes/programme/open/${item.programId}/details` : `/courseAdministration/coursesdetails/${item.programId}/detailCourses/show`}
                  key={index}
                >
                  {item.course}{(studentData.matched_program.length - 1) !== index ? ", " : ""}
                </Link>)}</b>
              : <b className="matched-red">Not Matched</b>
            }
          </div>
          : ""
        }
      </div>
      <hr />
    </div>
  );
}

export default RegistrationDetails;
