import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import $ from "jquery";
import SettingsForm from "../components/SettingsForm";

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  componentWillMount() {
  }

  componentWillUnmount(){
  }


  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    
    return (
      <>
      
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
            <SideBar></SideBar>
            <div className="sidebar-right">
				<div className="settings-section">
					<div className="settings-card">
						<div className="settings-title">
							Settings
						</div>
						<SettingsForm></SettingsForm>
					</div>
				</div>
            </div>
        </div>
      </>
    );
  }
}

export default Settings;
