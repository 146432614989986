import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Tabs from "./Tabs";
import { getCurrentTimeHomePage } from "../common/Helper";
import Dashboard from "./DetailCoursesBlocks/DashBoard";
import CourseDetails from "./DetailCoursesBlocks/CourseDetails";
import Assessments from "./DetailCoursesBlocks/Assessments";
import Assemblies from "./DetailCoursesBlocks/Assemblies";
import OnlineClasses from "./DetailCoursesBlocks/OnlineClasses";
import Notifications from "./DetailCoursesBlocks/Notifications";
import Students from "./DetailCoursesBlocks/Students";
import Tickets from "./DetailCoursesBlocks/Tickets";
// import AuditTrail from "./DetailCoursesBlocks/AuditTrail";
import AuditTrail from "./AuditTrail";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetAssessmentDetails, GetOnlineClassGraphData, GetCourseDetails } from "../../services/CourseService";
import { TrimText } from "../common/TrimText";
import PostsAndFeed from "./DetailCoursesBlocks/PostsAndFeed";
import PermissionsGate from "../../utils/permissionGate";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import Programs from "./DetailCoursesBlocks/Programs";
import { SP_PASS, STUDENT_PORTAL_URL } from "../../utils/Constants";
import { GetSubjectContentService } from "../../services/PostsAndFeedService";
import { useSelector } from "react-redux";
import AssembliesDrag from "./DetailCoursesBlocks/AssembliesDrag";
import CourseGradeSettingsList from "./CourseGradeSettings/CourseGradeSettingsList";
import CourseGradeBook from "./CourseGradbook/CourseGradeBook";

const DetailCourses = () => {

    const { id, tab, type, subId } = useParams();
    const history = useHistory();
    const [basicInfo, setBasicInfo] = useState({});
    const [unreadCommentCount, setUnreadCommentCount] = useState(0);
    const bottomScroll = React.createRef();
    const user = useSelector((state) => state.user);
    const assessment_name = useSelector((state)=>state.assessment.assessment_name)
    const [subjectContent, setSubjectContent] = useState([]);
    const [chatUnreadCount, setChatUnreadCount] = useState(0)
    const [assName, setAssName] = useState("")
    const [courseName, setCourseName] = useState("");
    const [enableChat, setEnableChat] = useState(false)
    const [courseData, setCourseData] = useState("")
    const [gradingStandard, setGradingStandard] = useState()
    const [saveData, setSaveData] = useState(false)
    useEffect(() => {
        getFirstGraphData();
    }, [])

    const getFirstGraphData = () => {
        GetOnlineClassGraphData(id).then(response => {
            setBasicInfo(response && response.data ? response.data : {})
        }).catch(error => {
        })
    }

    useEffect(() => {
        GetSubjectContentService({ sid: id, user_id: user.id })
            .then((res) => {
                setSubjectContent(res.data)
                setUnreadCommentCount(res.data.messageCount)
                setChatUnreadCount(res.data.messageCount)
                setCourseName(res.data?.subject?.name)
            })
            .catch((error) => { });
    }, [enableChat])
    useEffect(() => {
        GetCourseDetails(id)
        .then((data) => {
          if(data.data.grading_standard) { 
          setGradingStandard(data.data.grading_standard)
          }
          setCourseData(data.data.correspondence)
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
        if(subId){
            GetAssessmentDetails(subId).then((res)=>{
                setAssName(res.data.name)
            }).catch(err=>console.log(err))
        }
    }, [id, subId, saveData])
    
    return (
        <div className="mob-header">
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradeBooks" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block space-box">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2 shift-move">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            {tab == "assessments" && type == "open" ? <h4 className="text-left-align landing-heading heading_color_dynamic">Assessment:<span className="ml-1" title={assName}>{TrimText(assName, 60)}</span></h4> :<h4 className="text-left-align landing-heading heading_color_dynamic">Courses:<span className="ml-1" title={basicInfo?.course_name}>{TrimText(basicInfo?.course_name, 60)}</span></h4>}
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        {GlobalIconNames("leftarrow")}Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {tab == "assessments" && type == "open" ? <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <Link
                                                        title="Course"
                                                        className="btn btn-white-bordered"
                                                        to={`/courseAdministration/coursesdetails/${id}/detailCourses/show`}
                                                        // target="_blank"
                                                    >
                                                        <i className="fal fa-book"></i>Course
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> : 
                                        <div className="text-md-right action2-blk">
                                        <div className="ticket-view-btn d-flex align-items-center">
                                            <div className="add-new-ticket-bx">
                                                <a
                                                    title="Student View"
                                                    className="btn btn-white-bordered"
                                                    href={(basicInfo.qualification_id==null || basicInfo.qualification_id==undefined || basicInfo.qualification_id=="") ? `${STUDENT_PORTAL_URL}/courseinfo/shortcourse/${basicInfo.intakeID}/${SP_PASS}` : `${STUDENT_PORTAL_URL}/courseinfo/${basicInfo.qualification_id}/subject/${basicInfo.intakeID}/${SP_PASS}`}
                                                    target="_blank"
                                                >
                                                    {GlobalIconNames("studentview").html}
                                                </a>
                                            </div>
                                        </div>
                                    </div>}
                                    </div>
                                    {/* <h4 className="text-left-align landing-heading heading_color_dynamic">
                                        Courses <span>English</span>
                                    </h4> */}
                                </div>
                                <div className="tabs-wrap">
                                    {((tab == "assessments" && type != "open") || (tab != "assessments" && type != "open")) && <div className="scroll-tabs filter-scroll c-o-c-a coures-scroll-arrow">
                                        <button type="button" className="btn btn-arrow btn-scroll-left" onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft - 40)}>
                                            <i className="fal fa-chevron-left"></i>
                                        </button>
                                        <div className={`filter-scroll-inner `} ref={bottomScroll}>
                                            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                                <PermissionsGate scopes={["cdaview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/dashboard/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "dashboard" ? "active" : ""}`}>
                                                            <i className="fal fa-chart-pie-alt"></i>
                                                            Dashboard
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["cedit"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/detailCourses/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "detailCourses" ? "active" : ""}`} >
                                                            {GlobalIconNames("details").html}
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["cedit"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assignedProgram/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "assignedProgram" ? "active" : ""}`} >
                                                            {GlobalIconNames("assignedprogrammes").html}
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["castview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "assessments" ? "active" : ""}`} >
                                                            <i className="fal fa-file-alt"></i>Assessments
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["cagsview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/gradeSettings/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "gradeSettings" ? "active" : ""}`}>
                                                        <i className="fal fa-sliders-h-square"></i>Grade Settings
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["cagbview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/gradeBooks/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "gradeBooks" ? "active" : ""}`} >
                                                        <i className="fal fa-bookmark"></i>Gradebook
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["casbview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assemblies/show`)}>
                                                        <a className={`nav-link cursor-pointer ${(tab === "assemblies" || tab === "assembliesDrag") ? "active" : ""}`}>
                                                            <i className="fal fa-info-circle"></i>Content
                                                        </a>
                                                    </li>
                                                </PermissionsGate>
                                                {courseData !== "selfplaced" ? (
                                                <PermissionsGate scopes={["cocview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/onlineClasses/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "onlineClasses" ? "active" : ""}`}>
                                                            <i className="fal fa-laptop"></i>Class Schedule
                                                        </a>
                                                    </li>
                                                </PermissionsGate>)
                                                :null}
                                                <PermissionsGate scopes={["cnoview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/notifications/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "notifications" ? "active" : ""}`} >
                                                         {GlobalIconNames("notifications").html}
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["cstview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/students/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "students" ? "active" : ""}`}>
                                                            <i className="fal fa-users"></i>Students
                                                        </a>
                                                    </li>
                                                </PermissionsGate>

                                                <PermissionsGate scopes={["ctiview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/tickets/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "tickets" ? "active" : ""}`}>
                                                            <i className="fal fa-ticket"></i>Tickets
                                                        </a>
                                                    </li>
                                                </PermissionsGate>
                                                {courseData !== "selfplaced" && subjectContent?.subject?.convChatGroup == "1"  ? (
                                                <PermissionsGate scopes={["cpfview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/post-feed/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "post-feed" ? "active" : ""}`}>
                                                            {(unreadCommentCount) > 0 ?
                                                                <small className="notify-count1">{(unreadCommentCount) < 10 ? (unreadCommentCount) : '9+'}</small> : null}
                                                            <i className="fal fa-comments"></i>Chat Room
                                                        </a>
                                                    </li>
                                                </PermissionsGate>)
                                                :null}
                                                <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/auditTrail/show`)}>
                                                    <a className={`nav-link cursor-pointer ${tab === "auditTrail" ? "active" : ""}`} >
                                                        {GlobalIconNames("audittrail").html}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-arrow btn-scroll-right" onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft + 40)}>
                                            <i className="fal fa-chevron-right"></i>
                                        </button>
                                    </div>}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab === "dashboard" && <Dashboard userId={id} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                                            {tab === "detailCourses" && <CourseDetails userId={id} setEnableChat={setEnableChat} enableChat={enableChat}/>}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab21" role="tabpanel" aria-labelledby="pills-tab10">
                                            {tab === "assignedProgram" && <Programs userId={id} gradingStandard={gradingStandard} setSaveData={setSaveData} saveData={saveData}/>}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
                                            {tab === "assessments" && <Assessments userId={id} assName={assName} lmType={courseData} gradingStandard={gradingStandard}/>}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab51" role="tabpanel" aria-labelledby="pills-tab52">
                                            {tab === "gradeSettings" && <CourseGradeSettingsList userId={id} assName={assName}/>}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab78" role="tabpanel" aria-labelledby="pills-tab79">
                                            {tab === "gradeBooks" && <CourseGradeBook userId={id} assName={assName} courseName={courseName}/>}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab14" role="tabpanel" aria-labelledby="pills-tab3">
                                            {tab === "assemblies" && <Assemblies userId={id} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab15" role="tabpanel" aria-labelledby="pills-tab4">
                                            {tab === "onlineClasses" && <OnlineClasses userId={id} />}
                                        </div>
                                        {/* <div className="tab-pane fade active show" id="pills-tab16" role="tabpanel" aria-labelledby="pills-tab5">
                                            {tab === "recordings" && <Recordings userId={id} />}
                                        </div> */}
                                        <div className="tab-pane fade active show" id="pills-tab17" role="tabpanel" aria-labelledby="pills-tab6">
                                            {tab === "notifications" && <Notifications userId={id} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab18" role="tabpanel" aria-labelledby="pills-tab7">
                                            {tab === "students" && courseName ? <Students userId={id} courseName={courseName} /> : ""}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab19" role="tabpanel" aria-labelledby="pills-tab8">
                                            {tab === "tickets" && <Tickets userId={id} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab19" role="tabpanel" aria-labelledby="pills-tab8">
                                            {tab === "post-feed" && <PostsAndFeed userId={id} user_id={user.UserID} unreadCommentCount={unreadCommentCount} setUnreadCommentCount={setUnreadCommentCount} subjectContent={subjectContent} chatUnreadCount={chatUnreadCount} setChatUnreadCount={setChatUnreadCount} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab20" role="tabpanel" aria-labelledby="pills-tab9">
                                            {tab === "auditTrail" && <AuditTrail userId={id} />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab21" role="tabpanel" aria-labelledby="pills-tab21">
                                            {tab === "assembliesDrag" && <AssembliesDrag/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailCourses;
